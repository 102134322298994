import React, {Component} from 'react';

import styled from 'styled-components';

export const Flex = styled.div`
display: flex;
margin-top: ${props => props.marginTop};
`;

const GridCSS = styled.div`
width:100%;
display: grid;
margin-top: ${props => props.marginTop};
grid-template-columns: ${props => props.columns};
grid-template-rows: ${props => props.rows};
grid-column-gap: ${props => props.gapColumns};
grid-row-gap: ${props => props.gapRows};
`;

const Wrapper = styled.div`
margin-left: ${props => props.marginHorizontal};
margin-right: ${props => props.marginHorizontal};
width:100%;
`;

export const RedBoxCSS = styled.div`
background-color: #ff0000;
width:100%;
height:100%;
`;

export const CenterCSS = styled.div`
width: ${props => props.width};
margin-top: ${props => props.marginTop};
margin-left: auto;
display: flex;
justify-content: center;
margin-right: auto;
min-height: 80px;
@media screen and (max-width: 900px) {
width: 70%;
}
@media screen and (max-width: 400px) {
width: 90%;
}
position: relative;
`;

export const BoxCSS = styled.div`
width: ${props => props.width};
margin-top: ${props => props.marginTop};
`;

export function RedBox() {
    return (
        <RedBoxCSS>&nbsp;</RedBoxCSS>
    );

}
export class Grid extends Component {

    constructor(props) {
        super(props);


    }

    render() {
        return (
            <Wrapper marginHorizontal={this.props.marginHorizontal}>
                <GridCSS marginTop={this.props.marginTop}
                         columns={this.props.columns}
                         rows={this.props.rows}
                         gapColumns={this.props.gapColumns}
                         gapRows={this.props.gapRows}>
                    {this.props.children}
                </GridCSS>
            </Wrapper>
        );
    }
}