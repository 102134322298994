import React, {Component} from 'react';
import styled from 'styled-components';
import share from './../assets/share.svg';

import {i18n, i18nConfiguration, i18nRaw, i18nText} from "../common/I18n";
import {masterdata} from "../common/configuration";

import ReactMarkdown from 'react-markdown';
import IconButton from "@material-ui/core/IconButton";
import asset_edit from "../assets/edit.svg";
import placeholder from "../assets/placeholder.svg";

import StyledDialog from "../components/StyledDialog";
import StyledTextField from "../components/StyledTextField";
import StyledTextArea from "../components/StyledTextArea";
import StyledFlatButton from "../components/StyledFlatButton";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Menu from '@material-ui/core/Menu';

import {isNullOrUndefined} from "util";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";


const Share = styled.div`
    padding-left: 16px;
    padding-top: 16px;
    width: 24px;
    height: 24px;
`;

const Wrapper = styled.div`
    padding-left: 10.00%;
    padding-right: 33%;
    @media screen and (max-width: 1100px) {
    padding-right: 10%;

}
    user-select: none;
`;

const TitleLine = styled.div`
    width: 100%;
    display: flex;
`;

const EditTitleLine = styled.div`
    width: 95%;
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    width: fit-content;
    padding-top: 24px;
    opacity: 0.8;
    font-family: TruenoBold;
    font-size: 22px;
    color: #333333;
    letter-spacing: -0.16px;
    line-height: 28px;
    text-transform: uppercase;
`;

const Special = styled.div`
    width: fit-content;
    background-image: linear-gradient(90deg, #D7F2F2 62%, rgba(4, 173, 169, 0.00) 91%);
    /* Special Week: */
    font-family: TruenoRegular;
    font-size: 14px;
    color: #04ADA9;
    letter-spacing: 1.5px;
    text-align: center;
    min-width: 200px;
    line-height: 22px;
    text-transform: uppercase;
    border-radius: 0px 0px 0px 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 16px;
    padding-right: 16px;
`;

const Description = styled.div`
    padding-top: 6px;
    opacity: 0.8;
    font-family: TruenoLight;
    font-size: 18px;
    color: #818181;
    letter-spacing: 0;
    line-height: 28px;
`;

const EditDescription = styled.div`
    width: 95%;
    display: flex;
    flex-direction: column;
`;

const TitleHeader = styled.div`
    width: 95%;
    padding-top: 32px;
    opacity: 0.8;
    font-family: TruenoRegular;
    font-size: 16px;
    color: #04ADA9;
    letter-spacing: 1.5px;
    line-height: 22px;
    text-transform: uppercase;
    clear: left;
`;

const MenuCSS = styled.div`
    padding-top: 32px;
    opacity: 0.8;
    font-family: TruenoLight;
    font-size: 16px;
    color: #818181;
    letter-spacing: 0;
    line-height: 28px;
`;

const EditMenu = styled.div`
    width: 95%;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
`;

const About = styled.div`
    padding-top: 32px;
    opacity: 0.8;
    font-family: TruenoLight;
    font-size: 16px;
    color: #818181;
    letter-spacing: 0;
    line-height: 28px;
`;

const EditAbout = styled.div`
    width: 95%;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
`;

const Ingredients = styled.div`
    padding-top: 32px;
    opacity: 0.8;
    font-family: TruenoLight;
    font-size: 16px;
    color: #818181;
    letter-spacing: 0;
    line-height: 28px;
    clear: left;
`;

const EditIngredients = styled.div`
    width: 95%;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
`;

const GreenHeadline = styled.div`
    opacity: 0.8;
    font-family: TruenoRegular;
    font-size: 16px;
    color: #04ADA9;
    letter-spacing: 1.5px;
    line-height: 22px;
    text-transform: uppercase;
    padding-bottom: 16px;
`;
const Cancellation = styled.div`
    padding-top: 32px;
    opacity: 0.8;
    font-family: TruenoLight;
    font-size: 16px;
    color: #818181;
    letter-spacing: 0;
    line-height: 28px;
    clear:left;
`;


const EditCSS = styled.div`
float:left;
margin-top: 0px;
`;

const PolicyCSS = styled.div`
`;

const PolicyHeaderCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #04ADA9;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
`;

const PolicyBodyCSS = styled.div`
opacity: 0.8;
font-family: TruenoLight;
font-size: 18px;
color: #818181;
letter-spacing: 0;
line-height: 28px;

`;

const Host = styled.img`
    border-radius: 100%;
    width: 68px;
    height: 68px;
    float: left;
    shape-outside: ellipse();
    margin-right: 16px;
    object-fit: cover;
`;


class EditableDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorShareEl: null,
            edit: {title: false, description: false, menu: false, story: false, ingredients: false, policy: false},
            title: props.title,
            description: props.description,
            menu: props.menu,
            story: props.story,
            ingredients: props.ingredients,
            policy: props.policy,
        }


    }

    handleEdit(key) {
        this.setState({edit: {[key]: true}})
    };

    handleCancel(key) {
        this.setState({edit: {[key]: false}, [key]: this.props[key]})
    };

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    handleSave(key) {
        this.props.onSave(key, this.state[key]);
        this.setState({edit: {[key]: false}});
    };

    handlePolicyChange(event) {
        this.setState({policy: event.target.value});
    }

    handleShare = event => {
        this.setState({anchorShareEl: event.currentTarget});
    };

    handleClose = event => {
        this.setState({anchorShareEl: null});
    };

    render() {

        return (
            <Wrapper>


                {(!this.state.edit.title) &&
                <TitleLine>
                    {this.props.editMode &&
                    <EditCSS>
                        <IconButton
                            aria-haspopup="true"
                            color="inherit"
                            onClick={event => this.handleEdit("title")}
                        >
                            <img src={asset_edit}/>
                        </IconButton>
                    </EditCSS>
                    }

                    <Title>
                        {(this.props.title !== null) ? this.props.title : i18nRaw("draft/title/default")}
                    </Title>

                    {!this.props.editMode &&
                    <Share>
                        <IconButton
                            aria-haspopup="true"
                            color="inherit"
                            onClick={this.handleShare}
                        >
                            <img src={share} className="share" alt=""/>
                        </IconButton>
                    </Share>
                    }

                    {!this.props.editMode &&
                    <Menu
                        id="share-menu2"
                        anchorEl={this.state.anchorShareEl}
                        open={Boolean(this.state.anchorShareEl)}
                        onClose={this.handleClose}
                    >
                        <MenuItem>
                            <FacebookShareButton
                                url={this.props.url}
                                quote={this.props.title}
                                hashtag={'#space'}
                            >
                                <FacebookIcon
                                    size={32}
                                    round/>
                            </FacebookShareButton>
                        </MenuItem>

                        <MenuItem>
                            <TwitterShareButton
                                url={this.props.url}
                                title={this.props.title}>
                                <TwitterIcon
                                    size={32}
                                    round/>
                            </TwitterShareButton>
                        </MenuItem>

                        <MenuItem>
                            <TelegramShareButton
                                url={this.props.url}
                                title={this.props.title}>
                                <TelegramIcon size={32} round/>
                            </TelegramShareButton>
                        </MenuItem>

                        <MenuItem>
                            <WhatsappShareButton
                                url={this.props.url}
                                title={this.props.title}
                                separator=" :: ">
                                <WhatsappIcon size={32} round/>
                            </WhatsappShareButton>
                        </MenuItem>

                        <MenuItem>
                            <EmailShareButton
                                url={this.props.url}
                                title={this.props.title}>
                                <EmailIcon
                                    size={32}
                                    round/>
                            </EmailShareButton>
                        </MenuItem>
                    </Menu>
                    }


                </TitleLine>
                }


                {(this.state.edit.title) &&
                <EditTitleLine>
                    <StyledTextField
                        label={i18n("event/edit/title")}
                        name="title"
                        value={this.state.title}
                        width={"100%"}
                        onChange={this.handleChange.bind(this)}
                        required={false}/>
                    <StyledFlatButton
                        label={i18n("event/edit/save")}
                        name={"title"}
                        disabled={this.state.title === null || this.state.title.length < 1}
                        onClick={this.handleSave.bind(this)}/>
                    <StyledFlatButton label={i18n("event/edit/cancel")} name={"title"}
                                      onClick={this.handleCancel.bind(this)}/>

                </EditTitleLine>
                }


                {!isNullOrUndefined(this.props.tag) &&
                <Special>
                    <span style={{opacity: 1}}>{i18nText(this.props.tag.title)}</span>
                </Special>
                }


                {((!this.state.edit.description && this.props.editMode) || (this.props.description !== null && !this.props.editMode)) &&
                <Description>
                    {this.props.editMode &&
                    <EditCSS>
                        <IconButton
                            aria-haspopup="true"
                            color="inherit"
                            onClick={event => this.handleEdit("description")}
                        >
                            <img src={asset_edit}/>
                        </IconButton>
                    </EditCSS>
                    }
                    <ReactMarkdown
                        source={this.props.description !== null ? this.props.description : i18nRaw("draft/description/default")}/>

                </Description>
                }

                {(this.state.edit.description) &&
                <EditDescription>
                    <StyledTextArea
                        label={i18n("event/edit/description")}
                        name="description"
                        value={this.state.description}
                        width={"100%"}
                        onChange={this.handleChange.bind(this)}
                        required={false}/>
                    <StyledFlatButton
                        label={i18n("event/edit/save")}
                        name={"description"}
                        disabled={this.state.description === null || this.state.description.trim().length < 1}
                        onClick={this.handleSave.bind(this)}/>
                    <StyledFlatButton
                        label={i18n("event/edit/cancel")} name={"description"}
                        onClick={this.handleCancel.bind(this)}/>

                </EditDescription>
                }

                <TitleHeader>
                    {i18n('details/menu')}
                </TitleHeader>

                {(!this.state.edit.menu) &&

                <MenuCSS>
                    {this.props.editMode &&


                    <EditCSS>
                        <IconButton
                            aria-haspopup="true"
                            color="inherit"
                            onClick={event => this.handleEdit("menu")}
                        >
                            <img src={asset_edit}/>
                        </IconButton>
                    </EditCSS>
                    }

                    <ReactMarkdown source={this.props.menu ? this.props.menu : i18nRaw("draft/menu/default")}/>

                </MenuCSS>
                }

                {(this.state.edit.menu) &&

                <EditMenu>

                    <StyledTextArea
                        label={i18n("event/edit/menu")}
                        name="menu"
                        value={this.state.menu}
                        width={"100%"}
                        onChange={this.handleChange.bind(this)}
                        required={false}/>
                    <StyledFlatButton label={i18n("event/edit/save")}
                                      name={"menu"}
                                      disabled={this.state.menu === null || this.state.menu.length < 1}
                                      onClick={this.handleSave.bind(this)}/>
                    <StyledFlatButton label={i18n("event/edit/cancel")} name={"menu"}
                                      onClick={this.handleCancel.bind(this)}/>

                </EditMenu>
                }

                <TitleHeader>
                    {i18n('details/host', {name: this.props.firstname})}
                </TitleHeader>

                {(!this.state.edit.story) &&

                <About>
                    {this.props.editMode &&

                    <EditCSS>
                        <IconButton
                            aria-haspopup="true"
                            color="inherit"
                            onClick={event => this.handleEdit("story")}
                        >
                            <img src={asset_edit}/>
                        </IconButton>
                    </EditCSS>
                    }

                    {!this.props.editMode &&
                    <Host src={this.props.hostImage ? this.props.hostImage : placeholder} className="hostimage" alt=""/>
                    }

                    <ReactMarkdown
                        source={this.props.story ? this.props.story : i18nRaw("draft/story/default", {firstname: this.props.firstname})}/>

                </About>
                }

                {(this.state.edit.story) &&

                <EditAbout>

                    <StyledTextArea
                        label={i18n("event/edit/story")}
                        name="story"
                        value={this.state.story}
                        width={"100%"}
                        onChange={this.handleChange.bind(this)}
                        required={false}/>
                    <StyledFlatButton
                        label={i18n("event/edit/save")}
                        name={"story"}
                        disabled={this.state.story === null || this.state.story.length < 1}
                        onClick={this.handleSave.bind(this)}/>
                    <StyledFlatButton label={i18n("event/edit/cancel")} name={"story"}
                                      onClick={this.handleCancel.bind(this)}/>

                </EditAbout>
                }

                {((!this.state.edit.ingredients && this.props.editMode) || (this.props.ingredients !== null && !this.props.editMode)) &&
                <Ingredients>

                    {this.props.editMode &&


                    <EditCSS>
                        <IconButton
                            aria-haspopup="true"
                            color="inherit"
                            onClick={event => this.handleEdit("ingredients")}
                        >
                            <img src={asset_edit}/>
                        </IconButton>
                    </EditCSS>
                    }

                    <ReactMarkdown
                        source={this.props.ingredients ? this.props.ingredients : i18nRaw("draft/ingredients/default")}/>
                </Ingredients>
                }

                {this.state.edit.ingredients &&
                <EditIngredients>

                    <StyledTextArea
                        label={i18n("event/edit/ingredients")}
                        name="ingredients"
                        value={this.state.ingredients}
                        width={"100%"}
                        onChange={this.handleChange.bind(this)}
                        required={false}/>
                    <StyledFlatButton
                        label={i18n("event/edit/save")}
                        name={"ingredients"}
                        disabled={this.state.ingredients === null || this.state.ingredients.trim().length < 1}
                        onClick={this.handleSave.bind(this)}/>
                    <StyledFlatButton label={i18n("event/edit/cancel")} name={"ingredients"}
                                      onClick={this.handleCancel.bind(this)}/>

                </EditIngredients>
                }

                <Cancellation>
                    <GreenHeadline>{i18n('details/cancellation/title')}</GreenHeadline>

                    {this.props.editMode &&
                    <EditCSS>
                        <IconButton
                            aria-haspopup="true"
                            color="inherit"
                            onClick={event => this.handleEdit("policy")}

                        >
                            <img src={asset_edit}/>
                        </IconButton>
                    </EditCSS>
                    }

                    {i18n("details/cancellation/host/" + this.props.policy, {time: i18nConfiguration("time", this.props.time)})}
                </Cancellation>

                <StyledDialog
                    open={this.state.edit.policy}
                    title={i18n("details/edit/policy/title")}
                    text={i18n("details/edit/policy/text")}
                    name={"policy"}
                    onCancel={this.handleCancel.bind(this)}
                    onSave={this.handleSave.bind(this)}
                    control={
                        <RadioGroup
                            value={this.state.policy}
                            onChange={this.handlePolicyChange.bind(this)}
                            name={"policy"}
                        >

                            {masterdata.policies.map((value, index) => (
                                <FormControlLabel key={index}
                                                  value={value}
                                                  control={<Radio color="primary"/>}
                                                  label={
                                                      <PolicyCSS>
                                                          <PolicyHeaderCSS>{i18nConfiguration("policy", value)}</PolicyHeaderCSS>
                                                          <PolicyBodyCSS>
                                                              {i18nConfiguration("policyDescription", value, {time: i18nConfiguration("time", this.props.time)})}
                                                          </PolicyBodyCSS>
                                                      </PolicyCSS>
                                                  }/>
                            ))}

                        </RadioGroup>
                    }
                />


            </Wrapper>


        );
    }
}

export default EditableDetails;
