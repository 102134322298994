import React from 'react';
import PropTypes from 'prop-types';
import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import styled from 'styled-components';
import {isNullOrUndefined} from "util";


const TextFieldContainer = styled.div`
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: none;
    font-family: TruenoLight;
    font-size: 8.4px;
    letter-spacing: 0;
    width: ${props => props.width ? props.width : "80%"};
    height: ${props => props.height ? props.height : "100%"};
    padding-top: 16px;
`;


const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
    },

    typography: {
        useNextVariants: true,
    },

    overrides: {
        typography: {
            fontFamily:
                'TruenoLight',

        },

        MuiPrivateTextarea: {
            root: {
                height: "100%",
            },
        },

        MuiInputLabel: {
            root: {
                color: "#818181",
                textTransform: 'uppercase',
                fontSize: '14px',
                fontFamily: 'TruenoRegular',
            }
        },
        MuiInput: {
            root: {
                textTransform: 'uppercase',
                fontSize: '14px',
                paddingTop: 8,
                paddingBottom: 8,
                letterSpacing: 1.5,
                fontFamily: 'TruenoLight'
            },
        },
        MuiFormHelperText: {
            root: {
                fontFamily: 'TruenoLight',
                letterSpacing: 1.5,
            },

            error: {
                color: "#FF6344"
            }
        },
    }
});


class P23TextField extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (


            <MuiThemeProvider theme={theme}>
                <TextFieldContainer width={this.props.width} height={this.props.height}>

                    <TextField

                        autoFocus={this.props.autoFocus === true}
                        id={this.props.id ? this.props.id : this.props.name}
                        name={this.props.name}
                        label={this.props.label}
                        fullWidth={true}
                        required={this.props.required}
                        multiline={this.props.multiline === true}
                        rows={this.props.rows ? this.props.rows : 1}
                        rowsMax={this.props.rows ? this.props.rows : 1}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        type={this.props.type === "password" ? "password" : "text"}
                        error={!isNullOrUndefined(this.props.error)}
                        helperText={this.props.error}
                        disabled={this.props.disabled}

                    />
                </TextFieldContainer>


            </MuiThemeProvider>
        );
    }
}

export default (P23TextField);