import firebaseApp from "./firebase";
import {isNullOrUndefined} from "util";

const firestore = firebaseApp.firestore();
const storage = firebaseApp.storage();


export let masterdata = {
    cuisines: [],
    languages: [],
    specials: [],
    allergens: [],
    courtesies: [],
    types: [],
    times: [],
    policies: [],
    reportReasons: [],
    mobilePrefixCountries: [],
};

export let fees = {};

export let countries = {};

export let tags = [{label: "none", active: true, priority:999999999}];

export let languagePacks = {
    de_DE: new Map(),
    en_GB: new Map(),
};

let initialized = false;

export async function initialize() {

    if (initialized) {
        return initialized;
    }

    const fsMasterdata = await firestore.collection("masterdata").get();

    for (const fsData of fsMasterdata.docs) {
        masterdata[fsData.id] = fsData.data().values;
    }

    const fsFees = await firestore.collection("fees").get();

    for (const fsFee of fsFees.docs) {
        fees[fsFee.id] = {...fsFee.data()};
    }

    const fsCountries = await firestore.collection("countries").get();

    for (const fsCountry of fsCountries.docs) {
        countries[fsCountry.id] = {...fsCountry.data()};
    }

    const fsTags = await firestore.collection("tags").get();

    for (const fsTag of fsTags.docs) {
        tags.push({...fsTag.data()});
    }

    tags = tags.filter(tag => tag.active).sort((a,b) => b.priority - a.priority);

    const fsLocalizations = await firestore.collection("localization").get();

    for (const fsLocalization of fsLocalizations.docs) {
        languagePacks.de_DE.set(fsLocalization.data().key, fsLocalization.data().de_DE);
        if (!isNullOrUndefined(fsLocalization.data().en_GB)) {
            languagePacks.en_GB.set(fsLocalization.data().key, fsLocalization.data().en_GB)
        }
    }


    initialized = true;

    return initialized;
}

