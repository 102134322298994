import React, {Component} from 'react';

import styled from 'styled-components';
import Swipeable from 'react-swipeable'
import {throttle} from 'lodash'

import asset_left from "../assets/left.svg"
import asset_right from "../assets/right.svg"
import ReactDOM from "react-dom";
import dot from "../assets/dot.svg";
import Slider from "react-slick/lib";
import {uuidv4} from "../common/utils";

const Slide = styled.div`
padding-left: 5px;
padding-right: 5px;
`;

const WrapperCSS = styled.div`
margin-top:24px;
margin-bottom:24px;
display: block;
width:${props => props.width ? props.width : "100%"};
margin-left: auto;
margin-right: auto;
`;

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img
            className={className}
            src={asset_right}
            style={{ ...style}}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img
            className={className}
            src={asset_left}
            style={{ ...style}}
            onClick={onClick}
        />
    );
}

class Carousel extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        const settings = {
            customPaging: function (i) {
                return (
                    <a>
                        <img src={dot} alt="*"/>
                    </a>
                );
            },
            className: "testimonialSlider",
            centerMode: false,
            autoplay: this.props.autoplay ? this.props.autoplay : false,
            infinite: this.props.children.length > (this.props.slidesToShow ? this.props.slidesToShow : 4),
            slidesToShow: this.props.slidesToShow ? this.props.slidesToShow : 4,
            slidesToScroll: this.props.slidesToShow ? this.props.slidesToShow : 4,
            swipe: true,
            speed: 500,
            dots: this.props.children.length < ((this.props.slidesToShow ? this.props.slidesToShow : 4) * 10),
            initialSlide: 0,
            afterChange: this.props.onChange ? this.props.onChange : null,
            focusOnSelect: false,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                {
                    breakpoint: 1260,
                    settings: {
                        slidesToShow: this.props.slidesToShowL ? this.props.slidesToShowL : 3,
                        slidesToScroll: this.props.slidesToShowL ? this.props.slidesToShowL : 3,
                        infinite: this.props.children.length > (this.props.slidesToShowL ? this.props.slidesToShowL : 3),
                        dots:this.props.children.length < ((this.props.slidesToShowL ? this.props.slidesToShowL : 3) * 10)
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: this.props.slidesToShowM ? this.props.slidesToShowM : 2,
                        slidesToScroll: this.props.slidesToShowM ? this.props.slidesToShowM : 2,
                        infinite: this.props.children.length > (this.props.slidesToShowM ? this.props.slidesToShowM : 2),
                        dots:this.props.children.length < ((this.props.slidesToShowM ? this.props.slidesToShowM : 2) * 10)
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: this.props.slidesToShowS ? this.props.slidesToShowS : 1,
                        slidesToScroll: this.props.slidesToShowS ? this.props.slidesToShowS : 1,
                        infinite: this.props.children.length > (this.props.slidesToShowS ? this.props.slidesToShowS : 1),
                        dots:this.props.children.length < ((this.props.slidesToShowS ? this.props.slidesToShowS : 1) * 10)
                    }
                }
            ]

        };

        return (

            <WrapperCSS width={this.props.width}>
                <Slider {...settings}>
                    {this.props.children.map(child => {
                            return (<div key={uuidv4()}>
                                <Slide>
                                    {child}
                                </Slide>
                            </div>)

                        }
                    )}
                </Slider>
            </WrapperCSS>

        );
    }
}

export default Carousel;
