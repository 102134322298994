import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {MuiThemeProvider, createMuiTheme, withTheme} from '@material-ui/core/styles';
import Divider from "@material-ui/core/Divider/Divider";
import {components} from "./consts";
import DrawerMenu from "./DrawerMenu";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Dialog from "@material-ui/core/Dialog/Dialog";
import * as firebase from "firebase";
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import MoreIcon from '@material-ui/icons/MoreVert';
import firebaseApp, {trackUserPresence} from "../common/firebase";
import {isNullOrUndefined} from "util";
import {Redirect} from "react-router";
import {i18nChange} from "../common/I18n";
import Timer from "../components/Timer";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";

const firestore = firebaseApp.firestore();


const drawerWidth = 240;

const styles = theme => ({
    typography: {
        useNextVariants: true,
    },
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        height:"100vh",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: '100vh',
        overflow: 'auto',
    },
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        height: 320,
    },
    h5: {
        marginBottom: theme.spacing.unit * 2,
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    button: {
        margin: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
});

const AdminContext = React.createContext();


class Admin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            open: true,
            page: components.dashboard,
            loggingIn: false,
            failed: false,
            profile: null,
            anchorEl: null,
            mobileMoreAnchorEl: null,

        };
    }

    componentDidMount() {
        firebaseApp.auth().onAuthStateChanged( async (user) => {

            if (user) {
                try {

                    const fsProfile = await firestore.collection('admins').doc(user.uid).get();

                    if (!fsProfile.exists) {
                        this.setState({loggingIn: false, failed: true, username: '', password: ''});
                    } else {
                        this.setState({loggingIn: false, failed: false, profile: fsProfile.data(), username: '', password: ''});
                    }

                } catch (error) {
                    console.error(error);
                    this.setState({loggingIn: false, failed: true, username: '', password: ''});
                }
            }

        });
    }

    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    handleLogin = () => {
        firebaseApp.auth().signInWithEmailAndPassword(this.state.username, this.state.password).catch((error) => {
            this.setState({failed: true, loggingIn: false, username: '', password: ''});
        })
    };

    handleLogout = () => {

        firebaseApp.auth().signOut().then(() => {
            this.setState({loggingIn: false, failed: false,  mobileMoreAnchorEl: null, anchorEl: null});
        });

    };

    handleChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };


    handleDrawerOpen = () => {
        this.setState({open: true});
    };

    handleDrawerClose = () => {
        this.setState({open: false});
    };

    handleMenuChange = (component) => {
        this.setState({page: component});
    };


    handleChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    render() {
        const {classes} = this.props;

        const {loggingIn, failed} = this.state;

        const isAuth = !isNullOrUndefined(firebaseApp.auth().currentUser);
        const isAdmin = isAuth && !isNullOrUndefined(this.state.profile);

        const { anchorEl, mobileMoreAnchorEl } = this.state;
        const isMenuOpen = Boolean(anchorEl);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
            </Menu>
        );

        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                <MenuItem onClick={this.handleProfileMenuOpen}>
                    <IconButton color="inherit">
                        <AccountCircle />
                    </IconButton>
                    <p>Profile</p>
                </MenuItem>
            </Menu>
        );

        return (
            <React.Fragment>

                {isAuth && !isAdmin &&
                <Redirect push to={`/`}/>
                }

                {isAuth && isAdmin &&
                <AdminContext.Provider
                    value={{
                        drawerOpen: this.state.open,
                        page: this.state.page,
                        onMenuChange: this.handleMenuChange,
                        profile: this.state.profile,
                        firestore: firestore,
                    }}
                >
                    <CssBaseline/>
                    <div className={classes.root}>
                        <AppBar
                            position="fixed"
                            className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
                        >
                            <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
                                <IconButton
                                    color="inherit"
                                    aria-label="Open drawer"
                                    onClick={this.handleDrawerOpen}
                                    className={classNames(
                                        classes.menuButton,
                                        this.state.open && classes.menuButtonHidden,
                                    )}
                                >
                                    <MenuIcon/>
                                </IconButton>
                                <Typography
                                    variant="h6"
                                    color="inherit"
                                    gutterBottom
                                    className={classes.title}
                                >
                                    {this.state.page.title}
                                </Typography>
                                <div className={classes.sectionDesktop}>
                                    <Button
                                        aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                                        aria-haspopup="true"
                                        className={classes.button}
                                        onClick={this.handleProfileMenuOpen}
                                        color="inherit"
                                    >
                                        {firebaseApp.auth().currentUser.email}
                                        <AccountCircle className={classes.rightIcon} />
                                    </Button>
                                </div>
                                <div className={classes.sectionMobile}>
                                    <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                                        <MoreIcon />
                                    </IconButton>
                                </div>
                            </Toolbar>
                        </AppBar>
                        {renderMenu}
                        {renderMobileMenu}
                        <Drawer
                            variant="persistent"
                            classes={{
                                paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                            }}
                            open={this.state.open}
                        >
                            <div className={classes.toolbarIcon}>
                                <IconButton onClick={this.handleDrawerClose}>
                                    <ChevronLeftIcon/>
                                </IconButton>
                            </div>
                            <Divider/>
                            <List><DrawerMenu/></List>
                        </Drawer>
                        <main className={classes.content}>
                            <div className={classes.appBarSpacer}/>
                            {this.state.page.content}
                        </main>
                    </div>
                </AdminContext.Provider>
                }

                {!isAuth &&
                <Timer color={"#2196f3"} time={2}>

                    <Dialog
                        open={!isAuth}
                        aria-labelledby="form-dialog-title"
                    >
                        {failed &&
                        <div>
                            <DialogTitle id="form-dialog-title">Login Failed</DialogTitle>
                            <DialogContent>


                                <DialogContentText>
                                    Your login failed. Double check username and password.
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="username"
                                    name="username"
                                    label="Username"
                                    type="text"
                                    onChange={this.handleChange}
                                    error
                                    fullWidth
                                />
                                <TextField
                                    margin="dense"
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    onChange={this.handleChange}
                                    error
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>

                                <Button onClick={this.handleLogin} color="primary">
                                    Try Again to Login
                                </Button>
                            </DialogActions>
                        </div>
                        }

                        {loggingIn &&
                        <div>
                            <DialogContent>
                                <CircularProgress color="secondary"/>
                            </DialogContent>

                        </div>
                        }

                        {(!failed && !loggingIn) &&
                        <div>
                            <DialogTitle id="form-dialog-title">Login</DialogTitle>
                            <DialogContent>


                                <DialogContentText>
                                    Use your admin username and password to access admin section
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="username"
                                    name="username"
                                    label="Username"
                                    type="text"
                                    onChange={this.handleChange}
                                    fullWidth
                                />
                                <TextField
                                    margin="dense"
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    onChange={this.handleChange}
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>

                                <Button onClick={this.handleLogin} color="primary">
                                    Login
                                </Button>
                            </DialogActions>
                        </div>
                        }


                    </Dialog>
                </Timer>
                }
            </React.Fragment>
        );
    }
}

Admin.propTypes = {
    classes: PropTypes.object.isRequired,
};

export const AdminContextConsumer = AdminContext.Consumer;


export default withStyles(styles, {withTheme: true})(Admin)