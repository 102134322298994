import React, {PureComponent} from 'react';

import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';

import MomentUtils from '@date-io/moment';
import { BasePicker, MuiPickersUtilsProvider, Calendar } from "material-ui-pickers";

import styled from 'styled-components';

const PickerCSS = styled.div`
    overflow: hidden;
    user-select: none;
`;


const materialTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiTypography: {
            body1: {
                fontFamily:
                    "TruenoRegular",
                textTransform: "uppercase",
                color: '#04ADA9',
            },
            caption: {
                fontFamily:
                    "TruenoLight"
            }
        },
        MuiIconButton: {
            label: {
                fontFamily:
                    "TruenoLight"
            }
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#04ADA9',
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                // backgroundColor: lightBlue.A200,
                // color: 'white',
            },
        },
        MuiPickersDay: {

            day: {
                color: '#000000',
            },
            isSelected: {
                backgroundColor: '#FF6344',
                '&:hover': {
                    backgroundColor: '#FF6344',
                },
            },
            current: {
                color: '#04ADA9',
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: '#04ADA9',
            },
        },
    },
});


export default class StyledDatePicker extends React.Component {

    render() {

        return (

            <MuiPickersUtilsProvider utils={MomentUtils}>
                <MuiThemeProvider theme={materialTheme}>
                    <BasePicker value={this.props.date} onChange={this.props.onChange}>
                        {
                            ({
                                 date,
                                 handleChange,
                             }) => (
                                <div>
                                    <PickerCSS>
                                        <Calendar date={date} onChange={this.props.onChange} disablePast={true} minDate={'2018-10-15'}/>
                                    </PickerCSS>
                                </div>
                            )
                        }
                    </BasePicker>
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        );
    }
}

