import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ContactsIcon from '@material-ui/icons/Contacts';
import MailIcon from '@material-ui/icons/Mail';
import EventNoteIcon from '@material-ui/icons/EventNote';
import EventIcon from '@material-ui/icons/Event';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import CopyrightIcon from '@material-ui/icons/Copyright';
import FlagIcon from '@material-ui/icons/Flag';
import NoteIcon from '@material-ui/icons/Note';
import RedeemIcon from '@material-ui/icons/Redeem';
import LabelIcon from '@material-ui/icons/Label';
import MapIcon from '@material-ui/icons/Map';

import UpdateIcon from '@material-ui/icons/Update';
import MobileIcon from '@material-ui/icons/PhoneAndroid';
import ReportIcon from '@material-ui/icons/BugReport';
import NotesIcon from '@material-ui/icons/Notes';
import SmokeFreeIcon from '@material-ui/icons/SmokeFree';
import AllergensIcon from '@material-ui/icons/LocalPharmacy';
import SpecialsIcon from '@material-ui/icons/LocalPlay';
import ForumIcon from '@material-ui/icons/Forum';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import EuroIcon from '@material-ui/icons/EuroSymbol';
import LocationIcon from '@material-ui/icons/LocationCity';
import MuteIcon from '@material-ui/icons/VolumeOff';
import ImageIcon from '@material-ui/icons/BrokenImage';
import WeightIcon from '@material-ui/icons/LineWeight';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import CCIcon from "@material-ui/icons/CreditCard";


import CommentIcon from '@material-ui/icons/Comment';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import BookIcon from '@material-ui/icons/Book';
import LanguageIcon from '@material-ui/icons/Language';
import {components} from "./consts";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';
import Divider from "@material-ui/core/Divider/Divider";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";


const styles = theme => ({});

class DrawerMenu extends React.Component {
    state = {};


    render() {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page, onMenuChange, drawerOpen, profile}) => (
                        <div>
                            <ListItem button onClick={(e) => onMenuChange(components.dashboard)}
                                      selected={page === components.dashboard}>
                                <Tooltip title={components.dashboard.tooltip}>
                                    <ListItemIcon>
                                        <DashboardIcon/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary={components.dashboard.menu}/>
                            </ListItem>

                            {drawerOpen ? (
                                <ListSubheader disableSticky={true}>Interaction</ListSubheader>
                            ) : (
                                <Divider/>
                            )}

                            <ListItem button onClick={(e) => onMenuChange(components.subscribers)}
                                      selected={page === components.subscribers}>
                                <Tooltip title={components.subscribers.tooltip}>
                                    <ListItemIcon>
                                        <ContactsIcon/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary={components.subscribers.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.feedback)}
                                      selected={page === components.feedback}>
                                <Tooltip title={components.feedback.tooltip}>
                                    <ListItemIcon>
                                        <MailIcon/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary={components.feedback.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.scorings)}
                                      selected={page === components.scorings}>
                                <Tooltip title={components.scorings.tooltip}>
                                    <ListItemIcon>
                                        <StarHalfIcon/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary={components.scorings.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.testimonials)}
                                      selected={page === components.testimonials}>
                                <Tooltip title={components.testimonials.tooltip}>
                                    <ListItemIcon>
                                        <ThumbUpIcon/>
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary={components.testimonials.menu}/>
                            </ListItem>
                            {drawerOpen ? (
                                <ListSubheader disableSticky={true}>Transactions</ListSubheader>
                            ) : (
                                <Divider/>
                            )}
                            <ListItem button onClick={(e) => onMenuChange(components.users)}
                                      selected={page === components.users}>
                                <Tooltip title={components.users.tooltip}>
                                    <ListItemIcon>
                                        <PeopleIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.users.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.drafts)}
                                      selected={page === components.drafts}>
                                <Tooltip title={components.drafts.tooltip}>
                                    <ListItemIcon>
                                        <EventNoteIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.drafts.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.events)}
                                      selected={page === components.events}>
                                <Tooltip title={components.events.tooltip}>
                                    <ListItemIcon>
                                        <EventIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.events.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.bookings)}
                                      selected={page === components.bookings}>
                                <Tooltip title={components.bookings.tooltip}>
                                    <ListItemIcon>
                                        <BookIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.bookings.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.payments)}
                                      selected={page === components.payments}>
                                <Tooltip title={components.payments.tooltip}>
                                    <ListItemIcon>
                                        <CCIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.payments.menu}/>
                            </ListItem>

                            {drawerOpen ? (
                                <ListSubheader disableSticky={true}>Content</ListSubheader>
                            ) : (
                                <Divider/>
                            )}
                            <ListItem button onClick={(e) => onMenuChange(components.tweets)}
                                      selected={page === components.tweets}>
                                <Tooltip title={components.tweets.tooltip}>
                                    <ListItemIcon>
                                        <NoteIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.tweets.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.news)}
                                      selected={page === components.news}>
                                <Tooltip title={components.news.tooltip}>
                                    <ListItemIcon>
                                        <NoteIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.news.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.images)}
                                      selected={page === components.images}>
                                <Tooltip title={components.images.tooltip}>
                                    <ListItemIcon>
                                        <NoteIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.images.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.quotes)}
                                      selected={page === components.quotes}>
                                <Tooltip title={components.quotes.tooltip}>
                                    <ListItemIcon>
                                        <NoteIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.quotes.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.vouchers)}
                                      selected={page === components.vouchers}>
                                <Tooltip title={components.vouchers.tooltip}>
                                    <ListItemIcon>
                                        <RedeemIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.vouchers.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.tags)}
                                      selected={page === components.tags}>
                                <Tooltip title={components.tags.tooltip}>
                                    <ListItemIcon>
                                        <LabelIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.tags.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.localization)}
                                      selected={page === components.localization}>
                                <Tooltip title={components.localization.tooltip}>
                                    <ListItemIcon>
                                        <LanguageIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.localization.menu}/>
                            </ListItem>

                            <ListItem button onClick={(e) => onMenuChange(components.countries)}
                                      selected={page === components.countries}>
                                <Tooltip title={components.countries.tooltip}>
                                    <ListItemIcon>
                                        <MapIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.countries.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.cities)}
                                      selected={page === components.cities}>
                                <Tooltip title={components.cities.tooltip}>
                                    <ListItemIcon>
                                        <LocationIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.cities.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.notifications)}
                                      selected={page === components.notifications}>
                                <Tooltip title={components.notifications.tooltip}>
                                    <ListItemIcon>
                                        <CommentIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.notifications.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.fees)}
                                      selected={page === components.fees}>
                                <Tooltip title={components.fees.tooltip}>
                                    <ListItemIcon>
                                        <EuroIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.fees.menu}/>
                            </ListItem>

                            {drawerOpen ? (
                                <ListSubheader disableSticky={true}>Compliance</ListSubheader>
                            ) : (
                                <Divider/>
                            )}
                            <ListItem button onClick={(e) => onMenuChange(components.reportings)}
                                      selected={page === components.reportings}>
                                <Tooltip title={components.reportings.tooltip}>
                                    <ListItemIcon>
                                        <FlagIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.reportings.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.muted)}
                                      selected={page === components.muted}>
                                <Tooltip title={components.muted.tooltip}>
                                    <ListItemIcon>
                                        <MuteIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.muted.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.unwantedimages)}
                                      selected={page === components.unwantedimages}>
                                <Tooltip title={components.unwantedimages.tooltip}>
                                    <ListItemIcon>
                                        <ImageIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.unwantedimages.menu}/>
                            </ListItem>


                            {drawerOpen ? (
                                <ListSubheader disableSticky={true}>Masterdata</ListSubheader>
                            ) : (
                                <Divider/>
                            )}
                            <ListItem button onClick={(e) => onMenuChange(components.cuisines)}
                                      selected={page === components.cuisines}>
                                <Tooltip title={components.cuisines.tooltip}>
                                    <ListItemIcon>
                                        <RoomServiceIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.cuisines.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.languages)}
                                      selected={page === components.languages}>
                                <Tooltip title={components.languages.tooltip}>
                                    <ListItemIcon>
                                        <ForumIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.languages.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.specials)}
                                      selected={page === components.specials}>
                                <Tooltip title={components.specials.tooltip}>
                                    <ListItemIcon>
                                        <SpecialsIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.specials.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.allergens)}
                                      selected={page === components.allergens}>
                                <Tooltip title={components.allergens.tooltip}>
                                    <ListItemIcon>
                                        <AllergensIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.allergens.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.courtesies)}
                                      selected={page === components.courtesies}>
                                <Tooltip title={components.courtesies.tooltip}>
                                    <ListItemIcon>
                                        <SmokeFreeIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.courtesies.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.types)}
                                      selected={page === components.types}>
                                <Tooltip title={components.types.tooltip}>
                                    <ListItemIcon>
                                        <BookmarksIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.types.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.policies)}
                                      selected={page === components.policies}>
                                <Tooltip title={components.policies.tooltip}>
                                    <ListItemIcon>
                                        <NotesIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.policies.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.reportReasons)}
                                      selected={page === components.reportReasons}>
                                <Tooltip title={components.reportReasons.tooltip}>
                                    <ListItemIcon>
                                        <ReportIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.reportReasons.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.mobilePrefixCountries)}
                                      selected={page === components.mobilePrefixCountries}>
                                <Tooltip title={components.mobilePrefixCountries.tooltip}>
                                    <ListItemIcon>
                                        <MobileIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.mobilePrefixCountries.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.times)}
                                      selected={page === components.times}>
                                <Tooltip title={components.times.tooltip}>
                                    <ListItemIcon>
                                        <UpdateIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.times.menu}/>
                            </ListItem>
                            <ListItem button onClick={(e) => onMenuChange(components.scoreWeights)}
                                      selected={page === components.scoreWeights}>
                                <Tooltip title={components.scoreWeights.tooltip}>
                                    <ListItemIcon>
                                        <WeightIcon/>
                                    </ListItemIcon>
                                </Tooltip>

                                <ListItemText primary={components.scoreWeights.menu}/>
                            </ListItem>

                            {profile.super === true &&
                            <React.Fragment>
                                {drawerOpen ? (
                                    <ListSubheader disableSticky={true}>Organization</ListSubheader>
                                ) : (
                                    <Divider/>
                                )}
                                <ListItem button onClick={(e) => onMenuChange(components.admins)}
                                          selected={page === components.admins}>
                                    <Tooltip title={components.admins.tooltip}>
                                        <ListItemIcon>
                                            <FolderSharedIcon/>
                                        </ListItemIcon>
                                    </Tooltip>

                                    <ListItemText primary={components.admins.menu}/>
                                </ListItem>
                            </React.Fragment>
                            }
                        </div>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

DrawerMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles, {withTheme: true})(DrawerMenu)