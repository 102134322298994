import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import KeyboardVoiceICon from '@material-ui/icons/KeyboardVoice';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
        position: 'fixed',
        bottom: theme.spacing.unit * 3,
        left: theme.spacing.unit * 2,
        zIndex: 100,
        textTransform: 'uppercase',
        fontSize: '18px',
        letterSpacing: 1.5,
        fontFamily:
            'TruenoLight',
        color:"#ffffff"


    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
});

const theme2 = createMuiTheme({

    palette: {
        primary: {
            main: "#FF6344",
            light: "#FF6344"
        },
    },
    typography: {
        useNextVariants: true,
    },
});

function StyledFloatingButton(props) {
    const {classes} = props;
    return (

        <MuiThemeProvider theme={theme2}>

            <Button variant="contained" color="primary" className={classes.button} href={props.href}>
                {props.text}
                <CheckCircle className={classes.rightIcon}/>
            </Button>
        </MuiThemeProvider>

    );
}

StyledFloatingButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StyledFloatingButton);