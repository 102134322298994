import React from 'react';

import styled from 'styled-components';



const Wrapper = styled.div`
`;

const ButtonCSS = styled.div`
    width:100%;
    min-width: 200px;
background: none;
border-radius: 8px;
font-family: TruenoRegular;
font-size: 14px;
color: ${props => props.grey ? "#a9a9a9" : "#FF6344"};
letter-spacing: 1.5px;
text-align: center;
margin-top:24px;
height:20px;
margin-bottom:10px;
text-transform: uppercase;
user-select: none;
transition: box-shadow 0.5s;
&:hover {
cursor: pointer;
font-family: TruenoBold;
}
&:active {
box-shadow: none;
cursor: pointer;
font-family: TruenoRegular;
}
`;

const ButtonCSSDisabled = styled.div`
    width:100%;
        min-width: 200px;

background: none;
border-radius: 8px;
font-family: TruenoRegular;
font-size: 14px;
color: #eff0f1;
letter-spacing: 1.5px;
text-align: center;
line-height: 50px;
min-height: 50px;
text-transform: uppercase;
user-select: none;
`;


class StyledFlatButton extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (

            <div style={{width: '100%'}}>
                {(this.props.disabled) &&

                <ButtonCSSDisabled>
                    {this.props.label}
                </ButtonCSSDisabled>
                }

                {!(this.props.disabled) &&

                <ButtonCSS grey={this.props.grey} onClick={event => this.props.onClick(this.props.name)}>
                    {this.props.label}
                </ButtonCSS>
                }
            </div>



        )
    }
}

export default (StyledFlatButton);