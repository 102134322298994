import React, {Component} from 'react';
import Gallery from 'react-photo-gallery';
import styled from "styled-components";

import {i18nText, i18n} from "../common/I18n";
import CustomPhoto from "./CustomPhoto";


const Wrapper = styled.div`
    padding-left: 10%;
    padding-right: 33%;
    margin-top: 56px;
    padding-bottom: 24px;
    background-color: rgba(239, 178, 85, 0.03);
        @media screen and (max-width: 1100px) {
    padding-right: 10%;

}
`;


const Title = styled.div`
    opacity: 0.8;
    font-family: TruenoRegular;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    text-transform: uppercase;
    padding-top: 24px;
    padding-bottom: 24px;
`;



class P23Gallery extends Component {

    constructor(props) {
        super(props);


    }

    generatePhotos(images) {

        const ratios = [[4, 3], [1, 1], [3, 4]];
        return images.map((image, index) => {
            return {
                src: image,
                sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
                width: ratios[index % ratios.length][0],
                height: ratios[index % ratios.length][1],
            }

        });
    }

    render() {


        return (
            <Wrapper>

                <Title>{i18n('gallery/title')}</Title>
                <Gallery
                    photos={this.generatePhotos(this.props.images)}
                    margin={8}
                    renderImage={CustomPhoto}
                />


            </Wrapper>

        );
    }
}

export default P23Gallery;
