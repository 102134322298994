import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';
import MUIDataTable from "mui-datatables";
import firebaseApp from "../common/firebase";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";
import {isNullOrUndefined} from "util";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button/Button";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import RefreshIcon from "@material-ui/icons/Refresh";


const firestore = firebaseApp.firestore();


const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
});

class Notifications extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            working: false,
            values: [],
            showDialog: false,
            editMode: false,
            key: null,
            title: null,
            message: null,
            value: null,
        };
    }


    componentDidMount() {
        this.reload();
    }

    reload = () => {
        this.setState({loading: true}, async () => {
            try {

                let fsValues = await firestore.collection("masterdata").doc("notifications").get();

                let values = [];

                for (const key of Object.keys(fsValues.data())) {

                    values.push({
                        key: key,
                        title: fsValues.data()[key]["title"],
                        message: fsValues.data()[key]["message"]
                    });
                }

                this.setState({values: values, loading: false});
            } catch (error) {
                console.error(error);
                this.setState({loading: false});
            }

        })

    };

    handleChange = event => {

        let value = event.target.value;

        const path = event.target.name.split(".");

        let newValue = {...this.state[path[0]]};
        newValue[path[1]] = value;

        this.setState({[path[0]]: newValue});
    };

    openDialog = () => {

        this.setState({
            showDialog: true,
            title: this.state.value.title,
            message: this.state.value.message,
            key: this.state.value.key,
        })

    };

    closeDialog = () => {
        this.setState({
            showDialog: false,
            key: null,
            title: null,
            message: null,
            value: null,
        })
    };

    handleSave = () => {
        this.setState({working: true}, async () => {

            await firestore.collection("masterdata").doc("notifications").set({
                    [this.state.key]: {title: this.state.title, message: this.state.message}
                },
                {merge: true});

            this.setState({
                working: false,
                showDialog: false,
                key: null,
                title: null,
                message: null,
                value: null,
            }, () => {
                this.reload();
            })
        })
    };

    render() {
        const {classes} = this.props;

        const columns = [
            {

                name: "Action",
                field: "action",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Tooltip title={"Edit entry"}>
                                <IconButton onClick={(e) => this.setState({value: value}, () => this.openDialog(true))}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>

                        );
                    },
                },

            },
            {

                name: "Key",
                field: "key",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {

                name: "Title (Deutsch)",
                field: "title.de_DE",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {

                name: "Title (English)",
                field: "title.en_GB",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {

                name: "Message (Deutsch)",
                field: "message.de_DE",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {

                name: "Message (English)",
                field: "message.en_GB",
                options: {
                    filter: true,
                    sort: true
                }
            },

        ];


        const options = {
            filter: false,
            selectableRows: 'none',
            responsive: 'scrollFullHeight',
            customToolbar: () =>
                <Tooltip title={"Reload Data"}>
                    <IconButton className={classes.iconButton}
                                onClick={this.reload}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>,
        };

        const data = this.state.values.map(value => {

            const line = [];

            for (const column of columns) {
                let field = value[column.field];

                if (column.field === "action") {
                    field = value;
                }

                if (column.field === "message.de_DE") {
                    field = value.message.de_DE;
                }

                if (column.field === "message.en_GB") {
                    field = value.message.en_GB;
                }

                if (column.field === "title.de_DE") {
                    field = value.title.de_DE;
                }

                if (column.field === "title.en_GB") {
                    field = value.title.en_GB;
                }

                if (isNullOrUndefined(field)) {
                    field = "-"
                }
                line.push(field);
            }

            return line;
        });

        const isValidTitleDE = !isNullOrUndefined(this.state.title) && !isNullOrUndefined(this.state.title.de_DE) && this.state.title.de_DE.trim().length > 0;
        const isValidTitleEN = !isNullOrUndefined(this.state.title) && !isNullOrUndefined(this.state.title.en_GB) && this.state.title.en_GB.trim().length > 0;

        const isValidMessageDE = !isNullOrUndefined(this.state.message) && !isNullOrUndefined(this.state.message.de_DE) && this.state.message.de_DE.trim().length > 0;
        const isValidMessageEN = !isNullOrUndefined(this.state.message) && !isNullOrUndefined(this.state.message.en_GB) && this.state.message.en_GB.trim().length > 0;


        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page}) => (
                        <React.Fragment>

                            {this.state.loading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress className={classes.progress} size={50}/>
                                    </Grid>
                                ) :
                                (
                                    <React.Fragment>

                                        <MUIDataTable
                                            columns={columns}
                                            data={data}
                                            title={page.title}
                                            options={options}
                                        />

                                    </React.Fragment>

                                )}


                            <Dialog
                                open={this.state.showDialog}
                                aria-labelledby="form-dialog-title"
                            >

                                <React.Fragment>
                                    <DialogTitle
                                        id="form-dialog-title">Edit {this.state.key} of {page.title}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            Edit the text. You can't change the label
                                        </DialogContentText>

                                        <TextField
                                            value={this.state.title ? this.state.title.de_DE : null}
                                            margin="dense"
                                            id="title.de_DE"
                                            name="title.de_DE"
                                            label="Title (Deutsch)"
                                            multiline={true}
                                            rows={5}
                                            rowsMax={5}
                                            type="text"
                                            error={!isValidTitleDE}
                                            helperText={!isValidTitleDE ? "Text required" : ""}
                                            onChange={this.handleChange}
                                            fullWidth
                                            disabled={this.state.working}
                                        />

                                        <TextField
                                            value={this.state.title ? this.state.title.en_GB : null}
                                            margin="dense"
                                            id="title.en_GB"
                                            name="title.en_GB"
                                            label="Title (English)"
                                            multiline={true}
                                            rows={5}
                                            rowsMax={5}
                                            type="text"
                                            error={!isValidTitleEN}
                                            helperText={!isValidTitleEN ? "Text required" : ""}
                                            onChange={this.handleChange}
                                            fullWidth
                                            disabled={this.state.working}
                                        />

                                        <TextField
                                            value={this.state.message ? this.state.message.de_DE : null}
                                            margin="dense"
                                            id="message.de_DE"
                                            name="message.de_DE"
                                            label="Message (Deutsch)"
                                            multiline={true}
                                            rows={5}
                                            rowsMax={5}
                                            type="text"
                                            error={!isValidMessageDE}
                                            helperText={!isValidMessageDE ? "Text required" : ""}
                                            onChange={this.handleChange}
                                            fullWidth
                                            disabled={this.state.working}
                                        />

                                        <TextField
                                            value={this.state.message ? this.state.message.en_GB : null}
                                            margin="dense"
                                            id="message.en_GB"
                                            name="message.en_GB"
                                            label="Message (English)"
                                            multiline={true}
                                            rows={5}
                                            rowsMax={5}
                                            type="text"
                                            error={!isValidMessageEN}
                                            helperText={!isValidMessageEN ? "Text required" : ""}
                                            onChange={this.handleChange}
                                            fullWidth
                                            disabled={this.state.working}
                                        />

                                    </DialogContent>

                                    <DialogActions>
                                        <Button onClick={this.closeDialog} color="primary"
                                                disabled={this.state.working}>
                                            Close
                                        </Button>
                                        <Button onClick={this.handleSave} color="primary" autoFocus
                                                disabled={this.state.working || !isValidTitleDE || !isValidMessageDE || !isValidTitleEN || !isValidMessageEN}>
                                            Save
                                        </Button>
                                    </DialogActions>
                                </React.Fragment>
                            </Dialog>


                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

Notifications.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles, {withTheme: true})(Notifications)