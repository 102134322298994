import React from 'react';
import styled from 'styled-components';

import asset_Up from "../assets/stepperUp.svg";
import asset_Down from "../assets/stepperDown.svg";


const WrapperCSS = styled.div`
display: flex;
flex-direction: column;
justify-content:space-evenly;
user-select: none;
`;

const StepperCSS = styled.img`
opacity: 0.6;
width: ${props => props.size};
height: ${props => props.size};
&:hover {
    opacity: 1;
    cursor: pointer;
}
&:active {
    opacity: 0.2;
    cursor: pointer;
`;





class StyledStepper extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <WrapperCSS>
                <StepperCSS size={this.props.small === true ? "25px" : "50px"} src={asset_Up} onClick={(event) => this.props.onUp()}/>
                <StepperCSS size={this.props.small === true ? "25px" : "50px"} src={asset_Down} onClick={(event) => this.props.onDown()}/>
            </WrapperCSS>


        );
    }
}

export default (StyledStepper);