import React, {Component} from 'react';
import {initialize} from "../common/configuration";
import * as assetsService from "../common/assets";

import CookieConsent, {Cookies} from "react-cookie-consent";



import Footer from "../components/Footer";
import Header from "../components/Header";
import LoginForm from "../components/LoginForm";
import Categories from "../components/Categories";
import EventsWall from "../components/EventsWall";
import {i18n, i18nChange} from "../common/I18n";
import asset_LogoLarge from "../assets/logo_large.svg";

import hero from "../assets/hero2.jpg";
import {withRouter} from "react-router-dom";
import Timer from "../components/Timer";
import {Redirect} from "react-router";
import firebaseApp, {trackUserPresence} from "../common/firebase";
import * as firebase from "firebase";
import {HeroBlock, HeroWrapper, VideoHero} from "../components/VideoHero";
import StyledWhiteButton from "../components/StyledWhiteButton";
import StyledInvertedWhiteButton from "../components/StyledInvertedWhiteButton";
import styled from "styled-components";
import * as ReactDOM from "react-dom";
import people from "../assets/people.svg";
import event from "../assets/event.svg";
import explore from "../assets/explore.svg";
import goodfood from "../assets/goodfood.svg";
import moment from "moment";
import {isNullOrUndefined} from "util";
import withDebug from "../common/withDebug";
import queryString from 'query-string';
import {notNullOrUndefined} from "../common/utils";


const firestore = firebaseApp.firestore();

const LinksCSS = styled.div`
display: flex;
justify-content: flex-end;
font-family: TruenoLight;
font-size: 16px;
color: #FFFFFF;
letter-spacing: 0;
line-height: 26px;
@media screen and (max-width: 800px) {
display: none;
}
`;

const Link = styled.div`
    margin-left: 24px;
    min-width: 100px;
    &:hover {
cursor: pointer;
font-family: TruenoRegular;
}
&:active {
cursor: pointer;
font-family: TruenoLight;
}
`;

const HeroElements = styled.div`
display: flex;
justify-content: flex-end;
flex-direction: column;
height: 550px;
width: 100%;
`;

const HeroText = styled.div`
font-family: TruenoBold;
font-size: 48px;
color: #FFFFFF;
letter-spacing: 2px;
line-height: 52px;
text-align: left;
margin-bottom: 32px;
width: 80%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 800px) {
font-size: 34px;
text-align: center;
line-height: 56px;
margin-bottom: 48px;
}
`;

const HeroButtons = styled.div`
display: flex;
justify-content: flex-start;
margin-bottom: 32px;
width: 80%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 800px) {
justify-content: center;
flex-direction: column;
width: 80%;
}
`;

const RightButtonCSS = styled.div`
margin-left: 24px;
width:90%;
@media screen and (max-width: 800px) {
margin-left: 0px;
margin-top: 24px;
width:100%;
}
`;

const MobileButtonCSS = styled.div`
display: none;
@media screen and (max-width: 800px) {
display: block;
margin-left: 0px;
margin-top: 24px;
}
`;

const TitleCSS = styled.div`
width:100%;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 80px;
opacity: 0.8;
font-family: TruenoBold;
font-size: 34px;
color: #333333;
letter-spacing: -0.49px;
line-height: 42px;
text-transform: uppercase;
`;

const WrapperCSS = styled.div`
    padding-left: 10%;
    padding-right: 10%;
`;

const ClaimCSS = styled.div`
display: block;
margin-top:20px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 16px;
color: #818181;
letter-spacing: 0;
text-align: left;
line-height: 26px;
width:100%;

`;


const RowCSS = styled.div`
  margin-top: 42px;

display: grid;
  grid-template-columns: 20% 28% 4% 20% 28%;
  
    @media screen and (max-width: 800px) {
  grid-template-columns: 40% 60%;
      grid-template-rows: auto auto;
      grid-column-gap: 5px;
      grid-row-gap: 42px;
}

`;

const Image1CSS = styled.img`
grid-column-start: 1;
grid-column-end: 2;
   @media screen and (max-width: 800px) {
grid-column-start: 1;
grid-column-end: 2;
grid-row-start: 1;
grid-row-end: 2;
}
width: 90%;
box-shadow: 0 0 24px 0 rgba(0,0,0,0.04);
border-radius: 100%;
z-index: 10;
`;

const Text1CSS = styled.div`
grid-column-start: 2;
grid-column-end: 3;
   @media screen and (max-width: 800px) {
grid-column-start: 2;
grid-column-end: 3;
grid-row-start: 1;
grid-row-end: 2;
}

`;

const Image2CSS = styled.img`
grid-column-start: 4;
grid-column-end: 5;
   @media screen and (max-width: 800px) {
grid-column-start: 1;
grid-column-end: 2;
grid-row-start: 2;
grid-row-end: 3;
}
width: 90%;
box-shadow: 0 0 24px 0 rgba(0,0,0,0.04);
border-radius: 100%;
z-index: 10;

`;

const Text2CSS = styled.div`
grid-column-start: 5;
grid-column-end: 6;
   @media screen and (max-width: 800px) {
grid-column-start: 2;
grid-column-end: 3;
grid-row-start: 2;
grid-row-end: 3;
}
`;

const SmallTitleCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
`;

const ParagraphCSS = styled.div`
margin-top: 12px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 14px;
color: #818181;
letter-spacing: 0;
line-height: 26px;
`;

class Start extends Component {

    constructor(props) {
        super(props);

        let login = queryString.parse(this.props.location.search).login;

        this.state = {
            user: null,
            roles: null,
            username: "",
            password: "",
            events: [],
            tweets: [],
            quotes: [],
            news: [],
            images: [],
            loading: false,
            initialized:false,
            scrollToLogin: false,
            loginShown: (login === "true"),
            assetURLs: {},
        };

        const currentLanguage = localStorage.getItem('currentLanguage');
        if (notNullOrUndefined(currentLanguage)) {
            i18nChange(currentLanguage);
            this.setState({language: currentLanguage});
        }
    }

    componentDidMount() {

        initialize().then(async () => {
            await this.fetchAssets();
            await this.setState({initialized: true});
        });

        firebaseApp.auth().onAuthStateChanged(async (user) => {
            if (user) {

                try {
                    const profile = await firestore.collection('profiles').doc(user.uid).get();
                    const roles = await firestore.collection('roles').doc(user.uid).get();


                    if (!profile.exists) {
                        this.setState({systemError: "noProfile"});
                    } else {

                        if (!roles.exists) {
                            this.setState({user: profile.data()}, () => {
                                i18nChange(this.state.user.language);
                                this.listen(user.uid);
                            })
                        } else {
                            this.setState({user: profile.data(), roles: roles.data()}, () => {
                                i18nChange(this.state.user.language);
                                this.listen(user.uid);
                            })
                        }
                        trackUserPresence();



                    }
                } catch (error) {
                    this.setState({systemError: error});
                }


            } else {
                this.setState({roles: null, user: null, username: "", password: "", loading: false})

            }

        });

        this.load();
    }

    fetchAssets = async () => {
        const fullPathAssets = [
            'start/video.mp4',
            'start/frame.jpg',
        ];
        const noExtensionAssets = [
            'start/illustration1',
            'start/illustration2',
            'start/illustration3',
            'start/illustration4',
        ];
        const assetURLs = await assetsService.fetchAssets(fullPathAssets, noExtensionAssets);
        await this.setState({
            assetURLs: assetURLs,
        });

    };

    handleRegister = (name) => {
        this.setState({redirect: true, url: '/register'});
    }

    handleGoToMy = (name) => {
        this.setState({redirect: true, url: this.state.roles.guest ? '/my' : '/host/my'});
    }

    toggleLoginShown = (scroll) => {
        this.setState({loginShown: !this.state.loginShown, scrollToLogin: scroll === true});
    };

    showLogin = (scroll) => {
        this.setState({loginShown: true});
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleScroll = (name) => {
        if (name) {
            let node = ReactDOM.findDOMNode(this.refs[name]);
            if (node) {
                node.scrollIntoView();
            }
        }
    }

    load() {

        this.setState({loading: true, events: [], tweets: [], quotes: [], news: [], images: []}, async () => {
            const fsEvents = await firestore.collection("events").where("status", "==", "running").get();

            let events = [];

            for (const fsEvent of fsEvents.docs) {
                let event = {...fsEvent.data()};
                event.id = fsEvent.id;
                events.push(event);

            }

            const fsTweets = await firestore.collection("tweets").orderBy("created", "desc").get();

            let tweets = [];

            for (const fsTweet of fsTweets.docs) {
                tweets.push({...fsTweet.data(), id: fsTweet.id});
            }

            const fsQuotes = await firestore.collection("quotes").orderBy("created", "desc").get();

            let quotes = [];

            for (const fsQuote of fsQuotes.docs) {
                quotes.push({...fsQuote.data(), id: fsQuote.id});
            }

            const fsNews = await firestore.collection("news").orderBy("created", "desc").get();

            let news = [];

            for (const fsNew of fsNews.docs) {
                news.push({...fsNew.data(), id: fsNew.id});
            }

            const fsImages = await firestore.collection("images").orderBy("created", "desc").get();
            let images = [];

            for (const fsImage of fsImages.docs) {
                images.push({...fsImage.data(), id: fsImage.id});
            }

            this.setState({events: events, tweets: tweets, news: news, quotes: quotes, images: images, loading: false});
        });


    }

    listen = (uid) => {
        firestore.collection('roles').doc(uid)
            .onSnapshot(
                snapshot => {
                    this.setState({roles: snapshot.data()});
                }
                , snapShotError => {
                    this.setState({systemError: snapShotError.code});
                });

    };


    handleLogin(name) {
        this.setState({working: true}, () => {
            firebase.auth().signInWithEmailAndPassword(this.state.username, this.state.password)
                .catch((error) => {
                    this.setState({error: i18n("error/" + error.code), working: false})
                })
        });

    }

    componentDidUpdate() {
        if (this.state.scrollToLogin && !isNullOrUndefined(this.login)) {
            this.setState({scrollToLogin: false}, () => {
                ReactDOM.findDOMNode(this.login).scrollIntoView()
            });
        }
    }


    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    handleLanguageChange = async (language, save) => {

        await this.setState({loading: true});
        if (save) {
            localStorage.setItem('currentLanguage', language);
        }
        i18nChange(language);
        await initialize()
            .then(async () => {
                await this.setState({loading: true});
            })
    };

    render() {

        if (!this.state.initialized) {
            return (<Timer/>);
        }

        const isAuth = this.state.user !== null;
        return (
            <div>
                {(this.state.redirect) &&
                <Redirect push to={this.state.url}/>
                }

                <VideoHero video={this.state.assetURLs['start/video.mp4']} frame={this.state.assetURLs['start/frame.jpg']}>
                    <Header
                        logoWhite
                        user={this.state.user}
                        roles={this.state.roles}
                        onLogin={(event) => this.showLogin()}
                        handleLanguageChange={this.handleLanguageChange}
                    >
                        <LinksCSS>
                            {!this.state.loginShown && !isAuth &&
                            <Link onClick={(event) => this.toggleLoginShown()}>{i18n("header/login")}</Link>}
                            {!isAuth && <Link
                                onClick={(event) => this.handleRegister("register")}>{i18n("header/register")}</Link>}
                        </LinksCSS>
                    </Header>
                    <HeroWrapper>
                        <HeroBlock>
                            <HeroElements>
                                <HeroText>

                                    {i18n("hero/text")}

                                </HeroText>
                                <HeroButtons>

                                    {!isAuth &&
                                    <StyledWhiteButton
                                        minWidth={'71%'}
                                        label={i18n("hero/register")}
                                        name={"register"}
                                        onClick={this.handleRegister.bind(this)}
                                    />}

                                    {isAuth &&
                                    <StyledWhiteButton
                                        minWidth={"300px"}
                                        label={i18n("hero/goToMy")}
                                        name={"goToMy"}
                                        onClick={this.handleGoToMy.bind(this)}
                                    />}

                                    <RightButtonCSS>

                                        <StyledInvertedWhiteButton
                                            onClick={this.handleScroll.bind(this)}
                                            label={i18n("hero/more")}
                                            name={"more"}
                                        />
                                    </RightButtonCSS>

                                    {!this.state.loginShown && !isAuth &&

                                    <MobileButtonCSS>
                                        <StyledInvertedWhiteButton
                                            onClick={((e) => this.toggleLoginShown(true))}
                                            label={i18n("hero/login")}
                                            name={"login"}
                                        />
                                    </MobileButtonCSS>
                                    }


                                </HeroButtons>
                            </HeroElements>
                        </HeroBlock>
                        <HeroBlock zIndex={1} ref={input => {
                            this.login = input;
                        }}>

                            {this.state.loginShown && !isAuth &&
                            <LoginForm

                                onClose={this.toggleLoginShown.bind(this)}
                                onLogin={this.handleLogin.bind(this)}
                                onRegister={this.handleRegister.bind(this)}
                                error={this.state.error}
                                username={this.state.username}
                                password={this.state.password}
                                onChange={this.handleChange.bind(this)}
                                working={this.state.working}
                                enableRegister={true}/>
                            }
                        </HeroBlock>
                    </HeroWrapper>
                </VideoHero>

                <Categories/>
                <EventsWall
                    margin={"10%"}
                    loading={this.state.loading}
                    events={this.state.events}
                    tweets={this.state.tweets}
                    quotes={this.state.quotes}
                    news={this.state.news}
                    images={this.state.images}
                    title={i18n('eventsWall/latest')}
                    user={this.state.user}
                />

                <WrapperCSS>
                    <TitleCSS ref="more">{i18n("guest/title")}</TitleCSS>

                    <ClaimCSS>{i18n("guest/claim")}</ClaimCSS>

                    <RowCSS>
                        <Image1CSS src={this.state.assetURLs['start/illustration1']} alt=""/>
                        <Text1CSS>
                            <SmallTitleCSS>{i18n("guest/1/title")}</SmallTitleCSS>
                            <ParagraphCSS>{i18n("guest/1/p")}</ParagraphCSS>
                        </Text1CSS>
                        <Image2CSS src={this.state.assetURLs['start/illustration2']} alt=""/>
                        <Text2CSS>
                            <SmallTitleCSS>{i18n("guest/2/title")}</SmallTitleCSS>
                            <ParagraphCSS>{i18n("guest/2/p")}</ParagraphCSS>
                        </Text2CSS>
                    </RowCSS>
                    <RowCSS>
                        <Image1CSS src={this.state.assetURLs['start/illustration3']} alt=""/>
                        <Text1CSS>
                            <SmallTitleCSS>{i18n("guest/3/title")}</SmallTitleCSS>
                            <ParagraphCSS>{i18n("guest/3/p")}</ParagraphCSS>
                        </Text1CSS>
                        <Image2CSS src={this.state.assetURLs['start/illustration4']} alt=""/>
                        <Text2CSS>
                            <SmallTitleCSS>{i18n("guest/4/title")}</SmallTitleCSS>
                            <ParagraphCSS>{i18n("guest/4/p")}</ParagraphCSS>
                        </Text2CSS>
                    </RowCSS>
                </WrapperCSS>

                <CookieConsent
                    buttonText={i18n("cookie/ok")}
                    cookieName="culinariooCookieConsent"
                    location={"top"}
                    style={{
                        background: "#F5F5F5",
                        color: "var(--green)",
                        fontFamily: "TruenoLight",
                        alignItems: "start",
                        opacity: "0.8",
                    }}
                    buttonStyle={{
                        background: "var(--red)",
                        color: "white",
                        fontFamily: "TruenoRegular",
                        borderRadius: 8,
                        fontSize: 18,
                        textTransform: "uppercase"

                    }}
                >
                    {i18n("cookie/text")}
                </CookieConsent>


                <Footer/>
            </div>
        );
    }
}

export default withDebug()(withRouter(Start));
