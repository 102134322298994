import React, {Component} from 'react';
import styled from 'styled-components';

import StyledButton from "../components/StyledButton"
import StyledFlatButton from "../components/StyledFlatButton"
import MousePopover from "../components/MousePopover"


import asset_people from '../assets/icon_people.svg';
import asset_cook from '../assets/icon_cook.svg';
import asset_plate from '../assets/icon_plate.svg';
import asset_globe from '../assets/icon_globe.svg';
import asset_leaf from '../assets/icon_leaf.svg';
import asset_place from '../assets/icon_place.svg';
import asset_medical from '../assets/icon_medical.svg';
import asset_house from '../assets/icon_house.svg';
import asset_map from '../assets/icon_map.svg';
import asset_clock from '../assets/icon_clock.svg';
import asset_cancel from '../assets/icon_cancel.svg';
import asset_calendar from '../assets/icon_calendar.svg';
import asset_info from '../assets/info.svg';


import IconButton from "@material-ui/core/IconButton";
import asset_edit from "../assets/edit.svg";

import StyledDialog from "../components/StyledDialog";
import StyledCheckBox from "../components/StyledCheckBox";

import 'moment/locale/de';
import moment from 'moment';

import {i18nText, i18n, i18nLocale, i18nConfiguration} from "../common/I18n";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import StyledStepper from "./StyledStepper";
import StyledDatePicker from "./StyledDatePicker";
import StyledTextField from "./StyledTextField";
import ScrollSelect from "./ScrollSelect";
import {CheckBoxSelectControl, AddressControl, TimesControl, PeopleControl} from "./EditableComponents";

import {
    masterdata
} from "../common/configuration";

import {isNullOrUndefined} from "util";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import StyledTextArea from "./StyledTextArea";
import Dropzone from "react-dropzone";
import MouseOverPopover from "./MousePopover";
import StyledInvertedWhiteButton from "./StyledGreyButton";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import ForumIcon from '@material-ui/icons/Forum';
import asset_running from "../assets/outlinedShield.svg";
import asset_passed from "../assets/shield.svg";
import asset_canceled from "../assets/redShield.svg";
import Tooltip from '@material-ui/core/Tooltip';


const WrapperCSS = styled.div`
    margin-left: 69%;
    height: 120%;
    width:25%;
    margin-top: ${(props) => props.marginTop ? props.marginTop : "600px"};
    position: absolute;
    top:0;
@media screen and (max-width: 1100px) {
    display:block;
    position:relative;
    margin-top: 0px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
        width:100%;
}
`;

const StickyBoxCSS = styled.div`
position: sticky;
display: block;
top:calc(112px/2 + 60px);
z-index: 99;
@media screen and (max-width: 1100px) {
    position:relative;
    margin-top: 0px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    top:0px;
}
`;

const BoxCSS = styled.div`
background: #FFFFFF;
box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
border-radius: 8px;
position: relative;
@media screen and (max-width: 1100px) {
width:100%;
box-shadow: none;
border-radius: 0px;
}
width:90%;
display: block;
margin-top: 0;
margin-left: auto;
margin-right: auto;
`;


const BigProfile = styled.img`
width:112px;
height:112px;
border-radius: 100%;
border-color: white;
border-width: 4px;
border-style: solid;
z-index: 99;
object-fit: cover;
`;

const DropCSS = styled.div`
display: grid;
border-radius: 100%;
background-color: rgba(255,99,68, 0.6);
width: 112px;
height: 112px;
text-align: center;
&:hover {
box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
cursor: pointer;
font-family: TruenoBold;
}
&:active {
box-shadow: none;
cursor: pointer;
font-family: TruenoRegular;
}
`;

const DropTextCSS = styled.div`
    opacity: 0.8;
font-family: TruenoLight;
font-size: 10px;
color: #FAFAFA;
letter-spacing: 1.5px;
line-height: 22px;
justify-self: center;
align-self: center;
`;

const BigProfileContainer = styled.div`
width:112px;
height:112px;
left: calc(50% - (112px / 2));
top: calc(-112px / 2);
position: absolute;
z-index: 100;
`;


const BoxEntriesWrapper = styled.div`
width: 100%;
padding-top: 16px;
padding-bottom: 24px;
`;

const BoxEntriesGrid = styled.div`
margin-top: 16px;
width: 100%;
display: grid;
grid-template-columns: 35% 10% 55%;

`;

const BoxEntryIcon = styled.img`
align-self: start;
  justify-self: end;
  margin-right: ${
    props => {
        if (props.extraPadding) {
            return (16 + props.extraPadding) + "px";
        } else {
            return "16px";
        }
    }};
`;

const BoxEntryText = styled.div`
align-self: start;
font-family: TruenoLight;
font-size: 14px;
color: #818181;
letter-spacing: 0;
line-height: 14px;
padding-top: 2px;
`;

const BoxEntryTextLine = styled.p`
    -webkit-margin-after: 0.5em;
`;

const Button1CSS = styled.div`
width:80%;
padding-top: 24px;
display: block;
margin-left: auto;
margin-right: auto;
`;

const ButtonCSS = styled.div`
width:80%;
display: block;
margin-left: auto;
margin-right: auto;
padding-bottom: 24px;
`;

const EditCSS = styled.div`
justify-self: end;
align-self: start;
margin-right: 16px;
margin-top: -13px;
`;


const AmountCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 44px;
color: #04ADA9;
letter-spacing: -1.5px;
text-align: right;
line-height: 50px;
grid-area: amount;
justify-self: end;
`;

const AmountTotalCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 44px;
color: #04ADA9;
letter-spacing: -1.5px;
text-align: center;
line-height: 50px;
grid-area: amount;
justify-self: center;
`;

const GuestAmountCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 18px;
color: #04ADA9;
letter-spacing: -1.5px;
text-align: center;
line-height: 50px;
grid-area: guestamount;
justify-self: center;
`;

const CurrencyCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 28px;
color: #04ADA9;
letter-spacing: -0.2px;
text-align: center;
line-height: 30px;
top: 0px;
right: 0px;
grid-area: currency;
justify-self: start;
`;

const StepperCSS = styled.div`
opacity: 0.8;
font-family: TruenoLight;
font-size: 12px;
color: #04ADA9;
letter-spacing: 2px;
grid-area: stepper;
justify-self: start;
`;


const InfoCSS = styled.div`
float: right;
margin-left: 16px;
margin-top:-16px;
cursor: pointer;
`;

const PriceCSS = styled.div`
padding-top: calc(180px / 2 + 8px);
margin-left: auto;
margin-right: auto;
display: grid;
grid-template-columns: 50% 20% 30%;
user-select: none;
justify-content:space-between;
width:70%;
grid-template-areas: 
    "amount currency stepper" 
`;

const Price2CSS = styled.div`
position: relative;
padding-top: calc(180px / 2 + 8px);
margin-left: auto;
margin-right: auto;
display: grid;
grid-template-columns: 100%;
user-select: none;
justify-content:space-between;
width:70%;
grid-template-areas: 
    "amount"
    "guestamount" 
`;

const LegalCSS = styled.div`
width:70%;
padding-top: 24px;
display: block;
user-select: none;
margin-left: auto;
margin-right: auto;
`;

const LegalLabelCSS = styled.div`
    opacity: 0.8;
    font-Family: TruenoLight;
    font-size: 12.00px;
    color: #818181;
    letter-spacing: 0;
    line-height: 18.00px;
    align-items: start
`;

const PolicyCSS = styled.div`
`;

const PolicyHeaderCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #04ADA9;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
`;

const PolicyBodyCSS = styled.div`
opacity: 0.8;
font-family: TruenoLight;
font-size: 18px;
color: #818181;
letter-spacing: 0;
line-height: 28px;

`;

const FinancialWrapperCSS = styled.div`
display: flex;
flex-direction: column;
min-width: 300px;
padding-left: 40px;
padding-right: 40px;
`;
const FinancialHeaderCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 16px;
color: #818181;
letter-spacing: 0;
width: 100%;
margin-top: 16px;
margin-bottom: 16px;
text-align: center;
`;
const FinancialLineCSS = styled.div`
display: flex;
justify-content:space-between;
width: 100%;
margin-top: 16px;
margin-bottom: 16px;
`;
const FinancialTextCSS = styled.div`
opacity: 0.8;
font-family: TruenoLight;
font-size: 16px;
color: #818181;
letter-spacing: 0;
line-height: 26px;
`;
const FinancialBoldTextCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 16px;
color: #818181;
letter-spacing: 0;
line-height: 26px;
`;


const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#FF6344',
            // contrastText: will be calculated to contast with palette.primary.main
        },
        // error: will use the default color
    },

    typography: {
        useNextVariants: true,
    },

    overrides: {

        MuiIconButton: {
            root: {
                marginLeft: 8

            }
        },

        MuiMenuItem: {
            root: {
                marginTop: 16,
                marginBottom: 16,
                fontFamily: "TruenoLight",
                fontSize: 14,
                letterSpacing: 1.5,
                color: '#FF6344',
                textTransform: 'uppercase'
            }
        },

        MuiFormControlLabel: {
            root: {
                marginTop: '40px',
                alignItems: 'start'
            }
        },

    }
});

class BoxEntries extends Component {
    constructor(props) {
        super(props);


    }

    render() {
        return (
            <BoxEntriesWrapper>


                {this.props.entries.map(entry => {

                    if ((entry.values.length !== 0 && !isNullOrUndefined(entry.values[0])) || this.props.editMode) {
                        return (
                            <BoxEntriesGrid key={entry.name}>

                                {this.props.editMode &&

                                <EditCSS>
                                    <IconButton
                                        onClick={event => this.props.onEdit(entry.name)}

                                    >
                                        <img src={asset_edit}/>
                                    </IconButton>
                                </EditCSS>
                                }

                                {!this.props.editMode &&

                                <div/>
                                }

                                <Tooltip title={entry.tooltip} enterTouchDelay={0}>
                                    <BoxEntryIcon src={entry.icon} extraPadding={entry.extraPadding}/>
                                </Tooltip>

                                <BoxEntryText>

                                    {(entry.values.length === 0 || isNullOrUndefined(entry.values[0])) ?
                                        (
                                            <BoxEntryTextLine>{i18n("details/edit/noValue")}</BoxEntryTextLine>
                                        ) :
                                        (
                                            entry.values.map(value => {
                                                return (
                                                    <BoxEntryTextLine key={value}>{value}</BoxEntryTextLine>
                                                )
                                            }))}
                                </BoxEntryText>
                            </BoxEntriesGrid>)
                    }

                })}
            </BoxEntriesWrapper>

        )
    }

}

const Button2CSS = styled.div`
margin-top: 32px;
width:40%;
display: block;
margin-left: auto;
margin-right: auto;
padding-bottom: 32px;
`;

class EditableBookingOverview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorMoreEl: null,
            anchorEl: null,
            edit: false,
            legal: false,
            types: this.props.types.slice(), // we need a copy
            cuisines: this.props.cuisines.slice(),// we need a copy
            languages: this.props.languages.slice(), // we need a copy
            specials: this.props.specials.slice(), // we need a copy
            allergens: this.props.allergens.slice(), // we need a copy
            courtesies: this.props.courtesies.slice(), // we need a copy
            times: this.props.times.slice(), // we need a copy
            address: Object.assign({}, this.props.address), // we need a copy
            addressSpecials: Object.assign({}, this.props.addressSpecials), // we need a copy
            date: this.props.date, // we need a copy
            people: Object.assign({}, this.props.people), // we need a copy
            time: this.props.time
        }
    }

    handleCheckedList(valueName, name, event) {
        var values = this.state[valueName];

        if (event.target.checked) { //make sure it's in the list

            if (!values.includes(name)) {
                values.push(name);
            }

        } else { // make sure it's not in the list

            if (values.includes(name)) {
                var index = values.indexOf(name)
                values.splice(index, 1);
            }

        }

        this.setState({[valueName]: values});
    };

    handleEdit(key) {
        this.setState({edit: true, name: key})
    };

    handleCancel(key) {

        this.setState({
            edit: false,
            [key]: (this.props[key] instanceof Array) ? this.props[key].slice() : this.props[key]
        })
    };

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    handleI18nChange(event) {

        let entry = {... this.state[event.target.name]};
        entry[i18nLocale()] = event.target.value;
        this.setState({[event.target.name]: entry});
    };

    handleAddressChange(event) {

        var address = this.state.address;
        address[event.target.name] = event.target.value;
        this.setState({address: address});
    };

    handleDateChange(date) {
        this.setState({date: date.toDate()});
    };

    handleSave(key) {
        this.setState({edit: false}, this.props.onSave(key, this.state[key]));
    };

    handleCheck(name, event) {
        this.setState({[name]: event.target.checked});
    };

    handleTimesChange(index, position, date) {

        const newDate = moment(date.toDate())
        const newTime = moment(this.state.date)
            .hours(newDate.hours())
            .minutes(newDate.minutes())
            .seconds(newDate.seconds())
            .milliseconds(0);


        let times = this.state.times.map((time) => Object.assign({}, time));

        if (!isNullOrUndefined(date) && !isNullOrUndefined(date.toDate)) {

            const allOtherTimes = [...times];
            allOtherTimes.splice(index, 1)

            let startTime = newTime;
            let endTime = times[index][1];

            if (position === 1) {
                startTime = times[index][0];
                endTime = newTime;
            }

            const noOverlap = allOtherTimes.reduce((acc, time) => {

                    return acc && (
                        !moment(time[0]).isBetween(startTime, endTime) &&
                        !moment(time[1]).isBetween(startTime, endTime)
                    )
                }
                , true
            );

            if (noOverlap) {

                times[index][position] = newTime.toDate();

                if (moment(times[index][0]).isAfter(moment(times[index][1]))) {
                    times[index][1] = moment(times[index][0]).add(1, 'm').toDate();
                }
                this.setState({times: times});
            } else {
                this.setState({times: this.state.times});
            }
        }
    };

    handleOtherSlot() {
        var times = this.state.times.slice();

        var lastTime = times.reduce((acc, time) => {
            return moment(time[1]).isAfter(acc) ? moment(time[1]) : acc;
        }, moment(this.state.date).hours(19).minutes(0).seconds(0).milliseconds(0));

        if (lastTime.isBefore(moment(this.state.date).hours(22).minutes(45).seconds(0).milliseconds(0))) {
            times.push([lastTime.add(15, 'm').toDate(), lastTime.clone().add(30, 'm').toDate()])
            this.setState({times: times});
        }
    };

    handleRemoveSlot() {

        var times = this.state.times.slice();
        times.splice(times.length - 1, 1);
        this.setState({times: times});
    };

    handlePeopleChange = (name, number) => {

        var people = this.state.people;
        people[name] = number + 1;

        if (people.min > people.max) {
            people.min = people.max;
        }

        this.setState({people: people});

    };


    handleTimeChange(event) {
        this.setState({time: event.target.value});
    }

    handlePopoverOpen = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handlePopoverClose = () => {
        this.setState({anchorEl: null});
    };

    handleMore = (name, event) => {
        this.setState({anchorMoreEl: event.currentTarget});
    };

    handleClose = event => {

        this.setState({anchorMoreEl: null});
    };

    handleCancelEvent = event => {

        this.setState({anchorMoreEl: null}, () => {
            this.props.onCancel();
        });
    };

    render() {

        let statusIcon = asset_running;
        let statusText = i18n("overview/running");
        let running = true;

        if (moment(this.props.end).isBefore((moment()))) {
            statusIcon = asset_passed;
            statusText = i18n("overview/passed");
            running = false;
        }

        if (this.props.status === "canceled" || this.props.status === "canceledBySystem") {
            statusIcon = asset_canceled;
            statusText = i18n("overview/canceled");
            running = false;
        }

        const translatedCuisines = masterdata.cuisines.map(cuisine => {
            return {key: cuisine, text: i18nConfiguration("cuisine", cuisine)}
        });


        const sortedCusines = [...masterdata.cuisines.slice(0, 3), ...translatedCuisines.slice(3, translatedCuisines.length - 3).sort(function (a, b) {
            return a.text[0].localeCompare(b.text[0]);
        }).map(cuisine => cuisine.key)];

        const controls = {
            types: (<CheckBoxSelectControl i18nKey={"type"} allValues={masterdata.types} values={this.state.types}
                                           onChange={(name, event) => this.handleCheckedList("types", name, event)}/>),
            cuisines: (<CheckBoxSelectControl i18nKey={"cuisine"} allValues={sortedCusines} values={this.state.cuisines}
                                              onChange={(name, event) => this.handleCheckedList("cuisines", name, event)}/>),
            languages: (
                <CheckBoxSelectControl i18nKey={"language"} allValues={masterdata.languages}
                                       values={this.state.languages}
                                       onChange={(name, event) => this.handleCheckedList("languages", name, event)}/>),
            specials: (
                <CheckBoxSelectControl i18nKey={"special"} allValues={masterdata.specials} values={this.state.specials}
                                       onChange={(name, event) => this.handleCheckedList("specials", name, event)}/>),
            allergens: (
                <CheckBoxSelectControl i18nKey={"allergen"} allValues={masterdata.allergens}
                                       values={this.state.allergens}
                                       onChange={(name, event) => this.handleCheckedList("allergens", name, event)}/>),
            courtesies: (
                <CheckBoxSelectControl i18nKey={"courtesy"} allValues={masterdata.courtesies}
                                       values={this.state.courtesies}
                                       onChange={(name, event) => this.handleCheckedList("courtesies", name, event)}/>),

            address: (<AddressControl address={this.state.address} onChange={this.handleAddressChange.bind(this)}/>),

            date: (<StyledDatePicker date={this.state.date}
                                     onChange={this.handleDateChange.bind(this)}/>),
            times: (<TimesControl times={this.state.times}
                                  onChange={this.handleTimesChange.bind(this)}
                                  onOtherSlot={this.handleOtherSlot.bind(this)}
                                  onRemoveSlot={this.handleRemoveSlot.bind(this)}/>),

            people: (<PeopleControl people={this.state.people}
                                    onChange={this.handlePeopleChange.bind(this)}/>),
            time: (<MuiThemeProvider theme={theme}>

                <RadioGroup
                    value={this.state.time}
                    onChange={this.handleTimeChange.bind(this)}
                    name={"time"}
                >

                    {masterdata.times.map((value, index) => (
                        <FormControlLabel key={index}
                                          value={value.label}
                                          control={<Radio color="primary"/>}
                                          label={
                                              <PolicyCSS>
                                                  <PolicyHeaderCSS>{i18nConfiguration("time", value.label)}</PolicyHeaderCSS>
                                                  <PolicyBodyCSS>
                                                      {i18nConfiguration("timeDescription", value.label)}
                                                  </PolicyBodyCSS>
                                              </PolicyCSS>}/>
                    ))}

                </RadioGroup>
            </MuiThemeProvider>),
            addressSpecials: (<StyledTextArea
                label={i18n("host/draft/addressSpecialsLabel")}
                name="addressSpecials"
                disabled={this.state.working}
                value={i18nText(this.state.addressSpecials)}
                onChange={this.handleI18nChange.bind(this)}
                required={false}
                autoFocus={false}/>),
        };

        const disabled = {
            types: this.state.types.length < 1,
            cuisines: this.state.cuisines.length < 1,
            languages: this.state.languages.length < 1,
            specials: false,
            allergens: false,
            courtesies: false,
            address: (
                this.state.address === null ||
                isNullOrUndefined(this.state.address.street) ||
                this.state.address.street.trim().length < 1 ||
                isNullOrUndefined(this.state.address.city) ||
                this.state.address.city.trim().length < 1 ||
                isNullOrUndefined(this.state.address.postalCode) ||
                this.state.address.postalCode.trim().length < 5),
            times: false,
            people: false,
            time: false
        };

        let entries = [
            {
                icon: asset_place,
                values: [this.props.address.street, this.props.address.postalCode + " " + this.props.address.city],
                extraPadding: 3,
                name: "address",
                tooltip: i18n("overview/address/tooltip"),
            },
            {
                icon: asset_map,
                values: [i18nText(this.props.addressSpecials)],
                extraPadding: 3,
                name: "addressSpecials",
                tooltip: i18n("overview/addressSpecials/tooltip"),

            },
            {
                icon: asset_calendar,
                values: [

                    moment(this.props.date).format("dddd, DD.MM.YYYY")],
                name: "date",
                tooltip: i18n("overview/date/tooltip"),

            },
            {
                icon: asset_clock,
                values:
                    this.props.times.map(value => (
                        i18n("overview/time", {
                            start: moment(value[0]).format("HH:mm"),
                            end: moment(value[1]).format("HH:mm")
                        }))),
                name: "times",
                tooltip: i18n("overview/times/tooltip"),

            },
            {
                icon: asset_leaf,
                values: this.props.specials.map(entry => i18nConfiguration('special', entry)),
                name: "specials",
                tooltip: i18n("overview/specials/tooltip"),


            },
            {
                icon: asset_cook,
                values: this.props.types.map(entry => i18nConfiguration('type', entry)),
                extraPadding: 2,
                name: "types",
                tooltip: i18n("overview/types/tooltip"),

            },
            {
                icon: asset_people,
                values: [i18n("overview/seats", {
                    min: this.props.people.min,
                    max: this.props.people.max,
                    current: 0,
                })],
                name: "people",
                tooltip: i18n("overview/people/tooltip"),

            },
            {
                icon: asset_plate,
                values: this.props.cuisines.map(entry => i18nConfiguration('cuisine', entry)),
                name: "cuisines",
                tooltip: i18n("overview/cuisines/tooltip"),

            },

            {
                icon: asset_globe,
                values: this.props.languages.map(entry => i18nConfiguration('language', entry)),
                name: "languages",
                tooltip: i18n("overview/languages/tooltip"),


            },

            {
                icon: asset_medical,
                values: this.props.allergens.map(entry => i18nConfiguration('allergen', entry)),
                extraPadding: 2,
                name: "allergens",
                tooltip: i18n("overview/allergens/tooltip"),


            },
            {
                icon: asset_house,
                values: this.props.courtesies.map(entry => i18nConfiguration('courtesy', entry)),
                name: "courtesies",
                tooltip: i18n("overview/courtesies/tooltip"),


            },
            {
                icon: asset_cancel,
                values: [i18nConfiguration('time', this.props.time)],
                name: "time",
                tooltip: i18n("overview/time/tooltip"),


            },
        ];

        if (!this.props.bookingMode) {
            entries.push(
                {
                    icon: statusIcon,
                    values: [statusText],
                    extraPadding: 2,
                    name: "status",
                    tooltip: i18n("overview/status/tooltip"),
                },
            )

        }

        let dropzoneRef = null;


        return (


            <WrapperCSS marginTop={this.props.marginTop}>

                <StickyBoxCSS>

                    {this.props.editMode ? (

                        <BigProfileContainer onClick={() => {
                            dropzoneRef.open()
                        }}>


                            <Dropzone className="smallDragAndDropArea"
                                      onDrop={this.props.onDrop}
                                      accept="image/*"
                                      multiple={true}
                                      ref={(node) => {
                                          dropzoneRef = node;
                                      }}>

                                {(this.props.image === null) &&
                                <DropCSS>
                                    <DropTextCSS>
                                        {i18n("host/draft/dropProfileImage")}
                                    </DropTextCSS>
                                </DropCSS>
                                }

                                {(this.props.image !== null) &&
                                <BigProfile src={this.props.image}/>
                                }

                            </Dropzone>


                        </BigProfileContainer>
                    ) : (
                        <BigProfileContainer>
                            <BigProfile src={this.props.image}/>
                        </BigProfileContainer>

                    )}


                    <BoxCSS>

                        {this.props.bookingMode &&

                        <PriceCSS>

                            <AmountCSS>{this.props.amount}</AmountCSS>
                            <CurrencyCSS>{i18nConfiguration('currency', this.props.currency)}</CurrencyCSS>

                            <StepperCSS>
                                <StyledStepper
                                    onUp={this.props.onUp}
                                    onDown={this.props.onDown}
                                    small={true}/>
                            </StepperCSS>


                        </PriceCSS>
                        }

                        {!this.props.bookingMode &&

                        <Price2CSS>
                            <AmountTotalCSS>{this.props.totalAmount} {i18nConfiguration('currency', this.props.currency)}
                                <InfoCSS onMouseEnter={this.handlePopoverOpen}
                                         onMouseLeave={this.handlePopoverClose}>
                                    <img src={asset_info}/>
                                </InfoCSS>
                            </AmountTotalCSS>

                            <GuestAmountCSS>{`( ${this.props.amount} ${i18nConfiguration('currency', this.props.currency)} ${i18n("bookingOverview/perGuest")})`}</GuestAmountCSS>
                        </Price2CSS>
                        }

                        {this.props.bookingMode &&

                        <LegalCSS>
                            <StyledCheckBox
                                checked={this.state.legal}
                                onChange={this.handleCheck.bind(this)}
                                value={'legal'}
                                marginBottom={'8px'}
                                label={
                                    <LegalLabelCSS>{i18n('host/draft/legal')}</LegalLabelCSS>}
                            />
                        </LegalCSS>
                        }


                        {this.props.bookingMode &&

                        <Button1CSS>
                            <StyledButton label={i18n("host/book")}
                                          onClick={this.props.onClick}
                                          working={this.props.working}
                                          disabled={!this.state.legal || this.props.disabled}
                                          name="book"
                                          minWidth={"0"}/>

                        </Button1CSS>
                        }

                        {this.props.bookingMode && !this.props.working &&

                        <Button1CSS>
                            <StyledButton label={i18n("host/save")}
                                          onClick={this.props.onClick}
                                          working={this.props.working}
                                          name="save"
                                          minWidth={"0"}/>

                        </Button1CSS>
                        }


                        <BoxEntriesWrapper>

                            <BoxEntries entries={entries} editMode={this.props.editMode}
                                        onEdit={this.handleEdit.bind(this)}/>

                        </BoxEntriesWrapper>

                        {this.props.bookingMode && false &&


                        <ButtonCSS>

                            {!this.props.showVoucher &&

                            <StyledFlatButton label={i18n("host/voucher")}
                                              onClick={this.props.onClick}
                                              name="voucher"
                                              disabled={false}/>

                            }
                            {this.props.showVoucher &&
                            <StyledTextField
                                label={i18n("host/voucher/label")}
                                name="voucher"
                                value={this.props.voucher}
                                onChange={this.props.onChange}
                                required={false}/>
                            }
                        </ButtonCSS>
                        }

                        {!this.props.bookingMode &&

                        <MuiThemeProvider theme={theme}>


                            <Button2CSS>
                                <StyledInvertedWhiteButton label={i18n("bookingOverview/more")}
                                                           name="more"
                                                           onClick={this.handleMore}

                                />

                                <Menu
                                    id="more-menu"
                                    anchorEl={this.state.anchorMoreEl}
                                    open={Boolean(this.state.anchorMoreEl)}
                                    onClose={this.handleClose}
                                >

                                    {this.props.cancelable &&
                                    <MenuItem onClick={this.handleCancelEvent.bind(this)}>
                                        <ListItemIcon>
                                            <SettingsBackupRestoreIcon/>
                                        </ListItemIcon>
                                        <ListItemText inset primary={i18n('bookingOverview/cancel')}
                                                      secondary={i18n('bookingOverview/cancel/description')}/>
                                    </MenuItem>
                                    }

                                    {this.props.currentSeats > 0 &&


                                    <MenuItem onClick={this.props.onMessageAll}>
                                        <ListItemIcon>
                                            <ForumIcon/>
                                        </ListItemIcon>
                                        <ListItemText inset primary={i18n('bookingOverview/messageAll')}
                                                      secondary={i18n('bookingOverview/messageAll/description')}/>
                                    </MenuItem>
                                    }

                                </Menu>

                            </Button2CSS>
                        </MuiThemeProvider>
                        }


                    </BoxCSS>
                </StickyBoxCSS>

                <StyledDialog
                    open={this.state.edit}
                    title={i18n("details/edit/" + this.state.name + "/title")}
                    text={i18n("details/edit/" + this.state.name + "/text")}
                    name={this.state.name}
                    onCancel={this.handleCancel.bind(this)}
                    onSave={this.handleSave.bind(this)}
                    disabled={disabled[this.state.name]}
                    control={controls[this.state.name]}
                />

                <MouseOverPopover open={Boolean(this.state.anchorEl)} anchorEl={this.state.anchorEl}>
                    <FinancialWrapperCSS>
                        <FinancialHeaderCSS>{i18n("bookingOverview/financial/header")}</FinancialHeaderCSS>
                        <FinancialLineCSS>
                            <FinancialTextCSS>{i18n("bookingOverview/financial/income",
                                {
                                    people: this.props.currentSeats,
                                    amount: this.props.amount,
                                    currency: i18nConfiguration('currency', this.props.currency)
                                })}</FinancialTextCSS>
                            <FinancialTextCSS>{`${this.props.totalAmount} ${i18nConfiguration('currency', this.props.currency)}`}</FinancialTextCSS>
                        </FinancialLineCSS>
                        <FinancialLineCSS>
                            <FinancialBoldTextCSS>{i18n("bookingOverview/financial/sum")}</FinancialBoldTextCSS>
                            <FinancialBoldTextCSS>{`${this.props.totalAmount} ${i18nConfiguration('currency', this.props.currency)}`}</FinancialBoldTextCSS>
                        </FinancialLineCSS>
                        <FinancialLineCSS>
                            <FinancialTextCSS>{i18n("bookingOverview/financial/minus")}</FinancialTextCSS>
                            <FinancialTextCSS>{this.props.fee === 0 ? "./." : `${this.props.fee} ${i18nConfiguration('currency', this.props.currency)}`}</FinancialTextCSS>
                        </FinancialLineCSS>
                        <hr width={"100%"}/>
                        <FinancialLineCSS>
                            <FinancialBoldTextCSS>{i18n("bookingOverview/financial/total")}</FinancialBoldTextCSS>
                            <FinancialBoldTextCSS>{`${this.props.earnings} ${i18nConfiguration('currency', this.props.currency)}`}</FinancialBoldTextCSS>
                        </FinancialLineCSS>
                    </FinancialWrapperCSS>
                </MouseOverPopover>


            </WrapperCSS>

        );
    }
}


export default EditableBookingOverview;
