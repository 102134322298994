import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Event from './pages/Event';
import Page404 from './pages/Page404';
import Landing from "./pages/Landing";
import Privacy from "./pages/Privacy";
import Admin from "./admin/Admin";
import Start from "./pages/Start";
import My from "./pages/My";

import Profile from "./pages/Profile";
import Register from "./pages/Register";
import HostRegister from "./pages/HostRegister";
import MyEvent from "./pages/MyEvent";
import Category from "./pages/Category";
import SearchPage from "./pages/SearchPage";
import HostMy from "./pages/HostMy";
import Host from "./pages/Host";
import HostMyEvent from "./pages/HostMyEvent";
import HostMyDraft from "./pages/HostMyDraft";
import RedirectPage from "./pages/RedirectPage";
import Auth from "./pages/Auth";
import Receipt from "./pages/Receipt";
import Score from "./pages/Score";
import Terms from "./pages/Terms";
import About from "./pages/About";
import Suspended from "./pages/Suspended";

import MuiThemeProvider from "@material-ui/core/es/styles/MuiThemeProvider";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import {adminTheme} from "./admin/adminTheme";

function AdminWrapper(props) {
    const { classes, theme } = props; // example
    return (
        <MuiThemeProvider theme={adminTheme}>
            <CssBaseline /> {/* initialize css */}
            <Admin />
        </MuiThemeProvider>
    );
}


ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/event/:id" component={Event} /> //
            <Route exact path="/landing" component={Landing} /> //
            <Route exact path="/" component={Start} /> //
            <Route exact path="/redirect/:site" component={RedirectPage} /> //
            <Route exact path="/profile" component={Profile} /> //
            <Route exact path="/register" component={Register} /> //
            <Route exact path="/register/:eventId" component={Register} /> //
            <Route exact path="/host/register" component={HostRegister} /> //
            <Route exact path="/my" component={My} /> //
            <Route exact path="/my/event/:id" component={MyEvent} /> //
            <Route exact path="/receipt/:id" component={Receipt} /> // ?
            <Route exact path="/score/:id" component={Score} /> // ?
            <Route exact path="/category/:id" component={Category} /> // ?
            <Route exact path="/search/:country/:city" component={SearchPage} /> // ?
            <Route exact path="/host/my" component={HostMy} /> //
            <Route exact path="/host/my/draft/:id" component={HostMyDraft} /> // ?
            <Route exact path="/host" component={Host} /> //
            <Route exact path="/host/my/event/:id" component={HostMyEvent} /> //
            <Route exact path="/privacy" component={Privacy} /> // ?
            <Route exact path="/terms" component={Terms} /> //
            <Route exact path="/admin" component={AdminWrapper} />
            <Route exact path="/auth" component={Auth} /> //
            <Route exact path="/about" component={About} /> //
            <Route exact path="/suspended" component={Suspended} /> //
            <Route path="*" component={Page404} />
        </Switch>
    </BrowserRouter>,
    document.querySelector('#app')
);

