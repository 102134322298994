import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { i18n, i18nConfiguration, i18nText } from "../common/I18n";
import Moment from "react-moment";
import moment from "moment";
import StyledProgress from "./StyledProgress";
import Card from "./Card";
import StyledCheckBox from "./StyledCheckBox";
import StyledFlatButton from "./StyledFlatButton";
import StyledTextField from "./StyledTextField";
import StyledButton from "./StyledButton";
import styled from "styled-components";
import asset_close from "../assets/close.svg";
import Carousel from "./Carousel";
import { CenterCSS } from "../common/Layout";
import asset_info from '../assets/info.svg';
import MouseOverPopover from "./MousePopover";






const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#FF6344',
            dark: '#FF6344',
            // contrastText: will be calculated to contast with palette.primary.main
        },
        secondary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
    },

    typography: {
        useNextVariants: true,
    },


    overrides: {
        MuiTypography: {
            root: {
                fontFamily: 'TruenoRegular',
            },
            title: {
                fontFamily: 'TruenoRegular',
                color: '#04ADA9',
            },
        },

        MuiDialogContentText: {
            root: {
                fontFamily: 'TruenoLight',
                marginBottom: "40px",
                minWidth: "400px"
            },
        },
        MuiButton: {
            root: {
                fontFamily: 'TruenoRegular',
            },
        },

        MuiStepLabel: {
            root: {
                fontFamily: 'TruenoLight',
            },
            label: {
                fontFamily: 'TruenoLight',
            },
        },
        MuiStepIcon: {
            text: {
                fontFamily: 'TruenoLight',
                fill: "#ffffff",
            },
            root: {
                color: '#EEEEEE',
                '&$active': {
                    color: '#04ADA9',
                    text: {
                        fill: '#EEEEEE',
                    }
                },
                '&$completed': {
                    color: '#04ADA9',
                    text: {
                        fill: '#EEEEEE',

                    },
                }
            }

        },
        MuiDialogActions: {
            root: {
                justifyContent: "space-evenly",
            },
        },
        MuiFormControlLabel: {
            root: {
                marginBottom: '24px',
            },
        },
    }
});

const Step5GridCSS = styled.div`
padding: 32px;
grid-template-columns: 50% 50%;
grid-template-rows: 10% 50% auto auto;
grid-column-gap: 24px;
grid-row-gap: 8px;
display: grid;
@media screen and (max-width: 700px) {
grid-template-columns: 100%;
grid-template-rows: 10% auto 10% auto auto 10%;
grid-template-areas: 
"title"
"event"
"promocode"
"payment"
"legal"
"button"
}
grid-template-areas: 
"title ."
"event payment"
"event legal"
"promocode button"
`;

const TitleCSS = styled.div`
grid-area: title;
`;

const TitleBlackCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
text-transform: uppercase;
`;

const TitleGreenCSS = styled(TitleBlackCSS)`
color: #04ADA9;
`;


const EventCSS = styled.div`
grid-area: event;
`;

const EventBoxCSS = styled.div`
width:100%;
height: 100%;
background: #FFFFFF;
box-shadow: 0 2px 9px 0 rgba(0,0,0,0.50);
border-radius: 8px;
min-height: 400px;
position: relative;
`;

const EventImageCSS = styled.div`
border-radius: 8px 8px 0 0;
background-image: url(${props => props.src});
height: 55%;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
position: absolute;
top:0px;
`;

const EventInfoCSS = styled.div`
height: 45%;
width: 100%;
position: absolute;
bottom:0px;
display: grid;
grid-template-columns: 5% 20% 40% 30% 5%;
grid-template-rows: 30% 40% 30%;
grid-template-areas: 
". infotitle infotitle infotitle ."
". infoprofile info info ."
". infoprice infoprice infoprice ."
`;

const EventInfoTitleCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 12px;
color: #818181;
letter-spacing: 1.29px;
line-height: 22px;
text-transform: uppercase;
grid-area: infotitle;
align-self: center;
`;

const EventInfoProfileCSS = styled.div`
background-image: url(${props => props.src});
background-size: cover;
background-repeat: no-repeat;
border-radius: 100%;
width: 60px;
height: 60px;
grid-area: infoprofile;
`;

const EventInfoInfoCSS = styled.div`
grid-area: info;
`;


const EventInfoInfo1CSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 12px;
color: #333333;
letter-spacing: 1.5px;
text-align: left;
line-height: 22px;
text-transform: uppercase;
`;

const EventInfoInfo2CSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 10px;
color: #E2E2E2;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
`;

const EventInfoPriceCSS = styled.div`
grid-area: infoprice;
position:relative;
display: flex;
justify-content:flex-end;
`;

const EventInfoPriceAmountCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 44px;
color: #04ADA9;
letter-spacing: -0.6px;
text-align: center;
line-height: 48px;
justify-self: center;

`;

const EventInfoPriceCurrencyCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 28px;
color: #04ADA9;
letter-spacing: -0.2px;
text-align: center;
line-height: 32px;
margin-left: 8px;
`;


const PaymentCSS = styled.div`
grid-area: payment;
align-self: start;
width: 100%;
justify-self: center;
`;

const LegalCSS = styled.div`
grid-area: legal;
justify-self: center;
`;

const PromoCodeCSS = styled.div`
grid-area: promocode;
`;

const LegalLabelCSS = styled.div`
    opacity: 0.8;
    font-Family: TruenoLight;
    font-size: 12.00px;
    color: #818181;
    letter-spacing: 0;
    line-height: 18.00px;
    align-items: start
    justify-self: center;
`;

const CaptchaCSS = styled.div`
margin-top: 40px;
display:block;
margin-left: auto;
margin-right: auto;
`;

const HintTextCSS = styled.div`
font-family: TruenoLight;
text-align: center;
margin-top: 16px;
color: #818181;
font-size: 16px;
`;

const HintCSS = styled.div`
position: absolute;
user-select: none;
top: 8px;
right: -40px;
display: block;
opacity: 0.4;
@media screen and (max-width: 600px) {
position: relative;
margin-left: auto;
margin-right: auto;
margin-top: 16px;
margin-bottom: 16px;
top:0px;
right: 0px;
}
cursor: help;
&:hover {
opacity: 1;
}
`;

const ButtonCSS = styled.div`
grid-area: button;
justify-self: center;
margin-top:24px;
@media screen and (max-width: 1100px) {
justify-self: center;
}
`;

const CloseCSS = styled.div`
opacity: 0.8;
display: flex;
justify-content: center;
align-items:center;
min-width: 42px;
min-height: 42px;
background-color: var(--green);
user-select: none;
border-radius: 100%;
position: absolute;
right:16px;
top:16px;
&:hover {
cursor: pointer;
opacity: 1;
}
&:active {
opacity: 0.8;
cursor: pointer;
}
`;

const InfoCSS = styled.div`
cursor: pointer;
margin-left: 8px;
`;

const FinancialWrapperCSS = styled.div`
display: flex;
flex-direction: column;
padding-left: 40px;
padding-right: 40px;
`;
const FinancialHeaderCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 18px;
@media screen and (max-width: 600px) {
    font-size: 14px;
}
color: #818181;
letter-spacing: 0;
width: 100%;
margin-top: 16px;
margin-bottom: 16px;
text-align: center;
`;
const FinancialLineCSS = styled.div`
display: flex;
@media screen and (max-width: 600px) {
flex-direction: column;
}
justify-content:space-between;
width: 100%;
margin-top: 16px;
margin-bottom: 16px;
`;
const FinancialTextCSS = styled.div`
opacity: 0.8;
font-family: TruenoLight;
font-size: 16px;
@media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 18px;
}
color: #818181;
letter-spacing: 0;
line-height: 26px;
`;
const FinancialBoldTextCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 16px;
@media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 18px;

}
color: #818181;
letter-spacing: 0;
line-height: 26px;
`;

class BookDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            legal: false,
            takeAway: false,
            promoCode: null,
            ccIndex: 0,
            anchorEl: null,

        };

    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleCheck(name, event) {
        this.setState({ [name]: event.target.checked });
    };

    handleCCChange = (index) => {
        this.setState({ ccIndex: index });
    };

    handlePopoverOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handlePopoverClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { fullScreen } = this.props;
        const isTakeAway = this.props.event.types.includes("takeAway");

        const nf = new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return (
            <MuiThemeProvider theme={theme}>

                <Dialog
                    fullScreen={fullScreen}
                    open={this.props.open}
                    onClose={this.props.onClose}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={"md"}
                    fullWidth={true}
                >
                    <CloseCSS onClick={this.props.onClose}>
                        <img src={asset_close} width={16} height={16} />
                    </CloseCSS>

                    <Step5GridCSS>
                        <TitleCSS>
                            <TitleBlackCSS>{i18nText(this.props.event.title)}</TitleBlackCSS>
                            <TitleGreenCSS><Moment
                                fromNow>{this.props.event.start.toDate()}</Moment></TitleGreenCSS>
                        </TitleCSS>
                        <EventCSS>
                            <EventBoxCSS>
                                <EventImageCSS src={this.props.event.images[this.props.event.mainImageIndex]} />
                                <EventInfoCSS>
                                    <EventInfoTitleCSS>
                                        {i18n("register/eventinfo/title",
                                            {
                                                city: i18nText(this.props.event.address.city),
                                                date: moment(this.props.event.date.toDate()).format("DD. MMM"),
                                                start: moment(this.props.event.start.toDate()).format("HH:mm"),
                                                end: moment(this.props.event.end.toDate()).format("HH:mm")
                                            })}
                                    </EventInfoTitleCSS>
                                    <EventInfoProfileCSS
                                        src={this.props.user.image} />
                                    <EventInfoInfoCSS>
                                        <EventInfoInfo1CSS>{`${this.props.user.firstname} ${this.props.user.lastname} ${(this.props.people > 0) ? "+" + this.props.people : ""}`}</EventInfoInfo1CSS>
                                        <EventInfoInfo2CSS>{this.props.user.email} </EventInfoInfo2CSS>
                                    </EventInfoInfoCSS>

                                    <EventInfoPriceCSS>
                                        <EventInfoPriceAmountCSS>

                                            {this.props.totalAmount}

                                        </EventInfoPriceAmountCSS>
                                        <EventInfoPriceCurrencyCSS>
                                            {i18nConfiguration('currency', this.props.currency)}
                                        </EventInfoPriceCurrencyCSS>
                                        <InfoCSS onMouseEnter={this.handlePopoverOpen}
                                            onMouseLeave={this.handlePopoverClose}>
                                            <img src={asset_info} />
                                        </InfoCSS>
                                    </EventInfoPriceCSS>
                                </EventInfoCSS>

                            </EventBoxCSS>
                        </EventCSS>
                        <PaymentCSS>
                            {this.props.stripeCustomers.length === 0 ? (
                                <div>
                                    <HintTextCSS>
                                        {i18n("bookDialog/noCC")}
                                    </HintTextCSS>
                                    <CenterCSS width={"40%"} marginTop={"40px"}>

                                        <StyledButton
                                            label={i18n("bookDialog/goToProfile")}
                                            onClick={(e) => {
                                                window.location = `/profile?back=/my/event/${this.props.event.id}`
                                            }}
                                            name="button_goToProfile"
                                            working={this.state.working}
                                            disabled={false} />


                                    </CenterCSS>
                                </div>

                            ) : (

                                    !this.props.displayPromoCode ? (

                                        <Carousel slidesToShow={1} slidesToShowL={1} slidesToShowM={1} slidesToShowS={1}
                                            width={"95%"}
                                            onChange={this.handleCCChange}>

                                            {this.props.stripeCustomers.map(stripeCustomer => {
                                                return (
                                                    <Card
                                                        key={stripeCustomer.data.sources.data[0].last4}
                                                        number={`**** **** **** ${stripeCustomer.data.sources.data[0].last4}`}
                                                        month={`${stripeCustomer.data.sources.data[0].exp_month}`.padStart(2, '0')}
                                                        year={`${stripeCustomer.data.sources.data[0].exp_year}`.slice(-2).padStart(2, '0')}
                                                        type={stripeCustomer.data.sources.data[0].brand.toLowerCase()}
                                                        cvc={"123"}
                                                        highlighted={null} />)
                                            })}
                                        </Carousel>) : (<div />)
                                )
                            }

                        </PaymentCSS>
                        <LegalCSS>

                            {!this.props.displayPromoCode &&
                                <React.Fragment>
                                    {isTakeAway &&
                                        <StyledCheckBox
                                            checked={this.state.takeAway}
                                            onChange={this.handleCheck.bind(this)}
                                            value={'takeAway'}
                                            marginBottom={'8px'}
                                            disabled={this.props.working}
                                            label={
                                                <LegalLabelCSS>{i18n('register/takeAway')}</LegalLabelCSS>}
                                        />
                                    }

                                    <StyledCheckBox
                                        checked={this.state.legal}
                                        onChange={this.handleCheck.bind(this)}
                                        value={'legal'}
                                        marginBottom={'8px'}
                                        disabled={this.props.working}
                                        label={
                                            <LegalLabelCSS>{i18n('register/legal')}</LegalLabelCSS>}
                                    />
                                </React.Fragment>
                            }

                        </LegalCSS>
                        {!this.props.hidePromoCode &&
                            <PromoCodeCSS>
                                {!this.props.displayPromoCode &&
                                    <StyledFlatButton label={i18n("register/promoCode")}
                                        onClick={this.props.onDisplayPromoCode}
                                        name="promoCode"
                                        working={this.props.working}
                                        disabled={false} />
                                }
                                {this.props.displayPromoCode &&
                                    <StyledTextField
                                        label={i18n("register/promoCodeLabel")}
                                        name="promoCode"
                                        value={this.state.promoCode}
                                        disabled={this.props.working}
                                        error={this.props.promoError}
                                        onChange={this.handleChange.bind(this)}
                                        required={false} />
                                }
                                {this.props.displayPromoCode &&
                                    <StyledFlatButton label={i18n("register/cancelPromoCode")}
                                        onClick={this.props.onCancelPromoCode}
                                        name="button_cancelPromoCode"
                                        working={this.props.working}
                                        disabled={false} />
                                }
                            </PromoCodeCSS>
                        }

                        <ButtonCSS>
                            {!this.props.displayPromoCode &&

                                <StyledButton label={i18n("register/confirm")}
                                    onClick={(name) => this.props.onBook(this.state.promoCode, this.props.stripeCustomers[this.state.ccIndex], this.state.takeAway)}
                                    name="button_book"
                                    working={this.props.working}
                                    disabled={!this.state.legal || this.props.stripeCustomers.length === 0} />
                            }

                            {this.props.displayPromoCode &&

                                <StyledButton label={i18n("register/redeemCode")}
                                    onClick={(name) => this.props.onRedeem(this.state.promoCode)}
                                    name="button_redeemCode"
                                    working={this.state.working}
                                    disabled={this.state.promoCode === null || this.state.promoCode.trim() === ""}
                                    />
                            }
                        </ButtonCSS>
                    </Step5GridCSS>
                </Dialog>

                <MouseOverPopover open={Boolean(this.state.anchorEl)} anchorEl={this.state.anchorEl}>
                    <FinancialWrapperCSS>
                        <FinancialHeaderCSS>{i18n("price/financial/header")}</FinancialHeaderCSS>
                        <FinancialLineCSS>
                            <FinancialTextCSS>{i18n("price/financial/basePrice",
                                {
                                    people: (1 + this.props.people),
                                    amount: nf.format(this.props.event.price.amount),
                                    currency: i18nConfiguration('currency', this.props.event.price.currency)
                                })}</FinancialTextCSS>
                            <FinancialTextCSS>{`${this.props.baseAmount} ${i18nConfiguration('currency', this.props.currency)}`}</FinancialTextCSS>
                        </FinancialLineCSS>

                        <FinancialLineCSS>
                            <FinancialTextCSS>{i18n("price/financial/servicePrice")}</FinancialTextCSS>
                            <FinancialTextCSS>{`${this.props.serviceAmount} ${i18nConfiguration('currency', this.props.currency)}`}</FinancialTextCSS>
                        </FinancialLineCSS>
                        <FinancialLineCSS>
                            <FinancialBoldTextCSS>{i18n("price/financial/sum")}</FinancialBoldTextCSS>
                            <FinancialBoldTextCSS>{`${this.props.sumAmount} ${i18nConfiguration('currency', this.props.currency)}`}</FinancialBoldTextCSS>
                        </FinancialLineCSS>
                        <FinancialLineCSS>
                            <FinancialTextCSS>{i18n("price/financial/voucher")}</FinancialTextCSS>
                            <FinancialTextCSS>{this.props.voucherAmount === 0 ? "./." : `${this.props.voucherAmount} ${i18nConfiguration('currency', this.props.currency)}`}</FinancialTextCSS>
                        </FinancialLineCSS>
                        <hr width={"100%"} />
                        <FinancialLineCSS>
                            <FinancialBoldTextCSS>{i18n("price/financial/total")}</FinancialBoldTextCSS>
                            <FinancialBoldTextCSS>{`${this.props.totalAmount} ${i18nConfiguration('currency', this.props.currency)}`}</FinancialBoldTextCSS>
                        </FinancialLineCSS>
                        <hr width={"100%"} />
                        <FinancialLineCSS>
                            <FinancialTextCSS>{i18n("price/financial/hints/text")}</FinancialTextCSS>
                        </FinancialLineCSS>
                    </FinancialWrapperCSS>
                </MouseOverPopover>
            </MuiThemeProvider>
        );
    }
}

BookDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(BookDialog);