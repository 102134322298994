import React, {Component} from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';

import {i18nText, i18n, i18nLocale} from "../common/I18n";


const Wrapper = styled.div`
    padding-left: 10.0%;
    padding-right: 33%;
    padding-bottom: 24px;
    background-color: rgba(239, 178, 85, 0.03);
    @media screen and (max-width: 1100px) {
    padding-right: 10%;

}
`;

const Title = styled.div`
    opacity: 0.8;
    font-family: TruenoRegular;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    text-transform: uppercase;
    padding-top: 24px;
    padding-bottom: 24px;

`;

const K_WIDTH = 150;
const K_HEIGHT = 150;

const locationStyle = {

    position: 'absolute',
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2,

    border: '1px solid #FF6344',
    borderRadius: K_HEIGHT,
    backgroundColor: '#FF6344',
    opacity:0.3,
};


class Address extends Component {

    constructor(props) {
        super(props);


    }

    render() {

        const OPTIONS = {
            minZoom: 6,
            maxZoom: 19,
        }

        return (
            <Wrapper>

                <Title>{i18n('address/title')}</Title>




                <div style={{ height: '500px', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyCcdL6ifwm4SQ6IhPHnWHt92sQE1CTaJyE", language: i18nLocale(),
                            region: i18nLocale(), }}
                        center={{lat: this.props.location.latitude, lng: this.props.location.longitude}}
                        defaultZoom={17}
                        options = {OPTIONS}

                    >

                        <div style={locationStyle} lat={this.props.location.latitude} lng={this.props.location.longitude} />


                    </GoogleMapReact>
                </div>


            </Wrapper>

        );
    }
}

export default Address;
