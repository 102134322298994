import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';
import MUIDataTable from "mui-datatables";
import firebaseApp from "../common/firebase";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";
import moment from "moment";
import {isNullOrUndefined} from "util";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button/Button";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import CheckedIcon from "@material-ui/icons/CheckBox";
import UncheckedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Avatar from "@material-ui/core/Avatar/Avatar";
import placeholder from "../assets/placeholder.svg";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import RefreshIcon from "@material-ui/icons/Refresh";


const firestore = firebaseApp.firestore();


const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
});

class Testimonials extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            working: false,
            values: [],
            showDialog: false,
            editMode: false,
            value: null,
            visible: false,
            priority: "0",
            text: "",
        };
    }


    componentDidMount() {
        this.reload();
    }

    reload = () => {
        this.setState({loading: true}, async () => {
            try {

                let fsValues = await firestore.collection("testimonials").get();

                let values = [];

                for (const fsValue of fsValues.docs) {

                    const {created, ...other} = fsValue.data();
                    values.push({created: created.toDate(), ...other, ref: fsValue.ref});
                }

                this.setState({values: values, loading: false});
            } catch (error) {
                console.error(error);
                this.setState({loading: false});
            }

        })

    };

    handleDelete = (selectedRows) => {

        this.setState({working: true}, async () => {
            try {

                const batch = firestore.batch();

                for (const selectedRow of selectedRows.data) {

                    batch.delete(this.state.values[selectedRow.dataIndex].ref);
                }

                await batch.commit();

                this.setState({working: false}, () => {
                    this.reload();
                });

            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });

    };

    handleChange = event => {

        let value = event.target.value;
        this.setState({[event.target.name]: value});
    };

    handleChangedSwitch = name => event => {
        this.setState({[name]: event.target.checked});
    };

    openDialog = () => {
        this.setState({
            showDialog: true,

            visible: this.state.value.visible,
            priority: this.state.value.priority,
            text: this.state.value.text,

            editMode: true
        })

    };

    closeDialog = () => {
        this.setState({
            showDialog: false,
            visible: false,
            priority: "0",
            text: "",
            editMode: false,
            value: null
        })
    };

    handleSave = () => {
        this.setState({working: true}, async () => {

            const priority = parseInt(this.state.priority);

            await this.state.value.ref.set({
                visible: this.state.visible,
                priority: priority,
                text: this.state.text,
            }, {merge: true});


            this.setState({
                showDialog: false,
                working: false,
                visible: false,
                priority: "0",
                text: "",
                value: null
            }, () => {
                this.reload();
            })
        })
    };

    render() {
        const {classes} = this.props;

        const columns = [
            {

                name: "Action",
                field: "action",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Tooltip title={"Edit entry"}>
                                <IconButton onClick={(e) => this.setState({value: value}, () => this.openDialog())}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>

                        );
                    },
                },

            },
            {

                name: "Visible",
                field: "visible",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {(value === true) ? (<CheckedIcon/>) : (<UncheckedIcon/>)}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Text",
                field: "text",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {

                name: "Who",
                field: "who",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <ListItem>
                                {value.src ? (
                                    <Avatar
                                        alt={value.alt}
                                        src={value.src}
                                    />
                                ) : (
                                    <Avatar
                                        alt={value.alt}
                                        src={placeholder}
                                    />)}
                                <ListItemText primary={value.primary} secondary={value.secondary}/>
                            </ListItem>

                        );
                    },

                }
            },
            {

                name: "Whom",
                field: "whom",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <ListItem>
                                {value.src ? (
                                    <Avatar
                                        alt={value.alt}
                                        src={value.src}
                                    />
                                ) : (
                                    <Avatar
                                        alt={value.alt}
                                        src={placeholder}
                                    />)}
                                <ListItemText primary={value.primary} secondary={value.secondary}/>
                            </ListItem>

                        );
                    },
                }
            },
            {

                name: "Priority",
                field: "priority",
                options: {
                    filter: true,
                    sort: true,
                    display: "false",

                }
            },
            {

                name: "Uid",
                field: "uid",
                options: {
                    filter: true,
                    sort: true,
                    display: "false",

                }
            },
            {

                name: "Created",
                field: "created",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {moment(value).format("YYYY-MM-DD, HH:mm:ss")}
                            </React.Fragment>

                        );
                    },
                }
            },
        ];


        const options = {
            filter: false,
            selectableRows: 'multiple',
            responsive: 'scrollFullHeight',
            customToolbar: () =>
                <Tooltip title={"Reload Data"}>
                    <IconButton className={classes.iconButton}
                                onClick={this.reload}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>,
            customToolbarSelect: (selectedRows) =>
                <StyledCustomToolbarSelect
                    selectedRows={selectedRows}
                    onDelete={this.handleDelete}/>

        };

        const data = this.state.values.map(value => {

            const line = [];

            for (const column of columns) {
                let field = value[column.field];

                if (column.field === "action") {
                    field = value;
                }

                if (column.field === "who") {
                    field = {
                        src: value.who.image,
                        alt: value.who.firstname,
                        primary: value.who.firstname,
                        secondary: value.who.uid
                    };
                }

                if (column.field === "whom") {
                    field = {
                        src: value.whom.image,
                        alt: `${value.whom.firstname} ${value.whom.lastname}`,
                        primary: `${value.whom.firstname} ${value.whom.lastname}`,
                        secondary: value.uid
                    };
                }

                if (isNullOrUndefined(field)) {
                    field = "-"
                }
                line.push(field);
            }

            return line;
        });

        const isValidPriority = !isNaN(this.state.priority) && parseFloat(this.state.priority) >= 0;
        const isValidText = this.state.text.trim().length > 0;

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page}) => (
                        <React.Fragment>

                            {this.state.loading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress className={classes.progress} size={50}/>
                                    </Grid>
                                ) :
                                (
                                    <React.Fragment>

                                        <MUIDataTable
                                            columns={columns}
                                            data={data}
                                            title={page.title}
                                            options={options}
                                        />

                                    </React.Fragment>

                                )}


                            <Dialog
                                open={this.state.showDialog}
                                aria-labelledby="form-dialog-title"
                            >

                                <React.Fragment>
                                    <DialogTitle
                                        id="form-dialog-title">Edit {this.state.title} of {page.title}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            Edit the values
                                        </DialogContentText>

                                        <TextField
                                            value={this.state.text}
                                            margin="dense"
                                            id="text"
                                            name="text"
                                            label="Text"
                                            type="text"
                                            multiline={true}
                                            rows={5}
                                            rowsMax={5}
                                            error={!isValidText}
                                            helperText={!isValidText ? "Enter text" : ""}
                                            onChange={this.handleChange}
                                            fullWidth
                                            disabled={this.state.working}
                                        />

                                        <TextField
                                            value={this.state.priority}
                                            margin="dense"
                                            id="priority"
                                            name="priority"
                                            label="Priority"
                                            type="number"
                                            error={!isValidPriority}
                                            helperText={!isValidPriority ? "Only positiv numbers" : ""}
                                            onChange={this.handleChange}
                                            fullWidth
                                            disabled={this.state.working}
                                        />

                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.visible}
                                                    onChange={this.handleChangedSwitch('visible')}
                                                    value="visible"
                                                />
                                            }
                                            label="is visible"
                                        />


                                    </DialogContent>

                                    <DialogActions>
                                        <Button onClick={this.closeDialog} color="primary"
                                                disabled={this.state.working}>
                                            Close
                                        </Button>
                                        <Button onClick={this.handleSave} color="primary" autoFocus
                                                disabled={this.state.working || !isValidText || !isValidPriority}>
                                            Save
                                        </Button>
                                    </DialogActions>
                                </React.Fragment>


                            </Dialog>


                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

Testimonials.propTypes = {
    classes: PropTypes.object.isRequired,
};

const defaultToolbarSelectStyles = {
    iconButton: {
        marginRight: "24px",
        top: "50%",
        display: "inline-block",
        position: "relative",
        transform: "translateY(-50%)",
    },
};

class CustomToolbarSelect extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <div className={"custom-toolbar-select"}>
                <Tooltip title={"Delete"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onDelete(this.props.selectedRows)}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

}

const StyledCustomToolbarSelect = withStyles(defaultToolbarSelectStyles, {name: "CustomToolbarSelect"})(CustomToolbarSelect);

export default withStyles(styles, {withTheme: true})(Testimonials)