import React, {Component} from 'react';

import styled from 'styled-components';
import {i18n, i18nConfiguration, i18nText} from "../common/I18n";

import asset_message from "../assets/message.svg"
import asset_copy from "../assets/copy.svg"
import asset_more from "../assets/more_lined.svg"
import asset_placeholder from "../assets/placeholder.svg"


import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';

import Moment from 'react-moment';
import 'moment/locale/de';
import moment from "moment/moment";

import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip'
import firebaseApp from "../common/firebase";
import {uuidv4} from "../common/utils";

firebaseApp.auth().useDeviceLanguage();
const firestore = firebaseApp.firestore();


const OuterWrapperCSS = styled.div`
width:100%;
height:600px;
user-select: none;
`;

const WrapperCSS = styled.div`
width:100%;
height:520px;
display: flex;
`;

const TitleBoxCSS = styled.div`
height: 80px;
align-items: center;
`;

const TitleBlackCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
text-transform: uppercase;
`;

const TitleGreenCSS = styled(TitleBlackCSS)`
color: #04ADA9;
`;

const BoxCSS = styled.div`
opacity: 1;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 3px;
width: 97%;
height: 97%;
background: #FFFFFF;
box-shadow: 0 1px 1px 0 rgba(0,0,0,0.50);
border-radius: 8px;
user-select: none;
&:hover {
box-shadow: 0 2px 7px 0 rgba(0,0,0,0.50);
cursor: pointer;
}
&:active {
cursor: pointer;

}
`;

const ImageCSS = styled.div`
   background-image:  url(${props => props.src});
   background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 60%;
`;

const InfoCSS = styled.div`
    border-radius: 0px 0px 8px 8px;
    width: 100%;
    height: 40%;
    position: relative;
`;

const TitleCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 12px;
color: #818181;
letter-spacing: 1.29px;
line-height: 22px;
text-transform: uppercase;
padding-left: 16px;
padding-top: 6px;

`;

const DateCSS = styled.div`
opacity: 0.6;
font-family: TruenoLight;
font-size: 9px;
color: #818181;
letter-spacing: 0.96px;
line-height: 22px;
text-transform: uppercase;
padding-left: 16px;
`;

const GuestTitleCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 9px;
color: #04ADA9;
letter-spacing: 0.96px;
line-height: 22px;
text-transform: uppercase;
padding-left: 16px;
padding-top: 6px;
`;

const AmountTitleCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 9px;
color: #04ADA9;
letter-spacing: 0.96px;
line-height: 22px;
text-transform: uppercase;
padding-left: 16px;
padding-top: 16px;
`;

const ActionsCSS = styled.div`
position: absolute;
bottom: 8px;
display: flex;
justify-content:space-evenly;
width: 90%;
margin-left: auto;
margin-right: auto;
z-index: 10;
`;

const GuestsCSS = styled.div`
display: flex;
justify-content:flex-start;
width: 90%;
margin-left: auto;
margin-right: auto;
margin-top: 16px;
`;

const ProfileImageCSS = styled.img`
width: 32px;
height: 32px;
object-fit: cover;
border-radius: 100%;
`;

const ProfileCSS = styled.div`
width: 32px;
height: 32px;
margin-right: 16px;
position: relative;
`;

const SeatsCSS = styled.div`
opacity: 0.7;
background: #04ADA9;
color: #ffffff;
right:-10px;
position: absolute;
top:16px;
font-family: TruenoLight;
font-size: 12px;
padding: 4px;
border-radius: 100%;
text-align: left;
z-index: 10;
`;


const ActionCSS = styled.div`
width:30%;
opacity: 0.6;
display: flex;
justify-content:space-evenly;
&:hover {
opacity: 1;
}
&:active {
opacity: 0.5;
}
z-index: 99;
`;

const IconCSS = styled.img`

`;

const LabelCSS = styled.div`
font-family: TruenoLight;
font-size: 11px;
color: #FF6344;
letter-spacing: -0.16px;
text-transform: uppercase;
padding-left: 8px;
padding-top: 6px;
`;

const styles = theme => ({
    lightTooltip: {
        background: theme.palette.common.white,
        color: theme.palette.text.primary,
        fontFamily: 'TruenoLight',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
});

const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#FF6344',
            dark: '#FF6344',
            // contrastText: will be calculated to contast with palette.primary.main
            contrastText: '#ffffff'
        },

    },

    typography: {
        useNextVariants: true,
    },

    overrides: {

        MuiBadge: {
            badge: {
                fontFamily: "TruenoLight",
                color: '#5C5C5C',

            }
        },

    }


});


class Guest extends Component {
    constructor(props) {
        super(props);


    }

    render() {
        return (
            <ProfileCSS onClick={this.props.onClick}>

                {(this.props.badge > 0) ?
                    (
                        <Badge badgeContent={this.props.badge} color="primary">
                            <ProfileImageCSS src={this.props.image}/>
                        </Badge>
                    ) :
                    (
                        <ProfileImageCSS src={this.props.image}/>
                    )
                }

                {(this.props.seats > 0) &&
                <SeatsCSS>{"+" + this.props.seats}</SeatsCSS>
                }
            </ProfileCSS>
        )
    }

}

class HostEventBlock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            count: {}
        }
    }

    UNSAFE_componentWillMount() {

        firestore.collection('messages').where("eventId", "==", this.props.event.id).where("hostUid", "==", this.props.event.uid).where("readByHost", "==", false).onSnapshot(
            fsMessages => {
                const count = {};
                for (const fsMessage of fsMessages.docs) {
                    count[fsMessage.data().guestUid] = count[fsMessage.data().guestUid] ? count[fsMessage.guestUid] + 1 : 1;
                }

                this.setState({count: count});
            }
        );

    }

    render() {

        const {classes} = this.props;

        const total = this.props.event.bookings.reduce((sum, booking) => {
            return sum + ((booking.seats + 1) * this.props.event.price.amount)
        }, 0);


        return (


            <MuiThemeProvider theme={theme}>

                <OuterWrapperCSS>

                    {!this.props.empty ? (
                        <TitleBoxCSS>
                            <TitleBlackCSS>{i18nText(this.props.event.title)}</TitleBlackCSS>
                            <TitleGreenCSS>{this.props.event.status === "canceled" ? i18n(`host/event/${this.props.event.status}`) :
                                <Moment
                                    fromNow>{this.props.event.start.toDate()}</Moment>}</TitleGreenCSS>
                        </TitleBoxCSS>
                    ) : (
                        <TitleBoxCSS>
                            <TitleBlackCSS>...</TitleBlackCSS>
                        </TitleBoxCSS>
                    )
                    }
                    <WrapperCSS>
                        {!this.props.empty ? (
                            <BoxCSS onClick={(event) => window.location = `/host/my/event/${this.props.event.id}`}>
                                <ImageCSS
                                    src={this.props.event.images[this.props.event.mainImageIndex]}/>
                                <InfoCSS>
                                    <TitleCSS>{i18n("host/event/title", {
                                        city: i18nText(this.props.event.address.city),
                                    })}</TitleCSS>

                                    <DateCSS>{i18n("host/event/date", {
                                        date: moment(this.props.event.start.toDate()).format("DD. MMM"),
                                        start: moment(this.props.event.start.toDate()).format("HH:mm"),
                                        end: moment(this.props.event.end.toDate()).format("HH:mm")
                                    })}</DateCSS>

                                    {this.props.event.currentSeats === 0 &&
                                    <GuestTitleCSS>
                                        {i18n("host/event/noGuest")}
                                    </GuestTitleCSS>

                                    }

                                    {this.props.event.currentSeats === 1 &&
                                    <GuestTitleCSS>
                                        {i18n("host/event/1Guest")}
                                    </GuestTitleCSS>

                                    }

                                    {this.props.event.currentSeats > 1 &&
                                    <GuestTitleCSS>
                                        {i18n("host/event/guests", {amount: this.props.event.currentSeats})}
                                    </GuestTitleCSS>

                                    }


                                    <GuestsCSS>

                                        {(this.props.event.bookings.filter(booking => booking.status === "ok" || booking.status === "confirmed" || booking.status === "paymentScheduled").length < 1) ?
                                            (
                                                <Guest image={asset_placeholder}/>
                                            ) : (
                                                this.props.event.bookings.filter(booking => booking.status === "ok" || booking.status === "confirmed" || booking.status === "paymentScheduled").map((booking, index) => {
                                                    if (index < 5) {
                                                        return (
                                                            <Guest
                                                                key={uuidv4()}
                                                                image={booking.guest.image}
                                                                seats={booking.seats}
                                                                badge={this.state.count[booking.uid] ? this.state.count[booking.uid] : 0}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    this.props.onChat(this.props.event, booking);
                                                                }}/>
                                                        )
                                                    }
                                                    if (index === 5) {
                                                        return (
                                                            <div>...</div>
                                                        )
                                                    }
                                                })
                                            )
                                        }

                                    </GuestsCSS>

                                    <AmountTitleCSS>
                                        {i18n("host/event/financial", {
                                            currency: i18nConfiguration('currency', this.props.event.price.currency),
                                            total: total,
                                            price: this.props.event.price.amount,
                                        })}
                                    </AmountTitleCSS>


                                    <ActionsCSS>
                                        {this.props.event.currentSeats > 0 &&

                                        <Tooltip title={i18n("tooltip/message")}
                                                 classes={{tooltip: classes.lightTooltip}}>

                                            <ActionCSS onClick={(event) => {
                                                event.stopPropagation()
                                                this.props.onMessageAll(this.props.event)
                                            }}>
                                                <IconCSS src={asset_message}/>
                                                <LabelCSS>{i18n("host/event/message")}</LabelCSS>
                                            </ActionCSS>
                                        </Tooltip>
                                        }

                                        <Tooltip title={i18n("tooltip/copyEvent")}
                                                 classes={{tooltip: classes.lightTooltip}}>
                                            <ActionCSS onClick={(event) => {
                                                event.stopPropagation()
                                                this.props.onClone(this.props.event);
                                            }}>
                                                <IconCSS src={asset_copy}/>
                                                <LabelCSS>{i18n("host/event/copy")}</LabelCSS>
                                            </ActionCSS>
                                        </Tooltip>
                                        {false &&

                                        <Tooltip title={i18n("tooltip/more")} classes={{tooltip: classes.lightTooltip}}>

                                            <ActionCSS onClick={(event) => {
                                                event.stopPropagation()
                                            }}>
                                                <IconCSS src={asset_more}/>
                                                <LabelCSS>{i18n("host/event/more")}</LabelCSS>
                                            </ActionCSS>
                                        </Tooltip>
                                        }
                                    </ActionsCSS>


                                </InfoCSS>

                            </BoxCSS>
                        ) : (
                            <BoxCSS/>
                        )
                        }
                    </WrapperCSS>
                </OuterWrapperCSS>
            </MuiThemeProvider>

        );
    }
}

export default withStyles(styles)(HostEventBlock);
