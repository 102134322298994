import React, {Component} from 'react';

import styled from 'styled-components';
import {i18n, i18nConfiguration, i18nText} from "../common/I18n";
import Rating from "../components/Rating";
import IconButton from "@material-ui/core/IconButton/IconButton";
import heart from "../assets/heart.svg";
import empty_heart from "../assets/heart_empty.svg";
import share from "../assets/googleshare.svg";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton, WhatsappIcon, WhatsappShareButton
} from "react-share";
import {isNullOrUndefined} from "util";
import {calcFee, nf} from "../common/utils";
import {fees} from "../common/configuration";


const WrapperCSS = styled.div`
width:100%;
height:345px;
display: flex;
position: relative;

`;

const BoxCSS = styled.a`
opacity: 1;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 3px;
width: 97%;
height: 97%;
background: #FFFFFF;
box-shadow: 0 1px 1px 0 rgba(0,0,0,0.50);
border-radius: 8px;
user-select: none;
&:hover {
cursor: pointer;
box-shadow: 0 2px 7px 0 rgba(0,0,0,0.50);
}
`;

const ImageCSS = styled.div`
   background-image:  url(${props => props.src});
   background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 70%;
`;

const InfoCSS = styled.div`
    border-radius: 0px 0px 8px 8px;
    width: 100%;
    height: 30%;
    position: relative;
`;

const TitleCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 12px;
color: #818181;
letter-spacing: 1.29px;
line-height: 22px;
text-transform: uppercase;
padding-left: 16px;
padding-top: 6px;

`;

const CityCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #E2E2E2;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
padding-left: 16px;
bottom: 20px;
position: absolute;
`;

const AmountCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 22px;
color: #04ADA9;
letter-spacing: -0.6px;
text-align: right;
line-height: 22px;
`;

const CurrencyCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 14px;
color: #04ADA9;
letter-spacing: -0.2px;
text-align: center;
line-height: 16px;
top: 0px;
right: 0px;
`;

const PriceCSS = styled.div`
position: absolute;
right: 16px;
top: 30px;
display: flex;
`;

const SpecialCSS = styled.div`
opacity: 0.2;
background-image: linear-gradient(270deg, #36BDBA 32%, rgba(4,173,169,0.00) 100%);
position: absolute;
right: 0px;
bottom: 0px;
border-radius: 0 0 8px 0;
width: 50%;
line-height: 22px;
`;

const TagCSS = styled.div`
opacity: 1;
font-family: TruenoRegular;
font-size: 14px;
color: #04ADA9;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
text-transform: uppercase;
right: 0px;
bottom: 0px;
position: absolute;
padding-right: 8px;
`;

const ToolsCSS = styled.div`
position: absolute;
right:8px;
top:8px;
display: flex;
flex-direction: column;
justify-content: space-between;
z-index: 9;
`;

const Heart = styled.img`
width: 22px;
height: 22px;
opacity: 1;
&:hover {
cursor: pointer;
`;

const Share = styled.img`
width: 22px;
height: 22px;
opacity: 1;
&:hover {
opacity: 1;
cursor: pointer;
`;

class EventBlock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorShareEl: null,
            working:false
        }


    }

    handleShare = event => {
        this.setState({anchorShareEl: event.currentTarget});
    };

    handleClose = event => {
        this.setState({anchorShareEl: null});
    };

    render() {

        return (
            <WrapperCSS>
                <BoxCSS onClick={e => window.location = `/my/event/${this.props.event.id}`}>
                    <ToolsCSS style={{display: this.state.working ? "none" : "flex"}}>


                        {this.props.active &&
                        <IconButton
                            aria-haspopup="true"
                            color="inherit"
                            onClick={(event) => {
                                event.stopPropagation();
                                this.props.onFavorite(this.props.id);
                            }}
                        >
                            <Heart src={this.props.favorited ? heart : empty_heart}/>
                        </IconButton>
                        }

                        <IconButton
                            aria-haspopup="true"
                            color="inherit"
                            onClick={(event) => {
                                event.stopPropagation();
                                this.handleShare(event);
                            }}
                        >
                            <Share src={share}/>
                        </IconButton>

                        <Menu
                            id="share-menu"
                            anchorEl={this.state.anchorShareEl}
                            open={Boolean(this.state.anchorShareEl)}
                            onClose={this.handleClose}
                            style={{zIndex: 10}}
                            onClick={(event) => {
                                event.stopPropagation()
                            }}
                        >
                            <MenuItem>
                                <FacebookShareButton
                                    url={`https://${window.location.hostname}/api/shared/event/${this.props.event.id}`}
                                    quote={i18nText(this.props.event.title)}>
                                    <FacebookIcon
                                        size={32}
                                        round/>
                                </FacebookShareButton>
                            </MenuItem>

                            <MenuItem>
                                <TwitterShareButton
                                    url={`https://${window.location.hostname}/api/shared/event/${this.props.event.id}`}
                                    title={i18nText(this.props.event.title)}>
                                    <TwitterIcon
                                        size={32}
                                        round/>
                                </TwitterShareButton>
                            </MenuItem>

                            <MenuItem>
                                <TelegramShareButton
                                    url={`https://${window.location.hostname}/api/shared/event/${this.props.event.id}`}
                                    title={i18nText(this.props.event.title)}>
                                    <TelegramIcon size={32} round/>
                                </TelegramShareButton>
                            </MenuItem>

                            <MenuItem>
                                <WhatsappShareButton
                                    url={`https://${window.location.hostname}/api/shared/event/${this.props.event.id}`}
                                    title={i18nText(this.props.event.title)}
                                    separator=" :: ">
                                    <WhatsappIcon size={32} round/>
                                </WhatsappShareButton>
                            </MenuItem>

                            <MenuItem>
                                <EmailShareButton
                                    url={`https://${window.location.hostname}/api/shared/event/${this.props.event.id}`}
                                    title={i18nText(this.props.event.title)}>
                                    <EmailIcon
                                        size={32}
                                        round/>
                                </EmailShareButton>
                            </MenuItem>
                        </Menu>

                    </ToolsCSS>
                    <ImageCSS src={this.props.event.images[this.props.event.mainImageIndex]}/>
                    <InfoCSS>
                        <TitleCSS>{i18n("eventBlock/title", {
                            type: i18nConfiguration("type", this.props.event.types[0]),
                            name: this.props.event.host.firstname
                        })}</TitleCSS>
                        <Rating style={{
                            width: '33%',
                            marginLeft: '14px',
                            marginRight: '0',
                            paddingTop: '6px'

                        }} score={this.props.event.host.score}></Rating>
                        <CityCSS>{i18nText(this.props.event.address.city)}</CityCSS>

                        <PriceCSS>
                            <AmountCSS>{nf.format(this.props.event.price.amount + calcFee(this.props.event.price.amount, fees["basic"]))}</AmountCSS>

                            <CurrencyCSS>{i18nConfiguration('currency', this.props.event.price.currency)}</CurrencyCSS>
                        </PriceCSS>

                        {!isNullOrUndefined(this.props.event.tag) &&
                        <div>
                            <SpecialCSS>
                                &nbsp;
                            </SpecialCSS>
                            <TagCSS>
                                {i18nText(this.props.event.tag.title)}
                            </TagCSS>
                        </div>
                        }

                    </InfoCSS>

                </BoxCSS>
            </WrapperCSS>
        );
    }
}

export default EventBlock;
