import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';
import MUIDataTable from "mui-datatables";
import firebaseApp from "../common/firebase";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";
import moment from "moment";
import {isNullOrUndefined} from "util";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Avatar from "@material-ui/core/Avatar/Avatar";
import placeholder from "../assets/placeholder.svg";
import Chip from "@material-ui/core/Chip/Chip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import StarIcon from "@material-ui/icons/Star";
import EmptyStarIcon from "@material-ui/icons/StarBorder";
import BlockIcon from "@material-ui/icons/Block";
import ImageIcon from "@material-ui/icons/Image";
import Popover from "@material-ui/core/Popover/Popover";
import FlagIcon from "@material-ui/icons/Flag";
import RefreshIcon from "@material-ui/icons/Refresh";
import {Link} from 'react-router-dom'


const firestore = firebaseApp.firestore();


const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
    chip: {
        margin: theme.spacing.unit * 0.5,
    },
    popOverImage: {
        width: "400px",
    },
});

class Events extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            elements: [],
        };
    }


    UNSAFE_componentWillMount() {
        this.reload();
    }

    reload = () => {
        this.setState({loading: true}, async () => {
            try {


                let fsElements = await firestore.collection("events").orderBy("created", "desc").get();

                let elements = [];

                for (const fsElement of fsElements.docs) {


                    let fsProfile = await firestore.collection("profiles").doc(fsElement.data().uid).get();

                    const email = fsProfile.data().email;
                    const language = fsProfile.data().language;
                    const firstname = fsProfile.data().firstname;
                    const lastname = fsProfile.data().lastname;

                    const {created, date, start, end, cancelUntil, payOn, ...other} = fsElement.data();
                    const element = {
                        created: created.toDate(),
                        date: date.toDate(),
                        firstname: firstname,
                        lastname: lastname,
                        start: start.toDate(),
                        end: end.toDate(),
                        cancelUntil: cancelUntil.toDate(),
                        payOn: payOn.toDate(),
                        ...other,
                        email: email,
                        language: language,
                        id: fsElement.id
                    };
                    elements.push(element)
                }

                this.setState({elements: elements, loading: false});
            } catch (error) {
                console.error(error);
                this.setState({loading: false});
            }

        })

    };

    handleStar = (selectedRows) => {


        this.setState({working: true}, async () => {
            try {

                const batch = firestore.batch();

                for (const selectedRow of selectedRows.data) {
                    batch.set(
                        firestore.collection("events").doc(this.state.elements[selectedRow.dataIndex].id),
                        {
                            star: true,
                            changed: new Date(),
                        }, {merge: true});
                }

                await batch.commit();

                this.setState({working: false}, () => {
                    this.reload();
                });
            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });

    };

    handleUnstar = (selectedRows) => {


        this.setState({working: true}, async () => {
            try {

                const batch = firestore.batch();

                for (const selectedRow of selectedRows.data) {
                    batch.set(
                        firestore.collection("events").doc(this.state.elements[selectedRow.dataIndex].id),
                        {
                            star: false,
                            changed: new Date(),
                        }, {merge: true});
                }

                await batch.commit();

                this.setState({working: false}, () => {
                    this.reload();
                });
            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });

    };

    handleCancel = (selectedRows) => {


        this.setState({working: true}, async () => {
            try {

                const batch = firestore.batch();

                for (const selectedRow of selectedRows.data) {
                    batch.set(
                        firestore.collection("events").doc(this.state.elements[selectedRow.dataIndex].id),
                        {
                            status: "canceledByAdmin",
                            changed: new Date(),
                        }, {merge: true});
                }

                await batch.commit();

                this.setState({working: false}, () => {
                    this.reload();
                });
            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });

    };

    render() {
        const {classes} = this.props;

        const columns = [
            {

                name: "Status",
                field: "status",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {

                name: "Flagged",
                field: "flagged",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {(value === true) &&
                                <Tooltip title={"Event is flagged"}>
                                    <FlagIcon color={"secondary"}/>
                                </Tooltip>}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Fee",
                field: "fee",
                options: {
                    display: "false",
                    filter: true,
                    sort: true,
                }
            },
            {

                name: "Created",
                field: "created",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {moment(value).format("YYYY-MM-DD, HH:mm:ss")}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Link",
                field: "link",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Link to={value} target={"__new"}>{value}</Link>
                        );
                    },
                }
            },
            {

                name: "Cancel Until",
                field: "cancelUntil",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {moment(value).format("YYYY-MM-DD, HH:mm:ss")}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Pay On",
                field: "payOn",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {moment(value).format("YYYY-MM-DD, HH:mm:ss")}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Star",
                field: "star",
                options: {
                    filter: true,
                    sort: true,
                    display: "false",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {(value === true) ? (
                                    <Tooltip title={"Event is starred"}>
                                        <StarIcon/>
                                    </Tooltip>) : (
                                    <Tooltip title={"Event is not starred"}>
                                        <EmptyStarIcon/>
                                    </Tooltip>)}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Image",
                field: "image",
                options: {
                    display: "false",
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Tooltip title={value.alt}>

                                {value.src ? (
                                    <Avatar
                                        alt={value.alt}
                                        src={value.src}
                                    />
                                ) : (
                                    <Avatar
                                        alt={value.alt}
                                        src={placeholder}
                                    />)}

                            </Tooltip>

                        );
                    },
                }
            },
            {

                name: "City",
                field: "city",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {

                name: "Name",
                field: "name",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "About",
                field: "about",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "E-Mail",
                field: "email",
                options: {
                    display: "false",
                    filter: false,
                    sort: true
                }
            },
            {

                name: "Score",
                field: "score",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {

                name: "Title",
                field: "title",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                }
            },
            {

                name: "Date",
                field: "date",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {moment(value).format("YYYY-MM-DD")}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Description",
                field: "description",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Types",
                field: "types",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (values, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>

                                {values.length === 0 ? (
                                    <div>-</div>
                                ) : (
                                    values.map(value =>
                                        <Chip key={value} label={value} className={classes.chip}
                                              variant="outlined"/>
                                    ))
                                }
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Price",
                field: "price",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const nf = new Intl.NumberFormat('de-DE', {
                            style: 'currency',
                            currency: value.currency,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        });

                        return nf.format(value.amount);

                    },
                }
            },
            {

                name: "Start",
                field: "start",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                <div>{moment(value).format("HH:mm")}</div>
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "End",
                field: "end",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                <div>{moment(value).format("HH:mm")}</div>
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Seats",
                field: "seats",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return `${value.min} - ${value.max}`;

                    },
                }
            },
            {

                name: "Current Seats",
                field: "currentSeats",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {

                name: "Menu",
                field: "menu",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Ingredients",
                field: "ingredients",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Address",
                field: "address",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const postalCode = !isNullOrUndefined(value.postalCode) ? value.postalCode : "-";
                        const street = !isNullOrUndefined(value.street) ? value.street : "-";
                        const city = !isNullOrUndefined(value.city) && !isNullOrUndefined(value.city[value.language]) ? value.city[value.language] : "-";
                        const country = !isNullOrUndefined(value.country) ? value.country : "-";

                        return (
                            <div>
                                {street}<br/>
                                {`${postalCode} ${city}`}<br/>
                                {country}<br/>
                            </div>
                        )

                    },
                }
            },
            {

                name: "Special Event",
                field: "tag",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Country",
                field: "country",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Address Hints",
                field: "addressSpecials",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Cuisines",
                field: "cuisines",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (values, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>

                                {values.length === 0 ? (
                                    <div>-</div>
                                ) : (
                                    values.map(value =>
                                        <Chip key={value} label={value} className={classes.chip}
                                              variant="outlined"/>
                                    ))
                                }
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Specials",
                field: "specials",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (values, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>

                                {values.length === 0 ? (
                                    <div>-</div>
                                ) : (
                                    values.map(value =>
                                        <Chip key={value} label={value} className={classes.chip}
                                              variant="outlined"/>
                                    ))
                                }
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Allergens",
                field: "allergens",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (values, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>

                                {values.length === 0 ? (
                                    <div>-</div>
                                ) : (
                                    values.map(value =>
                                        <Chip key={value} label={value} className={classes.chip}
                                              variant="outlined"/>
                                    ))
                                }
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Courtesies",
                field: "courtesies",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (values, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>

                                {values.length === 0 ? (
                                    <div>-</div>
                                ) : (
                                    values.map(value =>
                                        <Chip key={value} label={value} className={classes.chip}
                                              variant="outlined"/>
                                    ))
                                }
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Languages",
                field: "languages",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (values, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>

                                {values.length === 0 ? (
                                    <div>-</div>
                                ) : (
                                    values.map(value =>
                                        <Chip key={value} label={value} className={classes.chip}
                                              variant="outlined"/>
                                    ))
                                }
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Policy",
                field: "policy",
                options: {
                    filter: true,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Time",
                field: "time",
                options: {
                    filter: true,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Images",
                field: "images",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (values, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>

                                {values.length === 0 ? (
                                    <div>-</div>
                                ) : (
                                    values.map((value, index) =>
                                        <StyledImagePopover key={index} src={value}/>
                                    )
                                )
                                }
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Reference",
                field: "reference",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Id",
                field: "id",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
        ];


        const options = {
            filter: true,
            filterType: 'dropdown',
            selectableRows: 'multiple',
            responsive: 'scrollFullHeight',
            customToolbar: () =>
                <Tooltip title={"Reload Data"}>
                    <IconButton className={classes.iconButton}
                                onClick={this.reload}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>,
            customToolbarSelect: (selectedRows) =>
                <StyledCustomToolbarSelect
                    selectedRows={selectedRows}
                    onStar={this.handleStar}
                    onUnstar={this.handleUnstar}
                    onCancel={this.handleCancel}/>

        };

        const data = this.state.elements.map(element => {

            const line = [];

            for (const column of columns) {

                let field = element[column.field];

                if (column.field === "image") {
                    field = {src: element.host.image, alt: `${element.host.firstname} ${element.host.lastname}`}
                }
                if (column.field === "flagged") {
                    field = element.host.score < 500;
                }
                if (column.field === "title") {
                    field = !isNullOrUndefined(element.title) && !isNullOrUndefined(element.title[element.language]) ?
                        element.title[element.language] : "-";
                }
                if (column.field === "description") {
                    field = !isNullOrUndefined(element.description) && !isNullOrUndefined(element.description[element.language]) ?
                        element.description[element.language] : "-";
                }
                if (column.field === "menu") {
                    field = !isNullOrUndefined(element.menu) && !isNullOrUndefined(element.menu[element.language]) ?
                        element.menu[element.language] : "-";
                }
                if (column.field === "ingredients") {
                    field = !isNullOrUndefined(element.ingredients) && !isNullOrUndefined(element.ingredients[element.language]) ?
                        element.ingredients[element.language] : "-";
                }
                if (column.field === "address") {
                    field = !isNullOrUndefined(element.address) ?
                        {...element.address, language: element.language} : {language: element.language};
                }
                if (column.field === "tag") {
                    field = !isNullOrUndefined(element.tag) ?
                        element.tag.label : "-";
                }
                if (column.field === "city") {
                    field = !isNullOrUndefined(element.address) ?
                        element.address.city[element.language] : "-";
                }
                if (column.field === "country") {
                    field = !isNullOrUndefined(element.address) ?
                        element.address.country : "-";
                }
                if (column.field === "score") {
                    field = !isNullOrUndefined(element.host) ?
                        element.host.score : "-";
                }
                if (column.field === "addressSpecials") {
                    field = !isNullOrUndefined(element.addressSpecials) && !isNullOrUndefined(element.addressSpecials[element.language]) ?
                        element.addressSpecials[element.language] : "-";
                }
                if (column.field === "about") {
                    field = !isNullOrUndefined(element.about) && !isNullOrUndefined(element.about[element.language]) ?
                        element.about[element.language] : "-";
                }

                if (column.field === "fee") {
                    field = !isNullOrUndefined(element.fee) ?
                        element.fee.label : "-";
                }

                if (column.field === "name") {
                    field = `${element.firstname} ${element.lastname}`;
                }

                if (column.field === "link") {
                    field = `/event/${element.id}`;
                }

                if (isNullOrUndefined(field)) {
                    field = "-";
                }

                line.push(field);
            }

            return line;
        });

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page}) => (
                        <React.Fragment>

                            {this.state.loading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress className={classes.progress} size={50}/>
                                    </Grid>
                                ) :
                                (
                                    <MUIDataTable
                                        columns={columns}
                                        data={data}
                                        title={page.title}
                                        options={options}
                                    />
                                )}

                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

Events.propTypes = {
    classes: PropTypes.object.isRequired,
};

const defaultToolbarSelectStyles = {
    iconButton: {
        marginRight: "24px",
        top: "50%",
        display: "inline-block",
        position: "relative",
        transform: "translateY(-50%)",
    },
};

class CustomToolbarSelect extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <div className={"custom-toolbar-select"}>

                <Tooltip title={"Star Event"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onStar(this.props.selectedRows)}>
                        <StarIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Unstar Event"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onUnstar(this.props.selectedRows)}>
                        <EmptyStarIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Cancel Event"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onCancel(this.props.selectedRows)}>
                        <BlockIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

}

const StyledCustomToolbarSelect = withStyles(defaultToolbarSelectStyles, {name: "CustomToolbarSelect"})(CustomToolbarSelect);

class ImagePopover extends React.Component {
    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    render() {
        const {classes} = this.props;
        const {anchorEl} = this.state;
        const open = Boolean(anchorEl);

        return (
            <React.Fragment>
                <IconButton
                    aria-owns={open ? 'simple-popper' : undefined}
                    aria-haspopup="true"
                    variant="contained"
                    onClick={this.handleClick}
                >
                    <ImageIcon/>
                </IconButton>
                <Popover
                    id="simple-popper"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <img src={this.props.src} className={classes.popOverImage}/>
                </Popover>
            </React.Fragment>
        );
    }
}

ImagePopover.propTypes = {
    classes: PropTypes.object.isRequired,
};

const StyledImagePopover = withStyles(styles)(ImagePopover);


export default withStyles(styles, {withTheme: true})(Events)