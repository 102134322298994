import React, { Component } from 'react';
import styled from 'styled-components';

import StyledButton from "../components/StyledButton"


import asset_people from '../assets/largePeople.svg';
import asset_place from '../assets/largePlace.svg';
import asset_clock from '../assets/largeClock.svg';
import asset_canceled from '../assets/largeRedShield.svg';
import asset_running from '../assets/largeOutlinedShield.svg';
import asset_passed from '../assets/largeShield.svg';


import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Moment from 'react-moment';
import 'moment/locale/de';
import moment from 'moment';


import { i18nText, i18n, i18nLocale, i18nConfiguration } from "../common/I18n";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import WarningIcon from '@material-ui/icons/Warning';

import StyledInvertedWhiteButton from "../components/StyledGreyButton";

const WrapperCSS = styled.div`
    margin-left: 69%;
    height: 120%;
    width:25%;
    margin-top: ${(props) => props.marginTop ? props.marginTop : "420px"};
    position: absolute;
    top:0;
@media screen and (max-width: 1100px) {
    display:block;
    position:relative;
    margin-top: 0px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    width:100%;
}
`;
const StickyBoxCSS = styled.div`
position: sticky;
display: block;
top:calc(112px/2 + 24px);
z-index: 99;
@media screen and (max-width: 1100px) {
    position:relative;
    margin-top: 100px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    top:0px;
}
`;

const BoxCSS = styled.div`
background: #FFFFFF;
box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
border-radius: 8px;
position: relative;
@media screen and (max-width: 1100px) {
width:100%;
    height: 100%;
box-shadow: none;
border-radius: 0px;
}
width:90%;
display: block;
margin-top: calc(112px / 2);
margin-left: auto;
margin-right: auto;
`;

const BigProfile = styled.img`
object-fit: cover;
width:112px;
height:112px;
border-radius: 100%;
left: calc(50% - (112px / 2));
top: calc(-112px / 2);
@media screen and (max-width: 1100px) {
    width:80px;
    height:80px;
    left: calc(50% - (80px / 2));
top: calc(-80px / 2);
    }
position: absolute;
border-color: white;
border-width: 4px;
border-style: solid;
z-index: 99;
`;


const BoxEntriesGrid = styled.div`
margin-top: 42px;
width: 100%;
display: grid;
grid-template-columns: 40% 60%;

`;

const BoxEntriesWrapper = styled.div`
width: 100%;
padding-bottom: 24px;
padding-top: calc(112px / 2 + 8px);
`;

const BoxEntryIcon = styled.img`
align-self: start;
  justify-self: start;
  height: 64px;
  margin-left: ${
    props => {
        if (props.extraPadding) {
            return (24 + props.extraPadding) + "px";
        } else {
            return "24px";
        }
    }};
`;

const BoxEntryText = styled.div`
padding-top: 4px;
font-family: TruenoLight;
font-size: 12px;
color: #818181;
letter-spacing: 0;
text-transform: uppercase;
@media screen and (max-width: 1100px) {
font-size: 16px;
}
`;

const BoxGuestEntry = styled.div`
padding-top: 4px;
display: flex;
justify-content: flex-start;
`;

const GuestImageCSS = styled.img`
object-fit: cover;
border-radius: 100%;
width:56px;
height:56px;
`;

const ExtraGuestCSS = styled.div`
border-radius: 8px;
display: flex;
align-items:center;
justify-content:center;
width:56px;
height:56px;
margin-left: 16px;
font-family: TruenoRegular;
font-size: 24px;
color: #FAFAFA;
letter-spacing: 1.5px;
background: #04ADA9;
`;

const BoxEntryTextLine = styled.p`
    -webkit-margin-after: 0.5em;
`;

const BoxEntryGreenTextLine = styled.p`
    -webkit-margin-after: 0.5em;
    color: #04ADA9;
`;

const Button1CSS = styled.div`
margin-top: 32px;
width:50%;
display: block;
margin-left: auto;
margin-right: auto;
`;

const Button2CSS = styled.div`
margin-top: 32px;
width:50%;
display: block;
margin-left: auto;
margin-right: auto;
padding-bottom: 32px;
`;

const MenuIcon = styled.img`

`;


const theme = createMuiTheme({

    typography: {
        useNextVariants: true,
    },

    overrides: {

        MuiIconButton: {
            root: {
                marginLeft: 8

            }
        },

        MuiMenuItem: {
            root: {
                marginTop: 16,
                marginBottom: 16,
                fontFamily: "TruenoLight",
                fontSize: 14,
                letterSpacing: 1.5,
                color: '#FF6344',
                textTransform: 'uppercase'
            }
        },

    }
});


class BookedOverview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorMoreEl: null,
            test: true
        }

    }

    handleMore = (name, event) => {
        this.setState({ anchorMoreEl: event.currentTarget });
    };

    handleClose = event => {

        this.setState({ anchorMoreEl: null });
    };

    handleCancel = event => {

        this.setState({ anchorMoreEl: null }, () => {
            this.props.onCancel();
        });
    };

    handleReport = event => {

        this.setState({ anchorMoreEl: null }, () => {
            this.props.onReport();
        });
    };

    render() {

        let statusIcon = asset_running;
        let statusText = i18n("bookedOverview/running");
        let running = true;

        if (moment(this.props.event.end.toDate()).isBefore((moment()))) {
            statusIcon = asset_passed;
            statusText = i18n("bookedOverview/passed");
            running = false;
        }

        if (this.props.booking.status === "canceled") {
            statusIcon = asset_canceled;
            statusText = i18n("bookedOverview/canceled");
            running = false;
        }

        if (this.props.booking.status === "canceledByHost") {
            statusIcon = asset_canceled;
            statusText = i18n("bookedOverview/canceledByHost");
            running = false;
        }

        if (this.props.booking.status === "canceledBySystem") {
            statusIcon = asset_canceled;
            statusText = i18n("bookedOverview/canceledBySystem");
            running = false;
        }

        return (

            <WrapperCSS>

                <StickyBoxCSS>

                    <BigProfile src={this.props.event.host.image} />


                    <BoxCSS>

                        <BoxEntriesWrapper>

                            <BoxEntriesGrid>

                                <BoxEntryIcon src={statusIcon} extraPadding={4} />

                                <BoxEntryText>
                                    <BoxEntryTextLine>
                                        {statusText}
                                    </BoxEntryTextLine>
                                </BoxEntryText>

                            </BoxEntriesGrid>

                            <BoxEntriesGrid>

                                <BoxEntryIcon src={asset_clock} />

                                <BoxEntryText>
                                    <BoxEntryTextLine>
                                        <Moment
                                            format="DD. MMM YYYY">{this.props.event.date.toDate()}</Moment>
                                    </BoxEntryTextLine>
                                    <BoxEntryTextLine> {i18n("bookedOverview/time", {
                                        start: moment(this.props.event.start.toDate()).format("HH:mm"),
                                        end: moment(this.props.event.end.toDate()).format("HH:mm"),
                                    })}</BoxEntryTextLine>
                                </BoxEntryText>

                            </BoxEntriesGrid>

                            <BoxEntriesGrid>

                                <BoxEntryIcon src={asset_place} extraPadding={8} />

                                <BoxEntryText>
                                    <BoxEntryTextLine>{this.props.event.host.firstname} {this.props.event.host.lastname}</BoxEntryTextLine>
                                    <BoxEntryTextLine>{this.props.event.address.street}</BoxEntryTextLine>
                                    <BoxEntryTextLine>{this.props.event.address.postalCode} {i18nText(this.props.event.address.city)}</BoxEntryTextLine>
                                    {i18nText(this.props.event.addressSpecials) &&
                                        <BoxEntryGreenTextLine>{i18nText(this.props.event.addressSpecials)}</BoxEntryGreenTextLine>
                                    }
                                </BoxEntryText>

                            </BoxEntriesGrid>

                            <BoxEntriesGrid>

                                <BoxEntryIcon src={asset_people} />

                                <BoxGuestEntry>
                                    <GuestImageCSS src={this.props.user.image} />

                                    {(this.props.booking.seats > 0) &&
                                        <ExtraGuestCSS> +{this.props.booking.seats}</ExtraGuestCSS>
                                    }

                                </BoxGuestEntry>

                            </BoxEntriesGrid>
                        </BoxEntriesWrapper>

                        <Button1CSS>
                            <StyledInvertedWhiteButton label={i18n("bookedOverview/back")}
                                onClick={this.props.onChat}
                                onClick={(e) => { window.location = "/my" }}
                                name="back"
                                minWidth={"0"} />
                        </Button1CSS>

                        <Button1CSS>
                            <StyledButton label={i18n("bookedOverview/chat")}
                                onClick={this.props.onChat}
                                name="chat"
                                minWidth={"0"} />
                        </Button1CSS>

                        <MuiThemeProvider theme={theme}>


                            <Button2CSS>
                                <StyledInvertedWhiteButton label={i18n("bookedOverview/more")}
                                    name="more"
                                    onClick={this.handleMore}

                                />

                                <Menu
                                    id="more-menu"
                                    anchorEl={this.state.anchorMoreEl}
                                    open={Boolean(this.state.anchorMoreEl)}
                                    onClose={this.handleClose}
                                >


                                    <MenuItem onClick={this.handleReport.bind(this)}>
                                        <ListItemIcon>
                                            <WarningIcon />
                                        </ListItemIcon>
                                        <ListItemText inset primary={i18n('bookedOverview/report')}
                                            secondary={i18n('bookedOverview/report/description')} />
                                    </MenuItem>

                                    {this.props.cancelable &&

                                        <MenuItem onClick={this.handleCancel.bind(this)}>
                                            <ListItemIcon>
                                                <SettingsBackupRestoreIcon />
                                            </ListItemIcon>
                                            <ListItemText inset primary={i18n('bookedOverview/cancel')}
                                                secondary={i18n('bookedOverview/cancel/description')} />
                                        </MenuItem>
                                    }

                                </Menu>

                            </Button2CSS>
                        </MuiThemeProvider>

                    </BoxCSS>
                </StickyBoxCSS>


            </WrapperCSS>

        );
    }
}


export default BookedOverview;
