import React, {Component} from 'react';


import styled from 'styled-components';
import asset_cook from '../assets/cook.png';


class Cook extends Component {

    constructor(props) {
        super(props);
    }


    render() {

        return (

            <img src={asset_cook} style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                height:"200px",
            }}/>


        )

    }
}

export default Cook;
