import React, { Component } from 'react';
import Header from "../components/Header";
import Address from "../components/Address";
import Cover from "../components/Cover";
import Details from "../components/Details";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";
import Overview from "../components/Overview";
import BookedOverview from "../components/BookedOverview";
import Testimonial from "../components/Testimonial";
import Directions from "../components/Directions";
import { initialize } from "../common/configuration";
import qs from 'qs';
import SearchInput from "../components/SearchInput";
import firebaseApp, { trackUserPresence } from "../common/firebase";
import { i18n, i18nChange, i18nConfiguration, i18nText } from "../common/I18n";
import Timer from "../components/Timer";
import { Redirect } from "react-router";
import { isNullOrUndefined } from "util";
import { List } from "react-content-loader";
import StyledDialog from "../components/StyledDialog";
import ChatWindow from "../components/ChatWindow";
import BookDialog from "../components/BookDialog";
import { calcFee, populateTags, calcStripeFee } from "../common/utils";
import ReportDialog from "../components/ReportDialog";
import moment from "moment";
import Card from "../components/Card";
import withDebug from "../common/withDebug";

const firestore = firebaseApp.firestore();


class MyEvent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.match.params.id,
            user: null,
            event: null,
            favorite: null,
            events: [],
            bookings: [],
            roles: null,
            selected: -1,
            error: null,
            loading: false,
            systemError: null,
            openCancelDialog: false,
            openReportDialog: false,
            openBookDialog: false,
            showChat: false,
            chatWithName: null,
            reference: null,
            voucherType: null,
            voucherAmount: null,
            people: 0,
            displayPromoCode: false,
            hidePromoCode: false,
            messages: [],
            stripeCustomers: [],
            testimonials: [],
            initialized: false,

        };
    }

    UNSAFE_componentWillMount() {

        initialize().then(() => this.setState({ initialized: true }));

        firebaseApp.auth().onAuthStateChanged(async (user) => {
            if (user) {
                try {

                    const profile = await firestore.collection('profiles').doc(user.uid).get();
                    const roles = await firestore.collection('roles').doc(user.uid).get();

                    if (!profile.exists || !roles.exists) {
                        this.setState({ systemError: "noProfile" });
                    } else {
                        this.setState({ user: profile.data(), roles: roles.data(), hidePromoCode: roles.data().vip }, () => {
                            i18nChange(this.state.user.language);
                            this.load(true);
                            trackUserPresence();

                        })
                    }
                } catch (error) {
                    this.setState({ systemError: error.code });
                }
            }
        });

    }

    listen = (id) => {
        firestore.collection('events').doc(id)
            .onSnapshot(
                snapshot => {
                    let event = { ...snapshot.data() };
                    event.id = snapshot.id;

                    this.setState({ event: event });
                }
                , snapShotError => {
                    this.setState({ systemError: snapShotError.code });
                });

        firestore.collection('bookings').where("uid", "==", this.state.user.uid)
            .where("eventId", "==", this.state.id)
            .onSnapshot(
                fsBookings => {
                    let bookings = [];

                    for (const fsBooking of fsBookings.docs) {
                        let booking = { ...fsBooking.data() };
                        booking.id = fsBooking.id;
                        bookings.push(booking);
                    }

                    this.setState({ bookings: bookings });
                }
                , snapShotError => {
                    this.setState({ systemError: snapShotError.code });
                });

        firestore.collection('stripeCustomers').where("uid", "==", this.state.user.uid)
            .onSnapshot(
                fsStripeCustomers => {

                    let stripeCustomers = [];

                    this.setState({ stripeCustomers: [] }, () => {

                        for (const fsStripeCustomer of fsStripeCustomers.docs) {
                            stripeCustomers.push({ ...fsStripeCustomer.data() });
                        }

                        stripeCustomers = stripeCustomers.filter(stripeCustomer => {
                            return new moment(`${stripeCustomer.data.sources.data[0].exp_year}-${stripeCustomer.data.sources.data[0].exp_month.toString().padStart(2, '0')}-01`).isAfter(new moment().add(1, "months"))
                        });


                        this.setState({ stripeCustomers: stripeCustomers });
                    });
                }
                , snapShotError => {
                    this.setState({ systemError: snapShotError.code });
                });
    };

    load(loading) {

        this.setState({
            loading: loading,
            events: [],
            bookings: [],
            testimonials: [],
            event: null,
            favorite: null
        }, async () => {

            try {


                const fsEvent = await firestore.collection("events").doc(this.state.id).get();
                if (fsEvent.exists) {

                    let event = { ...fsEvent.data() };
                    event.id = fsEvent.id;


                    const fsRelatedEvents = await firestore.collection("events").where("reference", "==", fsEvent.data().reference).get();

                    let events = [];
                    for (let fsRelatedEvent of fsRelatedEvents.docs) {
                        let relatedEvent = { ...fsRelatedEvent.data() };
                        relatedEvent.id = fsRelatedEvent.id;

                        events.push(relatedEvent);
                    }


                    const fsBookings = await firestore.collection("bookings")
                        .where("uid", "==", this.state.user.uid)
                        .where("eventId", "==", this.state.id)
                        .get();

                    let bookings = [];

                    for (const fsBooking of fsBookings.docs) {
                        let booking = { ...fsBooking.data() };
                        booking.id = fsBooking.id;
                        bookings.push(booking);
                    }

                    events = events.filter((event) => {
                        return bookings.filter(booking => booking.eventId === event.id).length === 0
                    });

                    let testimonials = [];

                    const fsTestimonials = await firestore.collection("testimonials").where("uid", "==", fsEvent.data().uid).get();

                    for (let fsTestimonial of fsTestimonials.docs) {
                        let testimonial = { ...fsTestimonial.data() };
                        testimonials.push(testimonial);
                    }
                    ;


                    this.setState({
                        loading: false,
                        event: event,
                        bookings: bookings,
                        events: events,
                        testimonials: testimonials
                    }, () => {
                        this.loadFavorites();
                        if (loading) {
                            this.listen(this.state.id);
                        }
                    });


                } else {
                    window.location = "notFound";

                }
            } catch (error) {
                this.setState({ loading: false, systemError: error });
            }

        });

    }

    loadFavorites = () => {

        this.setState({ favorite: null }, async () => {

            try {

                const fsFavorites = await firestore.collection("favorites")
                    .where("uid", "==", this.state.user.uid)
                    .where("eventId", "==", this.state.id)
                    .get();

                let favorite = null;

                for (const fsFavorite of fsFavorites.docs) {
                    favorite = { ...fsFavorite.data() };
                    favorite.id = fsFavorite.id;
                }

                this.setState({ favorite: favorite });

            } catch (error) {
                this.setState({ systemError: error });
            }
        });
    }

    handleChat = () => {

        this.setState({
            showChat: true,
            chatWithName: this.state.event.host.firstname,
            reference: this.state.event,
        }, () => {
            firestore.collection('messages').where("eventId", "==", this.state.event.id).where("hostUid", "==", this.state.event.uid).where("guestUid", "==", this.state.user.uid)
                .onSnapshot(
                    fsMessages => {

                        const messages = [];
                        for (const fsMessage of fsMessages.docs) {

                            const message = {
                                text: fsMessage.data().text,
                                name: fsMessage.data().isGuest ? fsMessage.data().guest.name : fsMessage.data().host.name,
                                image: fsMessage.data().isGuest ? fsMessage.data().guest.image : fsMessage.data().host.image,
                                other: !fsMessage.data().isGuest,
                                readByGuest: fsMessage.data().readByGuest,
                                readByHost: fsMessage.data().readByHost,
                                timestamp: fsMessage.data().created.toDate(),
                                ref: fsMessage.ref,
                            };

                            messages.push(message);
                        }

                        messages.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());

                        this.setState({ messages: messages });

                    }
                    , snapShotError => {
                        this.setState({ systemError: snapShotError.code });
                    });
        });
    };

    handleCloseChat = (event) => {
        this.setState({ showChat: false, chatWithName: null, messages: [], reference: null, message: "" }, () => {
            firestore.collection('messages').where("eventId", "==", event.id).where("hostUid", "==", event.uid).where("guestUid", "==", this.state.user.uid)
                .onSnapshot(() => {
                })
        });
    };

    handleCloseBookDialog = (event) => {

        this.setState({ openBookDialog: false });
    };

    handleOpenBookDialog = async (people) => {

        const selected = (this.state.selected === -1) ? this.state.events.map(event => {
            return event.id
        }).indexOf(this.state.id) : this.state.selected;

        const fsVoucher = await firestore.collection("vouchers").doc("VIP").get();

        if (fsVoucher.exists) {
            const eligable =
                (isNullOrUndefined(fsVoucher.data().minAmount) ||
                    (fsVoucher.data().minAmount <= (this.state.events[selected].price.amount * (this.state.people + 1) + calcFee(this.state.events[selected].price.amount * (this.state.people + 1), this.state.events[selected].fee)))
                )

            const validFrom = moment(fsVoucher.data().validFrom.toDate());
            const validTo = moment(fsVoucher.data().validTo.toDate());
            const now = moment();

            const active = now.isAfter(validFrom) && now.isBefore(validTo);

            if (eligable && active) {
                this.setState({ voucherType: fsVoucher.data().type, voucherAmount: fsVoucher.data().amount, openBookDialog: true });
            } else {
                this.setState({ voucherType: null, voucherAmount: null, openBookDialog: true });
            }
        } else {
            this.setState({ voucherType: null, voucherAmount: null, openBookDialog: true });
        }

    };

    handleOpenReportDialog = () => {



        this.setState({ openReportDialog: true });


    };

    handleCloseReportDialog = (event) => {
        this.setState({ openReportDialog: false });
    };

    handleSend = async (event, text) => {
        await firestore.collection("messages").add(
            {
                guest: {
                    name: this.state.user.firstname,
                    image: this.state.user.image,
                },
                guestUid: this.state.user.uid,
                host: {
                    name: event.host.firstname,
                    image: event.host.image,
                },
                hostUid: event.uid,
                readByHost: false,
                readByGuest: true,
                isGuest: true,
                text: text,
                eventId: event.id,
                created: new Date()
            });
    };

    handleRead = async (messages) => {
        for (const message of messages) {
            if (!message.readByGuest) {
                await message.ref.set(
                    {
                        readByGuest: true,
                    },
                    { merge: true }
                )
            }
        }
    };

    handleCancel = () => {
        this.setState({ openCancelDialog: true });
    };

    handleDisplayPromoCode = (name) => {
        this.setState({ displayPromoCode: true });
    }

    handleCancelPromoCode = (name) => {
        this.setState({ displayPromoCode: false, promoCode: null, promoError: null });
    }

    handleRedeem = (promoCode) => {
        this.setState({ working: true }, async () => {

            const selected = (this.state.selected === -1) ? this.state.events.map(event => {
                return event.id
            }).indexOf(this.state.id) : this.state.selected;

            if (!isNullOrUndefined(promoCode)) {

                const fsVoucher = await firestore.collection("vouchers").doc(promoCode).get();

                if (!fsVoucher.exists) {
                    this.setState({ working: false, promoError: i18n("promoCode/unkown") });
                    return;
                } else {

                    const fsRedeemed = await firestore.collection("vouchers").doc(promoCode).collection('redeem').doc(this.state.user.uid).get();

                    if (fsRedeemed.exists) {
                        this.setState({ working: false, promoError: i18n("promoCode/alreadyRedeemed") });
                        return;
                    } else {

                        const eligable =
                            (isNullOrUndefined(fsVoucher.data().minAmount) ||
                                (fsVoucher.data().minAmount <= (this.state.events[selected].price.amount * (this.state.people + 1) + calcFee(this.state.events[selected].price.amount * (this.state.people + 1), this.state.events[selected].fee)))
                            ) &&
                            (fsVoucher.data().constraint === null || fsVoucher.data().constraint === this.state.events[selected].type)
                            && promoCode !== "VIP"

                        if (!eligable) {
                            this.setState({ working: false, promoError: i18n("promoCode/notEligable") });
                            return;
                        }

                        const validFrom = moment(fsVoucher.data().validFrom.toDate());
                        const validTo = moment(fsVoucher.data().validTo.toDate());
                        const now = moment();
                        let currentRedeems = 0;
                        const maxRedeems = fsVoucher.data().maxRedeems;

                        const fsRedeemed = await firestore.collection("vouchers").doc(promoCode).collection('redeem').get();

                        for (const fsRedeem of fsRedeemed.docs) {
                            currentRedeems++;
                        }



                        const active = now.isAfter(validFrom) && now.isBefore(validTo) && (currentRedeems < maxRedeems);


                        if (!active) {
                            this.setState({ working: false, promoError: i18n("promoCode/expired") });
                            return;
                        }
                    }

                }

                this.setState({ voucherType: fsVoucher.data().type, voucherAmount: fsVoucher.data().amount, displayPromoCode: false, working: false });
            }
        })
    }

    handleBook = (promoCode, stripeCustomer, takeAway) => {

        this.setState({ working: true }, async () => {

            try {

                const selected = (this.state.selected === -1) ? this.state.events.map(event => {
                    return event.id
                }).indexOf(this.state.id) : this.state.selected;


                await firestore.collection('bookingRequests').add(
                    {
                        type: "book",
                        uid: firebaseApp.auth().currentUser.uid,
                        event: this.state.events[selected],
                        seats: this.state.people,
                        wantsTakeAway: takeAway,
                        promoCode: promoCode,
                        cc: stripeCustomer,
                        status: "new",
                        error: null,
                        reason: null,
                        created: new Date(),
                        updated: null
                    });


                const tags = populateTags(this.state.user, this.state.events[selected]);

                await firestore.collection('profiles').doc(firebaseApp.auth().currentUser.uid).set(
                    {
                        updated: new Date(),
                        tags: tags,
                        change: "ignore",

                    }
                    , { merge: true });


                this.setState({ working: false, openBookDialog: false });

            } catch (error) {
                console.log(error);
                this.setState({ working: false, systemError: error });
            }

        })
    };

    handleSelectionChange = (index) => {
        this.setState({ selected: index });
    };

    handlePeopleChange = (name, index) => {
        this.setState({ [name]: index });
    };

    handleFavorite = async (id) => {


        try {

            await firestore.collection("favorites").add(
                { uid: this.state.user.uid, eventId: id, created: new Date() }
            );

            this.loadFavorites();

        } catch (error) {
            this.setState({ loading: false, systemError: error.code })
        }


    };

    handleUnFavorite = async (id) => {

        try {


            const fsFavorites = await firestore.collection("favorites").where("eventId", "==", id).where("uid", "==", this.state.user.uid).get();

            for (const fsFavorite of fsFavorites.docs) {
                await fsFavorite.ref.delete();
            }

            this.loadFavorites();


        } catch (error) {
            this.setState({ loading: false, systemError: error.code })
        }

    };

    handleCloseCancelDialog = (name) => {

        this.setState({ openCancelDialog: false });

    };

    handleConfirmedCancelDialog = (name) => {

        this.setState({ openCancelDialog: false, loading: true }, async () => {

            try {

                await firestore.collection('bookingRequests').add(
                    {
                        type: "cancel",
                        uid: firebaseApp.auth().currentUser.uid,
                        event: this.state.event,
                        booking: this.state.bookings[0],
                        status: "new",
                        error: null,
                        reason: null,
                        created: new Date(),
                        updated: null
                    }
                );

                this.setState({ loading: false })


            } catch (error) {
                this.setState({ loading: false, systemError: error.code })
            }

        });

    };


    render() {

        const nf = new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        if (!this.state.initialized) {
            return (<Timer />);
        }

        const selected = (this.state.selected === -1) ? this.state.events.map(event => {
            return event.id
        }).indexOf(this.state.id) : this.state.selected;

        const isAuth = this.state.user !== null;
        const isHost = !isNullOrUndefined(this.state.roles) && this.state.roles.host === true;
        const isGuest = !isNullOrUndefined(this.state.roles) && this.state.roles.guest === true;

        const cancelable = !isNullOrUndefined(this.state.event) && moment(this.state.event.cancelUntil.toDate()).isAfter(moment());

        const baseAmount = !isNullOrUndefined(this.state.event) ? this.state.event.price.amount * (1 + this.state.people) : 0;
        const serviceAmount = !isNullOrUndefined(this.state.event) ? calcFee(baseAmount, this.state.event.fee) : 0;
        const sumAmount = baseAmount + serviceAmount;
        const stripeAmount = !isNullOrUndefined(this.state.event) ? calcStripeFee(sumAmount) : 0;


        let voucherAmount = 0;

        if (this.state.voucherType !== null && this.state.voucherAmount !== null) {

            if (this.state.voucherType === "percentage") {
                voucherAmount = sumAmount * this.state.voucherAmount;
            } else {
                voucherAmount = this.state.voucherAmount;
            }

            if (voucherAmount > serviceAmount - stripeAmount) {
                voucherAmount = serviceAmount - stripeAmount;
            }

        }

        const totalAmount = sumAmount - voucherAmount;

        return (


            <div>
                {!isAuth &&
                    <Timer time={2}>
                        <Redirect push to={`/event/${this.state.id}`} />
                    </Timer>
                }

                {(isAuth && this.state.loading) &&
                    <List width={"400px"} style={{
                        display: 'block',
                        marginTop: "64px",
                        marginBottom: "64px",
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }} />
                }

                {(isAuth && isHost && !isGuest && this.state.event !== null && (this.state.event.uid !== this.state.user.uid)) &&
                    <Redirect push to={`/my`} />
                }

                {(isAuth && isHost && this.state.event !== null && (this.state.event.uid === this.state.user.uid)) &&
                    <Redirect push to={`/host/my/event/${this.state.id}`} />
                }


                {(isAuth && isGuest && this.state.event !== null) &&
                    <div>
                        <Header user={this.state.user} roles={this.state.roles}>
                        </Header>

                        <Cover images={this.state.event.images}
                            highlight={this.state.event.mainImageIndex} />

                        {this.state.bookings.length > 0 &&
                            <BookedOverview booking={this.state.bookings[0]}
                                event={this.state.event}
                                user={this.state.user}
                                cancelable={cancelable}
                                onCancel={this.handleCancel.bind(this)}
                                onChat={this.handleChat.bind(this)}
                                onReport={this.handleOpenReportDialog.bind(this)} />
                        }

                        {this.state.bookings.length === 0 &&
                            <Overview events={this.state.events}
                                selected={selected}
                                working={this.state.working}
                                event={this.state.events[selected]}
                                isAuth={isAuth}
                                isGuest={isGuest}
                                user={this.state.user}
                                people={this.state.people}
                                onPeopleChange={this.handlePeopleChange.bind(this)}
                                onFavorite={this.handleFavorite}
                                favorite={this.state.favorite}
                                onUnFavorite={this.handleUnFavorite}
                                onSelectionChange={this.handleSelectionChange.bind(this)}
                                onChat={this.handleChat.bind(this)}
                                onBook={this.handleOpenBookDialog.bind(this)}
                                onReport={this.handleOpenReportDialog.bind(this)} />
                        }

                        <Details event={this.state.event}
                            onFavorite={this.handleFavorite}
                            onUnFavorite={this.handleUnFavorite}
                            favorite={this.state.favorite}
                            isAuth={isAuth} />

                        {(this.state.booking) &&
                            <Directions location={this.state.event.geo.geopoint} />
                        }

                        <Gallery images={this.state.event.images} />

                        {this.state.testimonials.length > 0 &&
                            <Testimonial testimonials={this.state.testimonials} />
                        }

                        {(!this.state.booking) &&
                            <Address location={this.state.event.geo.geopoint} />
                        }

                        <ReportDialog
                            open={this.state.openReportDialog}
                            onClose={this.handleCloseReportDialog.bind(this)}
                            subject={this.state.event}
                            kind={"event"}
                        />

                        <StyledDialog
                            name={"cancel"}
                            open={this.state.openCancelDialog}
                            onCancel={this.handleCloseCancelDialog.bind(this)}
                            onSave={this.handleConfirmedCancelDialog.bind(this)}
                            title={i18n("cancel/dialog/title")}
                            text={i18n("cancel/dialog/cancellation/" + this.state.event.policy, { time: i18nConfiguration("time", this.state.event.time) })}
                            working={this.state.working}
                            okLabel={i18n("cancel/dialog/ok")} />

                        <ChatWindow open={this.state.showChat}
                            name={this.state.chatWithName}
                            messages={this.state.messages}
                            reference={this.state.event}
                            onRead={this.handleRead.bind(this)}
                            onSend={this.handleSend.bind(this)}
                            onClose={this.handleCloseChat.bind(this)} />

                        <BookDialog open={this.state.openBookDialog}
                            event={this.state.event}
                            user={this.state.user}
                            people={this.state.people}
                            stripeCustomers={this.state.stripeCustomers}
                            promoError={this.state.promoError}
                            working={this.state.working}
                            onBook={this.handleBook.bind(this)}
                            currency={this.state.event.price.currency}
                            hidePromoCode={this.state.hidePromoCode}
                            displayPromoCode={this.state.displayPromoCode}
                            onDisplayPromoCode={this.handleDisplayPromoCode}
                            onCancelPromoCode={this.handleCancelPromoCode}
                            baseAmount={nf.format(baseAmount)}
                            serviceAmount={nf.format(serviceAmount)}
                            sumAmount={nf.format(sumAmount)}
                            voucherAmount={nf.format(voucherAmount)}
                            onRedeem={this.handleRedeem}
                            totalAmount={nf.format(totalAmount)}
                            onClose={this.handleCloseBookDialog} />

                        <Footer />


                    </div>
                }
            </div>
        );
    }
}

export default withDebug()(MyEvent);
