import React, {Component} from 'react';
import {initialize} from "../common/configuration";
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';


import logo_large from "../assets/logo_large.svg";
import logo_white from "../assets/logo_white.svg";
import facebook from "../assets/facebook.svg";
import twitter from "../assets/twitter.svg";
import instagram from "../assets/instagram.svg";
import styled from 'styled-components';
import hero1 from "../assets/hero2.jpg";
import Timer from "../components/Timer";
import {i18n} from "../common/I18n";
import withDebug from "../common/withDebug";


const BackgroundContainer = styled.div`
    width: 100%;
    height: 50%;
    position: absolute;
   background-image: linear-gradient(0deg, #FFFFFF 4%, rgba(255,255,255,0.83) 76%, rgba(255,255,255,0.33) 99%), url(${props => props.image});
   background-size: cover;
    background-repeat: no-repeat;
   
`;


const HeaderContainer = styled.div`
    width: 100%;
    position: relative;
`;

const StyleCSS = styled.div`
h1 {
opacity: 0.8;
font-family: TruenoBold;
font-size: 44px;
@media screen and (max-width: 800px) {
font-size: 32px;
}
color: #333333;
letter-spacing: -0.54px;
text-align: center;
margin-top: 70px;
}

h2 {
margin-top:70px;
opacity: 0.8;
font-family: TruenoBold;
@media screen and (max-width: 800px) {
font-size: 20px;

}
color: #333333;
letter-spacing: -0.6px;
text-align: center;
line-height: 48px;
margin-bottom: 28px;
}

h3 {
display: block;
margin-top:20px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 16px;
color: #818181;
letter-spacing: 0;
text-align: center;
line-height: 26px;
    width:50%;
      @media screen and (max-width: 800px) {
          width:80%;

}
    margin-left: auto;
    margin-right: auto;
}

p {
display: block;
margin-top:20px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 16px;
color: #818181;
letter-spacing: 0;
text-align: left;
line-height: 26px;
    width:50%;
      @media screen and (max-width: 800px) {
          width:80%;

}
    margin-left: auto;
    margin-right: auto;
}
`;


const Logo = styled.img`
margin-top: 70px;
`;

const LargeLogo = styled.img`
padding-top: 70px;

width: 30%;
margin-left: auto;
margin-right: auto;
    display: block;

`;

const theme = createMuiTheme({

    palette: {
        primary: {
            main: "#FF6344",
            light: "#FF6344"
        },
    },
    typography: {
        useNextVariants: true,
    },


    overrides: {
        typography: {
            fontFamily:
                '"TruenoLight"'
        },

        MuiCircularProgress: {
            root: {
                display: 'block',
                marginTop: '24px',
                marginBottom: '16px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        },

        MuiButton: {
            root: {
                textTransform: 'uppercase',
                fontSize: '12px',
                color: '#818181',
                height: 20,
            }
        },
        MuiInput: {
            root: {
                textTransform: 'uppercase',
                fontSize: '14px',
                color: '#818181',
                height: 20,
                paddingTop: 10,
                paddingBottom: 10,
                letterSpacing: 0,
            },
        },
    }
});


class Privacy extends Component {


    constructor(props) {
        super(props);
        this.state = {
            initialized:false,
        }

    }

    componentDidMount() {
        initialize().then(() => this.setState({initialized: true}));
    }


    render() {



        if (!this.state.initialized) {
            return (<Timer/>);
        }

        return (
            <div>
                <MuiThemeProvider theme={theme}>

                    <BackgroundContainer image={hero1}>
                    </BackgroundContainer>


                    <HeaderContainer>
                        <a href="/"> <LargeLogo src={logo_large} alt=""/> </a>
                    </HeaderContainer>

                    <StyleCSS>
                        {i18n("privacy")}
                    </StyleCSS>


                    <center>
                        <Logo src={logo_white}/>
                    </center>
                    <center style={{marginTop: 20, marginBottom: 50}}>
                        <a href={"https://www.facebook.com/Culinarioo-182792569028065/"}><img src={facebook}/></a>
                        <a href={"https://www.twitter.com/culinarioo"}><img src={twitter}
                                                                            style={{marginLeft: 35, marginRight: 35}}/></a>
                        <a href={"https://www.instagram.com/culinarioo"}><img src={instagram}/></a>
                    </center>

                </MuiThemeProvider>

            </div>
        );
    }
}

export default withDebug()(Privacy);
