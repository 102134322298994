import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';
import MUIDataTable from "mui-datatables";
import firebaseApp from "../common/firebase";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button/Button";
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import RefreshIcon from "@material-ui/icons/Refresh";

const firestore = firebaseApp.firestore();


const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
});

class Masterdata extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            working: false,
            loading: true,
            values: [],
            showDialog: false,
            value: "",
        };
    }


    componentDidMount() {
        this.reload();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.type !== prevProps.type) {
            this.reload();
        }
    }

    reload = () => {
        this.setState({loading: true}, async () => {
            try {

                let fsElement = await firestore.collection("masterdata").doc(this.props.type).get();

                this.setState({values: [...fsElement.data().values], loading: false});
            } catch (error) {
                console.error(error);
                this.setState({loading: false});
            }

        })

    };

    handleDelete = (selectedRows) => {

        this.setState({working: true}, async () => {
            try {


                let values = [...this.state.values];

                for (const selectedRow of selectedRows.data) {
                    values = values.filter(value => value !== this.state.values[selectedRow.dataIndex]);
                }

                await firestore.collection("masterdata").doc(this.props.type).set({
                    values: values
                });

                this.reload();

            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });

    };

    handleChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    openDialog = () => {
        this.setState({showDialog: true, value: ""})
    };

    closeDialog = () => {
        this.setState({showDialog: false, value: ""})
    };

    handleSave = () => {
        this.setState({working: true}, async () => {

            let values = [...this.state.values];
            values.push(this.state.value);

            await firestore.collection("masterdata").doc(this.props.type).set({
                values: values
            });

            this.setState({working: false, showDialog: false, value: ""}, () => {
                this.reload();
            })
        })
    };


    render() {
        const {classes} = this.props;

        const columns = [
            {

                name: "Value",
                field: "value",
                options: {
                    filter: false,
                    sort: true
                }
            },
        ];


        const options = {
            filter: false,
            selectableRows: 'multiple',
            responsive: 'scrollFullHeight',
            customToolbar: () =>
                <Tooltip title={"Reload Data"}>
                    <IconButton className={classes.iconButton}
                                onClick={this.reload}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>,
            customToolbarSelect: (selectedRows) =>
                <StyledCustomToolbarSelect
                    selectedRows={selectedRows}
                    onDelete={this.handleDelete}/>

        };

        const data = this.state.values.map(value => [value]);

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page}) => (
                        <React.Fragment>

                            {this.state.loading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress className={classes.progress} size={50}/>
                                    </Grid>
                                ) :
                                (
                                    <React.Fragment>

                                        <MUIDataTable
                                            columns={columns}
                                            data={data}
                                            title={page.title}
                                            options={options}
                                        />

                                        <Button variant="fab" className={classes.fab} color={"secondary"}
                                                onClick={this.openDialog}>
                                            <AddIcon/>
                                        </Button>

                                    </React.Fragment>

                                )}


                            <Dialog
                                open={this.state.showDialog}
                                aria-labelledby="form-dialog-title"
                            >
                                <DialogTitle id="form-dialog-title">Add a new value to {page.title}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        add a new value. Be aware that you can't edit values afterwards but have to
                                        delete them
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        value={this.state.value}
                                        margin="dense"
                                        id="value"
                                        name="value"
                                        label="Value"
                                        type="text"
                                        onChange={this.handleChange}
                                        fullWidth
                                        disabled={this.state.working}
                                        error={this.state.values.includes(this.state.value)}
                                        helperText={this.state.values.includes(this.state.value) ? "Value exists already" : null}
                                    />
                                </DialogContent>

                                <DialogActions>
                                    <Button onClick={this.closeDialog} color="primary" disabled={this.state.working}>
                                        Close
                                    </Button>
                                    <Button onClick={this.handleSave} color="primary" autoFocus
                                            disabled={this.state.value.trim().length === 0 || this.state.values.includes(this.state.value || this.state.working)}>
                                        Save
                                    </Button>
                                </DialogActions>
                            </Dialog>


                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

Masterdata.propTypes = {
    classes: PropTypes.object.isRequired,
};

const defaultToolbarSelectStyles = {
    iconButton: {
        marginRight: "24px",
        top: "50%",
        display: "inline-block",
        position: "relative",
        transform: "translateY(-50%)",
    },
};

class CustomToolbarSelect extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <div className={"custom-toolbar-select"}>
                <Tooltip title={"Delete"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onDelete(this.props.selectedRows)}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

}

const StyledCustomToolbarSelect = withStyles(defaultToolbarSelectStyles, {name: "CustomToolbarSelect"})(CustomToolbarSelect);

export default withStyles(styles, {withTheme: true})(Masterdata)