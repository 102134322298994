import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import Error from '@material-ui/icons/Error';


const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
        position: 'fixed',
        bottom: theme.spacing.unit * 3,
        left: theme.spacing.unit * 2,
        zIndex: 100,
        textTransform: 'uppercase',
        fontSize: '18px',
        letterSpacing: 1.5,
        fontFamily:
            'TruenoLight',
        color:"#ffffff"


    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
});

const theme2 = createMuiTheme({

    palette: {
        primary: {
            main: "#FF6344",
            light: "#FF6344"
        },
    },
    typography: {
        useNextVariants: true,
    },
});

function StyledErrorFloatingButton(props) {
    const {classes} = props;
    return (

        <MuiThemeProvider theme={theme2}>

            <Button variant="contained" color="primary" className={classes.button} onClick={props.onClick}>
                {props.text}
                <Error className={classes.rightIcon}/>
            </Button>
        </MuiThemeProvider>

    );
}

StyledErrorFloatingButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StyledErrorFloatingButton);