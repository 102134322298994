import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';
import MUIDataTable from "mui-datatables";
import firebaseApp from "../common/firebase";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";
import {isNullOrUndefined} from "util";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button/Button";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import RefreshIcon from "@material-ui/icons/Refresh";


const firestore = firebaseApp.firestore();


const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
});

class ScoreWeights extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            working: false,
            values: [],
            showDialog: false,
            editMode: false,
            key: null,
            score: "0",
            value: null,
        };
    }


    componentDidMount() {
        this.reload();
    }

    reload = () => {
        this.setState({loading: true}, async () => {
            try {

                let fsValues = await firestore.collection("masterdata").doc("scores").get();

                let values = [];

                for (const key of Object.keys(fsValues.data())) {

                    values.push({key: key, score: fsValues.data()[key]});
                }

                this.setState({values: values, loading: false});
            } catch (error) {
                console.error(error);
                this.setState({loading: false});
            }

        })

    };

    handleChange = event => {

        let value = event.target.value;
        this.setState({[event.target.name]: value});
    };

    openDialog = () => {

        this.setState({
            showDialog: true,
            score: this.state.value.score,
            key: this.state.value.key,
        })

    };

    closeDialog = () => {
        this.setState({
            showDialog: false,
            score: "0",
            key: null,
            value: null,
        })
    };

    handleSave = () => {
        this.setState({working: true}, async () => {

            const score = parseInt(this.state.score);

            await firestore.collection("masterdata").doc("scores").set({
                    [this.state.key]: score
                },
                {merge: true});

            this.setState({
                working: false,
                showDialog: false,
                score: "0",
                key: null,
                value: null,
            }, () => {
                this.reload();
            })
        })
    };

    render() {
        const {classes} = this.props;

        const columns = [
            {

                name: "Action",
                field: "action",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Tooltip title={"Edit entry"}>
                                <IconButton onClick={(e) => this.setState({value: value}, () => this.openDialog(true))}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>

                        );
                    },
                },

            },
            {

                name: "Key",
                field: "key",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {

                name: "Score",
                field: "score",
                options: {
                    filter: true,
                    sort: true
                }
            },

        ];


        const options = {
            filter: false,
            selectableRows: 'none',
            responsive: 'scrollFullHeight',
            customToolbar: () =>
                <Tooltip title={"Reload Data"}>
                    <IconButton className={classes.iconButton}
                                onClick={this.reload}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>,
        };

        const data = this.state.values.map(value => {

            const line = [];

            for (const column of columns) {
                let field = value[column.field];

                if (column.field === "action") {
                    field = value;
                }

                if (isNullOrUndefined(field)) {
                    field = "-"
                }
                line.push(field);
            }

            return line;
        });

        const isValidScore = !isNaN(this.state.score) && parseInt(this.state.score);

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page}) => (
                        <React.Fragment>

                            {this.state.loading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress className={classes.progress} size={50}/>
                                    </Grid>
                                ) :
                                (
                                    <React.Fragment>

                                        <MUIDataTable
                                            columns={columns}
                                            data={data}
                                            title={page.title}
                                            options={options}
                                        />

                                    </React.Fragment>

                                )}


                            <Dialog
                                open={this.state.showDialog}
                                aria-labelledby="form-dialog-title"
                            >

                                <React.Fragment>
                                    <DialogTitle
                                        id="form-dialog-title">Edit {this.state.key} of {page.title}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            Edit the weight. You can't change the label
                                        </DialogContentText>

                                        <TextField
                                            value={this.state.score}
                                            margin="dense"
                                            id="score"
                                            name="score"
                                            label="Score"
                                            type="number"
                                            error={!isValidScore}
                                            helperText={!isValidScore ? "Only numbers" : ""}
                                            onChange={this.handleChange}
                                            fullWidth
                                            disabled={this.state.working}
                                        />

                                    </DialogContent>

                                    <DialogActions>
                                        <Button onClick={this.closeDialog} color="primary"
                                                disabled={this.state.working}>
                                            Close
                                        </Button>
                                        <Button onClick={this.handleSave} color="primary" autoFocus
                                                disabled={this.state.working || !isValidScore}>
                                            Save
                                        </Button>
                                    </DialogActions>
                                </React.Fragment>
                            </Dialog>


                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

ScoreWeights.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles, {withTheme: true})(ScoreWeights)