import React, { Component } from 'react';
import cook_wink from "../assets/cook_wink.svg";
import styled from 'styled-components';
import withDebug from "../common/withDebug";

const Cook = styled.img`
    padding-top: 25px;
    max-width: 50%;
    max-height: 50%;
`;


class Page404 extends Component {
    render() {
        return (
            <center>
                <Cook src={cook_wink} alt="work in progress"/>
            </center>
        );
    }
}

export default withDebug()(Page404);
