import React from 'react';
import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import {isNullOrUndefined} from "util";


import styled from 'styled-components';

const TextFieldContainer = styled.div`

box-shadow: 0 2px 6px 0 rgba(0,0,0,0.50);
border-radius: 8px;
    padding-top: 3px;
    padding-bottom: 8px;
    border: none;
    user-select: none;
                    letter-spacing: 2px;
    width: ${props => props.width ? props.width : "100%"};
`;


const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
    },

    typography: {
        useNextVariants: true,
    },


    overrides: {
        MuiTypography: {
            body1: {
                fontSize: '16px',
                height: "100%",
                color: '#E2E2E2',
                letterSpacing: 2,
                fontFamily: 'TruenoLight',
                textAlign: 'center',
            }
        },

        MuiIconButton: {
            root: {
                top:"-1px",
                marginRight: 8,

            }
        },
        MuiFormHelperText: {
            root: {
                fontFamily: 'TruenoLight',
                letterSpacing: 1.5,
                paddingLeft: 16,
            },

            error: {
                color: "#FF6344"
            }
        },


        MuiInputLabel: {
            root: {
                textTransform: 'uppercase',
                fontSize: '12px',
                height: "22px",
                paddingLeft: 16,
                color: '#E2E2E2',
                letterSpacing: 1.5,
                fontFamily: 'TruenoLight',
            }
        },
        MuiInput: {
            root: {
                textTransform: 'uppercase',
                fontSize: '16px',
                height: "100%",
                paddingTop: 8,
                paddingBottom: 8,
                fontFamily: 'TruenoRegular',
                letterSpacing: 2,

            },
        },
        MuiInputAdornment: {
            root: {
                height: null,

            },
        },
        MuiInputBase: {
            input: {
                paddingLeft: 16,
                paddingRight: 16,
                maxWidth:"100%"

            },
        },
    }
});


class StyledTextField extends React.Component {

    constructor(props) {
        super(props);
    }

    textField = null;


    render() {
        return (


            <MuiThemeProvider theme={theme}>
                <TextFieldContainer width={this.props.width} onClick={() => { if(!isNullOrUndefined(this.textField.focus)) {this.textField.focus()}}}>
                    <TextField
                        InputProps={{
                            startAdornment: this.props.startAdornment,
                            endAdornment: this.props.endAdornment,
                            disableUnderline: true,
                            inputComponent: this.props.mask
                        }}
                        InputLabelProps={this.props.mask ? { shrink:  true} : null}
                        placeholder={this.props.placeholder}
                        inputRef={field => this.textField = field}
                        name={this.props.name}
                        label={this.props.label}
                        fullWidth={true}
                        required={this.props.required}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        type={this.props.type === "password" ? "password" : "text"}
                        error={!isNullOrUndefined(this.props.error) && this.props.error}
                        disabled={this.props.disabled}
                        autoFocus={this.props.autoFocus}
                        helperText={this.props.error}
                        onKeyPress={this.props.onKeyPress}
                    />
                </TextFieldContainer>


            </MuiThemeProvider>
        );
    }
}

export default (StyledTextField);