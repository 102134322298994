import React from 'react';
import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import styled from 'styled-components';


const RadioButtonsContainer = styled.div`
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: none;
    font-family: TruenoLight;
    font-size: 8.4px;
    letter-spacing: 0;
    width: 80%;
    padding-top: 24px;
`;

const theme = createMuiTheme({

    typography: {
        fontFamily:
            '"TruenoLight"',
        fontSize: '10px',
        useNextVariants: true,

    },



    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
    }
});

theme.overrides = {

    MuiFormLabel: {
        root: {
            textTransform: 'uppercase',
            fontSize: '12px',
            height: 20,
        },
    },

    MuiFormControlLabel: {
        root: {
            textTransform: 'uppercase',
            fontSize: '10px',
            height: 18,
            fontFamily: 'TruenoLight',
            marginTop: '8px',
            marginLeft: '0px',
            marginRight: '0px'

        },
    },
    MuiFormGroup: {
        root: {
            marginTop: '16px',
            display: 'block',
            [theme.breakpoints.down('lg')]: {
                width: '50%',
                marginBottom: '16px',
            },
            [theme.breakpoints.up('lg')]: {
                width: '100%',
            },
            marginLeft: '0px',
            marginRight: 'auto'
        }
    }
};


class P23RadioButtons extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (


            <MuiThemeProvider theme={theme}>
                <RadioButtonsContainer>
                    <FormControl>
                        <FormLabel component="legend">{this.props.label}</FormLabel>
                        <RadioGroup
                            aria-label={this.props.name}
                            name={this.props.name}
                            value={this.props.value}
                            onChange={this.props.onChange}
                        >

                            {this.props.choices.map(choice => <FormControlLabel key={choice.value} value={choice.value}
                                                                                control={<Radio color="primary"
                                                                                                disabled={this.props.disabled}/>}
                                                                                label={choice.label}/>)}

                        </RadioGroup>
                    </FormControl>
                </RadioButtonsContainer>


            </MuiThemeProvider>
        );
    }
}

export default (P23RadioButtons);