import React, {Component} from 'react';
import {initialize} from "../common/configuration";

import styled from 'styled-components';
import {i18n, i18nText} from "../common/I18n";
import Header from "../components/Header";
import Footer from "../components/Footer";
import qs from 'qs';
import {Redirect} from 'react-router';
import quote_top from '../assets/quote_top.svg';
import quote_bottom from '../assets/quote_bottom.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {isNullOrUndefined} from "util";


import * as firebase from "firebase";

import firebaseApp from '../common/firebase';
import LoginForm from "../components/LoginForm";
import Timer from "../components/Timer";
import withDebug from "../common/withDebug";

const firestore = firebaseApp.firestore();

const Wizard = styled.div`

margin-top: 54px;
margin-bottom: 54px;
@media screen and (max-width: 1200px) {
margin-left: 0%;
margin-right: 0%;
}
`;

const QuestionCSS = styled.div`
width: 70%;
text-align: center;
display: grid;
grid-template-columns: 10% 80% 10%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 900px) {
width: 100%;
grid-template-columns: 20% 60% 20%;
}
`;

const ContentCSS = styled.div`
margin-top: 64px;
width: 70%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 900px) {
width: 90%;
}
`;

const TextCSS = styled.div`
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
opacity: 0.8;
text-transform: uppercase;
justify-self: center;
align-self: center;
padding-left: 24px;
padding-right: 24px;
`;

const LeftQuoteCSS = styled.img`
justify-self: end;
align-self: start;
margin-top: -32px;
`;

const RightQuoteCSS = styled.img`
justify-self: start;
align-self: end;
margin-bottom: -32px;
`;

const ErrorCSS = styled.div`
opacity: 0.8;
font-family: TruenoLight;
font-size: 18px;
color: #FF6344;
letter-spacing: 0;
line-height: 28px;
margin-top: 16px;
text-align: center;
`;


const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
    },
    typography: {
        useNextVariants: true,
    },

});


function Question(props) {
    return (
        <QuestionCSS>
            <LeftQuoteCSS src={quote_top}/>
            <TextCSS>{props.children}</TextCSS>
            <RightQuoteCSS src={quote_bottom}/>
        </QuestionCSS>
    )
}


class RedirectPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            site: props.match.params.site,
            redirect: false,
            url: null,
            username: null,
            password: null,
            error: null,
            working: false,
            user: null,
            initialized:false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            site: nextProps.match.params.site,
        };
    }

    componentDidMount() {

        initialize().then(() => this.setState({initialized: true}));

        firebaseApp.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({user: user}, () => this.redirect())

            }
        });
    }

    redirect = async () => {

        if (this.state.site === 'stripe') {
            const code = qs.parse(this.props.location.search.slice(1)).code;
            const error = qs.parse(this.props.location.search.slice(1)).error;
            const state = qs.parse(this.props.location.search.slice(1)).state;

            if (isNullOrUndefined(error)) {

                try {

                    let stripeConnectRequests = null;
                    try {

                        stripeConnectRequests = await firestore.collection('stripeConnectRequests').doc(code).get();
                    } catch (error) {
                        // error then we assume it does not exoist
                    }

                    if (stripeConnectRequests !== null && stripeConnectRequests.exists) {

                        if (stripeConnectRequests.data().status === "created") {
                            this.setState({redirect: true, url: `/host/my/draft/${state}`});
                        } else {
                            this.setState({
                                redirect: false,
                                error: i18n("redirect/processing/error/codeAlreadyExists")
                            });
                        }
                    } else {

                        await firestore.collection('stripeConnectRequests').doc(code).set({
                            uid: this.state.user.uid,
                            code: code,
                            error: isNullOrUndefined(error) ? null : error,
                            status: "created",
                            created: new Date(),
                            updated: new Date(),
                        });

                        this.setState({redirect: true, url: `/host/my/draft/${state}`});


                    }
                } catch (error) {
                    this.setState({redirect: false, error: i18n(`error/${error.code}`)});
                }
            }
        }

        if (this.state.site === 'password') {
            this.setState({redirect: true, url: "/host/my"});
        }
    };

    handleLogin(name) {
        this.setState({working: true}, () => {
            firebase.auth().signInWithEmailAndPassword(this.state.username, this.state.password)
                .catch((error) => {
                    this.setState({error: i18n("error/" + error.code), working: false})
                })
        });
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    render() {

        if (!this.state.initialized) {
            return (<Timer/>);
        }

        return (
            <div style={{userSelect: "none"}}>

                {this.state.user === null &&
                <Timer time={1}>
                    <Header></Header>

                    <Wizard>
                        <Question>{i18n("redirect/processing/" + this.state.site)}</Question>
                        <ContentCSS>
                            <div style={{
                                width: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}>

                                {this.state.site === "stripe" &&
                                <LoginForm
                                    onLogin={this.handleLogin.bind(this)}
                                    error={this.state.error}
                                    username={this.state.username}
                                    password={this.state.password}
                                    working={this.state.working}
                                    onChange={this.handleChange.bind(this)}/>
                                }


                            </div>
                        </ContentCSS>
                    </Wizard>
                    <Footer/>
                </Timer>
                }

                {this.state.user !== null &&

                <div style={{userSelect: "none"}}>
                    <Header></Header>


                    {(this.state.redirect) &&
                    <Redirect push to={this.state.url}/>
                    }

                    {isNullOrUndefined(this.state.error) &&
                    <Wizard>
                        <Question>{i18n("redirect/processing/" + this.state.site)}</Question>
                        <ContentCSS>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>

                                <MuiThemeProvider theme={theme}>
                                    <CircularProgress size={50}/>
                                </MuiThemeProvider>
                            </div>
                        </ContentCSS>
                    </Wizard>
                    }

                    {!isNullOrUndefined(this.state.error) &&
                    <Wizard>
                        <Question>{i18n("redirect/processing/" + this.state.site + "/error")}</Question>
                        <ContentCSS>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>

                                <ErrorCSS>
                                    {this.state.error}
                                </ErrorCSS>
                            </div>
                        </ContentCSS>
                    </Wizard>
                    }


                    <Footer></Footer>
                </div>
                }
            </div>
        );
    }
}

export default withDebug()(RedirectPage);
