import React, {Component} from 'react';

import {languagePacks} from "./configuration";

import ReactHtmlParser from 'react-html-parser';
import {isNullOrUndefined} from "util";


const language = (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;

let languagePack = languagePacks.de_DE;
let locale = "de_DE";
let country = "DE";

let DEBUG = false;


switch(language) {
    case "de":
    case "de-DE":
    case "de-CH":
    case "de-LU":
    case "de-LI":
    case "de-AT":
        languagePack = languagePacks.de_DE;
        locale = "de_DE";
        break;
    default:
        languagePack = languagePacks.en_GB;
        locale = "en_GB";
}


String.prototype.replaceAll = function(target, replacement) {
    return this.split(target).join(replacement);
};

export function i18n(id, values, nulled) {

    if (DEBUG) return id;
    let pack = languagePack;

    if (!pack.has(id)) {
        pack = languagePacks.de_DE;
    }



    if (pack.has(id)) {


        var string = pack.get(id);

        if (values) {

            Object.entries(values).forEach(([key, value]) => {

                string = string.replaceAll(`{${key}}`, value);
            });
        }
        return ReactHtmlParser(string);
    }
    else
        return nulled ? null : id;
}

export function i18nRaw(id, values) {

    if (DEBUG) return id;

    let pack = languagePack;

    if (!pack.has(id)) {
        pack = languagePacks.de_DE;
    }

    if (pack.has(id)) {


        var string = pack.get(id);

        if (values) {

            Object.entries(values).forEach(([key, value]) => {

                string = string.replaceAll(`{${key}}`, value);
            });
        }
        return string;
    }
    else
        return id;
}

export function i18nText(element) {

    if (isNullOrUndefined(element)) {
        return null;
    }

    switch (locale) {
        case "de_DE":
            return !isNullOrUndefined(element.de_DE) ? element.de_DE : !isNullOrUndefined(element.en_GB) ? element.en_GB : null;
        case "en_GB":
            return !isNullOrUndefined(element.en_GB) ? element.en_GB : !isNullOrUndefined(element.de_DE) ? element.de_DE : null;
        default:
            return !isNullOrUndefined(element.de_DE)  ? element.de_DE : !isNullOrUndefined(element.en_GB) ? element.en_GB : null;
    }
}

export function i18nTextFormatted(element) {
    switch (locale) {
        case "de_DE":
            return ReactHtmlParser(element.de_DE);
        case "en_GB":
            return (element.en_GB) ? ReactHtmlParser(element.en_GB) : ReactHtmlParser(element.de_DE);
        default:
            return ReactHtmlParser(element.de_DE);
    }
}
export function i18nConfiguration(id, value, values) {

    let pack = languagePack;

    if (!pack.has("configuration/" + id + "/" + value)) {
        pack = languagePacks.de_DE;
    }

    if (pack.has("configuration/" + id + "/" + value)) {

        var string = pack.get("configuration/" + id + "/" + value);

        if (values) {

            Object.entries(values).forEach(([key, value]) => {

                string = string.replaceAll(`{${key}}`, value);
            });
        }
        return ReactHtmlParser(string);


    }
    else
        return "configuration/" + id + "/" + value;

}


export function i18nLocale() {
    return locale;
}

export function i18nCountry() {
    return country;
}

export function i18nChange(language) {

    switch(language) {
        case "de":
        case "de_DE":
        case "de_AT":
        case "de_LU":
        case "de_LI":
        case "de_CH":
            languagePack = languagePacks.de_DE;
            locale = "de_DE";
            break;
        default:
            languagePack = languagePacks.en_GB;
            locale = "en_GB";
    }
}

export function setDEBUG(debug) {
    DEBUG = debug;
}



