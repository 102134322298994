import React from 'react';
import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import styled from 'styled-components';

const TextFieldContainer = styled.div`

box-shadow: 0 2px 6px 0 rgba(0,0,0,0.50);
border-radius: 8px;
    padding-left: 16px;
    padding-top: 3px;
    padding-bottom: 8px;
    border: none;
                    letter-spacing: 2px;
    width: ${props => props.width ? props.width : "100%"};
`;





class StyledTextArea extends React.Component {

    constructor(props) {
        super(props);
    }

    textField = null;

    render() {

        const theme = createMuiTheme({

            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#04ADA9',
                    dark: '#04ADA9',
                    // contrastText: will be calculated to contast with palette.primary.main
                },
                secondary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#FF6344',
                    dark: '#FF6344',
                    // contrastText: will be calculated to contast with palette.primary.main
                },
            },



            typography: {
                useNextVariants: true,
            },


            overrides: {
                typography: {
                    fontFamily:
                        '"TruenoLight"'
                },

                MuiInputLabel: {
                    root: {
                        textTransform: 'uppercase',
                        fontSize: this.props.inputLabelFontSize ? this.props.inputLabelFontSize : "12px",
                        height: 22,
                        color: this.props.inputLabelColor? this.props.inputLabelColor : '#E2E2E2',
                        letterSpacing: 1.5,
                        fontFamily: 'TruenoLight',
                        textAlign: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }
                },
                MuiPrivateTextarea: {
                  root: {
                      height: "100%",
                  }
                },
                MuiInput: {
                    root: {
                        textTransform: 'uppercase',
                        fontSize: '16px',
                        height: this.props.height ? this.props.height : 200,
                        paddingTop: 8,
                        paddingBottom: 8,
                        fontFamily: 'TruenoRegular',
                        letterSpacing: 2,

                    },
                },
            }
        });


        return (


            <MuiThemeProvider theme={theme}>
                <TextFieldContainer width={this.props.width} onClick={() => this.textField.focus()}>
                    <TextField
                        InputProps={{
                            startAdornment: this.props.startAdornment,
                            endAdornment: this.props.endAdornment,
                            disableUnderline: true,
                            inputComponent: this.props.mask
                        }}
                        inputRef={field => this.textField = field}
                        name={this.props.name}
                        multiline
                        rowsMax={this.props.rowsMax ? this.props.rowsMax : "10"}
                        label={this.props.label}
                        fullWidth={true}
                        required={this.props.required}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        error={this.props.error}
                        disabled={this.props.disabled}
                        autoFocus={this.props.autoFocus}
                        onKeyPress={this.props.onKeyPress}
                    />
                </TextFieldContainer>


            </MuiThemeProvider>
        );
    }
}

export default (StyledTextArea);