import React from "react";
import Dashboard from "./Dashboard";
import Subscribers from "./Subscribers";
import Feedback from "./Feedback";
import Users from "./Users";
import Drafts from "./Drafts";
import Events from "./Events";
import Bookings from "./Bookings";
import Reportings from "./Reportings";
import Masterdata from "./Masterdata";
import Times from "./Times";
import Countries from "./Countries";
import Cities from "./Cities";
import Localization from "./Localization";
import Fees from "./Fees";
import Muted from "./Muted";
import UnwantedImages from "./UnwantedImages";
import News from "./News";
import Tweets from "./Tweets";
import Quotes from "./Quotes";
import Tags from "./Tags";
import ScoreWeights from "./ScoreWeights";
import Notifications from "./Notifications";
import Vouchers from "./Vouchers";
import Scorings from "./Scorings";
import Testimonials from "./Testimonials";
import Payments from "./Payments";
import Admins from "./Admins";
import ImageWall from "./ImageWall";

export const components = {
    dashboard: {
        menu: "Dashboard",
        title: "Dashboard",
        tooltip: "Dashboard",
        content: <Dashboard/>
    },
    subscribers: {
        menu: "Subscribers",
        title: "Subscribers",
        tooltip: "Subscribers",
        content: <Subscribers/>
    },
    feedback: {
        menu: "Feedback",
        title: "Feedback",
        tooltip: "Feedback",
        content: <Feedback/>
    },
    users: {
        menu: "User",
        title: "User",
        tooltip: "User",
        content: <Users/>
    },
    drafts: {
        menu: "Drafts",
        title: "Drafts",
        tooltip: "Drafts",
        content: <Drafts/>
    },
    events: {
        menu: "Events",
        title: "Events",
        tooltip: "Events",
        content: <Events/>
    },
    payments: {
        menu: "Payments",
        title: "Payments",
        tooltip: "Payments",
        content: <Payments/>
    },
    bookings: {
        menu: "Bookings",
        title: "Bookings",
        tooltip: "Bookings",
        content: <Bookings/>
    },
    reportings: {
        menu: "Reportings",
        title: "Reportings",
        tooltip: "Reportings",
        content: <Reportings/>
    },
    cuisines: {
        menu: "Cuisines",
        title: "Cuisines",
        tooltip: "Cuisines",
        content: <Masterdata type={"cuisines"}/>
    },
    languages: {
        menu: "Languages",
        title: "Languages",
        tooltip: "Languages",
        content: <Masterdata type={"languages"}/>
    },
    specials: {
        menu: "Specials",
        title: "Specials",
        tooltip: "Specials",
        content: <Masterdata type={"specials"}/>
    },
    allergens: {
        menu: "Allergens",
        title: "Allergens",
        tooltip: "Allergens",
        content: <Masterdata type={"allergens"}/>
    },
    courtesies: {
        menu: "Courtesies",
        title: "Courtesies",
        tooltip: "Courtesies",
        content: <Masterdata type={"courtesies"}/>
    },
    types: {
        menu: "Types",
        title: "Types",
        tooltip: "Types",
        content: <Masterdata type={"types"}/>
    },
    policies: {
        menu: "Policies",
        title: "Policies",
        tooltip: "Policies",
        content: <Masterdata type={"policies"}/>
    },
    reportReasons: {
        menu: "Report Reasons",
        title: "Report Reasons",
        tooltip: "Report Reasons",
        content: <Masterdata type={"reportReasons"}/>
    },
    mobilePrefixCountries: {
        menu: "Mobile prefixes",
        title: "Mobile prefixes",
        tooltip: "Mobile prefixes",
        content: <Masterdata type={"mobilePrefixCountries"}/>
    },

    times: {
        menu: "Cancelation Times",
        title: "Cancelation Times",
        tooltip: "Cancelation Times",
        content: <Times/>
    },

    countries: {
        menu: "Countries",
        title: "Countries",
        tooltip: "Countries",
        content: <Countries/>
    },

    localization: {
        menu: "Localization",
        title: "Localization",
        tooltip: "Localization",
        content: <Localization/>
    },

    cities: {
        menu: "Cities",
        title: "Cities",
        tooltip: "Cities",
        content: <Cities/>
    },

    fees: {
        menu: "Fees",
        title: "Fees",
        tooltip: "Fees",
        content: <Fees/>
    },

    muted: {
        menu: "Muted words",
        title: "Muted words",
        tooltip: "Muted words",
        content: <Muted/>
    },

    unwantedimages: {
        menu: "Unwanted images",
        title: "Unwanted images",
        tooltip: "Unwanted images",
        content: <UnwantedImages/>
    },

    news: {
        menu: "News",
        title: "News",
        tooltip: "News",
        content: <News/>
    },

    images: {
        menu: "Images",
        title: "Images",
        tooltip: "Images",
        content: <ImageWall/>
    },

    tweets: {
        menu: "Tweets",
        title: "Tweets",
        tooltip: "Tweets",
        content: <Tweets/>
    },

    quotes: {
        menu: "Quotes",
        title: "Quotes",
        tooltip: "Quotes",
        content: <Quotes/>
    },

    tags: {
        menu: "Special Events",
        title: "Special Events",
        tooltip: "Special Events",
        content: <Tags/>
    },

    scoreWeights: {
        menu: "Score Weights",
        title: "Score Weights",
        tooltip: "ScoreWeights",
        content: <ScoreWeights/>
    },

    notifications: {
        menu: "Notifications",
        title: "Notifications",
        tooltip: "Notifications",
        content: <Notifications/>
    },
    vouchers: {
        menu: "Vouchers",
        title: "Vouchers",
        tooltip: "Vouchers",
        content: <Vouchers/>
    },
    scorings: {
        menu: "Scorings",
        title: "Scorings",
        tooltip: "Scorings",
        content: <Scorings/>
    },
    testimonials: {
        menu: "Testimonials",
        title: "Testimonials",
        tooltip: "Testimonials",
        content: <Testimonials/>
    },
    admins: {
        menu: "Admin User",
        title: "Admin User",
        tooltip: "Admin User",
        content: <Admins/>
    }
}