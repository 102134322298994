import React, {Component} from 'react';
import styled from 'styled-components';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    PostalCodeElement,
    injectStripe,
    CardElement
} from 'react-stripe-elements';

import {i18nText, i18n, i18nLocale} from "../common/I18n";
import StyledButton from "./StyledButton";


const Wrapper = styled.div`
    @media screen and (max-width: 1100px) {
    padding-left: 10%;
    padding-right: 10%;
}

`;

const TextFieldContainer = styled.div`

box-shadow: 0 2px 6px 0 rgba(0,0,0,0.50);
border-radius: 8px;
    padding-left: 16px;
    padding-top: 24px;
    padding-bottom: 24px;
    border: none;
    user-select: none;
    letter-spacing: 2px;
    width: ${props => props.width};
`;

const LineCSS = styled.div`
display: flex;
justify-content: space-between;
padding-bottom: 16px;
`;

const ButtonCSS = styled.div`
display: flex;
padding-top: 24px;
justify-content: flex-end;
    @media screen and (max-width: 1100px) {
justify-content: center;

}
`;

const createOptions = (placeholder) => {
    return {
        style: {
            base: {
                letterSpacing: 1.5,
                fontSize: "16px",
                '::placeholder': {
                    textTransform: 'uppercase',
                    fontSize: '16px',
                    color: '#E2E2E2',
                    letterSpacing: 1.5,


                },
            },
            invalid: {
                color: '#FF6344',
            },
        },
    };
};

class CC extends Component {

    constructor(props) {
        super(props);
        this.state = {
            highlight: null,
            cardNumber: false,
            cardExpiry: false,
            cardCvc: false,
            brand: null,
            working: false,
        };
    }

    handleChange = (change) => {

        if (change.elementType === "cardNumber") {
            this.setState({brand: change.brand}, () => {
                this.props.onBrandChange(this.state.brand)
            });
        }

        this.setState({[change.elementType]: change.complete}, () => {
            this.props.onCompletedChange(this.state.cardNumber && this.state.cardExpiry && this.state.cardCvc)
        })
    };

    handleFocus = (element) => {
        this.setState({highlight: element}, () => {
            this.props.onHighlight(this.state.highlight)
        })
    };

    handleBlur = (element) => {
        this.setState({highlight: null}, () => {
            this.props.onHighlight(this.state.highlight)
        })
    };

    handleClick = (name) => {

        this.setState({working: true}, () => {

            this.props.stripe.createToken({name: `${this.props.name}`})
                .then(({token}) => {

                    this.setState({working: false}, () => {
                        this.props.onToken(token)
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({working: false, error: error});
                })
        });

    };

    render() {

        return (
            <Wrapper>

                <LineCSS>
                    <TextFieldContainer width={"100%"}>
                        <CardNumberElement
                            onChange={this.handleChange}
                            onFocus={(event) => this.handleFocus("cardNumber")}
                            onBlur={(event) => this.handleBlur("cardNumber")}
                            {...createOptions()}
                        />
                    </TextFieldContainer>
                </LineCSS>
                <LineCSS>
                    <TextFieldContainer width={"100px"}>
                        <CardExpiryElement
                            onChange={this.handleChange}
                            onFocus={(event) => this.handleFocus("cardExpiry")}
                            onBlur={(event) => this.handleBlur("cardExpiry")}
                            {...createOptions()}
                        />
                    </TextFieldContainer>


                    <TextFieldContainer width={"100px"}>
                        <CardCVCElement
                            onChange={this.handleChange}
                            onFocus={(event) => this.handleFocus("cardCvc")}
                            onBlur={(event) => this.handleBlur("cardCvc")}
                            {...createOptions()}
                        />
                    </TextFieldContainer>
                </LineCSS>

                <ButtonCSS>
                    <div style={{width: "250px"}}>
                        <StyledButton label={this.props.label}
                                      name="stripe"
                                      working={this.state.working || this.props.working}
                                      onClick={this.handleClick.bind(this)}
                                      disabled={!(this.state.cardNumber && this.state.cardExpiry && this.state.cardCvc)}/>
                    </div>
                </ButtonCSS>


            </Wrapper>

        );
    }
}

export default injectStripe(CC);
