import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';
import MUIDataTable from "mui-datatables";
import firebaseApp from "../common/firebase";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button/Button";
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import RefreshIcon from "@material-ui/icons/Refresh";

const firestore = firebaseApp.firestore();


const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
});

class Times extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            values: [],
            showDialog: false,
            label: "",
            hours: "0",
        };
    }


    componentDidMount() {
        this.reload();
    }

    reload = () => {
        this.setState({loading: true}, async () => {
            try {

                let fsElement = await firestore.collection("masterdata").doc("times").get();

                this.setState({values: [...fsElement.data().values], loading: false});
            } catch (error) {
                console.error(error);
                this.setState({loading: false});
            }

        })

    };

    handleDelete = (selectedRows) => {

        this.setState({working: true}, async () => {
            try {


                let values = [...this.state.values];

                for (const selectedRow of selectedRows.data) {
                    values = values.filter(value => value.label !== this.state.values[selectedRow.dataIndex].label);
                }

                await firestore.collection("masterdata").doc("times").set({
                    values: values
                });

                this.reload();

            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });

    };

    handleChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    openDialog = () => {
        this.setState({showDialog: true, label: "", hours: "0"})
    };

    closeDialog = () => {
        this.setState({showDialog: false, label: "", hours: "0"})
    };

    handleSave = () => {
        this.setState({working: true}, async () => {

            let values = [...this.state.values];
            values.push({label: this.state.label, hours: parseInt(this.state.hours)});

            await firestore.collection("masterdata").doc("times").set({
                values: values
            });

            this.setState({working: false, showDialog: false, label: "", hours: "0"}, () => {
                this.reload();
            })
        })
    };


    render() {
        const {classes} = this.props;

        const columns = [
            {

                name: "Label",
                field: "label",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {

                name: "Hours",
                field: "hours",
                options: {
                    filter: false,
                    sort: true
                }
            },
        ];


        const options = {
            filter: false,
            selectableRows: 'multiple',
            responsive: 'scrollFullHeight',
            customToolbar: () =>
                <Tooltip title={"Reload Data"}>
                    <IconButton className={classes.iconButton}
                                onClick={this.reload}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>,
            customToolbarSelect: (selectedRows) =>
                <StyledCustomToolbarSelect
                    selectedRows={selectedRows}
                    onDelete={this.handleDelete}/>

        };

        const data = this.state.values.map(value => {

            const line = [];

            for (const column of columns) {

                let field = value[column.field];
                line.push(field);
            }

            return line;
        });

        const labelExistsAlready = this.state.values.filter(value => value.label === this.state.label).length > 0;
        const isValidHours = !isNaN(this.state.hours) && parseInt(this.state.hours) >= 0;
        const isValidLabel = this.state.label.trim().length > 0;

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page}) => (
                        <React.Fragment>

                            {this.state.loading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress className={classes.progress} size={50}/>
                                    </Grid>
                                ) :
                                (
                                    <React.Fragment>

                                        <MUIDataTable
                                            columns={columns}
                                            data={data}
                                            title={page.title}
                                            options={options}
                                        />

                                        <Button variant="fab" className={classes.fab} color={"secondary"}
                                                onClick={this.openDialog}>
                                            <AddIcon/>
                                        </Button>

                                    </React.Fragment>

                                )}


                            <Dialog
                                open={this.state.showDialog}
                                aria-labelledby="form-dialog-title"
                            >
                                <DialogTitle id="form-dialog-title">Add a new value to {page.title}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        add a new value. Be aware that you can't edit values afterwards but have to
                                        delete them
                                    </DialogContentText>
                                    <TextField
                                        value={this.state.label}
                                        autoFocus
                                        margin="dense"
                                        id="label"
                                        name="label"
                                        label="Label"
                                        type="text"
                                        error={labelExistsAlready}
                                        helperText={labelExistsAlready ? "Label exists already" : ""}
                                        onChange={this.handleChange}
                                        fullWidth
                                        disabled={this.state.working}
                                    />

                                    <TextField
                                        value={this.state.time}
                                        margin="dense"
                                        id="hours"
                                        name="hours"
                                        label="Hours"
                                        type="number"
                                        error={!isValidHours}
                                        helperText={!isValidHours ? "only positive numbers" : ""}
                                        onChange={this.handleChange}
                                        fullWidth
                                        disabled={this.state.working}
                                    />
                                </DialogContent>

                                <DialogActions>
                                    <Button onClick={this.closeDialog} color="primary" disabled={this.state.working}>
                                        Close
                                    </Button>
                                    <Button onClick={this.handleSave} color="primary" autoFocus
                                            disabled={!isValidHours || !isValidHours || labelExistsAlready}>
                                        Save
                                    </Button>
                                </DialogActions>
                            </Dialog>


                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

Times.propTypes = {
    classes: PropTypes.object.isRequired,
};

const defaultToolbarSelectStyles = {
    iconButton: {
        marginRight: "24px",
        top: "50%",
        display: "inline-block",
        position: "relative",
        transform: "translateY(-50%)",
    },
};

class CustomToolbarSelect extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <div className={"custom-toolbar-select"}>
                <Tooltip title={"Delete"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onDelete(this.props.selectedRows)}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

}

const StyledCustomToolbarSelect = withStyles(defaultToolbarSelectStyles, {name: "CustomToolbarSelect"})(CustomToolbarSelect);

export default withStyles(styles, {withTheme: true})(Times)