import React from 'react';

import styled from 'styled-components';
import {Grid, Flex} from "../common/Layout";

import line from "../assets/line.svg";
import circle from "../assets/circle.svg";


import activeLine from "../assets/activeLine.svg";
import activeCircle from "../assets/activeCircle.svg";





const Wrapper = styled.div`
    width:100%;
    @media screen and (max-width: 690px) {
        display: none;
    
    }
`;

const LinkCSS = styled.a`
    width:100%;
    justify-self: end;
    align-self: center;
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #FF6344;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
text-align: right;
user-select: none;
&:hover {
cursor: pointer;
}
`;

const FlexCSS = styled.div`

padding-left: 24px;
    display: flex;
    width:100%;
    justify-self: start;
    align-self: center;
    user-select: none;
`;

const Step = styled.img`
    opacity:${props => props.opacity};
    &:hover {
cursor: pointer;
}
`;


class Progress extends React.Component {

    constructor(props) {
        super(props);
    }

    calcOpacity(number, count, current) {

        let diff = 0;

        if (number < current) {

            diff = (1 / count * (current - number));

        } else {
            diff = (1 / count * (number - current));
        }

        let clamp = (diff > 0.95) ? 0.95 : diff;
        return 1.0 - clamp;


    }



    render() {
        return (

            <Wrapper>
                <Grid columns={"30% 70%"}>
                    <LinkCSS href={this.props.href}>{this.props.title}</LinkCSS>

                    <FlexCSS>

                        {Array.from(Array(this.props.count).keys()).map(number => {
                            if (number < 1) {
                                return (
                                    <Step key={number} opacity={this.calcOpacity(number, this.props.count, this.props.current)}
                                          src={(number <= this.props.current) ? activeCircle : circle}
                                          onClick={event => (number < this.props.current && this.props.onClick) ? this.props.onClick(number) : null}/>
                                )
                            } else {
                                return (
                                    <Flex key={number} onClick={event => ((number < this.props.current) && this.props.onClick) ? this.props.onClick(number) : null}>
                                        <Step opacity={this.calcOpacity(number, this.props.count, this.props.current)}
                                              src={(number <= this.props.current) ? activeLine : line}/>
                                        <Step opacity={this.calcOpacity(number, this.props.count, this.props.current)}
                                              src={(number <= this.props.current) ? activeCircle : circle}/>
                                    </Flex>
                                )
                            }
                        })}
                    </FlexCSS>
                </Grid>
            </Wrapper>

        );
    }
}

export default (Progress);