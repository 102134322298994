import React, { Component } from 'react';
import {initialize} from "../common/configuration";
import * as assetsService from "../common/assets";

import styled from 'styled-components';
import { i18n, i18nChange, i18nText } from "../common/I18n";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Categories from "../components/Categories";
import EventsWall from "../components/EventsWall";
import SearchInput from "../components/SearchInput";

import EventBlock from "../components/EventBlock";
import BookingBlock from "../components/BookingBlock";
import Carousel from "../components/Carousel";
import firebaseApp, { trackUserPresence } from "../common/firebase";
import { isNullOrUndefined } from "util";
import ErrorSnackbar from "../components/ErrorSnackbar";
import Timer from "../components/Timer";
import { Redirect } from "react-router";
import HostEventBlock from "../components/HostEventBlock";
import StyledProgress from "../components/StyledProgress";
import { List } from "react-content-loader";
import Assistent, { AssistantActions } from "../components/Assistent";
import StyledFlatButton from "../components/StyledFlatButton";
import passion from "../assets/passion.svg";
import easy from "../assets/easy.svg";
import exclusive from "../assets/exclusive.svg";
import money from "../assets/money.svg";
import people from "../assets/people.svg";
import event from "../assets/event.svg";
import explore from "../assets/explore.svg";
import goodfood from "../assets/goodfood.svg";
import ReactDOM from "react-dom";
import ChatWindow from "../components/ChatWindow";
import placeholder from "../assets/placeholder.svg";
import moment from "moment";
import { sortEventsBy } from "../common/utils";
import withDebug from "../common/withDebug"


firebaseApp.auth().useDeviceLanguage();
const firestore = firebaseApp.firestore();
const storage = firebaseApp.storage();


const TitleCSS = styled.div`
width:90%;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 80px;
opacity: 0.8;
font-family: TruenoBold;
font-size: 34px;
color: #333333;
letter-spacing: -0.49px;
line-height: 42px;
text-transform: uppercase;
`;

const HintCSS = styled.div`
width:90%;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 80px;
opacity: 0.1;
font-family: TruenoLight;
font-size: 34px;
color: #333333;
letter-spacing: -0.49px;
line-height: 42px;
text-transform: uppercase;
user-select: none;
text-align: center;
`;

const WrapperCSS = styled.div`
    padding-left: 5%;
    padding-right: 5%;
`;

const ClaimCSS = styled.div`
display: block;
margin-top:20px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 16px;
color: #818181;
letter-spacing: 0;
text-align: left;
line-height: 26px;
width:100%;
@media screen and (max-width: 800px) {
width:100%;
}

`;


const RowCSS = styled.div`
  margin-top: 42px;

display: grid;
  grid-template-columns: 20% 28% 4% 20% 28%;
  
    @media screen and (max-width: 800px) {
  grid-template-columns: 40% 60%;
  grid-template-rows: auto auto;
  grid-column-gap: 5px;
      grid-row-gap: 42px;
}

`;

const Image1CSS = styled.img`
grid-column-start: 1;
grid-column-end: 2;
   @media screen and (max-width: 800px) {
grid-column-start: 1;
grid-column-end: 2;
grid-row-start: 1;
grid-row-end: 2;
}
width: 90%;
box-shadow: 0 0 24px 0 rgba(0,0,0,0.04);
border-radius: 100%;
z-index: 10;
`;

const Text1CSS = styled.div`
grid-column-start: 2;
grid-column-end: 3;
   @media screen and (max-width: 800px) {
grid-column-start: 2;
grid-column-end: 3;
grid-row-start: 1;
grid-row-end: 2;
}

`;

const Image2CSS = styled.img`
grid-column-start: 4;
grid-column-end: 5;
   @media screen and (max-width: 800px) {
grid-column-start: 1;
grid-column-end: 2;
grid-row-start: 2;
grid-row-end: 3;
}
width: 90%;
box-shadow: 0 0 24px 0 rgba(0,0,0,0.04);
border-radius: 100%;
z-index: 10;

`;

const Text2CSS = styled.div`
grid-column-start: 5;
grid-column-end: 6;
   @media screen and (max-width: 800px) {
grid-column-start: 2;
grid-column-end: 3;
grid-row-start: 2;
grid-row-end: 3;
}
`;

const SmallTitleCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
`;

const ParagraphCSS = styled.div`
margin-top: 12px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 14px;
color: #818181;
letter-spacing: 0;
line-height: 26px;
`;


class My extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            roles: null,
            bookings: [],
            favorites: [],
            suggestions: [],
            messages: [],
            loadingBookings: false,
            systemError: null,
            scrollToTop: false,
            showChat: false,
            chatWithName: null,
            reference: null,
            events: [],
            tweets: [],
            quotes: [],
            news: [],
            images: [],
            initialized: false,
            assetURLs: {},
        };
    }

    componentDidMount() {
        initialize().then(async () => {
            await this.fetchAssets();
            await this.setState({initialized: true});
        });

        firebaseApp.auth().onAuthStateChanged(async (user) => {
            if (user) {
                try {

                    const profile = await firestore.collection('profiles').doc(user.uid).get();
                    if (!profile.exists) {
                        this.setState({ systemError: "noProfile" });
                    } else {
                        this.setState({ user: profile.data() }, () => {
                            i18nChange(this.state.user.language);
                            trackUserPresence();
                            this.load();
                            this.listen(user.uid);
                            this.loadWall();
                        })
                    }
                } catch (error) {
                    this.setState({ systemError: error.code });
                }
            }
        });

    }

    componentDidUpdate = () => {

        if (this.state.scrollToTop) {
            this.setState({ scrollToTop: false }, () => {
                ReactDOM.findDOMNode(this).scrollIntoView()
            });
        }
    };

    fetchAssets = async () => {
        const fullPathAssets = [
        ];
        const noExtensionAssets = [
            'guest-my/illustration1',
            'guest-my/illustration2',
            'guest-my/illustration3',
            'guest-my/illustration4',
        ];
        const assetURLs = await assetsService.fetchAssets(fullPathAssets, noExtensionAssets);
        await this.setState({
            assetURLs: assetURLs,
        });
    };

    listen = (uid) => {
        firestore.collection('bookings').where("uid", "==", uid)
            .onSnapshot(
                snapshot => {
                    this.load();

                }
                , snapShotError => {
                    this.setState({ systemError: snapShotError.code });
                });

        firestore.collection('registrations').doc(uid)
            .onSnapshot(
                snapshot => {
                    this.setState({ hasRegistration: snapshot.exists });
                }
                , snapShotError => {
                    this.setState({ systemError: snapShotError.code });
                });

        firestore.collection('roles').doc(uid)
            .onSnapshot(
                snapshot => {
                    this.setState({ roles: snapshot.data() });
                }
                , snapShotError => {
                    this.setState({ systemError: snapShotError.code });
                });
    };

    load() {


        this.setState({
            loading: true,
            favorites: [],
            bookings: [],
            suggestions: [],
        }, async () => {

            try {


                let bookings = [];
                let favorites = [];
                let suggestions = [];

                const fsFavorites = await firestore.collection("favorites").where("uid", "==", this.state.user.uid).get();

                for (const fsFavorite of fsFavorites.docs) {
                    let favorite = { ...fsFavorite.data() };
                    favorite.id = fsFavorite.id;

                    const event = await firestore.collection("events").doc(favorite.eventId).get();

                    favorite.event = { ...event.data() };
                    favorite.event.id = event.id;
                    favorites.push(favorite);

                }


                const fsSuggestions = await firestore.collection("events").where("status", "==", "running").get();


                for (const fsSuggestion of fsSuggestions.docs) {
                    let suggestion = { ...fsSuggestion.data() };
                    suggestion.id = fsSuggestion.id;

                }

                suggestions = sortEventsBy(suggestions, this.state.user.tags);


                const fsBookings = await firestore.collection("bookings").where("uid", "==", this.state.user.uid).get();

                for (const fsBooking of fsBookings.docs) {
                    let booking = { ...fsBooking.data() };
                    booking.id = fsBooking.id;

                    const event = await firestore.collection("events").doc(booking.eventId).get();
                    booking.event = { ...event.data() };
                    booking.event.id = event.id;
                    bookings.push(booking);

                }


                // don't suggest events, which are already booked
                suggestions = suggestions.filter((suggestion) => {
                    return bookings.filter(booking => booking.event.id === suggestion.id).length === 0
                });

                // don't suggest events, which are already favorited
                suggestions = suggestions.filter((suggestion) => {
                    return favorites.filter(favorite => favorite.event.id === suggestion.id).length === 0
                });

                // don't suggest own events
                suggestions = suggestions.filter((suggestion) => {
                    return suggestion.uid !== this.state.user.uid;
                });

                // don't show favorites, which are already booked
                favorites = favorites.filter((favorite) => {
                    return bookings.filter(booking => booking.event.id === favorite.event.id).length === 0
                });



                this.setState({
                    loading: false,
                    bookings: bookings,
                    favorites: favorites,
                    suggestions: suggestions,
                });
            } catch (error) {
                this.setState({ loading: false, systemError: error.code });
            }
        });
    }

    loadWall() {

        this.setState({ events: [], tweets: [], quotes: [], news: [] , images: []}, async () => {
            const fsEvents = await firestore.collection("events").where("status", "==", "running").get();

            let events = [];

            for (const fsEvent of fsEvents.docs) {
                let event = { ...fsEvent.data() };
                event.id = fsEvent.id;
                events.push(event);

            }

            const fsTweets = await firestore.collection("tweets").orderBy("created", "desc").get();

            let tweets = [];

            for (const fsTweet of fsTweets.docs) {
                tweets.push({ ...fsTweet.data(), id: fsTweet.id });
            }

            const fsQuotes = await firestore.collection("quotes").orderBy("created", "desc").get();

            let quotes = [];

            for (const fsQuote of fsQuotes.docs) {
                quotes.push({ ...fsQuote.data(), id: fsQuote.id });
            }

            const fsNews = await firestore.collection("news").orderBy("created", "desc").get();

            let news = [];

            for (const fsNew of fsNews.docs) {
                news.push({ ...fsNew.data(), id: fsNew.id });
            }

            const fsImages = await firestore.collection("images").orderBy("created", "desc").get();
            let images = [];

            for (const fsImage of fsImages.docs) {
                images.push({...fsImage.data(), id: fsImage.id});
            }


            this.setState({ events: events, tweets: tweets, news: news, quotes: quotes, images: images });
        });


    }

    handleFavorite = (id) => {

        this.setState({ loading: true, scrollToTop: true }, async () => {

            try {

                await firestore.collection("favorites").add(
                    { uid: this.state.user.uid, eventId: id, created: new Date() }
                );

                this.load();

            } catch (error) {
                this.setState({ loading: false, systemError: error.code })
            }
        });


    };

    handleUnFavorite = (event) => {
        this.setState({ loading: true, scrollToTop: true }, async () => {

            try {
                const fsFavorites = await firestore.collection("favorites")
                    .where("eventId", "==", event.id)
                    .where("uid", "==", this.state.user.uid)
                    .get();

                for (const fsFavorite of fsFavorites.docs) {
                    await fsFavorite.ref.delete();
                }

                this.load();

            } catch (error) {
                this.setState({ loading: false, systemError: error.code })
            }
        });

    };

    handleBecomeGuest = () => {


        this.setState({ working: true }, async () => {

            try {

                await firestore.collection('registrations').doc(firebaseApp.auth().currentUser.uid).set(
                    {
                        step: "DONE",
                        mobileprefix: this.state.user.mobileprefix,
                        mobile: this.state.user.mobile,
                        email: this.state.user.email,
                        birthday: this.state.user.birthday,
                        people: 0,
                        image: this.state.user.image,
                        firstname: this.state.user.firstname,
                        lastname: this.state.user.lastname,
                        promoCode: null,
                        done: true,
                        created: new Date(),
                        updated: false,
                        event: null,
                    },
                    { merge: true }
                );

                this.setState({ working: false });

            } catch (error) {
                this.setState({ working: false, systemError: error });
            }


        })


    };

    handleChat = (booking) => {

        this.setState({
            showChat: true,
            chatWithName: booking.event.host.firstname,
            reference: booking,
        }, () => {
            firestore.collection('messages').where("eventId", "==", booking.event.id).where("hostUid", "==", booking.event.uid).where("guestUid", "==", this.state.user.uid)
                .onSnapshot(
                    fsMessages => {

                        const messages = [];
                        for (const fsMessage of fsMessages.docs) {

                            const message = {
                                text: fsMessage.data().text,
                                name: fsMessage.data().isGuest ? fsMessage.data().guest.name : fsMessage.data().host.name,
                                image: fsMessage.data().isGuest ? fsMessage.data().guest.image : fsMessage.data().host.image,
                                other: !fsMessage.data().isGuest,
                                readByGuest: fsMessage.data().readByGuest,
                                readByHost: fsMessage.data().readByHost,
                                timestamp: fsMessage.data().created.toDate(),
                                ref: fsMessage.ref,
                            };

                            messages.push(message);
                        }

                        messages.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());

                        this.setState({ messages: messages });

                    }
                    , snapShotError => {
                        this.setState({ systemError: snapShotError.code });
                    });
        });
    };

    handleClose = (booking) => {
        this.setState({ showChat: false, chatWithName: null, messages: [], reference: null, message: "" }, () => {
            firestore.collection('messages').where("eventId", "==", booking.event.id).where("hostUid", "==", booking.event.uid).where("guestUid", "==", this.state.user.uid)
                .onSnapshot(() => {
                })
        });
    };

    handleRead = async (messages) => {
        for (const message of messages) {
            if (!message.readByGuest) {
                await message.ref.set(
                    {
                        readByGuest: true,
                    },
                    { merge: true }
                )
            }
        }
    };

    handleSend = async (booking, text) => {
        await firestore.collection("messages").add(
            {
                guest: {
                    name: this.state.user.firstname,
                    image: this.state.user.image,
                },
                guestUid: this.state.user.uid,
                host: {
                    name: booking.event.host.firstname,
                    image: booking.event.host.image,
                },
                hostUid: booking.event.uid,
                readByHost: false,
                readByGuest: true,
                isGuest: true,
                text: text,
                eventId: booking.event.id,
                created: new Date()
            });
    };


    render() {

        if (!this.state.initialized) {
            return (<Timer />);
        }

        const isAuth = this.state.user !== null;
        const isGuest = !isNullOrUndefined(this.state.roles) && this.state.roles.guest;

        const activeBookings = this.state.bookings.filter((booking) => booking.status === "ok" || booking.status === "confirmed");
        // activeBookings.sort((a,b) => a.event.start.seconds - b.event.start.seconds);
        const pastBookings = this.state.bookings.filter((booking) => booking.status !== "ok" && booking.status !== "confirmed");

        return (

            <div>

                {!isAuth &&
                    <Timer time={4}>
                        <Redirect push to={`/`} />
                    </Timer>
                }

                {isAuth &&
                    <div>
                        <Header user={this.state.user}
                            roles={this.state.roles}><SearchInput></SearchInput></Header>

                        {this.state.loading ?
                            (
                                <List width={"400px"} style={{
                                    display: 'block',
                                    marginTop: "64px",
                                    marginBottom: "64px",
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }} />
                            ) :
                            (
                                <div>

                                    {!isGuest && !this.state.hasRegistration &&
                                        <Assistent width={"60%"}
                                            text={i18n("assistent/guest/notAGuest", { firstname: this.state.user.firstname })}
                                            actions={
                                                <AssistantActions>
                                                    <StyledFlatButton grey label={i18n("assistent/guest/goToHostMy")}
                                                        onClick={event => {
                                                            window.location = "/host/my"
                                                        }} />

                                                    <StyledFlatButton label={i18n("assistent/guest/becomeGuest")}
                                                        onClick={this.handleBecomeGuest.bind(this)}
                                                        disabled={this.state.working} />
                                                </AssistantActions>
                                            } />
                                    }

                                    {(!isGuest) && this.state.hasRegistration &&
                                        <Assistent width={"60%"}
                                            text={i18n("assistent/guest/hasRegistration", { firstname: this.state.user.firstname })}
                                            actions={
                                                <div />
                                            } />
                                    }

                                    {isGuest &&
                                        <div>
                                            {(activeBookings.length > 0) ? (

                                                <div>
                                                    <TitleCSS>{i18n('my/event', { firstname: this.state.user.firstname })}</TitleCSS>


                                                    <Carousel width={"90%"}>

                                                        {
                                                            activeBookings.map(booking => <BookingBlock key={booking.id}
                                                                booking={booking}
                                                                onChat={this.handleChat.bind(this)} />)
                                                        }

                                                    </Carousel>
                                                </div>
                                            ) : (
                                                    <Assistent width={"60%"}
                                                        text={i18n("assistent/guest/welcome", { firstname: this.state.user.firstname })}
                                                        actions={
                                                            <AssistantActions>
                                                                <StyledFlatButton grey
                                                                    label={i18n("assistent/guest/goToProfile")}
                                                                    onClick={event => {
                                                                        window.location = "/profile"
                                                                    }} />
                                                            </AssistantActions>
                                                        } />

                                                )

                                            }

                                            <TitleCSS>{i18n('my/favorites', { firstname: this.state.user.firstname })}</TitleCSS>
                                            {this.state.favorites.length > 0 ? (
                                                <Carousel width={"90%"}>

                                                    {this.state.favorites.map(favorite => {
                                                        return (<EventBlock favorited
                                                                            id={favorite.id}
                                                                            key={favorite.id}
                                                                            event={favorite.event}
                                                                            active
                                                                            onFavorite={this.handleUnFavorite.bind(this, favorite.event)}/>);
                                                    })}
                                                </Carousel>
                                            ) : (
                                                    <HintCSS>{i18n("my/noFavorites")}</HintCSS>

                                                )}


                                            <TitleCSS>{i18n('my/interested', { firstname: this.state.user.firstname })}</TitleCSS>
                                            {this.state.suggestions.length > 0 ? (
                                                <Carousel width={"90%"}>

                                                    {this.state.suggestions.map(event => {
                                                        return (<EventBlock
                                                            key={event.id} id={event.id}
                                                            event={event}
                                                            active
                                                            onFavorite={this.handleFavorite.bind(this)} />);
                                                    })}
                                                </Carousel>
                                            ) : (
                                                    <HintCSS>{i18n("my/noSuggestions")}</HintCSS>

                                                )}

                                            {(pastBookings.length > 0) &&

                                                <div>
                                                    <TitleCSS>{i18n('my/pastBookings', { firstname: this.state.user.firstname })}</TitleCSS>


                                                    <Carousel width={"90%"}>

                                                        {
                                                            pastBookings.map(booking => <BookingBlock key={booking.id}
                                                                booking={booking}
                                                                onChat={this.handleChat.bind(this)} />)
                                                        }

                                                    </Carousel>
                                                </div>
                                            }


                                            {this.state.events.length > 20 &&
                                                <Categories margin={"5%"} />
                                            }

                                            {this.state.events.length > 20 &&
                                                <EventsWall
                                                    loading={this.state.loading}
                                                    events={this.state.events}
                                                    tweets={this.state.tweets}
                                                    quotes={this.state.quotes}
                                                    news={this.state.news}
                                                    images={this.state.images}
                                                    title={i18n('eventsWall/latest')} />
                                            }
                                        </div>
                                    }
                                </div>)

                        }


                        <TitleCSS ref="more">{i18n("guest/title")}</TitleCSS>
                        <WrapperCSS>

                            <ClaimCSS>{i18n("guest/claim")}</ClaimCSS>

                            <RowCSS>
                                <Image1CSS src={this.state.assetURLs['guest-my/illustration1']} alt=""/>
                                <Text1CSS>
                                    <SmallTitleCSS>{i18n("guest/1/title")}</SmallTitleCSS>
                                    <ParagraphCSS>{i18n("guest/1/p")}</ParagraphCSS>
                                </Text1CSS>
                                <Image2CSS src={this.state.assetURLs['guest-my/illustration2']} alt=""/>
                                <Text2CSS>
                                    <SmallTitleCSS>{i18n("guest/2/title")}</SmallTitleCSS>
                                    <ParagraphCSS>{i18n("guest/2/p")}</ParagraphCSS>
                                </Text2CSS>
                            </RowCSS>
                            <RowCSS>
                                <Image1CSS src={this.state.assetURLs['guest-my/illustration3']} alt=""/>
                                <Text1CSS>
                                    <SmallTitleCSS>{i18n("guest/3/title")}</SmallTitleCSS>
                                    <ParagraphCSS>{i18n("guest/3/p")}</ParagraphCSS>
                                </Text1CSS>
                                <Image2CSS src={this.state.assetURLs['guest-my/illustration4']} alt=""/>
                                <Text2CSS>
                                    <SmallTitleCSS>{i18n("guest/4/title")}</SmallTitleCSS>
                                    <ParagraphCSS>{i18n("guest/4/p")}</ParagraphCSS>
                                </Text2CSS>
                            </RowCSS>
                        </WrapperCSS>

                        <ErrorSnackbar message={this.state.systemError ? this.state.systemError.code : null}
                            open={!isNullOrUndefined(this.state.systemError)} onClose={(event) => {
                                console.log(this.state.systemError);
                                this.setState({ systemError: null })
                            }} />

                        <ChatWindow open={this.state.showChat}
                            name={this.state.chatWithName}
                            onRead={this.handleRead.bind(this)}
                            messages={this.state.messages}
                            reference={this.state.reference}
                            onSend={this.handleSend.bind(this)}
                            onClose={this.handleClose.bind(this)} />

                        <Footer></Footer>
                    </div>
                }
            </div>
        );
    }
}

export default withDebug()(My);
