import React from 'react';
import PropTypes from 'prop-types';
import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import styled from 'styled-components';

const SelectFieldContainer = styled.div`
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: none;
    font-family: TruenoLight;
    font-size: 8.4px;
    letter-spacing: 0;
    width: ${props => props.width ? props.width : "100%"};
    padding-top: 16px;
`;


const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
        secondary: {
            light: '#ff0000',
            main: '#0044ff',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#ffcc00',
        },
        // error: will use the default color
    },
    typography: {
        useNextVariants: true,
    },


    overrides: {
        typography: {
            fontFamily:
                '"TruenoLight"'
        },

        MuiInputLabel: {
            root: {
                textTransform: 'uppercase',
                fontSize: '12px',
                height: 20,
            }
        },
        MuiInput: {
            root: {
                textTransform: 'uppercase',
                fontSize: '14px',
                height: 20,
                paddingTop: 8,
                paddingBottom: 8,
                letterSpacing: 0,
            },
        },
        MuiSelect: {
            root: {
                width: '100%',

            },
            select: {
                textTransform: 'uppercase',
                fontSize: '14px',
                fontFamily: 'TruenoLight',
                color: '#818181'
            },
            icon: {
                color: '#04ADA9',
            }
        },
        MuiFormControl: {
            root: {
                width: '100%',
            }
        },

    }
});


class P23SelectField extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <MuiThemeProvider theme={theme}>

                <SelectFieldContainer width={this.props.width}>
                    <FormControl>
                        <InputLabel>{this.props.label}</InputLabel>
                        <Select
                            native
                            value={this.props.value}
                            onChange={this.props.onChange(this.props.name)}
                        >

                            {this.props.choices.map(choice => <option key={choice.value} value={choice.value} disabled={this.props.disabled}>{choice.label}</option>)}

                        </Select>
                    </FormControl>
                </SelectFieldContainer>
            </MuiThemeProvider>

        );
    }
}

export default (P23SelectField);