import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';
import MUIDataTable from "mui-datatables";
import firebaseApp from "../common/firebase";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";
import {isNullOrUndefined} from "util";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button/Button";
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import RefreshIcon from "@material-ui/icons/Refresh";


const firestore = firebaseApp.firestore();


const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
});

class Localization extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            working: false,
            entries: [],
            showDialog: false,
            editMode: false,
            entry: null,
            key: "",
            de_DE: "",
            en_GB: "",
        };
    }


    componentDidMount() {
        this.reload();
    }

    reload = () => {
        this.setState({loading: true}, async () => {
            try {

                let fsEntries = await firestore.collection("localization").get();

                let entries = [];

                for (const fsEntry of fsEntries.docs) {
                    entries.push({...fsEntry.data(), ref: fsEntry.ref});
                }

                this.setState({entries: entries, loading: false});
            } catch (error) {
                console.error(error);
                this.setState({loading: false});
            }

        })

    };

    handleDelete = (selectedRows) => {

        this.setState({working: true}, async () => {
            try {

                const batch = firestore.batch();

                for (const selectedRow of selectedRows.data) {

                    batch.delete(this.state.entries[selectedRow.dataIndex].ref);
                }

                await batch.commit();

                this.setState({working: false}, () => {
                    this.reload();
                });

            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });

    };

    handleChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    openDialog = (mode) => {

        if (mode) {
            this.setState({
                showDialog: true,
                de_DE: this.state.entry.de_DE,
                en_GB: this.state.entry.en_GB ? this.state.entry.en_GB : "",
                key: this.state.entry.key,
                editMode: true
            })
        } else {
            this.setState({showDialog: true, de_DE: "", en_GB: "", key: "", editMode: false, entry: null})
        }
    };

    closeDialog = () => {
        this.setState({showDialog: false, de_DE: "", en_GB: "", key: "", editMode: false, entry: null})
    };

    handleSave = () => {
        this.setState({working: true}, async () => {

            await firestore.collection("localization").doc(this.state.key.replace(/\//g, '-')).set({
                key: this.state.key,
                de_DE: this.state.de_DE,
                en_GB: this.state.en_GB,
            });

            this.setState({
                working: false,
                showDialog: false,
                de_DE: "",
                en_GB: "",
                key: "",
                editMode: false,
                entry: null
            }, () => {
                this.reload();
            })
        })
    };

    render() {
        const {classes} = this.props;

        const columns = [
            {

                name: "Action",
                field: "action",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Tooltip title={"Edit entry"}>
                                <IconButton onClick={(e) => this.setState({entry: value}, () => this.openDialog(true))}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>

                        );
                    },
                },

            },
            {

                name: "Key",
                field: "key",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {

                name: "Deutsch",
                field: "de_DE",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {

                name: "English",
                field: "en_GB",
                options: {
                    filter: false,
                    sort: true
                }
            },
        ];


        const options = {
            filter: false,
            selectableRows: 'multiple',
            responsive: 'scrollFullHeight',
            customToolbar: () =>
                <Tooltip title={"Reload Data"}>
                    <IconButton className={classes.iconButton}
                                onClick={this.reload}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>,
            customToolbarSelect: (selectedRows) =>
                <StyledCustomToolbarSelect
                    selectedRows={selectedRows}
                    onDelete={this.handleDelete}/>

        };

        const data = this.state.entries.map(entry => {

            const line = [];

            for (const column of columns) {
                let field = entry[column.field];

                if (column.field === "action") {
                    field = entry;
                }

                if (isNullOrUndefined(field)) {
                    field = "-";
                }

                line.push(field);
            }

            return line;
        });
        const keyExistsAlready = this.state.entries.filter(entry => entry.key === this.state.key).length > 0;

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page}) => (
                        <React.Fragment>

                            {this.state.loading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress className={classes.progress} size={50}/>
                                    </Grid>
                                ) :
                                (
                                    <React.Fragment>

                                        <MUIDataTable
                                            columns={columns}
                                            data={data}
                                            title={page.title}
                                            options={options}
                                        />

                                        <Button variant="fab" className={classes.fab} color={"secondary"}
                                                onClick={(e) => this.openDialog(false)}>
                                            <AddIcon/>
                                        </Button>

                                    </React.Fragment>

                                )}


                            <Dialog
                                open={this.state.showDialog}
                                aria-labelledby="form-dialog-title"
                            >

                                {this.state.editMode ? (
                                    <React.Fragment>
                                        <DialogTitle
                                            id="form-dialog-title">Edit {this.state.key} of {page.title}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Edit the localization values. You can't change the key
                                            </DialogContentText>

                                            <TextField
                                                rows={5}
                                                rowsMax={5}
                                                value={this.state.de_DE}
                                                multiline={true}
                                                margin="dense"
                                                id="de_DE"
                                                name="de_DE"
                                                label="Deutsch"
                                                type="text"
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />

                                            <TextField
                                                rows={5}
                                                rowsMax={5}
                                                value={this.state.en_GB}
                                                multiline={true}
                                                margin="dense"
                                                id="en_GB"
                                                name="en_GB"
                                                label="Englisch"
                                                type="text"
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />


                                        </DialogContent>

                                        <DialogActions>
                                            <Button onClick={this.closeDialog} color="primary"
                                                    disabled={this.state.working}>
                                                Close
                                            </Button>
                                            <Button onClick={this.handleSave} color="primary" autoFocus
                                                    disabled={this.state.en_GB.trim().length === 0 || this.state.de_DE.trim().length === 0}>
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <DialogTitle id="form-dialog-title">Add a new value
                                            to {page.title}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                add a new value.
                                            </DialogContentText>
                                            <TextField
                                                value={this.state.key}
                                                autoFocus
                                                margin="dense"
                                                id="key"
                                                name="key"
                                                label="Key"
                                                type="text"
                                                onChange={this.handleChange}
                                                fullWidth
                                                error={keyExistsAlready}
                                                helperText={"Key exists already"}
                                                disabled={this.state.working}
                                            />

                                            <TextField
                                                rows={5}
                                                rowsMax={5}
                                                value={this.state.de_DE}
                                                multiline={true}
                                                margin="dense"
                                                id="de_DE"
                                                name="de_DE"
                                                label="Deutsch"
                                                type="text"
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />

                                            <TextField
                                                rows={5}
                                                rowsMax={5}
                                                value={this.state.en_GB}
                                                multiline={true}
                                                margin="dense"
                                                id="en_GB"
                                                name="en_GB"
                                                label="Englisch"
                                                type="text"
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />


                                        </DialogContent>

                                        <DialogActions>
                                            <Button onClick={this.closeDialog} color="primary"
                                                    disabled={this.state.working}>
                                                Close
                                            </Button>
                                            <Button onClick={this.handleSave} color="primary" autoFocus
                                                    disabled={this.state.en_GB.trim().length === 0 || this.state.de_DE.trim().length === 0 || keyExistsAlready}>
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </React.Fragment>
                                )}

                            </Dialog>


                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

Localization.propTypes = {
    classes: PropTypes.object.isRequired,
};

const defaultToolbarSelectStyles = {
    iconButton: {
        marginRight: "24px",
        top: "50%",
        display: "inline-block",
        position: "relative",
        transform: "translateY(-50%)",
    },
};

class CustomToolbarSelect extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <div className={"custom-toolbar-select"}>
                <Tooltip title={"Delete"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onDelete(this.props.selectedRows)}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

}

const StyledCustomToolbarSelect = withStyles(defaultToolbarSelectStyles, {name: "CustomToolbarSelect"})(CustomToolbarSelect);

export default withStyles(styles, {withTheme: true})(Localization)