import React from 'react';

import styled from 'styled-components';


const Wrapper = styled.div`
width: 100%;
height: ${props => props.height ? props.height : "100%"};
overflow: hidden;
position: relative;
`;

const SelectionsCSS = styled.div`
display: grid;
grid-template-columns: repeat(${props => props.boxes}, ${props => props.boxSize + "px"});
grid-column-gap: 16px;
width: ${props => (props.boxes * props.boxSize) + "px"};
height: 100%;
position: absolute;
left:${props => {
    if (props.selected >= props.scrollAfter) {

        if (props.selected > (props.boxes - props.minVisible)) {
            return ((-(props.boxes - props.minVisible - 1) * (props.boxSize + 16)) + "px")
        } else {
            return ((-(props.selected - props.scrollAfter) * (props.boxSize + 16)) + "px")
        }
    } else {
        return "0px"
    }
}};
transition: left 1s;

`;

(function () {
    Math.clamp = function (a, b, c) {
        return Math.max(b, Math.min(c, a));
    }
})();


const BoxCSS = styled.div`
background-color: ${props => props.active ? "rgba(255,99,68, 0.6)" : "rgba(216,216,216, 1)"};
opacity: ${props => Math.clamp(1 - (props.distance) * 0.2, 0.1, 1)};
border-radius: 8px;
width: ${props => Math.clamp((90 - props.distance * 10), 10, 90) + "%"};
height: ${props => Math.clamp((90 - props.distance * 10), 10, 90) + "%"};
justify-self: center;
align-self: center;
display:flex;
align-items: center; /* Vertical center alignment */
justify-content: center; /* Horizontal center alignment */
transition: width 0.2s, height 0.2s;
&:hover {
box-shadow: 0 2px 2px 0 rgba(0,0,0,0.20);
cursor: pointer;
}
&:active {
box-shadow: none;
cursor: pointer;
}
`;

const TextCSS = styled.div`
font-family: TruenoRegular;
font-size: ${props => ((props.fontBase ? props.fontBase : 24) * Math.clamp((1 - (props.distance) / 10), 0.1, 1)) + "px"};
color: ${props => props.active ? "#FAFAFA" : "#7D7D7D"};
letter-spacing: 1.5px;
transition: color 0.2s, font-size 0.1s;
user-select: none;
`;


class SelectBox extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (



            <BoxCSS active={this.props.active} distance={this.props.distance} onClick={event => {
                this.props.onClick();
            }}>
                <TextCSS fontBase={this.props.fontBase} active={this.props.active} distance={this.props.distance}>{this.props.children}</TextCSS>
            </BoxCSS>



        )
    }
}

class ScrollSelect extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            width: 0,
            height: 0
    }
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    updateDimensions() {

        var w = window,
            d = document,
            documentElement = d.documentElement,
            body = d.getElementsByTagName('body')[0],
            width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
            height = w.innerHeight || documentElement.clientHeight || body.clientHeight;

        this.setState({width: width, height: height});
    }

    UNSAFE_componentWillMount() {
        this.updateDimensions();
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }


    render() {
        return (

            <Wrapper height={this.props.height}>
                <SelectionsCSS boxes={this.props.values.length} scrollAfter={this.props.scrollAfter} minVisible={this.props.minVisible}
                               selected={this.props.selected} boxSize={this.props.boxSize}>
                    {this.props.values.map((value, index) => {

                        return (
                            <SelectBox key={index}
                                       active={index === this.props.selected}
                                       distance={Math.abs(this.props.selected - index)}
                                       onClick={event => this.props.onChange(this.props.name, index)}
                                       fontBase={this.props.fontBase}>{value}</SelectBox>
                        )
                    })}
                </SelectionsCSS>
            </Wrapper>



        )
    }
}

export default (ScrollSelect);