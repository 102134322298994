import React from 'react';

import styled from 'styled-components';



const Wrapper = styled.div`
`;

const ButtonCSS = styled.div`
    width:100%;
background: none;
border-color: #000000;
border-radius: 8px;
border-width: 1px;
border-style: solid;
font-family: TruenoRegular;
font-size: 14px;
color: #000000;
letter-spacing: 1.5px;
text-align: center;
line-height: 50px;
min-height: 50px;
text-transform: uppercase;
user-select: none;
&:hover {
cursor: pointer;
background: rgba(0,0,0, 1);
color: #ffffff;
}
&:active {
box-shadow: none;
cursor: pointer;
color: #ffffff;
background: rgba(0,0,0, 0.8);
}
`;

const ButtonCSSDisabled = styled.div`
    width:100%;
background: #eff0f1;
border-radius: 8px;
font-family: TruenoRegular;
font-size: 12px;
color: #FFFFFF;
letter-spacing: 1.5px;
text-align: center;
line-height: 50px;
min-height: 50px;
text-transform: uppercase;
user-select: none;
`;


class StyledGreyButton extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (

            <div style={{width: '100%'}}>
                {(this.props.disabled) &&

                <ButtonCSSDisabled>
                    {this.props.label}
                </ButtonCSSDisabled>
                }

                {!(this.props.disabled) &&

                <ButtonCSS onClick={event => this.props.onClick ? this.props.onClick(this.props.name, event) : null}>
                    {this.props.label}
                </ButtonCSS>
                }
            </div>



        )
    }
}

export default (StyledGreyButton);