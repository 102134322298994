import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';
import MUIDataTable from "mui-datatables";
import firebaseApp from "../common/firebase";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";
import moment from "moment";
import {isNullOrUndefined} from "util";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Avatar from "@material-ui/core/Avatar/Avatar";
import placeholder from "../assets/placeholder.svg";
import Chip from "@material-ui/core/Chip/Chip";
import ImageIcon from "@material-ui/icons/Image";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Popover from "@material-ui/core/Popover/Popover";
import RefreshIcon from "@material-ui/icons/Refresh";


const firestore = firebaseApp.firestore();


const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
    chip: {
        margin: theme.spacing.unit * 0.5,
    },
    popOverImage: {
        width: "400px",
    },
});

class Drafts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            elements: [],
        };
    }


    UNSAFE_componentWillMount() {
        this.reload();
    }

    reload = () => {
        this.setState({loading: true}, async () => {
            try {


                let fsElements = await firestore.collection("drafts").orderBy("created", "desc").get();

                let elements = [];

                for (const fsElement of fsElements.docs) {


                    let fsProfile = await firestore.collection("profiles").doc(fsElement.data().uid).get();

                    const image = fsProfile.data().image;
                    const name = `${fsProfile.data().firstname} ${fsProfile.data().lastname}`;
                    const email = fsProfile.data().email;
                    const language = fsProfile.data().language;

                    const {created, date, times, ...other} = fsElement.data();
                    const element = {
                        created: created.toDate(),
                        date: date.toDate(),
                        times: times.map(time => {
                            return {
                                start: time.start.toDate(),
                                end: time.end.toDate()
                            }
                        }),
                        ...other,
                        image: image,
                        name: name,
                        email: email,
                        language: language,
                        id: fsElement.id
                    }
                    elements.push(element)
                }

                this.setState({elements: elements, loading: false});
            } catch (error) {
                console.error(error);
                this.setState({loading: false});
            }

        })

    };


    render() {
        const {classes} = this.props;

        const columns = [
            {

                name: "Step",
                field: "step",
                options: {
                    filter: true,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Created",
                field: "created",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {moment(value).format("YYYY-MM-DD, HH:mm:ss")}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Image",
                field: "image",
                options: {
                    display: "false",
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Tooltip title={value.alt}>

                                {value.src ? (
                                    <Avatar
                                        alt={value.alt}
                                        src={value.src}
                                    />
                                ) : (
                                    <Avatar
                                        alt={value.alt}
                                        src={placeholder}
                                    />)}

                            </Tooltip>

                        );
                    },
                }
            },
            {

                name: "About",
                field: "about",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "E-Mail",
                field: "email",
                options: {
                    display: "false",
                    filter: false,
                    sort: true
                }
            },
            {

                name: "Address",
                field: "address",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const postalCode = !isNullOrUndefined(value.postalCode) ? value.postalCode : "-";
                        const street = !isNullOrUndefined(value.street) ? value.street : "-";
                        const city = !isNullOrUndefined(value.city) && !isNullOrUndefined(value.city[value.language]) ? value.city[value.language] : "-";
                        const country = !isNullOrUndefined(value.country) ? value.country : "-";

                        return (
                            <div>
                                {street}<br/>
                                {`${postalCode} ${city}`}<br/>
                                {country}<br/>
                            </div>
                        )

                    },
                }
            },
            {

                name: "Title",
                field: "title",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                }
            },
            {

                name: "Date",
                field: "date",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {moment(value).format("YYYY-MM-DD")}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Description",
                field: "description",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Types",
                field: "types",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (values, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>

                                {values.length === 0 ? (
                                    <div>-</div>
                                ) : (
                                    values.map(value =>
                                        <Chip key={value} label={value} className={classes.chip}
                                              variant="outlined"/>
                                    ))
                                }
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Price",
                field: "price",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const nf = new Intl.NumberFormat('de-DE', {
                            style: 'currency',
                            currency: value.currency,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        });

                        return nf.format(value.amount);

                    },
                }
            },
            {

                name: "Times",
                field: "times",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (values, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>

                                {values.length === 0 ? (
                                    <div>-</div>
                                ) : (
                                    values.map(value =>
                                        <div>{moment(value.start).format("HH:mm")} - {moment(value.end).format("HH:mm")}</div>
                                    )
                                )
                                }
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Seats",
                field: "seats",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return `${value.min} - ${value.max}`;

                    },
                }
            },
            {

                name: "Menu",
                field: "menu",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Ingredients",
                field: "ingredients",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Name",
                field: "name",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {

                name: "Address Hints",
                field: "addressSpecials",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Cuisines",
                field: "cuisines",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (values, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>

                                {values.length === 0 ? (
                                    <div>-</div>
                                ) : (
                                    values.map(value =>
                                        <Chip key={value} label={value} className={classes.chip}
                                              variant="outlined"/>
                                    ))
                                }
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Specials",
                field: "specials",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (values, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>

                                {values.length === 0 ? (
                                    <div>-</div>
                                ) : (
                                    values.map(value =>
                                        <Chip key={value} label={value} className={classes.chip}
                                              variant="outlined"/>
                                    ))
                                }
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Allergens",
                field: "allergens",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (values, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>

                                {values.length === 0 ? (
                                    <div>-</div>
                                ) : (
                                    values.map(value =>
                                        <Chip key={value} label={value} className={classes.chip}
                                              variant="outlined"/>
                                    ))
                                }
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Courtesies",
                field: "courtesies",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (values, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>

                                {values.length === 0 ? (
                                    <div>-</div>
                                ) : (
                                    values.map(value =>
                                        <Chip key={value} label={value} className={classes.chip}
                                              variant="outlined"/>
                                    ))
                                }
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Languages",
                field: "languages",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (values, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>

                                {values.length === 0 ? (
                                    <div>-</div>
                                ) : (
                                    values.map(value =>
                                        <Chip key={value} label={value} className={classes.chip}
                                              variant="outlined"/>
                                    ))
                                }
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Policy",
                field: "policy",
                options: {
                    filter: true,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Time",
                field: "time",
                options: {
                    filter: true,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Images",
                field: "images",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (values, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>

                                {values.length === 0 ? (
                                    <div>-</div>
                                ) : (
                                    values.map((value, index) =>
                                        <StyledImagePopover key={index} src={value}/>
                                    )
                                )
                                }
                            </React.Fragment>

                        );
                    },
                }
            },
        ];


        const options = {
            filter: true,
            filterType: 'dropdown',
            selectableRows: 'none',
            responsive: 'scrollFullHeight',
            customToolbar: () =>
                <Tooltip title={"Reload Data"}>
                    <IconButton className={classes.iconButton}
                                onClick={this.reload}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>,

        };

        const data = this.state.elements.map(element => {

            const line = [];

            for (const column of columns) {

                let field = element[column.field];

                if (column.field === "image") {
                    field = {src: element.image, alt: element.name}
                }
                if (column.field === "title") {
                    field = !isNullOrUndefined(element.title) && !isNullOrUndefined(element.title[element.language]) ?
                        element.title[element.language] : "-";
                }
                if (column.field === "description") {
                    field = !isNullOrUndefined(element.description) && !isNullOrUndefined(element.description[element.language]) ?
                        element.description[element.language] : "-";
                }
                if (column.field === "menu") {
                    field = !isNullOrUndefined(element.menu) && !isNullOrUndefined(element.menu[element.language]) ?
                        element.menu[element.language] : "-";
                }
                if (column.field === "ingredients") {
                    field = !isNullOrUndefined(element.ingredients) && !isNullOrUndefined(element.ingredients[element.language]) ?
                        element.ingredients[element.language] : "-";
                }
                if (column.field === "address") {
                    field = !isNullOrUndefined(element.address) ?
                        {...element.address, language: element.language} : {language: element.language};
                }
                if (column.field === "addressSpecials") {
                    field = !isNullOrUndefined(element.addressSpecials) && !isNullOrUndefined(element.addressSpecials[element.language]) ?
                        element.addressSpecials[element.language] : "-";
                }
                if (column.field === "about") {
                    field = !isNullOrUndefined(element.about) && !isNullOrUndefined(element.about[element.language]) ?
                        element.about[element.language] : "-";
                }

                if (isNullOrUndefined(field)) {
                    field = "-";
                }

                line.push(field);
            }

            return line;
        });

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page}) => (
                        <React.Fragment>

                            {this.state.loading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress className={classes.progress} size={50}/>
                                    </Grid>
                                ) :
                                (
                                    <MUIDataTable
                                        columns={columns}
                                        data={data}
                                        title={page.title}
                                        options={options}
                                    />
                                )}

                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

Drafts.propTypes = {
    classes: PropTypes.object.isRequired,
};

class ImagePopover extends React.Component {
    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    render() {
        const {classes} = this.props;
        const {anchorEl} = this.state;
        const open = Boolean(anchorEl);

        return (
            <React.Fragment>
                <IconButton
                    aria-owns={open ? 'simple-popper' : undefined}
                    aria-haspopup="true"
                    variant="contained"
                    onClick={this.handleClick}
                >
                    <ImageIcon/>
                </IconButton>
                <Popover
                    id="simple-popper"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <img src={this.props.src} className={classes.popOverImage}/>
                </Popover>
            </React.Fragment>
        );
    }
}

ImagePopover.propTypes = {
    classes: PropTypes.object.isRequired,
};

const StyledImagePopover = withStyles(styles)(ImagePopover);


export default withStyles(styles, {withTheme: true})(Drafts)