import React, {Component} from 'react';
import styled from 'styled-components';


const ContentWrapper = styled.div`
display: grid;
grid-template-columns: 10% 40% 40% 10%;
grid-template-rows: 90px 1fr;
grid-row-gap: 15px;
grid-template-areas: 
"header header header header"
"block block block block"
`;

export const HeroWrapper = styled.div`
grid-area: block;
display: flex;
@media screen and (max-width: 800px) {
flex-direction: column;
}
`;

export const HeroBlock = styled.div`
width: 50%;
height: 100%;
z-index: ${props => props.zIndex ? props.zIndex : 2};
margin-bottom: 24px;
@media screen and (max-width: 800px) {
width: 100%;
}
`;

const Wrapper = styled.div`
width: 100%;
height: 100%;
min-height: 600px;
position: relative;
overflow-x: hidden;

@media screen and (min-width: 800px) {
    overflow-y: hidden;
}
`;



const BackgroundContainer = styled.div`
    width: 100%;
    height: 100%;
background-image: linear-gradient(-180deg, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.24) 18%, rgba(0,0,0,0.00) 68%),linear-gradient(-1deg, #000000 0%, rgba(0,0,0,0.01) 18%, rgba(0,0,0,0.00) 33%);
    position: absolute;
    top:0px;
    left:0px;
   background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;

`;

const VideoContainer = styled.video`
      position: absolute;
  top: 50%; 
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background-image: url(${props => props.background});
     background-size: cover;
    background-repeat: no-repeat;
  min-height: 100%; 
  min-width: 100%;
  width: auto; 
  height: auto;
  z-index: -1000; 

`;


export class VideoHero extends Component {

    constructor(props) {
        super(props);


    }

    render() {
        return (
            <Wrapper>
                <BackgroundContainer/>

                <VideoContainer loop autoPlay playsInline muted={true} background={this.props.frame}>
                    <source src={this.props.video} type="video/mp4"/>
                </VideoContainer>

                <ContentWrapper>
                    {this.props.children}
                </ContentWrapper>

            </Wrapper>
        );
    }
}

export default VideoHero;
