import React from 'react';
import '../stylesheets/StyledBlueButton.css';

import styled from 'styled-components';


class StyledBlueButton extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{display: "flex", justifyContent: "center"}}>
                <div onClick={this.props.onClick} className="stripe-connect"><span>{this.props.label}</span></div>
            </div>
        )


    }
}

export default (StyledBlueButton);