import React, {Component} from 'react';
import {initialize} from "../common/configuration";
import {Font, Image, PDFDownloadLink, PDFViewer} from '@react-pdf/renderer';
import {Page, Text, View, Document, StyleSheet} from '@react-pdf/renderer';


import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';


import logo_white from "../assets/logo_white.svg";
import facebook from "../assets/facebook.svg";
import twitter from "../assets/twitter.svg";
import instagram from "../assets/instagram.svg";
import styled from 'styled-components';
import hero1 from "../assets/hero2.jpg";
import Timer from "../components/Timer";
import {i18n, i18nChange, i18nConfiguration, i18nText} from "../common/I18n";
import firebaseApp, {trackUserPresence} from "../common/firebase";
import {isNullOrUndefined} from "util";
import {Redirect} from "react-router";
import Header from "../components/Header";
import SearchInput from "../components/SearchInput";
import Footer from "../components/Footer";
import {CenterCSS} from "../common/Layout";
import quote_top from "../assets/quote_top.svg";
import quote_bottom from "../assets/quote_bottom.svg";
import {List} from "react-content-loader";
import moment from "moment";
import {calcStripeFee} from "../common/utils";
import withDebug from "../common/withDebug";

firebaseApp.auth().useDeviceLanguage();
const firestore = firebaseApp.firestore();


const Wizard = styled.div`

margin-top: 54px;
margin-bottom: 54px;
@media screen and (max-width: 1200px) {
margin-left: 0%;
margin-right: 0%;
}
`;

const QuestionCSS = styled.div`
width: 70%;
text-align: center;
display: grid;
grid-template-columns: 10% 80% 10%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 900px) {
width: 100%;
grid-template-columns: 20% 60% 20%;
}
`;

const ContentCSS = styled.div`
margin-top: 64px;
width: 70%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 900px) {
width: 90%;
}
`;

const TextCSS = styled.div`
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
opacity: 0.8;
text-transform: uppercase;
justify-self: center;
align-self: center;
padding-left: 24px;
padding-right: 24px;
`;

const LeftQuoteCSS = styled.img`
justify-self: end;
align-self: start;
margin-top: -32px;
`;

const RightQuoteCSS = styled.img`
justify-self: start;
align-self: end;
margin-bottom: -32px;
`;


function Question(props) {
    return (
        <QuestionCSS>
            <LeftQuoteCSS src={quote_top}/>
            <TextCSS>{props.children}</TextCSS>
            <RightQuoteCSS src={quote_bottom}/>
        </QuestionCSS>
    )
}

class Receipt extends Component {


    constructor(props) {
        super(props);
        this.state = {
            initialized: false,
            user: null,
            roles: null,
            id: props.match.params.id,
            booking: null

        }

    }

    componentDidMount() {
        initialize().then(() => this.setState({initialized: true}));

        firebaseApp.auth().onAuthStateChanged(async (user) => {
            if (user) {
                try {

                    const profile = await firestore.collection('profiles').doc(user.uid).get();
                    if (!profile.exists) {
                        this.setState({systemError: "noProfile"});
                    } else {
                        this.setState({user: profile.data()}, () => {
                            i18nChange(this.state.user.language);
                            this.load();
                            trackUserPresence();
                        })
                    }
                } catch (error) {
                    this.setState({systemError: error.code});
                }
            }
        });
    }

    load() {

        this.setState({
            loading: true,
            booking: null,
        }, async () => {

            try {


                const fsPayment = await firestore.collection("payments").doc(this.state.id).get();
                if (fsPayment.exists) {

                    let payment = {...fsPayment.data()};
                    payment.id = fsPayment.id;

                    const fsBooking = await firestore.collection("bookings").doc(payment.bookingId).get();
                    payment.booking = fsBooking.data();

                    const fsEvent = await firestore.collection("events").doc(payment.eventId).get();
                    payment.event = fsEvent.data();

                    this.setState({
                        loading: false,
                        payment: payment,
                    });
                } else {
                    window.location = "/notFound";

                }
            } catch (error) {
                this.setState({loading: false, systemError: error});
            }

        });

    }


    render() {

        const isAuth = this.state.user !== null;
        const isGuest = !isNullOrUndefined(this.state.roles) && this.state.roles.guest;


        if (!this.state.initialized) {
            return (<Timer/>);
        }

        if (this.state.loading && isAuth) {
            return (
                <List width={"400px"} style={{
                    display: 'block',
                    marginTop: "64px",
                    marginBottom: "64px",
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}/>)
        }

        const styles = StyleSheet.create({
            body: {
                paddingTop: 35,
                paddingBottom: 65,
                paddingHorizontal: 35,
            },
            bold: {
                fontSize: 14,
                fontWeight: 'bold',
                marginBottom: 3,
            },
            h1: {
                fontSize: 16,
                fontWeight: 'bold',
                marginTop: 16,
                marginBottom: 16,
                textAlign: 'center'
            },
            h2: {
                fontSize: 14,
                fontWeight: 'bold',
                textAlign: 'center',
                marginBottom: 8,

            },
            h3: {
                fontSize: 12,
                fontWeight: 'bold',
                textAlign: 'center'
            },
            normal: {
                fontSize: 12,
                marginBottom: 8,
            },
            normalTab: {
                fontSize: 12,
                marginBottom: 8,
                marginLeft: 32
            },
            small: {
                fontSize: 10,
                marginBottom: 8,
            },
            space: {
                marginTop: 16,
                marginBottom: 16,
            },
            singleRowSmall: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 8,
            },
            singleRowWithLine: {
                borderTopColor: "#000000",
                borderTopStyle: "solid",
                borderTopWidth: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingTop: 16,
                marginBottom: 16,
            },
            box: {
                width: "45%"
            }
        });

        const nf = new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        const vatPercentage = this.state.user.requiresVAT ? this.state.payment.booking.wantsTakeAway ? 7 : 19 : 0;
        const vat =  vatPercentage / (100 + vatPercentage);
        const withoutVat =  100 / (100 + vatPercentage);
        const seats = 1 + this.state.payment.booking.seats;
        const singleNetPrice = this.state.payment.amount / seats * withoutVat;
        const totalNetPrice = this.state.payment.amount * withoutVat;
        const totalPrice = this.state.payment.amount;
        const totalVat = this.state.payment.amount * vat;


        return (

            <React.Fragment>

                {!isAuth &&
                <Timer time={4}>
                    <Redirect push to={`/`}/>
                </Timer>
                }
                {isAuth &&
                <React.Fragment>

                    <Header user={this.state.user}
                            roles={this.state.roles}><SearchInput></SearchInput></Header>

                    <Wizard>
                        <Question>{i18n("receipt/header")}</Question>
                        <ContentCSS>
                            <CenterCSS width={"40%"} marginTop={"40px"}>

                                <PDFDownloadLink document={
                                    <Document>
                                        <Page style={styles.body}>

                                            <Text style={styles.h1}>{i18n("receipt/title")}</Text>
                                            <Text style={styles.h2}>{this.state.payment.event.host.firstname} {this.state.payment.event.host.lastname}</Text>
                                            <Text style={styles.h3}>{this.state.payment.event.address.postalCode} {i18nText(this.state.payment.event.address.city)}</Text>

                                            {!isNullOrUndefined(this.state.user.taxID) && <Text style={styles.h3}>{i18n("receipt/taxId")} {this.state.user.taxID}</Text>}
                                            {!isNullOrUndefined(this.state.user.vatID) && <Text style={styles.h3}>{i18n("receipt/vatId")} {this.state.user.vatID}</Text>}

                                            <View style={styles.space}/>


                                            <View style={styles.singleRowSmall}>
                                                <Text style={styles.normal}>{i18n("receipt/invoiceNumber")}</Text>
                                                <Text style={styles.normal}>{this.state.payment.code}</Text>
                                            </View>
                                            <View style={styles.singleRowSmall}>
                                                <Text style={styles.normal}>{i18n("receipt/invoiceDate")}</Text>
                                                <Text style={styles.normal}>{moment(this.state.payment.event.start.toDate()).format("DD.MM.YYYY")}</Text>
                                            </View>
                                            <View style={styles.space}/>


                                            <View style={styles.singleRowSmall}>
                                                <Text style={styles.normal}>{seats} * {i18nText(this.state.payment.event.title)} </Text>
                                                <Text style={styles.normal}>{nf.format(totalNetPrice)} {i18nConfiguration('currency', this.state.payment.event.price.currency)}</Text>
                                            </View>
                                            <View style={styles.singleRowSmall}>
                                                <Text style={styles.normalTab}>{i18n("receipt/price")} {nf.format(singleNetPrice)} {i18nConfiguration('currency', this.state.payment.event.price.currency)}</Text>
                                            </View>

                                            <View style={styles.singleRowWithLine}>
                                                <Text style={styles.normal}>{i18n("receipt/net")}</Text>
                                                <Text style={styles.normal}>{nf.format(totalNetPrice)} {i18nConfiguration('currency', this.state.payment.event.price.currency)}</Text>
                                            </View>
                                            {this.state.user.requiresVAT &&
                                            <View style={styles.singleRowSmall}>
                                                <Text style={styles.normal}>+ {vatPercentage} % {i18n("receipt/vat")}</Text>
                                                <Text style={styles.normal}>{nf.format(totalVat)} {i18nConfiguration('currency', this.state.payment.event.price.currency)}</Text>
                                            </View>
                                            }
                                            {!this.state.user.requiresVAT &&
                                            <View style={styles.singleRowSmall}>
                                                <Text style={styles.normal}>{i18n("receipt/noVat")}</Text>
                                            </View>
                                            }
                                            <View style={styles.singleRowWithLine}>
                                                <Text style={styles.normal}>{i18n("receipt/sum")}</Text>
                                                <Text style={styles.normal}>{nf.format(totalPrice)} {i18nConfiguration('currency', this.state.payment.event.price.currency)}</Text>
                                            </View>

                                            <Text style={styles.small}>{i18n("receipt/payedWithCC")}</Text>

                                            <View style={styles.singleRowWithLine}>
                                                <Text style={styles.normal}>{i18n("receipt/people")}</Text>
                                            </View>
                                            <View style={styles.space}/>


                                            <View style={styles.singleRowWithLine}>
                                                <Text style={styles.normal}>{i18n("receipt/reason")}</Text>
                                            </View>
                                            <View style={styles.space}/>

                                            <View style={styles.singleRowWithLine}>
                                                <Text style={styles.normal}>{i18n("receipt/signature")}</Text>
                                            </View>
                                            <View style={styles.space}/>


                                        </Page>
                                    </Document>
                                } fileName={`culinarioo_receipt_${this.state.payment.id}.pdf`}>
                                    {({blob, url, loading, error}) => (loading ?
                                        i18n("receipt/downloading")
                                        :
                                        i18n("receipt/download"))}
                                </PDFDownloadLink>
                            </CenterCSS>
                        </ContentCSS>
                    </Wizard>
                </React.Fragment>

                }

                <Footer></Footer>

            </React.Fragment>
        )
    }
}

export default withDebug()(Receipt);
