import React, {Component} from 'react';

import styled from 'styled-components';
import {i18n, i18nConfiguration, i18nRaw, i18nText} from "../common/I18n";

import asset_message from "../assets/message.svg"
import asset_delete from "../assets/delete.svg"
import asset_copy from "../assets/copy.svg"
import asset_more from "../assets/more_lined.svg"
import asset_placeholder from "../assets/placeholder.svg"


import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';

import Moment from 'react-moment';
import 'moment/locale/de';
import moment from "moment/moment";

import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip'


const OuterWrapperCSS = styled.div`
width:100%;
height:600px;
user-select: none;
`;

const WrapperCSS = styled.div`
width:100%;
height:520px;
display: flex;
`;

const TitleBoxCSS = styled.div`
height: 80px;
align-items: center;
`;

const TitleBlackCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
text-transform: uppercase;
`;

const TitleGreenCSS = styled(TitleBlackCSS)`
color: #04ADA9;
`;

const BoxCSS = styled.div`
opacity: 1;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 3px;
width: 97%;
height: 97%;
background: #FFFFFF;
box-shadow: 0 1px 1px 0 rgba(0,0,0,0.50);
border-radius: 8px;
user-select: none;
&:hover {
box-shadow: 0 2px 7px 0 rgba(0,0,0,0.50);
cursor: pointer;
}
&:active {
cursor: pointer;

}
`;

const ImageCSS = styled.div`
   background-image:  url(${props => props.src});
   background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 60%;
`;

const InfoCSS = styled.div`
    border-radius: 0px 0px 8px 8px;
    width: 100%;
    height: 40%;
    position: relative;
`;

const TitleCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 12px;
color: #818181;
letter-spacing: 1.29px;
line-height: 22px;
text-transform: uppercase;
padding-left: 16px;
padding-top: 6px;

`;

const TimeTitleCSS = styled.div`
opacity: 0.6;
font-family: TruenoLight;
font-size: 9px;
color: #818181;
letter-spacing: 0.96px;
line-height: 22px;
text-transform: uppercase;
padding-left: 16px;
`;

const AmountTitleCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 9px;
color: #04ADA9;
letter-spacing: 0.96px;
line-height: 22px;
text-transform: uppercase;
padding-left: 16px;
padding-top: 16px;
`;

const ActionsCSS = styled.div`
position: absolute;
bottom: 8px;
display: flex;
justify-content:space-evenly;
width: 90%;
margin-left: auto;
margin-right: auto;
z-index: 100;
`;

const GuestsCSS = styled.div`
display: flex;
justify-content:flex-start;
width: 90%;
margin-left: auto;
margin-right: auto;
margin-top: 16px;
`;

const ProfileImageCSS = styled.img`
width: 32px;
height: 32px;
object-fit: cover;
border-radius: 100%;
`;

const ProfileCSS = styled.div`
width: 32px;
height: 32px;
margin-right: 16px;
position: relative;
`;

const SeatsCSS = styled.div`
opacity: 0.7;
background: #04ADA9;
color: #ffffff;
right:-8px;
position: absolute;
top:16px;
font-family: TruenoLight;
font-size: 12px;
padding: 4px;
border-radius: 100%;
text-align: left;
`;


const ActionCSS = styled.div`
width:30%;
opacity: 0.6;
display: flex;
justify-content:space-evenly;
&:hover {
opacity: 1;
}
&:active {
opacity: 0.5;
}
z-index: 99;
`;

const IconCSS = styled.img`

`;

const LabelCSS = styled.div`
font-family: TruenoLight;
font-size: 11px;
color: #FF6344;
letter-spacing: -0.16px;
text-transform: uppercase;
padding-left: 8px;
padding-top: 6px;
`;

const styles = theme => ({
    lightTooltip: {
        background: theme.palette.common.white,
        color: theme.palette.text.primary,
        fontFamily: 'TruenoLight',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
});

const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#FF6344',
            dark: '#FF6344',
            // contrastText: will be calculated to contast with palette.primary.main
            contrastText: '#ffffff'
        },

    },

    typography: {
        useNextVariants: true,
    },

    overrides: {

        MuiBadge: {
            badge: {
                fontFamily: "TruenoLight",
                color: '#5C5C5C',

            }
        },

    }


});


class HostDraftBlock extends Component {

    constructor(props) {
        super(props);
    }

    indexOfStep = (_step) => {

        return [
            "WELCOME",
            "TITLE",
            "MENU",
            "TYPES",
            "CUISINES",
            "ASSETS",
            "PRICE",
            "ADDRESS",
            "STRIPE",
            "TIMES",
            "CONFIRM",
        ].findIndex((step) => step === _step);
    };

    isBefore(currentStep, step) {
        return this.indexOfStep(currentStep) < this.indexOfStep(step);
    }

    render() {

        const {classes} = this.props;




        return (


            <MuiThemeProvider theme={theme}>

                <OuterWrapperCSS>
                    <TitleBoxCSS>
                        <TitleBlackCSS>{this.props.draft.title ? i18nText(this.props.draft.title) : i18nRaw("draft/title/default")}</TitleBlackCSS>
                    </TitleBoxCSS>
                    <WrapperCSS>
                        <BoxCSS onClick={(event) => window.location = `/host/my/draft/${this.props.draft.id}`}>
                            {(this.props.draft.images.length > 0) ? (
                                <ImageCSS
                                    src={this.props.draft.images[this.props.draft.mainImageIndex]}/>
                            ) : (
                                <ImageCSS
                                    src={asset_placeholder}/>
                            )}

                            <InfoCSS>
                                <TitleCSS>{this.isBefore(this.props.draft.step, "ADDRESS") ? i18n("host/draft/title/notSet") : i18n("host/draft/title", {
                                    city: i18nText(this.props.draft.address.city),
                                    date: moment(this.props.draft.date.toDate()).format("DD. MMMM YYYY")
                                })}</TitleCSS>

                                <TitleCSS>{this.isBefore(this.props.draft.step, "TIMES") ? i18n("host/draft/date/notSet") : i18n("host/draft/date", {
                                    date: moment(this.props.draft.date.toDate()).format("DD. MMMM YYYY")
                                })}</TitleCSS>

                                {!this.isBefore(this.props.draft.step, "TIMES") && this.props.draft.times.map((time, index) => {
                                    if (index < 3) {
                                        return (
                                            <TimeTitleCSS key={index}>{i18n("host/draft/time", {
                                                start: moment(time.start.toDate()).format("HH:mm"),
                                                end: moment(time.end.toDate()).format("HH:mm")
                                            })}</TimeTitleCSS>
                                        )
                                    }
                                })}


                                <AmountTitleCSS>
                                    {this.isBefore(this.props.draft.step, "PRICE") ? i18n("host/draft/financial/notSet") : i18n("host/draft/financial", {
                                        currency: i18nConfiguration('currency', this.props.draft.price.currency),
                                        price: this.props.draft.price.amount,
                                    })}
                                </AmountTitleCSS>


                                <ActionsCSS>

                                    <Tooltip title={i18n("tooltip/delete")}
                                             classes={{tooltip: classes.lightTooltip}}>
                                        <ActionCSS onClick={(event) => {
                                            event.stopPropagation();
                                            this.props.onDelete(this.props.draft.id);
                                        }}>
                                            <IconCSS src={asset_delete}/>
                                            <LabelCSS>{i18n("host/draft/delete")}</LabelCSS>
                                        </ActionCSS>
                                    </Tooltip>

                                    <Tooltip title={i18n("tooltip/copyDraft")}
                                             classes={{tooltip: classes.lightTooltip}}>
                                        <ActionCSS onClick={(event) => {
                                            event.stopPropagation();
                                            this.props.onClone(this.props.draft);
                                        }}>
                                            <IconCSS src={asset_copy}/>
                                            <LabelCSS>{i18n("host/draft/copy")}</LabelCSS>
                                        </ActionCSS>
                                    </Tooltip>
                                </ActionsCSS>


                            </InfoCSS>

                        </BoxCSS>
                    </WrapperCSS>
                </OuterWrapperCSS>
            </MuiThemeProvider>

        );
    }
}

export default withStyles(styles)(HostDraftBlock);
