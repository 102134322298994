import React from 'react';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import {i18n, i18nCountry, i18nRaw} from "../common/I18n";
import {Redirect} from 'react-router';

import asset_search from "../assets/search.svg"
import styled from 'styled-components';
import firebaseApp from "../common/firebase";
const firestore = firebaseApp.firestore();


const SearchContainerCSS = styled.div`
display: flex;
`;




function renderInput(inputProps) {
    const {classes, ref, ...other} = inputProps;

    return (
        <SearchContainerCSS>
            <img src={asset_search} />
            <TextField
                fullWidth
                InputProps={{
                    inputRef: ref,
                    classes: {
                        input: classes.input,
                    },
                    ...other,
                }}
            />
        </SearchContainerCSS>
    );
}

function renderSuggestion(suggestion, {query, isHighlighted}) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) => {
                    return part.highlight ? (
                        <span key={String(index)} style={{fontWeight: 500, color: "#04ADA9"}}>
              {part.text}
            </span>
                    ) : (
                        <strong key={String(index)} style={{fontWeight: 300}}>
                            {part.text}
                        </strong>
                    );
                })}
            </div>
        </MenuItem>
    );
}

function renderSuggestionsContainer(options) {
    const {containerProps, children} = options;

    return (
        <Paper {...containerProps} square>
            {children}
        </Paper>
    );
}

function getSuggestionValue(suggestion) {
    return suggestion.label;
}

async function getSuggestions(value) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0) {
        return [];
    }

    const fsCities = await firestore.collection("cities").orderBy("search")
            .startAt(inputValue)
            .endAt(inputValue+"~").limit(5).get();

    return fsCities.docs.map(fsCity => fsCity.data());
}

const styles = theme => ({
    container: {
        flexGrow: 1,
        position: 'relative',
        height: 30,
        boxShadow: '0 2px 6px 0 rgba(0,0,0,0.1)',
        borderRadius: 8,
        paddingLeft: 16,
        paddingTop: 3,
        paddingBottom: 8,
        border: 'none',
        letterSpacing: 2

    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        fontFamily: 'TruenoRegular',
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        fontFamily: 'TruenoRegular',
        textTransform: 'uppercase',
        listStyleType: 'none',
    },
    input: {
        opacity: 1,
        paddingLeft: 8,
        fontFamily: 'TruenoRegular',
        fontSize: 14,
        color: '#a9a9a9',
        letterSpacing: 1.5,
        lineHeight: '22px',
        textTransform: 'uppercase'
    }
});

class SearchInput extends React.Component {
    state = {
        value: '',
        suggestions: [],
        redirect: false
    };

    handleSuggestionsFetchRequested = async ({value}) => {
        this.setState({
            suggestions: await getSuggestions(value),
        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    handleChange = (event, {newValue}) => {
        this.setState({
            value: newValue,
        });
    };

    handleSuggestionSelected = (event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {

        if (this.props.onChange) {
            this.props.onChange("DE", suggestionValue);
        } else {
            this.setState({
                value: suggestionValue,
                redirect: true,
            }, () => {this.setState({redirect:false})});
        }

    };

    render() {
        const {classes} = this.props;

        if (this.state.redirect) {
            return (<Redirect push to={`/search/${i18nCountry()}/${this.state.value}`}/>)
        }

        return (

            <div>
                <Autosuggest
                    theme={{
                        container: classes.container,
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                    }}
                    renderInputComponent={renderInput}
                    suggestions={this.state.suggestions}
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                    renderSuggestionsContainer={renderSuggestionsContainer}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    highlightFirstSuggestion={true}
                    onSuggestionSelected={this.handleSuggestionSelected}
                    inputProps={{
                        classes,
                        placeholder: i18nRaw("search/label"),
                        value: this.state.value,
                        onChange: this.handleChange,
                        disableUnderline: true
                    }}
                />
            </div>
        );
    }
}


export default withStyles(styles)(SearchInput);