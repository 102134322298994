import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';
import MUIDataTable from "mui-datatables";
import firebaseApp from "../common/firebase";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";
import moment from "moment";
import {isNullOrUndefined} from "util";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {calcStripeFee, calcVAT} from "../common/utils";


const firestore = firebaseApp.firestore();


const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
});

class Payments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            elements: [],
        };
    }


    UNSAFE_componentWillMount() {
        this.reload();
    }

    reload = () => {
        this.setState({loading: true}, async () => {
            try {


                let fsElements = await firestore.collection("payments").orderBy("created", "desc").get();

                let elements = [];

                for (const fsElement of fsElements.docs) {

                    const {created, ...other} = fsElement.data();
                    const element = {created: created.toDate(), ...other, id: fsElement.id}
                    elements.push(element)
                }

                this.setState({elements: elements, loading: false});
            } catch (error) {
                console.error(error);
                this.setState({loading: false});
            }

        })

    };


    render() {
        const {classes} = this.props;

        const columns = [
            {

                name: "Status",
                field: "status",
                options: {
                    filter: true,
                    sort: true,
                    display: "true",
                }
            },
            {

                name: "Created",
                field: "created",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    download: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {moment(value).format("YYYY-MM-DD, HH:mm:ss")}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Date",
                field: "date",
                options: {
                    filter: false,
                    sort: true,
                    display: "true"
                }
            },
            {

                name: "Year",
                field: "year",
                options: {
                    filter: true,
                    sort: true,
                    display: "false"
                }
            },
            {

                name: "Month",
                field: "month",
                options: {
                    filter: true,
                    sort: true,
                    display: "false"
                }
            },
            {

                name: "Event Id",
                field: "eventId",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Booking Id",
                field: "bookingId",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Booking Code",
                field: "code",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                }
            },
            {

                name: "Guest ID",
                field: "sourceUid",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Customer Number",
                field: "guestNumber",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                }
            },
            {

                name: "Invoice Number",
                field: "number",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                }
            },
            {

                name: "Host ID",
                field: "destinationUid",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Payment ID",
                field: "id",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Amount",
                field: "amount",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Fee",
                field: "fee",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Stripe Fee",
                field: "stripeFee",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Total Amount",
                field: "totalAmount",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {

                name: "Voucher Amount",
                field: "voucherAmount",
                options: {
                    filter: false,
                    sort: true,
                    display: "false"

                }
            },
            {

                name: "Voucher",
                field: "voucher",
                options: {
                    filter: false,
                    sort: true,
                    display: "false"
                }
            },
            {

                name: "VAT",
                field: "vat",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                }
            },
            {

                name: "Net Fee",
                field: "netFee",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                }
            },
            {

                name: "Currency",
                field: "currency",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
        ];


        const options = {
            filter: true,
            filterType: 'dropdown',
            selectableRows: 'none',
            responsive: 'scrollFullHeight',
            customToolbar: () =>
                <Tooltip title={"Reload Data"}>
                    <IconButton className={classes.iconButton}
                                onClick={this.reload}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>
        };

        const data = this.state.elements.map(element => {

            const line = [];

            for (const column of columns) {

                let field = element[column.field];

                if (column.field === "year") {
                    field = moment(element["created"]).format("YYYY");
                }

                if (column.field === "date") {
                    field = moment(element["created"]).format("DD.MM.YYYY");
                }

                if (column.field === "month") {
                    field = moment(element["created"]).format("MM");
                }

                if (column.field === "stripeFee") {
                    field = parseFloat(calcStripeFee(element["totalAmount"])).toFixed(2);
                }

                if (column.field === "vat") {
                    field = parseFloat(calcVAT(element["fee"] - calcStripeFee(element["totalAmount"]))).toFixed(2);
                }

                if (column.field === "netFee") {
                    field = parseFloat(element["fee"] - calcVAT(element["fee"] - calcStripeFee(element["totalAmount"])) - calcStripeFee(element["totalAmount"])).toFixed(2);
                }

                if (column.field === "totalAmount") {
                    field = parseFloat(element["totalAmount"]).toFixed(2);
                }

                if (column.field === "amount") {
                    field = parseFloat(element["amount"]).toFixed(2);
                }

                if (column.field === "fee") {
                    field = parseFloat(element["fee"]).toFixed(2);
                }

                if (column.field === "voucherAmount") {
                    field = parseFloat(element["voucherAmount"]).toFixed(2);
                }


                if (isNullOrUndefined(field)) {
                    field = "-";
                }

                line.push(field);
            }

            return line;
        });

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page}) => (
                        <React.Fragment>

                            {this.state.loading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress className={classes.progress} size={50}/>
                                    </Grid>
                                ) :
                                (
                                    <MUIDataTable
                                        columns={columns}
                                        data={data}
                                        title={page.title}
                                        options={options}
                                    />
                                )}

                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

Payments.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles, {withTheme: true})(Payments)