import React, {Component} from 'react';
import {i18n, i18nConfiguration, i18nText} from "../common/I18n";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import P23TextField from "./P23TextField";
import StyledDialog from "./StyledDialog";
import * as firebase from "firebase";
import P23SelectField from "./P23SelectField";
import {masterdata} from "../common/configuration";
import firebaseApp from "../common/firebase";

const firestore = firebaseApp.firestore();


class ReportDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            working: false,
            reason: "none",
            report: "",
            reportSent: false,
        }


    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    handleCancel(key) {

        this.setState({
            error: null,
            working: false,
            reason: "none",
            report: "",
            reportSent: false,
        }, () => {
            this.props.onClose();
        })
    };

    handleOk(key) {

        this.setState({
            error: null,
            working: false,
            reason: "none",
            report: "",
            reportSent: false,
        }, () => {
            this.props.onClose();
        })
    };

    handleSend(key, subject) {

        this.setState({
            working: true,
        }, async () => {

            try {

                await firestore.collection("reports").add(
                    {
                        uid: firebaseApp.auth().currentUser.uid,
                        reason: this.state.reason,
                        report: this.state.report,
                        kind: this.props.kind,
                        subject: this.props.subject,
                        created: new Date(),
                    }
                );

                this.setState({
                    error: null,
                    working: false,
                    reason: "none",
                    report: "",
                    reportSent: true,
                })
            } catch (error) {
                console.log(error);
                this.setState({working:false, error:error.code})
            }
        });
    };

    handleSelectChange = name => event => {
        this.setState({[name]: event.target.value});
    };

    render() {

        return (
            <StyledDialog
                open={this.props.open}
                title={i18n(`report/${this.props.kind}/title`)}
                text={this.state.reportSent ? i18n("report/thanks") : i18n("report/text")}
                working={this.state.working}
                name={"report"}
                actions={
                    (this.state.reportSent) ? (
                        <DialogActions>
                            <Button onClick={event => this.handleOk("ok")} color="primary">
                                {i18n("report/ok")}
                            </Button>
                        </DialogActions>
                    ) : (
                        <DialogActions>
                            <Button onClick={event => this.handleCancel("cancel")} color="primary">
                                {i18n("report/cancel")}
                            </Button>
                            <Button onClick={event => this.handleSend("send", this.props.subject)} color="primary"
                                    disabled={this.state.report === null || this.state.report.trim().length < 1 || this.state.reason === null || this.state.reason === "none"}>
                                {i18n("report/send")}
                            </Button>
                        </DialogActions>
                    )
                }
                control={
                    (!this.state.reportSent) ? (
                        <div>
                            <P23SelectField
                                name={"reason"}
                                label={i18n("report/reason")}
                                choices={masterdata.reportReasons.map((choice) => ({
                                    value: choice,
                                    label: i18nConfiguration("reportReason", choice)
                                }))}
                                value={this.state.reason}
                                onChange={this.handleSelectChange.bind(this)}
                            />
                            <P23TextField
                                autoFocus={false}
                                name="report"
                                width={"100%"}
                                label={i18n("report/label")}
                                value={this.state.report}
                                onChange={this.handleChange.bind(this)}
                                error={this.state.error}
                                multiline={true}
                                inputProps={{
                                    disableUnderline: true,
                                }}
                                rows={10}
                            />
                        </div>
                    ) : (<div/>)


                }
            />
        )
    }
}

export default ReportDialog;

