import React, {Component} from 'react';
import {initialize} from "../common/configuration";

import styled from 'styled-components';
import Header from "../components/Header";
import {i18n, i18nChange, i18nConfiguration, i18nText} from "../common/I18n";
import Carousel from "../components/Carousel";
import HostEventBlock from "../components/HostEventBlock";
import Footer from "../components/Footer";
import firebaseApp, {trackUserPresence} from "../common/firebase";
import Cover from "../components/Cover";
import EditableBookingOverview from "../components/EditableBookingOverview";
import GuestList from "../components/GuestList";
import EditableDetails from "../components/EditableDetails";
import Gallery from "../components/Gallery";
import Timer from "../components/Timer";
import {Redirect} from "react-router";
import {List} from "react-content-loader";
import {TelegramShareButton} from "react-share";
import {masterdata} from "../common/configuration";
import StyledDialog from "../components/StyledDialog";
import moment from "moment";
import ChatWindow from "../components/ChatWindow";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import P23TextField from "../components/P23TextField";
import {isNullOrUndefined} from "util";
import withDebug from "../common/withDebug";


const firestore = firebaseApp.firestore();


class HostMyEvent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.match.params.id,
            loading: false,
            user: null,
            event: null,
            bookings: null,
            openCancelDialog: false,
            showChat: false,
            chatWithName: null,
            reference: null,
            messages: [],
            count: {},
            allMessages: {},
            working: false,
            showMessageAll: false,
            message: "",
            messageAllSent: false,
            initialized: false

        };
    }


    UNSAFE_componentWillMount() {

        initialize().then(() => this.setState({initialized: true}));


        firebaseApp.auth().onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const fsProfile = await firestore.collection('profiles').doc(user.uid).get();
                    const fsRoles = await firestore.collection('roles').doc(user.uid).get();

                    if (!fsProfile.exists || !fsRoles.exists) {
                        this.setState({systemError: "noProfile"});
                    } else {
                        this.setState({user: fsProfile.data(), roles: fsRoles.data()}, () => {
                            i18nChange(this.state.user.language);
                            this.load(true);
                            this.listenToChat();
                            trackUserPresence();

                        })

                    }
                } catch (error) {
                    this.setState({systemError: error});
                }


            }
        });

    }

    listenToChat() {
        firestore.collection('messages').where("eventId", "==", this.state.id).where("hostUid", "==", this.state.user.uid).where("readByHost", "==", false).onSnapshot(
            fsMessages => {
                const count = {};
                for (const fsMessage of fsMessages.docs) {
                    count[fsMessage.data().guestUid] = count[fsMessage.data().guestUid] ? count[fsMessage.guestUid] + 1 : 1;
                }

                this.setState({count: count});
            }
        );

        firestore.collection('messages').where("eventId", "==", this.state.id).where("hostUid", "==", this.state.user.uid).onSnapshot(
            fsMessages => {
                const messages = {};
                for (const fsMessage of fsMessages.docs) {

                    if (isNullOrUndefined(messages[fsMessage.data().guestUid])) {
                        messages[fsMessage.data().guestUid] = [];
                    }
                    ;

                    messages[fsMessage.data().guestUid].push(fsMessage.data());
                }

                this.setState({allMessages: messages});
            }
        );
    }

    load(loading) {

        this.setState({loading: loading}, async () => {
            try {

                const fsEvent = await firestore.collection('events').doc(this.state.id).get();

                if (fsEvent.exists) {

                    const fsBookings = await firestore.collection('bookings').where("eventId", "==", fsEvent.id).get();
                    let bookings = [];
                    for (let fsBooking of fsBookings.docs) {
                        bookings.push(fsBooking.data())
                    }

                    bookings = bookings.filter(booking => booking.status === "ok" || booking.status === "confirmed" || booking.status === "paymentScheduled");
                    this.setState({event: fsEvent.data(), bookings: bookings, loading: false});

                    if (loading) {
                        this.listen(this.state.id)
                    }
                } else {
                    this.setState({systemError: "notFound", loading: false});


                }
            } catch (error) {
                this.setState({systemError: error, loading: false});

            }
        })
    }

    listen = (id) => {
        firestore.collection('events').doc(id)
            .onSnapshot(
                snapshot => {
                    this.load(false);
                }
                , snapShotError => {
                    this.setState({systemError: snapShotError.code});
                });
    };

    handleCloseCancelDialog = (name) => {

        this.setState({openCancelDialog: false});

    };

    handleConfirmedCancelDialog = (name) => {

        this.setState({openCancelDialog: false, loading: true}, async () => {

            try {

                await firestore.collection('cancelEventRequests').add(
                    {
                        uid: firebaseApp.auth().currentUser.uid,
                        event: this.state.id,
                        status: "new",
                        error: null,
                        reason: null,
                        created: new Date(),
                        updated: null
                    }
                );

                this.setState({loading: false})


            } catch (error) {
                this.setState({loading: false, systemError: error.code})
            }

        });

    };

    handleCancel = () => {
        this.setState({openCancelDialog: true});
    };

    handleChat = (reference) => {

        this.setState({
            showChat: true,
            chatWithName: reference.firstname,
            reference: reference,
        }, () => {
            firestore.collection('messages').where("eventId", "==", this.state.id).where("hostUid", "==", this.state.user.uid).where("guestUid", "==", reference.uid)
                .onSnapshot(
                    fsMessages => {

                        const messages = [];
                        for (const fsMessage of fsMessages.docs) {

                            const message = {
                                text: fsMessage.data().text,
                                name: fsMessage.data().isGuest ? fsMessage.data().guest.name : fsMessage.data().host.name,
                                image: fsMessage.data().isGuest ? fsMessage.data().guest.image : fsMessage.data().host.image,
                                other: fsMessage.data().isGuest,
                                readByGuest: fsMessage.data().readByGuest,
                                readByHost: fsMessage.data().readByHost,
                                timestamp: fsMessage.data().created.toDate(),
                                ref: fsMessage.ref,
                            };

                            messages.push(message);
                        }

                        messages.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());

                        this.setState({messages: messages});

                    }
                    , snapShotError => {
                        this.setState({systemError: snapShotError.code});
                    });
        });
    };

    handleCloseChat = (reference) => {
        this.setState({showChat: false, chatWithName: null, messages: [], reference: null, message: ""}, () => {

            firestore.collection('messages').where("eventId", "==", this.state.id).where("hostUid", "==", this.state.user.uid).where("guestUid", "==", reference.uid)
                .onSnapshot(() => {
                })
        });
    };

    handleSend = async (reference, text) => {
        await firestore.collection("messages").add(
            {
                host: {
                    name: this.state.user.firstname,
                    image: this.state.user.image,
                },
                hostUid: this.state.user.uid,
                guest: {
                    name: reference.firstname,
                    image: reference.image,
                },
                guestUid: reference.uid,
                readByHost: true,
                readByGuest: false,
                isGuest: false,
                text: text,
                eventId: this.state.id,
                created: new Date()
            });
    };

    handleRead = async (messages) => {
        for (const message of messages) {
            if (!message.readByHost) {
                await message.ref.set(
                    {
                        readByHost: true,
                    },
                    {merge: true}
                )
            }
        }
    };

    handleMessageAllCancel(key) {

        this.setState({
            working: false,
            showMessageAll: false,
            message: "",
            messageAllSent: false,
        })
    };

    handleMessageAllOk(key) {

        this.setState({
            working: false,
            showMessageAll: false,
            message: "",
            messageAllSent: false,
        })
    };

    handleMessageAllSend(key) {

        this.setState({
            working: true,
        }, async () => {
            try {

                const bookings = this.state.bookings.filter((booking) => booking.status === "ok");

                for (const booking of bookings) {
                    await firestore.collection("messages").add(
                        {
                            host: {
                                name: this.state.user.firstname,
                                image: this.state.user.image,
                            },
                            hostUid: this.state.user.uid,
                            guest: {
                                name: booking.guest.firstname,
                                image: booking.guest.image,
                            },
                            guestUid: booking.uid,
                            readByHost: true,
                            readByGuest: false,
                            isGuest: false,
                            text: this.state.message,
                            eventId: booking.eventId,
                            created: new Date()
                        });

                }

                this.setState({working: false, messageAllSent: true})


            } catch (error) {
                this.setState({error: error, working: false})
            }
        })
    };

    handleMessageAll(key) {

        this.setState({
            working: false,
            showMessageAll: true,
            message: "",
            messageAllSent: false,
        })
    };

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    render() {

        if (!this.state.initialized) {
            return (<Timer/>);
        }

        const isAuth = this.state.user !== null;
        const isHost = !isNullOrUndefined(this.state.roles) && this.state.roles.host === true;
        const isGuest = !isNullOrUndefined(this.state.roles) && this.state.roles.guest === true;

        const nf = new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return (
            <div>

                {!isAuth &&
                <Timer time={4}>
                    <Redirect push to={`/host`}/>
                </Timer>
                }


                {(isAuth && this.state.loading) &&

                <List width={"100%"} style={{
                    display: 'block',
                    marginTop: "64px",
                    marginBottom: "64px",
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}/>
                }

                {(isAuth && this.state.event !== null && !this.state.loading && isHost && this.state.event.uid === this.state.user.uid) &&
                <div>

                    <Header home={"/host/my"} user={this.state.user} roles={this.state.roles}></Header>

                    <Cover images={this.state.event.images.map((image, index) => {
                        return image;
                    })} highlight={1}/>

                    <EditableBookingOverview
                        image={this.state.event.host.image}
                        address={{
                            street: this.state.event.address.street,
                            postalCode: this.state.event.address.postalCode,
                            city: i18nText(this.state.event.address.city),
                            country: this.state.event.address.country

                        }}
                        addressSpecials={this.state.event.addressSpecials}
                        date={this.state.event.date.toDate()}
                        times={[[this.state.event.start.toDate(), this.state.event.end.toDate()]]}
                        specials={this.state.event.specials}
                        types={this.state.event.types}
                        people={this.state.event.seats}
                        cuisines={this.state.event.cuisines}
                        languages={this.state.event.languages}
                        allergens={this.state.event.allergens}
                        courtesies={this.state.event.courtesies}
                        amount={nf.format(this.state.event.price.amount)}
                        fee={0}
                        currentSeats={this.state.event.currentSeats}
                        totalAmount={nf.format(this.state.event.currentSeats * this.state.event.price.amount)}
                        earnings={nf.format(this.state.event.currentSeats * this.state.event.price.amount)}
                        currency={this.state.event.price.currency}
                        time={this.state.event.time}
                        cancelable={moment(this.state.event.cancelUntil.toDate()).isAfter(moment()) && this.state.event.status === "running"}
                        status={this.state.event.status}
                        end={this.state.event.end.toDate()}
                        editMode={false}
                        bookingMode={false}
                        onCancel={this.handleCancel.bind(this)}
                        onMessageAll={this.handleMessageAll.bind(this)}
                        marginTop={"450px"}
                    />

                    <GuestList
                        bookings={this.state.bookings}
                        allMessages={this.state.allMessages}
                        seats={this.state.event.currentSeats}
                        count={this.state.count}
                        onChat={this.handleChat.bind(this)}
                    />

                    <EditableDetails
                        title={i18nText(this.state.event.title)}
                        url={`https://${window.location.hostname}/api/shared/event/${this.state.id}`}
                        description={i18nText(this.state.event.description)}
                        menu={i18nText(this.state.event.menu)}
                        time={this.state.event.time}
                        tag={this.state.event.tag}
                        story={i18nText(this.state.event.host.about)}
                        firstname={this.state.event.host.firstname}
                        policy={this.state.event.policy}
                        hostImage={this.state.event.host.image}
                        ingredients={i18nText(this.state.event.ingredients)}
                        editMode={false}/>

                    <Gallery images={this.state.event.images}/>

                    <StyledDialog
                        name={"cancel"}
                        open={this.state.openCancelDialog}
                        onCancel={this.handleCloseCancelDialog.bind(this)}
                        onSave={this.handleConfirmedCancelDialog.bind(this)}
                        title={i18n("cancel/host/dialog/title")}
                        text={i18n("cancel/host/dialog/text")}
                        working={this.state.working}
                        okLabel={i18n("cancel/host/dialog/ok")}/>

                    <ChatWindow open={this.state.showChat}
                                name={this.state.chatWithName}
                                onRead={this.handleRead.bind(this)}
                                messages={this.state.messages}
                                reference={this.state.reference}
                                onSend={this.handleSend.bind(this)}
                                onClose={this.handleCloseChat.bind(this)}/>

                    <StyledDialog
                        open={this.state.showMessageAll}
                        title={i18n("messageAll/title")}
                        text={
                            this.state.messageAllSent ?
                                i18n("messageAll/sent") :
                                i18n("messageAll/text",
                                    {
                                        title: this.state.event ? i18nText(this.state.event.title) : null,
                                        city: this.state.event ? i18nText(this.state.event.address.city) : null,
                                        date: this.state.event ? moment(this.state.event.start.toDate()).format("DD. MMM") : null,
                                        time: this.state.event ? moment(this.state.event.start.toDate()).format("HH:mm") : null,
                                    })}
                        working={this.state.working}
                        name={"messageAll"}
                        actions={
                            (this.state.messageAllSent) ? (
                                <DialogActions>
                                    <Button onClick={event => this.handleMessageAllOk("ok")} color="primary">
                                        {i18n("messageAll/ok")}
                                    </Button>
                                </DialogActions>
                            ) : (
                                <DialogActions>
                                    <Button onClick={event => this.handleMessageAllCancel("cancel")} color="primary">
                                        {i18n("messageAll/cancel")}
                                    </Button>
                                    <Button onClick={event => this.handleMessageAllSend("save")} color="primary"
                                            disabled={this.state.message === null || this.state.message.trim().length === 0}>
                                        {i18n("messageAll/send")}
                                    </Button>
                                </DialogActions>
                            )
                        }
                        control={
                            (!this.state.messageAllSent) ? (
                                <P23TextField
                                    autoFocus={false}
                                    name="message"
                                    width={"100%"}
                                    label={i18n("messageAll/label")}
                                    value={this.state.message}
                                    onChange={this.handleChange.bind(this)}
                                    error={this.state.error}
                                    multiline={true}
                                    inputProps={{
                                        disableUnderline: true,
                                    }}
                                    rows={10}
                                />
                            ) : (<div/>)


                        }
                    />

                    <Footer></Footer>
                </div>
                }

                {(isAuth && this.state.event !== null && this.state.event.uid !== this.state.user.uid) &&
                <Redirect push to={`/my/event/${this.state.id}`}/>
                }


            </div>
        );
    }
}

export default withDebug()(HostMyEvent);
