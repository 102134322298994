import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';
import MUIDataTable from "mui-datatables";
import firebaseApp from "../common/firebase";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";
import moment from "moment";
import {isNullOrUndefined} from "util";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Avatar from "@material-ui/core/Avatar/Avatar";
import placeholder from "../assets/placeholder.svg";
import {Link} from "react-router-dom";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton/IconButton";


const firestore = firebaseApp.firestore();


const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
});

class Bookings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            elements: [],
        };
    }


    UNSAFE_componentWillMount() {
        this.reload();
    }

    reload = () => {
        this.setState({loading: true}, async () => {
            try {


                let fsElements = await firestore.collection("bookings").orderBy("created", "desc").get();

                let elements = [];

                for (const fsElement of fsElements.docs) {

                    const {created, ...other} = fsElement.data();
                    const element = {created: created.toDate(), ...other, id: fsElement.id}
                    elements.push(element)
                }

                this.setState({elements: elements, loading: false});
            } catch (error) {
                console.error(error);
                this.setState({loading: false});
            }

        })

    };


    render() {
        const {classes} = this.props;

        const columns = [
            {

                name: "Status",
                field: "status",
                options: {
                    filter: true,
                    sort: true,
                    display: "true",
                }
            },
            {

                name: "Event Id",
                field: "eventId",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Image",
                field: "image",
                options: {
                    display: "false",
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Tooltip title={value.alt}>

                                {value.src ? (
                                    <Avatar
                                        alt={value.alt}
                                        src={value.src}
                                    />
                                ) : (
                                    <Avatar
                                        alt={value.alt}
                                        src={placeholder}
                                    />)}

                            </Tooltip>

                        );
                    },
                }
            },
            {

                name: "Created",
                field: "created",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {moment(value).format("YYYY-MM-DD, HH:mm:ss")}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Name",
                field: "name",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                }
            },
            {

                name: "Customer Number",
                field: "number",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                }
            },
            {

                name: "Extra Seats",
                field: "seats",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                }
            },
            {

                name: "Voucher",
                field: "promoCode",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                }
            },
            {

                name: "Link",
                field: "link",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Link to={value} target={"__new"}>{value}</Link>

                        );
                    },
                }
            },
            {

                name: "Code",
                field: "code",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Uid",
                field: "uid",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Id",
                field: "id",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
        ];


        const options = {
            filter: true,
            filterType: 'dropdown',
            selectableRows: 'none',
            responsive: 'scrollFullHeight',
            customToolbar: () =>
                <Tooltip title={"Reload Data"}>
                    <IconButton className={classes.iconButton}
                                onClick={this.reload}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>
        };

        const data = this.state.elements.map(element => {

            const line = [];

            for (const column of columns) {

                let field = element[column.field];

                if (column.field === "name") {
                    field = element.guest.firstname;
                }

                if (column.field === "number") {
                    field = element.guest.number;
                }

                if (column.field === "image") {
                    field = {src: element.guest.image, alt: `${element.guest.firstname}`}
                }

                if (column.field === "link") {
                    field = `/event/${element.eventId}`
                }

                if (isNullOrUndefined(field)) {
                    field = "-";
                }

                line.push(field);
            }

            return line;
        });

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page}) => (
                        <React.Fragment>

                            {this.state.loading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress className={classes.progress} size={50}/>
                                    </Grid>
                                ) :
                                (
                                    <MUIDataTable
                                        columns={columns}
                                        data={data}
                                        title={page.title}
                                        options={options}
                                    />
                                )}

                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

Bookings.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles, {withTheme: true})(Bookings)