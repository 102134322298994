import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import asset_hint from "../assets/hint.svg";
import {isNullOrUndefined} from "util";

const styles = theme => ({
    lightTooltip: {
        background: "#04ADA9",
        color: "#ffffff",
        fontSize: 12,
        fontFamily: "TruenoLight",
        padding: 16,
        borderRadius: 5,
        letterSpacing: 0,
        lineHeight: "26px",
        maxWidth: "300px",
        userSelect: "none"
    },
});

class StyledTooltip extends React.Component {
    state = {
        arrowRef: null,
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node,
        });
    };

    render() {
        const {classes} = this.props;

        if (!isNullOrUndefined(this.props.text)) {
            return (
                <Tooltip title={this.props.text} position={"left"}
                    classes={{ tooltip: classes.lightTooltip }} enterTouchDelay={0} disableTouchListener={true}>
                    <img src={asset_hint} style={{display: "block", marginLeft: "auto", marginRight: "auto", userSelect: "none"}}/>
                </Tooltip>)

        } else {
            return <div/>
        }
    }
}


export default withStyles(styles)(StyledTooltip);