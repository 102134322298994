import React, {Component} from 'react';
import styled from 'styled-components';
import share from './../assets/share.svg';

import {i18nText, i18n, i18nConfiguration, i18nRaw} from "../common/I18n";

import ReactMarkdown from 'react-markdown';
import IconShare from "@material-ui/icons/Share";
import IconButton from "@material-ui/core/IconButton";
import IconFavorite from "@material-ui/icons/FavoriteBorder";
import IconUnfavorite from "@material-ui/icons/Favorite";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    PinterestShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
    EmailShareButton,
    TumblrShareButton,

    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    PinterestIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
    TumblrIcon,
    EmailIcon,
} from 'react-share';
import {isNullOrUndefined} from "util";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";

const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#FF6344',
            dark: '#FF6344',
            // contrastText: will be calculated to contast with palette.primary.main
        }
    }
})

const Wrapper = styled.div`
    padding-left: 10.00%;
    padding-right: 33%;
    @media screen and (max-width: 1100px) {
    padding-right: 10%;

}
    user-select: none;
`;

const TitleLine = styled.div`
    width: 100%;
    display: flex;
`;

const Title = styled.div`
    width: fit-content;
    padding-top: 24px;
    opacity: 0.8;
    font-family: TruenoBold;
    font-size: 22px;
    color: #333333;
    letter-spacing: -0.16px;
    line-height: 28px;
    text-transform: uppercase;
`;

const Share = styled.div`
    padding-left: 16px;
    padding-top: 16px;
    width: 24px;
    height: 24px;
`;

const Favorite = styled.div`
    padding-left: 16px;
    padding-top: 16px;
    width: 24px;
    height: 24px;
`;

const Special = styled.div`
    width: fit-content;
    background-image: linear-gradient(90deg, #D7F2F2 62%, rgba(4, 173, 169, 0.00) 91%);
    /* Special Week: */
    font-family: TruenoRegular;
    font-size: 14px;
    color: #04ADA9;
    letter-spacing: 1.5px;
    text-align: center;
    line-height: 22px;
    text-transform: uppercase;
    border-radius: 0px 0px 0px 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 16px;
    padding-right: 16px;
`;

const Description = styled.div`
    padding-top: 6px;
    opacity: 0.8;
    font-family: TruenoLight;
    font-size: 18px;
    color: #818181;
    letter-spacing: 0;
    line-height: 28px;
`;


const SmallHeader = styled.div`
    width: 100%;
    padding-top: 32px;
    opacity: 0.8;
    font-family: TruenoRegular;
    font-size: 16px;
    color: #04ADA9;
    letter-spacing: 0px;
    line-height: 22px;
    text-transform: uppercase;
    clear: left;
`;

const MenuCSS = styled.div`
    padding-top: 32px;
    opacity: 0.8;
    font-family: TruenoLight;
    font-size: 16px;
    color: #818181;
    letter-spacing: 0;
    line-height: 28px;
`;


const About = styled.div`
    padding-top: 32px;
    opacity: 0.8;
    font-family: TruenoLight;
    font-size: 16px;
    color: #818181;
    letter-spacing: 0;
    line-height: 28px;
`;


const Ingredients = styled.div`
    padding-top: 32px;
    opacity: 0.8;
    font-family: TruenoLight;
    font-size: 16px;
    color: #818181;
    letter-spacing: 0;
    line-height: 28px;
    clear: left;
`;

const GreenHeadline = styled.div`
    opacity: 0.8;
    font-family: TruenoRegular;
    font-size: 16px;
    color: #04ADA9;
    letter-spacing: 1.5px;
    line-height: 22px;
    text-transform: uppercase;
    padding-bottom: 16px;
`;
const Cancellation = styled.div`
    padding-top: 32px;
    opacity: 0.8;
    font-family: TruenoLight;
    font-size: 16px;
    color: #818181;
    letter-spacing: 0;
    line-height: 28px;
`;

const Host = styled.img`
    border-radius: 100%;
    width: 68px;
    height: 68px;
    float: left;
    shape-outside: ellipse();
    margin-right: 16px;
    object-fit: cover;
`;


class Details extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorShareEl: null,
        }
    }

    handleShare = event => {
        this.setState({anchorShareEl: event.currentTarget});
    };

    handleClose = event => {
        this.setState({anchorShareEl: null});
    };

    render() {

        return (
            <Wrapper>
                <TitleLine>
                    <Title>
                        {i18nText(this.props.event.title)}
                    </Title>
                    <MuiThemeProvider theme={theme}>


                        <Share>
                            <IconButton
                                aria-haspopup="true"
                                color="primary"
                                onClick={this.handleShare}
                            >
                                <IconShare/>
                            </IconButton>
                        </Share>

                        {this.props.isAuth &&

                        <Favorite>


                            {!isNullOrUndefined(this.props.favorite) ? (
                                <IconButton
                                    aria-haspopup="true"
                                    color="primary"
                                    onClick={(e) => {
                                        this.props.onUnFavorite(this.props.event.id)
                                    }}
                                >
                                    <IconUnfavorite/>
                                </IconButton>
                            ) : (
                                <IconButton
                                    aria-haspopup="true"
                                    color="primary"
                                    onClick={(e) => this.props.onFavorite(this.props.event.id)}
                                >
                                    <IconFavorite/>
                                </IconButton>
                            )}


                        </Favorite>
                        }
                    </MuiThemeProvider>


                    <Menu
                        id="share-menu"
                        anchorEl={this.state.anchorShareEl}
                        open={Boolean(this.state.anchorShareEl)}
                        onClose={this.handleClose}
                    >
                        <MenuItem>
                            <FacebookShareButton
                                url={`https://${window.location.hostname}/api/shared/event/${this.props.event.id}`}
                                quote={i18nText(this.props.event.title)}>
                                <FacebookIcon
                                    size={32}
                                    round/>
                            </FacebookShareButton>
                        </MenuItem>

                        <MenuItem>
                            <TwitterShareButton
                                url={`https://${window.location.hostname}/api/shared/event/${this.props.event.id}`}
                                title={i18nText(this.props.event.title)}>
                                <TwitterIcon
                                    size={32}
                                    round/>
                            </TwitterShareButton>
                        </MenuItem>

                        <MenuItem>
                            <TelegramShareButton
                                url={`https://${window.location.hostname}/api/shared/event/${this.props.event.id}`}
                                title={i18nText(this.props.event.title)}>
                                <TelegramIcon size={32} round/>
                            </TelegramShareButton>
                        </MenuItem>

                        <MenuItem>
                            <WhatsappShareButton
                                url={`https://${window.location.hostname}/api/shared/event/${this.props.event.id}`}
                                title={i18nText(this.props.event.title)}
                                separator=" :: ">
                                <WhatsappIcon size={32} round/>
                            </WhatsappShareButton>
                        </MenuItem>

                        <MenuItem>
                            <EmailShareButton
                                url={`https://${window.location.hostname}/api/shared/event/${this.props.event.id}`}
                                title={i18nText(this.props.event.title)}>
                                <EmailIcon
                                    size={32}
                                    round/>
                            </EmailShareButton>
                        </MenuItem>
                    </Menu>
                </TitleLine>


                {!isNullOrUndefined(this.props.event.tag) &&
                <Special>
                    <span style={{opacity: 1}}>{i18nText(this.props.event.tag.title)}</span>
                </Special>
                }


                <Description>
                    <ReactMarkdown source={i18nText(this.props.event.description)}/>
                </Description>


                <SmallHeader>
                    {i18n('details/menu')}
                </SmallHeader>
                <MenuCSS>
                    <ReactMarkdown source={i18nText(this.props.event.menu)}/>
                </MenuCSS>


                <SmallHeader>
                    {i18n('details/host', {name: this.props.event.host.firstname})}
                </SmallHeader>

                <About>
                    <Host src={this.props.event.host.image} className="hostimage" alt=""/>
                    <ReactMarkdown
                        source={isNullOrUndefined(this.props.event.host.about) ? isNullOrUndefined(i18nText(this.props.event.host.about)) : i18nRaw("draft/story/default", {firstname: this.props.event.host.firstname})}/>
                </About>

                <Ingredients>
                    <ReactMarkdown source={i18nText(this.props.event.ingredients)}/>
                </Ingredients>

                <Cancellation>
                    <GreenHeadline>{i18n('details/cancellation/title')}</GreenHeadline>
                    {i18n("details/cancellation/" + this.props.event.policy, {time: i18nConfiguration("time", this.props.event.time)})}
                </Cancellation>


            </Wrapper>


        );
    }
}

export default Details;
