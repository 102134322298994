/*global google*/
import React, {Component} from 'react';

import {withScriptjs, withGoogleMap, GoogleMap, DirectionsRenderer, Marker} from "react-google-maps"
import {compose, withProps, lifecycle} from "recompose"
import {i18n, i18nLocale} from "../common/I18n";
import styled from 'styled-components';
import GoogleMapReact from "google-map-react";


const WrapperCSS = styled.div`
    padding-left: 10.0%;
    padding-right: 33%;
    padding-bottom: 24px;
    background-color: rgba(239, 178, 85, 0.03);
    @media screen and (max-width: 1100px) {
    padding-right: 10%;

}
    background-color: rgba(239, 178, 85, 0.03);

`;

const TitleCSS = styled.div`
    opacity: 0.8;
    font-family: TruenoRegular;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    text-transform: uppercase;
    padding-top: 24px;
    padding-bottom: 24px;

`;


const MapWithADirectionsRenderer = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCcdL6ifwm4SQ6IhPHnWHt92sQE1CTaJyE&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div style={{height: `100%`}}/>,
        mapElement: <div style={{height: `100%`}}/>,
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
        componentDidMount() {
            const DirectionsService = new google.maps.DirectionsService();

            DirectionsService.route({
                origin: new google.maps.LatLng(41.8507300, -87.6512600),
                destination: new google.maps.LatLng(41.8525800, -87.6514100),
                travelMode: google.maps.TravelMode.DRIVING,
            }, (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    this.setState({
                        directions: result,
                    });
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            });
        }
    })
)(props =>
    <GoogleMap
        defaultZoom={7}
        defaultCenter={new google.maps.LatLng(41.8507300, -87.6512600)}
    >
        {props.directions && <DirectionsRenderer directions={props.directions}/>}
    </GoogleMap>
);

const K_WIDTH = 150;
const K_HEIGHT = 150;

const locationStyle = {

    position: 'absolute',
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2,

    border: '1px solid #FF6344',
    borderRadius: K_HEIGHT,
    backgroundColor: '#FF6344',
    opacity:0.3,
};

class Directions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isMarkerShown: true,
        }

    }

    componentDidMount() {
        this.delayedShowMarker()
    }

    delayedShowMarker = () => {
        setTimeout(() => {
            this.setState({isMarkerShown: true})
        }, 3000)
    }

    handleMarkerClick = () => {
        this.setState({isMarkerShown: false})
        this.delayedShowMarker()
    };



    render() {

        const OPTIONS = {
            minZoom: 6,
            maxZoom: 16,
        };

        return (

            <WrapperCSS>

                <TitleCSS>{i18n('directions/title')}</TitleCSS>

                <div style={{ height: '500px', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyCcdL6ifwm4SQ6IhPHnWHt92sQE1CTaJyE", language: i18nLocale(),
                            region: i18nLocale(), }}
                        center={{lat: this.props.location.latitude, lng: this.props.location.longitude}}
                        defaultZoom={14}
                        options = {OPTIONS}

                    >

                        <div style={locationStyle} lat={this.props.location.latitude} lng={this.props.location.longitude} />


                    </GoogleMapReact>
                </div>

            </WrapperCSS>


        )
    }
}

export default Directions;
