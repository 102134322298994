import React from 'react';

import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';


const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
    },
    typography: {
        useNextVariants: true,
    },

});


class StyledProgress extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <div style={{
                width: '100%', display: 'flex', justifyContent: 'center', justifySelf: "center",
                alignSelf: "center"
            }}>
                <MuiThemeProvider theme={theme}>
                    <CircularProgress size={50}/>
                </MuiThemeProvider>
            </div>
        )
    }
}

export default (StyledProgress);