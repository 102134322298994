import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {i18n, i18nConfiguration} from "../common/I18n";
import withMobileDialog from '@material-ui/core/withMobileDialog';


import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";


const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#FF6344',
            dark: '#FF6344',
            // contrastText: will be calculated to contast with palette.primary.main
        },
        secondary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
    },

    typography: {
        useNextVariants: true,
    },

    overrides: {
        MuiTypography: {
            root: {
                fontFamily: 'TruenoRegular',
            },
            title: {
                fontFamily: 'TruenoRegular',
                color: '#04ADA9',
            },
        },

        MuiDialogContentText: {
            root: {
                fontFamily: 'TruenoLight',
                marginBottom: "40px"
            },
        },
        MuiButton: {
            root: {
                fontFamily: 'TruenoRegular',
            },
        },

        MuiStepLabel: {
            root: {
                fontFamily: 'TruenoLight',
            },
            label: {
                fontFamily: 'TruenoLight',
            },
        },
        MuiStepIcon: {
            text: {
                fontFamily: 'TruenoLight',
                fill: "#ffffff",
            },
            root : {
                color: '#EEEEEE',
                '&$active': {
                    color: '#04ADA9',
                    text: {
                        fill: '#EEEEEE',
                    }
                },
                '&$completed': {
                    color: '#04ADA9',
                    text: {
                        fill: '#EEEEEE',

                    },
                }
            }

        },
        MuiDialogActions: {
            root: {
                justifyContent: "space-evenly",
            },
        },
        MuiFormControlLabel: {
            root: {
                marginBottom: '24px',
            },
        },
    }
});

class StyledDialog extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {

        const { fullScreen } = this.props;

        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    <Dialog
                        fullScreen={fullScreen}
                        open={this.props.open}
                        onClose={this.props.onClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.props.text}
                            </DialogContentText>
                            {this.props.control}
                        </DialogContent>

                        {this.props.working ? (
                            <DialogActions>
                                <CircularProgress size={50} color={"secondary"}/>
                            </DialogActions>

                        ) : (

                            this.props.actions ? (
                                <div>
                                    {this.props.actions}
                                </div>

                            ) : (
                                <DialogActions>
                                    <Button onClick={event => this.props.onCancel(this.props.name)} color="primary">
                                        {i18n("edit/dialog/cancel")}
                                    </Button>
                                    <Button onClick={event => this.props.onSave(this.props.name)} color="primary"
                                             disabled={this.props.disabled}>
                                        {this.props.okLabel ? this.props.okLabel : i18n("edit/dialog/save")}
                                    </Button>
                                </DialogActions>

                            )
                        )}


                    </Dialog>
                </MuiThemeProvider>
            </div>
        );
    }
}

export default withMobileDialog({breakpoint: 'xs'})(StyledDialog)