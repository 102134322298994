import {createMuiTheme} from '@material-ui/core';

import {deepOrange} from '@material-ui/core/colors';
import {blue} from '@material-ui/core/colors';

export const adminTheme = createMuiTheme({
    palette: {
        primary: {
            c100: blue[100],
            c200: blue[200],
            light: blue[300],
            main: blue[500],
            dark: blue[700]
        },
        secondary: {
            light: deepOrange[300],
            main: deepOrange[500],
            dark: deepOrange[700]
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        useNextVariants: true,
    },
    anchor: {
        main: blue[500],
        selected: blue[700]
    },

    overrides: {
        MuiTable: {
            root: {
                outline: "none",
            }
        }
    }
});