import React, {Component} from 'react';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {initialize} from "../common/configuration";

import logo_large from "../assets/logo_large.svg";
import logo_white from "../assets/logo_white.svg";
import facebook from "../assets/facebook.svg";
import twitter from "../assets/twitter.svg";
import instagram from "../assets/instagram.svg";
import styled from 'styled-components';
import hero1 from "../assets/hero2.jpg";
import {i18n} from "../common/I18n";
import Timer from "../components/Timer";
import withDebug from "../common/withDebug";


const OverlayContainer = styled.div`
display: grid;
  grid-template-columns: 10% 40% 40% 10%;
  grid-template-rows: 1fr;
  @media screen and (max-width: 800px) {
    grid-template-columns: 5% 90% 5%;
      grid-template-rows: 360px 520px;
}
  
    width: 100%;
    height: auto;
    position: relative;
    z-index:99;
`;

const BackgroundContainer = styled.div`
    width: 100%;
    height: 50%;
    position: absolute;
   background-image: linear-gradient(0deg, #FFFFFF 4%, rgba(255,255,255,0.83) 76%, rgba(255,255,255,0.33) 99%), url(${props => props.image});
   background-size: cover;
    background-repeat: no-repeat;
   
`;

const FormContainer = styled.div`
  grid-column-start: 3;
  grid-column-end:4;
  grid-row-start: 1;
grid-row-end: 2;
  @media screen and (max-width: 800px) {
    grid-column-start: 2;
  grid-column-end:3;
  grid-row-start: 2;
grid-row-end: 3;
}
`;

const SliderContainer = styled.div`

  grid-column-start: 2;
grid-column-end: 3;
grid-row-start: 1;
grid-row-end: 2;
  @media screen and (max-width: 800px) {
    grid-column-start: 2;
  grid-column-end:3;
  grid-row-start: 1;
grid-row-end: 2;
}
    padding-top: 8%;
`;

const HeaderContainer = styled.div`
    width: 100%;
    position: relative;
`;

const Title = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 44px;
@media screen and (max-width: 800px) {
font-size: 32px;
}
color: #333333;
letter-spacing: -0.54px;
text-align: center;
margin-top: 70px;
`;

const Claim = styled.div`
display: block;
margin-top:20px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 16px;
color: #818181;
letter-spacing: 0;
text-align: center;
line-height: 26px;
    width:50%;
      @media screen and (max-width: 800px) {
          width:80%;

}
    margin-left: auto;
    margin-right: auto;
`;

const ParagraphCSS = styled.div`
display: block;
margin-top:20px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 16px;
color: #818181;
letter-spacing: 0;
text-align: left;
line-height: 26px;
    width:50%;
      @media screen and (max-width: 800px) {
          width:80%;

}
    margin-left: auto;
    margin-right: auto;
`;

const SmallerTitle = styled.div`
margin-top:70px;
opacity: 0.8;
font-family: TruenoBold;
@media screen and (max-width: 800px) {
font-size: 20px;

}
color: #333333;
letter-spacing: -0.6px;
text-align: center;
line-height: 48px;
margin-bottom: 28px;

`;

const Row = styled.div`
  margin-top: 42px;

display: grid;
  grid-template-columns: 10% 13% 25% 4% 13% 25% 10%;
  
    @media screen and (max-width: 800px) {
  grid-template-columns: 5% 30% 60% 5%;
      grid-template-rows: 50% 50%;
      grid-column-gap: 5px;
      grid-row-gap: 42px;
}

`;

const Image1 = styled.img`
grid-column-start: 2;
grid-column-end: 3;
   @media screen and (max-width: 800px) {
grid-column-start: 2;
grid-column-end: 3;
grid-row-start: 1;
grid-row-end: 2;
}
width: 90%;
box-shadow: 0 0 24px 0 rgba(0,0,0,0.04);
border-radius: 100%;
z-index: 10;
`;

const Text1 = styled.div`
grid-column-start: 3;
grid-column-end: 4;
   @media screen and (max-width: 800px) {
grid-column-start: 3;
grid-column-end: 4;
grid-row-start: 1;
grid-row-end: 2;
}

`;

const Image2 = styled.img`
grid-column-start: 5;
grid-column-end: 6;
   @media screen and (max-width: 800px) {
grid-column-start: 2;
grid-column-end: 3;
grid-row-start: 2;
grid-row-end: 3;
}
width: 90%;
box-shadow: 0 0 24px 0 rgba(0,0,0,0.04);
border-radius: 100%;
z-index: 10;

`;

const Text2 = styled.div`
grid-column-start: 6;
grid-column-end: 7;
   @media screen and (max-width: 800px) {
grid-column-start: 3;
grid-column-end: 4;
grid-row-start: 2;
grid-row-end: 3;
}
`;

const SmallTitle = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
`;

const Paragraph = styled.div`
margin-top: 12px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 14px;
color: #818181;
letter-spacing: 0;
line-height: 26px;
`;

const AboutUs = styled.div`
  display: grid;
  grid-template-columns: 15% 25% 4% 41% 15%;
     @media screen and (max-width: 800px) {
       grid-template-columns: 5% 90% 5%;
       grid-template-rows: 30% 70%;

}
`;

const AboutUsLeft = styled.div`
  grid-column-start: 2;
grid-column-end: 3;
@media screen and (max-width: 800px) {
  grid-column-start: 2;
grid-column-end: 3;
  grid-row-start: 2;
grid-row-end: 3;

}
`;

const AboutUsLine1 = styled.div`
margin-top: 16px;
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #04ADA9;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
text-align: right;
@media screen and (max-width: 800px) {
text-align: center;

}
`;

const AboutUsDetails = styled.div`
  grid-column-start: 4;
grid-column-end: 5;
@media screen and (max-width: 800px) {
  grid-column-start: 2;
grid-column-end: 3;
  grid-row-start: 1;
grid-row-end: 2;

}

`;

const AboutUsParagraph = styled.div`
margin-top: 16px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 16px;
color: #818181;
letter-spacing: 0;
line-height: 26px;
`;

const AboutUsParagraphRight = styled(AboutUsParagraph)`
text-align: right;
@media screen and (max-width: 800px) {
text-align: center;
}
`;

const Logo = styled.img`
margin-top: 70px;
`;

const LargeLogo = styled.img`
padding-top: 70px;

width: 30%;
margin-left: auto;
margin-right: auto;
    display: block;

`;

const SliderParagraph = styled.div`
opacity: 0.8;
margin-top: 16px;
font-family: TruenoLight;
font-size: 18px;
color: #818181;
letter-spacing: 0;
text-align: center;
line-height: 26px;
margin-left: auto;
margin-right: auto;
display: block;
`;

const FormParagraph = styled.div`
opacity: 0.8;
margin-top: 20%;
margin-bottom: 20%;
font-family: TruenoLight;
font-size: 18px;
color: #818181;
letter-spacing: 0;
text-align: center;
line-height: 26px;
width: 80%;
margin-left: auto;
margin-right: auto;
display: block;
`;

const HashtagGreen = styled.div`
padding-top: 70px;
font-family: TruenoUltraLightItalic;
font-size: 50px;
@media screen and (max-width: 800px) {
font-size: 35px;
padding-top: 20px;

}
color: #04ADA9;
text-align: center;
margin-left: auto;
margin-right: auto;
display: block;
`;


const HashtagBlue = styled(HashtagGreen)`
color: #4B9AC1;
`;

const HashtagYellow = styled(HashtagGreen)`
color: #F5A623;
`;

const Link = styled.a`
color:#ff6344;
text-decoration: none;
opacity: 0.5;
`;

const Form = styled.div`
display: block;
background-color: rgba(70,65,67,0.05);
border-radius: 8px;
width: 70%;
@media screen and (max-width: 800px) {
width: 100%;
}
margin-top: 40px;
margin-left: auto;
margin-right: auto;
padding-top: 10px;
padding-bottom: 10px;
`;

const theme = createMuiTheme({

    palette: {
        primary: {
            main: "#FF6344",
            light: "#FF6344"
        },
    },

    typography: {
        useNextVariants: true,
    },


    overrides: {
        typography: {
            fontFamily:
                '"TruenoLight"'
        },

        MuiCircularProgress: {
            root: {
                display: 'block',
                marginTop: '24px',
                marginBottom: '16px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        },

        MuiButton: {
            root: {
                textTransform: 'uppercase',
                fontSize: '12px',
                color: '#818181',
                height: 20,
            }
        },
        MuiInput: {
            root: {
                textTransform: 'uppercase',
                fontSize: '14px',
                color: '#818181',
                height: 20,
                paddingTop: 10,
                paddingBottom: 10,
                letterSpacing: 0,
            },
        },
    }
});


class About extends Component {


    constructor(props) {
        super(props);
        this.state = {
            initialized:false
        }

    }

    componentDidMount() {
        initialize().then(() => this.setState({initialized: true}));
    }


    render() {

        if (!this.state.initialized) {
            return (<Timer/>);
        }

        return (
            <div>
                <MuiThemeProvider theme={theme}>

                    <BackgroundContainer image={hero1}>
                    </BackgroundContainer>


                    <HeaderContainer>
                        <a href="/"> <LargeLogo src={logo_large} alt=""/> </a>
                    </HeaderContainer>
                    <Title>{i18n("about/title")}</Title>

                    <Claim>
                        {i18n("about/1/p")}
                    </Claim>
                    <Claim>
                        {i18n("about/2/p")}
                    </Claim>
                    <Claim>
                        {i18n("about/3/p")}
                    </Claim>

                    <SmallerTitle>{i18n("about/imprint")}</SmallerTitle>
                    <ParagraphCSS>
                        {i18n("about/imprint/p")}
                    </ParagraphCSS>


                    <SmallerTitle>{i18n("about/press")}</SmallerTitle>
                    <ParagraphCSS>
                        {i18n("about/press/p")}
                    </ParagraphCSS>
                    <SmallerTitle>{i18n("about/privacy")}</SmallerTitle>
                    <ParagraphCSS>
                        {i18n("about/privacy/p")}
                    </ParagraphCSS>


                    <center>
                        <Logo src={logo_white}/>
                    </center>
                    <center style={{marginTop: 20, marginBottom: 50}}>
                        <a href={"https://www.facebook.com/Culinarioo-182792569028065/"}><img src={facebook}/></a>
                        <a href={"https://www.twitter.com/culinarioo"}><img src={twitter}
                                                                            style={{marginLeft: 35, marginRight: 35}}/></a>
                        <a href={"https://www.instagram.com/culinarioo"}><img src={instagram}/></a>
                    </center>

                </MuiThemeProvider>

            </div>
        );
    }
}

export default withDebug()(About);
