import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import Mailchimp from 'react-mailchimp-form'

const styles = theme => ({
    root: {
        fontSize: '.8em',
        textAlign: 'center',
        marginBottom: theme.spacing.unit * 2,
    },
    label: {
        fontSize: '.8em',
    },
    textFieldAndButtonContainer: {
        textAlign: 'center',
        display: 'inline',
    },
    textField: {
        padding: theme.spacing.unit / 2,
        width: '60%',
        border: '1px solid darkgray',
    },
    button: {
        borderRadius: theme.spacing.unit / 3,
        marginLeft: theme.spacing.unit / 2,
        backgroundColor: 'darkgray',
        color: 'white',
        border: 0,
        paddingTop: theme.spacing.unit / 2,
        paddingRight: theme.spacing.unit * 1.5,
        paddingBottom: theme.spacing.unit / 2,
        paddingLeft: theme.spacing.unit * 1.5,
        cursor: 'pointer',
    },
});

class NewsletterSubscribe extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
        };
    }

    get isEmailValid() {
        return this.state.email.length > 0;
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    render() {

        const {classes} = this.props;
        const {email} = this.state;

        return (
            <Mailchimp
                action='https://credeo.us20.list-manage.com/subscribe/post?u=1934bf24ec47c4555961c5ded&amp;id=9ba1940751'
                fields={[
                    {
                        name: 'EMAIL',
                        placeholder: 'email address',
                        type: 'email',
                        required: true
                    }
                ]}
                messages = {
                    {
                        sending: "Sending...",
                        success: "Thank you for subscribing!",
                        error: "An unexpected internal error has occurred.",
                        empty: "You must write an e-mail.",
                        duplicate: "Too many subscribe attempts for this email address",
                        button: "Subscribe"
                    }
                }
            />
        );

        return (
            <div className={classes.root}>
                <span
                    className={classes.label}
                >Subscribe to our mailing list</span>
                <div
                    className={classes.textFieldAndButtonContainer}
                >
                    <input
                        className={classes.textField}
                        placeholder={'email address'}
                        value={email}
                        onChange={this.handleChange(email)}
                        disabled={!this.isEmailValid}
                    />
                    <button
                        className={classes.button}
                    >Subscribe</button>
                </div>
            </div>
        );
    }
}

export default withStyles(styles, {withTheme: true})(NewsletterSubscribe);
