import React, {Component} from 'react';
import styled from 'styled-components';

import {i18nText, i18n, i18nLocale} from "../common/I18n";
import asset_cc from "../assets/cc.svg";
import asset_cc_amex from "../assets/cc_amex.svg";
import asset_cc_mastercard from "../assets/cc_mastercard.svg";
import asset_cc_visa from "../assets/cc_visa.svg";
import asset_cc_back from "../assets/cc_back.svg";


const CardCC = styled.div`
   width: 285px;
   height: 180px;
   position: relative;

`;

const CCImage = styled.img`
   width: 285px;
   height: 180px;
   position: absolute;
   left:0px;
   top:0px;
   opacity: ${(props) => props.dimm ? '0.3' : '1'};
   transition: opacity 2s;

`;

const CardNumberCC = styled.div`
 position: absolute;
 left: 20px;
 bottom: 45px;
 font-family: "OCRAExtended";
font-size: 22px;
line-height: 30px;
color:  #FFFFFF;
text-align: left;
   opacity: ${(props) => props.dimm ? '0.3' : '1'};
      transition: opacity 1s;

text-shadow: 0 2px 3px rgba(0,0,0,0.80);
`;

const ValidCC = styled.div`
 position: absolute;
 right: 80px;
 bottom: 15px;
 font-family: "OCRAExtended";
font-size: 16px;
color: #FFFFFF;
text-align: left;
   opacity: ${(props) => props.dimm ? '0.3' : '1'};
         transition: opacity 1s;

text-shadow: 0 2px 3px rgba(0,0,0,0.80);
`;

const CVCCC = styled.div`
 position: absolute;
 right: 20px;
 bottom: 65px;
 font-family: "OCRAExtended";
font-size: 16px;
color: #1d1d1d;
   opacity: ${(props) => props.dimm ? '0.3' : '1'};

text-align: left;
`;

const ValidLabelCC = styled.div`
 position: absolute;
 right: 150px;
 bottom: 20px;
 font-family: "OCRAExtended";
font-size: 8px;
color: #ffffff;
text-align: right;
   opacity: ${(props) => props.dimm ? '0.3' : '1'};
      transition: opacity 2s;


text-transform: uppercase;
`;

const ValidLabelRedCC = styled.div`
 position: absolute;
 right: 150px;
 bottom: 20px;
 font-family: "OCRAExtended";
font-size: 8px;
color: #FF6344;
text-align: right;
   opacity: ${(props) => props.dimm ? '0.3' : '1'};
      transition: opacity 2s;


text-transform: uppercase;
`;


class Card extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let asset = asset_cc;

        switch (this.props.type) {
            case "amex":
            case "american express":
                asset = asset_cc_amex;
                break;
            case "visa":
                asset = asset_cc_visa;
                break;
            case "mastercard":
                asset = asset_cc_mastercard;
                break;
        }

        const names = "flip-container" + (this.props.highlighted === "cardCvc" ? " flipped" : "")
        ;
        return (

            <center>
                <div style={{userSelect: "none"}}>


                    <div className={names}>
                        <div className="flipper">
                            <div className="front">
                                <CardCC>
                                    <CCImage
                                        src={asset}
                                        dimm={this.props.highlighted !== null}/>

                                    <CardNumberCC
                                        highlighted={this.props.highlighted === "cardNumber"}
                                        dimm={this.props.highlighted !== "cardNumber" && this.props.highlighted !== null}
                                    >
                                        {this.props.number}
                                    </CardNumberCC>
                                    {this.props.expired !== true &&
                                    <ValidLabelCC
                                        dimm={this.props.highlighted !== null}
                                    >
                                        {i18n("cc/validUntil")}</ValidLabelCC>
                                    }
                                    {this.props.expired === true &&
                                    <ValidLabelRedCC
                                        dimm={this.props.highlighted !== null}
                                    >
                                        {i18n("cc/expiredSince")}</ValidLabelRedCC>
                                    }
                                    <ValidCC
                                        highlighted={this.props.highlighted === "cardExpiry"}
                                        dimm={this.props.highlighted !== "cardExpiry" && this.props.highlighted !== null}
                                    >
                                        {`${this.props.month}/${this.props.year}`}</ValidCC>



                                </CardCC>
                            </div>
                            <div className="back">
                                <CardCC>

                                    <CCImage
                                        src={asset_cc_back}
                                        dimm={this.props.highlighted !== null}/>

                                    <CVCCC
                                        highlighted={this.props.highlighted === "cardCvc"}>{this.props.cvc}</CVCCC>

                                </CardCC>
                            </div>
                        </div>
                    </div>


                </div>
            </center>


        );
    }
}

export default Card;
