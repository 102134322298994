import React from 'react';
import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import styled from 'styled-components';

const CheckBoxContainer = styled.div`
    width: ${props => props.width};
    margin-bottom: ${props => props.marginBottom};
`;


const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
        secondary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
    },

    typography: {
        useNextVariants: true,
    },


    overrides: {
        MuiButtonBase: {
            root: {
                top: '-10px',
            },
        },
        MuiCheckbox: {
            root: {
                color: '#04ADA9',
            },
        },
    }
});


class StyledCheckBox extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (


            <MuiThemeProvider theme={theme}>
                <CheckBoxContainer width={this.props.width} marginBottom={this.props.marginBottom}>
                    <FormGroup>
                        <FormControlLabel
                            style={{alignItems: 'start'}}
                            disabled={this.props.disabled}
                            control={
                                <Checkbox
                                    checked={this.props.checked}
                                    onChange={event => this.props.onChange(this.props.value, event)}
                                    value={this.props.value}
                                />
                            }
                            label={this.props.label}
                        />
                    </FormGroup>
                </CheckBoxContainer>

            </MuiThemeProvider>
        );
    }
}

export default (StyledCheckBox);