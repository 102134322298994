import React, { Component } from 'react';
import { initialize } from "../common/configuration";

import styled from 'styled-components';
import { i18n, i18nChange, i18nText } from "../common/I18n";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SearchInput from "../components/SearchInput";
import EventBlock from "../components/EventBlock";
import Carousel from "../components/Carousel";
import firebaseApp, { trackUserPresence } from "../common/firebase";
import { List } from "react-content-loader";
import { getCity, getEventsNearby, sortEventsBy } from "../common/utils";
import Timer from "../components/Timer";
import withDebug from "../common/withDebug";


const firestore = firebaseApp.firestore();


const TitleCSS = styled.div`
width:80%;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 80px;
opacity: 0.8;
font-family: TruenoBold;
font-size: 34px;
color: #333333;
letter-spacing: -0.49px;
line-height: 42px;
text-transform: uppercase;
`;

const HintCSS = styled.div`
width:90%;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 80px;
opacity: 0.1;
font-family: TruenoLight;
font-size: 34px;
color: #333333;
letter-spacing: -0.49px;
line-height: 42px;
text-transform: uppercase;
user-select: none;
text-align: center;
`;


class SearchPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            events: [],
            user: null,
            roles: null,
            favorites: [],
            geopoint: null,
            city: props.match.params.city,
            country: props.match.params.country,
            initialized: false
        };
    }


    componentDidMount() {

        initialize().then(() => this.setState({ initialized: true }));


        firebaseApp.auth().onAuthStateChanged(async (user) => {
            if (user) {
                try {

                    const profile = await firestore.collection('profiles').doc(user.uid).get();
                    const roles = await firestore.collection('roles').doc(user.uid).get();

                    if (!profile.exists || !roles.exists) {
                        this.setState({ systemError: "noProfile" });
                    } else {
                        this.setState({ user: profile.data(), roles: roles.data() }, () => {
                            i18nChange(this.state.user.language);
                        });
                        trackUserPresence();

                    }
                } catch (error) {
                    this.setState({ systemError: error.code });
                }
            }
        });

        this.setState({ working: true, events: [] }, async () => {
            await this.handleChange(this.state.country, this.state.city);
        })
    }

    handleChange = async (_country, _city) => {

        const { country, city, geopoint } = await getCity(_country, _city);

        this.setState({ country: country, city: city, working: true }, async () => {

            let results = [];
            if (country !== null && city !== null && geopoint !== null) {
                results = await getEventsNearby(geopoint);
            }

            this.setState({ working: false, events: results })

        });
    };


    render() {

        if (!this.state.initialized) {
            return (<Timer />);
        }

        const nearby = [...this.state.events];

        let soon = [...this.state.events];
        soon = soon.sort((a, b) => a.start.toDate().getTime() - b.start.toDate().getTime());

        let cheap = [...this.state.events];
        cheap = cheap.sort((a, b) => a.price.amount - b.price.amount);


        return (
            <div>
                <Header
                    user={this.state.user} roles={this.state.roles}><SearchInput
                        onChange={this.handleChange}></SearchInput></Header>

                {this.state.working ?
                    (<List width={"400px"} style={{
                        display: 'block',
                        marginTop: "64px",
                        marginBottom: "64px",
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }} />) :
                    (
                        <div>
                            <TitleCSS>{i18n('search/title', { city: this.state.city })}</TitleCSS>

                            {this.state.events.length > 0 ? (

                                <Carousel width={"90%"}>

                                    {nearby.map(event => {
                                        return (<EventBlock key={event.id}
                                            id={event.id}
                                            event={event} />);
                                    })}
                                </Carousel>
                            ) : (
                                    <HintCSS>{i18n("search/noEvents")}</HintCSS>

                                )}

                            {this.state.events.length > 4 &&

                                <React.Fragment>

                                    <TitleCSS>{i18n('search/soon', { city: this.state.city })}</TitleCSS>

                                    {this.state.events.length > 0 ? (

                                        <Carousel width={"90%"}>

                                            {soon.map(event => {
                                                return (<EventBlock key={event.id}
                                                    id={event.id}
                                                    event={event} />);
                                            })}
                                        </Carousel>
                                    ) : (
                                            <HintCSS>{i18n("search/noEvents")}</HintCSS>

                                        )}
                                </React.Fragment>
                            }

                            {this.state.events.length > 4 &&

                                <React.Fragment>

                                    <TitleCSS>{i18n('search/cheap', { city: this.state.city })}</TitleCSS>

                                    {this.state.events.length > 0 ? (

                                        <Carousel width={"90%"}>

                                            {cheap.map(event => {
                                                return (<EventBlock key={event.id}
                                                    id={event.id}
                                                    event={event} />);
                                            })}
                                        </Carousel>
                                    ) : (
                                            <HintCSS>{i18n("search/noEvents")}</HintCSS>

                                        )}
                                </React.Fragment>
                            }


                        </div>)
                }


                <Footer></Footer>
            </div>
        );
    }
}

export default withDebug()(SearchPage);
