import React, { Component } from 'react';
import { initialize } from "../common/configuration";

import Header from "../components/Header";
import StyledCheckBox from '../components/StyledCheckBox';
import Footer from "../components/Footer";
import Progress from "../components/Progress";
import { countries, masterdata } from "../common/configuration"
import { CenterCSS, Grid, BoxCSS } from "../common/Layout";
import { i18n, i18nConfiguration, i18nText, i18nLocale } from "../common/I18n";
import styled from 'styled-components';

import quote_top from '../assets/quote_top.svg';
import quote_bottom from '../assets/quote_bottom.svg';
import StyledTextField from "../components/StyledTextField";
import StyledButton from "../components/StyledButton";
import StyledFlatButton from "../components/StyledFlatButton";
import Dropzone from 'react-dropzone'
import ScrollSelect from "../components/ScrollSelect";
import Timer from "../components/Timer";
import CC from "../components/CC";
import Card from "../components/Card";

import Moment from 'react-moment';
import 'moment/locale/de';
import moment from "moment/moment";
import { Redirect } from 'react-router';
import firebaseApp, { trackUserPresence } from "../common/firebase";

import IconButton from "@material-ui/core/IconButton";
import * as firebase from "firebase";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { isNullOrUndefined } from "util";
import { calcFee, DateMaskCustom, EMailMaskCustom, isEMailValid, MobileMaskCustom, populateTags, calcStripeFee } from "../common/utils";
import { ReactGA } from "../common/tracking";
import hash from "object-hash";
import { Elements, StripeProvider } from 'react-stripe-elements';
import ErrorSnackbar from "../components/ErrorSnackbar";
import StyledProgress from "../components/StyledProgress";
import ReactDOM from "react-dom";
import StyledTooltip from "../components/StyledTooltip";
import * as loadimage from "blueimp-load-image";
import qs from "qs";
import MouseOverPopover from "../components/MousePopover";
import asset_info from '../assets/info.svg';
import withDebug from "../common/withDebug";


firebaseApp.auth().useDeviceLanguage();
const firestore = firebaseApp.firestore();
const storage = firebaseApp.storage();


const Wizard = styled.div`

margin-top: 54px;
margin-bottom: 54px;
@media screen and (max-width: 1200px) {
margin-left: 0%;
margin-right: 0%;
}
`;

const QuestionCSS = styled.div`
width: 70%;
text-align: center;
display: grid;
grid-template-columns: 10% 80% 10%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 900px) {
width: 100%;
grid-template-columns: 20% 60% 20%;
}
`;

const ContentCSS = styled.div`
margin-top: 64px;
width: 70%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 900px) {
width: 90%;
}
`;

const TextCSS = styled.div`
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
opacity: 0.8;
text-transform: uppercase;
justify-self: center;
align-self: center;
padding-left: 24px;
padding-right: 24px;
`;

const LeftQuoteCSS = styled.img`
justify-self: end;
align-self: start;
margin-top: -32px;
`;

const RightQuoteCSS = styled.img`
justify-self: start;
align-self: end;
margin-bottom: -32px;
`;

const Step2GridCSS = styled.div`
grid-template-columns: 45% 55%;
grid-template-rows: repeat(5, 1fr);
grid-column-gap: 8px;
grid-row-gap: 8px;
display: grid;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 700px) {
grid-template-columns: 100%;
grid-template-rows: repeat(7, 1fr);
grid-template-areas: 
"image"
"image"
"firstname"
"lastname"
"birthday"
"people"
"button"
}
grid-template-areas: 
"image firstname"
"image lastname"
"image birthday"
"image people"
". button"
`;

const ImageCSS = styled.div`
grid-area: image;
justify-self: center;
align-self: start;
`;

const FirstNameCSS = styled.div`
grid-area: firstname;
`;

const LastNameCSS = styled.div`
grid-area: lastname;
`;

const BirthdayCSS = styled.div`
grid-area: birthday;
display: flex;
flex-direction: column;
position: relative;
`;

const PeopleCSS = styled.div`
grid-area: people;
`;

const ButtonCSS = styled.div`
grid-area: button;
justify-self: center;
margin-top:24px;
@media screen and (max-width: 1100px) {
justify-self: center;
}
`;

const StyledProgressCSS = styled.div`
display: grid;
border-radius: 100%;
background-color: rgba(255,99,68, 0.6);
width: 100%;
height: 100%;
text-align: center;
`;

const DropCSS = styled.div`
display: grid;
border-radius: 100%;
background-color: rgba(255,99,68, 0.6);
width: 100%;
height: 100%;
text-align: center;
&:hover {
box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
cursor: pointer;
font-family: TruenoBold;
}
&:active {
box-shadow: none;
cursor: pointer;
font-family: TruenoRegular;
}
`;

const DropPreviewCSS = styled.div`
background-image:  url(${props => props.src});
background-size: cover;
background-repeat: no-repeat;
border-radius: 100%;
width: 100%;
height: 100%;
&:hover {
box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
cursor: pointer;
font-family: TruenoBold;
}
&:active {
box-shadow: none;
cursor: pointer;
font-family: TruenoRegular;
}
`;

const DropTextCSS = styled.div`
    opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #FAFAFA;
letter-spacing: 1.5px;
line-height: 22px;
justify-self: center;
align-self: center;
`;


const Step4GridCSS = styled.div`
grid-template-columns: 45% 50%;
grid-template-rows: repeat(3, 1fr);
grid-column-gap: 8px;
grid-row-gap: 24px;
display: grid;
@media screen and (max-width: 1100px) {
grid-template-columns: 100%;
grid-template-rows: repeat(4, 1fr);
grid-template-areas: 
"cc"
"ccform"
"ccform"
"button"
}
grid-template-areas: 
"cc ccform"
"cc ccform"
"cc button"
`;

const CCCSS = styled.div`
grid-area: cc;
justify-self: center;
align-self: start;
`;

const CCForm = styled.div`
grid-area: ccform;
`;

const CCExpiresCSS = styled.div`
width:50%;
padding-right: 10%;
`;

const CVCCSS = styled.div`
padding-left: 10%;
width:30%;
`;

const Step5GridCSS = styled.div`
grid-template-columns: 50% 50%;
grid-template-rows: 10% 35% auto auto;
grid-column-gap: 32px;
grid-row-gap: 16px;
display: grid;
@media screen and (max-width: 700px) {
grid-template-columns: 100%;
grid-template-rows: 10% auto 10% auto auto 10%;
grid-template-areas: 
"title"
"event"
"promocode"
"payment"
"legal"
"button"
}
grid-template-areas: 
"title ."
"event payment"
"event legal"
"promocode button"
`;

const TitleCSS = styled.div`
grid-area: title;
`;

const TitleBlackCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
text-transform: uppercase;
`;

const TitleGreenCSS = styled(TitleBlackCSS)`
color: #04ADA9;
`;


const EventCSS = styled.div`
grid-area: event;
`;

const EventBoxCSS = styled.div`
width:100%;
height: 100%;
background: #FFFFFF;
box-shadow: 0 2px 9px 0 rgba(0,0,0,0.50);
border-radius: 8px;
min-height: 400px;
position: relative;
`;

const EventImageCSS = styled.div`
border-radius: 8px 8px 0 0;
background-image: url(${props => props.src});
height: 55%;
width: 100%;
background-size: cover;
background-repeat: no-repeat;
position: absolute;
top:0px;
`;

const EventInfoCSS = styled.div`
height: 45%;
width: 100%;
position: absolute;
bottom:0px;
display: grid;
grid-template-columns: 5% 20% 40% 30% 5%;
grid-template-rows: 30% 40% 30%;
grid-template-areas: 
". infotitle infotitle infotitle ."
". infoprofile info info ."
". infoprice infoprice infoprice ."
`;

const EventInfoTitleCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 12px;
color: #818181;
letter-spacing: 1.29px;
line-height: 22px;
text-transform: uppercase;
grid-area: infotitle;
align-self: center;
`;

const EventInfoProfileCSS = styled.div`
background-image: url(${props => props.src});
background-size: cover;
background-repeat: no-repeat;
border-radius: 100%;
width: 60px;
height: 60px;
grid-area: infoprofile;
`;

const EventInfoInfoCSS = styled.div`
grid-area: info;
`;


const EventInfoInfo1CSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 12px;
color: #333333;
letter-spacing: 1.5px;
text-align: left;
line-height: 22px;
text-transform: uppercase;
`;

const EventInfoInfo2CSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 10px;
color: #E2E2E2;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
`;

const EventInfoPriceCSS = styled.div`
grid-area: infoprice;
position:relative;
display: flex;
justify-content:flex-end;
`;


const EventInfoPriceAmountCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 44px;
color: #04ADA9;
letter-spacing: -0.6px;
text-align: center;
line-height: 48px;
justify-self: center;

`;

const EventInfoPriceCurrencyCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 28px;
color: #04ADA9;
letter-spacing: -0.2px;
text-align: center;
line-height: 32px;
`;


const PaymentCSS = styled.div`
grid-area: payment;
align-self: start;
justify-self: center;
`;

const LegalCSS = styled.div`
grid-area: legal;
justify-self: center;

`;

const PromoCodeCSS = styled.div`
grid-area: promocode;
`;

const LegalLabelCSS = styled.div`
    opacity: 0.8;
    font-Family: TruenoLight;
    font-size: 12.00px;
    color: #818181;
    letter-spacing: 0;
    line-height: 18.00px;
    align-items: start
`;

const CaptchaCSS = styled.div`
margin-top: 40px;
display:block;
margin-left: auto;
margin-right: auto;
`;

const HintTextCSS = styled.div`
color: var(--green);
font-family: TruenoLight;
text-align: left;
margin-top: 16px;
font-size: 12px;
`;

const HintCSS = styled.div`
position: absolute;
user-select: none;
top: 8px;
right: -40px;
display: block;
opacity: 0.4;
@media screen and (max-width: 600px) {
margin-left: auto;
margin-right: auto;
top:8px;
right: -32px;
}
cursor: help;
&:hover {
opacity: 1;
}
`;

const InfoCSS = styled.div`
cursor: pointer;
margin-left: 8px;
`;

const FinancialWrapperCSS = styled.div`
display: flex;
flex-direction: column;
min-width: 300px;
padding-left: 40px;
padding-right: 40px;
`;
const FinancialHeaderCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 16px;
color: #818181;
letter-spacing: 0;
width: 100%;
margin-top: 16px;
margin-bottom: 16px;
text-align: center;
`;
const FinancialLineCSS = styled.div`
display: flex;
@media screen and (max-width: 600px) {
flex-direction: column;
}
justify-content:space-between;
width: 100%;
margin-top: 16px;
margin-bottom: 16px;
`;
const FinancialTextCSS = styled.div`
opacity: 0.8;
font-family: TruenoLight;
font-size: 16px;
color: #818181;
letter-spacing: 0;
line-height: 26px;
`;
const FinancialBoldTextCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 16px;
color: #818181;
letter-spacing: 0;
line-height: 26px;
`;

const STEPS_WITHOUT_EVENT = [
    "WELCOME",
    "MOBILE",
    "CODE",
    "EMAIL",
    "CONFIRM",
    "PASSWORD",
    "GUEST",
    "DONE",
];

const STEPS_WITH_EVENT = [
    "WELCOME",
    "MOBILE",
    "CODE",
    "EMAIL",
    "CONFIRM",
    "PASSWORD",
    "GUEST",
    "CC",
    "BOOK",
    "BOOKED",
];

function Question(props) {
    return (
        <QuestionCSS>
            <LeftQuoteCSS src={quote_top} />
            <TextCSS>{props.children}</TextCSS>
            <RightQuoteCSS src={quote_bottom} />
        </QuestionCSS>
    )
}

class Register extends Component {


    constructor(props) {
        super(props);

        this.state = {
            id: isNullOrUndefined(props.match.params.eventId) ? null : props.match.params.eventId,
            step: "WELCOME",
            event: null,
            mobile: null,
            mobileprefix: "+49",
            birthday: null,
            birthdayText: null,
            dateError: null,
            code: null,
            email: null,
            password: null,
            firstname: null,
            lastname: null,
            promoCode: null,
            displayPromoCode: false,
            people: 0,
            legal: false,
            showPassword: false,
            created: new Date(),
            done: false,
            takeAway: false,
            image: null,
            updated: null,
            language: i18nLocale(),
            ccHighlight: null,
            ccCompleted: false,
            ccBrand: null,
            systemError: null,
            voucherType: null,
            voucherAmount: null,
            steps: isNullOrUndefined(props.match.params.eventId) ? STEPS_WITHOUT_EVENT : STEPS_WITH_EVENT,
            stripeCustomers: [],
            initialized: false,
            anchorEl: null

        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            forward: qs.parse(nextProps.location.search.slice(1)).forward,
        };
    }

    componentDidMount() {

        initialize().then(() => this.setState({ initialized: true }));


        firebaseApp.auth().onAuthStateChanged(async (user) => {
            if (user) {

                try {
                    const registration = await firestore.collection('registrations').doc(user.uid).get();

                    if (!registration.exists) {
                        console.log('Error. No such document!');
                    } else {
                        this.deserialize(registration.data());
                    }
                    this.listen(user.uid);
                    trackUserPresence();


                } catch (error) {
                    this.setState({ systemError: error });
                }
                ;
            }
        });

        this.load();

    };

    componentDidUpdate = () => {

        if (this.state.scrollToTop) {
            this.setState({ scrollToTop: false }, () => {
                ReactDOM.findDOMNode(this).scrollIntoView()
            });
        }
    };

    listen = (uid) => {
        firestore.collection('stripeCustomers').where("uid", "==", uid)
            .onSnapshot(
                snapshot => {

                    let stripeCustomers = [];

                    snapshot.forEach((change) => {
                        stripeCustomers.push(change.data());
                    });


                    if (stripeCustomers.length > 1) {
                        stripeCustomers.sort((a, b) => {
                            return b.created.toDate().getTime() - a.created.toDate().getTime()
                        });
                    }

                    this.setState({
                        stripeCustomers: stripeCustomers,
                    });

                }
                , snapShotError => {
                    this.setState({ systemError: snapShotError });
                });

        firestore.collection('profiles').doc(uid)
            .onSnapshot(
                snapshot => {

                    this.setState({
                        user: snapshot.data(),
                    });

                }
                , snapShotError => {
                    this.setState({ systemError: snapShotError });
                });
    };

    serialize = () => {
        return {
            step: this.state.step,
            mobileprefix: this.state.mobileprefix,
            mobile: this.state.mobile,
            email: this.state.email,
            birthday: this.state.birthday,
            people: this.state.people,
            image: this.state.image,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            promoCode: this.state.promoCode,
            done: this.state.done,
            created: this.state.created,
            updated: this.state.updated,
            event: isNullOrUndefined(this.state.event) ? null : this.state.event.id
        }

    };

    deserialize = (data) => {
        this.setState(
            {
                step: data.step,
                mobileprefix: data.mobileprefix,
                mobile: data.mobile,
                email: data.email,
                people: data.people,
                image: data.image,
                birthday: data.birthday ? data.birthday.toDate() : null,
                firstname: data.firstname,
                lastname: data.lastname,
                promoCode: data.promoCode,
                done: data.done,
                created: data.created,
                updated: data.updated,
                id: data.event
            }, () => {
                this.load();
            }
        );

    };

    load = () => {

        if (this.state.id !== null) {


            firestore.collection('events').doc(this.state.id).get()
                .then(event => {
                    if (!event.exists) {
                        window.location = "/notFound";
                    } else {

                        let _event = Object.assign({}, event.data());
                        _event.id = event.id;

                        this.setState({ event: _event });

                    }
                })
                .catch(error => {
                    this.setState({ error: error.code });
                });
        }
    };

    nextStep = () => {

        let index = this.state.steps.findIndex((step) => step === this.state.step);
        if (index === -1) {
            return this.state.steps[0];
        }

        if ((index + 1) < this.state.steps.length) {
            return this.state.steps[index + 1]
        } else {
            return this.state.steps[index]
        }
    };

    previousStep = () => {

        let index = this.state.steps.findIndex((step) => step === this.state.step);
        if (index === -1) {
            return this.state.steps[0];
        }

        if ((index - 1) < 0) {
            return this.state.steps[index]
        } else {
            return this.state.steps[index - 1];
        }
    };

    indexOfStep = () => {

        return this.state.steps.findIndex((step) => step === this.state.step);
    };

    handleChangeSelected = (name, number) => {

        this.setState({ [name]: number });
    };

    handleDateChange(event) {

        this.setState({ [event.target.name]: event.target.value });

        const birthday = moment(event.target.value, "DD.MM.YYYY", true);
        if (birthday.isValid()) {

            if (birthday.isAfter(moment().subtract(18, "years"))) {
                this.setState({ birthday: null, dateError: "tooYoung" });

            } else {
                this.setState({ birthday: birthday.toDate(), dateError: null });
            }
        } else {
            this.setState({ birthday: null, dateError: "invalidDate" });
        }
    };

    async handleClick(name) {
        switch (name) {

            case "button_back":
                this.setState({ step: this.previousStep(), error: null, scrollToTop: true });
                break;

            case "button_next":
                this.setState({ step: this.nextStep(), error: null, scrollToTop: true });
                break;

            case "button_resend":

                this.setState({ working: true, error: null }, async () => {

                    try {
                        const actionCodeSettings = {
                            url: `https://${window.location.hostname}/register${this.state.id ? "/" + this.state.id : ""}`,
                        };
                        await firebaseApp.auth().currentUser.sendEmailVerification(actionCodeSettings);
                        this.setState({ working: false })

                    } catch (error) {
                        this.setState({ working: false, systemError: error }, () => {
                            window.recaptchaVerifier = null
                        });
                    }

                });

                break;

            case "button_welcome": // welcome

                this.setState({ working: true, error: null, step: this.nextStep() }, () => {
                    ReactGA.pageview('/guest/register/welcome');
                    this.setState({ working: false })
                });
                break;

            case "button_mobile": // mobile
                this.setState({ working: true, error: null }, async () => {


                    try {

                        const trimmedPhone = (this.state.mobileprefix + this.state.mobile).replace(/\s/g, '');

                        const fsPhone = await firestore.collection('phones').doc(hash(trimmedPhone)).get();

                        if (fsPhone.exists) {
                            this.setState({ working: false, error: "PHONE_EXISTS_ALREADY" });
                        } else {

                            const confirmationResult = await firebase.auth().signInWithPhoneNumber(trimmedPhone, window.recaptchaVerifier);
                            ReactGA.pageview('/guest/register/mobile');

                            this.setState({
                                step: this.nextStep(),
                                working: false,
                                confirmationResult: confirmationResult,
                                scrollToTop: true
                            }, () => {
                                window.recaptchaVerifier = null
                            });
                        }

                    } catch (error) {
                        this.setState({ working: false, error: error.code }, () => {
                            window.recaptchaVerifier = null
                        });
                    }
                });

                break;
            case "button_code": // code
                this.setState({ working: true, error: null }, () => {
                    this.state.confirmationResult.confirm(this.state.code)
                        .then(result => {
                            ReactGA.pageview('/guest/register/code');
                            this.setState({
                                step: this.nextStep(),
                                working: false,
                                scrollToTop: true
                            });
                        })
                        .catch(error => {
                            this.setState({ working: false, error: error.code });
                        });
                });
                break;
            case "button_email": // email

                this.setState({ working: true, error: null }, async () => {

                    ReactGA.pageview('/guest/register/email');

                    const fsEmail = await firestore.collection('emails').doc(hash(this.state.email.toLowerCase())).get();

                    try {
                        if (fsEmail.exists && (this.state.email !== firebaseApp.auth().currentUser.email)) {
                            this.setState({ working: false, error: "EMAIL_EXISTS_ALREADY" });
                        } else {
                            const actionCodeSettings = {
                                url: `https://${window.location.hostname}/register${this.state.id ? "/" + this.state.id + "?forwarded=true" : "?forwarded=true"}`,
                            };

                            if (this.state.email !== firebaseApp.auth().currentUser.email) {
                                await firebaseApp.auth().currentUser.updateEmail(this.state.email);
                            }

                            await firebaseApp.auth().currentUser.sendEmailVerification(actionCodeSettings);

                            let batch = firestore.batch();

                            let data = this.serialize();
                            data.step = this.nextStep();

                            batch.set(
                                firestore.collection('registrations').doc(firebaseApp.auth().currentUser.uid),
                                data
                            );

                            if (this.state.email !== firebaseApp.auth().currentUser.email) {
                                batch.set(
                                    firestore.collection('emails').doc(hash(firebaseApp.auth().currentUser.email.toLowerCase())),
                                    { created: new Date() }
                                );
                            }

                            await batch.commit();

                            this.setState({ step: this.nextStep(), working: false, scrollToTop: true });

                        }
                    } catch (error) {

                        if (error.code === "auth/requires-recent-login") {
                            this.setState({ working: false, step: "WELCOME", forwarded: true });
                        } else {
                            this.setState({ working: false, systemError: error });
                        }
                    }


                });

                break;
            case "button_password": // password

                this.setState({ working: true, error: null }, async () => {

                    try {

                        ReactGA.pageview('/guest/register/password');

                        const credential = firebase.auth.EmailAuthProvider.credential(this.state.email, this.state.password);
                        await firebaseApp.auth().currentUser.linkAndRetrieveDataWithCredential(credential);

                        let data = this.serialize();
                        data.step = this.nextStep();

                        await firestore.collection('registrations').doc(firebaseApp.auth().currentUser.uid).set(data,
                            { merge: true });

                        this.setState({ step: this.nextStep(), working: false, scrollToTop: true });
                    } catch (error) {
                        if (error.code === "auth/requires-recent-login") {
                            this.setState({ working: false, step: "WELCOME", forwarded: true });
                        } else {
                            this.setState({ working: false, error: error.code });
                        }
                    }
                });

                break;
            case "button_guest": // who

                this.setState({ working: true, error: null }, () => {

                    ReactGA.pageview('/guest/register/guest');

                    const profile = {
                        uid: firebase.auth().currentUser.uid,
                        firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        mobileprefix: this.state.mobileprefix,
                        mobile: this.state.mobile,
                        birthday: this.state.birthday,
                        email: this.state.email,
                        image: this.state.image,
                        address: {
                            street: null,
                            postalCode: null,
                            country: "DE",
                            city: {}
                        },
                        about: {},
                        requiresVAT: false,
                        vatID: null,
                        taxID: null,
                        tags: [],
                        language: this.state.language,
                        change: "ignore",
                        created: new Date(),
                        updated: null,
                    };


                    let batch = firestore.batch();

                    let data = this.serialize();
                    data.done = true;
                    data.step = this.nextStep();

                    batch.set(
                        firestore.collection('profiles').doc(firebase.auth().currentUser.uid),
                        profile
                    );
                    batch.set(
                        firestore.collection('registrations').doc(firebase.auth().currentUser.uid),
                        data
                    );

                    batch.set(
                        firestore.collection('phones').doc(hash((this.state.mobileprefix + this.state.mobile).replace(/\s/g, ''))),
                        { created: new Date() }
                    );

                    batch.commit()
                        .then(() => {
                            this.setState({ done: true, working: false, step: this.nextStep() });
                        })
                        .catch((error) => {
                            this.setState({ working: false, systemError: error });
                        });
                });

                break;
            case "button_cc": // stripe handled in handleCCToken
                this.setState({ step: this.nextStep(), working: false, scrollToTop: true });
                break;
            case "button_book": // book
                this.setState({ working: true, error: null }, async () => {

                    let data = this.serialize();
                    data.done = true;
                    data.step = this.nextStep();

                    let batch = firestore.batch();

                    batch.set(
                        firestore.collection('registrations').doc(firebaseApp.auth().currentUser.uid),
                        data
                    );

                    batch.set(
                        firestore.collection('profiles').doc(firebaseApp.auth().currentUser.uid),
                        {
                            tags: populateTags(this.state.user, this.state.event),
                            status: "active",
                            change: "ignore",
                            updated: new Date()
                        },
                        { merge: true }
                    );


                    const bookingRequest = {
                        type: "book",
                        uid: firebaseApp.auth().currentUser.uid,
                        event: this.state.event,
                        seats: this.state.people,
                        promoCode: this.state.promoCode,
                        status: "new",
                        wantsTakeAway: this.state.takeAway,
                        cc: this.state.stripeCustomers[0],
                        error: null,
                        reason: null,
                        created: new Date(),
                        updated: null
                    };

                    batch.set(
                        firestore.collection('bookingRequests').doc(),
                        bookingRequest);


                    batch.commit()
                        .then(() => {
                            this.setState({ done: true, working: false, scrollToTop: true, step: this.nextStep() });
                        })
                        .catch((error) => {
                            this.setState({ working: false, systemError: error });
                        });
                });
                break;

            case "button_promoCode":
                this.setState({ displayPromoCode: true });
                break;

            case "button_cancelPromoCode":
                this.setState({ promoCode: null, displayPromoCode: false });
                break;

            case "button_redeemCode":
                this.setState({ working: true }, async () => {
                    if (!isNullOrUndefined(this.state.promoCode)) {

                        const fsVoucher = await firestore.collection("vouchers").doc(this.state.promoCode).get();

                        if (!fsVoucher.exists) {
                            this.setState({ working: false, promoError: i18n("promoCode/unkown") });
                            return;
                        } else {

                            const fsRedeemed = await await firestore.collection("vouchers").doc(this.state.promoCode).collection('redeem').doc(this.state.user.uid).get();

                            if (fsRedeemed.exists) {
                                this.setState({ working: false, promoError: i18n("promoCode/alreadyRedeemed") });
                                return;
                            } else {

                                const eligable =
                                    (isNullOrUndefined(fsVoucher.data().minAmount) ||
                                        (fsVoucher.data().minAmount <= (this.state.event.price.amount * (this.state.people + 1) + calcFee(this.state.event.price.amount * (this.state.people + 1), this.state.event.fee))))
                                    &&
                                    (fsVoucher.data().constraint === null || fsVoucher.data().constraint === this.state.event.type)
                                    && this.state.promoCode !== "VIP"

                                if (!eligable) {
                                    this.setState({ working: false, promoError: i18n("promoCode/notEligable") });
                                    return;
                                }

                                const validFrom = moment(fsVoucher.data().validFrom.toDate());
                                const validTo = moment(fsVoucher.data().validTo.toDate());
                                const now = moment();
                                let currentRedeems = 0;
                                const maxRedeems = fsVoucher.data().maxRedeems;

                                const fsRedeemed = await firestore.collection("vouchers").doc(this.state.promoCode).collection('redeem').get();

                                for (const fsRedeem of fsRedeemed.docs) {
                                    currentRedeems++;
                                }


                                const active = now.isAfter(validFrom) && now.isBefore(validTo) && (currentRedeems < maxRedeems);

                                if (!active) {
                                    this.setState({ working: false, promoError: i18n("promoCode/expired") });
                                    return;
                                }
                            }

                        }

                        this.setState({ voucherType: fsVoucher.data().type, voucherAmount: fsVoucher.data().amount, displayPromoCode: false, working: false });

                    }


                })
                break;

            case "button_redirect":

                this.setState({ working: true }, () => {
                    window.location = "/my";
                });
                break;

            default:
                break;
        }
    }

    handleCheck(name, event) {
        this.setState({ [name]: event.target.checked });
    };

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleDrop2(acceptedFiles) {

        this.setState({ working: true }, () => {
            acceptedFiles.forEach(file => {

                const re = /(?:\.([^.]+))?$/;
                var extension = re.exec(file.name)[1];

                if (!isNullOrUndefined(extension)) {
                    const ref = storage.ref().child("profiles/" + firebase.auth().currentUser.uid + "." + Date.now() + "." + extension);
                    ref.put(file).then((snapshot) => {
                        snapshot.ref.getDownloadURL().then((downloadURL) => {
                            this.setState({ image: downloadURL, working: false });
                        });
                    });
                } else {
                    this.setState({ working: false });
                }


            });
        });

    };

    handleDrop(acceptedFiles) {

        this.setState({ workingUpload: true, working: true }, () => {
            acceptedFiles.forEach(file => {

                const re = /(?:\.([^.]+))?$/;
                const extension = re.exec(file.name)[1];


                if (!isNullOrUndefined(extension)) {

                    loadimage(file, (canvas) => {

                        canvas.toBlob((blob) => {

                            const ref = storage.ref().child("profiles/" + firebase.auth().currentUser.uid + "." + Date.now() + "." + extension);

                            ref.put(blob)
                                .then((snapshot) => {
                                    snapshot.ref.getDownloadURL().then((downloadURL) => {
                                        this.setState({ image: downloadURL, working: false, workingUpload: false });
                                    });
                                })
                                .catch((error) => {
                                    console.log("error");

                                    this.setState({ workingUpload: false, working: false, error: error.code });

                                });

                        });


                    }, { maxWidth: 400, orientation: true });


                } else {
                    this.setState({ workingUpload: false, working: false });
                }


            });
        });

    };

    captcha = (ref) => {

        if (isNullOrUndefined(window.recaptchaVerifier)) {
            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(ref, {
                'size': 'normal',
                'callback': (response) => {
                    this.setState({ recaptchaed: true });
                },
                'expired-callback': () => {
                    this.setState({ recaptchaed: false });

                }
            });


        }

        window.recaptchaVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
        });

    };

    handleSelectClick(target) {
        this.setState({ anchorEl: target });
    }

    handleSelectClose() {
        this.setState({ anchorEl: null });
    }

    handleSelect(name, value) {
        this.setState({ [name]: value, anchorEl: null });
    }

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    handleCCHightlight = (element) => {
        this.setState({ ccHighlight: element })
    };

    handleCCCompleted = (completed) => {
        this.setState({ ccCompleted: completed })
    };

    handleCCBrand = (brand) => {
        this.setState({ ccBrand: brand })
    };

    handleCCToken = (token) => {
        this.setState({ working: true, error: null }, () => {

            const data = {
                created: new Date(),
                updated: null,
                status: "created",
                uid: firebase.auth().currentUser.uid,
                token: token,
                error: null,
            };

            firestore.collection('stripeTokens').doc(token.id).set(data)
                .then(() => {
                    this.setState({ step: this.nextStep(), working: false, scrollToTop: true });
                })
                .catch((error) => {
                    this.setState({ working: false, systemError: error });
                });
        });
    };

    handlePopoverOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handlePopoverClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {

        if (!this.state.initialized) {
            return (<Timer />);
        }

        let dropzoneRef = null;

        const nf = new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        const remainingSeats = this.state.event ? (this.state.event.seats.max - this.state.event.currentSeats) : 1;
        const peopleList = [...Array(remainingSeats).keys()].map(i => i === 0 ? i18n("people/me") : `+${i}`);
        const isTakeAway = this.state.event ? this.state.event.types.includes("takeAway") : false;

        const baseAmount = !isNullOrUndefined(this.state.event) ? this.state.event.price.amount * (1 + this.state.people) : 0;
        const serviceAmount = !isNullOrUndefined(this.state.event) ? calcFee(baseAmount, this.state.event.fee) : 0;
        const sumAmount = baseAmount + serviceAmount;
        const stripeAmount = !isNullOrUndefined(this.state.event) ? calcStripeFee(sumAmount) : 0;



        let voucherAmount = 0;

        if (this.state.voucherType !== null && this.state.voucherAmount !== null) {

            if (this.state.voucherType === "percentage") {
                voucherAmount = sumAmount * this.state.voucherAmount;
            } else {
                voucherAmount = this.state.voucherAmount;
            }

            if (voucherAmount > serviceAmount - stripeAmount) {
                voucherAmount = serviceAmount - stripeAmount;
            }

        }

        const totalAmount = sumAmount - voucherAmount;


        return (

            <div>

                <Timer time={2}>

                    <Header>
                        <Progress
                            count={this.state.steps.length}
                            current={(this.indexOfStep())}
                            title={isNullOrUndefined(this.state.event) ? i18n("register/title") : i18nText(this.state.event.title)}
                            href={`/event/${this.state.id}`} /></Header>

                    {(this.state.step === "WELCOME") &&
                        <Wizard>
                            <Question>{isNullOrUndefined(this.state.forward) ? i18n("register/step/welcome") : i18n("register/step/welcome/forwarded")}</Question>
                            <ContentCSS>


                                <CenterCSS width={"40%"} marginTop={"40px"}>


                                    <StyledButton
                                        label={i18n("register/next")}
                                        onClick={this.handleClick.bind(this)}
                                        name="button_welcome"
                                        working={this.state.working}
                                        disabled={false} />


                                </CenterCSS>

                            </ContentCSS>
                        </Wizard>
                    }

                    {(this.state.step === "MOBILE") &&
                        <Wizard>
                            <Question>{i18n("register/step/mobile")}</Question>
                            <ContentCSS>

                                <CenterCSS width={"60%"}>
                                    <StyledTextField
                                        startAdornment={
                                            <IconButton onClick={event => {
                                                this.handleSelectClick(event.currentTarget);
                                            }}>
                                                <InputAdornment position="start">{this.state.mobileprefix}</InputAdornment>
                                            </IconButton>}
                                        mask={MobileMaskCustom}
                                        label={i18n("register/mobile")}
                                        name="mobile"
                                        placeholder={"1__ _ ___________"}
                                        value={this.state.mobile}
                                        disabled={this.state.working}
                                        onChange={this.handleChange.bind(this)}
                                        required={false}
                                        error={isNullOrUndefined(this.state.error) ? null : i18n("error/" + this.state.error)}
                                        autoFocus={true} />

                                    <HintCSS><StyledTooltip text={i18n("register/mobile/hint", {}, true)} /></HintCSS>

                                </CenterCSS>

                                <CenterCSS width={"60%"}>

                                    <CaptchaCSS>
                                        <div ref={(ref) => this.captcha(ref)}></div>
                                    </CaptchaCSS>

                                </CenterCSS>


                                <Menu id="render-countries-menu" anchorEl={this.state.anchorEl}
                                    open={Boolean(this.state.anchorEl)} onClose={this.handleSelectClose.bind(this)}>

                                    {masterdata.mobilePrefixCountries.map(value => (
                                        <MenuItem key={value} onClick={event => {
                                            this.handleSelect("mobileprefix", value)
                                        }}>{value}</MenuItem>

                                    ))}
                                </Menu>

                                <CenterCSS width={"40%"} marginTop={"40px"}>

                                    <StyledButton label={i18n("register/next")}
                                        onClick={this.handleClick.bind(this)}
                                        name="button_mobile"
                                        disabled={this.state.mobile === null || this.state.mobile.trim().length < 10 || !this.state.recaptchaed}
                                        working={this.state.working} />
                                </CenterCSS>

                            </ContentCSS>
                        </Wizard>
                    }

                    {(this.state.step === "CODE") &&
                        <Wizard>
                            <Question>{i18n("register/step/code", { mobile: `${this.state.mobileprefix} ${this.state.mobile}` })}</Question>
                            <ContentCSS>

                                <CenterCSS width={"50%"}>
                                    <StyledTextField
                                        label={i18n("register/code")}
                                        name="code"
                                        value={this.state.code}
                                        disabled={this.state.working}
                                        onChange={this.handleChange.bind(this)}
                                        error={isNullOrUndefined(this.state.error) ? null : i18n("error/" + this.state.error)}
                                        required={false} />

                                    <HintCSS><StyledTooltip text={i18n("register/code/hint", {}, true)} /></HintCSS>

                                </CenterCSS>

                                <CenterCSS width={"40%"} marginTop={"40px"}>

                                    <StyledButton label={i18n("register/next")}
                                        onClick={this.handleClick.bind(this)}
                                        name="button_code"
                                        working={this.state.working}
                                        disabled={this.state.code === null || this.state.code.trim().length < 6} />
                                </CenterCSS>

                                <CenterCSS width={"40%"} marginTop={"24px"}>


                                    <StyledFlatButton
                                        label={i18n("register/back")}
                                        onClick={this.handleClick.bind(this)}
                                        name="button_back" />


                                </CenterCSS>

                            </ContentCSS>
                        </Wizard>
                    }

                    {(this.state.step === "EMAIL") &&
                        <Wizard>
                            <Question>{i18n("register/step/email")}</Question>
                            <ContentCSS>

                                <CenterCSS width={"60%"}>
                                    <StyledTextField
                                        label={i18n("register/email")}
                                        name="email"
                                        value={this.state.email}
                                        mask={EMailMaskCustom}
                                        disabled={this.state.working}
                                        error={isNullOrUndefined(this.state.error) ? null : i18n("error/" + this.state.error)}
                                        placeholder={"Name@domain.de"}
                                        onChange={this.handleChange.bind(this)}
                                        required={false}
                                        autoFocus={true} />

                                    <HintCSS><StyledTooltip text={i18n("register/email/hint", {}, true)} /></HintCSS>

                                </CenterCSS>

                                <CenterCSS width={"40%"} marginTop={"40px"}>

                                    <StyledButton label={i18n("register/next")}
                                        onClick={this.handleClick.bind(this)}
                                        name="button_email"
                                        working={this.state.working}
                                        disabled={this.state.email === null || this.state.email.trim().length === 0 || !isEMailValid(this.state.email)} />
                                </CenterCSS>

                            </ContentCSS>
                        </Wizard>
                    }


                    {(this.state.step === "CONFIRM") &&
                        <Wizard>
                            <Question>{firebase.auth().currentUser.emailVerified ? i18n("register/step/confirmed", { email: firebase.auth().currentUser.email }) : i18n("register/step/confirm", { email: firebase.auth().currentUser.email })}</Question>
                            <ContentCSS>


                                <CenterCSS width={"40%"} marginTop={"40px"}>


                                    {firebase.auth().currentUser.emailVerified &&
                                        <StyledButton
                                            label={i18n("register/next")}
                                            onClick={this.handleClick.bind(this)}
                                            name="button_next"
                                            working={this.state.working}
                                            disabled={false} />
                                    }

                                    {!firebase.auth().currentUser.emailVerified &&
                                        <StyledButton
                                            label={i18n("register/resend")}
                                            onClick={this.handleClick.bind(this)}
                                            name="button_resend"
                                            working={this.state.working}
                                            disabled={false} />
                                    }

                                </CenterCSS>

                                {!firebase.auth().currentUser.emailVerified &&
                                    <CenterCSS width={"40%"} marginTop={"40px"}>

                                        <StyledFlatButton
                                            label={i18n("register/back")}
                                            onClick={this.handleClick.bind(this)}
                                            name="button_back" />

                                    </CenterCSS>
                                }
                            </ContentCSS>
                        </Wizard>
                    }

                    {(this.state.step === "PASSWORD") &&
                        <Wizard>
                            <Question>{i18n("register/step/password", { email: this.state.email })}</Question>
                            <ContentCSS>

                                <CenterCSS width={"60%"}>
                                    <StyledTextField
                                        label={i18n("register/password")}
                                        name="password"
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        value={this.state.password}
                                        onChange={this.handleChange.bind(this)}
                                        disabled={this.state.working}
                                        error={isNullOrUndefined(this.state.error) ? null : i18n("error/" + this.state.error)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                    onMouseDown={this.handleMouseDownPassword}
                                                >
                                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        required={false}
                                        autoFocus={true} />

                                    <HintCSS><StyledTooltip text={i18n("register/password/hint", {}, true)} /></HintCSS>

                                </CenterCSS>

                                <CenterCSS width={"40%"} marginTop={"40px"}>

                                    <StyledButton label={i18n("register/next")}
                                        onClick={this.handleClick.bind(this)}
                                        name="button_password"
                                        working={this.state.working}
                                        disabled={this.state.password === null || this.state.password.trim().length === 0} />
                                </CenterCSS>

                                <CenterCSS width={"40%"} marginTop={"24px"}>


                                    <StyledFlatButton
                                        label={i18n("register/back")}
                                        onClick={this.handleClick.bind(this)}
                                        name="button_back" />


                                </CenterCSS>

                            </ContentCSS>
                        </Wizard>
                    }

                    {(this.state.step === "GUEST") &&
                        <Wizard>
                            <Question>{i18n("register/step/who")}</Question>
                            <ContentCSS>

                                <Step2GridCSS>
                                    <ImageCSS onClick={() => {
                                        dropzoneRef.open()
                                    }}>


                                        <Dropzone className="dragAndDropArea"
                                            onDrop={this.handleDrop.bind(this)}
                                            accept="image/*"
                                            multiple={true}
                                            ref={(node) => {
                                                dropzoneRef = node;
                                            }}>

                                            {(this.state.image === null) && !this.state.workingUpload &&
                                                <DropCSS>
                                                    <DropTextCSS>
                                                        {i18n("register/dropimage")}
                                                    </DropTextCSS>
                                                </DropCSS>
                                            }

                                            {this.state.workingUpload &&
                                                <StyledProgressCSS>
                                                    <StyledProgress />
                                                </StyledProgressCSS>
                                            }

                                            {(this.state.image != null) && !this.state.workingUpload &&
                                                <DropPreviewCSS src={this.state.image} />
                                            }

                                        </Dropzone>


                                    </ImageCSS>
                                    <FirstNameCSS>
                                        <StyledTextField
                                            label={i18n("register/firstname")}
                                            name="firstname"
                                            value={this.state.firstname}
                                            onChange={this.handleChange.bind(this)}
                                            disabled={this.state.working}
                                            required={false} />
                                    </FirstNameCSS>
                                    <LastNameCSS>
                                        <StyledTextField
                                            label={i18n("register/lastname")}
                                            name="lastname"
                                            value={this.state.lastname}
                                            onChange={this.handleChange.bind(this)}
                                            disabled={this.state.working}
                                            required={false} />
                                    </LastNameCSS>
                                    <BirthdayCSS>
                                        <StyledTextField
                                            label={i18n("register/birthday")}
                                            name="birthdayText"
                                            value={this.state.birthdayText}
                                            disabled={this.state.working}
                                            placeholder={"TT.MM.JJJJ"}
                                            onChange={this.handleDateChange.bind(this)}
                                            mask={DateMaskCustom}
                                            error={isNullOrUndefined(this.state.dateError) ? null : i18n("error/" + this.state.dateError)}
                                            required={false} />

                                        <HintTextCSS>{i18n("register/birthday/hint")}</HintTextCSS>

                                    </BirthdayCSS>

                                    {this.state.id &&
                                        <PeopleCSS>
                                            <ScrollSelect
                                                values={peopleList}
                                                scrollAfter={2}
                                                minVisible={4}
                                                boxSize={70}
                                                height={"70px"}
                                                disabled={this.state.working}
                                                selected={this.state.people}
                                                onChange={this.handleChangeSelected.bind(this)}
                                                name={"people"}
                                            />


                                        </PeopleCSS>
                                    }

                                    <ButtonCSS>
                                        <StyledButton label={i18n("register/next")}
                                            onClick={this.handleClick.bind(this)}
                                            name="button_guest"
                                            working={this.state.working}
                                            disabled={this.state.firstname === null || this.state.lastname === null ||
                                                !isNullOrUndefined(this.state.dateError)} />

                                    </ButtonCSS>
                                </Step2GridCSS>

                            </ContentCSS>
                        </Wizard>
                    }

                    {(this.state.step === "CC") &&
                        <Wizard>
                            <Question>{i18n("register/step/stripe", { name: this.state.firstname })}</Question>
                            <ContentCSS>

                                {this.state.stripeCustomers.length === 0 ? (


                                    <Step4GridCSS>
                                        <CCCSS>
                                            <Card
                                                number={"1234 5678 9012 1234"}
                                                month={"01"}
                                                year={"20"}
                                                type={this.state.ccBrand}
                                                cvc={"123"}
                                                highlighted={this.state.ccHighlight} />
                                        </CCCSS>
                                        <CCForm>
                                            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
                                                <Elements>
                                                    <CC
                                                        onHighlight={this.handleCCHightlight}
                                                        onCompletedChange={this.handleCCCompleted}
                                                        onBrandChange={this.handleCCBrand}
                                                        onToken={this.handleCCToken}
                                                        name={`${this.state.firstname} ${this.state.lastname}`}
                                                        working={this.state.working}
                                                        label={i18n("register/next")} />

                                                </Elements>
                                            </StripeProvider>
                                        </CCForm>

                                        <ButtonCSS>

                                            <StyledFlatButton
                                                label={i18n("register/back")}
                                                onClick={this.handleClick.bind(this)}
                                                name="button_back" />
                                        </ButtonCSS>

                                    </Step4GridCSS>
                                ) : (

                                        <div>

                                            <CenterCSS width={"60%"}>
                                                <Card
                                                    number={`**** **** **** ${this.state.stripeCustomers[0].data.sources.data[0].last4}`}
                                                    month={`${this.state.stripeCustomers[0].data.sources.data[0].exp_month}`.padStart(2, '0')}
                                                    year={`${this.state.stripeCustomers[0].data.sources.data[0].exp_year}`.slice(-2).padStart(2, '0')}
                                                    type={this.state.stripeCustomers[0].data.sources.data[0].brand.toLowerCase()}
                                                    cvc={"123"}
                                                    highlighted={this.state.ccHighlight} />
                                            </CenterCSS>


                                            <CenterCSS width={"40%"} marginTop={"40px"}>

                                                <StyledButton label={i18n("register/next")}
                                                    onClick={this.handleClick.bind(this)}
                                                    name="button_cc"
                                                    working={this.state.working}
                                                    disabled={false} />
                                            </CenterCSS>

                                            <CenterCSS width={"40%"} marginTop={"24px"}>


                                                <StyledFlatButton
                                                    label={i18n("register/back")}
                                                    onClick={this.handleClick.bind(this)}
                                                    name="button_back" />


                                            </CenterCSS>
                                        </div>


                                    )
                                }


                            </ContentCSS>
                        </Wizard>
                    }

                    {(this.state.step === "BOOK") &&
                        <Wizard>
                            <Question>{i18n("register/step/book", { name: this.state.firstname })}</Question>

                            <ContentCSS>

                                <Step5GridCSS>
                                    <TitleCSS>
                                        <TitleBlackCSS>{i18nText(this.state.event.title)}</TitleBlackCSS>
                                        <TitleGreenCSS><Moment
                                            fromNow>{this.state.event.start.toDate()}</Moment></TitleGreenCSS>
                                    </TitleCSS>
                                    <EventCSS>
                                        <EventBoxCSS>
                                            <EventImageCSS src={this.state.event.images[this.state.event.mainImageIndex]} />
                                            <EventInfoCSS>
                                                <EventInfoTitleCSS>
                                                    {i18n("register/eventinfo/title",
                                                        {
                                                            city: i18nText(this.state.event.address.city),
                                                            date: moment(this.state.event.date.toDate()).format("DD. MMM"),
                                                            start: moment(this.state.event.start.toDate()).format("HH:mm"),
                                                            end: moment(this.state.event.end.toDate()).format("HH:mm")
                                                        })}
                                                </EventInfoTitleCSS>
                                                <EventInfoProfileCSS
                                                    src={this.state.image} />
                                                <EventInfoInfoCSS>
                                                    <EventInfoInfo1CSS>{`${this.state.firstname} ${this.state.lastname} ${(this.state.people > 0) ? "+" + this.state.people : ""}`}</EventInfoInfo1CSS>
                                                    <EventInfoInfo2CSS>{this.state.email} </EventInfoInfo2CSS>
                                                </EventInfoInfoCSS>

                                                <EventInfoPriceCSS>
                                                    <EventInfoPriceAmountCSS>
                                                        {nf.format(totalAmount)}
                                                    </EventInfoPriceAmountCSS>
                                                    <EventInfoPriceCurrencyCSS>
                                                        {i18nConfiguration('currency', this.state.event.price.currency)}
                                                    </EventInfoPriceCurrencyCSS>
                                                    <InfoCSS onMouseEnter={this.handlePopoverOpen}
                                                        onMouseLeave={this.handlePopoverClose}>
                                                        <img src={asset_info} />
                                                    </InfoCSS>
                                                </EventInfoPriceCSS>
                                            </EventInfoCSS>

                                        </EventBoxCSS>
                                    </EventCSS>
                                    <PaymentCSS>
                                        {this.state.stripeCustomers.length === 0 ? (
                                            <StyledProgress />
                                        ) : (
                                                this.state.displayPromoCode === false ? (
                                                    <Card
                                                        number={`**** **** **** ${this.state.stripeCustomers[0].data.sources.data[0].last4}`}
                                                        month={`${this.state.stripeCustomers[0].data.sources.data[0].exp_month}`.padStart(2, '0')}
                                                        year={`${this.state.stripeCustomers[0].data.sources.data[0].exp_year}`.slice(-2).padStart(2, '0')}
                                                        type={this.state.stripeCustomers[0].data.sources.data[0].brand.toLowerCase()}
                                                        cvc={"123"}
                                                        highlighted={this.state.ccHighlight} />
                                                ) : (<div />)
                                            )
                                        }

                                    </PaymentCSS>
                                    <LegalCSS>

                                        {!this.state.displayPromoCode &&
                                            <React.Fragment>
                                                {isTakeAway &&
                                                    <StyledCheckBox
                                                        checked={this.state.takeAway}
                                                        onChange={this.handleCheck.bind(this)}
                                                        value={'takeAway'}
                                                        marginBottom={'8px'}
                                                        disabled={this.props.working}
                                                        label={
                                                            <LegalLabelCSS>{i18n('register/takeAway')}</LegalLabelCSS>}
                                                    />
                                                }

                                                <StyledCheckBox
                                                    checked={this.state.legal}
                                                    onChange={this.handleCheck.bind(this)}
                                                    value={'legal'}
                                                    marginBottom={'8px'}
                                                    disabled={this.state.working}
                                                    label={
                                                        <LegalLabelCSS>{i18n('register/legal')}</LegalLabelCSS>}
                                                />
                                            </React.Fragment>
                                        }
                                    </LegalCSS>
                                    <PromoCodeCSS>
                                        {!this.state.displayPromoCode &&
                                            <StyledFlatButton label={i18n("register/promoCode")}
                                                onClick={this.handleClick.bind(this)}
                                                name="button_promoCode"
                                                disabled={false} />
                                        }

                                        {this.state.displayPromoCode &&
                                            <StyledTextField
                                                label={i18n("register/promoCodeLabel")}
                                                name="promoCode"
                                                value={this.state.promoCode}
                                                error={this.state.promoError}
                                                disabled={this.state.working}
                                                onChange={this.handleChange.bind(this)}
                                                required={false} />
                                        }

                                        {this.state.displayPromoCode &&
                                            <StyledFlatButton label={i18n("register/cancelPromoCode")}
                                                onClick={this.handleClick.bind(this)}
                                                name="button_cancelPromoCode"
                                                disabled={false} />
                                        }
                                    </PromoCodeCSS>
                                    <ButtonCSS>


                                        {!this.state.displayPromoCode &&

                                            <StyledButton label={i18n("register/confirm")}
                                                onClick={this.handleClick.bind(this)}
                                                name="button_book"
                                                working={this.state.working}
                                                disabled={!this.state.legal || this.state.stripeCustomers.length === 0} />
                                        }

                                        {this.state.displayPromoCode &&

                                            <StyledButton label={i18n("register/redeemCode")}
                                                onClick={this.handleClick.bind(this)}
                                                name="button_redeemCode"
                                                working={this.state.working}
                                                disabled={this.state.promoCode === null || this.state.promoCode.trim() === ""} />
                                        }
                                    </ButtonCSS>
                                </Step5GridCSS>

                            </ContentCSS>
                        </Wizard>
                    }

                    {(this.state.step === "DONE") &&
                        <Wizard>
                            <Question>{i18n("register/step/done", { firstname: this.state.firstname })}</Question>
                            <ContentCSS>


                                <CenterCSS width={"40%"} marginTop={"40px"}>


                                    <StyledButton
                                        label={i18n("register/redirect")}
                                        onClick={this.handleClick.bind(this)}
                                        name="button_redirect"
                                        working={this.state.working}
                                        disabled={false} />


                                </CenterCSS>

                            </ContentCSS>
                        </Wizard>
                    }

                    {(this.state.step === "BOOKED") &&
                        <Wizard>
                            <Question>{i18n("register/step/booked", { firstname: this.state.firstname })}</Question>
                            <ContentCSS>


                                <CenterCSS width={"40%"} marginTop={"40px"}>


                                    <StyledButton
                                        label={i18n("register/redirect")}
                                        onClick={this.handleClick.bind(this)}
                                        name="button_redirect"
                                        working={this.state.working}
                                        disabled={false} />


                                </CenterCSS>

                            </ContentCSS>
                        </Wizard>
                    }


                    <ErrorSnackbar message={this.state.systemError ? this.state.systemError.message : "Technischer Fehler"}
                        open={!isNullOrUndefined(this.state.systemError)} onClose={(event) => {
                            this.setState({ systemError: null })
                        }} />

                    {!isNullOrUndefined(this.state.event) &&
                        <MouseOverPopover open={Boolean(this.state.anchorEl)} anchorEl={this.state.anchorEl}>
                            <FinancialWrapperCSS>
                                <FinancialHeaderCSS>{i18n("price/financial/header")}</FinancialHeaderCSS>
                                <FinancialLineCSS>
                                    <FinancialTextCSS>{i18n("price/financial/basePrice",
                                        {
                                            people: (1 + this.state.people),
                                            amount: nf.format(this.state.event.price.amount),
                                            currency: i18nConfiguration('currency', this.state.event.price.currency)
                                        })}</FinancialTextCSS>
                                    <FinancialTextCSS>{`${nf.format(baseAmount)} ${i18nConfiguration('currency', this.state.event.price.currency)}`}</FinancialTextCSS>
                                </FinancialLineCSS>

                                <FinancialLineCSS>
                                    <FinancialTextCSS>{i18n("price/financial/servicePrice")}</FinancialTextCSS>
                                    <FinancialTextCSS>{`${nf.format(serviceAmount)} ${i18nConfiguration('currency', this.state.event.price.currency)}`}</FinancialTextCSS>
                                </FinancialLineCSS>
                                <FinancialLineCSS>
                                    <FinancialBoldTextCSS>{i18n("price/financial/sum")}</FinancialBoldTextCSS>
                                    <FinancialBoldTextCSS>{`${nf.format(sumAmount)} ${i18nConfiguration('currency', this.state.event.price.currency)}`}</FinancialBoldTextCSS>
                                </FinancialLineCSS>
                                <FinancialLineCSS>
                                    <FinancialTextCSS>{i18n("price/financial/voucher")}</FinancialTextCSS>
                                    <FinancialTextCSS>{voucherAmount === 0 ? "./." : `${nf.format(voucherAmount)} ${i18nConfiguration('currency', this.state.event.price.currency)}`}</FinancialTextCSS>
                                </FinancialLineCSS>
                                <hr width={"100%"} />
                                <FinancialLineCSS>
                                    <FinancialBoldTextCSS>{i18n("price/financial/total")}</FinancialBoldTextCSS>
                                    <FinancialBoldTextCSS>{`${nf.format(totalAmount)} ${i18nConfiguration('currency', this.state.event.price.currency)}`}</FinancialBoldTextCSS>
                                </FinancialLineCSS>
                                <hr width={"100%"} />
                                <FinancialLineCSS>
                                    <FinancialTextCSS>{i18n("price/financial/hints/text")}</FinancialTextCSS>
                                </FinancialLineCSS>
                            </FinancialWrapperCSS>
                        </MouseOverPopover>
                    }

                    <Footer />
                </Timer>
            </div>
        );
    }
}

export default withDebug()(Register);
