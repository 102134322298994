import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';
import MUIDataTable from "mui-datatables";
import firebaseApp from "../common/firebase";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";
import moment from "moment";
import {isNullOrUndefined} from "util";
import Avatar from "@material-ui/core/Avatar/Avatar";
import CheckedIcon from "@material-ui/icons/CheckBox";
import UncheckedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import PauseIcon from "@material-ui/icons/Pause";
import StarIcon from "@material-ui/icons/Star";
import EmptyStarIcon from "@material-ui/icons/StarBorder";
import PlayButton from "@material-ui/icons/PlayArrow";
import Chip from "@material-ui/core/Chip/Chip";
import placeholder from "../assets/placeholder.svg";
import FlagIcon from "@material-ui/icons/Flag";
import RefreshIcon from "@material-ui/icons/Refresh";


const firestore = firebaseApp.firestore();


const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
    chip: {
        margin: theme.spacing.unit,
    },
});

class Users extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            elements: [],
        };
    }


    UNSAFE_componentWillMount() {
        this.reload();

    }

    reload = () => {
        try {
            this.setState({loading: true}, async () => {

                let fsElements = await firestore.collection("profiles").orderBy("created", "desc").get();

                let elements = [];

                for (const fsElement of fsElements.docs) {

                    const {created, updated, birthday, ...other} = fsElement.data();

                    // custom reload
                    let fsHostScore = await firestore.collection("hostScores").doc(fsElement.id).get();
                    let fsGuestScore = await firestore.collection("guestScores").doc(fsElement.id).get();
                    let fsRoles = await firestore.collection("roles").doc(fsElement.id).get();

                    const hostScore = fsHostScore.exists ? fsHostScore.data().score : null;
                    const guestScore = fsGuestScore.exists ? fsGuestScore.data().score : null;
                    const roles = fsRoles.exists ? fsRoles.data() : null;

                    const element = {
                        created: created.toDate(),
                        updated: updated ? updated.toDate() : null,
                        birthday: birthday.toDate(),
                        hostScore: hostScore,
                        guestScore: guestScore,
                        roles: roles,
                        ...other,
                        id: fsElement.id
                    };

                    elements.push(element)
                }

                this.setState({elements: elements, loading: false});

            })
        } catch (error) {
            console.error(error);
            this.setState({loading: false});
        }
    };

    handlePromote = (selectedRows) => {


        this.setState({working: true}, async () => {
            try {

                const batch = firestore.batch();

                for (const selectedRow of selectedRows.data) {
                    batch.set(
                        firestore.collection("roles").doc(this.state.elements[selectedRow.dataIndex].id),
                        {
                            vip: true,
                            changed: new Date(),
                        }, {merge: true});
                }

                await batch.commit();

                this.setState({working: false}, () => {
                    this.reload();
                });
            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });

    };

    handleDemote = (selectedRows) => {
        this.setState({working: true}, async () => {
            try {

                const batch = firestore.batch();

                for (const selectedRow of selectedRows.data) {
                    batch.set(
                        firestore.collection("roles").doc(this.state.elements[selectedRow.dataIndex].id),
                        {
                            vip: false,
                            changed: new Date(),
                        }, {merge: true});
                }

                await batch.commit();

                this.setState({working: false}, () => {
                    this.reload();
                });
            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });
    };

    handleBlock = (selectedRows) => {
        this.setState({working: true}, async () => {
            try {

                const batch = firestore.batch();

                for (const selectedRow of selectedRows.data) {
                    batch.set(
                        firestore.collection("roles").doc(this.state.elements[selectedRow.dataIndex].id),
                        {
                            blocked: true,
                            changed: new Date(),
                        }, {merge: true});
                }

                await batch.commit();

                this.setState({working: false}, () => {
                    this.reload();
                });
            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });
    };

    handleUnblock = (selectedRows) => {
        this.setState({working: true}, async () => {
            try {

                const batch = firestore.batch();

                for (const selectedRow of selectedRows.data) {
                    batch.set(
                        firestore.collection("roles").doc(this.state.elements[selectedRow.dataIndex].id),
                        {
                            blocked: false,
                            changed: new Date(),
                        }, {merge: true});
                }

                await batch.commit();

                this.setState({working: false}, () => {
                    this.reload();
                });
            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });
    };


    render() {
        const {classes} = this.props;

        const columns = [
            {

                name: "Blocked",
                field: "blocked",
                options: {
                    filter: true,
                    sort: true,
                    display: "false",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {(value === true) ? (
                                    <Tooltip title={"User is blocked"}>
                                        <PauseIcon/>
                                    </Tooltip>) : (
                                    <Tooltip title={"User is not blocked"}>
                                        <PlayButton/>
                                    </Tooltip>)}
                            </React.Fragment>

                        );
                    },

                }
            },
            {

                name: "Flagged",
                field: "flagged",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {(value === true) &&
                                <Tooltip title={"User is flagged"}>
                                    <FlagIcon color={"secondary"}/>
                                </Tooltip>}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Vip",
                field: "vip",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {(value === true) ? (
                                    <Tooltip title={"User is VIP"}>
                                        <StarIcon/>
                                    </Tooltip>) : (
                                    <Tooltip title={"User is no VIP"}>
                                        <EmptyStarIcon/>
                                    </Tooltip>)}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Uid",
                field: "uid",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {

                name: "Customer Number",
                field: "number",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {

                name: "Image",
                field: "image",
                options: {
                    display: "false",
                    download: false,
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (


                            <Tooltip title={value.alt}>

                                {value.src ? (
                                    <Avatar
                                        alt={value.alt}
                                        src={value.src}
                                    />
                                ) : (
                                    <Avatar
                                        alt={value.alt}
                                        src={placeholder}
                                    />
                                )}

                            </Tooltip>

                        );
                    },
                },

            },
            {

                name: "Created",
                field: "created",
                options: {
                    filter: false,
                    sort: false,
                    display: "false",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {moment(value).format("YYYY-MM-DD, HH:mm:ss")}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Birthday",
                field: "birthday",
                options: {
                    filter: false,
                    sort: false,
                    display: "false",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {moment(value).format("YYYY-MM-DD")}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "E-Mail",
                field: "email",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",

                }
            },
            {

                name: "Mobile",
                field: "mobile",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Firstname",
                field: "firstname",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {

                name: "Lastname",
                field: "lastname",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Language",
                field: "language",
                options: {
                    filter: true,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Country",
                field: "country",
                options: {
                    filter: true,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "ZIP",
                field: "postalCode",
                options: {
                    filter: true,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Street",
                field: "street",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Guest Score",
                field: "guestScore",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Host Score",
                field: "hostScore",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "About",
                field: "about",
                options: {
                    filter: false,
                    sort: false,
                    display: "false",
                }
            },
            {

                name: "Tags",
                field: "tags",
                options: {
                    filter: false,
                    sort: false,
                    display: "false",
                    customBodyRender: (values, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>

                                {values.length === 0 ? (
                                    <div>-</div>
                                ) : (
                                    values.map(value =>
                                        <Chip key={value.label} label={value.label} className={classes.chip}
                                              variant="outlined"/>
                                    ))
                                }
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Guest",
                field: "guest",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {(value === true) ? (<CheckedIcon/>) : (<UncheckedIcon/>)}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Host",
                field: "host",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {(value === true) ? (<CheckedIcon/>) : (<UncheckedIcon/>)}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "City",
                field: "city",
                options: {
                    filter: true,
                    sort: true,
                }
            },
        ];


        const options = {
            filter: true,
            filterType: 'dropdown',
            selectableRows: 'multiple',
            responsive: 'scrollFullHeight',
            customToolbar: () =>
                <Tooltip title={"Reload Data"}>
                    <IconButton className={classes.iconButton}
                                onClick={this.reload}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>,
            customToolbarSelect: (selectedRows) =>
                <StyledCustomToolbarSelect
                    selectedRows={selectedRows}
                    onBlock={this.handleBlock}
                    onUnblock={this.handleUnblock}
                    onPromote={this.handlePromote}
                    onDemote={this.handleDemote}/>
        };

        const data = this.state.elements.map(element => {

            const line = [];

            for (const column of columns) {

                let field = element[column.field];

                if (column.field === "flagged") {

                    const isHost = !isNullOrUndefined(element.roles) && element.roles.host === true;
                    const isGuest = !isNullOrUndefined(element.roles) && element.roles.guest === true;
                    field = (isGuest && element.guestScore < 500) || (isHost && element.hostScore < 500);
                }


                if (column.field === "mobile") {
                    field = `${element.mobileprefix} ${element.mobile}`
                }

                if (column.field === "image") {
                    field = {src: element.image, alt: `${element.firstname} ${element.lastname}`}
                }

                if (column.field === "guest") {
                    field = !isNullOrUndefined(element.roles) && element.roles.guest === true;
                }

                if (column.field === "vip") {
                    field = !isNullOrUndefined(element.roles) && element.roles.vip === true;
                }

                if (column.field === "number") {
                    field = !isNullOrUndefined(element.roles) ? element.roles.number : -1;
                }

                if (column.field === "blocked") {
                    field = !isNullOrUndefined(element.roles) && element.roles.blocked === true;
                }

                if (column.field === "host") {
                    field = !isNullOrUndefined(element.roles) && element.roles.host === true;
                }

                if (column.field === "country") {
                    field = !isNullOrUndefined(element.address) ? element.address.country : null;
                }

                if (column.field === "postalCode") {
                    field = !isNullOrUndefined(element.address) ? element.address.postalCode : null;
                }

                if (column.field === "city") {
                    field = !isNullOrUndefined(element.address) && !isNullOrUndefined(element.address.city[element.language]) ? element.address.city[element.language] : null;
                }

                if (column.field === "about") {
                    field = !isNullOrUndefined(element.about) && !isNullOrUndefined(element.about[element.language]) ? element.about[element.language] : null;
                }

                if (column.field === "street") {
                    field = !isNullOrUndefined(element.address) ? element.address.street : null;
                }

                if (isNullOrUndefined(field)) {
                    field = "-";
                }

                line.push(field);
            }

            return line;
        });

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page}) => (
                        <React.Fragment>

                            {this.state.loading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress className={classes.progress} size={50}/>
                                    </Grid>
                                ) :
                                (
                                    <MUIDataTable
                                        columns={columns}
                                        data={data}
                                        title={page.title}
                                        options={options}
                                    />
                                )}

                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

Users.propTypes = {
    classes: PropTypes.object.isRequired,
};

const defaultToolbarSelectStyles = {
    iconButton: {
        marginRight: "24px",
        top: "50%",
        display: "inline-block",
        position: "relative",
        transform: "translateY(-50%)",
    },
};

class CustomToolbarSelect extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <div className={"custom-toolbar-select"}>

                <Tooltip title={"Promote to VIP"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onPromote(this.props.selectedRows)}>
                        <StarIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Demote from VIP"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onDemote(this.props.selectedRows)}>
                        <EmptyStarIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Block user"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onBlock(this.props.selectedRows)}>
                        <PauseIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Unblock user"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onUnblock(this.props.selectedRows)}>
                        <PlayButton/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

}

const StyledCustomToolbarSelect = withStyles(defaultToolbarSelectStyles, {name: "CustomToolbarSelect"})(CustomToolbarSelect);


export default withStyles(styles, {withTheme: true})(Users)