import React, {Component} from 'react';
import {
    withRouter
} from 'react-router-dom'

import {initialize} from "../common/configuration";
import * as assetsService from "../common/assets";


import Footer from "../components/Footer";
import Header from "../components/Header";
import {VideoHero, HeroBlock, HeroWrapper} from "../components/VideoHero";
import LoginForm from "../components/LoginForm";
import {i18n, i18nChange} from "../common/I18n";

import asset_LogoLarge from "../assets/logo_large.svg";

import money from "../assets/money.svg";
import exclusive from "../assets/exclusive.svg";
import passion from "../assets/passion.svg";
import easy from "../assets/easy.svg";
import hero from "../assets/hero2.jpg";
import styled from 'styled-components';
import firebaseApp, {trackUserPresence} from "../common/firebase";
import {Redirect} from "react-router";

import 'firebase/firestore';
import StyledWhiteButton from "../components/StyledWhiteButton";
import StyledInvertedWhiteButton from "../components/StyledInvertedWhiteButton";
import Button from "@material-ui/core/Button/Button";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import StyledButton from "../components/StyledButton";
import * as ReactDOM from "react-dom";

import * as firebase from "firebase";
import 'firebase/firestore';
import 'firebase/storage';
import Timer from "../components/Timer";
import {isNullOrUndefined} from "util";
import withDebug from "../common/withDebug";

firebaseApp.auth().useDeviceLanguage();
const firestore = firebaseApp.firestore();


const WrapperCSS = styled.div`
    padding-left: 10%;
    padding-right: 10%;
`;

const TitleCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 44px;
@media screen and (max-width: 800px) {
font-size: 32px;
margin-top: 124px;

}
color: #333333;
letter-spacing: -0.54px;
text-align: center;
margin-top: 70px;
`;

const ButtonCSS = styled.div`
opacity: 0.8;
@media screen and (max-width: 800px) {
margin-top: 124px;
margin-bottom: 124px;

}
text-align: center;
margin-top: 70px;
margin-bottom: 70px;
`;

const ClaimCSS = styled.div`
display: block;
margin-top:20px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 16px;
color: #818181;
letter-spacing: 0;
text-align: center;
line-height: 26px;
width:100%;
@media screen and (max-width: 800px) {
width:100%;
}

`;


const RowCSS = styled.div`
  margin-top: 42px;

display: grid;
  grid-template-columns: 20% 28% 4% 20% 28%;
  
    @media screen and (max-width: 800px) {
  grid-template-columns: 40% 60%;
      grid-template-rows: auto auto;
      grid-column-gap: 5px;
      grid-row-gap: 42px;
}

`;

const Image1CSS = styled.img`
grid-column-start: 1;
grid-column-end: 2;
   @media screen and (max-width: 800px) {
grid-column-start: 1;
grid-column-end: 2;
grid-row-start: 1;
grid-row-end: 2;
}
width: 90%;
box-shadow: 0 0 24px 0 rgba(0,0,0,0.04);
border-radius: 100%;
z-index: 10;
`;

const Text1CSS = styled.div`
grid-column-start: 2;
grid-column-end: 3;
   @media screen and (max-width: 800px) {
grid-column-start: 2;
grid-column-end: 3;
grid-row-start: 1;
grid-row-end: 2;
}

`;

const Image2CSS = styled.img`
grid-column-start: 4;
grid-column-end: 5;
   @media screen and (max-width: 800px) {
grid-column-start: 1;
grid-column-end: 2;
grid-row-start: 2;
grid-row-end: 3;
}
width: 90%;
box-shadow: 0 0 24px 0 rgba(0,0,0,0.04);
border-radius: 100%;
z-index: 10;

`;

const Text2CSS = styled.div`
grid-column-start: 5;
grid-column-end: 6;
   @media screen and (max-width: 800px) {
grid-column-start: 2;
grid-column-end: 3;
grid-row-start: 2;
grid-row-end: 3;
}
`;

const SmallTitleCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
`;

const ParagraphCSS = styled.div`
margin-top: 12px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 14px;
color: #818181;
letter-spacing: 0;
line-height: 26px;
`;

const LinksCSS = styled.div`
display: flex;
justify-content: flex-end;
font-family: TruenoLight;
font-size: 16px;
color: #FFFFFF;
letter-spacing: 0;
line-height: 26px;
@media screen and (max-width: 800px) {
display: none;
}
`;

const Link = styled.div`
    margin-left: 24px;
    min-width: 100px;
    &:hover {
cursor: pointer;
font-family: TruenoRegular;
}
&:active {
cursor: pointer;
font-family: TruenoLight;
}
`;

const HeroElements = styled.div`
display: flex;
justify-content: flex-end;
flex-direction: column;
height: 550px;
weight: 100%;
`;

const HeroText = styled.div`
font-family: TruenoBold;
font-size: 48px;
color: #FFFFFF;
letter-spacing: 2px;
line-height: 52px;
text-align: left;
margin-bottom: 32px;
width: 80%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 800px) {
font-size: 34px;
text-align: center;
line-height: 56px;
margin-bottom: 48px;
}
`;

const HeroButtons = styled.div`
display: flex;
justify-content: flex-start;
margin-bottom: 32px;
width: 80%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 800px) {
justify-content: center;
flex-direction: column;
width: 80%;
}
`;

const RightButtonCSS = styled.div`
margin-left: 24px;
width:90%;
@media screen and (max-width: 800px) {
margin-left: 0px;
margin-top: 24px;
width:100%;
}
`;

const MobileButtonCSS = styled.div`
display: none;
@media screen and (max-width: 800px) {
display: block;
margin-left: 0px;
margin-top: 24px;
}
`;


const theme = createMuiTheme({

    palette: {
        primary: {
            main: "#FF6344",
            light: "#FF6344"
        },
    },

    typography: {
        useNextVariants: true,
    },


    overrides: {
        typography: {
            fontFamily:
                'TruenoLight'
        },

        MuiButton: {
            root: {
                textTransform: 'uppercase',
                fontSize: '18px',
                letterSpacing: 2,

            }
        },
    }
});


class Host extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            roles: null,
            username: null,
            password: null,
            redirect: false,
            error: null,
            url: null,
            working: false,
            loginShown: false,
            initialized: false,
            scrollToLogin: false,
            assetURLs: {},
        };


    }

    componentDidMount() {

        initialize().then(async () => {
            await this.fetchAssets();
            await this.setState({initialized: true});
        });


        firebaseApp.auth().onAuthStateChanged(async (user) => {

            if (user) {

                try {
                    const profile = await firestore.collection('profiles').doc(user.uid).get();
                    const roles = await firestore.collection('roles').doc(user.uid).get();

                    if (!profile.exists || !roles.exists) {
                        this.setState({redirect: true, url: '/host/register', working: false})
                    } else {
                        this.setState({user: profile.data(), roles: roles.data(), working:false, loginShown:false}, () => {
                            i18nChange(this.state.user.language);
                            trackUserPresence();

                        })

                    }
                } catch (error) {
                    this.setState({systemError: error});
                }


            }
        });
    }

    componentDidUpdate() {
        if (this.state.scrollToLogin && !isNullOrUndefined(this.login)) {
            this.setState({scrollToLogin: false}, () => {
                ReactDOM.findDOMNode(this.login).scrollIntoView()
            });
        }
    }

    fetchAssets = async () => {
        const fullPathAssets = [
            'host/video.mp4',
            'host/frame.jpg',
        ];
        const noExtensionAssets = [
            'host/illustration1',
            'host/illustration2',
            'host/illustration3',
            'host/illustration4',
        ];
        const assetURLs = await assetsService.fetchAssets(fullPathAssets, noExtensionAssets);
        await this.setState({
            assetURLs: assetURLs,
        });

    };

    handleLogin(name) {
        this.setState({working: true}, () => {
            firebase.auth().signInWithEmailAndPassword(this.state.username, this.state.password)
                .catch((error) => {
                    this.setState({error: i18n("error/" + error.code), working: false})
                })
        });

    }

    handleRegister = (name) => {
        this.setState({redirect: true, url: '/host/register'});
    }

    handleGoToMy = (name) => {
        this.setState({redirect: true, url: '/host/my'});
    }

    toggleLoginShown = (scroll) => {
        this.setState({loginShown: !this.state.loginShown, scrollToLogin: scroll === true});
    };

    showLogin = (scroll) => {
        this.setState({loginShown: true});
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleScroll = (name) => {
        if (name) {
            let node = ReactDOM.findDOMNode(this.refs[name]);
            if (node) {
                node.scrollIntoView();
            }
        }
    }

    render() {

        if (!this.state.initialized) {
            return (<Timer/>);
        }

        const isAuth = this.state.user !== null;

        return (
            <div>
                {(this.state.redirect) &&
                <Redirect push to={this.state.url}/>
                }
                <VideoHero video={this.state.assetURLs['host/video.mp4']} frame={this.state.assetURLs['host/frame.jpg']}>
                    <Header logoWhite user={this.state.user} roles={this.state.roles} onLogin={(event) => this.showLogin()}>
                        <LinksCSS>
                            {!this.state.loginShown && !isAuth && <Link onClick={(event) => this.toggleLoginShown()}>{i18n("host/header/login")}</Link> }
                            {!isAuth && <Link onClick={(event) => this.handleRegister("register")}>{i18n("host/header/register")}</Link>}
                        </LinksCSS>
                    </Header>
                    <HeroWrapper>
                        <HeroBlock>
                            <HeroElements>
                                <HeroText>

                                    {i18n("host/hero/text")}

                                </HeroText>
                                <HeroButtons>

                                    {!isAuth &&
                                    <StyledWhiteButton
                                        label={i18n("host/hero/register")}
                                        name={"register"}
                                        onClick={this.handleRegister.bind(this)}
                                    />}

                                    {isAuth &&
                                    <StyledWhiteButton
                                        minWidth={"300px"}
                                        label={i18n("host/hero/goToMy")}
                                        name={"goToMy"}
                                        onClick={this.handleGoToMy.bind(this)}
                                    />}

                                    <RightButtonCSS>

                                        <StyledInvertedWhiteButton
                                            onClick={this.handleScroll.bind(this)}
                                            label={i18n("host/hero/more")}
                                            name={"more"}
                                        />
                                    </RightButtonCSS>

                                    {!this.state.loginShown && !isAuth &&

                                    <MobileButtonCSS>
                                        <StyledInvertedWhiteButton
                                            onClick={((e) => this.toggleLoginShown(true))}
                                            label={i18n("host/hero/login")}
                                            name={"login"}
                                        />
                                    </MobileButtonCSS>
                                    }


                                </HeroButtons>
                            </HeroElements>
                        </HeroBlock>
                        <HeroBlock zIndex={1} ref={input => {
                            this.login = input;
                        }}>

                            {this.state.loginShown &&
                            <LoginForm
                                onClose={this.toggleLoginShown.bind(this)}
                                onLogin={this.handleLogin.bind(this)}
                                onRegister={this.handleRegister.bind(this)}
                                error={this.state.error}
                                username={this.state.username}
                                password={this.state.password}
                                onChange={this.handleChange.bind(this)}
                                working={this.state.working}
                                enableRegister={true}/>
                            }
                        </HeroBlock>
                    </HeroWrapper>
                </VideoHero>
                <WrapperCSS>
                    <TitleCSS ref="more">{i18n("host/title")}</TitleCSS>
                    <ClaimCSS>{i18n("host/claim")}</ClaimCSS>

                    <ButtonCSS>
                        <MuiThemeProvider theme={theme}>
                            <Button variant="outlined" href={"/host/register"} color={"primary"}>
                                {i18n("host/register")}
                            </Button>
                        </MuiThemeProvider>
                    </ButtonCSS>

                    <RowCSS>
                        <Image1CSS src={this.state.assetURLs['host/illustration1']} alt=""/>
                        <Text1CSS>
                            <SmallTitleCSS>{i18n("host/1/title")}</SmallTitleCSS>
                            <ParagraphCSS>{i18n("host/1/p")}</ParagraphCSS>
                        </Text1CSS>
                        <Image2CSS src={this.state.assetURLs['host/illustration2']} alt=""/>
                        <Text2CSS>
                            <SmallTitleCSS>{i18n("host/2/title")}</SmallTitleCSS>
                            <ParagraphCSS>{i18n("host/2/p")}</ParagraphCSS>
                        </Text2CSS>
                    </RowCSS>
                    <RowCSS>
                        <Image1CSS src={this.state.assetURLs['host/illustration3']} alt=""/>
                        <Text1CSS>
                            <SmallTitleCSS>{i18n("host/3/title")}</SmallTitleCSS>
                            <ParagraphCSS>{i18n("host/3/p")}</ParagraphCSS>
                        </Text1CSS>
                        <Image2CSS src={this.state.assetURLs['host/illustration4']} alt=""/>
                        <Text2CSS>
                            <SmallTitleCSS>{i18n("host/4/title")}</SmallTitleCSS>
                            <ParagraphCSS>{i18n("host/4/p")}</ParagraphCSS>
                        </Text2CSS>
                    </RowCSS>
                </WrapperCSS>

                <Footer/>

            </div>
        );
    }
}

export default withDebug()(withRouter(Host));
