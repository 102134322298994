import React, {Component} from 'react';
import {initialize} from "../common/configuration";
import * as assetsService from "../common/assets";

import styled from 'styled-components';
import {i18n, i18nChange, i18nConfiguration, i18nLocale, i18nText} from "../common/I18n";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Timer from "../components/Timer";
import SearchInput from "../components/SearchInput";

import Carousel from "../components/Carousel";
import firebaseApp, {trackUserPresence} from "../common/firebase";

import * as firebase from "firebase";
import HostEventBlock from "../components/HostEventBlock";
import HostDraftBlock from "../components/HostDraftBlock";
import moment from "moment/moment";
import Button from '@material-ui/core/Button';
import {Redirect} from "react-router";
import AddIcon from '@material-ui/icons/Add';
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from '@material-ui/core/styles';
import StyledDialog from "../components/StyledDialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import ErrorSnackbar from "../components/ErrorSnackbar";
import {isNullOrUndefined} from "util";
import {List} from 'react-content-loader'
import Cook from "../components/Cook";
import Assistent, {AssistantActions} from "../components/Assistent";
import StyledFlatButton from "../components/StyledFlatButton";
import StyledGreyButton from "../components/StyledGreyButton";
import passion from "../assets/passion.svg";
import easy from "../assets/easy.svg";
import exclusive from "../assets/exclusive.svg";
import money from "../assets/money.svg";
import ChatWindow from "../components/ChatWindow";
import {isEMailValid} from "../common/utils";
import P23TextField from "../components/P23TextField";
import withDebug from "../common/withDebug";


const firestore = firebaseApp.firestore();


const TitleCSS = styled.div`
width:90%;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 80px;
opacity: 0.8;
font-family: TruenoBold;
font-size: 34px;
color: #333333;
letter-spacing: -0.49px;
line-height: 42px;
text-transform: uppercase;
user-select: none;
`;

const HintCSS = styled.div`
width:90%;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 80px;
opacity: 0.1;
font-family: TruenoLight;
font-size: 34px;
color: #333333;
letter-spacing: -0.49px;
line-height: 42px;
text-transform: uppercase;
user-select: none;
text-align: center;
`;

const WrapperCSS = styled.div`
    padding-left: 5%;
    padding-right: 5%;
`;

const ClaimCSS = styled.div`
display: block;
margin-top:20px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 16px;
color: #818181;
letter-spacing: 0;
text-align: left;
line-height: 26px;
width:100%;
@media screen and (max-width: 800px) {
width:100%;
}

`;


const RowCSS = styled.div`
  margin-top: 42px;

display: grid;
  grid-template-columns: 20% 28% 4% 20% 28%;
  
    @media screen and (max-width: 800px) {
  grid-template-columns: 40% 60%;
  grid-template-rows: auto auto;
      grid-column-gap: 5px;
      grid-row-gap: 42px;
}

`;

const Image1CSS = styled.img`
grid-column-start: 1;
grid-column-end: 2;
   @media screen and (max-width: 800px) {
grid-column-start: 1;
grid-column-end: 2;
grid-row-start: 1;
grid-row-end: 2;
}
width: 90%;
box-shadow: 0 0 24px 0 rgba(0,0,0,0.04);
border-radius: 100%;
z-index: 10;
`;

const Text1CSS = styled.div`
grid-column-start: 2;
grid-column-end: 3;
   @media screen and (max-width: 800px) {
grid-column-start: 2;
grid-column-end: 3;
grid-row-start: 1;
grid-row-end: 2;
}

`;

const Image2CSS = styled.img`
grid-column-start: 4;
grid-column-end: 5;
   @media screen and (max-width: 800px) {
grid-column-start: 1;
grid-column-end: 2;
grid-row-start: 2;
grid-row-end: 3;
}
width: 90%;
box-shadow: 0 0 24px 0 rgba(0,0,0,0.04);
border-radius: 100%;
z-index: 10;

`;

const Text2CSS = styled.div`
grid-column-start: 5;
grid-column-end: 6;
   @media screen and (max-width: 800px) {
grid-column-start: 2;
grid-column-end: 3;
grid-row-start: 2;
grid-row-end: 3;
}
`;

const SmallTitleCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
`;

const ParagraphCSS = styled.div`
margin-top: 12px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 14px;
color: #818181;
letter-spacing: 0;
line-height: 26px;
`;

const styles = theme => ({
    lightTooltip: {
        background: theme.palette.common.white,
        color: theme.palette.text.primary,
        fontFamily: 'TruenoLight',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
});

const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#FF6344',
            // contrastText: will be calculated to contast with palette.primary.main
        },
        secondary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
    },

    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiButton: {
            label: {
                fontFamily: 'TruenoLight',
                color: "#ffffff"
            },
            fab: {
                position: 'fixed',
                bottom: createMuiTheme({typography: {
                        useNextVariants: true,
                    }}).spacing.unit * 3,
                right: createMuiTheme({typography: {
                        useNextVariants: true,
                    }}).spacing.unit * 3,
                zIndex: 100
            }
        },
        MuiCircularProgress: {
            root: {
                position: 'fixed',
                bottom: createMuiTheme({typography: {
                        useNextVariants: true,
                    }}).spacing.unit * 3,
                right: createMuiTheme({typography: {
                        useNextVariants: true,
                    }}).spacing.unit * 3,
                zIndex: 100
            }

        }
    }

});


class HostMy extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            hasRegistration: false,
            roles: null,
            events: [],
            pastEvents: [],
            drafts: [],
            systemError: null,
            hasRunning: false,
            hasError: false,
            working: false,
            loading: false,
            openDeleteDialog: false,
            showChat: false,
            chatWithName: null,
            reference: null,
            messages: [],
            showMessageAll: false,
            message: "",
            messageAllSent: false,
            event:null,
            initialized:false,
            assetURLs: {},
        };
    }

    UNSAFE_componentWillMount() {

        initialize().then(async () => {
            await this.fetchAssets();
            await this.setState({initialized: true});
        });

        firebaseApp.auth().onAuthStateChanged(async (user) => {
            if (user) {

                try {
                    const profile = await firestore.collection('profiles').doc(user.uid).get();
                    const roles = await firestore.collection('roles').doc(user.uid).get();


                    if (!profile.exists) {
                        this.setState({systemError: "noProfile"});
                    } else {

                        trackUserPresence();


                        if (!roles.exists) {
                            this.setState({user: profile.data()}, () => {
                                this.listen(user.uid);
                                i18nChange(this.state.user.language);
                                this.load("loading")
                            })
                        } else {
                            this.setState({user: profile.data(), roles: roles.data()}, () => {
                                i18nChange(this.state.user.language);
                                this.listen(user.uid);
                                this.load("loading")
                            })
                        }


                    }
                } catch (error) {
                    this.setState({systemError: error});
                }


            }
        });

    }

    load = (indicator) => {

        this.setState({[indicator]: true, events: [], pastEvents: [], drafts: []}, async () => {

            try {
                const fsEvents = await firestore.collection('events').where("uid", "==", this.state.user.uid).get();
                const fsDrafts = await firestore.collection('drafts').where("uid", "==", this.state.user.uid).get();

                let events = [];
                let pastEvents = [];
                let drafts = [];


                for (let fsEvent of fsEvents.docs) {
                    let event = Object.assign({}, fsEvent.data());
                    event.id = fsEvent.id;

                    const fsBookings = await firestore.collection('bookings').where("eventId", "==", fsEvent.id).get();

                    let bookings = [];

                    for (let fsBooking of fsBookings.docs) {
                        bookings.push(fsBooking.data());
                    }

                    event.bookings = bookings;

                    if (event.status === "running" || event.status === "confirmed") {
                        events.push(event);
                    } else {
                        pastEvents.push(event);
                    }

                }

                for (let fsDraft of fsDrafts.docs) {
                    let draft = Object.assign({}, fsDraft.data());
                    draft.id = fsDraft.id;
                    drafts.push(draft);
                }

                this.setState({events: events, pastEvents: pastEvents, drafts: drafts, [indicator]: false})

            } catch (error) {
                this.setState({systemError: error, [indicator]: false})
            }

        });


    };

    listen = (uid) => {
        firestore.collection('roles').doc(uid)
            .onSnapshot(
                snapshot => {

                    this.setState({roles: snapshot.data()});

                }
                , snapShotError => {
                    this.setState({systemError: snapShotError.code});
                });

        firestore.collection('hostRegistrations').doc(uid)
            .onSnapshot(
                snapshot => {
                    this.setState({hasRegistration: snapshot.exists});
                }
                , snapShotError => {
                    this.setState({systemError: snapShotError.code});
                });

        firestore.collection('eventRequests').where("uid", "==", uid)
            .onSnapshot(
                fsEventRequests => {

                    let hasRunning = false;
                    let hasError = false;
                    for (let fsEventRequest of fsEventRequests.docs) {
                        if (fsEventRequest.data().status === "error") {
                            hasError = true;
                        }
                        if (fsEventRequest.data().status === "created") {
                            hasRunning = true;
                        }


                    }

                    this.setState({hasRunning: hasRunning, hasError: hasError});

                }
                , snapShotError => {
                    this.setState({systemError: snapShotError.code});
                });
        firestore.collection('events').where("uid", "==", uid)
            .onSnapshot(
                snapshot => {

                    this.load("loading");

                }
                , snapShotError => {
                    this.setState({systemError: snapShotError.code});
                });
    };

    fetchAssets = async () => {
        const fullPathAssets = [
        ];
        const noExtensionAssets = [
            'host-my/illustration1',
            'host-my/illustration2',
            'host-my/illustration3',
            'host-my/illustration4',
        ];
        const assetURLs = await assetsService.fetchAssets(fullPathAssets, noExtensionAssets);
        await this.setState({
            assetURLs: assetURLs,
        });

    };

    handleCloneEvent = (event) => {
        this.setState({working: true}, () => {


            const date = moment().add(7, "d").hours(0).minutes(0).seconds(0).milliseconds(0);

            const start = moment(event.start.toDate()).date(date.date()).month(date.month()).year(date.year());
            const end = moment(event.end.toDate()).date(date.date()).month(date.month()).year(date.year());

            const newDraft =

                {
                    step: "CONFIRM",
                    uid: firebase.auth().currentUser.uid,
                    images: event.images,
                    mainImageIndex: event.mainImageIndex,
                    title: event.title,
                    tag: null,
                    description: event.description,
                    menu: event.menu,
                    ingredients: event.ingredients,
                    address: this.state.user.address,
                    about: event.host.about,
                    seats: event.seats,
                    addressSpecials: event.addressSpecials,
                    cuisines: event.cuisines,
                    languages: event.languages,
                    specials: event.specials,
                    allergens: event.allergens,
                    courtesies: event.courtesies,
                    types: event.types,
                    policy: event.policy,
                    time: event.time,
                    date: date.toDate(),
                    times: [{
                        start: start.toDate(),
                        end: end.toDate(),
                    }],
                    price: event.price,
                    voucher: null,
                    created: new Date(),
                    updated: null,
                };

            firestore.collection('drafts').add(newDraft)
                .then((doc) => {


                    window.location = `/host/my/draft/${doc.id}`

                })
                .catch((error) => {
                    this.setState({working: false, error: error.code});
                })
        });

    };

    handleCloneDraft = (draft) => {

        const date = moment().add(7, "d").hours(0).minutes(0).seconds(0).milliseconds(0);

        const start = moment(date).hours(19);
        const end = moment(date).hours(21);

        this.setState({working: true}, () => {

            const newDraft =

                {
                    step: draft.step,
                    uid: firebase.auth().currentUser.uid,
                    images: draft.images,
                    mainImageIndex: draft.mainImageIndex,
                    title: draft.title,
                    tag: null,
                    description: draft.description,
                    menu: draft.menu,
                    ingredients: draft.ingredients,
                    address: draft.address,
                    about: draft.about,
                    addressSpecials: draft.addressSpecials,
                    seats: draft.seats,
                    cuisines: draft.cuisines,
                    languages: draft.languages,
                    specials: draft.specials,
                    allergens: draft.allergens,
                    courtesies: draft.courtesies,
                    types: draft.types,
                    policy: draft.policy,
                    time: draft.time,
                    date: date.toDate(),
                    times: [{
                        start: start.toDate(),
                        end: end.toDate(),
                    }],
                    price: draft.price,
                    voucher: null,
                    created: new Date(),
                    updated: null,
                };

            firestore.collection('drafts').add(newDraft)
                .then((doc) => {
                    window.location = `/host/my/draft/${doc.id}`
                })
                .catch((error) => {
                    this.setState({working: false, error: error.code});
                })
        });

    };

    handleBecomeHost = () => {


        this.setState({working: true}, async () => {

            try {

                await firestore.collection('hostRegistrations').doc(firebaseApp.auth().currentUser.uid).set(
                    {
                        step: "DONE",
                        mobileprefix: this.state.user.mobileprefix,
                        mobile: this.state.user.mobile,
                        email: this.state.user.email,
                        image: this.state.user.image,
                        firstname: this.state.user.firstname,
                        lastname: this.state.user.lastname,
                        language: this.state.user.language,
                        done: true,
                        birthday: this.state.user.birthday,
                        created: new Date()
                    },
                    {merge: true}
                );

                this.setState({working: false});

            } catch (error) {
                this.setState({working: false, systemError: error});
            }


        })


    };

    handleNewDraft = () => {

        const date = moment().add(7, "d").hours(0).minutes(0).seconds(0).milliseconds(0);

        const start = moment(date).hours(19);
        const end = moment(date).hours(21);

        this.setState({working: true}, () => {

            const newDraft =

                {
                    step: "WELCOME",
                    uid: firebase.auth().currentUser.uid,
                    images: [],
                    mainImageIndex: 0,
                    title: {},
                    tag: null,
                    description: {},
                    menu: {},
                    ingredients: {},
                    address: this.state.user.address,
                    about: this.state.user.about,
                    addressSpecials: {},
                    seats: {min: 1, max: 8},
                    cuisines: [],
                    languages: ["german"],
                    specials: [],
                    allergens: [],
                    courtesies: [],
                    types: [],
                    policy: "strict",
                    time: "24h",
                    date: date.toDate(),
                    times: [
                        {start: start.toDate(), end: end.toDate()}
                    ],
                    price: {amount: 10, currency: "EUR"},
                    voucher: null,
                    created: new Date(),
                    updated: null,
                };

            firestore.collection('drafts').add(newDraft)
                .then((doc) => {
                    window.location = `/host/my/draft/${doc.id}`
                })
                .catch((error) => {
                    this.setState({working: false, error: error.code});
                })
        });

    };

    handleContinueDraft = () => {
        window.location = `/host/my/draft/${this.state.drafts[0].id}`

    };

    handleDelete = (draftID) => {

        this.setState({openDeleteDialog: true, draftID: draftID});

    };

    handleCloseDialog = (name) => {

        this.setState({openDeleteDialog: false, draftID: null});

    };

    handleDeleteDialog = (name) => {

        this.setState({loading: true}, () => {

            firestore.collection('drafts').doc(this.state.draftID).delete()
                .then(() => {
                    this.setState({openDeleteDialog: false, draftID: null}, () => this.load("loading"));
                })
                .catch((error) => {
                    this.setState({loading: false, systemError: error.code});
                })
        });

    };

    handleChat = (event, booking) => {

        this.setState({
            showChat: true,
            chatWithName: booking.guest.firstname,
            reference: {event: event, booking: booking},
        }, () => {
            firestore.collection('messages').where("eventId", "==", event.id).where("hostUid", "==", this.state.user.uid).where("guestUid", "==", booking.uid)
                .onSnapshot(
                    fsMessages => {

                        const messages = [];
                        for (const fsMessage of fsMessages.docs) {

                            const message = {
                                text: fsMessage.data().text,
                                name: fsMessage.data().isGuest ? fsMessage.data().guest.name : fsMessage.data().host.name,
                                image: fsMessage.data().isGuest ? fsMessage.data().guest.image : fsMessage.data().host.image,
                                other: fsMessage.data().isGuest,
                                readByGuest: fsMessage.data().readByGuest,
                                readByHost: fsMessage.data().readByHost,
                                timestamp: fsMessage.data().created.toDate(),
                                ref: fsMessage.ref,
                            };

                            messages.push(message);
                        }

                        messages.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());

                        this.setState({messages: messages});

                    }
                    , snapShotError => {
                        this.setState({systemError: snapShotError.code});
                    });
        });
    };

    handleCloseChat = (reference) => {
        this.setState({showChat: false, chatWithName: null, messages: [], reference: null, message: ""}, () => {
            firestore.collection('messages').where("eventId", "==", reference.event.id).where("hostUid", "==", reference.event.uid).where("guestUid", "==", reference.booking.uid)
                .onSnapshot(() => {
                })
        });
    };

    handleSend = async (reference, text) => {
        await firestore.collection("messages").add(
            {
                host: {
                    name: this.state.user.firstname,
                    image: this.state.user.image,
                },
                hostUid: this.state.user.uid,
                guest: {
                    name: reference.booking.guest.firstname,
                    image: reference.booking.guest.image,
                },
                guestUid: reference.booking.uid,
                readByHost: true,
                readByGuest: false,
                isGuest: false,
                text: text,
                eventId: reference.event.id,
                created: new Date()
            });
    };

    handleRead = async (messages) => {
        for (const message of messages) {
            if (!message.readByHost) {
                await message.ref.set(
                    {
                        readByHost: true,
                    },
                    {merge: true}
                )
            }
        }
    };

    handleMessageAllCancel(key) {

        this.setState({
            working: false,
            showMessageAll: false,
            message: "",
            messageAllSent: false,
            event:null,
        })
    };

    handleMessageAllOk(key) {

        this.setState({
            working: false,
            showMessageAll: false,
            message: "",
            messageAllSent: false,
            event:null,
        })
    };

    handleMessageAllSend(key) {

        this.setState({
            working: true,
        }, async () => {
            try {

                const bookings = this.state.event.bookings.filter((booking) => booking.status === "ok");

                for (const booking of bookings) {
                    await firestore.collection("messages").add(
                        {
                            host: {
                                name: this.state.user.firstname,
                                image: this.state.user.image,
                            },
                            hostUid: this.state.user.uid,
                            guest: {
                                name: booking.guest.firstname,
                                image: booking.guest.image,
                            },
                            guestUid: booking.uid,
                            readByHost: true,
                            readByGuest: false,
                            isGuest: false,
                            text: this.state.message,
                            eventId: booking.eventId,
                            created: new Date()
                        });

                }

                this.setState({working:false, messageAllSent:true})


            } catch (error) {
                this.setState({error: error, working:false})
            }
        })
    };

    handleMessageAll(event) {

        this.setState({
            working: false,
            showMessageAll: true,
            message: "",
            messageAllSent: false,
            event:event,
        })
    };

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };


    render() {

        if (!this.state.initialized) {
            return (<Timer/>);
        }

        const {classes} = this.props;


        let events = this.state.events.slice();
        events = events.sort(function (a, b) {
            return moment(a.date.toDate()).isBefore(moment(b.date.toDate())) ? -1 : 1;
        });

        let pastEvents = this.state.pastEvents.slice();
        pastEvents = pastEvents.sort(function (a, b) {
            return moment(a.date.toDate()).isBefore(moment(b.date.toDate())) ? -1 : 1;
        });

        let drafts = this.state.drafts.slice();
        drafts = drafts.sort(function (a, b) {
            return moment(a.created.toDate()).isBefore(moment(b.created.toDate())) ? -1 : 1;
        });

        const isHost = !isNullOrUndefined(this.state.roles) && this.state.roles.host;
        const isAuth = this.state.user !== null;

        const hasAnyEvents = events.length > 0 || pastEvents.length > 0 || drafts.length > 0;
        const hasDraft = drafts.length > 0;
        const hasEvent = events.length > 0;
        const hasPastEvents = pastEvents.length > 0;


        return (
            <div>

                {(!isAuth) &&
                <Timer time={4}>
                    <Redirect push to={`/host`}/>
                </Timer>
                }

                {(isAuth) &&
                <div>

                    <Header user={this.state.user} roles={this.state.roles}/>

                    {(!isHost) && !this.state.hasRegistration &&
                    <div>

                        <Assistent width={"60%"}
                                   text={i18n("assistent/host/notAHost", {firstname: this.state.user.firstname})}
                                   actions={
                                       <AssistantActions>
                                           <StyledFlatButton grey label={i18n("assistent/host/goToMy")}
                                                             onClick={event => {
                                                                 window.location = "/my"
                                                             }}/>

                                           <StyledFlatButton label={i18n("assistent/host/becomeHost")}
                                                             onClick={this.handleBecomeHost.bind(this)}
                                                             disabled={this.state.working}/>
                                       </AssistantActions>
                                   }/>


                    </div>
                    }

                    {(!isHost) && this.state.hasRegistration &&
                    <div>

                        <Assistent width={"60%"}
                                   text={i18n("assistent/host/hasRegistration", {firstname: this.state.user.firstname})}
                                   actions={
                                       <div/>
                                   }/>
                    </div>
                    }

                    {(isHost) &&
                    <div>


                        {this.state.loading ? (
                                <List width={"400px"} style={{
                                    display: 'block',
                                    marginTop: "64px",
                                    marginBottom: "64px",
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}/>)
                            :
                            (
                                <div>

                                    {this.state.hasRunning &&
                                    <Assistent width={"60%"}
                                               text={i18n("assistent/host/running", {firstname: this.state.user.firstname})}
                                               actions={<div/>
                                               }/>
                                    }

                                    {!hasAnyEvents && !this.state.hasRunning &&
                                    <Assistent width={"60%"}
                                               text={i18n("assistent/host/welcome", {firstname: this.state.user.firstname})}
                                               actions={
                                                   <AssistantActions>
                                                       <StyledFlatButton grey label={i18n("assistent/host/goToProfile")}
                                                                         onClick={event => {
                                                                             window.location = "/profile"
                                                                         }}/>
                                                       <StyledFlatButton label={i18n("assistent/host/goToEvent")}
                                                                         onClick={this.handleNewDraft.bind(this)}/>
                                                   </AssistantActions>
                                               }/>
                                    }

                                    {hasDraft && !hasEvent && !this.state.hasRunning &&
                                    <Assistent width={"60%"}
                                               text={i18n("assistent/host/continueDraft", {firstname: this.state.user.firstname})}
                                               actions={
                                                   <AssistantActions>
                                                       <StyledFlatButton label={i18n("assistent/host/goToDraft")}
                                                                         onClick={this.handleContinueDraft.bind(this)}/>
                                                   </AssistantActions>
                                               }/>
                                    }

                                    {!hasDraft && !hasEvent && hasPastEvents && !this.state.hasRunning &&
                                    <Assistent width={"60%"}
                                               text={i18n("assistent/host/newEvent", {firstname: this.state.user.firstname})}
                                               actions={
                                                   <AssistantActions>
                                                       <StyledFlatButton label={i18n("assistent/host/goToEvent")}
                                                                         onClick={this.handleNewDraft.bind(this)}/>
                                                   </AssistantActions>
                                               }/>
                                    }

                                    <div>

                                        <TitleCSS>{i18n('host/my/currentEvents', {firstname: this.state.user.firstname})}</TitleCSS>

                                        {(events.length > 0) &&

                                        <Carousel width={"90%"}>
                                            {
                                                events.map((event) => {
                                                        return (
                                                            <HostEventBlock key={event.id}
                                                                            event={event}
                                                                            onClone={this.handleCloneEvent.bind(this)}
                                                                            onChat={this.handleChat.bind(this)}
                                                                            onMessageAll={this.handleMessageAll.bind(this)}/>)
                                                    }
                                                )}
                                        </Carousel>
                                        }

                                        {(events.length === 0) &&
                                        <HintCSS>{i18n("host/my/noEvents")}</HintCSS>
                                        }

                                    </div>


                                    <div>
                                        <TitleCSS>{i18n('host/my/drafts', {firstname: this.state.user.firstname})}</TitleCSS>
                                        {(drafts.length > 0) &&

                                        <Carousel width={"90%"}>
                                            {
                                                drafts.map((draft) => {
                                                        return (<HostDraftBlock key={draft.id} draft={draft}
                                                                                onDelete={this.handleDelete.bind(this)}
                                                                                onClone={this.handleCloneDraft.bind(this)}/>)
                                                    }
                                                )}

                                        </Carousel>
                                        }

                                        {(drafts.length === 0) &&
                                        <HintCSS>{i18n("host/my/noDrafts")}</HintCSS>
                                        }
                                    </div>


                                    <div>
                                        <TitleCSS>{i18n('host/my/pastEvents', {firstname: this.state.user.firstname})}</TitleCSS>
                                        {(pastEvents.length > 0) &&

                                        <Carousel width={"90%"}>
                                            {


                                                pastEvents.map((event) => {
                                                        return (
                                                            <HostEventBlock key={event.id}
                                                                            event={event}
                                                                            onClone={this.handleCloneEvent.bind(this)}
                                                                            onChat={this.handleChat.bind(this)}
                                                                            onMessageAll={this.handleMessageAll.bind(this)}/>)

                                                    }
                                                )}
                                        </Carousel>
                                        }
                                        {(pastEvents.length === 0) &&
                                        <HintCSS>{i18n("host/my/noPastEvents")}</HintCSS>
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    }

                    <TitleCSS ref="more">{i18n("host/title")}</TitleCSS>
                    <WrapperCSS>

                        <ClaimCSS>{i18n("host/claim")}</ClaimCSS>

                        <RowCSS>
                            <Image1CSS src={this.state.assetURLs['host-my/illustration1']} alt=""/>
                            <Text1CSS>
                                <SmallTitleCSS>{i18n("host/1/title")}</SmallTitleCSS>
                                <ParagraphCSS>{i18n("host/1/p")}</ParagraphCSS>
                            </Text1CSS>
                            <Image2CSS src={this.state.assetURLs['host-my/illustration2']} alt=""/>
                            <Text2CSS>
                                <SmallTitleCSS>{i18n("host/2/title")}</SmallTitleCSS>
                                <ParagraphCSS>{i18n("host/2/p")}</ParagraphCSS>
                            </Text2CSS>
                        </RowCSS>
                        <RowCSS>
                            <Image1CSS src={this.state.assetURLs['host-my/illustration3']} alt=""/>
                            <Text1CSS>
                                <SmallTitleCSS>{i18n("host/3/title")}</SmallTitleCSS>
                                <ParagraphCSS>{i18n("host/3/p")}</ParagraphCSS>
                            </Text1CSS>
                            <Image2CSS src={this.state.assetURLs['host-my/illustration4']} alt=""/>
                            <Text2CSS>
                                <SmallTitleCSS>{i18n("host/4/title")}</SmallTitleCSS>
                                <ParagraphCSS>{i18n("host/4/p")}</ParagraphCSS>
                            </Text2CSS>
                        </RowCSS>
                    </WrapperCSS>

                    <Footer></Footer>

                </div>
                }


                {hasAnyEvents &&

                <MuiThemeProvider theme={theme}>


                    {this.state.working ? (
                        <CircularProgress size={50} color={"secondary"}/>
                    ) : (
                        <Tooltip title={i18n("tooltip/newEvent")}
                                 classes={{tooltip: classes.lightTooltip}}>
                            <Button variant="fab" color="primary" aria-label="Add"
                                    onClick={(event) => this.handleNewDraft()}>
                                <AddIcon/>
                            </Button>
                        </Tooltip>
                    )
                    }


                </MuiThemeProvider>
                }


                <StyledDialog
                    name={"delete"}
                    open={this.state.openDeleteDialog}
                    onCancel={this.handleCloseDialog.bind(this)}
                    onSave={this.handleDeleteDialog.bind(this)}
                    title={i18n("host/delete/dialog/title")}
                    text={i18n("host/delete/dialog/text")}
                    working={this.state.working}
                    okLabel={i18n("host/delete/dialog/ok")}/>

                <ChatWindow open={this.state.showChat}
                            name={this.state.chatWithName}
                            onRead={this.handleRead.bind(this)}
                            messages={this.state.messages}
                            reference={this.state.reference}
                            onSend={this.handleSend.bind(this)}
                            onClose={this.handleCloseChat.bind(this)}/>

                <StyledDialog
                    open={this.state.showMessageAll}
                    title={i18n("messageAll/title")}
                    text={
                        this.state.messageAllSent ?
                            i18n("messageAll/sent") :
                            i18n("messageAll/text",
                                {
                                    title: this.state.event ? i18nText(this.state.event.title) : null,
                                    city: this.state.event ? i18nText(this.state.event.address.city) : null,
                                    date: this.state.event ? moment(this.state.event.start.toDate()).format("DD. MMM") : null,
                                    time: this.state.event ? moment(this.state.event.start.toDate()).format("HH:mm") : null,
                                })}
                    working={this.state.working}
                    name={"messageAll"}
                    actions={
                        (this.state.messageAllSent) ? (
                            <DialogActions>
                                <Button onClick={event => this.handleMessageAllOk("ok")} color="primary">
                                    {i18n("messageAll/ok")}
                                </Button>
                            </DialogActions>
                        ) : (
                            <DialogActions>
                                <Button onClick={event => this.handleMessageAllCancel("cancel")} color="primary">
                                    {i18n("messageAll/cancel")}
                                </Button>
                                <Button onClick={event => this.handleMessageAllSend("save")} color="primary"
                                        disabled={this.state.message === null || this.state.message.trim().length === 0}>
                                    {i18n("messageAll/send")}
                                </Button>
                            </DialogActions>
                        )
                    }
                    control={
                        (!this.state.messageAllSent) ? (
                            <P23TextField
                                autoFocus={false}
                                name="message"
                                width={"100%"}
                                label={i18n("messageAll/label")}
                                value={this.state.message}
                                onChange={this.handleChange.bind(this)}
                                error={this.state.error}
                                multiline={true}
                                inputProps={{
                                    disableUnderline: true,
                                }}
                                rows={10}
                            />
                        ) : (<div/>)


                    }
                />

                <ErrorSnackbar message={this.state.systemError}
                               open={!isNullOrUndefined(this.state.systemError)} onClose={(event) => {
                    console.log(this.state.systemError);
                    this.setState({systemError: null})
                }}/>


            </div>
        );
    }
}

export default withDebug()(withStyles(styles)(HostMy));
