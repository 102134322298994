import React, {Component} from 'react';
import {initialize} from "../common/configuration";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Progress from "../components/Progress";
import {CenterCSS, Grid, BoxCSS} from "../common/Layout";
import {i18n, i18nConfiguration, i18nText, i18nLocale, i18nChange} from "../common/I18n";
import styled from 'styled-components';

import quote_top from '../assets/quote_top.svg';
import quote_bottom from '../assets/quote_bottom.svg';
import StyledButton from "../components/StyledButton";
import Timer from "../components/Timer";
import Moment from 'react-moment';
import 'moment/locale/de';
import moment from "moment/moment";
import {Redirect} from 'react-router';
import firebaseApp, {trackUserPresence} from "../common/firebase";

import * as firebase from "firebase";

import {isNullOrUndefined} from "util";
import {ReactGA} from "../common/tracking";
import ErrorSnackbar from "../components/ErrorSnackbar";
import StyledProgress from "../components/StyledProgress";
import StyledFlatButton from "../components/StyledFlatButton";
import {List} from "react-content-loader";
import Rating from "react-rating";

import asset_emptystar from '../assets/star.svg';
import asset_star from '../assets/filled_start.svg';
import Avatar from "@material-ui/core/Avatar/Avatar";
import StyledTextArea from "../components/StyledTextArea";
import withDebug from "../common/withDebug";

firebaseApp.auth().useDeviceLanguage();
const firestore = firebaseApp.firestore();


const Wizard = styled.div`

margin-top: 54px;
margin-bottom: 54px;
@media screen and (max-width: 1200px) {
margin-left: 0%;
margin-right: 0%;
}
`;

const QuestionCSS = styled.div`
width: 70%;
text-align: center;
display: grid;
grid-template-columns: 10% 80% 10%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 900px) {
width: 100%;
grid-template-columns: 20% 60% 20%;
}
`;

const ContentCSS = styled.div`
margin-top: 64px;
width: 70%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 900px) {
width: 90%;
}
`;

const TextCSS = styled.div`
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
opacity: 0.8;
text-transform: uppercase;
justify-self: center;
align-self: center;
padding-left: 24px;
padding-right: 24px;
`;

const LeftQuoteCSS = styled.img`
justify-self: end;
align-self: start;
margin-top: -32px;
`;

const RightQuoteCSS = styled.img`
justify-self: start;
align-self: end;
margin-bottom: -32px;
`;

const ProfileCSS = styled.img`
width: 132px;
height: 132px;
object-fit: cover;
border-radius: 100%;
`;


function Question(props) {
    return (
        <QuestionCSS>
            <LeftQuoteCSS src={quote_top}/>
            <TextCSS>{props.children}</TextCSS>
            <RightQuoteCSS src={quote_bottom}/>
        </QuestionCSS>
    )
}

const STEPS = [
    "WELCOME",
    "STARS",
    "QUESTION",
    "MESSAGE",
    "DONE",
];

class Score extends Component {


    constructor(props) {
        super(props);

        this.state = {
            id: isNullOrUndefined(props.match.params.id) ? null : props.match.params.id,
            step: "WELCOME",
            steps: STEPS,
            stars: 0,
            message: null,
            scoringRequest: null,
            loading: true,
            initialized:false

        };
    }

    componentDidMount() {

        initialize().then(() => this.setState({initialized: true}));


        firebaseApp.auth().onAuthStateChanged(async (user) => {
            if (user) {
                try {

                    const profile = await firestore.collection('profiles').doc(user.uid).get();
                    const roles = await firestore.collection('roles').doc(user.uid).get();

                    if (!profile.exists || !roles.exists) {
                        this.setState({systemError: "noProfile"});
                    } else {
                        this.setState({user: profile.data(), roles: roles.data()}, () => {
                            i18nChange(this.state.user.language);
                            this.load();
                            trackUserPresence();

                        })
                    }
                } catch (error) {
                    this.setState({systemError: error.code});
                }
            }
        });


    };

    load = () => {

        this.setState({loading: true, scoringRequest: null}, async () => {
            const fsScoringRequest = await firestore.collection('scoringRequests').doc(this.state.id).get();
            if (!fsScoringRequest.exists) {
                window.location = "/notFound";
            } else {
                const scoringRequest = {...fsScoringRequest.data(), id: fsScoringRequest.id};
                this.setState({loading: false, scoringRequest: scoringRequest});
            }
        });
    };

    nextStep = () => {

        let index = this.state.steps.findIndex((step) => step === this.state.step);
        if (index === -1) {
            return this.state.steps[0];
        }

        if ((index + 1) < this.state.steps.length) {
            return this.state.steps[index + 1]
        } else {
            return this.state.steps[index]
        }
    };

    previousStep = () => {

        let index = this.state.steps.findIndex((step) => step === this.state.step);
        if (index === -1) {
            return this.state.steps[0];
        }

        if ((index - 1) < 0) {
            return this.state.steps[index]
        } else {
            return this.state.steps[index - 1];
        }
    };

    indexOfStep = () => {

        return this.state.steps.findIndex((step) => step === this.state.step);
    };


    handleClick(name) {
        switch (name) {

            case "button_back" :
                this.setState({step: this.previousStep(), error: null});
                break;

            case "button_next" :
                this.setState({step: this.nextStep(), error: null});
                break;

            case "button_save" :

                this.setState({working: true}, async () => {

                    await firestore.collection("scoringResponses").add(
                        {
                            uid: this.state.user.uid,
                            basedOn: this.state.id,
                            log: this.state.scoringRequest,
                            message: this.state.message,
                            stars: this.state.stars,
                            status: "new",
                            reason: null,
                            created: new Date(),
                            updated: null
                        }
                    );

                    this.setState({working: false, step: "DONE"});

                });

                break;

            case "button_done":

                const isGuest = this.state.user !== null && this.state.roles.guest;

                this.setState({working: true}, () => {
                    window.location = isGuest ? "/my" : "/host/my";
                });
                break;

            default:
                break;
        }
    }


    render() {

        if (!this.state.initialized) {
            return (<Timer/>);
        }

        const isAuth = this.state.user !== null;

        let asGuest = false;
        let otherFirstname = null;
        let otherImage = null;
        let title = null;
        let date = null;
        let time = null;
        let city = null;

        if (isAuth && !this.state.loading) {

            asGuest = this.state.user.uid === this.state.scoringRequest.booking.uid;
            otherFirstname = asGuest ? this.state.scoringRequest.event.host.firstname : this.state.scoringRequest.booking.guest.firstname;
            otherImage = asGuest ? this.state.scoringRequest.event.host.image : this.state.scoringRequest.booking.guest.image;
            title = i18nText(this.state.scoringRequest.event.title);
            date = moment(this.state.scoringRequest.event.date.toDate()).format("DD. MMMM");
            time = moment(this.state.scoringRequest.event.start.toDate()).format("HH:mm");
            city = i18nText(this.state.scoringRequest.event.address.city);
        }

        const values = {
            otherFirstname: otherFirstname,
            title: title,
            date: date,
            time: time,
            city: city
        };


        return (


            <div>

                {!isAuth &&
                <Timer time={3}>
                    <Redirect push to={`/`}/>
                </Timer>
                }

                {isAuth && this.state.loading &&
                <List width={"400px"} style={{
                    display: 'block',
                    marginTop: "64px",
                    marginBottom: "64px",
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}/>
                }

                {isAuth && !this.state.loading &&
                <div>

                    <Header user={this.state.user} roles={this.state.roles}>
                        <Progress
                            count={this.state.steps.length}
                            current={(this.indexOfStep())}
                            title={i18n("score/title", {firstname: this.state.user.firstname, ...values})}/>
                    </Header>

                    {(this.state.step === "WELCOME") &&
                    <Wizard>
                        <Question>{i18n("score/step/welcome", {firstname: this.state.user.firstname, ...values})}</Question>
                        <ContentCSS>


                            <CenterCSS width={"40%"} marginTop={"40px"}>


                                <StyledButton
                                    label={i18n("score/next")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_next"
                                    working={this.state.working}
                                    disabled={false}/>


                            </CenterCSS>

                        </ContentCSS>
                    </Wizard>
                    }

                    {(this.state.step === "STARS") &&
                    <Wizard>
                        <Question>{i18n("score/step/stars", {firstname: this.state.user.firstname, ...values})}</Question>
                        <ContentCSS>

                            <CenterCSS width={"40%"} marginTop={"40px"}>
                                <ProfileCSS src={otherImage}/>
                            </CenterCSS>

                            <CenterCSS width={"40%"} marginTop={"40px"}>
                                <Rating
                                    initialRating={this.state.stars}
                                    emptySymbol={<img src={asset_emptystar} width={32} height={32} style={{margin: 8}}
                                                      className="icon"/>}
                                    fullSymbol={<img src={asset_star} width={32} height={32} style={{margin: 8}}
                                                     className="icon"/>}
                                    onClick={(stars) => this.setState({stars: stars})}
                                />
                            </CenterCSS>


                            <CenterCSS width={"40%"} marginTop={"40px"}>


                                <StyledButton
                                    label={i18n("score/next")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_next"
                                    working={this.state.working}
                                    disabled={this.state.stars === 0}/>


                            </CenterCSS>

                        </ContentCSS>
                    </Wizard>
                    }

                    {(this.state.step === "QUESTION") &&
                    <Wizard>
                        <Question>{this.state.stars > 3 ? i18n("score/step/question/good", {firstname: this.state.user.firstname, ...values}) : i18n("score/step/question/bad", {firstname: this.state.user.firstname, ...values})}</Question>
                        <ContentCSS>


                            <CenterCSS width={"40%"} marginTop={"40px"}>


                                <StyledButton
                                    label={i18n("score/next")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_next"
                                    working={this.state.working}
                                    disabled={false}/>


                            </CenterCSS>

                            {this.state.stars > 3 &&
                            <CenterCSS width={"40%"} marginTop={"40px"}>


                                <StyledFlatButton
                                    label={i18n("score/skip")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_save"
                                    working={this.state.working}
                                    disabled={false}/>


                            </CenterCSS>
                            }

                            <CenterCSS width={"40%"} marginTop={"24px"}>


                                <StyledFlatButton
                                    label={i18n("score/back")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_back"/>


                            </CenterCSS>

                        </ContentCSS>
                    </Wizard>
                    }

                    {(this.state.step === "MESSAGE") &&
                    <Wizard>
                        <Question>{i18n("score/step/message", {firstname: this.state.user.firstname, ...values})}</Question>
                        <ContentCSS>

                            <CenterCSS width={"60%"}>
                                <StyledTextArea
                                    label={i18n("score/message")}
                                    name="message"
                                    value={this.state.message}
                                    onChange={(event) => this.setState({[event.target.name]: event.target.value})}
                                    disabled={this.state.working}
                                    required={false}
                                    autoFocus={true}/>
                            </CenterCSS>

                            <CenterCSS width={"40%"} marginTop={"40px"}>


                                <StyledButton
                                    label={i18n("score/save")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_save"
                                    working={this.state.working}
                                    disabled={isNullOrUndefined(this.state.message) || this.state.message.trim().length === 0}/>


                            </CenterCSS>

                            <CenterCSS width={"40%"} marginTop={"24px"}>


                                <StyledFlatButton
                                    label={i18n("score/back")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_back"/>


                            </CenterCSS>

                        </ContentCSS>
                    </Wizard>
                    }


                    {(this.state.step === "DONE") &&
                    <Wizard>
                        <Question>{i18n("score/step/done", {firstname: this.state.user.firstname, ...values})}</Question>
                        <ContentCSS>


                            <CenterCSS width={"40%"} marginTop={"40px"}>


                                <StyledButton
                                    label={i18n("score/redirect")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_done"
                                    working={this.state.working}
                                    disabled={false}/>


                            </CenterCSS>

                        </ContentCSS>
                    </Wizard>
                    }

                    <ErrorSnackbar message={this.state.systemError ? this.state.systemError.code : null}
                                   open={!isNullOrUndefined(this.state.systemError)} onClose={(event) => {
                        console.log(this.state.systemError);
                        this.setState({systemError: null})
                    }}/>

                    <Footer/>
                </div>
                }
            </div>
        );
    }
}

export default withDebug()(Score);
