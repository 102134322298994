import React, { Component } from 'react';
import { initialize } from "../common/configuration";


import styled from 'styled-components';
import Header from "../components/Header";
import SearchInput from "../components/SearchInput";

import { countries, masterdata } from "../common/configuration"
import Footer from "../components/Footer";

import placeholder from "../assets/placeholder.svg";
import asset_phone from "../assets/greenPhone.svg";
import asset_mail from "../assets/greenMail.svg";
import asset_keys from "../assets/greenKeys.svg";
import asset_place from "../assets/greenPlace.svg";
import asset_money from "../assets/greenMoney.svg";
import asset_edit from "../assets/edit.svg";
import asset_person from "../assets/person.svg";
import asset_tagLeft from "../assets/tagLeft.svg";
import asset_tags from "../assets/tags.svg";
import asset_tagRight from "../assets/tagRight.svg";
import asset_tagOval from "../assets/tagOval.svg";
import asset_print from "../assets/print.svg";
import asset_bank from "../assets/bank.svg";
import asset__Iconcc from "../assets/cc_icon.svg";
import { i18nText, i18n, i18nConfiguration, i18nLocale, i18nTextFormatted, i18nChange } from "../common/I18n";
import Moment from 'react-moment';
import 'moment/locale/de';
import moment from "moment/moment";
import * as loadimage from 'blueimp-load-image';


import IconButton from "@material-ui/core/IconButton";
import DialogActions from "@material-ui/core/DialogActions";
import Stepper from "@material-ui/core/Stepper";
import Button from "@material-ui/core/Button";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Timer from "../components/Timer";
import { Redirect } from "react-router";
import firebaseApp, { trackUserPresence } from "../common/firebase";

import * as firebase from "firebase";
import StyledDialog from "../components/StyledDialog";
import { AddressControl } from "../components/EditableComponents";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import StyledTextField from "../components/StyledTextField";
import { calcFee, EMailMaskCustom, isEMailValid, MobileMaskCustom, uuidv4 } from "../common/utils";
import hash from "object-hash";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { isNullOrUndefined } from "util";
import withWidth from '@material-ui/core/withWidth';

import * as ReactGA from "react-ga";
import PhoneStepper from "../components/PhoneStepper";
import Dropzone from "react-dropzone";
import StyledProgress from "../components/StyledProgress";
import StyledTextArea from "../components/StyledTextArea";
import ReactMarkdown from "react-markdown";
import ErrorSnackbar from "../components/ErrorSnackbar";
import Carousel from "../components/Carousel";
import Card from "../components/Card";
import NewCCDialog from "../components/NewCCDialog";
import StyledButton from "../components/StyledButton";
import { CenterCSS } from "../common/Layout";
import StyledGreyButton from "../components/StyledGreyButton";
import asset_close from "../assets/close.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Typography from "@material-ui/core/Typography/Typography";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import MouseOverPopover from "../components/MousePopover";
import asset_info from "../assets/info.svg";
import IconPrint from "@material-ui/icons/Print";
import IconReceipt from "@material-ui/icons/Receipt";
import IconReply from "@material-ui/icons/Reply";
import StyledTooltip from "../components/StyledTooltip";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import withDebug from "../common/withDebug";
import queryString from 'query-string';



firebaseApp.auth().useDeviceLanguage();
const firestore = firebaseApp.firestore();
const storage = firebaseApp.storage();


const WrapperCSS = styled.div`
margin-top: 32px;
display: grid;
grid-template-columns: 10% 55% 30% 5%;
@media screen and (max-width: 800px) {
grid-template-columns: 2% 96% 2%;
grid-template-areas: 
". picture ."
". content ."
}
grid-template-areas: 
". content picture ."
`;

const ContentCSS = styled.div`
grid-area: content;
`;

const LineCSS = styled.div`
margin-bottom: ${props => props.marginBottom ? props.marginBottom : "24px"};
width: 100%;
display: grid;
grid-template-columns: 15% 75% 10%;
@media screen and (max-width: 800px) {
grid-template-columns: 3% 90% 3%;
grid-template-areas: 
". icon ."
"main main main"
". edit ."

}
grid-template-areas: 
"icon main edit"
`;

const PictureCSS = styled.div`
grid-area: picture;
margin-bottom: 32px;
`;

const IconCSS = styled.img`
grid-area: icon;
padding-top: 8px;
justify-self: start;
align-self: start;
@media screen and (max-width: 800px) {
justify-self: center;
align-self: center;
margin-bottom: 16px;
}
`;

const MainCSS = styled.div`
grid-area: main;
width: 100%;
justify-self: start;
align-self: start;
display: block;
`;

const StripeCSS = styled.div`
grid-column: 2/4;
display: flex;
flex-direction: column;
justify-content: space-between;
width: 100%;
height: 100%;
`;

const EditCSS = styled.div`
grid-area: edit;
justify-self: end;
align-self: start;
@media screen and (max-width: 800px) {
justify-self: center;
align-self: center;
margin-bottom: 16px;
}
`;

const HrCSS = styled.hr`
grid-column: 2 / 4;
width: 100%;
opacity: 0.2;
@media screen and (max-width: 800px) {
grid-column: 1 / 4;
}
`;

const DarkTextCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 22px;
color: #333333;
letter-spacing: -0.16px;
line-height: 28px;
@media screen and (max-width: 800px) {
text-align: center;
}


`;

const LightTextCSS = styled.div`
opacity: 0.8;
font-family: TruenoLight;
font-size: 18px;
color: #818181;
letter-spacing: 0;
line-height: 28px;
@media screen and (max-width: 800px) {
text-align: center;
}
`;

const ProfileImageCSS = styled.div`
display: grid;
margin-left: auto;
margin-right: auto;
width: 292px;
height: 292px;
position: relative;
`;

const EditImageCSS = styled.div`
position: absolute;
top: 0;
left: 0;
border-radius: 100%;
width: 292px;
height: 292px;
background-color: rgba(255,99,68, 0.6);
z-index: 99;
display: grid;
cursor: pointer;
`;

const StyledProgressCSS = styled.div`
position: absolute;
top: 0;
left: 0;
border-radius: 100%;
width: 292px;
height: 292px;
background-color: rgba(255,99,68, 0.6);
z-index: 99;
display: grid;
`;

const EditImageTextCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #FAFAFA;
letter-spacing: 1.5px;
line-height: 22px;
justify-self: center;
align-self: center;
`;

const ImageCSS = styled.img`
border-radius: 100%;
width: 292px;
height: 292px;
object-fit: cover;
z-index: 1;
`;

const TagsCSS = styled.div`
grid-area: main;
margin-top: 32px;
width: 80%;
margin-left: auto;
margin-right: auto;
display: flex;
flex-wrap: wrap;
justify-content: center;
`;

const AboutCSS = styled.div`
margin-top: 32px;
width: 80%;
margin-left: auto;
margin-right: auto;
display: flex;
flex-direction:column;
justify-content: center;
align-items: center;
`;

const AboutTextCSS = styled.div`
opacity: 0.8;
font-family: TruenoLight;
font-size: 14px;
color: #333333;
letter-spacing: -0.16px;
line-height: 18px;
text-align: center;

p {
padding-bottom: 8px;
}
`;


const TagCSS = styled.div`
background-color: var(--green);
position: relative;
margin-top: 16px;
height: 32px;
margin-left: 8px;
margin-right: 8px;
`;

const TagLeftCSS = styled.img`
position: absolute;
left:0;
top:0;
`;
const TagRightCSS = styled.img`
position: absolute;
right:0;
top:0;
`;
const TagOvalCSS = styled.img`
position: absolute;
left:16px;
top:calc(50% - 10px/2);
`;

const TagDeleteCSS = styled.div`
position: absolute;
opacity: 0.8;
display: flex;
justify-content: center;
align-items:center;
min-width: 24px;
min-height: 24px;
background-color: var(--red);
user-select: none;
border-radius: 100%;
&:hover {
cursor: pointer;
opacity: 1;
}
&:active {
opacity: 0.8;
cursor: pointer;
}
right:-8px;
top:-12px;
`;

const TagTextCSS = styled.div`
font-family: TruenoRegular;
font-size: 14px;
color: #FFFFFF;
letter-spacing: 1.5px;
text-align: center;
line-height: 32px;
text-transform: uppercase;
padding-left: 32px;
padding-right: 24px;
user-select: none;
`;

const TagEditCSS = styled.div`
margin-left: 16px;
margin-top: 8px;
`;

const AboutEditCSS = styled.div`
margin-left: 16px;
margin-top: 8px;
`;

const LanguageCSS = styled.div`
margin-top: 32px;
width: 80%;
margin-left: auto;
margin-right: auto;
display: block;
`;

const LanguageLabelCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 22px;
color: #333333;
letter-spacing: -0.16px;
text-align: center;
`;

const LanguageTextCSS = styled.div`
margin-top: 8px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 18px;
color: var(--red);
letter-spacing: 0;
text-align: center;
cursor: pointer;
user-select: none;
&:hover {
font-family: TruenoBold;
}

`;

const PaymentCSS = styled.div`
opacity: 0.8;
background: #FFFFFF;
box-shadow: 0 0 5px 0 rgba(0,0,0,0.20);
position: relative;
width:100%;
height: 100%;
user-select: none;
`;

const PriceCSS = styled.div`
display: block;
margin-left: auto;
margin-right: auto;
padding-top: 16px;
display: grid;
grid-template-columns: repeat(3, 1fr);
`;

const DateCSS = styled.div`
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 8px;
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
`;

const TitleCSS = styled.div`
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 16px;
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #818181;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
text-transform: uppercase;
min-height: 100px;
`;

const AtCSS = styled.div`
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 14px;
opacity: 0.8;
font-family: TruenoRegular;
font-size: 12px;
color: var(--green);
letter-spacing: 1.5px;
text-align: center;
line-height: 16px;
text-transform: uppercase;
`;

const HostCSS = styled.img`
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 16px;
width: 48px;
height: 48px;
object-fit: cover;
border-radius: 100%;
`;

const PrintCSS = styled.div`
display: flex;
justify-content: space-evenly;
`;

const HostNameCSS = styled.div`
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 16px;
opacity: 0.8;
font-family: TruenoRegular;
font-size: 12px;
color: #818181;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
text-transform: uppercase;
`;

const AmountCSS = styled.div`
display: block;
margin-left: auto;
margin-right: auto;
font-family: TruenoBold;
font-size: 44px;
color: var(--green);
letter-spacing: -0.6px;
line-height: 48px;
text-align: center;
`;

const CurrencyCSS = styled.div`
font-family: TruenoBold;
font-size: 28px;
color: var(--green);
letter-spacing: -0.6px;
line-height: 32px;
text-align: center;
justify-self: start;
align-self: start;
`;

const PaymentsCSS = styled.div`
grid-column: 2/4;
display: flex;
justify-content: space-between;
width: 100%;
height: 100%;
`;

const CaptchaCSS = styled.div`
margin-top: 40px;
display:block;
margin-left: auto;
margin-right: auto;
`;

const HintCSS = styled.div`
width:90%;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 80px;
opacity: 0.1;
font-family: TruenoLight;
font-size: 34px;
color: #333333;
letter-spacing: -0.49px;
line-height: 42px;
text-transform: uppercase;
user-select: none;
text-align: center;
`;

const HintSmallCSS = styled.div`
width:90%;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 24px;
opacity: 1;
font-family: TruenoLight;
font-size: 18px;
color: #414141;
letter-spacing: -0.49px;
line-height: 28px;
text-transform: uppercase;
user-select: none;
text-align: center;
`;

const styles = {};

const theme = createMuiTheme({

    typography: {
        useNextVariants: true,
    },

    palette: {

        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#FF6344',
            dark: '#FF6344',
            // contrastText: will be calculated to contast with palette.primary.main
        },

        secondary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
    },


    overrides: {
        MuiTypography: {
            body2: {
                textTransform: 'uppercase',
                fontSize: '12px',
                height: "22px",
                letterSpacing: 1.5,
                fontFamily: 'TruenoLight',
            }
        }
    }
});


class Tag extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <TagCSS>
                <TagLeftCSS src={asset_tagLeft} />
                <TagOvalCSS src={asset_tagOval} />
                <TagRightCSS src={asset_tagRight} />
                <TagTextCSS>{this.props.children}</TagTextCSS>

                {this.props.editable &&
                    <TagDeleteCSS onClick={(event) => this.props.onDelete(this.props.tag)}>
                        <img src={asset_close} width={8} height={8} />
                    </TagDeleteCSS>
                }
            </TagCSS>
        )
    }

}

const InfoCSS = styled.div`
cursor: pointer;
margin-top: 8px;
display: flex;
justify-content: center;
`;

const FinancialWrapperCSS = styled.div`
display: flex;
flex-direction: column;
min-width: 300px;
padding-left: 40px;
padding-right: 40px;
`;
const FinancialHeaderCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 16px;
color: #818181;
letter-spacing: 0;
width: 100%;
margin-top: 16px;
margin-bottom: 16px;
text-align: center;
`;
const FinancialLineCSS = styled.div`
display: flex;
@media screen and (max-width: 600px) {
flex-direction: column;
}
justify-content:space-between;
width: 100%;
margin-top: 16px;
margin-bottom: 16px;
`;
const FinancialTextCSS = styled.div`
opacity: 0.8;
font-family: TruenoLight;
font-size: 16px;
color: #818181;
letter-spacing: 0;
line-height: 26px;
`;
const FinancialBoldTextCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 16px;
color: #818181;
letter-spacing: 0;
line-height: 26px;
`;


class PaymentOut extends Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            openReceiptDialog: false,
            working: false,
            requestSent: false,
        };

    }

    handlePopoverOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handlePopoverClose = () => {
        this.setState({ anchorEl: null });
    };

    handleCloseDialog = () => {

        this.setState({
            working: false, openReceiptDialog: false
        }, () => {
            this.setState({
                requestSent: false
            });
        });
    };

    handleSendDialog = () => {

        this.setState({
            working: true
        }, async () => {
            await firestore.collection('receiptRequests').add({
                uid: this.props.user.uid,
                paymentId: this.props.payment.id,
                hostUid: this.props.payment.event.uid,
                eventId: this.props.payment.eventId,
                event: this.props.payment.event,
                bookingId: this.props.payment.bookingId,
                booking: this.props.payment.booking,
                status: "new",
                created: new Date(),
                updated: null
            });

            this.setState({
                requestSent: true,
                working: false
            });

        });
    };

    handleOpenDialog = () => {
        this.setState({ openReceiptDialog: true });
    };

    render() {

        const nf = new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        const baseAmount = this.props.payment.amount;
        const serviceAmount = this.props.payment.fee;
        const sumAmount = this.props.payment.totalAmount;
        const voucherAmount = 0;
        const totalAmount = this.props.payment.totalAmount;

        return (
            <MuiThemeProvider theme={theme}>

                <div style={{ padding: "8px" }}>
                    <PaymentCSS>
                        <PriceCSS>
                            <div />
                            <AmountCSS>
                                {nf.format(this.props.payment.totalAmount)}
                            </AmountCSS>
                            <CurrencyCSS>
                                {i18nConfiguration("currency", this.props.payment.currency)}
                            </CurrencyCSS>
                        </PriceCSS>
                        <InfoCSS onMouseEnter={this.handlePopoverOpen}
                            onMouseLeave={this.handlePopoverClose}>
                            <img src={asset_info} />
                        </InfoCSS>
                        <DateCSS>
                            <Moment format="DD. MMM YYYY">
                                {this.props.payment.event.start.toDate()}
                            </Moment>
                        </DateCSS>
                        <TitleCSS>{i18nText(this.props.payment.event.title)}</TitleCSS>
                        <AtCSS>{i18n("profile/at")}</AtCSS>
                        <HostCSS src={this.props.payment.event.host.image} />
                        <HostNameCSS>{this.props.payment.event.host.firstname}</HostNameCSS>
                        <PrintCSS>
                            <Tooltip title={i18n("profile/receipt/tooltip")}>
                                <IconButton
                                    aria-haspopup="true"
                                    color="primary"
                                    onClick={this.handleOpenDialog}
                                >
                                    <IconReceipt />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={i18n("profile/print/tooltip")}>

                                <IconButton
                                    aria-haspopup="true"
                                    color="primary"
                                    onClick={async (e) => {

                                        const url = await storage.ref().child(`guestArea/${this.props.payment.booking.uid}/${this.props.payment.number}.pdf`).getDownloadURL();
                                        window.location = url
                                    }}
                                >
                                    <IconPrint />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={i18n("profile/jumpTo/tooltip")}>
                                <IconButton
                                    aria-haspopup="true"
                                    color="primary"
                                    onClick={(e) => {
                                        window.location = `/my/event/${this.props.payment.eventId}`
                                    }}
                                >
                                    <IconReply />
                                </IconButton>
                            </Tooltip>
                        </PrintCSS>
                    </PaymentCSS>

                    <StyledDialog
                        open={this.state.openReceiptDialog}
                        title={i18n("profile/receipt/title", { name: this.props.payment.event.host.firstname })}
                        text={this.state.requestSent ? i18n("profile/receipt/thanks") : i18n("profile/receipt/text", { email: this.props.user.email })}
                        name={"receipt"}
                        working={this.state.working}
                        actions={
                            (this.state.requestSent) ? (
                                <DialogActions>
                                    <Button onClick={this.handleCloseDialog} color="primary">
                                        {i18n("profile/receipt/ok")}
                                    </Button>
                                </DialogActions>
                            ) : (
                                    <DialogActions>
                                        <Button onClick={this.handleCloseDialog} color="primary">
                                            {i18n("profile/receipt/cancel")}
                                        </Button>
                                        <Button onClick={this.handleSendDialog} color="primary"
                                            disabled={this.state.working}>
                                            {i18n("profile/receipt/send")}
                                        </Button>
                                    </DialogActions>
                                )
                        }
                    />

                    <MouseOverPopover open={Boolean(this.state.anchorEl)} anchorEl={this.state.anchorEl}>
                        <FinancialWrapperCSS>
                            <FinancialHeaderCSS>{i18n("price/financial/header")}</FinancialHeaderCSS>
                            <FinancialLineCSS>
                                <FinancialTextCSS>{i18n("price/financial/basePrice",
                                    {
                                        people: (1 + this.props.payment.booking.seats),
                                        amount: nf.format(this.props.payment.event.price.amount),
                                        currency: i18nConfiguration('currency', this.props.payment.event.price.currency)
                                    })}</FinancialTextCSS>
                                <FinancialTextCSS>{`${nf.format(baseAmount)} ${i18nConfiguration('currency', this.props.payment.event.price.currency)}`}</FinancialTextCSS>
                            </FinancialLineCSS>

                            <FinancialLineCSS>
                                <FinancialTextCSS>{i18n("price/financial/servicePrice")}</FinancialTextCSS>
                                <FinancialTextCSS>{`${nf.format(serviceAmount)} ${i18nConfiguration('currency', this.props.payment.event.price.currency)}`}</FinancialTextCSS>
                            </FinancialLineCSS>
                            <FinancialLineCSS>
                                <FinancialBoldTextCSS>{i18n("price/financial/sum")}</FinancialBoldTextCSS>
                                <FinancialBoldTextCSS>{`${nf.format(sumAmount)} ${i18nConfiguration('currency', this.props.payment.event.price.currency)}`}</FinancialBoldTextCSS>
                            </FinancialLineCSS>
                            <FinancialLineCSS>
                                <FinancialTextCSS>{i18n("price/financial/voucher")}</FinancialTextCSS>
                                <FinancialTextCSS>{voucherAmount === 0 ? "./." : `${nf.format(voucherAmount)} ${i18nConfiguration('currency', this.props.payment.event.price.currency)}`}</FinancialTextCSS>
                            </FinancialLineCSS>
                            <hr width={"100%"} />
                            <FinancialLineCSS>
                                <FinancialBoldTextCSS>{i18n("price/financial/total")}</FinancialBoldTextCSS>
                                <FinancialBoldTextCSS>{`${nf.format(totalAmount)} ${i18nConfiguration('currency', this.props.payment.event.price.currency)}`}</FinancialBoldTextCSS>
                            </FinancialLineCSS>
                            <hr width={"100%"} />
                            <FinancialLineCSS>
                                <FinancialTextCSS>{i18n("price/financial/payment")}</FinancialTextCSS>
                                <FinancialTextCSS>{i18n("price/financial/payment/details", {
                                    brand: this.props.payment.paymentMethod.data.sources.data[0].brand,
                                    last4: this.props.payment.paymentMethod.data.sources.data[0].last4,
                                    exp_month: this.props.payment.paymentMethod.data.sources.data[0].exp_month,
                                    exp_year: this.props.payment.paymentMethod.data.sources.data[0].exp_year
                                })}</FinancialTextCSS>
                            </FinancialLineCSS>
                            <FinancialLineCSS>
                                <FinancialTextCSS>{i18n("price/financial/payment/on")}</FinancialTextCSS>
                                <FinancialTextCSS>{i18n("price/financial/payment/on/details", {
                                    date: moment(this.props.payment.log.created * 1000).format("DD.MM.YYYY"),
                                    time: moment(this.props.payment.log.created * 1000).format("HH:mm")
                                })}</FinancialTextCSS>
                            </FinancialLineCSS>
                            <hr width={"100%"} />
                            <FinancialLineCSS>
                                <FinancialBoldTextCSS>{i18n("price/financial/payedTotal")}</FinancialBoldTextCSS>
                                <FinancialBoldTextCSS>{`${nf.format(totalAmount)} ${i18nConfiguration('currency', this.props.payment.event.price.currency)}`}</FinancialBoldTextCSS>
                            </FinancialLineCSS>
                            <FinancialLineCSS>
                                <FinancialTextCSS>{i18n("price/financial/hints/text")}</FinancialTextCSS>
                            </FinancialLineCSS>
                        </FinancialWrapperCSS>
                    </MouseOverPopover>
                </div>
            </MuiThemeProvider>
        )
    }

}


class ReceivedPayment extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>

                <div style={{ padding: "8px" }}>
                    <PaymentCSS>
                        <PriceCSS>
                            <div />
                            <AmountCSS>
                                {this.props.payment.amount}
                            </AmountCSS>
                            <CurrencyCSS>
                                {i18nConfiguration("currency", this.props.payment.currency)}
                            </CurrencyCSS>
                        </PriceCSS>
                        <DateCSS>
                            <Moment format="DD. MMM YYYY">
                                {this.props.payment.event.start.toDate()}
                            </Moment>
                        </DateCSS>
                        <TitleCSS>{i18nText(this.props.payment.event.title)}</TitleCSS>
                        <AtCSS>{i18n("profile/from")}</AtCSS>
                        <HostCSS src={this.props.payment.booking.guest.image} />
                        <HostNameCSS>{this.props.payment.booking.guest.firstname} {this.props.payment.booking.seats > 0 && `+ ${this.props.payment.booking.seats}`}</HostNameCSS>
                        <PrintCSS>
                            <Tooltip title={i18n("profile/printHost/tooltip")}>

                                <IconButton
                                    aria-haspopup="true"
                                    color="primary"
                                    onClick={(e) => {
                                        window.location = `/receipt/${this.props.payment.id}`
                                    }}
                                >
                                    <IconPrint />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={i18n("profile/jumpTo/tooltip")}>
                                <IconButton
                                    aria-haspopup="true"
                                    color="primary"
                                    onClick={(e) => {
                                        window.location = `/host/my/event/${this.props.payment.eventId}`
                                    }}
                                >
                                    <IconReply />
                                </IconButton>
                            </Tooltip>
                        </PrintCSS>
                    </PaymentCSS>
                </div>
            </MuiThemeProvider>
        )
    }

}

class Profile extends Component {


    constructor(props) {
        super(props);

        let back = queryString.parse(this.props.location.search).back;

        this.state = {
            edit: false,
            working: false,
            error: null,
            reauthenticateError: null,
            newPassword: null,
            showNewPassword: false,
            password: null,
            showPassword: false,
            email: null,
            firstname: null,
            lastname: null,
            user: null,
            address: null,
            showImageEdit: false,
            mobile: null,
            mobileprefix: null,
            code: null,
            activePhoneStep: 0,
            image: null,
            about: null,
            language: "de_DE",
            requiresVAT: false,
            vatID: null,
            taxID: null,
            stripeCustomers: [],
            workingUpload: false,
            ccHighlight: null,
            ccCompleted: false,
            ccBrand: null,
            openCCDialog: false,
            openDeleteTagDialog: false,
            showDeleteTagOption: false,
            tag: null,
            sourcePayments: [],
            destinationPayments: [],
            initialized: false,
            back: back
        };
    }

    UNSAFE_componentWillMount() {

        initialize().then(() => this.setState({ initialized: true }));


        firebaseApp.auth().onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const fsProfile = await firestore.collection('profiles').doc(user.uid).get();
                    const fsRoles = await firestore.collection('roles').doc(user.uid).get();

                    if (!fsProfile.exists || !fsRoles.exists) {
                        this.setState({ systemError: "noProfile" });
                    } else {

                        let profile = fsProfile.data();
                        if (user.email !== fsProfile.data().email) {
                            await firestore.collection('profiles').doc(user.uid).set({
                                email: user.email,
                                change: "inform",
                                updated: new Date(),
                            },
                                { merge: true });

                            const fspNewPofile = await firestore.collection('profiles').doc(user.uid).get()
                            profile = fspNewPofile.data();

                        }

                        this.setState({ user: profile, roles: fsRoles.data() }, () => {
                            i18nChange(this.state.user.language);
                            this.load();
                            this.listen();
                            trackUserPresence();

                        })

                    }
                } catch (error) {
                    this.setState({ systemError: error });
                }


            }
        });

    }

    listen = () => {
        firestore.collection('stripeCustomers').where("uid", "==", this.state.user.uid)
            .onSnapshot(
                fsStripeCustomers => {

                    let stripeCustomers = [];

                    this.setState({ stripeCustomers: [] }, () => {

                        for (const fsStripeCustomer of fsStripeCustomers.docs) {
                            stripeCustomers.push({ ...fsStripeCustomer.data() });
                        }

                        this.setState({ stripeCustomers: stripeCustomers });
                    });
                }
                , snapShotError => {
                    this.setState({ systemError: snapShotError.code });
                });

        firestore.collection('payments').where("sourceUid", "==", this.state.user.uid)
            .onSnapshot(
                fsPayments => {

                    let payments = [];

                    this.setState({ sourcePayments: [] }, async () => {

                        for (const fsPayment of fsPayments.docs) {

                            let payment = { ...fsPayment.data() };
                            payment.id = fsPayment.id;
                            const fsEvent = await firestore.collection('events').doc(payment.eventId).get();
                            const fsBooking = await firestore.collection('bookings').doc(payment.bookingId).get();
                            payment.event = fsEvent.data();
                            payment.booking = fsBooking.data();

                            if (payment.status === "done") {
                                payments.push(payment);
                            }

                        }

                        payments = payments.sort((a, b) => { return b.created.toDate().getTime() - a.created.toDate().getTime() });

                        this.setState({ sourcePayments: payments });
                    });
                }
                , snapShotError => {
                    this.setState({ systemError: snapShotError.code });
                });

        firestore.collection('payments').where("destinationUid", "==", this.state.user.uid)
            .onSnapshot(
                fsPayments => {

                    let payments = [];

                    this.setState({ destinationPayments: [] }, async () => {

                        for (const fsPayment of fsPayments.docs) {

                            let payment = { ...fsPayment.data() };
                            payment.id = fsPayment.id;
                            const fsEvent = await firestore.collection('events').doc(payment.eventId).get();
                            const fsBooking = await firestore.collection('bookings').doc(payment.bookingId).get();
                            payment.event = fsEvent.data();
                            payment.booking = fsBooking.data();
                            if (payment.status === "done") {
                                payments.push(payment);
                            }

                        }

                        payments = payments.sort((a, b) => { return b.created.toDate().getTime() - a.created.toDate().getTime() });


                        this.setState({ destinationPayments: payments });
                    });
                }
                , snapShotError => {
                    this.setState({ systemError: snapShotError.code });
                });
    };

    load = () => {


        firestore.collection('profiles').doc(this.state.user.uid).get()
            .then(fsProfile => {
                this.setState({ user: fsProfile.data() }, () => {

                    const address = this.state.user.address ?
                        {
                            street: this.state.user.address.street,
                            postalCode: this.state.user.address.postalCode,
                            country: this.state.user.address.country,
                            city: i18nText(this.state.user.address.city),
                        } :
                        null;
                    this.setState(
                        {
                            address: address,
                            firstname: this.state.user.firstname,
                            lastname: this.state.user.lastname,
                            email: null,
                            code: null,
                            mobile: null,
                            mobileprefix: this.state.user.mobileprefix,
                            password: null,
                            newPassword: null,
                            activePhoneStep: 0,
                            error: null,
                            reauthenticateError: null,
                            image: this.state.user.image,
                            about: this.state.user.about && this.state.user.about[this.state.user.language] ? this.state.user.about[this.state.user.language] : null,
                            language: this.state.user.language,
                            workingUpload: false,
                            working: false,
                            requiresVAT: this.state.user.requiresVAT,
                            vatID: this.state.user.vatID,
                            taxID: this.state.user.taxID,

                        });
                })
            })
            .catch(error => {
                this.setState({ systemError: error.code });
            });

    }

    handleCancel = (key) => {

        this.setState({
            edit: false
        }, () => {
            this.load()
        });
    };

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    };

    captcha = (ref) => {

        if (isNullOrUndefined(window.recaptchaVerifier)) {
            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(ref, {
                'size': 'normal',
                'callback': (response) => {
                    this.setState({ recaptchaed: true });
                },
                'expired-callback': () => {
                    this.setState({ recaptchaed: false });

                }
            });


        }

        window.recaptchaVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
        });

    };

    handleSave(key) {

        switch (key) {
            case "address":
                this.setState({ working: true }, () => {

                    const data = {
                        street: this.state.address.street,
                        postalCode: this.state.address.postalCode,
                        city: { [i18nLocale()]: this.state.address.city },
                        country: this.state.address.country,
                    };

                    firestore.collection('profiles').doc(firebaseApp.auth().currentUser.uid).set({
                        address: data,
                        change: "inform",
                        updated: new Date()
                    }, { merge: true })
                        .then(() => {
                            this.setState({ working: false, edit: false }, () => this.load());
                        })
                        .catch((error) => {
                            this.setState({ working: false, systemError: error.code });
                        })
                });
                break;

            case "name":
                this.setState({ working: true }, () => {

                    firestore.collection('profiles').doc(firebaseApp.auth().currentUser.uid).set({
                        firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        change: "inform",
                        updated: new Date()
                    }, { merge: true })
                        .then(() => {
                            this.setState({ working: false, edit: false }, () => this.load());
                        })
                        .catch((error) => {
                            this.setState({ working: false, systemError: error.code });
                        })
                });
                break;

            case "vat":
                this.setState({ working: true }, () => {

                    firestore.collection('profiles').doc(firebaseApp.auth().currentUser.uid).set({
                        requiresVAT: this.state.requiresVAT,
                        vatID: this.state.vatID,
                        taxID: this.state.taxID,
                        change: "inform",
                        updated: new Date()
                    }, { merge: true })
                        .then(() => {
                            this.setState({ working: false, edit: false }, () => this.load());
                        })
                        .catch((error) => {
                            this.setState({ working: false, systemError: error.code });
                        })
                });
                break;

            case "about":
                this.setState({ working: true }, () => {

                    let about = this.state.user.about;
                    about[this.state.user.language] = this.state.about;

                    firestore.collection('profiles').doc(firebaseApp.auth().currentUser.uid).set({
                        about: about,
                        change: "inform",
                        updated: new Date()
                    }, { merge: true })
                        .then(() => {
                            this.setState({ working: false, edit: false }, () => this.load());
                        })
                        .catch((error) => {
                            this.setState({ working: false, systemError: error.code });
                        })
                });
                break;

            case "password":
                this.setState({ working: true }, () => {

                    const user = firebase.auth().currentUser;
                    const credential = firebase.auth.EmailAuthProvider.credential(
                        user.email,
                        this.state.password
                    );

                    user.reauthenticateAndRetrieveDataWithCredential(credential)
                        .then(() => {
                            user.updatePassword(this.state.newPassword)
                                .then(() => {

                                    firestore.collection('profiles').doc(firebaseApp.auth().currentUser.uid).set({
                                        passwordLastChanged: new Date(),
                                        change: "ignore",
                                        updated: new Date()
                                    }, { merge: true })
                                        .then(() => {
                                            this.setState({ working: false, edit: false }, () => this.load());
                                        })
                                        .catch((firestoreError) => {
                                            this.setState({ working: false, systemError: firestoreError.code });
                                        });


                                })
                                .catch((error) => {
                                    this.setState({ working: false, systemError: error.code });
                                });
                        })
                        .catch((reauthenticateError) => {
                            this.setState({ working: false, reauthenticateError: reauthenticateError.code });
                        });
                });
                break;
            case "email":
                this.setState({ working: true, error: null }, () => {

                    const user = firebase.auth().currentUser;
                    const oldEMail = user.email;
                    const credential = firebase.auth.EmailAuthProvider.credential(
                        user.email,
                        this.state.password
                    );

                    firestore.collection('emails').doc(hash(this.state.email.toLowerCase())).get()
                        .then(doc => {
                            if (doc.exists) {
                                this.setState({ working: false, error: "EMAIL_EXISTS_ALREADY" });
                            } else {
                                user.reauthenticateAndRetrieveDataWithCredential(credential)
                                    .then(() => {
                                        user.updateEmail(this.state.email)
                                            .then(() => {

                                                var batch = firestore.batch();

                                                batch.update(
                                                    firestore.collection('profiles').doc(user.uid),
                                                    {
                                                        updated: new Date(),
                                                        email: this.state.email,
                                                        change: "inform",
                                                    }
                                                );
                                                batch.set(
                                                    firestore.collection('emails').doc(hash(user.email.toLowerCase())),
                                                    { created: new Date() }
                                                );
                                                batch.delete(
                                                    firestore.collection('emails').doc(hash(oldEMail.toLowerCase())),
                                                );

                                                batch.commit()
                                                    .then(() => {

                                                        let actionCodeSettings = {
                                                            url: "https://" + window.location.hostname + "/profile",
                                                        };

                                                        user.sendEmailVerification(actionCodeSettings)
                                                            .then(() => {
                                                                this.setState({
                                                                    working: false,
                                                                    edit: false
                                                                }, () => this.load());

                                                            })
                                                            .catch((emailError) => {
                                                                this.setState({
                                                                    working: false,
                                                                    systemError: emailError.code
                                                                });
                                                            });

                                                    })
                                                    .catch((firestoreError) => {
                                                        this.setState({
                                                            working: false,
                                                            systemError: firestoreError.code
                                                        });
                                                    });


                                            })
                                            .catch((error) => {
                                                this.setState({ working: false, systemError: error.code });
                                            });
                                    })
                                    .catch((reauthenticateError) => {
                                        this.setState({ working: false, reauthenticateError: reauthenticateError.code });
                                    });
                            }


                        })
                        .catch(error => {
                            this.setState({ working: false, systemError: error.code });
                        });


                });


                break;
            default:
                break;
        }
    };


    handleAddressChange(event) {

        let address = this.state.address;
        address[event.target.name] = event.target.value;
        this.setState({ address: address });
    };

    handleMouseOver() {
        this.setState({ showImageEdit: true })
    }

    handleMouseOut() {
        this.setState({ showImageEdit: false })
    }

    handleEdit(key) {
        this.setState({ edit: true, name: key })
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    handleMouseDownNewPassword = event => {
        event.preventDefault();
    };

    handleClickShowNewPassword = () => {
        this.setState(state => ({ showNewPassword: !state.showNewPassword }));
    };

    getSteps = () => {
        return [i18n("profile/edit/mobile/step/1"), i18n("profile/edit/mobile/step/2"), i18n("profile/edit/mobile/step/3")];
    }

    getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <StyledTextField
                        label={i18n("profile/edit/password")}
                        name="password"
                        type={this.state.showPassword ? 'text' : 'password'}
                        value={this.state.password}
                        onChange={this.handleChange.bind(this)}
                        error={this.state.reauthenticateError ? i18n("error/" + this.state.reauthenticateError) : null}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                >
                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        required={false}
                        autoFocus={true} />
                );
            case 1:
                return (
                    <div>
                        <StyledTextField
                            startAdornment={
                                <IconButton onClick={event => {
                                    this.handleSelectClick(event.currentTarget);
                                }}>
                                    <InputAdornment position="start">{this.state.mobileprefix}</InputAdornment>
                                </IconButton>}
                            mask={MobileMaskCustom}
                            label={i18n("profile/edit/mobile")}
                            name="mobile"
                            placeholder={"1__ _ ___________"}
                            value={this.state.mobile}
                            onChange={this.handleChange.bind(this)}
                            error={this.state.error ? i18n("error/" + this.state.error) : null}
                            required={false}
                            autoFocus={true} />
                        <CaptchaCSS>
                            <div ref={(ref) => this.captcha(ref)}></div>
                        </CaptchaCSS>

                        <Menu id="render-countries-menu" anchorEl={this.state.anchorEl}
                            open={Boolean(this.state.anchorEl)} onClose={this.handleSelectClose.bind(this)}>

                            {masterdata.mobilePrefixCountries.map(value => (
                                <MenuItem onClick={event => {
                                    this.handleSelect("mobileprefix", value)
                                }}>{value}</MenuItem>

                            ))}
                        </Menu>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <StyledTextField
                            label={i18n("profile/edit/code")}
                            name="code"
                            value={this.state.code}
                            error={this.state.error ? i18n("error/" + this.state.error) : null}
                            onChange={this.handleChange.bind(this)}
                            required={false}
                            autoFocus={true} />
                    </div>
                );
            default:
                return '...';
        }
    };

    handleStep = step => () => {
        this.setState({
            activeStep: step,
        });
    };

    handlePhoneNext = () => {

        const { activePhoneStep } = this.state;

        switch (this.state.activePhoneStep) {
            case 0:
                this.setState({ working: true }, () => {

                    const user = firebase.auth().currentUser;
                    const credential = firebase.auth.EmailAuthProvider.credential(
                        user.email,
                        this.state.password
                    );

                    user.reauthenticateAndRetrieveDataWithCredential(credential)
                        .then(() => {
                            this.setState({
                                working: false,
                                activePhoneStep: activePhoneStep + 1,
                            });
                        })
                        .catch((reauthenticateError) => {
                            this.setState({ working: false, reauthenticateError: reauthenticateError.code });
                        });
                });
                break;
            case 1:

                this.setState({ working: true }, () => {

                    firestore.collection('phones').doc(hash((this.state.mobileprefix + this.state.mobile).replace(/\s/g, ''))).get()
                        .then(doc => {
                            if (doc.exists) {
                                this.setState({ working: false, error: "PHONE_EXISTS_ALREADY" });
                            } else {
                                firebase.auth().signInWithPhoneNumber(this.state.mobileprefix + this.state.mobile, window.recaptchaVerifier)
                                    .then(confirmationResult => {

                                        this.setState({
                                            working: false,
                                            activePhoneStep: activePhoneStep + 1,
                                            confirmationResult: confirmationResult,
                                        }, () => {
                                            window.recaptchaVerifier = null
                                        });


                                    })
                                    .catch(error => {
                                        this.setState({ working: false, error: error.code });
                                    });
                            }
                        })
                        .catch(error => {
                            this.setState({ working: false, error: error.code });
                        });
                });
                break;
            case 2:
            default:
                break;

        }

    };

    handlePhoneFinish = () => {
        this.setState({ working: true }, () => {

            var credential = firebase.auth.PhoneAuthProvider.credential(this.state.confirmationResult.verificationId, this.state.code);

            firebase.auth().currentUser.updatePhoneNumber(credential)
                .then(() => {
                    var batch = firestore.batch();

                    batch.update(
                        firestore.collection('profiles').doc(this.state.user.uid),
                        {
                            updated: new Date(),
                            change: "inform",
                            mobile: this.state.mobile,
                            mobileprefix: this.state.mobileprefix
                        }
                    );
                    batch.set(
                        firestore.collection('phones').doc(hash((this.state.mobileprefix + this.state.mobile).replace(/\s/g, ''))),
                        { created: new Date() }
                    );
                    batch.delete(
                        firestore.collection('phones').doc(hash((this.state.user.mobileprefix + this.state.user.mobile).replace(/\s/g, ''))),
                    );

                    batch.commit()
                        .then(() => {

                            this.setState({
                                working: false,
                                edit: false
                            }, () => this.load());

                        })
                        .catch((firestoreError) => {
                            this.setState({ working: false, error: firestoreError.code });
                        });
                })
                .catch(error => {
                    this.setState({ working: false, error: error.code });
                });
        });

    };

    handleSelectClick(target) {
        this.setState({ anchorEl: target });
    }

    handleSelectClose() {
        this.setState({ anchorEl: null });
    }

    handleSelect(name, value) {
        this.setState({ [name]: value, anchorEl: null });
    }

    getDisabledPhoneDialog = () => {
        switch (this.state.activePhoneStep) {
            case 0:
                return this.state.password === null || this.state.password.trim().length === 0;
            case 1:
                return this.state.mobile === null || this.state.mobile.trim().length < 10;
            case 2:
                return this.state.code === null || this.state.code.trim().length < 6;
            default:
                return true;
        }
    }

    handleDrop(acceptedFiles) {

        this.setState({ workingUpload: true }, () => {
            acceptedFiles.forEach(file => {

                const re = /(?:\.([^.]+))?$/;
                const extension = re.exec(file.name)[1];


                if (!isNullOrUndefined(extension)) {

                    loadimage(file, (canvas) => {

                        canvas.toBlob((blob) => {

                            const ref = storage.ref().child("profiles/" + firebase.auth().currentUser.uid + "." + Date.now() + "." + extension);

                            ref.put(blob)
                                .then((snapshot) => {

                                    snapshot.ref.getDownloadURL().then((downloadURL) => {
                                        firestore.collection('profiles').doc(firebaseApp.auth().currentUser.uid).set({
                                            image: downloadURL,
                                            change: "inform",
                                            updated: new Date()
                                        }, { merge: true })
                                            .then(() => {
                                                this.setState({}, () => this.load());
                                            })
                                            .catch((error) => {
                                                this.setState({ workingUpload: false, error: error.code });
                                            })
                                    });
                                })
                                .catch((error) => {
                                    this.setState({ workingUpload: false, error: error.code });

                                });

                        });


                    }, { maxWidth: 400, orientation: true });


                } else {
                    this.setState({ workingUpload: false });
                }


            });
        });

    };

    handleClickLanguage = event => {
        this.setState({ anchorLanguagesEl: event.currentTarget });
    };

    handleChangeLanguage = language => {
        this.setState({ anchorLanguagesEl: null, working: true }, () => {

            firestore.collection('profiles').doc(firebaseApp.auth().currentUser.uid).set({
                language: language,
                updated: new Date()
            }, { merge: true })
                .then(() => {
                    i18nChange(language);
                    this.setState({ working: false }, () => this.load());
                })
                .catch((error) => {
                    this.setState({ working: false, error: error.code });
                })
        });
    };

    handleCloseLanguage = event => {
        this.setState({ anchorLanguagesEl: null });
    };

    handleCCHightlight = (element) => {
        this.setState({ ccHighlight: element })
    };

    handleCCCompleted = (completed) => {
        this.setState({ ccCompleted: completed })
    };

    handleCCBrand = (brand) => {
        this.setState({ ccBrand: brand })
    };

    handleCCToken = (token) => {
        this.setState({ working: true, error: null }, () => {

            const data = {
                created: new Date(),
                updated: null,
                status: "created",
                uid: firebase.auth().currentUser.uid,
                token: token,
                error: null,
            };

            firestore.collection('stripeTokens').doc(token.id).set(data)
                .then(() => {
                    this.handleCloseCCDialog();
                })
                .catch((error) => {
                    this.setState({ working: false, systemError: error });
                });
        });
    };

    handleCloseCCDialog = () => {

        this.setState({
            working: false, openCCDialog: false, ccHighlight: null,
            ccCompleted: false,
            ccBrand: null
        });
    };

    handleOpenCCDialog = () => {
        this.setState({ openCCDialog: true });
    };

    handleOpenDeleteTag = (tag) => {
        this.setState({ openDeleteTagDialog: true, tag: tag });

    };

    handleCloseDeleteTagDialog = () => {
        this.setState({ openDeleteTagDialog: false, tag: null });

    };

    handleSwitchChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    handleDeleteTag = () => {

        this.setState({ working: true }, async () => {
            try {
                const tags = this.state.user.tags.filter(tag => tag.label !== this.state.tag.label || tag.type !== this.state.tag.type);
                await firestore.collection('profiles').doc(firebaseApp.auth().currentUser.uid).set({
                    tags: tags,
                    updated: new Date()
                }, { merge: true });

                this.setState({ openDeleteTagDialog: false, working: false, tag: null }, () => {
                    this.load()
                });
            } catch (error) {
                this.setState({ openDeleteTagDialog: false, working: false, tag: null, systemError: error });
            }
        })

    };

    toggleShowDeleteTagOption = () => {
        this.setState({ showDeleteTagOption: !this.state.showDeleteTagOption });
    };

    render() {


        const { classes } = this.props;

        if (!this.state.initialized) {
            return (<Timer />);
        }

        let dropzoneRef = null;


        const controls = {
            mobile: (<div>
                <PhoneStepper activePhoneStep={this.state.activePhoneStep} steps={this.getSteps()}
                    onStep={this.handleStep.bind(this)} />
                <div style={{ width: "90%", display: "block", marginLeft: "auto", marginRight: "auto" }}>
                    {this.getStepContent(this.state.activePhoneStep)}
                </div>
            </div>),
            address: (<AddressControl address={this.state.address} onChange={this.handleAddressChange.bind(this)} />),
            password: (
                <div>
                    <StyledTextField
                        label={i18n("profile/edit/password/new")}
                        name="newPassword"
                        type={this.state.showNewPassword ? 'text' : 'password'}
                        value={this.state.newPassword}
                        onChange={this.handleChange.bind(this)}
                        error={this.state.error ? i18n("error/" + this.state.error) : null}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={this.handleClickShowNewPassword}
                                    onMouseDown={this.handleMouseDownNewPassword}
                                >
                                    {this.state.showNewPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        required={false}
                        autoFocus={true} />

                    <div>&nbsp;</div>
                    <StyledTextField
                        label={i18n("profile/edit/password/old")}
                        name="password"
                        type={this.state.showPassword ? 'text' : 'password'}
                        value={this.state.password}
                        onChange={this.handleChange.bind(this)}
                        error={this.state.reauthenticateError ? i18n("error/" + this.state.reauthenticateError) : null}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                >
                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        required={false}
                        autoFocus={false} />
                </div>
            ),
            about: (
                <div>
                    <StyledTextArea
                        label={i18n("profile/edit/about")}
                        name="about"
                        value={this.state.about}
                        onChange={this.handleChange.bind(this)}
                        error={this.state.error ? i18n("error/" + this.state.error) : null}
                        required={false}
                        autoFocus={true} />
                </div>
            ),
            vat: (
                <div>
                    <MuiThemeProvider theme={theme}>

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.requiresVAT}
                                    onChange={this.handleSwitchChange('requiresVAT')}
                                    value="requiresVAT"
                                />
                            }
                            label={
                                this.state.requiresVAT ? i18n("profile/requiresVAT") : i18n("profile/noVAT")
                            }
                        />
                    </MuiThemeProvider>
                    <div>&nbsp;</div>
                    <StyledTextField
                        label={i18n("profile/edit/vatID")}
                        name="vatID"
                        value={this.state.vatID}
                        onChange={this.handleChange.bind(this)}
                        error={this.state.error ? i18n("error/" + this.state.error) : null}
                        required={false}
                        autoFocus={true} />
                    <div>&nbsp;</div>
                    <StyledTextField
                        label={i18n("profile/edit/taxID")}
                        name="taxID"
                        value={this.state.taxID}
                        onChange={this.handleChange.bind(this)}
                        error={this.state.error ? i18n("error/" + this.state.error) : null}
                        required={false}
                        autoFocus={false} />
                </div>
            ),
            email: (
                <div>
                    <StyledTextField
                        label={i18n("profile/edit/email")}
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange.bind(this)}
                        error={this.state.error ? i18n("error/" + this.state.error) : null}
                        required={false}
                        mask={EMailMaskCustom}
                        placeholder={"Name@domain.de"}
                        autoFocus={true} />

                    <div>&nbsp;</div>
                    <StyledTextField
                        label={i18n("profile/edit/password")}
                        name="password"
                        type={this.state.showPassword ? 'text' : 'password'}
                        value={this.state.password}
                        onChange={this.handleChange.bind(this)}
                        error={this.state.reauthenticateError ? i18n("error/" + this.state.reauthenticateError) : null}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                >
                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        required={false}
                        autoFocus={false} />
                </div>
            ),

            name: (
                <div>
                    <StyledTextField
                        label={i18n("profile/edit/firstname")}
                        name="firstname"
                        value={this.state.firstname}
                        onChange={this.handleChange.bind(this)}
                        error={this.state.error ? i18n("error/" + this.state.error) : null}
                        required={false}
                        autoFocus={true} />

                    <div>&nbsp;</div>
                    <StyledTextField
                        label={i18n("profile/edit/lastname")}
                        name="lastname"
                        value={this.state.lastname}
                        onChange={this.handleChange.bind(this)}
                        error={this.state.error ? i18n("error/" + this.state.error) : null}
                        required={false}
                        autoFocus={false} />
                </div>
            )
        };

        const disabled = {
            mobile: this.getDisabledPhoneDialog(),
            about: this.state.about === null || this.state.about.trim().length === 0,
            vat: this.state.requiresVAT && (this.state.vatID === null || this.state.vatID.trim().length === 0),
            address:
                isNullOrUndefined(this.state.address) ||
                isNullOrUndefined(this.state.address.street) ||
                isNullOrUndefined(this.state.address.postalCode) ||
                isNullOrUndefined(this.state.address.city) ||
                this.state.address.street.trim().length < 1 ||
                this.state.address.city.trim().length < 1 ||
                this.state.address.postalCode.trim().length < 5,
            password: this.state.newPassword === null || this.state.password === null || this.state.newPassword.trim().length === 1 || this.state.password.trim().length === 1,
            email: this.state.password === null || this.state.password.trim().length === 0 || this.state.email === null || this.state.email.trim().length === 0 || !isEMailValid(this.state.email),
            name: this.state.firstname === null || this.state.firstname.trim().length === 0 || this.state.lastname === null || this.state.lastname.trim().length === 0

        };

        const actions = {
            mobile: (
                <DialogActions>
                    <Button
                        onClick={(event) => this.handleCancel("mobile")}
                        color="primary"
                    >
                        {i18n("edit/dialog/cancel")}
                    </Button>
                    {(this.state.activePhoneStep < (this.getSteps().length - 1)) ? (
                        <Button
                            color="primary"
                            onClick={(event) => this.handlePhoneNext()}
                            disabled={disabled["mobile"]}
                        >
                            {i18n("profile/edit/dialog/next")}
                        </Button>
                    ) : (
                            <Button
                                color="primary"
                                onClick={(event) => this.handlePhoneFinish()}
                                disabled={disabled["mobile"]}
                            >
                                {i18n("profile/edit/dialog/finish")}
                            </Button>
                        )
                    }


                </DialogActions>
            ),
        };

        const isHost = this.state.roles && this.state.roles.host;
        const isGuest = this.state.roles && this.state.roles.guest;
        const isAuth = this.state.user !== null;


        return (


            <div>

                {!isAuth &&
                    <Timer time={4}>
                        <Redirect push to={`/`} />
                    </Timer>
                }

                {isAuth &&

                    <div>
                        <Header user={this.state.user} roles={this.state.roles}></Header>

                        <WrapperCSS>
                            <ContentCSS>

                                <LineCSS>
                                    <IconCSS src={asset_person} />
                                    <MainCSS>
                                        <DarkTextCSS>{this.state.user.firstname + " " + this.state.user.lastname}</DarkTextCSS>
                                        <LightTextCSS>{i18n("profile/name")}</LightTextCSS>
                                    </MainCSS>
                                    <EditCSS>
                                        <IconButton
                                            aria-haspopup="true"
                                            color="inherit"
                                            onClick={event => this.handleEdit("name")}
                                        >
                                            <img src={asset_edit} />
                                        </IconButton>
                                    </EditCSS>
                                </LineCSS>

                                <LineCSS>
                                    <HrCSS />
                                </LineCSS>

                                <LineCSS>
                                    <IconCSS src={asset_phone} />
                                    <MainCSS>
                                        <DarkTextCSS>{this.state.user.mobileprefix + " " + this.state.user.mobile}</DarkTextCSS>
                                        <LightTextCSS>{i18n("profile/mobile")}</LightTextCSS>
                                    </MainCSS>
                                    <EditCSS>
                                        <IconButton
                                            aria-haspopup="true"
                                            color="inherit"
                                            onClick={event => this.handleEdit("mobile")}
                                        >
                                            <img src={asset_edit} />
                                        </IconButton>
                                    </EditCSS>
                                </LineCSS>

                                <LineCSS>
                                    <HrCSS />
                                </LineCSS>

                                <LineCSS>
                                    <IconCSS src={asset_mail} />
                                    <MainCSS>
                                        <DarkTextCSS>{this.state.user.email}</DarkTextCSS>
                                        <LightTextCSS>{firebase.auth().currentUser.emailVerified ? i18n("profile/email") : i18n("profile/notVerifiedEmail")}</LightTextCSS>
                                    </MainCSS>
                                    <EditCSS>
                                        <IconButton
                                            aria-haspopup="true"
                                            color="inherit"
                                            onClick={event => this.handleEdit("email")}
                                        >
                                            <img src={asset_edit} />
                                        </IconButton>
                                    </EditCSS>
                                </LineCSS>

                                <LineCSS>
                                    <HrCSS />
                                </LineCSS>

                                <LineCSS>
                                    <IconCSS src={asset_keys} />
                                    <MainCSS>
                                        <DarkTextCSS>**********</DarkTextCSS>
                                        <LightTextCSS>{i18n(
                                            "profile/password",
                                            {
                                                age:
                                                    moment(this.state.user.passwordLastChanged ?
                                                        this.state.user.passwordLastChanged.toDate() :
                                                        this.state.user.created.toDate()).fromNow(false)
                                            })}</LightTextCSS>
                                    </MainCSS>
                                    <EditCSS>
                                        <IconButton
                                            aria-haspopup="true"
                                            color="inherit"
                                            onClick={event => this.handleEdit("password")}
                                        >
                                            <img src={asset_edit} />
                                        </IconButton>
                                    </EditCSS>
                                </LineCSS>

                                <LineCSS>
                                    <HrCSS />
                                </LineCSS>

                                {!isNullOrUndefined(this.state.user.address) &&

                                    <div>

                                        <LineCSS>
                                            <IconCSS src={asset_place} />
                                            <MainCSS>
                                                <DarkTextCSS>{this.state.user.address.street}</DarkTextCSS>
                                                <DarkTextCSS>{this.state.user.address.postalCode} {i18nText(this.state.user.address.city)}</DarkTextCSS>
                                                <DarkTextCSS>{i18nText(countries[this.state.user.address.country])}</DarkTextCSS>
                                                <LightTextCSS>{i18n("profile/place")}</LightTextCSS>
                                            </MainCSS>
                                            <EditCSS>
                                                <IconButton
                                                    aria-haspopup="true"
                                                    color="inherit"
                                                    onClick={event => this.handleEdit("address")}
                                                >
                                                    <img src={asset_edit} />
                                                </IconButton>
                                            </EditCSS>
                                        </LineCSS>

                                        <LineCSS>
                                            <HrCSS />
                                        </LineCSS>

                                    </div>
                                }

                                {isGuest &&
                                    <div>
                                        <LineCSS>
                                            <IconCSS src={asset__Iconcc}>
                                            </IconCSS>
                                            <MainCSS>
                                                {this.state.stripeCustomers.length > 0 ? (
                                                    <Carousel slidesToShow={1} slidesToShowL={1} slidesToShowM={1}
                                                        slidesToShowS={1}
                                                        width={"90%"}>

                                                        {this.state.stripeCustomers.map(stripeCustomer => {

                                                            return (
                                                                <Card
                                                                    key={stripeCustomer.data.sources.data[0].last4}
                                                                    number={`**** **** **** ${stripeCustomer.data.sources.data[0].last4}`}
                                                                    month={`${stripeCustomer.data.sources.data[0].exp_month}`.padStart(2, '0')}
                                                                    year={`${stripeCustomer.data.sources.data[0].exp_year}`.slice(-2).padStart(2, '0')}
                                                                    type={stripeCustomer.data.sources.data[0].brand.toLowerCase()}
                                                                    expired={new moment(`${stripeCustomer.data.sources.data[0].exp_year}-${stripeCustomer.data.sources.data[0].exp_month.toString().padStart(2, '0')}-01`).isBefore(new moment())}
                                                                    cvc={"123"}
                                                                    highlighted={null} />)
                                                        })}
                                                    </Carousel>


                                                ) :
                                                    (<div>
                                                        <HintCSS>{i18n("profile/noCC")}</HintCSS>
                                                        <CenterCSS width={"60%"} marginTop={"40px"}>


                                                            <StyledButton
                                                                label={i18n("profile/addCC")}
                                                                onClick={this.handleOpenCCDialog.bind(this)}
                                                                name="button_add"
                                                                working={this.state.working}
                                                                disabled={false} />


                                                        </CenterCSS>
                                                    </div>)
                                                }




                                            </MainCSS>
                                            <EditCSS>
                                                <IconButton
                                                    aria-haspopup="true"
                                                    color="inherit"
                                                    onClick={this.handleOpenCCDialog}
                                                >
                                                    <img src={asset_edit} />
                                                </IconButton>
                                            </EditCSS>
                                        </LineCSS>
                                        {this.state.back !== undefined &&

                                            <LineCSS>
                                                <IconCSS></IconCSS>
                                                <MainCSS>
                                                    <div>
                                                        <CenterCSS width={"50%"} marginTop={"0px"}>


                                                            <StyledButton
                                                                label={i18n("profile/back")}
                                                                onClick={(e) => { window.location = this.state.back }}
                                                                name="button_back"
                                                                working={this.state.working}
                                                                disabled={false} />


                                                        </CenterCSS>
                                                    </div>
                                                </MainCSS>
                                                <EditCSS></EditCSS>
                                            </LineCSS>
                                        }

                                        <LineCSS>
                                            <HrCSS />
                                        </LineCSS>
                                    </div>
                                }

                                {isGuest &&
                                    <div>


                                        <LineCSS>
                                            <IconCSS src={asset_tags}>
                                            </IconCSS>
                                            <MainCSS>
                                                <DarkTextCSS>{i18n("profile/tags")}</DarkTextCSS>
                                                <TagsCSS>

                                                    {this.state.user.tags.length === 0 &&
                                                        <HintCSS>{i18n("profile/tags/none")}</HintCSS>
                                                    }

                                                    {this.state.user.tags.map(tag =>
                                                        <Tag tag={tag} editable={this.state.showDeleteTagOption}
                                                            onDelete={this.handleOpenDeleteTag.bind(this)}>{(tag.type === "city") ? tag.label : i18nConfiguration(tag.type, tag.label)}</Tag>
                                                    )}

                                                </TagsCSS>
                                            </MainCSS>
                                            <EditCSS>
                                                {this.state.user.tags.length > 0 &&
                                                    <IconButton
                                                        aria-haspopup="true"
                                                        color="inherit"
                                                        onClick={this.toggleShowDeleteTagOption.bind(this)}
                                                    >
                                                        <img src={asset_edit} />
                                                    </IconButton>
                                                }
                                            </EditCSS>
                                        </LineCSS>


                                        <LineCSS>
                                            <HrCSS />
                                        </LineCSS>
                                    </div>
                                }

                                {isHost &&
                                    <div>


                                        <LineCSS>
                                            <IconCSS src={asset_bank} />
                                            <MainCSS>
                                                <DarkTextCSS>{this.state.user.requiresVAT ? i18n("profile/requiresVAT") : i18n("profile/noVAT")}</DarkTextCSS>
                                                {!isNullOrUndefined(this.state.user.vatID) &&
                                                    <DarkTextCSS>{i18n("profile/vatID", { vatID: this.state.user.vatID })}</DarkTextCSS>
                                                }
                                                {!isNullOrUndefined(this.state.user.vatID) &&
                                                    <DarkTextCSS>{i18n("profile/taxID", { taxID: this.state.user.taxID })}</DarkTextCSS>
                                                }
                                                <LightTextCSS>{i18n("profile/vat")}</LightTextCSS>
                                            </MainCSS>
                                            <EditCSS>
                                                <IconButton
                                                    aria-haspopup="true"
                                                    color="inherit"
                                                    onClick={event => this.handleEdit("vat")}
                                                >
                                                    <img src={asset_edit} />
                                                </IconButton>
                                            </EditCSS>
                                        </LineCSS>


                                        <LineCSS>
                                            <HrCSS />
                                        </LineCSS>
                                    </div>
                                }


                                {isHost &&
                                    <div>


                                        <LineCSS>
                                            <StripeCSS>


                                                <CenterCSS width={"100%"} marginTop={"40px"}>
                                                    <HintSmallCSS>{i18n("profile/stripe")}</HintSmallCSS>
                                                </CenterCSS>
                                                <CenterCSS width={"60%"} marginTop={"40px"}>
                                                    <StyledGreyButton
                                                        label={i18n("profile/goToStripe")}
                                                        onClick={(e) => {
                                                            window.open(
                                                                'https://dashboard.stripe.com',
                                                                '_blank'
                                                            )
                                                        }}
                                                        name="button_add"
                                                        working={this.state.working}
                                                        disabled={false} />
                                                </CenterCSS>


                                            </StripeCSS>
                                        </LineCSS>


                                        <LineCSS>
                                            <HrCSS />
                                        </LineCSS>
                                    </div>
                                }


                                {this.state.sourcePayments.length > 0 &&
                                    <div>
                                        <LineCSS>
                                            <IconCSS src={asset_money} />
                                            <PaymentsCSS>
                                                <Carousel slidesToShow={3} slidesToShowL={2} slidesToShowM={1}
                                                    slidesToShowS={1}
                                                    width={"100%"}>
                                                    {this.state.sourcePayments.map(payment => {
                                                        return (
                                                            <PaymentOut key={uuidv4()} payment={payment} user={this.state.user} />
                                                        );
                                                    })}
                                                </Carousel>
                                            </PaymentsCSS>
                                        </LineCSS>
                                        <LineCSS>
                                            <HrCSS />
                                        </LineCSS>

                                    </div>
                                }

                                {this.state.destinationPayments.length > 0 &&
                                    <div>
                                        <LineCSS>
                                            <IconCSS src={asset_money} />
                                            <PaymentsCSS>
                                                <Carousel slidesToShow={3} slidesToShowL={2} slidesToShowM={1}
                                                    slidesToShowS={1}
                                                    width={"100%"}>
                                                    {this.state.destinationPayments.map(payment => {
                                                        return (
                                                            <ReceivedPayment payment={payment} />
                                                        );
                                                    })}
                                                </Carousel>
                                            </PaymentsCSS>
                                        </LineCSS>
                                    </div>

                                }

                            </ContentCSS>
                            <PictureCSS>
                                <ProfileImageCSS onClick={() => {
                                    dropzoneRef.open()
                                }}>


                                    <Dropzone className="dragAndDropAreaProfile"
                                        onDrop={this.handleDrop.bind(this)}
                                        accept="image/*"
                                        multiple={true}
                                        ref={(node) => {
                                            dropzoneRef = node;
                                        }}>

                                        {this.state.workingUpload &&
                                            <StyledProgressCSS>
                                                <StyledProgress />
                                            </StyledProgressCSS>
                                        }

                                        {(this.state.showImageEdit && !this.state.workingUpload) &&

                                            <EditImageCSS onMouseOut={this.handleMouseOut.bind(this)}>
                                                <EditImageTextCSS>
                                                    {i18n("profile/edit")}
                                                </EditImageTextCSS>
                                            </EditImageCSS>

                                        }

                                        {!this.state.workingUpload &&
                                            <ImageCSS src={this.state.image ? this.state.image : placeholder}
                                                onMouseOver={this.handleMouseOver.bind(this)} />
                                        }
                                    </Dropzone>

                                </ProfileImageCSS>

                                {isHost &&

                                    <AboutCSS>
                                        <AboutTextCSS>
                                            {isNullOrUndefined(i18nText(this.state.user.about)) ? (
                                                i18n("profile/about/empty")
                                            ) : (
                                                    <ReactMarkdown source={i18nText(this.state.user.about)} />
                                                )
                                            }
                                        </AboutTextCSS>
                                        <AboutEditCSS>
                                            <IconButton
                                                aria-haspopup="true"
                                                color="inherit"
                                                onClick={event => this.handleEdit("about")}
                                            >
                                                <img src={asset_edit} />
                                            </IconButton>
                                        </AboutEditCSS>

                                    </AboutCSS>
                                }


                                <LanguageCSS>
                                    <LanguageLabelCSS>{i18n("profile/language")}</LanguageLabelCSS>
                                    <LanguageTextCSS
                                        onClick={this.handleClickLanguage}>{i18nConfiguration("language", this.state.user.language)}
                                    </LanguageTextCSS>

                                    <Menu
                                        id="fade-languages"
                                        anchorEl={this.state.anchorLanguagesEl}
                                        open={Boolean(this.state.anchorLanguagesEl)}
                                        onClose={this.handleCloseLanguage}
                                    >
                                        <MenuItem
                                            onClick={this.handleChangeLanguage.bind(null, "de_DE")}>{i18nConfiguration('language', "de_DE")}</MenuItem>
                                        <MenuItem
                                            onClick={this.handleChangeLanguage.bind(null, "en_GB")}>{i18nConfiguration('language', "en_GB")}</MenuItem>

                                    </Menu>
                                </LanguageCSS>

                            </PictureCSS>
                        </WrapperCSS>

                        <NewCCDialog
                            onClose={this.handleCloseCCDialog}
                            open={this.state.openCCDialog}
                            firstname={this.state.user.firstname}
                            lastname={this.state.user.lastname}
                            working={this.state.working}
                            ccBrand={this.state.ccBrand}
                            ccHighlight={this.state.ccHighlight}
                            onCCHightlight={this.handleCCHightlight}
                            onCCCompleted={this.handleCCCompleted}
                            onCCBrand={this.handleCCBrand}
                            onCCToken={this.handleCCToken}
                        />

                        <Footer />
                    </div>


                }

                <StyledDialog
                    open={this.state.edit}
                    title={i18n("profile/edit/" + this.state.name + "/title")}
                    text={i18n("profile/edit/" + this.state.name + "/text")}
                    name={this.state.name}
                    working={this.state.working}
                    onCancel={this.handleCancel.bind(this)}
                    onSave={this.handleSave.bind(this)}
                    disabled={disabled[this.state.name]}
                    control={controls[this.state.name]}
                    actions={actions[this.state.name]}
                />

                <StyledDialog
                    open={this.state.openDeleteTagDialog}
                    title={i18n("profile/tag/title")}
                    text={i18n("profile/tag/text", this.state.tag ? { label: (this.state.tag.type === "city") ? this.state.tag.label : i18nConfiguration(this.state.tag.type, this.state.tag.label) } : {})}
                    name={"tag"}
                    working={this.state.working}
                    onCancel={this.handleCloseDeleteTagDialog.bind(this)}
                    onSave={this.handleDeleteTag.bind(this)}
                    okLabel={i18n("profile/tag/ok")}
                />


                <ErrorSnackbar message={this.state.systemError ? this.state.systemError.code : null}
                    open={!isNullOrUndefined(this.state.systemError)} onClose={(event) => {
                        console.log(this.state.systemError);
                        this.setState({ systemError: null })
                    }} />

            </div>
        );
    }
}

export default withDebug()(withStyles(styles)(Profile));
