import React from 'react';
import queryString from 'query-string';
import { setDEBUG } from "../common/I18n";

const withDebug = () => (Component) => {
    class WithDebug extends React.Component {

        constructor(props) {
            super(props);

            let debug = queryString.parse(this.props.location.search).debug;

            if (debug === undefined) {
                debug = false;
            }

             setDEBUG(debug);
        }

        render() {
            let { ...other } = this.props;
            return (
                <Component {...other} />
            );
        }
    }

    return WithDebug;
}

export default withDebug;