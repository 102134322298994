import React from 'react';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Stepper from "@material-ui/core/Stepper";

const components = {
    sm: 'em',
    md: 'u',
    lg: 'del',
};

function PhoneStepper(props) {
    const { width } = props;

    return (
        <Stepper orientation={(width==="xs") ?  "vertical" : "horizontal"} activeStep={props.activePhoneStep}>
            {props.steps.map((label, index) => {
                return (
                    <Step key={label}>
                        <StepButton
                            onClick={(event) => props.onStep(index)}
                            completed={index < props.activePhoneStep}
                        >
                            {label}
                        </StepButton>
                    </Step>
                );
            })}
        </Stepper>
    );
}

export default withWidth()(PhoneStepper);