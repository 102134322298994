import React, {Component} from 'react';
import MaskedInput from 'react-text-mask';
import emailMask from "text-mask-addons/dist/emailMask";
import {i18nText} from "./I18n";
import {get} from "geofirex";
import * as geofirex from "geofirex";
import firebaseApp from "./firebase";

const firestore = firebaseApp.firestore();

const geo = geofirex.init(firebaseApp);

if (!Array.prototype.last) {
    Array.prototype.last = function () {
        return this.length > 0 ? this[this.length - 1] : null;
    };
}
;


export function isEMailValid(email) {

    const re = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

    return re.test(email);

}

export function DateMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
            keepCharPositions={true}
            guide={false}
            showMask={false}
        />
    );
}

export function MobileMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={[/[1-9]/, /[1-9]/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            keepCharPositions={true}
            guide={false}
            showMask={false}
        />
    );
}

export function PostalCodeMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
            keepCharPositions={true}
            guide={false}
            showMask={false}
        />
    );
}

export function EMailMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={emailMask}
            keepCharPositions={false}
            guide={false}
            showMask={false}
        />
    );
}

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function populateTags(profile, event) {
    let tags = [...profile.tags];

    const score = (type, labels, array) => {


        let elements = array.filter(tag => tag.type === type);

        for (const label  of labels) {

            let element = elements.filter(element => element.label === label);

            if (element.length > 0) {
                element[0].score = element[0].score + 1;
            } else {
                tags.push({type: type, score: 1, label: label});
            }
        }

    };


    score("city", [i18nText(event.address.city)], tags);
    score("courtesy", event.courtesies, tags);
    score("cuisine", event.cuisines, tags);
    score("language", event.languages, tags);
    score("special", event.specials, tags);
    score("type", event.types, tags);

    return tags;


};

export async function getCity(country, city) {
    const cityName = city ? city.toLowerCase() : null;

    const fsCities = await firestore.collection("cities").orderBy("search")
        .startAt(cityName)
        .endAt(cityName+"~").limit(5).get();

    let cities = [];

    for (const fsCity of fsCities.docs) {
        cities.push(fsCity.data());
    }

    const selectedCity = cities.length > 0 ? cities[0] : null;

    return {
        country: country,
        city: selectedCity ? selectedCity.label : null,
        geopoint: selectedCity ? geo.point(selectedCity.lat, selectedCity.lon) : null
    };
}

export function getEventsNearby(geopoint) {

    const events = geo.collection('events');
    const radius = 200; // km
    const field = 'geo';

    const query = events.within(geopoint, radius, field);
    return get(query).then(result => {

        result.sort((a, b) => (a.queryMetadata.distance - b.queryMetadata.distance));

        return result.filter(entry => entry.status === "running");

    });
};

export function sortEventsBy(events, tags) {

    let runningEvents = events.filter(event => event.status === "running");

    let scoredEvents = runningEvents.map(event => {

        let score = -event.price.amount;
        score = score - event.start.toDate().getMilliseconds();
        for (const tag of tags) {


            switch (tag.type) {
                case "city":
                    if (i18nText(event.address.city) === tag.label) {
                        score += 100 * tag.score;
                    }
                    break;
                case "courtesy":
                    if (event.courtesies.includes(tag.label)) {
                        score += 100 * tag.score;
                    }

                    break;
                case "cuisine":

                    if (event.cuisines.includes(tag.label)) {
                        score += 100 * tag.score;
                    }

                    break;
                case "language":
                    if (event.languages.includes(tag.label)) {
                        score += 100 * tag.score;
                    }
                    break;
                case "special":
                    if (event.specials.includes(tag.label)) {
                        score += 100 * tag.score;
                    }
                    break;
                case "type":
                    if (event.types.includes(tag.label)) {
                        score += 100 * tag.score;
                    }
                    break;
                default:
                    break;
            }
        }


        return {...event, score: score}
    }).sort((a, b) => {return b.score - a.score});


    return scoredEvents;

}

export function calcFee(amount, fee) {

    const factor = parseFloat((amount * fee.percentage).toFixed(2));
    const remainer = factor - Math.floor(factor);

    if (remainer <= 0.09) {
        return Math.floor(factor);
    } else if (remainer <= 0.49) {
        return Math.floor(factor) + 0.5;
    } else if (remainer <= 0.59) {
        return Math.floor(factor) + 0.5;
    } else {
        return Math.floor(factor) + 1;
    }
}

export function calcStripeFee(amount) {

    return parseFloat((0.25 + 0.014 * amount).toFixed(2));
}

export function calcVAT(amount) {

    return parseFloat((amount/119*19).toFixed(2));
}

export const nf = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export function notNullOrUndefined(value) {
    return value !== null && value !== undefined;
}

export function isNullOrUndefined(value) {
    return !notNullOrUndefined(value);
}