import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import styled from "styled-components";
import placeholder from "../assets/placeholder.svg";
import {i18n} from "../common/I18n";
import {isNullOrUndefined} from "util";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import IconButton from "@material-ui/core/IconButton/IconButton";
import IconSend from "@material-ui/icons/Send";
import StyledTextArea from "./StyledTextArea";
import ReactDOM from "react-dom";
import asset_close from "../assets/close.svg";
import {uuidv4} from "../common/utils";
import ReactHtmlParser from "react-html-parser";
import ReactMarkdown from "react-markdown";


const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#FF6344',
            dark: '#FF6344',
            // contrastText: will be calculated to contast with palette.primary.main
        },
        secondary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
    },

    typography: {
        useNextVariants: true,
    },


    overrides: {
        MuiTypography: {
            root: {
                fontFamily: 'TruenoRegular',
            },
            title: {
                fontFamily: 'TruenoRegular',
                color: '#04ADA9',
            },
            h6: {
                fontFamily: 'TruenoRegular',
                color: '#04ADA9',
            },
        },

        MuiDialogContentText: {
            root: {
                fontFamily: 'TruenoLight',
                marginBottom: "40px",
                minWidth: "400px"
            },
        },
        MuiButton: {
            root: {
                fontFamily: 'TruenoRegular',
            },
        },

        MuiStepLabel: {
            root: {
                fontFamily: 'TruenoLight',
            },
            label: {
                fontFamily: 'TruenoLight',
            },
        },
        MuiStepIcon: {
            text: {
                fontFamily: 'TruenoLight',
                fill: "#ffffff",
            },
            root: {
                color: '#EEEEEE',
                '&$active': {
                    color: '#04ADA9',
                    text: {
                        fill: '#EEEEEE',
                    }
                },
                '&$completed': {
                    color: '#04ADA9',
                    text: {
                        fill: '#EEEEEE',

                    },
                }
            }

        },
        MuiDialogActions: {
            root: {
                justifyContent: "space-evenly",
            },
        },
        MuiFormControlLabel: {
            root: {
                marginBottom: '24px',
            },
        },
    }
});

const MessageLineLeftCSS = styled.div`
display: flex;
justify-content: flex-start;
`;

const MessageLineRightCSS = styled.div`
display: flex;
justify-content: flex-end;
`;


const BallonGreenCSS = styled.div`
    border-radius: 20px;
    background: var(--green);
    color: #ffffff;
    padding: 20px;
    text-align: left;
    font-family: TruenoLight;
font-size: 14px;
letter-spacing: 1.5px;
text-align: left;
    position: relative;
&:after {
	content: '';
	bottom: 0;
	left: 30px;
	width: 0;
	height: 0;
	position: absolute;
	border: 7px solid transparent;
	border-top-color: var(--green);
	border-bottom: 0;
	border-left: 0;
	margin-left: -3.5px;
	margin-bottom: -7px;
};
`;


const BallonWhiteCSS = styled.div`
    border-radius: 20px;
    background: #ebebeb;
    color: var(--green);
    padding: 20px;
    text-align: left;
    font-family: TruenoLight;
font-size: 14px;
letter-spacing: 1.5px;
    position: relative;
&:after {
	content: '';
	bottom: 0;
	right: 30px;
	width: 0;
	height: 0;
	position: absolute;
	border: 7px solid transparent;
	border-top-color: #EBEBEB;
	border-bottom: 0;
	border-right: 0;
	margin-left: -3.5px;
	margin-bottom: -7px;
};
`;


const MessageCSS = styled.div`
display: flex;
flex-direction: column;
margin-top: 24px;
margin-bottom: 24px;
position: relative;
`;

const ProfileLineCSS = styled.div`
margin-top: 8px;
display: flex;
justify-content:${props => (props.align === "left") ? "flex-start" : "flex-end"};
align-items: center;
`;

const ProfileImageCSS = styled.img`
width:42px;
height:42px;
border-radius: 100%;
object-fit: cover;
`;

const ProfileNameCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 12.6px;
color: #333333;
letter-spacing: 1.35px;
line-height: 19.8px;
text-transform: uppercase;
margin-left: 16px;
margin-right: 16px;
`;


class Message extends Component {

    constructor(props) {
        super(props);
    }


    render() {

        return (

            <div>

                {this.props.align === "left" ? (
                    <MessageCSS>
                        <MessageLineLeftCSS>
                            <BallonGreenCSS width={this.props.width}>
                                <ReactMarkdown>
                                    {this.props.text}
                                </ReactMarkdown>
                            </BallonGreenCSS>
                        </MessageLineLeftCSS>
                        <ProfileLineCSS align={this.props.align}>
                            <ProfileImageCSS src={this.props.image ? this.props.image : placeholder}/>
                            <ProfileNameCSS>{this.props.name} </ProfileNameCSS>
                        </ProfileLineCSS>
                    </MessageCSS>
                ) : (
                    <MessageCSS>
                        <MessageLineRightCSS>
                            <BallonWhiteCSS width={this.props.width}>
                                <ReactMarkdown>
                                    {this.props.text}
                                </ReactMarkdown>
                            </BallonWhiteCSS>
                        </MessageLineRightCSS>

                        <ProfileLineCSS align={this.props.align}>
                            <ProfileNameCSS>{this.props.name} </ProfileNameCSS>
                            <ProfileImageCSS src={this.props.image ? this.props.image : placeholder}/>
                        </ProfileLineCSS>
                    </MessageCSS>
                )}


            </div>
        )

    }
}

const HintCSS = styled.div`
width:90%;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 80px;
opacity: 0.1;
font-family: TruenoLight;
font-size: 34px;
color: #333333;
letter-spacing: -0.49px;
line-height: 42px;
text-transform: uppercase;
user-select: none;
text-align: center;
`;

const CloseCSS = styled.div`
opacity: 0.8;
display: flex;
justify-content: center;
align-items:center;
min-width: 42px;
min-height: 42px;
background-color: var(--red);
user-select: none;
border-radius: 100%;
position: absolute;
right:16px;
top:16px;
&:hover {
cursor: pointer;
opacity: 1;
}
&:active {
opacity: 0.8;
cursor: pointer;
}
`;

class ChatWindow extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            message: "",
            scrollToBottom: true,

        };

        this.last = React.createRef();
    }

    handleMouseDownSend = event => {
        event.preventDefault();
    };

    handleClickSend = () => {

        const message = this.state.message;
        this.setState({message: ""}, () => {
            this.props.onSend(this.props.reference, message);
        })
    };

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    componentDidUpdate = () => {

        if (this.state.scrollToBottom) {

                if (this.refs.last) {
                    this.refs.last.scrollIntoView();
                    this.props.onRead(this.props.messages);
                }
        }
    };

    render() {
        const {fullScreen} = this.props;

        return (
            <MuiThemeProvider theme={theme}>

                <Dialog
                    fullScreen={fullScreen}
                    open={this.props.open}
                    onClose={(e) => this.props.onClose(this.props.reference)}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={"sm"}
                    fullWidth={true}
                >
                    <DialogTitle
                        id="responsive-dialog-title">{i18n("chat/title", {name: this.props.name})}
                        <CloseCSS onClick={(e) => this.props.onClose(this.props.reference)}>
                            <img src={asset_close} width={16} height={16}/>
                        </CloseCSS> </DialogTitle>
                    <DialogContent>

                        {this.props.messages.length === 0 &&
                        <HintCSS>{i18n("chat/noMessages", {name: this.props.name})}</HintCSS>
                        }
                        {this.props.messages.map(message => {
                            return (
                                <Message key={uuidv4()} text={message.text}
                                         image={message.image}
                                         name={message.other ? message.name : i18n("chat/you")}
                                         align={message.other ? "left" : "right"}/>)

                        })}
                        <div ref={"last"}/>

                    </DialogContent>
                    <DialogActions>
                        <StyledTextArea
                            label={i18n("chat/message", {name: this.props.name})}
                            name="message"
                            value={this.state.message}
                            onChange={this.handleChange.bind(this)}
                            disabled={this.props.working}
                            width={"100%"}
                            rowsMax={"5"}
                            height={100}
                            inputLabelFontSize={"18px"}
                            inputLabelColor={"#04ADA9"}
                            error={isNullOrUndefined(this.props.error) ? null : i18n("error/" + this.props.error)}
                            endAdornment={

                                <InputAdornment position="end" style={{marginRight:"10px"}}>
                                    <IconButton
                                        aria-label="Toggle password visibility"
                                        disabled={this.state.message === null || this.state.message.trim().length === 0}
                                        onClick={this.handleClickSend}
                                        onMouseDown={this.handleMouseDownSend}
                                        color={"secondary"}
                                    >
                                        <IconSend/>
                                    </IconButton>
                                </InputAdornment>
                            }
                            required={false}
                            autoFocus={true}/>
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider>
        );
    }
}

ChatWindow.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(ChatWindow);