import React, { Component } from 'react';
import styled from 'styled-components';


import { Flex } from "../common/Layout";


import heart from './../assets/heart.svg';
import logo from './../assets/logo_white.svg';
import culinarioo from './../assets/culinarioo.svg';
import quote from './../assets/quote_grey.svg';
import tweete from './../assets/tweete.svg';
import typeWriter from './../assets/typewriter.svg';
import share from './../assets/googleshare.svg';

import firebaseApp from "../common/firebase";
import * as firebase from "firebase";
import { i18n, i18nRaw, i18nText } from "../common/I18n";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton/IconButton";
import IconFavorite from "@material-ui/icons/FavoriteBorder";
import IconUnfavorite from "@material-ui/icons/Favorite";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton, WhatsappIcon, WhatsappShareButton
} from "react-share";
import Menu from "@material-ui/core/Menu/Menu";
import { List } from "react-content-loader";
import Carousel from "./Carousel";
import { isNullOrUndefined } from "util";
import { uuidv4, notNullOrUndefined } from "../common/utils";

const firestore = firebaseApp.firestore();
const storage = firebaseApp.storage();

const Wrapper = styled.div`
margin-left: ${props => props.margin ? props.margin : "5%"};
margin-top: 40px;
margin-right: ${props => props.margin ? props.margin : "5%"};
`;

const BlockContainer = styled.div`
width:100%;
display: grid;
grid-template-columns:repeat(4, 1fr);
@media screen and (max-width: 1200px) {
grid-template-columns:repeat(3, 1fr);
}
@media screen and (max-width: 900px) {
grid-template-columns:repeat(2, 1fr);
}
@media screen and (max-width: 400px) {
grid-template-columns:repeat(1, 1fr);
}
grid-column-gap:40px;
grid-row-gap:24px;
margin-top: 24px;
`;

const Column = styled.div`
width:100%;
`;

const BlockCSS = styled.div`
box-shadow: none ;
background-image: url(${props => props.image});
background-size: cover;
background-repeat: no-repeat;
border-radius: 8px;
background-position:center bottom;
margin-bottom: 24px;
opacity: 1;
width:100%;
height: ${props => {
        switch (props.size) {
            case 'normal':
                return "430px";
            case 'medium':
                return "344px";
            case 'small':
                return "287px";
            case 'large':
                return "473px";
        }
    }};
@media screen and (max-width: 1200px) {
height: ${props => {
        switch (props.size) {
            case 'normal':
                return "calc(0.9*430px)";
            case 'medium':
                return "calc(0.9*344px)";
            case 'small':
                return "calc(0.9*287px)";
            case 'large':
                return "calc(0.9*473px)";
        }
    }}
};
@media screen and (max-width: 900px) {
height: ${props => {
        switch (props.size) {
            case 'normal':
                return "calc(0.8*430px)";
            case 'medium':
                return "calc(0.8*344px)";
            case 'small':
                return "calc(0.8*287px)";
            case 'large':
                return "calc(0.8*473px)";
        }
    }}
};
@media screen and (max-width: 400px) {
height: ${props => {
        switch (props.size) {
            case 'normal':
                return "calc(0.7*430px)";
            case 'medium':
                return "calc(0.7*344px)";
            case 'small':
                return "calc(0.7*287px)";
            case 'large':
                return "calc(0.7*473px)";
        }
    }}
};
position: relative;
&:hover {
box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
cursor: pointer;
}
&:active {
box-shadow: none;
cursor: pointer;
}
`;

const Box = styled.div`
position: absolute;
width: 100%;
background: rgba(255,255,255,0.95);
border-radius: 0 0 8px 8px;
height: 92px;
bottom: 0px;
`;

const BoxTranparent = styled.div`
position: absolute;
width: 100%;
border-radius: 0 0 8px 8px;
height: 150px;
bottom: 0px;
`;

const Title1 = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;  
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
margin-top: 8px;
width: 100%;
text-transform: uppercase;
`;

const Title2 = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 9px;
margin-top: 3px;
color: #333333;
letter-spacing: 0.16px;
text-align: center;
line-height: 16px;
width: 100%;
text-transform: uppercase;
`;

const Title3 = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
margin-top: 3px;
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
width: 100%;
text-transform: uppercase;
`;


const Title = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 34px;
color: #333333;
letter-spacing: -0.49px;
line-height: 42px;
text-transform: uppercase;
margin-top: 40px;
@media screen and (max-width: 400px) {
font-size: 24px;
}
`;

const ToolsCSS = styled.div`
position: absolute;
right:8px;
top:8px;
display: flex;
flex-direction: column;
justify-content: space-between;
z-index: 9;
`;

const Heart = styled.img`
width: 22px;
height: 22px;
opacity: 0.7;
&:hover {
opacity: 1;
cursor: pointer;
`;

const Share = styled.img`
width: 22px;
height: 22px;
opacity: 0.7;
&:hover {
opacity: 1;
cursor: pointer;
`;

const Tweete = styled.img`
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
width: 80%;
opacity: 0.1;
position: absolute;
top:20.0%;
`;

const QuotationMarks = styled.img`
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
width: 80%;
opacity: 0.1;
position: absolute;
top:20.0%;
`;

const TypeWriter = styled.img`
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
width: 80%;
opacity: 1;
position: absolute;
top:20.0%;
`;

const Quote = styled.div`
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
opacity: 0.8;
letter-spacing: 0;
text-align: center;
position: absolute;
width: 90%;
top:25.0%;
`;

const ProfilePic = styled.img`
display: block;
margin-left: auto;
margin-right: auto;
width: 54px;
height: 54px;
border-radius: 100%;
position: relative;
`;

const SmallLogo = styled.img`
display: block;
margin-left: auto;
margin-right: auto;
width: 40px;
position: relative;
margin-top: 16px;
margin-bottom: 16px;
`;

const Culinarioo = styled.img`
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 16px;
bottom:0px;
left:34%;
position: absolute;
width:33%;
`;

const sizes = [
    "normal",
    "medium",
    "small",
    "large"
];


class EventsWall extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: 4,
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    updateDimensions() {

        let w = window,
            d = document,
            documentElement = d.documentElement,
            body = d.getElementsByTagName('body')[0],
            width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
            height = w.innerHeight || documentElement.clientHeight || body.clientHeight;

        if (width < 1200 && width >= 900) {
            this.setState({ columns: 3 });
        } else if (width < 900 && width > 500) {
            this.setState({ columns: 2 });
        } else if (width <= 500) {
            this.setState({ columns: 2 });
        } else {
            this.setState({ columns: 4 });
        }

    }

    UNSAFE_componentWillMount() {
        this.updateDimensions();

    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);


    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    getBlocks(entries, column, isCaroussel = false) {

        let startSize = column;

        return entries.map((entry, index) => {

            const size = isCaroussel ? sizes[0] : sizes[startSize++ % 4];

            if ((index % (this.state.columns) === column) || isCaroussel) {

                switch (entry.type) {

                    case 'tweet':
                        return (
                            <TwitterBlock
                                key={uuidv4()}
                                url={entry.url}
                                size={sizes[0]}
                                handle={entry.handle}
                                hashtag={entry.hashtag}
                                tweet={entry.tweet}
                                image={entry.image} />
                        );
                    case 'news':
                        return (
                            <NewsBlock
                                key={uuidv4()}
                                url={entry.url}
                                size={sizes[0]}
                                news={entry.news}
                                title={entry.title} />
                        );
                    case 'image':
                        return (
                            <ImageBlock
                                key={uuidv4()}
                                url={entry.url}
                                size={sizes[0]}
                                image={entry.image}
                            />
                        );
                    case 'quote':
                        return (
                            <QuoteBlock
                                key={uuidv4()}
                                size={sizes[0]}
                                name={entry.name}
                                subtitle={entry.subtitle}
                                quote={entry.quote}
                                image={entry.image} />
                        );
                    case 'standard':
                    case 'default':
                    default:
                        return (
                            <Block
                                key={uuidv4()}
                                size={size}
                                title={entry.title}
                                tag={entry.tag}
                                text={entry.text}
                                id={entry.id}
                                image={entry.image}
                                subtitle={entry.subtitle}
                                url={`${window.location.origin}/api/shared/event/${entry.id}`}
                                user={this.props.user}
                                favorite={false}
                                hanldeFavoriteEvent={this.handleFavoriteEvent}
                                hanldeUnFavoriteEvent={this.handleUnFavoriteEvent}
                            />
                        );
                }
            }

        })


    }

    getTimeLabel(event) {

        const morningStart = moment(event.date.toDate()).hours(6).minutes(0).seconds(0).milliseconds(0);
        const noonStart = moment(event.date.toDate()).hours(11).minutes(30).seconds(0).milliseconds(0);
        const afternoonStart = moment(event.date.toDate()).hours(14).minutes(30).seconds(0).milliseconds(0);
        const eveningStart = moment(event.date.toDate()).hours(17).minutes(30).seconds(0).milliseconds(0);

        const start = moment(event.start.toDate());

        let timeUntil = null;

        if (start.isBefore(morningStart)) {
            timeUntil = i18nRaw("eventWall/earlyMorning");
        } else if (start.isBetween(morningStart, noonStart, null, "[)")) {
            timeUntil = i18nRaw("eventWall/morning");

        } else if (start.isBetween(noonStart, afternoonStart, null, "[)")) {
            timeUntil = i18nRaw("eventWall/noon");

        } else if (start.isBetween(afternoonStart, eveningStart, null, "[]")) {
            timeUntil = i18nRaw("eventWall/afternoon");

        } else if (start.isAfter(eveningStart)) {
            timeUntil = i18nRaw("eventWall/evening");
        } else {
            timeUntil = i18nRaw("eventWall/unknown");
        }

        return `${start.format('DD.MM. | dddd')} | ${timeUntil}`;
    }

    shuffleArray(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    render() {

        const events = this.props.events
            .map(event => {
                return {
                    type: "event",
                    title: this.getTimeLabel(event),
                    text: i18nText(event.title),
                    id: event.id,
                    tag: !isNullOrUndefined(event.tag) ? i18nText(event.tag.title) : undefined,
                    subtitle: i18nText(event.address.city),
                    image: event.images[event.mainImageIndex],
                    reference: event.reference,
                    start: event.start,
                }
            })
            .filter((event, index, array) => {

                const arraytWithoutIndex = array.slice(index + 1);
                const count = arraytWithoutIndex.reduce((acc, event2) => {
                    return (event2.reference === event.reference) ? acc + 1 : acc
                }, 0);
                return (count === 0);

            });
        events.sort((a, b) => a.start.seconds - b.start.seconds);


        const news = this.props.news.filter(entry => entry.visible).sort((a, b) => b.priority - a.priority).map(newsEntry => {
            return {
                type: "news",
                title: i18nText(newsEntry.title),
                news: i18nText(newsEntry.news),
                url: newsEntry.url,

            }
        });

        const tweets = this.props.tweets.filter(entry => entry.visible).sort((a, b) => b.priority - a.priority).map(tweet => {
            return {
                type: "tweet",
                handle: `@${tweet.handle}`,
                tweet: i18nText(tweet.tweet),
                hashtag: i18nText(tweet.hashtag),
                url: tweet.url,
                image: `https://avatars.io/twitter/${tweet.handle}`
            }

        });

        const quotes = this.props.quotes.filter(entry => entry.visible).sort((a, b) => b.priority - a.priority).map(quote => {
            return {
                type: "quote",
                name: quote.name,
                quote: i18nText(quote.quote),
                subtitle: i18nText(quote.subtitle),
                image: quote.url
            }

        });

        const images = this.props.images.filter(entry => entry.visible).sort((a, b) => b.priority - a.priority).map(imageEntry => {
            return {
                type: "image",
                url: imageEntry.url,
                image: imageEntry.image,
            }
        });

        const pattern = "EIETEEEENEEQ";

        let entries = [];

        const length = events.length + news.length + tweets.length + quotes.length + images.length;


        if (length > 0) {

            let indexTweets = 0;
            let indexNews = 0;
            let indexImages = 0;
            let indexQuotes = 0;
            let indexEvents = 0;
            let index = 0;
            let totalIndex = 0;
            do {


                switch (pattern.charAt(index % pattern.length)) {

                    case 'E':
                        if (indexEvents < events.length) {
                            entries.push(events[indexEvents]);
                            indexEvents++;
                        }

                        break;
                    case 'T':
                        if (indexTweets < tweets.length) {
                            entries.push(tweets[indexTweets]);
                            indexTweets++;
                        }
                        break;
                    case 'N':
                        if (indexNews < news.length) {
                            entries.push(news[indexNews]);
                            indexNews++;
                        }
                        break;
                    case 'I':
                        if (indexImages < images.length) {
                            entries.push(images[indexImages]);
                            indexImages++;
                        }
                        break;
                    case 'Q':
                        if (indexQuotes < quotes.length) {
                            entries.push(quotes[indexQuotes]);
                            indexQuotes++;
                        }
                        break;

                }

                index++;
                totalIndex = indexTweets + indexNews + indexQuotes + indexEvents + indexImages;


            } while (totalIndex < length);
        }

        const displayWall = events.length > 10;

        if (events.length === 0) {
            return null;
        }

        return (
            <Wrapper margin={this.props.margin}>
                <Flex>
                    <Title>{this.props.title}</Title>
                </Flex>


                {
                    this.props.loading ? (
                        <List width={"400px"} style={{
                            display: 'block',
                            marginTop: "64px",
                            marginBottom: "64px",
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }} />
                    ) : (
                            <div style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}>

                                {displayWall ? (
                                    <BlockContainer>

                                        {Array.from(Array(this.state.columns).keys()).map(number =>
                                            <Column key={uuidv4()}>
                                                {this.getBlocks(entries, number)}
                                            </Column>
                                        )}
                                    </BlockContainer>
                                ) : (
                                        <Carousel>
                                            {
                                                this.getBlocks(entries, 0, true)
                                            }
                                        </Carousel>
                                    )}
                            </div>
                        )
                }
            </Wrapper>


        )
            ;
    }
}

const SpecialCSS = styled.div`
opacity: 0.2;
background-image: linear-gradient(270deg, #36BDBA 32%, rgba(4,173,169,0.00) 100%);
position: absolute;
right: 0px;
bottom: 0px;
border-radius: 0 0 8px 0;
width: 50%;
line-height: 22px;
`;

const TagCSS = styled.div`
opacity: 1;
font-family: TruenoRegular;
font-size: 14px;
color: #04ADA9;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
text-transform: uppercase;
right: 0px;
bottom: 0px;
position: absolute;
padding-right: 8px;
`;

class Block extends Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorShareEl: null,
            favorite: null,
            loading: true,
        }


    }

    componentDidMount() {
        this.loadFavorites()
            .then(() => this.setState({loading: false}));
    }

    handleShare = event => {
        this.setState({ anchorShareEl: event.currentTarget });
    };

    handleClose = event => {
        this.setState({ anchorShareEl: null });
    };

    loadFavorites = async () => {

        await this.setState({ favorite: null }, async () => {

            try {

                const fsFavorites = await firestore.collection("favorites")
                    .where("uid", "==", this.props.user.uid)
                    .where("eventId", "==", this.props.id)
                    .get();

                let favorite = null;

                for (const fsFavorite of fsFavorites.docs) {
                    favorite = { ...fsFavorite.data() };
                    favorite.id = fsFavorite.id;
                }

                this.setState({ favorite: favorite });

            } catch (error) {
                this.setState({ systemError: error });
            }
        });
    };

    handleFavoriteEvent = async (id) => {

        try {
            await firestore.collection("favorites").add(
                { uid: this.props.user.uid, eventId: id, created: new Date() }
            );
            this.loadFavorites();
        } catch (error) {
            this.setState({ loading: false, systemError: error.code })
        }
    };

    handleUnFavoriteEvent = async (id) => {

        try {
            const fsFavorites = await firestore.collection("favorites")
                .where("eventId", "==", id)
                .where("uid", "==", this.props.user.uid)
                .get();
            for (const fsFavorite of fsFavorites.docs) {
                await fsFavorite.ref.delete();
            }
            this.loadFavorites();
        } catch (error) {
            this.setState({ loading: false, systemError: error.code })
        }
    };

    render() {
        return (

            <BlockCSS size={this.props.size} image={this.props.image}
                onClick={event => window.location = `/event/${this.props.id}`}>
                <ToolsCSS>

                    {notNullOrUndefined(this.state.favorite) ? (
                        <IconButton
                            aria-haspopup="true"
                            style={{color: '#FF6344', opacity: .9}}
                            onClick={async (event) => {
                                event.stopPropagation();
                                await this.handleUnFavoriteEvent(this.props.id) }
                            }
                        >
                            <IconUnfavorite/>
                        </IconButton>
                    ) : (
                        <IconButton
                            aria-haspopup="true"
                            style={{color: '#FFFFFF', opacity: .7}}
                            onClick={async (event) => {
                                event.stopPropagation();
                                await this.handleFavoriteEvent(this.props.id)}
                            }
                        >
                            <IconFavorite/>
                        </IconButton>
                    )}

                    <IconButton
                        aria-haspopup="true"
                        color="inherit"
                        onClick={(event) => {
                            event.stopPropagation();
                            this.handleShare(event);
                        }}
                    >
                        <Share src={share} />
                    </IconButton>


                    <Menu
                        id="share-menu"
                        anchorEl={this.state.anchorShareEl}
                        open={Boolean(this.state.anchorShareEl)}
                        onClose={this.handleClose}
                        style={{ zIndex: 10 }}
                        onClick={(event) => {
                            event.stopPropagation()
                        }}
                    >
                        <MenuItem>
                            <FacebookShareButton
                                url={this.props.url}
                                quote={this.props.text}>
                                <FacebookIcon
                                    size={32}
                                    round />
                            </FacebookShareButton>
                        </MenuItem>

                        <MenuItem>
                            <TwitterShareButton
                                url={this.props.url}
                                title={this.props.text}>
                                <TwitterIcon
                                    size={32}
                                    round />
                            </TwitterShareButton>
                        </MenuItem>

                        <MenuItem>
                            <TelegramShareButton
                                url={this.props.url}
                                title={this.props.text}>
                                <TelegramIcon size={32} round />
                            </TelegramShareButton>
                        </MenuItem>

                        <MenuItem>
                            <WhatsappShareButton
                                url={this.props.url}
                                title={this.props.text}
                                separator=" :: ">
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </MenuItem>

                        <MenuItem>
                            <EmailShareButton
                                url={this.props.url}
                                title={this.props.text}
                                body={i18n("share/emailBody")}>
                                <EmailIcon
                                    size={32}
                                    round />
                            </EmailShareButton>
                        </MenuItem>
                    </Menu>

                </ToolsCSS>
                <Box>
                    <Title1>{this.props.title}</Title1>
                    <Title2>{this.props.text}</Title2>
                    <Title3>{this.props.subtitle}</Title3>
                    {!isNullOrUndefined(this.props.tag) &&
                        <div>
                            <SpecialCSS>
                                &nbsp;
                            </SpecialCSS>
                            <TagCSS>
                                {this.props.tag}
                            </TagCSS>
                        </div>
                    }


                </Box>
            </BlockCSS>

        )
    }
}

class TwitterBlock extends Component {
    constructor(props) {
        super(props);


    }

    render() {
        return (
            <BlockCSS size={this.props.size} noShadow onClick={event => window.open(this.props.url,'_blank')}>
                <Tweete src={tweete} />
                <Quote>{this.props.tweet}</Quote>
                <BoxTranparent>
                    <ProfilePic src={this.props.image} />
                    <Title1>{this.props.handle}</Title1>
                    <Title2>{this.props.hashtag}</Title2>
                </BoxTranparent>
            </BlockCSS>

        )
            ;
    }

}

class QuoteBlock extends Component {
    constructor(props) {
        super(props);


    }

    render() {
        return (
            <BlockCSS size={this.props.size} noShadow>
                <QuotationMarks src={quote} />
                <Quote>{this.props.quote}</Quote>
                <BoxTranparent>
                    <ProfilePic src={this.props.image} />
                    <Title1>{this.props.name}</Title1>
                    <Title2>{this.props.subtitle}</Title2>
                </BoxTranparent>
            </BlockCSS>

        )
            ;
    }

}

class NewsBlock extends Component {
    constructor(props) {
        super(props);


    }

    render() {
        return (
            <BlockCSS size={this.props.size} noShadow onClick={event => window.open(this.props.url,'_blank')}>
                <TypeWriter src={typeWriter} />
                <Quote>{this.props.news}</Quote>
                <BoxTranparent>
                    <SmallLogo src={logo} />
                    <Title1>{this.props.title}</Title1>
                    <Culinarioo src={culinarioo} />
                </BoxTranparent>
            </BlockCSS>

        )
            ;
    }

}

class ImageBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageURL: '',
        };
    }

    componentDidMount() {
       this.getPreview(this.props.image)
           .then(imageURL => {
               this.setState({
                   imageURL: imageURL,
               });
           });
    }

    getPreview = (filePath) => {
        return new Promise((async (resolve, reject) => {
            await storage.ref().child(filePath).getDownloadURL()
                .then(async downloadURL => {
                    resolve(downloadURL);
                });
        }))
    };

    render() {

        const {imageURL} = this.state;

        return (
            <BlockCSS size={this.props.size} image={imageURL} onClick={event => window.open(this.props.url,'_blank')}>
            </BlockCSS>
        );
    }
}

export default EventsWall;
