import React from 'react';
import PropTypes from 'prop-types';
import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import styled from 'styled-components';


const ButtonContainer = styled.div`
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: none;
    font-family: TruenoLight;
    font-size: 8.4px;
    letter-spacing: 0;
    width: 80%;
    padding-top: 16px;
`;



const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#FF6344',
            // contrastText: will be calculated to contast with palette.primary.main
        },
        secondary: {
            main: '#49639F',
        },
        // error: will use the default color
    },

    typography: {
        useNextVariants: true,
    },


    overrides: {
        typography: {
            fontFamily:
                '"TruenoLight"'
        },

        MuiInputLabel: {
            root: {
                textTransform: 'uppercase',
                fontSize: '12px',
                height: 20,
            }
        },
        MuiInput: {
            root: {
                textTransform: 'uppercase',
                fontSize: '14px',
                height: 20,
                paddingTop: 8,
                paddingBottom: 8,
                letterSpacing: 0,
            },
        },
        MuiSelect: {
            root: {
                width:'100%',
            }
        },
        MuiFormControl: {
            root: {
                width: '100%',
            }
        },
        MuiButton: {
            root: {
                display: 'block',
marginLeft: 'auto',
marginRight: 'auto',
                borderRadius: 8, marginTop: 20,  fontFamily: "TruenoLight",
                paddingLeft:30,
                paddingRight:30,
                opacity: 0.8,
                fontSize: 14,
                letterSpacing: 1.5,
                marginBottom: '16px'
            },
            label : {
                color: '#ffffff',
            }
        }
    }
});


class P23Button extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (


                <MuiThemeProvider theme={theme}>
                    <ButtonContainer>
                        <Button variant="contained"
                                color="primary"
                                disabled={this.props.disabled}
                                onClick={this.props.onClick}>{this.props.label}</Button>
                    </ButtonContainer>


                </MuiThemeProvider>
        );
    }
}

export default (P23Button);