import React, {PureComponent} from 'react';

import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';

import styled from 'styled-components';
import TextField from "@material-ui/core/TextField";
import {isNullOrUndefined} from "util";

const TextFieldContainer = styled.div`

box-shadow: 0 2px 6px 0 rgba(0,0,0,0.50);
border-radius: 8px;
    padding-left: 16px;
    padding-top: 3px;
    padding-bottom: 8px;
    border: none;
    user-select: none;
                    letter-spacing: 2px;
    width: ${props => props.width};
`;



const materialTheme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
    },

    typography: {
        useNextVariants: true,
    },

    overrides: {
        MuiTypography: {
            body1: {
                fontFamily:
                    "TruenoRegular",
                textTransform: "uppercase",
                color: '#04ADA9',
            },
            caption: {
                fontFamily:
                    "TruenoLight"
            }
        },

        MuiFormHelperText: {
            root: {
                fontFamily: 'TruenoLight',
                letterSpacing: 1.5,
            },

            error: {
                color: "#FF6344"
            }
        },

        MuiFormLabel: {
            root: {
                fontFamily: 'TruenoLight',
                letterSpacing: 1.5,
                color: '#04ADA9',
            },

            error: {
                color: "#FF6344"
            }
        },


        MuiInputLabel: {
            root: {
                textTransform: 'uppercase',
                fontSize: '12px',
                height: "22px",
                color: '#E2E2E2',
                letterSpacing: 1.5,
                fontFamily: 'TruenoLight',
            }
        },
        MuiInput: {
            root: {
                textTransform: 'uppercase',
                fontSize: '16px',
                height: "100%",
                paddingTop: 8,
                paddingBottom: 8,
                fontFamily: 'TruenoRegular',
                letterSpacing: 2,

            },
        },

        MuiIconButton: {
            label: {
                fontFamily:
                    "TruenoLight"
            }
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#04ADA9',
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                // backgroundColor: lightBlue.A200,
                // color: 'white',
            },
        },
        MuiPickersDay: {

            day: {
                color: '#000000',
            },
            selected: {
                backgroundColor: '#FF6344',
                '&:hover': {
                    backgroundColor: '#FF6344',
                },
            },
            current: {
                color: '#04ADA9',
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: '#04ADA9',
            },
        },
    },
});


class StyledInlineDatePicker extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (


                <TextFieldContainer width={this.props.width}>

                    <MuiThemeProvider theme={materialTheme}>

                        <TextField
                            InputProps={{
                                endAdornment: this.props.endAdornment,
                                disableUnderline: true,
                                inputComponent: this.props.mask,
                                shrink:true
                            }}
                            placeholder={this.props.placeholder}
                            inputRef={field => this.textField = field}
                            name={this.props.name}
                            label={this.props.label}
                            fullWidth={true}
                            required={this.props.required}
                            value={this.props.value}
                            onChange={this.props.onChange}
                            defaultValue="01.01.1980"
                            type={"date"}
                            error={!isNullOrUndefined(this.props.error) && this.props.error}
                            disabled={this.props.disabled}
                            autoFocus={this.props.autoFocus}
                            helperText={this.props.error}
                        />

                    </MuiThemeProvider>
                </TextFieldContainer>
        );
    }
}

export default (StyledInlineDatePicker);
