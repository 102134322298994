import React from "react";
import {components} from "./consts";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';

import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import LineChart from 'recharts/lib/chart/LineChart';
import Line from 'recharts/lib/cartesian/Line';
import XAxis from 'recharts/lib/cartesian/XAxis';
import YAxis from 'recharts/lib/cartesian/YAxis';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Tooltip from 'recharts/lib/component/Tooltip';
import Legend from 'recharts/lib/component/Legend';
import firebaseApp from "../common/firebase";
import Grid from "@material-ui/core/Grid/Grid";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import {isNullOrUndefined} from "util";
import Typography from "@material-ui/core/Typography/Typography";
import Paper from "@material-ui/core/Paper/Paper";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardActions from "@material-ui/core/CardActions/CardActions";
import Button from "@material-ui/core/Button/Button";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const firestore = firebaseApp.firestore();


function SimpleLineChart(props) {

    const {data} = props;
    return (
        // 99% per https://github.com/recharts/recharts/issues/172
        <ResponsiveContainer width="99%" height={320}>
            <LineChart data={data}>
                <XAxis dataKey="name"/>
                <YAxis/>
                <CartesianGrid vertical={false} strokeDasharray="3 3"/>
                <Tooltip/>
                <Legend/>
                <Line type="monotone" dataKey="Events" stroke="#04ADA9"/>
                <Line type="monotone" dataKey="Users" stroke="#FF6344" activeDot={{r: 8}}/>
                <Line type="monotone" dataKey="Bookings" stroke="#2196f3" activeDot={{r: 8}}/>
            </LineChart>
        </ResponsiveContainer>
    );
}


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    head: {
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline"
    },
    header: {
        margin: theme.spacing.unit * 5
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
});

class Dashboard extends React.Component {
    state = {
        loading: true,
        data: [],
        period: "7days"
    };

    componentDidMount() {
        this.reload();
    }

    handleChange = event => {
        this.setState({[event.target.name]: event.target.value}, () => {
            this.reload()
        });
    };

    reload = () => {
        this.setState({loading: true}, async () => {
                try {

                    const data = [];

                    const days = [];

                    let numberOfDays = 7;
                    let when = moment();

                    switch (this.state.period) {

                        case "7days":
                            numberOfDays = 7;
                            when = moment();
                            break;
                        case "30days":
                            numberOfDays = 30;
                            when = moment();
                            break;
                        case "thisMonth":
                            numberOfDays = moment().date()-1;
                            when = moment();
                            break;
                        case "lastMonth":
                            numberOfDays = moment().subtract(1, "months").daysInMonth();
                            when = moment().subtract(1, "months").endOf("month");
                            break;
                        default:
                            numberOfDays = 7;
                    }


                    for (let day = numberOfDays; day >= 0; day--) {
                        const ago = moment(when).startOf('day').subtract(day, "days");
                        days.push(ago);
                    }

                    let fsEvents = await firestore.collection("events").where("created", ">", days[0].toDate()).get();
                    let fsProfiles = await firestore.collection("profiles").where("created", ">", days[0].toDate()).get();
                    let fsBookings = await firestore.collection("bookings").where("created", ">", days[0].toDate()).get();

                    const events = fsEvents.docs.reduce((accumulator, fsEntry) => {
                        const entry = fsEntry.data();

                        const created = moment(entry.created.toDate());
                        const dayname = created.format('D.M');
                        const count = !isNullOrUndefined(accumulator[dayname]) ? accumulator[dayname] : 0;
                        accumulator[dayname] = count + 1;
                        return accumulator;
                    }, {});

                    const profiles = fsProfiles.docs.reduce((accumulator, fsEntry) => {
                        const entry = fsEntry.data();

                        const created = moment(entry.created.toDate());
                        const dayname = created.format('D.M');
                        const count = !isNullOrUndefined(accumulator[dayname]) ? accumulator[dayname] : 0;
                        accumulator[dayname] = count + 1;
                        return accumulator;
                    }, {});

                    const bookings = fsBookings.docs.reduce((accumulator, fsEntry) => {
                        const entry = fsEntry.data();

                        const created = moment(entry.created.toDate());
                        const dayname = created.format('D.M');
                        const count = !isNullOrUndefined(accumulator[dayname]) ? accumulator[dayname] : 0;
                        accumulator[dayname] = count + 1;
                        return accumulator;
                    }, {});

                    for (const day of days) {

                        const label = day.format('D.M');

                        const eventsCount = !isNullOrUndefined(events[label]) ? events[label] : 0;
                        const usersCount = !isNullOrUndefined(profiles[label]) ? profiles[label] : 0;
                        const bookingsCount = !isNullOrUndefined(bookings[label]) ? bookings[label] : 0;

                        data.push({name: label, Events: eventsCount, Users: usersCount, Bookings: bookingsCount})
                    }

                    this.setState({data: data, loading: false});

                }
                catch
                    (error) {
                    console.error(error);
                    this.setState({loading: false});
                }

            }
        )

    };


    render() {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page, onMenuChange}) => (
                        <div>

                            {this.state.loading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress className={classes.progress} size={50}/>
                                    </Grid>
                                ) :
                                (
                                    <React.Fragment>


                                        <div className={classes.head}>

                                            <Typography variant={"h5"} className={classes.header} align={"center"}>Trend
                                                of </Typography>

                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="age-simple">Period</InputLabel>
                                                <Select
                                                    value={this.state.period}
                                                    onChange={this.handleChange}
                                                    inputProps={{
                                                        name: 'period',
                                                        id: 'period',
                                                    }}
                                                >
                                                    <MenuItem value={"7days"}>Last 7 days</MenuItem>
                                                    <MenuItem value={"30days"}>Last 30 days</MenuItem>
                                                    <MenuItem value={"lastMonth"}>Last month</MenuItem>
                                                    <MenuItem value={"thisMonth"}>This month</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>


                                        <SimpleLineChart data={this.state.data}/>

                                        <Typography variant={"h5"} className={classes.header} align={"center"}>Your next
                                            steps</Typography>

                                        <Grid container spacing={24}>
                                            <Grid item xs>
                                                <Card className={classes.card}>
                                                    <CardContent>
                                                        <Typography variant="h5" component="h2">
                                                            Users
                                                        </Typography>
                                                        <Typography component="p">
                                                            Check your user and see what's going on.
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button size="small"
                                                                onClick={e => onMenuChange(components.users)}>Go to
                                                            users</Button>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                            <Grid item xs>
                                                <Card className={classes.card}>
                                                    <CardContent>
                                                        <Typography variant="h5" component="h2">
                                                            Events
                                                        </Typography>
                                                        <Typography component="p">
                                                            Check your events and see what's going on.
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button size="small"
                                                                onClick={e => onMenuChange(components.events)}>Go to
                                                            events</Button>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                            <Grid item xs>
                                                <Card className={classes.card}>
                                                    <CardContent>
                                                        <Typography variant="h5" component="h2">
                                                            Bookings
                                                        </Typography>
                                                        <Typography component="p">
                                                            Check your bookings and see what's going on.
                                                        </Typography>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button size="small"
                                                                onClick={e => onMenuChange(components.bookings)}>Go to
                                                            bookings</Button>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        </Grid>


                                    </React.Fragment>

                                )}

                        </div>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles, {withTheme: true})(Dashboard)