import React, {Component} from 'react';
import styled from 'styled-components';

import asset_star from '../assets/star.svg';
import asset_filledStar from '../assets/filled_start.svg';
import {uuidv4} from "../common/utils";


const RatingBoxCSS = styled.div`
display: grid;
grid-template-columns: repeat(5, 1fr);
`;

const StarCSS = styled.img`
width: 14px;
height: 14px;
justify-self: center;
`;

class Rating extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        let stars = 0;

        if (this.props.score >= 800) {
            stars = 5;
        } else if (this.props.score >= 600) {
            stars = 4;
        } else if (this.props.score >= 400) {
            stars = 3;
        } else if (this.props.score >= 200) {
            stars = 2;
        } else {
            stars = 1;
        }

        return (

            <RatingBoxCSS style={this.props.style}>

                {Array(...Array(5)).map((_, i) => {

                    const asset = (i < stars) ? asset_filledStar : asset_star;
                    return (<StarCSS src={asset} key={uuidv4()}/>);

                })}


            </RatingBoxCSS>

        );
    }

}


export default Rating;
