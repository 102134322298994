import React, {Component} from 'react';

import logo_white from './../assets/logo_white.svg';
import facebook from './../assets/facebook.svg';
import twitter from './../assets/twitter.svg';
import instagram from './../assets/instagram.svg';
import rss from './../assets/rss.svg';
import globe from './../assets/globe.svg';
import select from './../assets/select.svg';
import Button from '@material-ui/core/Button';


import {i18n, i18nConfiguration} from "../common/I18n";


import styled from "styled-components";
import StyledDialog from "./StyledDialog";
import P23TextField from "./P23TextField";

import * as firebase from "firebase";
import 'firebase/firestore';
import 'firebase/storage';

import firebaseApp from '../common/firebase';
import {isNullOrUndefined} from "util";
import {isEMailValid} from "../common/utils";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import NewsletterSubscribe from "./NewsletterSubscribe";

firebaseApp.auth().useDeviceLanguage();
const firestore = firebaseApp.firestore();


const Wrapper = styled.div`
    margin-top: 24px;
    padding-left: 10.00%;
    padding-right: 10.00%;
    user-select: none;
`;

const Line = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-around;
    width: 100%;
    @media screen and (max-width: 970px) {
    flex-direction: column;
}
`;

const Logo = styled.div`
    margin-top: 32px;
    margin-bottom: 32px;
`;

const LinkWrapper = styled.div`
    @media screen and (max-width: 970px) {
        &:nth-child(1) {
            order: 2;
        };
        &:nth-child(2) {
            order: 1;
        };
        &:nth-child(3) {
            order: 1;
        };
        &:nth-child(4) {
            order: 1;
        };
        &:nth-child(5) {
            order: 1;
        };
    }
`;

const Link = styled.a`
    opacity: 0.8;
    font-family: TruenoRegular;
    font-size: 14px;
   
    @media screen and (max-width: 970px) {
        font-size: 10px;
        margin-bottom: 16px;
}
    color: #5C5C5C;
    letter-spacing: 0px;
    line-height: 22px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    ${
    props => {
        if (props.left) {
            return `text-align: left;`;
        } else if (props.right) {
            return `text-align: right;`;

        }
    }}
    &:hover {
cursor: pointer;
opacity: 1;
}
&:active {
cursor: pointer;
opacity: 0.5;
}
`;

const Social = styled.div`
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    width: 50%;
`;

const Image = styled.img`
    height: 24px;
    width: 24px;
    padding-right: 16px;
`;

const Language = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
`;

const Newsletter = styled.div`
    display: flex;
    align-items: flex-start;
    align-content: center;
    justify-content: flex-start;
`;

const InputText = styled.input`
    border: none;
    border-radius: 8px 0 0 8px;
    background: #F8F8F8;
    padding: 10px;
    opacity: 0.4;
    font-family: TruenoLight;
    font-size: 8.4px;
    color: #818181;
    letter-spacing: 0;
    line-height: 15.6px;
    width: 150px;
    margin: 0;
`;

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            error: null,
            working: false,
            feedback: null,
            feedbackSent: false,
            email: null,
        }


    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    handleCancel(key) {

        this.setState({
            working: false,
            open: false,
            feedback: null,
            error: null,
            email: null,
            feedbackSent: false,

        })
    };

    handleOk(key) {

        this.setState({
            working: false,
            open: false,
            feedback: null,
            error: null,
            email: null,
            feedbackSent: false,
        })
    };

    handleSave(key) {

        this.setState({
            working: true,
        }, () => {
            firestore.collection('feedback').add({
                uid: firebase.auth().currentUser ? firebase.auth().currentUser.uid : null,
                email: firebase.auth().currentUser ? firebase.auth().currentUser.email : this.state.email,
                feedback: this.state.feedback,
                created: new Date(),
            })
                .then(() => {
                    this.setState({
                        error: null,
                        feedback: null,
                        working: false,
                        email: null,
                        feedbackSent: true,
                    })
                })
                .catch((createError) => {
                    this.setState({error: createError.code, working: false});
                });
        });
    };


    render() {
        return (
            <Wrapper>

                <Line>
                    <Link href="/">
                        <Logo>
                            <center>
                                <img src={logo_white} alt="culinarioo"/>
                            </center>
                        </Logo>
                    </Link>
                </Line>

                <Line>
                    <LinkWrapper>
                        <Link href="/about" target={"__new"}>{i18n("footer/about")}</Link>
                    </LinkWrapper>
                    <LinkWrapper>
                        <Link href="/privacy" target={"__new"}>{i18n("footer/privacy")}</Link>
                    </LinkWrapper>
                    <LinkWrapper>
                        <Link href="/terms" target={"__new"}>{i18n("footer/terms")}</Link>
                    </LinkWrapper>
                    <LinkWrapper>
                        <Link onClick={event => this.setState({open: true})}>{i18n("footer/feedback")}</Link>
                    </LinkWrapper>
                </Line>

                <Line>
                    <Social>
                        <Link href="https://www.facebook.com/Culinarioo-182792569028065/" target={"__new"} right>
                            <Image src={facebook} alt="facebook"/>
                        </Link>
                        <Link href="https://www.twitter.com/culinarioo" target={"__new"} right>

                            <Image src={twitter} alt="twitter"/>
                        </Link>
                        <Link href="https://www.instagram.com/culinarioo" target={"__new"} right>
                            <Image src={instagram} alt="instagram"/>
                        </Link>
                    </Social>


                </Line>

                <StyledDialog
                    open={this.state.open}
                    title={i18n("feedback/title")}
                    text={this.state.feedbackSent ? i18n("feedback/thanks") : i18n("feedback/text")}
                    working={this.state.working}
                    name={"feedback"}
                    actions={
                        (this.state.feedbackSent) ? (
                            <DialogActions>
                                <Button onClick={event => this.handleOk("ok")} color="primary">
                                    {i18n("feedback/ok")}
                                </Button>
                            </DialogActions>
                        ) : (
                            <DialogActions>
                                <Button onClick={event => this.handleCancel("cancel")} color="primary">
                                    {i18n("edit/dialog/cancel")}
                                </Button>
                                <Button onClick={event => this.handleSave("save")} color="primary"
                                        disabled={this.state.feedback === null || this.state.feedback.length < 1 || (firebase.auth().currentUser ? false : (this.state.email === null || !isEMailValid(this.state.email)))}>
                                    {i18n("feedback/send")}
                                </Button>
                            </DialogActions>
                        )
                    }
                    control={
                        (!this.state.feedbackSent) ? (
                            <div>
                                {firebase.auth().currentUser ? (
                                    <P23TextField
                                        autoFocus={true}
                                        name="email"
                                        width={"100%"}
                                        label={i18n("feedback/email")}
                                        value={firebase.auth().currentUser.email}
                                        onChange={this.handleChange.bind(this)}
                                        disabled={true}
                                        multiline={false}
                                        inputProps={{
                                            disableUnderline: false,
                                        }}
                                    />
                                ) : (
                                    <P23TextField
                                        autoFocus={true}
                                        name="email"
                                        width={"100%"}
                                        label={i18n("feedback/email")}
                                        value={this.state.email}
                                        onChange={this.handleChange.bind(this)}
                                        error={this.state.error}
                                        multiline={false}
                                        inputProps={{
                                            disableUnderline: false,
                                        }}
                                    />
                                )}

                                <P23TextField
                                    autoFocus={false}
                                    name="feedback"
                                    width={"100%"}
                                    label={i18n("feedback/label")}
                                    value={this.state.feedback}
                                    onChange={this.handleChange.bind(this)}
                                    error={this.state.error}
                                    multiline={true}
                                    inputProps={{
                                        disableUnderline: true,
                                    }}
                                    rows={10}
                                />
                            </div>
                        ) : (<div/>)


                    }
                />


            </Wrapper>

        );
    }
}

export default Footer;
