import React from 'react';

import styled from 'styled-components';


const Wrapper = styled.div`
`;

const ButtonCSS = styled.div`
width:100%;
background: none;
font-family: TruenoRegular;
font-size: 12px;
color: #04ADA9;
letter-spacing: 1.5px;
text-align: ${props => props.align ? props.align : "left"};
line-height: 50px;
min-height: 50px;
text-transform: uppercase;
user-select: none;
transition: box-shadow 0.5s;
&:hover {
cursor: pointer;
font-family: TruenoBold;
}
&:active {
cursor: pointer;
font-family: TruenoRegular;
}
`;

const ButtonCSSDisabled = styled.div`
width:100%;
background: none;
font-family: TruenoRegular;
font-size: 12px;
color: #a9a9a9;
letter-spacing: 1.5px;
text-align: ${props => props.align ? props.align : "left"};
line-height: 50px;
min-height: 50px;
text-transform: uppercase;
user-select: none;
`;


class StyledHintButton extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (

            <div style={{width: '100%'}}>
                {(this.props.disabled) &&

                <ButtonCSSDisabled align={this.props.align}>
                    {this.props.label}
                </ButtonCSSDisabled>
                }

                {!(this.props.disabled) &&

                <ButtonCSS align={this.props.align}
                           onClick={event => this.props.onClick(this.props.name)}>
                    {this.props.label}
                </ButtonCSS>
                }
            </div>


        )
    }
}

export default (StyledHintButton);