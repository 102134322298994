import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';
import MUIDataTable from "mui-datatables";
import firebaseApp from "../common/firebase";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";
import moment from "moment";
import {isNullOrUndefined} from "util";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button/Button";
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {i18nText} from "../common/I18n";
import CheckedIcon from "@material-ui/icons/CheckBox";
import UncheckedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import RefreshIcon from "@material-ui/icons/Refresh";


const firestore = firebaseApp.firestore();


const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
});

class Tweets extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            working: false,
            values: [],
            showDialog: false,
            editMode: false,
            value: null,
            visible: false,
            priority: "0",
            name: "",
            quote: "",
            url: "",
            subtitle: "",
        };
    }


    componentDidMount() {
        this.reload();
    }

    reload = () => {
        this.setState({loading: true}, async () => {
            try {

                let fsValues = await firestore.collection("quotes").get();

                let values = [];

                for (const fsValue of fsValues.docs) {

                    const {created, ...other} = fsValue.data();
                    values.push({created: created.toDate(), ...other, ref: fsValue.ref});
                }

                this.setState({values: values, loading: false});
            } catch (error) {
                console.error(error);
                this.setState({loading: false});
            }

        })

    };

    handleDelete = (selectedRows) => {

        this.setState({working: true}, async () => {
            try {

                const batch = firestore.batch();

                for (const selectedRow of selectedRows.data) {

                    batch.delete(this.state.values[selectedRow.dataIndex].ref);
                }

                await batch.commit();

                this.setState({working: false}, () => {
                    this.reload();
                });

            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });

    };

    handleChange = event => {

        let value = event.target.value;
        this.setState({[event.target.name]: value});
    };

    handleChangedSwitch = name => event => {
        this.setState({[name]: event.target.checked});
    };

    openDialog = (mode) => {

        if (mode) {
            this.setState({
                showDialog: true,

                visible: this.state.value.visible,
                priority: this.state.value.priority,
                quote: i18nText(this.state.value.quote),
                subtitle: i18nText(this.state.value.subtitle),
                url: this.state.value.url,
                name: this.state.value.name,
                editMode: true
            })
        } else {
            this.setState({
                showDialog: true,
                visible: false,
                priority: "0",
                quote: "",
                subtitle: "",
                name: "",
                url: "",
                editMode: false,
                value: null
            })
        }
    };

    closeDialog = () => {
        this.setState({
            showDialog: false,
            visible: false,
            priority: "0",
            quote: "",
            subtitle: "",
            name: "",
            url: "",
            editMode: false,
            value: null
        })
    };

    handleSave = () => {
        this.setState({working: true}, async () => {

            const priority = parseInt(this.state.priority);

            if (this.state.value) {
                await this.state.value.ref.set({
                    visible: this.state.visible,
                    priority: priority,
                    quote: {de_DE: this.state.quote},
                    subtitle: {de_DE: this.state.subtitle},
                    url: this.state.url,
                    name: this.state.name,
                }, {merge: true});

            } else {

                await firestore.collection("quotes").add({
                    visible: this.state.visible,
                    priority: priority,
                    quote: {de_DE: this.state.quote},
                    subtitle: {de_DE: this.state.subtitle},
                    url: this.state.url,
                    name: this.state.name,
                    created: new Date(),
                });
            }

            this.setState({
                working: false,
                showDialog: false,
                visible: false,
                priority: "0",
                quote: "",
                subtitle: "",
                name: "",
                url: "",
                editMode: false,
                value: null
            }, () => {
                this.reload();
            })
        })
    };

    render() {
        const {classes} = this.props;

        const columns = [
            {

                name: "Action",
                field: "action",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Tooltip title={"Edit entry"}>
                                <IconButton onClick={(e) => this.setState({value: value}, () => this.openDialog(true))}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>

                        );
                    },
                },

            },
            {

                name: "Visible",
                field: "visible",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {(value === true) ? (<CheckedIcon/>) : (<UncheckedIcon/>)}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Quote",
                field: "quote",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            i18nText(value)

                        );
                    },
                }
            },
            {

                name: "Subtitle",
                field: "subtitle",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            i18nText(value)

                        );
                    },
                }
            },
            {

                name: "Name",
                field: "name",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {

                name: "Image URL",
                field: "url",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {

                name: "Priority",
                field: "priority",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {

                name: "Created",
                field: "created",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {moment(value).format("YYYY-MM-DD, HH:mm:ss")}
                            </React.Fragment>

                        );
                    },
                }
            },
        ];


        const options = {
            filter: false,
            selectableRows: 'multiple',
            responsive: 'scrollFullHeight',
            customToolbar: () =>
                <Tooltip title={"Reload Data"}>
                    <IconButton className={classes.iconButton}
                                onClick={this.reload}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>,
            customToolbarSelect: (selectedRows) =>
                <StyledCustomToolbarSelect
                    selectedRows={selectedRows}
                    onDelete={this.handleDelete}/>

        };

        const data = this.state.values.map(value => {

            const line = [];

            for (const column of columns) {
                let field = value[column.field];

                if (column.field === "action") {
                    field = value;
                }

                if (isNullOrUndefined(field)) {
                    field = "-"
                }
                line.push(field);
            }

            return line;
        });

        const isValidPriority = !isNaN(this.state.priority) && parseFloat(this.state.priority) >= 0;
        const isValidQuote = this.state.quote.trim().length > 0;
        const isValidSubtitle = this.state.subtitle.trim().length > 0;
        const isValidName = this.state.name.trim().length > 0;
        const isValidURL = this.state.url.trim().length > 0;

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page}) => (
                        <React.Fragment>

                            {this.state.loading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress className={classes.progress} size={50}/>
                                    </Grid>
                                ) :
                                (
                                    <React.Fragment>

                                        <MUIDataTable
                                            columns={columns}
                                            data={data}
                                            title={page.title}
                                            options={options}
                                        />

                                        <Button variant="fab" className={classes.fab} color={"secondary"}
                                                onClick={(e) => this.openDialog(false)}>
                                            <AddIcon/>
                                        </Button>

                                    </React.Fragment>

                                )}


                            <Dialog
                                open={this.state.showDialog}
                                aria-labelledby="form-dialog-title"
                            >

                                {this.state.editMode ? (
                                    <React.Fragment>
                                        <DialogTitle
                                            id="form-dialog-title">Edit {this.state.tweet} of {page.title}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Edit the values
                                            </DialogContentText>

                                            <TextField
                                                value={this.state.quote}
                                                margin="dense"
                                                id="quote"
                                                name="quote"
                                                label="Quote"
                                                type="text"
                                                multiline={true}
                                                rows={5}
                                                rowsMax={5}
                                                error={!isValidQuote}
                                                helperText={!isValidQuote ? "Enter text" : ""}
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />

                                            <TextField
                                                value={this.state.name}
                                                margin="dense"
                                                id="name"
                                                name="name"
                                                label="Name"
                                                type="text"
                                                error={!isValidName}
                                                helperText={!isValidName ? "Enter text" : ""}
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />

                                            <TextField
                                                value={this.state.subtitle}
                                                margin="dense"
                                                id="subtitle"
                                                name="subtitle"
                                                label="Subtitle"
                                                type="text"
                                                error={!isValidSubtitle}
                                                helperText={!isValidSubtitle ? "Enter text" : ""}
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />

                                            <TextField
                                                value={this.state.url}
                                                margin="dense"
                                                id="url"
                                                name="url"
                                                label="Image URL"
                                                type="text"
                                                error={!isValidURL}
                                                helperText={!isValidURL ? "Enter URL" : ""}
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />

                                            <TextField
                                                value={this.state.priority}
                                                margin="dense"
                                                id="priority"
                                                name="priority"
                                                label="Priority"
                                                type="number"
                                                error={!isValidPriority}
                                                helperText={!isValidPriority ? "Only positiv numbers" : ""}
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={this.state.visible}
                                                        onChange={this.handleChangedSwitch('visible')}
                                                        value="visible"
                                                    />
                                                }
                                                label="is Visible"
                                            />


                                        </DialogContent>

                                        <DialogActions>
                                            <Button onClick={this.closeDialog} color="primary"
                                                    disabled={this.state.working}>
                                                Close
                                            </Button>
                                            <Button onClick={this.handleSave} color="primary" autoFocus
                                                    disabled={this.state.working || !isValidName || !isValidQuote || !isValidSubtitle || !isValidURL || !isValidPriority}>
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <DialogTitle id="form-dialog-title">Add a new value
                                            to {page.title}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                add a new value.
                                            </DialogContentText>
                                            <TextField
                                                value={this.state.quote}
                                                margin="dense"
                                                id="quote"
                                                name="quote"
                                                label="Quote"
                                                type="text"
                                                multiline={true}
                                                rows={5}
                                                rowsMax={5}
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />

                                            <TextField
                                                value={this.state.name}
                                                margin="dense"
                                                id="name"
                                                name="name"
                                                label="Name"
                                                type="text"
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />

                                            <TextField
                                                value={this.state.subtitle}
                                                margin="dense"
                                                id="subtitle"
                                                name="subtitle"
                                                label="Subtitle"
                                                type="text"
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />

                                            <TextField
                                                value={this.state.url}
                                                margin="dense"
                                                id="url"
                                                name="url"
                                                label="Image URL"
                                                type="text"
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />

                                            <TextField
                                                value={this.state.priority}
                                                margin="dense"
                                                id="priority"
                                                name="priority"
                                                label="Priority"
                                                type="number"
                                                error={!isValidPriority}
                                                helperText={!isValidPriority ? "Only positiv numbers" : ""}
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={this.state.visible}
                                                        onChange={this.handleChangedSwitch('visible')}
                                                        value="visible"
                                                    />
                                                }
                                                label="is Visible"
                                            />

                                        </DialogContent>

                                        <DialogActions>
                                            <Button onClick={this.closeDialog} color="primary"
                                                    disabled={this.state.working}>
                                                Close
                                            </Button>
                                            <Button onClick={this.handleSave} color="primary" autoFocus
                                                    disabled={this.state.working || !isValidName || !isValidQuote || !isValidSubtitle || !isValidURL || !isValidPriority}>
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </React.Fragment>
                                )}

                            </Dialog>


                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

Tweets.propTypes = {
    classes: PropTypes.object.isRequired,
};

const defaultToolbarSelectStyles = {
    iconButton: {
        marginRight: "24px",
        top: "50%",
        display: "inline-block",
        position: "relative",
        transform: "translateY(-50%)",
    },
};

class CustomToolbarSelect extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <div className={"custom-toolbar-select"}>
                <Tooltip title={"Delete"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onDelete(this.props.selectedRows)}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

}

const StyledCustomToolbarSelect = withStyles(defaultToolbarSelectStyles, {name: "CustomToolbarSelect"})(CustomToolbarSelect);

export default withStyles(styles, {withTheme: true})(Tweets)