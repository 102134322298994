import React from 'react';

import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';


const Wrapper = styled.div`
`;

const ButtonCSS = styled.div`
    width:100%;
    min-width: ${props => props.minWidth ? props.minWidth : "200px"};
background: rgba(255,255, 255, 0.9);
border-radius: 8px;
font-family: TruenoRegular;
font-size: 14px;
color: #000000;
letter-spacing: 1.5px;
text-align: center;
line-height: 50px;
min-height: 50px;
text-transform: uppercase;
user-select: none;
transition: box-shadow 0.5s;
border: 2px solid #ffffff;
&:hover {
cursor: pointer;
background: #ffffff;
color: #000000;
}
&:active {
box-shadow: none;
cursor: pointer;
color: #000000;
background: rgba(255,255, 255, 0.9);
}
`;

const ButtonCSSDisabled = styled.div`
opacity: 0.7;
width:100%;
min-width: ${props => props.minWidth ? props.minWidth : "200px"};
background: none;
border-radius: 8px;
font-family: TruenoRegular;
font-size: 14px;
color: #a9a9a9;
letter-spacing: 1.5px;
text-align: center;
line-height: 50px;
min-height: 50px;
text-transform: uppercase;
user-select: none;
border: 2px solid #a9a9a9;
`;

const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
    },

    typography: {
        useNextVariants: true,
    },

});


class StyledButton extends React.Component {

    constructor(props) {
        super(props);
    }

    handleKeyPress = (event) => {
        if (event.which === 13 && !this.props.disabled) {

            if (this.props.onClick) {
                this.props.onClick(this.props.name);
            }

        }
    };

    render() {
        return (

            (!this.props.working) ? (

                <div style={{minWidth: this.props.minWidth ? this.props.minWidth : '66%', outline: "none", marginLeft:this.props.marginLeft? this.props.marginLeft : 0}} tabIndex={0} onKeyPress={this.handleKeyPress}>
                    {(this.props.disabled) &&

                    <ButtonCSSDisabled minWidth={this.props.minWidth}>
                        {this.props.label}
                    </ButtonCSSDisabled>
                    }

                    {!(this.props.disabled) &&

                    <ButtonCSS onClick={event => this.props.onClick ? this.props.onClick(this.props.name) : null}
                               minWidth={this.props.minWidth}>
                        {this.props.label}
                    </ButtonCSS>
                    }
                </div>
            ) : (
                <div style={{width: '100%', outline: "none", display: 'flex', justifyContent: 'center'}}>

                    <MuiThemeProvider theme={theme}>
                        <CircularProgress size={50}/>
                    </MuiThemeProvider>
                </div>
            )

        )
    }
}

export default (StyledButton);