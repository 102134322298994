import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';
import MUIDataTable from "mui-datatables";
import firebaseApp from "../common/firebase";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";
import moment from "moment";
import {isNullOrUndefined} from "util";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button/Button";
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Dropzone from "react-dropzone";
import Jimp from "jimp";
import {uuidv4} from "../common/utils";
import Typography from "@material-ui/core/Typography/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";


const firestore = firebaseApp.firestore();


const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
});

class UnwantedImages extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            working: false,
            values: [],
            showDialog: false,
            editMode: false,
            value: null,
            comment: "",
            score: "0",
            hash: "",
            data: [],
            files: [],
            columns: [
                {

                    name: "Action",
                    field: "action",
                    options: {
                        filter: false,
                        sort: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <Tooltip title={"Edit entry"}>
                                    <IconButton
                                        onClick={(e) => this.setState({value: value}, () => this.openDialog(true))}>
                                        <EditIcon/>
                                    </IconButton>
                                </Tooltip>

                            );
                        },
                    },

                },
                {

                    name: "Comment",
                    field: "comment",
                    options: {
                        filter: false,
                        sort: true
                    }
                },
                {

                    name: "Score",
                    field: "score",
                    options: {
                        filter: false,
                        sort: true
                    }
                },
                {

                    name: "P-Hash",
                    field: "hash",
                    options: {
                        filter: false,
                        sort: true,
                        display: "false"
                    }
                },
                {

                    name: "Created",
                    field: "created",
                    options: {
                        filter: false,
                        sort: true,
                        display: "false",
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <React.Fragment>
                                    {moment(value).format("YYYY-MM-DD, HH:mm:ss")}
                                </React.Fragment>

                            );
                        },
                    }
                },
            ],


            options: {
                filter: false,
                selectableRows: 'multiple',
                responsive: 'scrollFullHeight',
                customToolbar: () =>
                    <Tooltip title={"Reload Data"}>
                        <IconButton
                            onClick={this.reload}>
                            <RefreshIcon/>
                        </IconButton>
                    </Tooltip>,
                customToolbarSelect: (selectedRows) =>
                    <StyledCustomToolbarSelect
                        selectedRows={selectedRows}
                        onDelete={this.handleDelete}/>

            }
        };
    }


    componentDidMount() {
        this.reload();
    }

    reload = () => {
        this.setState({loading: true}, async () => {
            try {

                let fsValues = await firestore.collection("unwantedimages").get();

                let values = [];

                for (const fsValue of fsValues.docs) {

                    const {created, ...other} = fsValue.data();
                    values.push({created: created.toDate(), ...other, ref: fsValue.ref});
                }

                const data = values.map(value => {

                    const line = [];

                    for (const column of this.state.columns) {
                        let field = value[column.field];

                        if (column.field === "action") {
                            field = value;
                        }

                        if (isNullOrUndefined(field)) {
                            field = "-";
                        }
                        line.push(field);
                    }

                    return line;
                });

                this.setState({values: values, data: data, loading: false});
            } catch (error) {
                console.error(error);
                this.setState({loading: false});
            }

        })

    };

    handleDelete = (selectedRows) => {

        this.setState({working: true}, async () => {
            try {

                const batch = firestore.batch();

                for (const selectedRow of selectedRows.data) {

                    batch.delete(this.state.values[selectedRow.dataIndex].ref);
                }

                await batch.commit();

                this.setState({working: false}, () => {
                    this.reload();
                });

            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });

    };

    handleChange = event => {

        let value = event.target.value;
        this.setState({[event.target.name]: value});
    };

    openDialog = (mode) => {


        if (mode) {
            this.setState({
                showDialog: true,
                comment: this.state.value.comment,
                score: this.state.value.score,
                editMode: true
            })
        } else {
            this.setState({
                showDialog: true,
                hash: "",
                comment: "",
                score: "0",
                editMode: false,
                files: [],
                value: null
            })
        }
    };

    closeDialog = () => {
        this.setState({showDialog: false, hash: "", comment: "", score: "0", editMode: false, files: [], value: null})
    };

    handleSave = () => {
        this.setState({working: true}, async () => {

            const score = parseFloat(this.state.score);

            if (this.state.value) {
                await this.state.value.ref.set({
                    score: score,
                    comment: this.state.comment,
                }, {merge: true});

            } else {

                await firestore.collection("unwantedimages").add({
                    score: score,
                    comment: this.state.comment,
                    created: new Date(),
                    hash: this.state.hash
                });
            }

            this.setState({
                working: false,
                showDialog: false,
                comment: "",
                hash: "",
                score: "0",
                editMode: false,
                files: [],
                value: null
            }, () => {
                this.reload();
            })
        })
    };

    onDrop(files) {

        this.setState({
            files: files.map(file => ({
                content: file,
                preview: URL.createObjectURL(file)
            }))
        }, async () => {

            const reader = new FileReader();
            reader.onload = async () => {
                const fileAsBinary = reader.result;

                const image = await Jimp.read(fileAsBinary);
                this.setState({hash: image.pHash()});


            };
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');

            reader.readAsArrayBuffer(this.state.files[0].content);
        });


    }

    render() {
        const {classes} = this.props;


        const hashExistsAlready = this.state.values.filter(value => value.hash === this.state.hash).length > 0;
        const isValidHash = this.state.hash.length > 0;
        const isValidScore = !isNaN(this.state.score) && parseFloat(this.state.score) >= 0;
        const isValidComment = this.state.comment.trim().length > 0;

        const {files} = this.state;


        const thumbsContainer = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 16
        };

        const thumb = {
            display: 'inline-flex',
            borderRadius: 2,
            border: '1px solid #eaeaea',
            marginBottom: 8,
            marginRight: 8,
            width: 200,
            height: 200,
            padding: 4,
            boxSizing: 'border-box'
        };

        const thumbInner = {
            display: 'flex',
            minWidth: 0,
            overflow: 'hidden'
        };

        const img = {
            display: 'block',
            width: 'auto',
            height: '100%',
        };

        const thumbs = files.map(file => (
            <div style={thumb} key={uuidv4()}>
                <div style={thumbInner}>
                    <img
                        src={file.preview}
                        style={img}
                    />
                </div>
            </div>
        ));

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page}) => (
                        <React.Fragment>

                            {this.state.loading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress className={classes.progress} size={50}/>
                                    </Grid>
                                ) :
                                (
                                    <React.Fragment>

                                        <MUIDataTable
                                            columns={this.state.columns}
                                            data={this.state.data}
                                            title={page.title}
                                            options={this.state.options}
                                        />

                                        <Button variant="fab" className={classes.fab} color={"secondary"}
                                                onClick={(e) => this.openDialog(false)}>
                                            <AddIcon/>
                                        </Button>

                                    </React.Fragment>

                                )}


                            <Dialog
                                open={this.state.showDialog}
                                aria-labelledby="form-dialog-title"
                            >

                                {this.state.editMode ? (
                                    <React.Fragment>
                                        <DialogTitle
                                            id="form-dialog-title">Edit image of {page.title}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Edit the image values. You can't change the hash
                                            </DialogContentText>

                                            <TextField
                                                value={this.state.comment}
                                                margin="dense"
                                                id="comment"
                                                name="comment"
                                                label="Comment"
                                                error={!isValidComment}
                                                rowsMax={5}
                                                rows={5}
                                                multiline={true}
                                                helperText={!isValidComment ? "comment is required" : ""}
                                                type="text"
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />

                                            <TextField
                                                value={this.state.score}
                                                margin="dense"
                                                id="score"
                                                name="score"
                                                label="Score"
                                                error={!isValidScore}
                                                helperText={!isValidScore ? "Valid score is >= 0 " : ""}
                                                type="number"
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />


                                        </DialogContent>

                                        <DialogActions>
                                            <Button onClick={this.closeDialog} color="primary"
                                                    disabled={this.state.working}>
                                                Close
                                            </Button>
                                            <Button onClick={this.handleSave} color="primary" autoFocus
                                                    disabled={this.state.working || !isValidScore || !isValidComment}>
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <DialogTitle id="form-dialog-title">Add a new Image
                                            to {page.title}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                add a new image.
                                            </DialogContentText>

                                            <section>

                                                {files.length === 0 &&
                                                <div className="dropzone">
                                                    <Dropzone
                                                        accept="image/*"
                                                        onDrop={this.onDrop.bind(this)}
                                                    />
                                                </div>
                                                }
                                                <aside style={thumbsContainer}>
                                                    {thumbs}
                                                </aside>
                                            </section>

                                            {isValidHash &&
                                            <Typography>
                                                {`calculated hash is ${this.state.hash}`}
                                            </Typography>
                                            }

                                            {hashExistsAlready &&
                                            <Typography>{`same image is already added`}</Typography>
                                            }
                                            <TextField
                                                value={this.state.comment}
                                                margin="dense"
                                                id="comment"
                                                name="comment"
                                                label="Comment"
                                                rowsMax={5}
                                                rows={5}
                                                multiline={true}
                                                type="text"
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />

                                            <TextField
                                                value={this.state.score}
                                                margin="dense"
                                                id="score"
                                                name="score"
                                                label="Score"
                                                error={!isValidScore}
                                                helperText={!isValidScore ? "Valid score is >= 0 " : ""}
                                                type="number"
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />


                                        </DialogContent>

                                        <DialogActions>
                                            <Button onClick={this.closeDialog} color="primary"
                                                    disabled={this.state.working}>
                                                Close
                                            </Button>
                                            <Button onClick={this.handleSave} color="primary" autoFocus
                                                    disabled={this.state.working || !isValidScore || !isValidComment || hashExistsAlready}>
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </React.Fragment>
                                )}

                            </Dialog>


                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

UnwantedImages.propTypes = {
    classes: PropTypes.object.isRequired,
};

const defaultToolbarSelectStyles = {
    iconButton: {
        marginRight: "24px",
        top: "50%",
        display: "inline-block",
        position: "relative",
        transform: "translateY(-50%)",
    },
};

class CustomToolbarSelect extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <div className={"custom-toolbar-select"}>
                <Tooltip title={"Delete"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onDelete(this.props.selectedRows)}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

}

const StyledCustomToolbarSelect = withStyles(defaultToolbarSelectStyles, {name: "CustomToolbarSelect"})(CustomToolbarSelect);

export default withStyles(styles, {withTheme: true})(UnwantedImages)