import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import {i18n, i18nConfiguration, i18nText} from "../common/I18n";
import Moment from "react-moment";
import moment from "moment";
import StyledProgress from "./StyledProgress";
import Card from "./Card";
import StyledCheckBox from "./StyledCheckBox";
import StyledFlatButton from "./StyledFlatButton";
import StyledTextField from "./StyledTextField";
import StyledButton from "./StyledButton";
import styled from "styled-components";
import asset_close from "../assets/close.svg";
import {Elements, StripeProvider} from "react-stripe-elements";
import CC from "./CC";

const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#FF6344',
            dark: '#FF6344',
            // contrastText: will be calculated to contast with palette.primary.main
        },
        secondary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
    },

    typography: {
        useNextVariants: true,
    },


    overrides: {
        MuiTypography: {
            root: {
                fontFamily: 'TruenoRegular',
            },
            title: {
                fontFamily: 'TruenoRegular',
                color: '#04ADA9',
            },
        },

        MuiDialogContentText: {
            root: {
                fontFamily: 'TruenoLight',
                marginBottom: "40px",
                minWidth: "400px"
            },
        },
        MuiButton: {
            root: {
                fontFamily: 'TruenoRegular',
            },
        },

        MuiStepLabel: {
            root: {
                fontFamily: 'TruenoLight',
            },
            label: {
                fontFamily: 'TruenoLight',
            },
        },
        MuiStepIcon: {
            text: {
                fontFamily: 'TruenoLight',
                fill: "#ffffff",
            },
            root: {
                color: '#EEEEEE',
                '&$active': {
                    color: '#04ADA9',
                    text: {
                        fill: '#EEEEEE',
                    }
                },
                '&$completed': {
                    color: '#04ADA9',
                    text: {
                        fill: '#EEEEEE',

                    },
                }
            }

        },
        MuiDialogActions: {
            root: {
                justifyContent: "space-evenly",
            },
        },
        MuiFormControlLabel: {
            root: {
                marginBottom: '24px',
            },
        },
    }
});


const CloseCSS = styled.div`
opacity: 0.8;
display: flex;
justify-content: center;
align-items:center;
min-width: 42px;
min-height: 42px;
background-color: var(--green);
user-select: none;
border-radius: 100%;
position: absolute;
right:16px;
top:16px;
&:hover {
cursor: pointer;
opacity: 1;
}
&:active {
opacity: 0.8;
cursor: pointer;
}
`;

const Step4GridCSS = styled.div`
padding: 32px;
grid-template-columns: 45% 50%;
grid-template-rows: repeat(3, 1fr);
grid-column-gap: 8px;
grid-row-gap: 24px;
display: grid;
@media screen and (max-width: 1100px) {
grid-template-columns: 100%;
grid-template-rows: repeat(4, 1fr);
grid-template-areas: 
"cc"
"ccform"
"ccform"
"button"
}
grid-template-areas: 
"cc ccform"
"cc ccform"
"cc button"
`;

const CCCSS = styled.div`
grid-area: cc;
justify-self: center;
align-self: start;
`;

const CCForm = styled.div`
grid-area: ccform;
`;


class NewCCDialog extends React.Component {

    constructor(props) {
        super(props);

    }

    handleClick(name) {
        this.setState({displayPromoCode: true});
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    handleCheck(name, event) {
        this.setState({[name]: event.target.checked});
    };

    render() {
        const {fullScreen} = this.props;

        return (
            <MuiThemeProvider theme={theme}>

                <Dialog
                    fullScreen={fullScreen}
                    open={this.props.open}
                    onClose={this.props.onClose}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={"md"}
                    fullWidth={true}
                >
                    <DialogTitle
                        id="responsive-dialog-title">{i18n("ccDialog/title")}
                        <CloseCSS onClick={this.props.onClose}>
                            <img src={asset_close} width={16} height={16}/>
                        </CloseCSS>
                    </DialogTitle>
                    <DialogContent>


                        <Step4GridCSS>
                            <CCCSS>
                                <Card
                                    number={"1234 5678 9012 1234"}
                                    month={"01"}
                                    year={"20"}
                                    type={this.props.ccBrand}
                                    cvc={"123"}
                                    highlighted={this.props.ccHighlight}/>
                            </CCCSS>
                            <CCForm>
                                <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
                                    <Elements>
                                        <CC
                                            onHighlight={this.props.onCCHightlight}
                                            onCompletedChange={this.props.onCCCompleted}
                                            onBrandChange={this.props.onCCBrand}
                                            onToken={this.props.onCCToken}
                                            name={`${this.props.firstname} ${this.props.lastname}`}
                                            working={this.props.working}
                                            label={i18n("ccDialog/new")}/>
                                    </Elements>
                                </StripeProvider>
                            </CCForm>
                        </Step4GridCSS>
                    </DialogContent>


                </Dialog>
            </MuiThemeProvider>
        );
    }
}

NewCCDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(NewCCDialog);