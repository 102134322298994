import React, {Component} from 'react';
import {initialize} from "../common/configuration";
import Header from "../components/Header";
import Address from "../components/Address";
import Cover from "../components/Cover";
import Details from "../components/Details";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";
import Overview from "../components/Overview";
import BookedOverview from "../components/BookedOverview";
import Testimonial from "../components/Testimonial";
import Directions from "../components/Directions";
import Timer from "../components/Timer";
import {Redirect} from "react-router";
import firebaseApp, {trackUserPresence} from "../common/firebase";
import * as firebase from "firebase";
import SearchInput from "../components/SearchInput";
import {i18nChange} from "../common/I18n";
import {isNullOrUndefined} from "util";
import ErrorSnackbar from "../components/ErrorSnackbar";
import withDebug from "../common/withDebug";



const firestore = firebaseApp.firestore();


class Event extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.match.params.id,
            events: [],
            testimonials: [],
            user: null,
            roles: null,
            selected: -1,
            systemError: null,
            initialized:false
        };
    }

    UNSAFE_componentWillMount() {

        initialize().then(() => this.setState({initialized: true}));


        firebaseApp.auth().onAuthStateChanged(async (user) => {
            if (user) {

                try {
                    const profile = await firestore.collection('profiles').doc(user.uid).get();
                    const roles = await firestore.collection('roles').doc(user.uid).get();


                    if (!profile.exists || !roles.exists) {
                    } else {

                        this.setState({user: profile.data(), roles: roles.data()}, () => {
                            i18nChange(this.state.user.language);
                            trackUserPresence();

                        })
                    }


                } catch (error) {
                    this.setState({systemError: error});
                }


            }
        });

        this.load();

    }

    load = () => {

        this.setState({working: true, events: []}, async () => {

            try {


                const fsEvent = await firestore.collection("events").doc(this.state.id).get();
                if (fsEvent.exists) {

                    const fsRelatedEvents = await firestore.collection("events").where("reference", "==", fsEvent.data().reference).get();

                    let events = [];
                    for (let fsRelatedEvent of fsRelatedEvents.docs) {
                        let relatedEvent = {...fsRelatedEvent.data()};
                        relatedEvent.id = fsRelatedEvent.id;

                        events.push(relatedEvent);
                    };

                    let testimonials = [];

                    const fsTestimonials = await firestore.collection("testimonials").where("uid", "==", fsEvent.data().uid).get();

                    for (let fsTestimonial of fsTestimonials.docs) {
                        let testimonial = {...fsTestimonial.data()};
                        testimonials.push(testimonial);
                    };

                    testimonials = testimonials.filter(testimonial => testimonial.visible).sort((a, b) => b.priority - a.priority);


                    this.setState({events: events, testimonials:testimonials, working: false})

                } else {
                    window.location = "notFound";

                }
            } catch (error) {
                this.setState({working: false, systemError: error});
            }

        });


    }

    handleBook() {
        this.setState({working: true}, () => {
            window.location = `/register/${this.state.events[this.state.selected === -1 ? 0 : this.state.selected].id}`;
        });
    }

    handleSelectionChange = (index) => {
        this.setState({selected: index});
    };

    render() {

        if (!this.state.initialized) {
            return (<Timer/>);
        }

        const isAuth = this.state.user !== null;
        const isHost = !isNullOrUndefined(this.state.roles) && this.state.roles.host;
        const isGuest = !isNullOrUndefined(this.state.roles) && this.state.roles.guest;


        const selected = (this.state.selected === -1) ? this.state.events.map(event => {
            return event.id
        }).indexOf(this.state.id) : this.state.selected;

        return (

            <div>

                {(!isAuth) &&
                <Timer time={2}>

                    <Header background/>

                    {!isNullOrUndefined(this.state.events[selected]) &&

                    <div>

                        <Cover images={this.state.events[selected].images}
                               highlight={this.state.events[selected].mainImageIndex}/>


                        <Overview events={this.state.events}
                                  selected={selected}
                                  working={this.state.working}
                                  event={this.state.events[selected]}
                                  onSelectionChange={this.handleSelectionChange.bind(this)}
                                  onBook={this.handleBook.bind(this)}/>


                        <Details event={this.state.events[selected]}
                                 isAuth={false}/>


                        <Gallery images={this.state.events[selected].images}/>

                        {this.state.testimonials.length > 0 &&
                            <Testimonial testimonials={this.state.testimonials}/>
                        }

                        <Address location={this.state.events[selected].geo.geopoint}/>


                    </div>
                    }


                    <Footer/>


                </Timer>
                }

                {(isAuth && isGuest) &&
                <Redirect push to={`/my/event/${this.state.id}`}/>
                }

                {(isAuth && isHost) &&
                <Redirect push to={`/host/my/event/${this.state.id}`}/>
                }

                <ErrorSnackbar message={this.state.systemError}
                               open={!isNullOrUndefined(this.state.systemError)} onClose={(event) => {
                    console.log(this.state.systemError);
                    this.setState({systemError: null})
                }}/>


            </div>
        );
    }
}

export default withDebug()(Event);
