/* global grecaptcha */

import ReactDOM from 'react-dom';
import {initialize} from "../common/configuration";
import React, {Component} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Progress from "../components/Progress";
import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {CenterCSS, Grid, BoxCSS} from "../common/Layout";
import {i18nText, i18n, i18nConfiguration, i18nLocale} from "../common/I18n";
import {isEMailValid, EMailMaskCustom, MobileMaskCustom, PostalCodeMaskCustom, DateMaskCustom} from "../common/utils";
import styled from 'styled-components';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import StyledErrorFloatingButton from "../components/StyledErrorFloatingButton"

import hash from 'object-hash';


import quote_top from '../assets/quote_top.svg';
import quote_bottom from '../assets/quote_bottom.svg';
import StyledTextField from "../components/StyledTextField";
import StyledTooltip from "../components/StyledTooltip";
import StyledButton from "../components/StyledButton";
import StyledFlatButton from "../components/StyledFlatButton";
import Dropzone from 'react-dropzone'
import 'moment/locale/de';
import moment from "moment/moment";
import {Redirect} from 'react-router';

import * as firebase from "firebase";
import 'firebase/firestore';
import 'firebase/storage';

import firebaseApp, {trackUserPresence} from '../common/firebase';
import {masterdata, countries} from '../common/configuration';
import {isNullOrUndefined} from "util";

import {ReactGA, ReactPixel} from "../common/tracking"
import Timer from "../components/Timer";
import StyledInlineDatePicker from "../components/StyledInlineDatePicker";
import ErrorSnackbar from "../components/ErrorSnackbar";
import Cook from "../components/Cook";
import * as loadimage from "blueimp-load-image";
import StyledProgress from "../components/StyledProgress";
import qs from "qs";
import withDebug from "../common/withDebug";


firebaseApp.auth().useDeviceLanguage();
const firestore = firebaseApp.firestore();
const storage = firebaseApp.storage();


const Wizard = styled.div`

margin-top: 54px;
margin-bottom: 54px;
@media screen and (max-width: 1200px) {
margin-left: 0%;
margin-right: 0%;
}
`;

const QuestionCSS = styled.div`
width: 70%;
text-align: center;
display: grid;
grid-template-columns: 10% 80% 10%;
margin-left: auto;
margin-right: auto;
user-select: none;
@media screen and (max-width: 900px) {
width: 100%;
grid-template-columns: 20% 60% 20%;
}
`;

const ContentCSS = styled.div`
margin-top: 64px;
width: 70%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 900px) {
width: 90%;
}
`;

const TextCSS = styled.div`
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
opacity: 0.8;
text-transform: uppercase;
justify-self: center;
align-self: center;
padding-left: 24px;
padding-right: 24px;
`;

const LeftQuoteCSS = styled.img`
justify-self: end;
align-self: start;
`;

const RightQuoteCSS = styled.img`
justify-self: start;
align-self: end;
`;

const HintCSS = styled.div`
position: absolute;
user-select: none;
top: 8px;
right: -40px;
display: block;
opacity: 0.4;
@media screen and (max-width: 600px) {
margin-left: auto;
margin-right: auto;
top:8px;
right: -32px;
}
cursor: help;
&:hover {
opacity: 1;
}
`;

const HintTextCSS = styled.div`
color: var(--green);
font-family: TruenoLight;
text-align: left;
margin-top: 16px;
font-size: 12px;
`;

const Step2GridCSS = styled.div`
grid-template-columns: 45% 55%;
grid-template-rows: repeat(4, 1fr);
grid-column-gap: 8px;
grid-row-gap: 16px;
display: grid;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 700px) {
grid-template-columns: 100%;
grid-template-rows: repeat(6, 1fr);
grid-template-areas: 
"image"
"image"
"firstname"
"lastname"
"birthday"
"button"
}
grid-template-areas: 
"image firstname"
"image lastname"
". birthday"
". button"
`;


const ImageCSS = styled.div`
grid-area: image;
justify-self: center;
align-self: start;
`;

const FirstNameCSS = styled.div`
grid-area: firstname;
`;

const LastNameCSS = styled.div`
grid-area: lastname;
`;

const BirthdayCSS = styled.div`
grid-area: birthday;
display: flex;
flex-direction: column;
position: relative;
`;

const StyledProgressCSS = styled.div`
display: grid;
border-radius: 100%;
background-color: rgba(255,99,68, 0.6);
width: 100%;
height: 100%;
text-align: center;
`;

const DropCSS = styled.div`
display: grid;
border-radius: 100%;
background-color: rgba(255,99,68, 0.6);
width: 100%;
height: 100%;
text-align: center;
&:hover {
box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
cursor: pointer;
font-family: TruenoBold;
}
&:active {
box-shadow: none;
cursor: pointer;
font-family: TruenoRegular;
}
`;

const DropPreviewCSS = styled.div`
background-image:  url(${props => props.src});
background-size: cover;
background-repeat: no-repeat;
border-radius: 100%;
width: 100%;
height: 100%;
&:hover {
box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
cursor: pointer;
font-family: TruenoBold;
}
&:active {
box-shadow: none;
cursor: pointer;
font-family: TruenoRegular;
}
`;

const DropTextCSS = styled.div`
    opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #FAFAFA;
letter-spacing: 1.5px;
line-height: 22px;
justify-self: center;
align-self: center;
`;

const ButtonCSS = styled.div`
grid-area: button;
justify-self: end;
@media screen and (max-width: 700px) {
justify-self: center;
}
`;

const CaptchaCSS = styled.div`
margin-top: 40px;
display:block;
margin-left: auto;
margin-right: auto;
`;


function Question(props) {
    return (

        <div>
            <QuestionCSS>
                <LeftQuoteCSS src={quote_top}/>
                <TextCSS>{props.children}</TextCSS>
                <RightQuoteCSS src={quote_bottom}/>
            </QuestionCSS>
        </div>
    )
}


ReactGA.pageview('/host/start');
ReactPixel.pageView();

const STEPS = [
    "WELCOME",
    "MOBILE",
    "CODE",
    "EMAIL",
    "CONFIRM",
    "PASSWORD",
    "HOST",
    "DONE",
];

class HostRegister extends Component {

    recaptcha = null;

    constructor(props) {
        super(props);

        this.state = {
            step: "WELCOME",
            mobileprefix: "+49",
            mobile: null,
            code: null,
            email: null,
            password: null,
            showPassword: false,
            image: null,
            firstname: null,
            lastname: null,
            language: i18nLocale(),
            anchorEl: null,
            recaptchaed: false,
            working: false,
            repeatError: null,
            error: null,
            confirmationResult: null,
            done: false,
            birthday: null,
            birthdayText: null,
            dateError: null,
            systemError: null,
            created: new Date(),
            initialized:false,

        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            forward: qs.parse(nextProps.location.search.slice(1)).forward,
        };
    }

    serialize = () => {
        return {
            step: this.state.step,
            mobileprefix: this.state.mobileprefix,
            mobile: this.state.mobile,
            email: this.state.email,
            image: this.state.image,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            language: this.state.language,
            done: this.state.done,
            birthday: this.state.birthday,
            created: this.state.created,

        }

    };

    deserialize = (data) => {
        this.setState(
            {
                step: data.step,
                mobileprefix: data.mobileprefix,
                mobile: data.mobile,
                email: data.email,
                birthday: data.birthday ? data.birthday.toDate() : null,
                image: data.image,
                firstname: data.firstname,
                lastname: data.lastname,
                language: data.language,
                done: data.done,
                created: data.created,
            }
        );

    };


    componentDidMount() {

        initialize().then(() => this.setState({initialized: true}));



        firebaseApp.auth().onAuthStateChanged((user) => {
            if (user) {
                firestore.collection('hostRegistrations').doc(user.uid).get()
                    .then(doc => {
                        if (!doc.exists) {
                            console.log('Error. No such document!');
                        } else {
                            this.setState({email: firebaseApp.auth().currentUser.email}, () => {
                                this.deserialize(doc.data())
                                trackUserPresence();

                            });
                        }
                    })
                    .catch(error => {
                        this.setState({systemError: error.code});
                    });


            }
        });
    };

    captcha = (ref) => {

        if (isNullOrUndefined(window.recaptchaVerifier)) {
            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(ref, {
                'size': 'normal',
                'callback': (response) => {
                    this.setState({recaptchaed: true});
                },
                'expired-callback': () => {
                    this.setState({recaptchaed: false});

                }
            });


        }

        window.recaptchaVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
        });

    };

    componentDidUpdate = () => {

        if (this.state.scrollToTop) {
            this.setState({scrollToTop: false}, () => {
                ReactDOM.findDOMNode(this).scrollIntoView()
            });
        }
    };

    handleNavigation = number => {
        this.setState({step: number});
    };

    nextStep = () => {

        let index = STEPS.findIndex((step) => step === this.state.step);
        if (index === -1) {
            return STEPS[0];
        }

        if ((index + 1) < STEPS.length) {
            return STEPS[index + 1]
        } else {
            return STEPS[index]
        }
    };

    previousStep = () => {

        let index = STEPS.findIndex((step) => step === this.state.step);
        if (index === -1) {
            return STEPS[0];
        }

        if ((index - 1) < 0) {
            return STEPS[index]
        } else {
            return STEPS[index - 1];
        }
    };

    indexOfStep = () => {

        return STEPS.findIndex((step) => step === this.state.step);
    };


    handleClick(name) {
        switch (name) {
            case "button_back" :
                this.setState({step: this.previousStep(), error: null, scrollToTop: true});
                break;

            case "button_next" :
                this.setState({step: this.nextStep(), error: null, scrollToTop: true});
                break;

            case "button_resend" :

                this.setState({working: true, error: null}, async () => {

                    try {
                        const actionCodeSettings = {
                            url: "https://" + window.location.hostname + "/host/register",
                        };
                        await firebaseApp.auth().currentUser.sendEmailVerification(actionCodeSettings);
                        this.setState({working: false})

                    } catch (error) {
                        this.setState({working: false, systemError: error.code}, () => {
                            window.recaptchaVerifier = null
                        });
                    }

                });

                break;

            case "button_welcome": // welcome

                this.setState({working: true, error: null, step: this.nextStep()}, () => {
                    ReactGA.pageview('/host/register/welcome');
                    this.setState({working: false})
                });
                break;


            case "button_mobile": // mobile

                this.setState({working: true, error: null}, async () => {

                    try {

                        const trimmedPhone = (this.state.mobileprefix + this.state.mobile).replace(/\s/g, '');

                        const fsPhone = await firestore.collection('phones').doc(hash(trimmedPhone)).get();

                        if (fsPhone.exists) {
                            this.setState({working: false, error: "PHONE_EXISTS_ALREADY"});
                        } else {

                            const confirmationResult = await firebase.auth().signInWithPhoneNumber(trimmedPhone, window.recaptchaVerifier);
                            ReactGA.pageview('/host/register/mobile');

                            this.setState({
                                step: this.nextStep(),
                                working: false,
                                confirmationResult: confirmationResult,
                                scrollToTop: true
                            }, () => {
                                window.recaptchaVerifier = null
                            });
                        }

                    } catch (error) {
                        this.setState({working: false, error: error.code}, () => {
                            window.recaptchaVerifier = null
                        });
                    }
                });


                break;
            case "button_code": //code

                this.setState({working: true, error: null}, () => {
                    this.state.confirmationResult.confirm(this.state.code)
                        .then(result => {
                            ReactGA.pageview('/host/register/code');
                            this.setState({
                                step: this.nextStep(),
                                working: false,
                                scrollToTop: true
                            });
                        })
                        .catch(error => {
                            this.setState({working: false, error: error.code});
                        });
                });

                break;
            case "button_email": // email

                this.setState({working: true, error: null}, async () => {

                    ReactGA.pageview('/host/register/email');

                    const fsEmail = await firestore.collection('emails').doc(hash(this.state.email.toLowerCase())).get();

                    try {
                        if (fsEmail.exists && (this.state.email !== firebaseApp.auth().currentUser.email)) {
                            this.setState({working: false, error: "EMAIL_EXISTS_ALREADY"});
                        } else {
                            const actionCodeSettings = {
                                url: "https://" + window.location.hostname + "/host/register?forwarded=true",
                            };

                            if (this.state.email !== firebaseApp.auth().currentUser.email) {
                                await firebaseApp.auth().currentUser.updateEmail(this.state.email);
                            }
                            await firebaseApp.auth().currentUser.sendEmailVerification(actionCodeSettings);

                            let batch = firestore.batch();

                            let data = this.serialize();
                            data.step = this.nextStep();

                            batch.set(
                                firestore.collection('hostRegistrations').doc(firebaseApp.auth().currentUser.uid),
                                data
                            );

                            if (this.state.email !== firebaseApp.auth().currentUser.email) {
                                batch.set(
                                    firestore.collection('emails').doc(hash(firebaseApp.auth().currentUser.email.toLowerCase())),
                                    {created: new Date()}
                                );
                            }

                            await batch.commit();

                            this.setState({step: this.nextStep(), working: false, scrollToTop: true});

                        }
                    } catch (error) {

                        if (error.code === "auth/requires-recent-login") {
                            this.setState({working: false, step: "WELCOME", forwarded:true});
                        } else {
                            this.setState({working: false, systemError: error.code});
                        }
                    }


                });
                break;
            case "button_password": // password

                this.setState({working: true, error: null}, async () => {

                    try {

                        ReactGA.pageview('/host/register/password');

                        const credential = firebase.auth.EmailAuthProvider.credential(this.state.email, this.state.password);
                        await firebaseApp.auth().currentUser.linkAndRetrieveDataWithCredential(credential);

                        let data = this.serialize();
                        data.step = this.nextStep();

                        await firestore.collection('hostRegistrations').doc(firebaseApp.auth().currentUser.uid).set(data,
                            {merge: true});

                        this.setState({step: this.nextStep(), working: false, scrollToTop: true});
                    } catch (error) {
                        if (error.code === "auth/requires-recent-login") {
                            this.setState({working: false, step: "WELCOME", forwarded:true});
                        } else {
                            this.setState({working: false, error: error.code});
                        }
                    }
                });


                break;

            case "button_done":

                this.setState({working: true, error: null}, () => {

                    ReactGA.pageview('/host/register/done');

                    const profile = {
                        uid: firebase.auth().currentUser.uid,
                        firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        mobileprefix: this.state.mobileprefix,
                        mobile: this.state.mobile,
                        birthday: this.state.birthday,
                        email: this.state.email,
                        image: this.state.image,
                        address: {
                            street: null,
                            postalCode: null,
                            country: "DE",
                            city: {}
                        },
                        about: {},
                        tags: [],
                        language: this.state.language,
                        requiresVAT: false,
                        vatID: null,
                        taxID: null,
                        status: "active",
                        change: "ignore",
                        created: new Date(),
                        updated: null,
                    };


                    let batch = firestore.batch();

                    let data = this.serialize();
                    data.done = true;
                    data.step = this.nextStep();

                    batch.set(
                        firestore.collection('profiles').doc(firebase.auth().currentUser.uid),
                        profile
                    );
                    batch.set(
                        firestore.collection('hostRegistrations').doc(firebase.auth().currentUser.uid),
                        data
                    );

                    batch.set(
                        firestore.collection('phones').doc(hash((this.state.mobileprefix + this.state.mobile).replace(/\s/g, ''))),
                        {created: new Date()}
                    );

                    batch.commit()
                        .then(() => {
                            this.setState({done: true, working: false, step: this.nextStep()});
                        })
                        .catch((error) => {
                            this.setState({working: false, systemError: error.code});
                        });
                });

                break;

            case "button_redirect":

                this.setState({working: true}, () => {
                    window.location = "/host/my";
                });
                break;

            default:
                break;
        }
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    handleEMailChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    handleDateChange(event) {

        this.setState({[event.target.name]: event.target.value});

        const birthday = moment(event.target.value, "DD.MM.YYYY", true);
        if (birthday.isValid()) {

            if (birthday.isAfter(moment().subtract(18, "years"))) {
                this.setState({birthday: null, dateError: "tooYoung"});

            } else {
                this.setState({birthday: birthday.toDate(), dateError: null});
            }
        } else {
            this.setState({birthday: null, dateError: "invalidDate"});
        }
    };

    handleDrop2(acceptedFiles) {

        this.setState({working: true}, () => {
            acceptedFiles.forEach(file => {

                const re = /(?:\.([^.]+))?$/;
                var extension = re.exec(file.name)[1];

                if (!isNullOrUndefined(extension)) {
                    const ref = storage.ref().child("profiles/" + firebase.auth().currentUser.uid + "." + Date.now() + "." + extension);
                    ref.put(file).then((snapshot) => {
                        snapshot.ref.getDownloadURL().then((downloadURL) => {
                            this.setState({image: downloadURL, working: false});
                        });
                    });
                } else {
                    this.setState({working: false});
                }


            });
        });

    };

    handleDrop(acceptedFiles) {

        this.setState({workingUpload: true, working:true}, () => {
            acceptedFiles.forEach(file => {

                const re = /(?:\.([^.]+))?$/;
                const extension = re.exec(file.name)[1];


                if (!isNullOrUndefined(extension)) {

                    loadimage(file, (canvas) => {

                        canvas.toBlob((blob) => {

                            const ref = storage.ref().child("profiles/" + firebase.auth().currentUser.uid + "." + Date.now() + "." + extension);

                            ref.put(blob)
                                .then((snapshot) => {
                                    snapshot.ref.getDownloadURL().then((downloadURL) => {
                                        this.setState({image: downloadURL, working: false, workingUpload: false});
                                    });
                                })
                                .catch((error) => {
                                    console.log("error");

                                    this.setState({workingUpload: false, working: false, error: error.code});

                                });

                        });


                    }, {maxWidth: 400, orientation: true});


                } else {
                    this.setState({workingUpload: false, working: false});
                }


            });
        });

    };

    handleSelectClick(target) {
        this.setState({anchorEl: target});
    }

    handleSelectClose() {
        this.setState({anchorEl: null});
    }

    handleSelect(name, value) {
        this.setState({[name]: value, anchorEl: null});
    }

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    handleClickShowPassword = () => {
        this.setState(state => ({showPassword: !state.showPassword}));
    };

    render() {

        if (!this.state.initialized) {
            return (<Timer/>);
        }

        let dropzoneRef = null;

        return (
            <div>

                <Timer time={2}>


                    <Header home={"/host"}>

                        {!this.state.done &&
                        <Progress
                            count={STEPS.length}
                            current={(this.indexOfStep())}
                            title={i18n("host/register/title")}
                            href={`/host`}/>
                        }</Header>

                    {(this.state.step === "WELCOME") &&
                    <Wizard>
                        <Question>{isNullOrUndefined(this.state.forward) ? i18n("host/register/step/welcome") : i18n("host/register/step/welcome/forwarded")}</Question>
                        <ContentCSS>


                            <CenterCSS width={"40%"} marginTop={"40px"}>


                                <StyledButton
                                    label={i18n("host/register/next")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_welcome"
                                    working={this.state.working}
                                    disabled={false}/>


                            </CenterCSS>

                        </ContentCSS>
                    </Wizard>
                    }


                    {(this.state.step === "MOBILE") && // mobile
                    <Wizard>
                        <Question>{i18n("host/register/step/mobile")}</Question>
                        <ContentCSS>


                            <CenterCSS width={"60%"}>
                                <StyledTextField
                                    startAdornment={
                                        <IconButton onClick={event => {
                                            this.handleSelectClick(event.currentTarget);
                                        }}>
                                            <InputAdornment position="start">{this.state.mobileprefix}</InputAdornment>
                                        </IconButton>}
                                    mask={MobileMaskCustom}
                                    label={i18n("host/register/mobile")}
                                    name="mobile"
                                    placeholder={"1__ _ ___________"}
                                    value={this.state.mobile}
                                    onChange={this.handleChange.bind(this)}
                                    error={isNullOrUndefined(this.state.error) ? null : i18n("error/" + this.state.error)}
                                    required={false}
                                    disabled={this.state.working}
                                    autoFocus={true}/>
                                <HintCSS><StyledTooltip text={i18n("host/register/mobile/hint", {}, true)}/></HintCSS>

                                <Menu id="render-countries-menu" anchorEl={this.state.anchorEl}
                                      open={Boolean(this.state.anchorEl)} onClose={this.handleSelectClose.bind(this)}>

                                    {masterdata.mobilePrefixCountries.map(value => (
                                        <MenuItem onClick={event => {
                                            this.handleSelect("mobileprefix", value)
                                        }}>{value}</MenuItem>

                                    ))}
                                </Menu>

                            </CenterCSS>

                            <CenterCSS width={"60%"}>
                                <CaptchaCSS>
                                    <div ref={(ref) => this.captcha(ref)}></div>
                                </CaptchaCSS>

                            </CenterCSS>


                            <CenterCSS width={"40%"} marginTop={"40px"}>


                                <StyledButton
                                    label={this.state.error === null ? i18n("host/register/next") : i18n("host/register/tryAgain")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_mobile"
                                    working={this.state.working}
                                    disabled={this.state.mobile === null || this.state.mobile.trim().length < 10 || !this.state.recaptchaed}/>


                            </CenterCSS>

                        </ContentCSS>
                    </Wizard>
                    }

                    {(this.state.step === "CODE") &&
                    <Wizard>
                        <Question>{i18n("host/register/step/code", {mobile: (this.state.mobileprefix + " " + this.state.mobile)})}</Question>
                        <ContentCSS>

                            <CenterCSS width={"60%"}>
                                <StyledTextField
                                    label={i18n("host/register/code")}
                                    name="code"
                                    value={this.state.code}
                                    onChange={this.handleChange.bind(this)}
                                    error={isNullOrUndefined(this.state.error) ? null : i18n("error/" + this.state.error)}
                                    required={false}
                                    disabled={this.state.working}
                                    autoFocus={true}/>
                                <HintCSS><StyledTooltip text={i18n("host/register/code/hint", {}, true)}/></HintCSS>

                            </CenterCSS>

                            <CenterCSS width={"40%"} marginTop={"40px"}>

                                <StyledButton
                                    label={this.state.error === null ? i18n("host/register/next") : i18n("host/register/tryAgain")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_code"
                                    working={this.state.working}
                                    disabled={this.state.code === null || this.state.code.trim().length < 6}/>
                            </CenterCSS>

                            <CenterCSS width={"40%"} marginTop={"8px"}>


                                <StyledFlatButton
                                    label={i18n("host/register/back")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_back"/>


                            </CenterCSS>

                        </ContentCSS>
                    </Wizard>
                    }

                    {(this.state.step === "EMAIL") &&
                    <Wizard>
                        <Question>{i18n("host/register/step/email")}</Question>
                        <ContentCSS>

                            <CenterCSS width={"60%"}>
                                <StyledTextField
                                    label={i18n("host/register/email")}
                                    name="email"
                                    value={this.state.email}
                                    mask={EMailMaskCustom}
                                    placeholder={"Name@domain.de"}
                                    error={isNullOrUndefined(this.state.error) ? null : i18n("error/" + this.state.error)}
                                    onChange={this.handleChange.bind(this)}
                                    required={false}
                                    disabled={this.state.working}
                                    autoFocus={true}/>

                                <HintCSS><StyledTooltip text={i18n("host/register/email/hint", {}, true)}/></HintCSS>

                            </CenterCSS>

                            <CenterCSS width={"40%"} marginTop={"40px"}>

                                <StyledButton
                                    label={this.state.error === null ? i18n("host/register/next") : i18n("host/register/tryAgain")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_email"
                                    working={this.state.working}
                                    disabled={this.state.email === null || this.state.email.trim().length === 0 || !isEMailValid(this.state.email)}/>
                            </CenterCSS>

                        </ContentCSS>
                    </Wizard>
                    }

                    {(this.state.step === "CONFIRM") &&
                    <Wizard>
                        <Question>{firebase.auth().currentUser.emailVerified ? i18n("host/register/step/confirmed", {email: firebase.auth().currentUser.email}) : i18n("host/register/step/confirm", {email: firebase.auth().currentUser.email})}</Question>
                        <ContentCSS>


                            <CenterCSS width={"40%"} marginTop={"40px"}>


                                {firebase.auth().currentUser.emailVerified &&
                                <StyledButton
                                    label={i18n("host/register/next")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_next"
                                    working={this.state.working}
                                    disabled={false}/>
                                }

                                {!firebase.auth().currentUser.emailVerified &&
                                <StyledButton
                                    label={i18n("host/register/resend")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_resend"
                                    working={this.state.working}
                                    disabled={false}/>
                                }

                            </CenterCSS>

                            {!firebase.auth().currentUser.emailVerified &&
                            <CenterCSS width={"40%"} marginTop={"40px"}>

                                <StyledFlatButton
                                    label={i18n("host/register/back")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_back"/>

                            </CenterCSS>
                            }
                        </ContentCSS>
                    </Wizard>
                    }

                    {(this.state.step === "PASSWORD") &&
                    <Wizard>
                        <Question>{i18n("host/register/step/password", {email: this.state.email})}</Question>
                        <ContentCSS>

                            <CenterCSS width={"60%"}>
                                <StyledTextField
                                    label={i18n("host/register/password")}
                                    name="password"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    value={this.state.password}
                                    onChange={this.handleChange.bind(this)}
                                    disabled={this.state.working}
                                    error={isNullOrUndefined(this.state.error) ? null : i18n("error/" + this.state.error)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                            >
                                                {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    required={false}
                                    autoFocus={true}/>

                                <HintCSS><StyledTooltip text={i18n("host/register/password/hint", {}, true)}/></HintCSS>

                            </CenterCSS>

                            <CenterCSS width={"40%"} marginTop={"40px"}>

                                <StyledButton
                                    label={this.state.error === null ? i18n("host/register/next") : i18n("host/register/tryAgain")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_password"
                                    working={this.state.working}
                                    disabled={this.state.password === null || this.state.password.trim().length === 0}/>
                            </CenterCSS>

                        </ContentCSS>
                    </Wizard>
                    }


                    {(this.state.step === "HOST") &&
                    <Wizard>
                        <Question>{i18n("host/register/step/host")}</Question>
                        <ContentCSS>

                            <Step2GridCSS>
                                <ImageCSS onClick={() => {
                                    dropzoneRef.open()
                                }}>


                                    <Dropzone className="dragAndDropArea"
                                              onDrop={this.handleDrop.bind(this)}
                                              accept="image/*"
                                              multiple={true}
                                              ref={(node) => {
                                                  dropzoneRef = node;
                                              }}>

                                        {(this.state.image === null) && !this.state.workingUpload &&
                                        <DropCSS>
                                            <DropTextCSS>
                                                {i18n("host/register/dropimage")}
                                            </DropTextCSS>
                                        </DropCSS>
                                        }

                                        {this.state.workingUpload &&
                                        <StyledProgressCSS>
                                            <StyledProgress/>
                                        </StyledProgressCSS>
                                        }

                                        {(this.state.image != null) && !this.state.workingUpload &&
                                        <DropPreviewCSS src={this.state.image}/>
                                        }

                                    </Dropzone>


                                </ImageCSS>
                                <FirstNameCSS>
                                    <StyledTextField
                                        label={i18n("host/register/firstname")}
                                        name="firstname"
                                        value={this.state.firstname}
                                        onChange={this.handleChange.bind(this)}
                                        required={false}
                                        disabled={this.state.working}
                                        error={isNullOrUndefined(this.state.error) ? null : i18n("error/" + this.state.error)}
                                        autoFocus={true}/>

                                </FirstNameCSS>
                                <LastNameCSS>
                                    <StyledTextField
                                        label={i18n("host/register/lastname")}
                                        name="lastname"
                                        value={this.state.lastname}
                                        disabled={this.state.working}
                                        onChange={this.handleChange.bind(this)}
                                        error={isNullOrUndefined(this.state.error) ? null : i18n("error/" + this.state.error)}
                                        required={false}/>
                                </LastNameCSS>

                                <BirthdayCSS>
                                    <StyledTextField
                                        label={i18n("host/register/birthday")}
                                        name="birthdayText"
                                        value={this.state.birthdayText}
                                        disabled={this.state.working}
                                        placeholder={"TT.MM.JJJJ"}
                                        onChange={this.handleDateChange.bind(this)}
                                        mask={DateMaskCustom}
                                        error={isNullOrUndefined(this.state.dateError) ? null : i18n("error/" + this.state.dateError)}
                                        required={false}/>

                                    <HintTextCSS>{i18n("host/register/birthday/hint")}</HintTextCSS>

                                </BirthdayCSS>

                                <ButtonCSS>
                                    <StyledButton
                                        label={this.state.error === null ? i18n("host/register/next") : i18n("host/register/tryAgain")}
                                        onClick={this.handleClick.bind(this)}
                                        name="button_done"
                                        working={this.state.working}
                                        disabled={
                                            this.state.birthday === null ||
                                            this.state.firstname === null ||
                                            this.state.lastname === null ||
                                            this.state.firstname.trim().length === 0 ||
                                            this.state.lastname.trim().length === 0 ||
                                            !isNullOrUndefined(this.state.dateError)}/>

                                </ButtonCSS>


                            </Step2GridCSS>


                        </ContentCSS>
                    </Wizard>
                    }

                    {(this.state.step === "DONE") &&
                    <Wizard>
                        <Question>{i18n("host/register/step/done", {firstname: this.state.firstname})}</Question>
                        <ContentCSS>


                            <CenterCSS width={"40%"} marginTop={"40px"}>


                                <StyledButton
                                    label={i18n("host/register/redirect")}
                                    onClick={this.handleClick.bind(this)}
                                    name="button_redirect"
                                    working={this.state.working}
                                    disabled={false}/>


                            </CenterCSS>

                        </ContentCSS>
                    </Wizard>
                    }

                    <ErrorSnackbar message={this.state.systemError ? this.state.systemError : null}
                                   open={!isNullOrUndefined(this.state.systemError)} onClose={(event) => {
                        console.log(this.state.systemError);
                        this.setState({systemError: null})
                    }}/>

                    <Footer/>
                </Timer>

            </div>
        );
    }
}

export default withDebug()(HostRegister);
