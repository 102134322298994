import React, { Component } from 'react';
import { initialize } from "../common/configuration";


import styled from 'styled-components';
import Header from "../components/Header";
import { i18nLocale, i18nChange, i18nText, i18n, i18nConfiguration } from "../common/I18n";
import Footer from "../components/Footer";
import firebaseApp, { trackUserPresence } from "../common/firebase";
import Cover from "../components/Cover";
import EditableBookingOverview from "../components/EditableBookingOverview";
import EditableDetails from "../components/EditableDetails";
import EditableGallery from "../components/EditableGallery";
import Timer from "../components/Timer";
import { Redirect } from "react-router";
import asset_placeholder from "../assets/placeholder.svg"
import moment from "moment";
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";
import * as firebase from "firebase";
import { Instagram } from 'react-content-loader'


import 'firebase/storage';
import * as ReactGA from "react-ga";
import { PostalCodeMaskCustom, uuidv4 } from "../common/utils";
import { CenterCSS } from "../common/Layout";
import StyledTextField from "../components/StyledTextField";
import StyledTextArea from "../components/StyledTextArea";
import StyledTooltip from "../components/StyledTooltip";
import StyledButton from "../components/StyledButton";
import quote_top from "../assets/quote_top.svg";
import quote_bottom from "../assets/quote_bottom.svg";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import Progress from "../components/Progress";
import { isNullOrUndefined } from "util";
import ScrollSelect from "../components/ScrollSelect";
import StyledFlatButton from "../components/StyledFlatButton";
import StyledCheckBox from "../components/StyledCheckBox";
import {
    countries,
    masterdata,
    tags
} from "../common/configuration";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import StyledDatePicker from "../components/StyledDatePicker";
import StyledTimePicker from "../components/StyledTimePicker";
import StyledStepper from "../components/StyledStepper";
import Dropzone from "react-dropzone";
import CustomPhoto from "../components/CustomPhoto";
import Gallery from 'react-photo-gallery';
import ReactDOM from "react-dom";
import ErrorSnackbar from "../components/ErrorSnackbar";
import StyledBlueButton from "../components/StyledBlueButton";
import StyledErrorFloatingButton from "../components/StyledErrorFloatingButton";
import * as loadimage from "blueimp-load-image";
import StyledProgress from "../components/StyledProgress";
import withDebug from "../common/withDebug";


const storage = firebaseApp.storage();
const firestore = firebaseApp.firestore();


const Wizard = styled.div`

margin-top: 54px;
margin-bottom: 54px;
@media screen and (max-width: 1200px) {
margin-left: 0%;
margin-right: 0%;
}
`;

const QuestionCSS = styled.div`
width: 70%;
text-align: center;
display: grid;
grid-template-columns: 10% 80% 10%;
margin-left: auto;
margin-right: auto;
user-select: none;
@media screen and (max-width: 900px) {
width: 100%;
grid-template-columns: 20% 60% 20%;
}
`;

const ContentCSS = styled.div`
margin-top: 64px;
margin-bottom: 64px;
width: 70%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 900px) {
width: 90%;
}
`;

const TextCSS = styled.div`
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
opacity: 0.8;
text-transform: uppercase;
justify-self: center;
align-self: center;
padding-left: 24px;
padding-right: 24px;
`;

const HelpTextCSS = styled.div`
font-family: TruenoLight;
font-size: 12px;
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
opacity: 0.7;
text-transform: uppercase;
justify-self: center;
align-self: center;
padding-left: 24px;
padding-right: 24px;
`;

const TextGreenCSS = styled.div`
font-family: TruenoLight;
font-size: 22px;
color: #04ADA9;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
opacity: 0.8;
justify-self: center;
align-self: center;
padding-left: 24px;
padding-right: 24px;
user-select: none;
`;

const LeftQuoteCSS = styled.img`
justify-self: end;
align-self: start;
margin-top: -32px;
`;

const RightQuoteCSS = styled.img`
justify-self: start;
align-self: end;
margin-bottom: -32px;
`;

const HintCSS = styled.div`
position: absolute;
user-select: none;
top: 8px;
right: -40px;
display: block;
opacity: 0.4;
@media screen and (max-width: 600px) {
margin-left: auto;
margin-right: auto;
top:8px;
right: -32px;
}
cursor: help;
&:hover {
opacity: 1;
}
`;
const LabelCSS = styled.div`
margin-top: 40px;
margin-bottom: 40px;
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #04ADA9;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
text-transform: uppercase;
user-select: none;
`;

const CheckboxCSS = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`;

const CheckboxLabelCSS = styled.div`
    opacity: 0.8;
    font-Family: TruenoRegular;
    font-size: 15.00px;
    color: #818181;
    letter-spacing: 0;
    line-height: 24.00px;
    align-items: start;
    user-select:none;
`;

const CheckboxDescriptionCSS = styled.div`
    opacity: 0.8;
    font-Family: TruenoLight;
    font-size: 12.00px;
    color: #818181;
    letter-spacing: 0;
    line-height: 24.00px;
    align-items: start;
    user-select:none;
`;


const Step2GridCSS = styled.div`
grid-template-columns: 45% 55%;
grid-template-rows: repeat(6, 1fr);
grid-column-gap: 8px;
grid-row-gap: 24px;
display: grid;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 700px) {
grid-template-columns: 100%;
grid-template-rows: repeat(8, 1fr);
grid-template-areas: 
"image"
"image"
"firstname"
"lastname"
"address"
"street"
"place"
"country"
"button"
}
grid-template-areas: 
"image firstname"
"image lastname"
". address"
". street"
". place"
". country"
". button"
`;


const ImageCSS = styled.div`
grid-area: image;
justify-self: center;
align-self: start;
`;

const FirstNameCSS = styled.div`
grid-area: firstname;
`;

const LastNameCSS = styled.div`
grid-area: lastname;
`;

const StreetCSS = styled.div`
grid-area: street;
margin-bottom: 24px;
`;

const PlaceCSS = styled.div`
grid-area: place;
margin-bottom: 24px;
`;

const CountryCSS = styled.div`
grid-area: country;
font-family: TruenoRegular;
font-size: 20px;
color: #04ADA9;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
text-align: right;
user-select:none;
`;

const DropCSS = styled.div`
display: grid;
border-radius: 100%;
background-color: rgba(255,99,68, 0.6);
width: 100%;
height: 100%;
text-align: center;
&:hover {
box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
cursor: pointer;
font-family: TruenoBold;
}
&:active {
box-shadow: none;
cursor: pointer;
font-family: TruenoRegular;
}
`;

const Drop2CSS = styled.div`
display: grid;
border-radius: 8px;
padding: 8px;
border-color: rgba(255,99,68, 0.6);
border-width: 3px;
border-style: dashed;
width: 100%;
height: 100%;
min-height: 300px;
text-align: center;
user-select:none;
&:hover {
box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
cursor: pointer;
font-family: TruenoBold;
}
&:active {
box-shadow: none;
cursor: pointer;
font-family: TruenoRegular;
}
`;

const DropPreviewCSS = styled.div`
background-image:  url(${props => props.src});
background-size: cover;
background-repeat: no-repeat;
border-radius: 100%;
width: 100%;
height: 100%;
&:hover {
box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
cursor: pointer;
font-family: TruenoBold;
}
&:active {
box-shadow: none;
cursor: pointer;
font-family: TruenoRegular;
}
`;

const DropTextCSS = styled.div`
    opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #FAFAFA;
letter-spacing: 1.5px;
line-height: 22px;
justify-self: center;
align-self: center;
`;

const DropText2CSS = styled.div`
    opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: rgba(255,99,68, 0.6);
letter-spacing: 1.5px;
line-height: 22px;
justify-self: center;
align-self: center;
`;

const PlaceLineCSS = styled.div`
display: flex;
`;

const PlaceCodeCSS = styled.div`
width:35%;
padding-right: 10%;
`;

const PlaceCityCSS = styled.div`
width:55%;
`;

const AddressLineCSS = styled.div`
display: flex;
align-self: end;
`;

const AddressIconCSS = styled.img`
width:10%;
padding-right: 10%;
`;

const AddressLabelCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #04ADA9;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
width:80%;
`;

const ButtonCSS = styled.div`
grid-area: button;
justify-self: end;
@media screen and (max-width: 700px) {
justify-self: center;
}
`;

const TimeLineCSS = styled.div`
margin-top: 16px;
margin-bottom: 16px;
display: flex;
justify-content:space-around;
position: relative;
@media screen and (max-width: 1000px) {
flex-direction: column;
align-items: center;
justify-content:center;
margin-bottom: 32px;
}
`;

const AddAnotherCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 9px;
color: #FF6344;
letter-spacing: 0.96px;
text-align: center;
line-height: 22px;
text-transform: uppercase;
`;

const AmountCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 110px;
color: #04ADA9;
letter-spacing: -1.5px;
text-align: right;
line-height: 121px;
`;

const CurrencyCSS = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 70px;
color: #04ADA9;
letter-spacing: -0.2px;
text-align: center;
line-height: 80px;
top: 0px;
right: 0px;
`;

const PriceCSS = styled.div`
margin-left: auto;
margin-right: auto;
display: flex;
width:60%;
@media screen and (max-width: 720px) {
width:80%;
}
user-select: none;
justify-content: space-between;
`;


const PolicySelectionCSS = styled.div`
width: 60%;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 40px;

`;

const TagSelectionCSS = styled.div`
width: 60%;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 40px;

`;

const PolicyCSS = styled.div`
`;

const TagCSS = styled.div`
`;

const PolicyHeaderCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #04ADA9;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
`;

const PolicyBodyCSS = styled.div`
opacity: 0.8;
font-family: TruenoLight;
font-size: 18px;
color: #818181;
letter-spacing: 0;
line-height: 28px;

`;

const TagHeaderCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #04ADA9;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
`;

const TagBodyCSS = styled.div`
opacity: 0.8;
font-family: TruenoLight;
font-size: 18px;
color: #818181;
letter-spacing: 0;
line-height: 28px;

`;


const StyledProgressCSS = styled.div`
display: grid;
border-radius: 100%;
background-color: rgba(255,99,68, 0.6);
width: 100%;
height: 100%;
text-align: center;
`;

const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#FF6344',
            // contrastText: will be calculated to contast with palette.primary.main
        },
        // error: will use the default color
    },

    typography: {
        useNextVariants: true,
    },


    overrides: {

        MuiFormControlLabel: {
            root: {
                marginTop: '40px',
                alignItems: 'start'
            }
        },

    }
})


function Question(props) {
    return (
        <QuestionCSS>
            <LeftQuoteCSS src={quote_top} />
            <TextCSS>{props.children}</TextCSS>
            <RightQuoteCSS src={quote_bottom} />
        </QuestionCSS>
    )
}

const STEPS = [
    "WELCOME",
    "TITLE",
    "MENU",
    "TYPES",
    "CUISINES",
    "ASSETS",
    "PRICE",
    "ADDRESS",
    "STRIPE",
    "TIMES",
    "CONFIRM",
];


class HostMyDraft extends Component {

    nodeAllergens;
    nodeCuisines;

    constructor(props) {
        super(props);

        this.state = {
            id: props.match.params.id,
            roles: null,
            stripeConnects: [],
            stripeConnectRequests: [],
            user: null,
            draft: null,
            error: null,
            working: false,
            showAllAllergens: false,
            showAllCuisines: false,
            showVoucher: false,
            scrollToTop: false,
            scrollToAllergens: false,
            scrollToCuisines: false,
            initialized: false


        };
    }


    UNSAFE_componentWillMount() {

        initialize().then(() => this.setState({ initialized: true }));


        firebaseApp.auth().onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const fsProfile = await firestore.collection('profiles').doc(user.uid).get();
                    const fsRoles = await firestore.collection('roles').doc(user.uid).get();

                    if (!fsProfile.exists || !fsRoles.exists) {
                        this.setState({ systemError: "noProfile" });
                    } else {
                        this.setState({ user: fsProfile.data(), roles: fsRoles.data() }, () => {
                            i18nChange(this.state.user.language);
                            this.load(true);
                            this.listen(user.uid);
                            trackUserPresence();

                        })

                    }
                } catch (error) {
                    this.setState({ systemError: error });
                }


            }
        });

    }

    listen = (uid) => {
        firestore.collection('stripeConnects').where("uid", "==", uid)
            .onSnapshot(
                snapshot => {

                    let stripeConnects = [];

                    snapshot.forEach((fsStripeConnect) => {
                        stripeConnects.push(fsStripeConnect.data());

                    });

                    this.setState({ stripeConnects: stripeConnects });

                }
                , snapShotError => {
                    this.setState({ systemError: snapShotError.code });
                });

        firestore.collection('stripeConnectRequests').where("uid", "==", uid)
            .onSnapshot(
                snapshot => {

                    let stripeConnectRequests = [];

                    snapshot.forEach((fsStripeConnectRequest) => {
                        stripeConnectRequests.push(fsStripeConnectRequest.data());

                    });

                    this.setState({ stripeConnectRequests: stripeConnectRequests });

                }
                , snapShotError => {
                    this.setState({ systemError: snapShotError.code });
                });
    };

    load = (scrollToTop) => {

        this.setState({ working: true }, async () => {

            try {

                const fsDraft = await firestore.collection('drafts').doc(this.state.id).get();

                if (!fsDraft.exists) {
                    this.setState({ working: false, systemError: "notFound" });
                } else {
                    let draft = { ...fsDraft.data() };
                    draft.date = draft.date.toDate();
                    draft.times = draft.times.map((time) => {
                        return { start: time.start.toDate(), end: time.end.toDate() }
                    });
                    this.setState({ working: false, scrollToTop: scrollToTop, draft: draft });
                }
            } catch (error) {
                this.setState({ working: false, systemError: error.code });

            }

        })
    }

    handleSave(key, value) {

        if (key === "address") {

            this.setState({ working: true }, () => {

                firestore.collection('drafts').doc(this.state.id).set({
                    address: {
                        street: value.street,
                        postalCode: value.postalCode,
                        city: { [i18nLocale()]: value.city },
                        country: value.country,
                    },
                    updated: new Date()

                }, { merge: true })
                    .then(() => {
                        this.load(false);
                    })
                    .catch((error) => {
                        this.setState({ working: false, error: error.code });
                    })
            });

        } else if (key === "people") {

            this.setState({ working: true }, () => {

                firestore.collection('drafts').doc(this.state.id).set({
                    seats: value,
                    updated: new Date()
                }, { merge: true })
                    .then(() => {
                        this.load(false);
                    })
                    .catch((error) => {
                        this.setState({ working: false, error: error.code });
                    })
            });

        } else if (key === "date") {
            this.setState({ working: true }, () => {

                var currentDate = moment(value);

                var alignedTimes = this.state.draft.times.map((time) => {
                    return [
                        moment(time.start)
                            .month(currentDate.month())
                            .date(currentDate.date())
                            .year(currentDate.year()).toDate(),
                        moment(time.end)
                            .month(currentDate.month())
                            .date(currentDate.date())
                            .year(currentDate.year()).toDate(),
                    ]
                });


                firestore.collection('drafts').doc(this.state.id).set({
                    date: currentDate.toDate(),
                    times: alignedTimes.map((time) => {
                        return { start: time[0], end: time[1] }
                    }),
                    updated: new Date()
                }, { merge: true })
                    .then(() => {
                        this.load(false);
                    })
                    .catch((error) => {
                        this.setState({ working: false, error: error.code });
                    })
            });

        } else if (["title", "description", "menu", "ingredients"].includes(key)) {

            this.setState({ working: true }, () => {

                firestore.collection('drafts').doc(this.state.id).set({
                    [key]: { [i18nLocale()]: value },
                    updated: new Date()
                }, { merge: true })
                    .then(() => {
                        this.load(false);
                    })
                    .catch((error) => {
                        this.setState({ working: false, error: error.code });
                    })
            });
        } else if (key === "story") {

            this.setState({ working: true }, () => {

                firestore.collection('drafts').doc(this.state.id).set({
                    about: { [i18nLocale()]: value },
                    updated: new Date()
                }, { merge: true })
                    .then(() => {
                        this.load(false);
                    })
                    .catch((error) => {
                        this.setState({ working: false, error: error.code });
                    })
            });
        } else if (key === "times") {

            this.setState({ working: true }, () => {


                firestore.collection('drafts').doc(this.state.id).set({
                    times: value.map((time) => {
                        return { start: time[0], end: time[1] }
                    }),
                    updated: new Date()
                }, { merge: true })
                    .then(() => {
                        this.load(false);
                    })
                    .catch((error) => {
                        this.setState({ working: false, error: error.code });
                    })
            });
        } else {
            this.setState({ working: true }, () => {

                firestore.collection('drafts').doc(this.state.id).set({
                    [key]: value,
                    updated: new Date()
                }, { merge: true })
                    .then(() => {
                        this.load(false);
                    })
                    .catch((error) => {
                        this.setState({ working: false, error: error.code });
                    })
            });
        }

    }

    handleUp() {

        let draft = { ...this.state.draft };
        draft.price.amount = draft.price.amount + 1;
        this.setState({ working: true, draft: draft }, () => {

            firestore.collection('drafts').doc(this.state.id).set({
                price: this.state.draft.price,
                updated: new Date()
            }, { merge: true })
                .then(() => {
                    this.setState({ working: false });
                })
                .catch((error) => {
                    this.setState({ working: false, error: error.code });
                })
        });
    };

    handleDown() {

        if (this.state.draft.price.amount > 1) {

            let draft = { ...this.state.draft };
            draft.price.amount = draft.price.amount - 1;

            this.setState({ working: true, draft: draft }, () => {

                firestore.collection('drafts').doc(this.state.id).set({
                    price: draft.price,
                    updated: new Date()
                }, { merge: true })
                    .then(() => {
                        this.setState({ working: false });
                    })
                    .catch((error) => {
                        this.setState({ working: false, error: error.code });
                    })
            });
        }
    };

    handleSortEnd({ oldIndex, newIndex }) {

        this.setState({ working: true }, () => {

            firestore.collection('drafts').doc(this.state.id).set({
                images: arrayMove(this.state.draft.images, oldIndex, newIndex),
                updated: new Date()
            }, { merge: true })
                .then(() => {
                    this.load(false);
                })
                .catch((error) => {
                    this.setState({ working: false, error: error.code });
                })
        });
    }

    handleDrop2(acceptedFiles) {


        this.setState({ working: true }, () => {
            acceptedFiles.forEach(file => {

                const ref = storage.ref().child("events/" + firebase.auth().currentUser.uid + "/" + Date.now() + "." + file.name);
                ref.put(file).then((snapshot) => {
                    snapshot.ref.getDownloadURL().then((downloadURL) => {

                        firestore.collection('drafts').doc(this.state.id).set({
                            images: [...this.state.draft.images, downloadURL],
                            updated: new Date()
                        }, { merge: true })
                            .then(() => {

                                this.load(false);
                            })
                            .catch((error) => {
                                this.setState({ working: false, error: error.code });
                            });

                    });
                });

            });
        });
    };

    handleDrop(acceptedFiles) {


        this.setState({ working: true, workingUpload: true }, () => {
            acceptedFiles.forEach(file => {

                const re = /(?:\.([^.]+))?$/;
                const extension = re.exec(file.name)[1];


                if (!isNullOrUndefined(extension)) {

                    loadimage(file, (canvas) => {

                        canvas.toBlob(async (blob) => {

                            const ref = storage.ref().child("events/" + firebase.auth().currentUser.uid + "/" + Date.now() + "." + extension);
                            ref.put(blob).then((snapshot) => {
                                snapshot.ref.getDownloadURL().then((downloadURL) => {

                                    firestore.collection('drafts').doc(this.state.id).set({
                                        images: [...this.state.draft.images, downloadURL],
                                        updated: new Date()
                                    }, { merge: true })
                                        .then(() => {

                                            this.setState({ workingUpload: false }, () => {
                                                this.load(false);
                                            })

                                        })
                                        .catch((error) => {
                                            this.setState({ working: false, workingUpload: false, error: error.code });
                                        });

                                });
                            });

                        });


                    }, { maxWidth: 800, orientation: true });


                } else {
                    this.setState({ workingUpload: false });
                }


            });
        });

    };


    isDisabled = () => {

        return (
            this.state.draft === null ? true : (
                isNullOrUndefined(this.state.draft.title) || i18nText(this.state.draft.title).trim().length === 0 ||
                isNullOrUndefined(this.state.draft.menu) || i18nText(this.state.draft.menu).trim().length === 0 ||
                isNullOrUndefined(this.state.user.image) ||
                this.state.stripeConnects.length === 0 ||
                this.state.draft.times.length === 0 ||
                this.state.draft.cuisines.length === 0 ||
                this.state.draft.images.length < 2 ||
                this.state.draft.languages.length === 0 ||
                this.state.draft.types.length === 0
            )
        )

    };

    mergeDraft = (key, step) => {
        this.setState({ working: true }, () => {

            firestore.collection('drafts').doc(this.state.id).set({
                [key]: this.state.draft[key],
                updated: new Date(),
                step: step
            }, { merge: true })
                .then(() => {
                    this.load(true);
                })
                .catch((error) => {
                    this.setState({ working: false, error: error.code });
                })
        });

    };

    saveDraft = (step) => {
        this.setState({ working: true }, () => {

            let draft = { ...this.state.draft };
            draft.updated = new Date();
            draft.step = step;

            firestore.collection('drafts').doc(this.state.id).set(draft)
                .then(() => {
                    this.load(true);
                })
                .catch((error) => {
                    this.setState({ working: false, error: error.code });
                })
        });

    };

    componentDidUpdate = () => {

        if (this.state.scrollToTop) {
            this.setState({ scrollToTop: false }, () => {
                ReactDOM.findDOMNode(this).scrollIntoView()
            });
        }

        if (this.state.scrollToAllergens) {
            this.setState({ scrollToAllergens: false }, () => {
                ReactDOM.findDOMNode(this.nodeAllergens).scrollIntoView()
            });
        }

        if (this.state.scrollToCuisines) {
            this.setState({ scrollToCuisines: false }, () => {
                ReactDOM.findDOMNode(this.nodeCuisines).scrollIntoView()
            });
        }
    };


    nextStep = () => {

        let index = STEPS.findIndex((step) => step === this.state.draft.step);
        if (index === -1) {
            return STEPS[0];
        }

        if ((index + 1) < STEPS.length) {
            return STEPS[index + 1]
        } else {
            return STEPS[index]
        }
    };

    previousStep = () => {

        let index = STEPS.findIndex((step) => step === this.state.draft.step);
        if (index === -1) {
            return STEPS[0];
        }

        if ((index - 1) < 0) {
            return STEPS[index]
        } else {
            return STEPS[index - 1];
        }
    };

    indexOfStep = () => {

        return STEPS.findIndex((step) => step === this.state.draft.step);
    };

    navigateToStripe = () => {
        this.handleNavigation(STEPS.findIndex((step) => step === "STRIPE"));
    };

    handleClick = (name) => {

        switch (name) {

            case "save":
                this.setState({ working: true }, () => {
                    window.location = "/host/my"
                });

                break;

            case "welcome":
                this.saveDraft(this.nextStep());

                break;

            case "title":
            case "seats":
            case "types":
            case "time":
            case "images":

                this.mergeDraft(name, this.nextStep());

                break;

            case "address":
            case "stripe":
            case "menuEtAl":
            case "descriptionEtAl":
            case "cuisinesEtAl":
            case "allergensEtAl":
            case "dateEtAl":
            case "priceEtAl":

                this.saveDraft(this.nextStep());

                break;
            case "book":

                this.setState({ working: true }, () => {

                    let batch = firestore.batch();

                    const uuid = uuidv4();

                    batch.set(
                        firestore.collection('eventRequests').doc(),
                        {
                            uid: firebase.auth().currentUser.uid,
                            reference: uuid,
                            images: this.state.draft.images,
                            mainImageIndex: this.state.draft.images.length > 2 ? 1 : 0,
                            title: this.state.draft.title,
                            tag: this.state.draft.tag,
                            description: this.state.draft.description,
                            menu: this.state.draft.menu,
                            ingredients: this.state.draft.ingredients,
                            address: this.state.draft.address,
                            about: this.state.draft.about,
                            addressSpecials: this.state.draft.addressSpecials,
                            seats: this.state.draft.seats,
                            cuisines: this.state.draft.cuisines,
                            languages: this.state.draft.languages,
                            specials: this.state.draft.specials,
                            allergens: this.state.draft.allergens,
                            courtesies: this.state.draft.courtesies,
                            types: this.state.draft.types,
                            policy: this.state.draft.policy,
                            time: this.state.draft.time,
                            date: this.state.draft.date,
                            status: "created",
                            times: this.state.draft.times,
                            price: this.state.draft.price,
                            voucher: this.state.draft.voucher,
                            created: new Date(),
                            updated: null,
                        }
                    );

                    if (isNullOrUndefined(this.state.user.address.street)) {
                        batch.set(
                            firestore.collection('profiles').doc(firebase.auth().currentUser.uid),
                            {
                                address: this.state.draft.address,
                                change: "ignore",
                                updated: new Date(),
                            },
                            { merge: true }
                        );

                    }
                    ;

                    if ((isNullOrUndefined(this.state.user.about) || isNullOrUndefined(this.state.user.about[i18nLocale()])) && (!isNullOrUndefined(this.state.draft.about) && !isNullOrUndefined(this.state.draft.about[i18nLocale()]))) {
                        batch.set(
                            firestore.collection('profiles').doc(firebase.auth().currentUser.uid),
                            {
                                about: this.state.draft.about,
                                change: "ignore",
                                updated: new Date(),
                            },
                            { merge: true }
                        );

                    }
                    ;


                    batch.delete(firestore.collection('drafts').doc(this.state.id));

                    batch.commit()
                        .then(() => {
                            window.location = "/host/my"
                        })
                        .catch((error) => {
                            this.setState({ working: false, error: error.code });
                        });
                });

                break;

            case "voucher":
                this.setState({ showVoucher: true });

                break;

            case "back":

                let draft = { ...this.state.draft }
                draft.step = this.previousStep();
                this.setState({ draft: draft, scrollToTop: true });

                break;
            default:
                break;


        }

    }

    handleChange(event) {

        let draft = { ...this.state.draft };

        if (event.target.name === "tag" && event.target.value === "none") {
            draft[event.target.name] = null;
        } else {
            draft[event.target.name] = event.target.value;
        }


        this.setState({ draft: draft });
    };

    handleAddressChange(event) {

        let draft = { ...this.state.draft };
        draft.address[event.target.name] = event.target.value;
        this.setState({ draft: draft });
    };

    handleI18nAddressChange(event) {

        let draft = { ...this.state.draft };
        draft.address[event.target.name][i18nLocale()] = event.target.value;
        this.setState({ draft: draft });
    };


    handleI18nChange(event) {

        let draft = { ...this.state.draft };
        draft[event.target.name][i18nLocale()] = event.target.value;
        this.setState({ draft: draft });
    };

    handleNavigation = (number) => {

        let draft = { ...this.state.draft }
        draft.step = STEPS[number];
        this.setState({ draft: draft });
    };

    handleChangeSeats = (name, number) => {

        let draft = { ...this.state.draft };
        draft.seats[name] = number + 1;

        if (draft.seats.min > draft.seats.max) {
            draft.seats.min = draft.seats.max;
        }

        this.setState({ draft: draft });
    };

    handleCheckedList(valueName, name, event) {

        let draft = { ...this.state.draft }

        let values = draft[valueName];

        if (event.target.checked) { //make sure it's in the list

            if (!values.includes(name)) {
                values.push(name);
            }

        } else { // make sure it's not in the list

            if (values.includes(name)) {
                var index = values.indexOf(name)
                values.splice(index, 1);
            }

        }

        draft[valueName] = values;

        this.setState({ draft: draft });
    };

    handleCheckedCuisines(name, event) {
        this.handleCheckedList("cuisines", name, event);
    };

    handleCheckedLanguages(name, event) {
        this.handleCheckedList("languages", name, event);
    };

    handleCheckedSpecials(name, event) {
        this.handleCheckedList("specials", name, event);
    };

    handleCheckedAllergens(name, event) {
        this.handleCheckedList("allergens", name, event);
    };

    toggleAllAllergens() {
        this.setState({ showAllAllergens: (!this.state.showAllAllergens), scrollToAllergens: (this.state.showAllAllergens) });
    };

    toggleAllCuisines() {
        this.setState({ showAllCuisines: (!this.state.showAllCuisines), scrollToCuisines: (this.state.showAllCuisines) });
    };

    handleCheckedCourtesies(name, event) {
        this.handleCheckedList("courtesies", name, event);
    };

    handleCheckedTypes(name, event) {
        this.handleCheckedList("types", name, event);
    };


    handleDateChange(date) {

        let draft = { ...this.state.draft };

        draft.date = date.toDate();

        draft.times = draft.times.map((time) => {
            return {
                start:
                    moment(time.start)
                        .month(date.month())
                        .date(date.date())
                        .year(date.year()).toDate(),
                end:
                    moment(time.end)
                        .month(date.month())
                        .date(date.date())
                        .year(date.year()).toDate(),
            }
        });


        this.setState({ draft: draft });
    };

    handleTimesChange(index, position, date) {

        let draft = { ...this.state.draft };


        const newTime = moment(draft.date)
            .hours(moment(date).hours())
            .minutes(moment(date).minutes())
            .seconds(moment(date).seconds())
            .milliseconds(0);


        if (!isNullOrUndefined(date) && !isNullOrUndefined(date.toDate)) {

            const allOtherTimes = [...draft.times];
            allOtherTimes.splice(index, 1)

            let startTime = newTime;
            let endTime = draft.times[index].end;

            if (position === 1) {
                startTime = draft.times[index].start;
                endTime = newTime;
            }

            const noOverlap = allOtherTimes.reduce((acc, time) => {

                return acc && (
                    !moment(time.start).isBetween(startTime, endTime) &&
                    !moment(time.end).isBetween(startTime, endTime)
                )
            }
                , true
            );

            if (noOverlap) {

                if (position === 0) {
                    draft.times[index].start = newTime.toDate();
                } else {
                    draft.times[index].end = newTime.toDate();
                }

                if (moment(draft.times[index].start).isAfter(moment(draft.times[index].end))) {
                    draft.times[index].end = moment(draft.times[index].start).add(1, 'm').toDate();
                }
                this.setState({ draft: draft });
            } else {
                this.setState({ draft: this.state.draft });
            }
        }
    };

    handleOtherSlot() {

        let draft = { ...this.state.draft };


        var lastTime = draft.times.reduce((acc, time) => {
            return moment(time.end).isAfter(acc) ? moment(time.end) : acc;
        }, moment(draft.date).hours(19).minutes(0).seconds(0).milliseconds(0));

        if (lastTime.isBefore(moment(draft.date).hours(22).minutes(45).seconds(0).milliseconds(0))) {
            draft.times.push({ start: lastTime.add(15, 'm').toDate(), end: lastTime.clone().add(30, 'm').toDate() })
            this.setState({ draft: draft });
        }
    };

    handleRemoveSlot() {

        let draft = { ...this.state.draft };

        draft.times.splice(draft.times.length - 1, 1);
        this.setState({ draft: draft });
    };

    generatePhotos() {

        const ratios = [[4, 3], [1, 1], [3, 4]]
        return this.state.draft.images.map((image, index) => {
            return {
                src: image,
                width: ratios[index % ratios.length][0],
                height: ratios[index % ratios.length][1],
            }

        });
    };

    handleRemoveImage() {

        let draft = { ...this.state.draft };
        draft.images.splice(draft.images.length - 1, 1);
        this.setState({ draft: draft });

    };

    componentDidCatch(error, info) {
        console.log(error);
        console.log(error.message);
        console.log(error.code);
        console.log(info);
        this.setState({
            working: false,
            systemError: isNullOrUndefined(error.message) ? (isNullOrUndefined(error.code) ? error.code : error) : error.message
        });
    }

    handleProfileDrop2(acceptedFiles) {

        this.setState({ working: true }, () => {
            acceptedFiles.forEach(async file => {

                const re = /(?:\.([^.]+))?$/;
                let extension = re.exec(file.name)[1];

                if (!isNullOrUndefined(extension)) {

                    const ref = storage.ref().child("profiles/" + firebase.auth().currentUser.uid + "." + Date.now() + "." + extension);
                    const snapshot = await ref.put(file);
                    const downloadURL = await snapshot.ref.getDownloadURL();
                    this.setState({ image: downloadURL, working: false });

                    await firestore.collection('profiles').doc(firebaseApp.auth().currentUser.uid).set({
                        image: downloadURL,
                        change: "ignore",
                        updated: new Date()
                    }, { merge: true });

                    const profile = await firestore.collection('profiles').doc(firebaseApp.auth().currentUser.uid).get();
                    this.setState({ working: false, user: profile.data() });

                } else {
                    this.setState({ working: false });
                }


            });
        });

    };

    handleProfileDrop(acceptedFiles) {

        this.setState({ working: true, workingUpload: true }, () => {
            acceptedFiles.forEach(file => {

                const re = /(?:\.([^.]+))?$/;
                const extension = re.exec(file.name)[1];


                if (!isNullOrUndefined(extension)) {

                    loadimage(file, (canvas) => {

                        canvas.toBlob(async (blob) => {

                            const ref = storage.ref().child("profiles/" + firebase.auth().currentUser.uid + "." + Date.now() + "." + extension);
                            const snapshot = await ref.put(blob);
                            const downloadURL = await snapshot.ref.getDownloadURL();
                            this.setState({ image: downloadURL, working: false, workingUpload: false });

                            await firestore.collection('profiles').doc(firebaseApp.auth().currentUser.uid).set({
                                image: downloadURL,
                                change: "ignore",
                                updated: new Date()
                            }, { merge: true });

                            const profile = await firestore.collection('profiles').doc(firebaseApp.auth().currentUser.uid).get();
                            this.setState({ working: false, workingUpload: false, user: profile.data() });

                        });


                    }, { maxWidth: 400, orientation: true });


                } else {
                    this.setState({ workingUpload: false });
                }


            });
        });

    };

    handleStripeConnect = () => {

        let url = "https://connect.stripe.com/oauth/authorize?";
        url += "response_type=code&client_id=" + process.env.REACT_APP_STRIPE_CLIENT_ID;
        url += "&scope=read_write&redirect_uri=" + encodeURI("https://" + window.location.hostname + "/" + process.env.REACT_APP_STRIPE_REDIRECT_URI);
        url += "&" + encodeURI("state=" + this.state.id);
        url += "&" + encodeURI("stripe_user[email]=" + this.state.user.email);
        url += "&" + encodeURI("stripe_user[url]=https://" + window.location.hostname + "/host");
        url += "&" + encodeURI("stripe_user[country]=" + this.state.draft.address.country);
        url += "&" + encodeURI("stripe_user[phone_number]=" + this.state.user.mobile);
        url += "&" + encodeURI("stripe_user[business_name]=" + this.state.user.firstname + " " + this.state.user.lastname);
        url += "&" + encodeURI("stripe_user[business_type]=sole_prop");
        url += "&" + encodeURI("stripe_user[first_name]=" + this.state.user.firstname);
        url += "&" + encodeURI("stripe_user[last_name]=" + this.state.user.lastname);
        url += "&" + encodeURI("stripe_user[dob_day]=" + moment(this.state.user.birthday.toDate()).date());
        url += "&" + encodeURI("stripe_user[dob_month]=" + (moment(this.state.user.birthday.toDate()).month() + 1));
        url += "&" + encodeURI("stripe_user[dob_year]=" + moment(this.state.user.birthday.toDate()).year());
        url += "&" + encodeURI("stripe_user[street_address]=" + this.state.draft.address.street);
        url += "&" + encodeURI("stripe_user[city]=" + i18nText(this.state.draft.address.city));
        url += "&" + encodeURI("stripe_user[zip]=" + this.state.draft.address.postalCode);
        url += "&" + encodeURI("stripe_user[physical_product]=false");
        url += "&" + encodeURI("stripe_user[product_category]=food_and_restaurants");
        url += "&" + encodeURI("stripe_user[product_description]=" + i18n("stripe/connect/product_description"));
        url += "&" + encodeURI("stripe_user[currency]=" + this.state.draft.price.currency.toLowerCase());

        window.location = url;

    };

    render() {

        if (!this.state.initialized) {
            return (<Timer />);
        }

        const translatedCuisines = masterdata.cuisines.map(cuisine => {
            return { key: cuisine, text: i18nConfiguration("cuisine", cuisine) }
        });


        const sortedCusines = [...masterdata.cuisines.slice(0, 3), ...translatedCuisines.slice(3, translatedCuisines.length - 3).sort(function (a, b) {
            return a.text[0].localeCompare(b.text[0]);
        }).map(cuisine => cuisine.key)];

        const SortableGallery = SortableContainer(({ photos }) => {
            return <Gallery photos={photos} renderImage={CustomPhoto} />;
        });

        let dropzoneRef = null;

        const isHost = this.state.roles && this.state.roles.host;
        const isAuth = this.state.user !== null;

        const hasStripeConnect = this.state.stripeConnects.length > 0;
        const isConnectingWithStripe = this.state.stripeConnectRequests.filter(value => value.status === "created").length > 0;
        const lastStripeConnect = this.state.stripeConnectRequests.sort((a, b) => a.created.toDate().getTime() - b.created.toDate().getTime()).last()
        const hasProblemsWithStripe = lastStripeConnect ? lastStripeConnect.status === "error" : false;


        return (
            <div>

                {!isAuth &&
                    <Timer time={2}>
                        <Redirect push to={`/host`} />
                    </Timer>
                }

                {(isAuth && isHost && this.state.draft !== null) &&
                    <div>

                        <Header home={"/host/my"} user={this.state.user}>
                            <Progress
                                count={STEPS.length}
                                current={(this.indexOfStep())}
                                onClick={this.handleNavigation.bind(this)}
                                title={i18n("host/draft/new")}
                                href={`/host`} />
                        </Header>

                        {(this.state.draft.step === "WELCOME") &&
                            <Wizard>
                                <Question>{i18n("host/draft/step/welcome", { firstname: this.state.user.firstname })}</Question>
                                <ContentCSS>

                                    <CenterCSS width={"40%"} marginTop={"40px"}>


                                        <StyledButton
                                            label={i18n("host/draft/start")}
                                            onClick={this.handleClick.bind(this)}
                                            name="welcome"
                                            working={this.state.working}
                                            disabled={false} />


                                    </CenterCSS>

                                </ContentCSS>
                            </Wizard>
                        }


                        {(this.state.draft.step === "TITLE") &&
                            <Wizard>
                                <Question>{i18n("host/draft/step/eventTitle", { firstname: this.state.user.firstname })}</Question>
                                <ContentCSS>

                                    <CenterCSS width={"60%"}>
                                        <StyledTextField
                                            label={i18n("host/draft/eventTitle")}
                                            name="title"
                                            value={this.state.draft.title[i18nLocale()]}
                                            onChange={this.handleI18nChange.bind(this)}
                                            required={false}
                                            disabled={this.state.working}
                                            autoFocus={true} />

                                        <HintCSS><StyledTooltip text={i18n("host/draft/eventTitle/hint", {}, true)} /></HintCSS>

                                    </CenterCSS>

                                    <CenterCSS width={"40%"} marginTop={"40px"}>

                                        <StyledButton
                                            label={this.state.error === null ? i18n("host/draft/next") : i18n("host/draft/tryAgain")}
                                            onClick={this.handleClick.bind(this)}
                                            name="title"
                                            working={this.state.working}
                                            disabled={isNullOrUndefined(this.state.draft.title[i18nLocale()]) || this.state.draft.title[i18nLocale()].trim().length === 0} />
                                    </CenterCSS>


                                </ContentCSS>
                            </Wizard>
                        }

                        {(this.state.draft.step === "MENU") &&
                            <Wizard>
                                <Question>{i18n("host/draft/step/menu", { firstname: this.state.user.firstname })}</Question>
                                <ContentCSS>

                                    <CenterCSS width={"60%"}>
                                        <StyledTextArea
                                            label={i18n("host/draft/menu")}
                                            name="menu"
                                            value={this.state.draft.menu[i18nLocale()]}
                                            onChange={this.handleI18nChange.bind(this)}
                                            disabled={this.state.working}
                                            required={false}
                                            autoFocus={true} />

                                        <HintCSS><StyledTooltip text={i18n("host/draft/menu/hint", {}, true)} /></HintCSS>

                                    </CenterCSS>
                                </ContentCSS>

                                <Question>{i18n("host/draft/step/ingredients", { firstname: this.state.user.firstname })}</Question>
                                <ContentCSS>

                                    <CenterCSS width={"60%"}>
                                        <StyledTextArea
                                            label={i18n("host/draft/ingredients")}
                                            name="ingredients"
                                            value={this.state.draft.ingredients[i18nLocale()]}
                                            onChange={this.handleI18nChange.bind(this)}
                                            disabled={this.state.working}
                                            required={false}
                                            autoFocus={false} />

                                        <HintCSS><StyledTooltip text={i18n("host/draft/ingredients/hint", {}, true)} /></HintCSS>

                                    </CenterCSS>

                                    <CenterCSS width={"40%"} marginTop={"40px"}>

                                        <StyledButton
                                            label={this.state.error === null ? i18n("host/draft/next") : i18n("host/draft/tryAgain")}
                                            onClick={this.handleClick.bind(this)}
                                            name="menuEtAl"
                                            working={this.state.working}
                                            disabled={isNullOrUndefined(this.state.draft.menu[i18nLocale()]) || this.state.draft.menu[i18nLocale()].trim().length === 0} />
                                    </CenterCSS>

                                    <CenterCSS width={"40%"} marginTop={"8px"}>


                                        <StyledFlatButton
                                            label={i18n("host/draft/back")}
                                            onClick={this.handleClick.bind(this)}
                                            name="back" />


                                    </CenterCSS>


                                </ContentCSS>
                            </Wizard>
                        }

                        {(this.state.draft.step === "TYPES") &&
                            <Wizard>
                                <Question>{i18n("host/draft/step/description", { firstname: this.state.user.firstname })}</Question>
                                <ContentCSS>

                                    <CenterCSS width={"60%"}>
                                        <StyledTextArea
                                            label={i18n("host/draft/description")}
                                            name="description"
                                            value={this.state.draft.description[i18nLocale()]}
                                            onChange={this.handleI18nChange.bind(this)}
                                            required={false}
                                            disabled={this.state.working}
                                            autoFocus={true} />

                                        <HintCSS><StyledTooltip text={i18n("host/draft/description/hint", {}, true)} /></HintCSS>

                                    </CenterCSS>
                                </ContentCSS>

                                <Question>{i18n("host/draft/step/types", { firstname: this.state.user.firstname })}</Question>
                                <ContentCSS>
                                    <CenterCSS width={"60%"} style={{ flexDirection: "column" }}>


                                        {masterdata.types.map(value => (
                                            <StyledCheckBox
                                                checked={this.state.draft.types.includes(value)}
                                                onChange={this.handleCheckedTypes.bind(this)}
                                                value={value}
                                                disabled={this.state.working}
                                                marginBottom={'8px'}
                                                label={
                                                    <CheckboxCSS>
                                                        <CheckboxLabelCSS>{i18nConfiguration("type", value)}</CheckboxLabelCSS>
                                                        <CheckboxDescriptionCSS>{i18n(`host/draft/types/${value}/hint`)}</CheckboxDescriptionCSS>
                                                    </CheckboxCSS>}
                                            />

                                        ))}
                                        <HintCSS><StyledTooltip text={i18n("host/draft/types/hint", {}, true)} /></HintCSS>


                                    </CenterCSS>

                                </ContentCSS>

                                {tags.length > 1 &&
                                    <React.Fragment>
                                        <Question>{i18n("host/draft/step/specialEvents", { firstname: this.state.user.firstname })}</Question>
                                        <ContentCSS>
                                            <CenterCSS width={"60%"} style={{ flexDirection: "column" }}>


                                                <TagSelectionCSS>

                                                    <MuiThemeProvider theme={theme}>

                                                        <RadioGroup
                                                            value={isNullOrUndefined(this.state.draft.tag) ? "none" : this.state.draft.tag}
                                                            onChange={this.handleChange.bind(this)}
                                                            name={"tag"}
                                                        >

                                                            {tags.map((value, index) => (
                                                                <FormControlLabel key={index}
                                                                    value={value.label}
                                                                    disabled={this.state.working}
                                                                    control={<Radio color="primary" />}
                                                                    label={
                                                                        <TagCSS>
                                                                            <TagHeaderCSS>{value.label === "none" ? i18n("specialEvents/none/title") : i18nText(value.title)}</TagHeaderCSS>
                                                                            <TagBodyCSS>
                                                                                {value.label === "none" ? i18n("specialEvents/none/description") : i18nText(value.description)}
                                                                            </TagBodyCSS>
                                                                        </TagCSS>} />
                                                            ))}

                                                        </RadioGroup>
                                                    </MuiThemeProvider>
                                                </TagSelectionCSS>
                                                <HintCSS><StyledTooltip
                                                    text={i18n("host/draft/specialEvents/hint", {}, true)} /></HintCSS>


                                            </CenterCSS>

                                        </ContentCSS>
                                    </React.Fragment>
                                }

                                <ContentCSS>

                                    <CenterCSS width={"40%"} marginTop={"40px"}>

                                        <StyledButton
                                            label={this.state.error === null ? i18n("host/draft/next") : i18n("host/draft/tryAgain")}
                                            onClick={this.handleClick.bind(this)}
                                            name="descriptionEtAl"
                                            working={this.state.working}
                                            disabled={this.state.draft.types.length === 0} />
                                    </CenterCSS>

                                    <CenterCSS width={"40%"} marginTop={"8px"}>


                                        <StyledFlatButton
                                            label={i18n("host/draft/back")}
                                            disabled={this.state.working}
                                            onClick={this.handleClick.bind(this)}
                                            name="back" />


                                    </CenterCSS>


                                </ContentCSS>


                            </Wizard>
                        }

                        {(this.state.draft.step === "CUISINES") &&
                            <Wizard>
                                <Question>{i18n("host/draft/step/cuisinesEtAl", { firstname: this.state.user.firstname })}</Question>
                                <ContentCSS>
                                    <CenterCSS width={"60%"} style={{ flexDirection: "column" }}>

                                        <div style={{ position: "relative" }} ref={c => (this.nodeCuisines = c)}>
                                            <LabelCSS>{i18n("host/draft/cuisines")}</LabelCSS>
                                            <HintCSS><StyledTooltip
                                                text={i18n("host/draft/cuisines/hint", {}, true)} /></HintCSS>
                                        </div>

                                        {sortedCusines.map((value, index) => {

                                            if (this.state.showAllCuisines || index < 3 || this.state.draft.cuisines.includes(value)) {
                                                return (
                                                    <StyledCheckBox
                                                        checked={this.state.draft.cuisines.includes(value)}
                                                        onChange={this.handleCheckedCuisines.bind(this)}
                                                        disabled={this.state.working}
                                                        value={value}
                                                        marginBottom={'8px'}
                                                        label={
                                                            <CheckboxLabelCSS>{i18nConfiguration("cuisine", value)}</CheckboxLabelCSS>}
                                                    />
                                                )

                                            }
                                        })}

                                        <CenterCSS width={"40%"} marginTop={"40px"}>

                                            {!(this.state.showAllCuisines) &&

                                                <StyledFlatButton
                                                    label={i18n("host/draft/showAllCuisines", { count: masterdata.cuisines.length })}
                                                    onClick={this.toggleAllCuisines.bind(this)}
                                                    disabled={false} />
                                            }

                                            {(this.state.showAllCuisines) &&

                                                <StyledFlatButton label={i18n("host/draft/showLessCuisines")}
                                                    onClick={this.toggleAllCuisines.bind(this)}
                                                    disabled={false} />
                                            }


                                        </CenterCSS>

                                        <div style={{ position: "relative" }}>

                                            <LabelCSS>{i18n("host/draft/lanuages")}</LabelCSS>
                                            <HintCSS><StyledTooltip
                                                text={i18n("host/draft/languages/hint", {}, true)} /></HintCSS>
                                        </div>

                                        {masterdata.languages.map(value => (
                                            <StyledCheckBox
                                                checked={this.state.draft.languages.includes(value)}
                                                onChange={this.handleCheckedLanguages.bind(this)}
                                                value={value}
                                                disabled={this.state.working}
                                                marginBottom={'8px'}
                                                label={
                                                    <CheckboxLabelCSS>{i18nConfiguration("language", value)}</CheckboxLabelCSS>}
                                            />

                                        ))}

                                        <div style={{ position: "relative" }}>

                                            <LabelCSS>{i18n("host/draft/specials")}</LabelCSS>

                                            <HintCSS><StyledTooltip
                                                text={i18n("host/draft/specials/hint", {}, true)} /></HintCSS>
                                        </div>

                                        {masterdata.specials.map(value => (
                                            <StyledCheckBox
                                                checked={this.state.draft.specials.includes(value)}
                                                onChange={this.handleCheckedSpecials.bind(this)}
                                                value={value}
                                                disabled={this.state.working}
                                                marginBottom={'8px'}
                                                label={
                                                    <CheckboxLabelCSS>{i18nConfiguration("special", value)}</CheckboxLabelCSS>}
                                            />

                                        ))}

                                    </CenterCSS>

                                    <CenterCSS width={"60%"} style={{ flexDirection: "column" }}>

                                        <div style={{ position: "relative" }} ref={c => (this.nodeAllergens = c)}>
                                            <LabelCSS>{i18n("host/draft/allergens")}</LabelCSS>
                                            <HintCSS><StyledTooltip
                                                text={i18n("host/draft/allergens/hint", {}, true)} /></HintCSS>
                                        </div>


                                        {masterdata.allergens.map((value, index) => {

                                            if (this.state.showAllAllergens || index < 5) {
                                                return (
                                                    <StyledCheckBox
                                                        checked={this.state.draft.allergens.includes(value)}
                                                        onChange={this.handleCheckedAllergens.bind(this)}
                                                        value={value}
                                                        disabled={this.state.working}
                                                        marginBottom={'8px'}
                                                        label={
                                                            <CheckboxLabelCSS>{i18nConfiguration("allergen", value)}</CheckboxLabelCSS>}
                                                    />
                                                )

                                            }
                                        })}

                                        <CenterCSS width={"40%"} marginTop={"40px"}>

                                            {!(this.state.showAllAllergens) &&

                                                <StyledFlatButton
                                                    label={i18n("host/draft/showAllAllergens", { count: masterdata.allergens.length })}
                                                    onClick={this.toggleAllAllergens.bind(this)}
                                                    disabled={false} />
                                            }

                                            {(this.state.showAllAllergens) &&

                                                <StyledFlatButton label={i18n("host/draft/showLessAllergens")}
                                                    onClick={this.toggleAllAllergens.bind(this)}
                                                    disabled={false} />
                                            }


                                        </CenterCSS>


                                        <div style={{ position: "relative" }}>

                                            <LabelCSS>{i18n("host/draft/courtesies")}</LabelCSS>
                                            <HintCSS><StyledTooltip
                                                text={i18n("host/draft/courtesies/hint", {}, true)} /></HintCSS>
                                        </div>

                                        {masterdata.courtesies.map(value => (
                                            <StyledCheckBox
                                                checked={this.state.draft.courtesies.includes(value)}
                                                onChange={this.handleCheckedCourtesies.bind(this)}
                                                value={value}
                                                disabled={this.state.working}
                                                marginBottom={'8px'}
                                                label={
                                                    <CheckboxLabelCSS>{i18nConfiguration("courtesy", value)}</CheckboxLabelCSS>}
                                            />

                                        ))}


                                    </CenterCSS>

                                    <CenterCSS width={"40%"} marginTop={"40px"}>

                                        <StyledButton
                                            label={this.state.error === null ? i18n("host/draft/next") : i18n("host/draft/tryAgain")}
                                            onClick={this.handleClick.bind(this)}
                                            name="allergensEtAl"
                                            working={this.state.working}
                                            disabled={this.state.draft.cuisines.length === 0 || this.state.draft.languages.length === 0} />
                                    </CenterCSS>

                                    <CenterCSS width={"40%"} marginTop={"8px"}>


                                        <StyledFlatButton
                                            label={i18n("host/draft/back")}
                                            onClick={this.handleClick.bind(this)}
                                            working={this.state.working}
                                            name="back" />


                                    </CenterCSS>

                                </ContentCSS>
                            </Wizard>
                        }

                        {(this.state.draft.step === "ASSETS") &&
                            <Wizard>
                                <Question>{i18n("host/draft/step/images", { firstname: this.state.user.firstname })}</Question>
                                <ContentCSS>

                                    {!this.state.workingUpload &&

                                        <CenterCSS width={"60%"}>
                                            <div onClick={() => {
                                                dropzoneRef.open()
                                            }}>


                                                <Dropzone className="dragAndDropArea2"
                                                    onDrop={this.handleDrop.bind(this)}
                                                    accept="image/*"
                                                    multiple={true}
                                                    disabled={this.state.working}
                                                    ref={(node) => {
                                                        dropzoneRef = node;
                                                    }}>

                                                    <Drop2CSS>


                                                        <DropText2CSS>
                                                            {i18n("host/draft/dropImages")}
                                                        </DropText2CSS>


                                                        <SortableGallery
                                                            axis={"xy"}
                                                            photos={this.generatePhotos()}
                                                            onSortEnd={this.handleSortEnd.bind(this)}
                                                            pressDelay={200}

                                                        />


                                                    </Drop2CSS>

                                                </Dropzone>


                                            </div>
                                            <HintCSS><StyledTooltip text={i18n("host/draft/images/hint", {}, true)} /></HintCSS>

                                        </CenterCSS>
                                    }

                                    {this.state.workingUpload &&
                                        <CenterCSS width={"60%"}>
                                            <Instagram width={"100%"} height={"300px"} />
                                        </CenterCSS>
                                    }
                                    <CenterCSS width={"60%"} style={{ marginTop: 24 }}>


                                        <TimeLineCSS>

                                            <AddAnotherCSS>
                                                {(this.state.draft.images.length > 0) &&
                                                    <StyledFlatButton label={i18n("host/draft/removeImage")}
                                                        disabled={this.state.working}
                                                        onClick={this.handleRemoveImage.bind(this)} />
                                                }
                                            </AddAnotherCSS>
                                        </TimeLineCSS>

                                    </CenterCSS>


                                    <CenterCSS width={"40%"} marginTop={"40px"}>

                                        <StyledButton
                                            label={this.state.error === null ? i18n("host/draft/next") : i18n("host/draft/tryAgain")}
                                            onClick={this.handleClick.bind(this)}
                                            name="images"
                                            working={this.state.working}
                                            disabled={this.state.draft.images.length < 2} />


                                    </CenterCSS>
                                    <CenterCSS width={"40%"} marginTop={"8px"}>


                                        <StyledFlatButton
                                            label={i18n("host/draft/back")}
                                            disabled={this.state.working}
                                            onClick={this.handleClick.bind(this)}
                                            name="back" />


                                    </CenterCSS>

                                </ContentCSS>
                            </Wizard>
                        }

                        {(this.state.draft.step === "PRICE") &&
                            <Wizard>
                                <Question>{i18n("host/draft/step/priceEtAl", { firstname: this.state.user.firstname })}</Question>
                                <ContentCSS>
                                    <CenterCSS width={"60%"}>

                                        <PriceCSS>
                                            <AmountCSS>{this.state.draft.price.amount}</AmountCSS>
                                            <CurrencyCSS>{i18nConfiguration('currency', this.state.draft.price.currency)}</CurrencyCSS>
                                            <StyledStepper
                                                disabled={this.state.working}
                                                onUp={this.handleUp.bind(this)}
                                                onDown={this.handleDown.bind(this)} />


                                        </PriceCSS>
                                        <HintCSS><StyledTooltip text={i18n("host/draft/priceEtAl/hint", {}, true)} /></HintCSS>


                                    </CenterCSS>

                                    <CenterCSS width={"60%"}>


                                        <PolicySelectionCSS>

                                            <MuiThemeProvider theme={theme}>

                                                <RadioGroup
                                                    value={this.state.draft.policy}
                                                    onChange={this.handleChange.bind(this)}
                                                    name={"policy"}
                                                >

                                                    {masterdata.policies.map((value, index) => (
                                                        <FormControlLabel key={index}
                                                            value={value}
                                                            disabled={this.state.working}
                                                            control={<Radio color="primary" />}
                                                            label={
                                                                <PolicyCSS>
                                                                    <PolicyHeaderCSS>{i18nConfiguration("policy", value)}</PolicyHeaderCSS>
                                                                    <PolicyBodyCSS>
                                                                        {i18nConfiguration("policyDescription", value, { time: i18nConfiguration("time", this.state.draft.time) })}
                                                                    </PolicyBodyCSS>
                                                                </PolicyCSS>} />
                                                    ))}

                                                </RadioGroup>
                                            </MuiThemeProvider>
                                        </PolicySelectionCSS>
                                    </CenterCSS>
                                </ContentCSS>

                                <Question>{i18n("host/draft/step/time", { firstname: this.state.user.firstname })}</Question>
                                <ContentCSS>
                                    <CenterCSS width={"60%"}>

                                        <MuiThemeProvider theme={theme}>

                                            <RadioGroup
                                                value={this.state.draft.time}
                                                onChange={this.handleChange.bind(this)}
                                                disabled={this.state.working}
                                                name={"time"}
                                            >

                                                {masterdata.times.map((value, index) => (
                                                    <FormControlLabel key={index}
                                                        value={value.label}
                                                        disabled={this.state.working}
                                                        control={<Radio color="primary" />}
                                                        label={
                                                            <PolicyCSS>
                                                                <PolicyHeaderCSS>{i18nConfiguration("time", value.label)}</PolicyHeaderCSS>
                                                                <PolicyBodyCSS>
                                                                    {i18nConfiguration("timeDescription", value.label)}
                                                                </PolicyBodyCSS>
                                                            </PolicyCSS>} />
                                                ))}

                                            </RadioGroup>
                                        </MuiThemeProvider>

                                        <HintCSS><StyledTooltip text={i18n("host/draft/time/hint", {}, true)} /></HintCSS>


                                    </CenterCSS>
                                </ContentCSS>

                                <Question>{i18n("host/draft/step/seats", { firstname: this.state.user.firstname })}</Question>
                                <ContentCSS>
                                    <CenterCSS width={"60%"} style={{ flexDirection: "column" }}>

                                        <LabelCSS>{i18n("host/draft/seats/min")}</LabelCSS>
                                        <ScrollSelect
                                            values={[...Array(6).keys()].map(i => i + 1)}
                                            scrollAfter={1}
                                            minVisible={2}
                                            height={"70px"}
                                            boxSize={70}
                                            disabled={this.state.working}
                                            selected={this.state.draft.seats.min - 1}
                                            onChange={this.handleChangeSeats.bind(this)}
                                            name={"min"}
                                        />
                                        <HintCSS><StyledTooltip text={i18n("host/draft/seats/hint", {}, true)} /></HintCSS>
                                        <LabelCSS>{i18n("host/draft/seats/max")}</LabelCSS>
                                        <ScrollSelect
                                            values={[...Array(20).keys()].map(i => i + 1)}
                                            scrollAfter={1}
                                            minVisible={2}
                                            height={"70px"}
                                            boxSize={70}
                                            disabled={this.state.working}
                                            selected={this.state.draft.seats.max - 1}
                                            onChange={this.handleChangeSeats.bind(this)}
                                            name={"max"}
                                        />

                                        <HintCSS><StyledTooltip text={i18n("host/draft/seats/hint", {}, true)} /></HintCSS>

                                    </CenterCSS>

                                    <CenterCSS width={"40%"} marginTop={"40px"}>

                                        <StyledButton
                                            label={this.state.error === null ? i18n("host/draft/next") : i18n("host/draft/tryAgain")}
                                            onClick={this.handleClick.bind(this)}
                                            name="priceEtAl"
                                            working={this.state.working}
                                            disabled={false} />


                                    </CenterCSS>

                                    <CenterCSS width={"40%"} marginTop={"8px"}>


                                        <StyledFlatButton
                                            label={i18n("host/draft/back")}
                                            disabled={this.state.working}
                                            onClick={this.handleClick.bind(this)}
                                            name="back" />


                                    </CenterCSS>


                                </ContentCSS>
                            </Wizard>
                        }


                        {(this.state.draft.step === "ADDRESS") &&
                            <Wizard>
                                <Question>{i18n("host/draft/step/address")}</Question>
                                <ContentCSS>

                                    <CenterCSS width={"60%"} style={{ flexDirection: "column" }}>
                                        <StreetCSS>
                                            <StyledTextField
                                                label={i18n("host/draft/street")}
                                                name="street"
                                                disabled={this.state.working}
                                                value={this.state.draft.address.street}
                                                onChange={this.handleAddressChange.bind(this)}
                                                required={false} />
                                        </StreetCSS>

                                        <PlaceCSS>
                                            <PlaceLineCSS>
                                                <PlaceCodeCSS>
                                                    <StyledTextField
                                                        label={i18n("host/draft/postalCode")}
                                                        name="postalCode"
                                                        disabled={this.state.working}
                                                        value={this.state.draft.address.postalCode}
                                                        mask={PostalCodeMaskCustom}
                                                        onChange={this.handleAddressChange.bind(this)}
                                                        required={false} />


                                                </PlaceCodeCSS>
                                                <PlaceCityCSS>
                                                    <StyledTextField
                                                        label={i18n("host/draft/city")}
                                                        name="city"
                                                        disabled={this.state.working}
                                                        value={i18nText(this.state.draft.address.city)}
                                                        onChange={this.handleI18nAddressChange.bind(this)}
                                                        required={false} />


                                                </PlaceCityCSS>
                                            </PlaceLineCSS>
                                        </PlaceCSS>

                                        <CountryCSS>
                                            {i18nText(countries[this.state.draft.address.country])}
                                        </CountryCSS>
                                    </CenterCSS>

                                    <div style={{ position: "relative" }}>

                                        <LabelCSS>{i18n("host/draft/addressSpecials")}</LabelCSS>
                                        <HintCSS><StyledTooltip
                                            text={i18n("host/draft/addressSpecials/hint", {}, true)} /></HintCSS>
                                    </div>

                                    <CenterCSS width={"60%"}>
                                        <StyledTextArea
                                            label={i18n("host/draft/addressSpecialsLabel")}
                                            name="addressSpecials"
                                            disabled={this.state.working}
                                            value={i18nText(this.state.draft.addressSpecials)}
                                            onChange={this.handleI18nChange.bind(this)}
                                            required={false}
                                            autoFocus={false} />

                                    </CenterCSS>

                                    <CenterCSS width={"40%"} marginTop={"40px"}>


                                        <StyledButton
                                            label={i18n("host/draft/next")}
                                            onClick={this.handleClick.bind(this)}
                                            name="address"
                                            working={this.state.working}
                                            disabled={
                                                isNullOrUndefined(this.state.draft.address.street) ||
                                                isNullOrUndefined(this.state.draft.address.city) ||
                                                isNullOrUndefined(this.state.draft.address.postalCode) ||
                                                this.state.draft.address.postalCode.trim().length < 5 ||
                                                this.state.draft.address.street.trim().length === 0 ||
                                                isNullOrUndefined(this.state.draft.address.city[i18nLocale()]) ||
                                                this.state.draft.address.city[i18nLocale()].trim().length === 0
                                            } />


                                    </CenterCSS>
                                    <CenterCSS width={"40%"} marginTop={"8px"}>


                                        <StyledFlatButton
                                            label={i18n("host/draft/back")}
                                            disabled={this.state.working}
                                            onClick={this.handleClick.bind(this)}
                                            name="back" />


                                    </CenterCSS>

                                </ContentCSS>
                            </Wizard>
                        }

                        {(this.state.draft.step === "STRIPE") &&
                            <Wizard>
                                <Question>{hasStripeConnect ?
                                    i18n("host/draft/step/stripe/connected") :
                                    (hasProblemsWithStripe ?
                                        i18n("host/draft/step/stripe/problem") :
                                        (isConnectingWithStripe ?
                                            i18n("host/draft/step/stripe/connecting") :
                                            i18n("host/draft/step/stripe")
                                        ))}</Question>
                                <ContentCSS>

                                    {(!hasStripeConnect && !hasProblemsWithStripe && !isConnectingWithStripe) &&
                                        <CenterCSS width={"40%"} marginTop={"40px"}>

                                            <StyledButton
                                                label={i18n("host/draft/stripe")}
                                                onClick={this.handleStripeConnect.bind(this)} />
                                        </CenterCSS>
                                    }

                                    {(!hasStripeConnect && !hasProblemsWithStripe && !isConnectingWithStripe) &&
                                        <CenterCSS width={"40%"} marginTop={"40px"} style={{ flexDirection: "column" }}>

                                            <HelpTextCSS>{i18n("stripe/help")}</HelpTextCSS>
                                        </CenterCSS>
                                    }

                                    {hasProblemsWithStripe &&
                                        <CenterCSS width={"40%"} marginTop={"40px"}>

                                            <StyledButton
                                                label={i18n("host/draft/stripe/again")}
                                                onClick={this.handleStripeConnect.bind(this)} />
                                        </CenterCSS>
                                    }

                                    {hasProblemsWithStripe &&
                                        <CenterCSS width={"40%"} marginTop={"40px"} style={{ flexDirection: "column" }}>

                                            <HelpTextCSS>{i18n("stripe/help")}</HelpTextCSS>
                                        </CenterCSS>
                                    }

                                    {(hasStripeConnect || isConnectingWithStripe) &&
                                        <CenterCSS width={"40%"} marginTop={"40px"}>


                                            <StyledButton
                                                label={i18n("host/draft/next")}
                                                onClick={this.handleClick.bind(this)}
                                                name="stripe"
                                                working={this.state.working}
                                                disabled={false} />
                                        </CenterCSS>
                                    }

                                    <CenterCSS width={"40%"} marginTop={"8px"}>


                                        <StyledFlatButton
                                            label={i18n("host/draft/back")}
                                            disabled={this.state.working}
                                            onClick={this.handleClick.bind(this)}
                                            name="back" />


                                    </CenterCSS>

                                </ContentCSS>
                            </Wizard>
                        }

                        {(this.state.draft.step === "TIMES") &&
                            <Wizard>
                                <Question>{i18n("host/draft/step/dateEtAl", { firstname: this.state.user.firstname })}</Question>
                                <ContentCSS>
                                    <CenterCSS width={"60%"} marginTop={"40px"} style={{ flexDirection: "column" }}>


                                        <StyledDatePicker date={this.state.draft.date}
                                            disabled={this.state.working}
                                            onChange={this.handleDateChange.bind(this)} />


                                        {Array.from(Array(this.state.draft.times.length).keys()).map((value, index) => {

                                            return (
                                                <TimeLineCSS>

                                                    <StyledTimePicker label={i18n("host/draft/starts")}
                                                        index={index}
                                                        disabled={this.state.working}
                                                        position={0}
                                                        date={this.state.draft.times[index].start}
                                                        onChange={this.handleTimesChange.bind(this)} />
                                                    <StyledTimePicker label={i18n("host/draft/ends")}
                                                        index={index}
                                                        disabled={this.state.working}
                                                        position={1}
                                                        date={this.state.draft.times[index].end}
                                                        onChange={this.handleTimesChange.bind(this)} />
                                                </TimeLineCSS>

                                            )
                                        })}


                                        <TimeLineCSS>

                                            <AddAnotherCSS>
                                                <StyledFlatButton label={i18n("host/draft/otherSlot")}
                                                    disabled={this.state.working}
                                                    onClick={this.handleOtherSlot.bind(this)} />
                                            </AddAnotherCSS>

                                            <HintCSS><StyledTooltip text={i18n("host/draft/time/hint", {}, true)} /></HintCSS>

                                        </TimeLineCSS>

                                        {(this.state.draft.times.length > 1) &&
                                            <TimeLineCSS>

                                                <AddAnotherCSS>
                                                    <StyledFlatButton label={i18n("host/draft/removeSlot")}
                                                        disabled={this.state.working}
                                                        onClick={this.handleRemoveSlot.bind(this)} />
                                                </AddAnotherCSS>
                                            </TimeLineCSS>
                                        }


                                        <HintCSS><StyledTooltip text={i18n("host/draft/date/hint", {}, true)} /></HintCSS>


                                    </CenterCSS>

                                    <CenterCSS width={"40%"} marginTop={"40px"}>

                                        <StyledButton
                                            label={this.state.error === null ? i18n("host/draft/next") : i18n("host/draft/tryAgain")}
                                            onClick={this.handleClick.bind(this)}
                                            name="dateEtAl"
                                            working={this.state.working}
                                            disabled={false} />


                                    </CenterCSS>

                                    <CenterCSS width={"40%"} marginTop={"8px"}>


                                        <StyledFlatButton
                                            label={i18n("host/draft/back")}
                                            disabled={this.state.working}
                                            onClick={this.handleClick.bind(this)}
                                            name="back" />


                                    </CenterCSS>

                                </ContentCSS>
                            </Wizard>
                        }

                        {(this.state.draft.step === "CONFIRM") &&

                            <Wizard>
                                <Question>{i18n("host/draft/step/last", { firstname: this.state.user.firstname })}</Question>
                                <div style={{ marginTop: 40 }} />

                                <Cover
                                    images={this.state.draft.images}
                                    highlight={this.state.draft.mainImageIndex} />

                                <EditableBookingOverview
                                    image={this.state.user.image}
                                    address={{
                                        street: this.state.draft.address.street,
                                        postalCode: this.state.draft.address.postalCode,
                                        city: i18nText(this.state.draft.address.city),
                                        country: this.state.draft.address.country

                                    }}
                                    addressSpecials={this.state.draft.addressSpecials}
                                    date={this.state.draft.date}
                                    times={this.state.draft.times.map((time) => {
                                        return [time.start, time.end]
                                    })}
                                    onDrop={this.handleProfileDrop.bind(this)}
                                    working={this.state.working}
                                    specials={this.state.draft.specials}
                                    types={this.state.draft.types}
                                    people={this.state.draft.seats}
                                    cuisines={this.state.draft.cuisines}
                                    languages={this.state.draft.languages}
                                    allergens={this.state.draft.allergens}
                                    courtesies={this.state.draft.courtesies}
                                    amount={this.state.draft.price.amount}
                                    currency={this.state.draft.price.currency}
                                    showVoucher={this.state.showVoucher}
                                    voucher={this.state.draft.voucher}
                                    time={this.state.draft.time}
                                    onSave={(key, value) => this.handleSave(key, value)}
                                    onUp={this.handleUp.bind(this)}
                                    onDown={this.handleDown.bind(this)}
                                    disabled={this.isDisabled()}
                                    onClick={this.handleClick.bind(this)}
                                    editMode={true}
                                    bookingMode={true}
                                />

                                <EditableDetails
                                    title={!isNullOrUndefined(this.state.draft.title) ? i18nText(this.state.draft.title) : null}
                                    description={!isNullOrUndefined(this.state.draft.description) ? i18nText(this.state.draft.description) : null}
                                    menu={!isNullOrUndefined(this.state.draft.menu) ? i18nText(this.state.draft.menu) : null}
                                    time={this.state.draft.time}
                                    story={!isNullOrUndefined(this.state.draft.about) ? i18nText(this.state.draft.about) : null}
                                    firstname={this.state.user.firstname}
                                    tag={isNullOrUndefined(this.state.draft.tag) ? null : tags.filter(tag => tag.label === this.state.draft.tag)[0]}
                                    policy={this.state.draft.policy}
                                    hostImage={this.state.user.image ? this.state.user.image : asset_placeholder}
                                    ingredients={!isNullOrUndefined(this.state.draft.ingredients) ? i18nText(this.state.draft.ingredients) : null}
                                    onSave={this.handleSave.bind(this)}
                                    editMode={true} />

                                <EditableGallery
                                    images={this.state.draft.images}
                                    onDrop={this.handleDrop.bind(this)}
                                    workingUpload={this.state.workingUpload}
                                    onRemoveImage={this.handleRemoveImage.bind(this)}
                                    onSortEnd={this.handleSortEnd.bind(this)}
                                    editMode={true} />

                            </Wizard>
                        }

                        {(hasProblemsWithStripe && this.state.draft.step !== "STRIPE") &&
                            <StyledErrorFloatingButton text={i18n("host/draft/stripe/error")} onClick={this.navigateToStripe} />
                        }

                        <Footer></Footer>
                    </div>
                }

                <ErrorSnackbar message={this.state.systemError ? this.state.systemError : null}
                    open={!isNullOrUndefined(this.state.systemError)} onClose={(event) => {
                        console.log(this.state.systemError);
                        this.setState({ systemError: null })
                    }} />

            </div>
        );
    }
}

export default withDebug()(HostMyDraft);
