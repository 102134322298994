import React, {Component} from 'react';

import styled from 'styled-components';
import {i18n, i18nConfiguration, i18nText} from "../common/I18n";

import asset_route from "../assets/route_lined.svg"
import asset_chat from "../assets/chat_lined.svg"
import asset_more from "../assets/more_lined.svg"

import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';

import Moment from 'react-moment';
import 'moment/locale/de';
import moment from "moment/moment";

import Badge from '@material-ui/core/Badge';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import firebaseApp from "../common/firebase";
import {countries} from "../common/configuration";

firebaseApp.auth().useDeviceLanguage();
const firestore = firebaseApp.firestore();


const OuterWrapperCSS = styled.div`
width:100%;
height:420px;
`;

const WrapperCSS = styled.div`
width:100%;
height:345px;
display: flex;
`;

const TitleBoxCSS = styled.div`
height: 75px;
align-items: center;
`;

const TitleBlackCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
text-transform: uppercase;
`;

const TitleGreenCSS = styled(TitleBlackCSS)`
color: #04ADA9;
`;

const BoxCSS = styled.a`
opacity: 1;
display: block;
margin-left: auto;
margin-right: auto;
margin-top: 3px;
width: 97%;
height: 97%;
background: #FFFFFF;
box-shadow: 0 1px 1px 0 rgba(0,0,0,0.50);
border-radius: 8px;
user-select: none;
&:hover {
box-shadow: 0 2px 7px 0 rgba(0,0,0,0.50);
cursor: pointer;
}
&:active {
cursor: pointer;
}
`;

const ImageCSS = styled.div`
   background-image:  url(${props => props.src});
   background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 70%;
`;

const InfoCSS = styled.div`
    border-radius: 0px 0px 8px 8px;
    width: 100%;
    height: 30%;
    position: relative;
`;

const TitleCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 12px;
color: #818181;
letter-spacing: 1.29px;
line-height: 22px;
text-transform: uppercase;
padding-left: 16px;
padding-top: 6px;

`;

const SubtitleCSS = styled.div`
opacity: 0.8;
font-family: TruenoLight;
font-size: 10px;
color: #04ADA9;
letter-spacing: 0.96px;
line-height: 12px;
text-transform: uppercase;
padding-left: 16px;
`;

const ActionsCSS = styled.div`
position: absolute;
bottom: 16px;
display: flex;
justify-content:space-evenly;
width: 80%;
margin-left: auto;
margin-right: auto;
z-index: 10;
`;

const ActionCSS = styled.div`
width:30%;
opacity: 0.6;
display: flex;
justify-content:space-evenly;
&:hover {
opacity: 1;
}
&:active {
opacity: 0.5;
}
z-index: 99;
`;

const IconCSS = styled.img`

`;

const LabelCSS = styled.div`
font-family: TruenoLight;
font-size: 11px;
color: #FF6344;
letter-spacing: -0.16px;
text-transform: uppercase;
padding-left: 8px;
padding-top: 6px;
`;

const styles = theme => ({
    lightTooltip: {
        background: theme.palette.common.white,
        color: theme.palette.text.primary,
        fontFamily: 'TruenoLight',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
});

const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#FF6344',
            dark: '#FF6344',
            // contrastText: will be calculated to contast with palette.primary.main
            contrastText: '#ffffff'
        },

    },

    typography: {
        useNextVariants: true,
    },


});


class BookingBlock extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openMessages: 0
        }
    }

    UNSAFE_componentWillMount() {

        firestore.collection('messages').where("eventId", "==", this.props.booking.event.id).where("guestUid", "==", this.props.booking.uid).where("readByGuest", "==", false).onSnapshot(
            fsMessages => {
                let count = 0;
                for (const fsMessage of fsMessages.docs) {
                    count = count + 1;
                }

                this.setState({openMessages: count});
            }
        );


    }


    render() {
        const {classes} = this.props;


        return (

            <MuiThemeProvider theme={theme}>

                <OuterWrapperCSS>
                    <TitleBoxCSS>
                        <TitleBlackCSS>{i18nText(this.props.booking.event.title)}</TitleBlackCSS>
                        <TitleGreenCSS>{this.props.booking.status !== "ok" ? i18n(`event/${this.props.booking.status}`) :
                            <Moment
                                fromNow>{this.props.booking.event.start.toDate()}</Moment>}</TitleGreenCSS>
                    </TitleBoxCSS>
                    <WrapperCSS>
                        <BoxCSS onClick={(event) => window.location = `/my/event/${this.props.booking.eventId}`}>
                            <ImageCSS
                                src={this.props.booking.event.images[this.props.booking.event.mainImageIndex]}/>
                            <InfoCSS>
                                <TitleCSS>{i18n("booking/title", {
                                    city: i18nText(this.props.booking.event.address.city),
                                })}</TitleCSS>
                                <SubtitleCSS>{i18n("booking/subtitle", {
                                    date: moment(this.props.booking.event.date.toDate()).format("DD. MMM"),
                                    start: moment(this.props.booking.event.start.toDate()).format("HH:mm"),
                                    end: moment(this.props.booking.event.end.toDate()).format("HH:mm")
                                })}</SubtitleCSS>

                                <ActionsCSS>

                                    <Tooltip title={i18n("tooltip/route")}
                                             classes={{tooltip: classes.lightTooltip}}>
                                        <ActionCSS onClick={(event) => {
                                            event.stopPropagation()
                                            window.open(
                                                `https://www.google.com/maps/dir/?api=1&&destination=${this.props.booking.event.address.street} ${this.props.booking.event.address.postalCode} ${i18nText(this.props.booking.event.address.city)} ${i18nText(countries[this.props.booking.event.address.country])}`,
                                                '_blank'
                                            )
                                        }}>
                                            <IconCSS src={asset_route}/>
                                            <LabelCSS>{i18n("booking/route")}</LabelCSS>
                                        </ActionCSS>
                                    </Tooltip>
                                    <Tooltip title={i18n("tooltip/chat")}
                                             classes={{tooltip: classes.lightTooltip}}>
                                        <ActionCSS onClick={(event) => {
                                            event.stopPropagation()
                                            if (this.props.onChat) {
                                                this.props.onChat(this.props.booking)
                                            }
                                        }}>
                                            {this.state.openMessages > 0 ? (
                                                <Badge badgeContent={this.state.openMessages} color="primary">
                                                    <IconCSS src={asset_chat}/>
                                                </Badge>
                                            ) : (
                                                <IconCSS src={asset_chat}/>
                                            )}

                                            <LabelCSS>{this.props.booking.event.host.firstname}</LabelCSS>
                                        </ActionCSS>
                                    </Tooltip>

                                    {false &&
                                    <ActionCSS>
                                        <IconCSS src={asset_more}/>
                                        <LabelCSS>{i18n("booking/more")}</LabelCSS>
                                    </ActionCSS>
                                    }
                                </ActionsCSS>


                            </InfoCSS>

                        </BoxCSS>
                    </WrapperCSS>
                </OuterWrapperCSS>
            </MuiThemeProvider>

        );
    }
}

export default withStyles(styles)(BookingBlock);
