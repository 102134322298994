import React, {Component} from 'react';


import styled from 'styled-components';
import asset_cook from '../assets/cook.png';
import Cook from "./Cook";

const BoxCSS = styled.div`
    width: ${props => props.width ? props.width : "100%"};
    border-radius: 20px;
    background: var(--green);
    color: #ffffff;
    padding: 20px;
    text-align: center;
    font-family: TruenoLight;
font-size: 14px;
letter-spacing: 1.5px;
text-align: left;
    position: relative;
    @media screen and (max-width: 800px) {
        width: 80%;

}
&:before {
@media screen and (max-width: 800px) {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid var(--green);
    border-top: 10px solid transparent;
    border-bottom: 10px solid var(--green);
    right: 50%;
    left:50%;
    top: -15px;
}
content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 15px solid transparent;
  border-right: 15px solid var(--green);
  border-top: 15px solid transparent;
  border-bottom: 15px solid var(--green);
  left: -16px;
  top: 6px;
}
}
`;

class Balloon extends Component {

    constructor(props) {
        super(props);
    }


    render() {

        return (

            <div style={{opacity:0.7}}>
            <BoxCSS width={this.props.width}>
                {this.props.text}
            </BoxCSS>
            </div>
        )

    }
}

export default Balloon;
