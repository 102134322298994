import React, {Component} from 'react';
import Gallery from 'react-photo-gallery';
import styled from "styled-components";

import {i18nText, i18n} from "../common/I18n";

import {
    SortableContainer,
    SortableElement,
    arrayMove
} from "react-sortable-hoc";

import CustomPhoto from "../components/CustomPhoto";
import Dropzone from "react-dropzone";
import * as firebase from "firebase";
import {CenterCSS} from "../common/Layout";
import {Instagram} from "react-content-loader";
import StyledFlatButton from "./StyledFlatButton";


const Wrapper = styled.div`
    padding-left: 10%;
    padding-right: 33%;
    margin-top: 56px;
    padding-bottom: 24px;
    background-color: rgba(239, 178, 85, 0.03);
        @media screen and (max-width: 1100px) {
    padding-right: 10%;

}
`;


const Title = styled.div`
    opacity: 0.8;
    font-family: TruenoRegular;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    text-transform: uppercase;
    padding-top: 24px;
    padding-bottom: 24px;
`;

const DropCSS = styled.div`
display: grid;
border-radius: 8px;
padding: 8px;
border-color: rgba(255,99,68, 0.6);
border-width: 3px;
border-style: dashed;
width: 100%;
height: 100%;
min-height: 300px;
text-align: center;
user-select:none;
&:hover {
box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
cursor: pointer;
font-family: TruenoBold;
}
&:active {
box-shadow: none;
cursor: pointer;
font-family: TruenoRegular;
}
`;

const DropTextCSS = styled.div`
    opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: rgba(255,99,68, 0.6);
letter-spacing: 1.5px;
line-height: 22px;
justify-self: center;
align-self: center;
`;


class EditableGallery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: false,
        }

    }


    generatePhotos(images) {

        const ratios = [[4, 3], [1, 1], [3, 4]]
        return images.map((image, index) => {
            return {
                src: image,
                width: ratios[index % ratios.length][0],
                height: ratios[index % ratios.length][1],
            }

        });
    }

    render() {

        const SortablePhoto = SortableElement(CustomPhoto);
        const SortableGallery = SortableContainer(({photos}) => {
            return <Gallery photos={photos} ImageComponent={SortablePhoto}/>;
        });

        let dropzoneRef = null;


        return (
            <Wrapper>

                <Title>{i18n('gallery/title')}</Title>

                {this.props.workingUpload &&
                <CenterCSS width={"60%"}>
                    <Instagram width={"100%"} height={"300px"}/>
                </CenterCSS>
                }

                {!this.props.workingUpload &&
                <div>
                    <div onClick={() => {
                        dropzoneRef.open()
                    }}>


                        <Dropzone className="dragAndDropArea2"
                                  onDrop={this.props.onDrop}
                                  accept="image/*"
                                  multiple={true}
                                  ref={(node) => {
                                      dropzoneRef = node;
                                  }}>

                            <DropCSS>
                                <DropTextCSS>
                                    {i18n("gallery/dropImages")}
                                </DropTextCSS>

                                <SortableGallery
                                    axis={"xy"}
                                    photos={this.generatePhotos(this.props.images)}
                                    onSortEnd={this.props.onSortEnd}
                                    pressDelay={200}
                                />

                            </DropCSS>

                        </Dropzone>

                    </div>
                    <CenterCSS width={"60%"} style={{marginTop: 24}}>


                        {(this.props.images.length > 0) &&
                        <StyledFlatButton label={i18n("host/draft/removeImage")}
                                          onClick={this.props.onRemoveImage}/>
                        }

                    </CenterCSS>
                </div>
                }

            </Wrapper>

        );
    }
}

export default EditableGallery;
