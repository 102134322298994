import * as ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};
ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, advancedMatching, options);

export {ReactGA, ReactPixel};