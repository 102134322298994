import * as firebase from "firebase/app";
import 'firebase';

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,

};

let firebaseApp = !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();

if (process.env.REACT_APP_VERIFICATION_DISABLED_FOR_TESTING === "TRUE") {
    firebaseApp.auth().settings.appVerificationDisabledForTesting = true;
}
firebaseApp.firestore().settings({});


export const trackUserPresence = () => {

// Fetch the current user's ID from Firebase Authentication.
    const uid = firebaseApp.auth().currentUser.uid;

// Create a reference to this user's specific status node.
// This is where we will store data about being online/offline.
    let userStatusDatabaseRef = firebase.database().ref('/status/' + uid);

// We'll create two constants which we will write to
// the Realtime database when this device is offline
// or online.
    let isOfflineForDatabase = {
        status: 'offline',
        changed: firebase.database.ServerValue.TIMESTAMP,
    };

    let isOnlineForDatabase = {
        status: 'online',
        changed: firebase.database.ServerValue.TIMESTAMP,
    };

    let userStatusFirestoreRef = firebaseApp.firestore().doc('/status/' + uid);

// Firestore uses a different server timestamp value, so we'll
// create two more constants for Firestore state.
    let isOfflineForFirestore = {
        status: 'offline',
        changed: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let isOnlineForFirestore = {
        status: 'online',
        changed: firebase.firestore.FieldValue.serverTimestamp(),
    };

    firebaseApp.database().ref('.info/connected').on('value', function(snapshot) {
        if (snapshot.val() == false) {
            // Instead of simply returning, we'll also set Firestore's state
            // to 'offline'. This ensures that our Firestore cache is aware
            // of the switch to 'offline.'
            userStatusFirestoreRef.set(isOfflineForFirestore);
            return;
        };

        userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
            userStatusDatabaseRef.set(isOnlineForDatabase);

            // We'll also add Firestore set here for when we come online.
            userStatusFirestoreRef.set(isOnlineForFirestore);
        });
    });
};


export default firebaseApp;
