import React, {Component} from 'react';
import styled from 'styled-components';
import logo from './../assets/logo.svg';
import menu_white from './../assets/menu_white.svg';
import menu from './../assets/menu.svg';
import bell from './../assets/bell.svg';
import placeholder from './../assets/placeholder.svg';
import redShield from './../assets/redShield.svg';
import asset_settings from './../assets/settings.svg';
import asset_fooddome from './../assets/fooddome.svg';
import asset_logout from './../assets/logout.svg';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import {
    Link,
} from 'react-router-dom';

import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import LanguageIcon from "@material-ui/icons/Language";

import {Redirect} from 'react-router';
import P23TextField from "./P23TextField";
import StyledDialog from "./StyledDialog";

import * as firebase from "firebase";
import 'firebase/firestore';
import 'firebase/storage';

import firebaseApp, {trackUserPresence} from '../common/firebase';
import {isNullOrUndefined} from "util";
import {notNullOrUndefined} from '../common/utils';
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import {isEMailValid, uuidv4} from "../common/utils";
import Badge from "@material-ui/core/Badge/Badge";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Divider from "@material-ui/core/Divider/Divider";
import MenuList from "@material-ui/core/MenuList/MenuList";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import ChatWindow from "./ChatWindow";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {i18n, i18nLocale, i18nConfiguration} from '../common/I18n';

firebaseApp.auth().useDeviceLanguage();
const firestore = firebaseApp.firestore();


const Wrapper = styled.div`
z-index: 10;
grid-area: header;
    width:100%;
    height: 96px;
background-color: ${props => props.background ? 'rgba(239, 178, 85, 0.03)' : 'none'};
display: grid;
grid-template-columns: 25% 30% 25% 20%;
grid-row-gap: 16px;
user-select: none;
@media screen and (max-width: 950px) {
    margin-bottom: 24px;

grid-template-areas: 
"logo logo menu menu"
"content content content content"
}
grid-template-areas: 
"logo content content menu"
`;

const Logo = styled.img`
    width: 184px;
    height: 40px;
`;

const LogoCSS = styled.div`
    justify-self: start;
    align-self: center;
    grid-area: logo;
    padding-left: 24px;


`;

const ContentCSS = styled.div`
    justify-self: center;
    align-self: center;
    grid-area: content;
    width: 90%;

`;

const MenuCSS = styled.div`
    justify-self: end;
    align-self: center;
    grid-area: menu;
    padding-right: 24px;

`;


const MenuIcon = styled.img`

`;

const ImageCSS = styled.img`
border-radius: 100%;
object-fit: cover;
width:48px;
height:48px;

`;


const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
            contrastText: '#ffffff'
        },
        // secondary: {
        //     main: '#FF6344',
        //     dark: '#FF6344',
        //     contrastText: '#ffffff'
        // }

    },

    typography: {
        useNextVariants: true,
    },


    overrides: {

        MuiIconButton: {
            root: {
                marginLeft: 8

            }
        },

        MuiMenuItem: {
            root: {
                marginTop: 16,
                marginBottom: 16,
                fontFamily: "TruenoLight",
                height:"100%",

            }
        },

        MuiTypography: {
            subtitle1: {
                fontFamily: "TruenoLight",

            },
            body2: {
                fontFamily: "TruenoLight",
                whiteSpace: "normal"

            },
            body1: {
                fontSize:14,
                fontFamily: "TruenoLight",

            }
        },

        MuiList: {
            root: {
                outline: "none",
            }
        },


        MuiListItem: {
            root: {
                fontFamily: "TruenoLight",
                fontSize: 14,
                letterSpacing: 1.5,
                color: '#5C5C5C',
                textTransform: 'uppercase'

            }
        },

        MuiListSubheader: {
            root: {
                fontFamily: "TruenoLight",
                fontSize: 16,
                letterSpacing: 1.5,
                color: '#04ADA9',
                textTransform: 'uppercase',
                textAlign: "center"

            }
        }

    }
});

const styles = theme => ({
    whiteIcon: {
        color: 'white',
    },
    regularIcon: {
        color: '#FF6344',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 320,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
    },
    buttonCancel: {
        backgroundColor: theme.palette.secondary,
    },
});

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorNavigationEl: null,
            anchorNotificationEl: null,
            anchorProfileEl: null,
            redirect: false,
            open: false,
            error: null,
            working: false,
            feedback: null,
            feedbackSent: false,
            email: null,
            notifications: [],
            showChat: false,
            chatWithName: null,
            allUnreadMessages: [],
            reference: null,
            messages: [],

            dialogLanguageOpen: false,
            location: 'DE',
            language: 'en_GB',
            rememberLanguageSetting: false,
        };

    }

    UNSAFE_componentWillMount() {

        const currentLanguage = localStorage.getItem('currentLanguage');
        if (notNullOrUndefined(currentLanguage)) {
            this.setState({language: currentLanguage});
        }

        firebaseApp.auth().onAuthStateChanged(async (user) => {

            if (user) {
                try {
                    this.listen(user.uid);
                } catch (error) {
                    this.setState({systemError: error.code});
                }
            }
        });

    }

    listen = (uid) => {

        const isHost = !isNullOrUndefined(this.props.roles) && this.props.roles.host === true;
        const isGuest = !isNullOrUndefined(this.props.roles) && this.props.roles.guest === true;


        if (isHost) {
            firestore.collection('messages').where("hostUid", "==", uid).where("readByHost", "==", false).onSnapshot(
                fsMessages => {
                    let messages = [];
                    for (const fsMessage of fsMessages.docs) {

                        messages.push(fsMessage.data());
                    }

                    console.log(messages);


                    this.setState({allUnreadMessages: messages});
                }
            )
        }

        if (isGuest) {
            firestore.collection('messages').where("guestUid", "==", uid).where("readByGuest", "==", false).onSnapshot(
                fsMessages => {
                    let messages = [];
                    for (const fsMessage of fsMessages.docs) {

                        messages.push(fsMessage.data());
                    }

                    this.setState({allUnreadMessages: messages});
                }
            )
        }


        firestore.collection('scoringRequests').where("uid", "==", uid)
            .onSnapshot(
                fsScoringRequests => {

                    this.setState({notifications: []}, () => {

                        let notifications = [];

                        for (const fsScoringRequest of fsScoringRequests.docs) {

                            notifications.push({...fsScoringRequest.data(), id: fsScoringRequest.id});
                        }

                        notifications = notifications.filter((notification) => (

                            notification.status !== "done"
                            && notification.status !== "ignored"
                            && moment().diff(moment.unix(notification.created.seconds), 'days') <= 14
                        ));
                        this.setState({notifications: notifications});
                    });

                }
                , snapShotError => {
                    this.setState({systemError: snapShotError.code});
                });
    };


    handleClickNavigation = event => {
        this.setState({anchorNavigationEl: event.currentTarget});
    };

    handleOpenLanguageDialog = () => {
        this.setState({dialogLanguageOpen: true});
    };

    handleCloseLanguageDialog = () => {
        this.setState({dialogLanguageOpen: false});
    };

    handleLocationChange = event => {
        this.setState({ location: event.target.value });
    };

    handleLanguageChange = event => {
        this.setState({ language: event.target.value });
    };

    handleCheckboxLanguageChange = event => {
        this.setState({ rememberLanguageSetting: event.target.checked });
    };

    handleChangeLanguage = async () => {
        await this.setState({working: true});
        this.props.handleLanguageChange(this.state.language, this.state.rememberLanguageSetting);
        await this.setState({ working: false });
        this.handleCloseLanguageDialog();
    };

    handleClickNotification = event => {
        this.setState({anchorNotificationEl: event.currentTarget});
    };

    handleClickProfile = event => {

        this.setState({anchorProfileEl: event.currentTarget});
    };

    handleClose = event => {
        this.setState({anchorNavigationEl: null, anchorNotificationEl: null, anchorProfileEl: null});
    };

    handleLogout = () => {
        firebaseApp.auth().signOut().then(() => {
            this.setState({redirect: true});
        }).catch((error) => {
            console.log(error)
        });
    };

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    handleCancel(key) {

        this.setState({
            working: false,
            open: false,
            feedback: null,
            error: null,
            email: null,
            feedbackSent: false,

        })
    };

    handleOk(key) {

        this.setState({
            working: false,
            open: false,
            feedback: null,
            error: null,
            email: null,
            feedbackSent: false,
        })
    };

    handleSave(key) {

        this.setState({
            working: true,
        }, () => {
            firestore.collection('feedback').add({
                uid: firebase.auth().currentUser ? firebase.auth().currentUser.uid : null,
                email: firebase.auth().currentUser ? firebase.auth().currentUser.email : this.state.email,
                feedback: this.state.feedback,
                created: new Date(),
            })
                .then(() => {
                    this.setState({
                        error: null,
                        feedback: null,
                        working: false,
                        email: null,
                        feedbackSent: true,
                    })
                })
                .catch((createError) => {
                    this.setState({error: createError.code, working: false});
                });
        });
    };

    handleChat = (reference) => {

        this.setState({
                showChat: true,
                chatWithName: reference.firstname,
                reference: reference,
            }, () => {

                const isHost = !isNullOrUndefined(this.props.roles) && this.props.roles.host === true;
                const isGuest = !isNullOrUndefined(this.props.roles) && this.props.roles.guest === true;


                if (isHost) {
                    firestore.collection('messages').where("eventId", "==", reference.eventId).where("hostUid", "==", this.props.user.uid).where("guestUid", "==", reference.uid)
                        .onSnapshot(
                            fsMessages => {

                                const messages = [];
                                for (const fsMessage of fsMessages.docs) {

                                    const message = {
                                        text: fsMessage.data().text,
                                        name: fsMessage.data().isGuest ? fsMessage.data().guest.name : fsMessage.data().host.name,
                                        image: fsMessage.data().isGuest ? fsMessage.data().guest.image : fsMessage.data().host.image,
                                        other: fsMessage.data().isGuest,
                                        readByGuest: fsMessage.data().readByGuest,
                                        readByHost: fsMessage.data().readByHost,
                                        timestamp: fsMessage.data().created.toDate(),
                                        ref: fsMessage.ref,
                                    };

                                    messages.push(message);
                                }

                                messages.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());

                                this.setState({messages: messages});

                            }
                            , snapShotError => {
                                this.setState({systemError: snapShotError.code});
                            });
                }

                if (isGuest) {
                    firestore.collection('messages').where("eventId", "==", reference.eventId).where("guestUid", "==", this.props.user.uid).where("hostUid", "==", reference.uid)
                        .onSnapshot(
                            fsMessages => {

                                const messages = [];
                                for (const fsMessage of fsMessages.docs) {

                                    const message = {
                                        text: fsMessage.data().text,
                                        name: fsMessage.data().isGuest ? fsMessage.data().guest.name : fsMessage.data().host.name,
                                        image: fsMessage.data().isGuest ? fsMessage.data().guest.image : fsMessage.data().host.image,
                                        other: !fsMessage.data().isGuest,
                                        readByGuest: fsMessage.data().readByGuest,
                                        readByHost: fsMessage.data().readByHost,
                                        timestamp: fsMessage.data().created.toDate(),
                                        ref: fsMessage.ref,
                                    };

                                    messages.push(message);
                                }

                                messages.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());

                                this.setState({messages: messages});

                            }
                            , snapShotError => {
                                this.setState({systemError: snapShotError.code});
                            });
                }
            }
        );
    };

    handleCloseChat = (reference) => {
        this.setState({showChat: false, chatWithName: null, messages: [], reference: null, message: ""}, () => {

            const isHost = !isNullOrUndefined(this.props.roles) && this.props.roles.host === true;
            const isGuest = !isNullOrUndefined(this.props.roles) && this.props.roles.guest === true;

            let fs = isHost ? firestore.collection('messages').where("eventId", "==", reference.eventId).where("hostUid", "==", this.props.user.uid).where("guestUid", "==", reference.uid) :
                firestore.collection('messages').where("eventId", "==", reference.eventId).where("guestUid", "==", this.props.user.uid).where("hostUid", "==", reference.uid);


            fs
                .onSnapshot(() => {
                })
        });
    };

    handleSend = async (reference, text) => {

        const isHost = !isNullOrUndefined(this.props.roles) && this.props.roles.host === true;
        const isGuest = !isNullOrUndefined(this.props.roles) && this.props.roles.guest === true;

        if (isHost) {
            await firestore.collection("messages").add(
                {
                    host: {
                        name: this.props.user.firstname,
                        image: this.props.user.image,
                    },
                    hostUid: this.props.user.uid,
                    guest: {
                        name: reference.firstname,
                        image: reference.image,
                    },
                    guestUid: reference.uid,
                    readByHost: true,
                    readByGuest: false,
                    isGuest: false,
                    text: text,
                    eventId: reference.eventId,
                    created: new Date()
                });
        }

        if (isGuest) {
            await firestore.collection("messages").add(
                {
                    guest: {
                        name: this.props.user.firstname,
                        image: this.props.user.image,
                    },
                    guestUid: this.props.user.uid,
                    host: {
                        name: reference.firstname,
                        image: reference.image,
                    },
                    hostUid: reference.uid,
                    readByHost: false,
                    readByGuest: true,
                    isGuest: true,
                    text: text,
                    eventId: reference.eventId,
                    created: new Date()
                });
        }
    };

    handleRead = async (messages) => {

        const isHost = !isNullOrUndefined(this.props.roles) && this.props.roles.host === true;
        const isGuest = !isNullOrUndefined(this.props.roles) && this.props.roles.guest === true;

        if (isHost) {
            for (const message of messages) {
                if (!message.readByHost) {
                    await message.ref.set(
                        {
                            readByHost: true,
                        },
                        {merge: true}
                    )
                }
            }
        }

        if (isGuest) {
            for (const message of messages) {
                if (!message.readByGuest) {
                    await message.ref.set(
                        {
                            readByGuest: true,
                        },
                        {merge: true}
                    )
                }
            }
        }
    };


    render() {

        const {classes} = this.props;
        const {dialogLanguageOpen, location, language, rememberLanguageSetting} = this.state;

        const isHost = !isNullOrUndefined(this.props.roles) && this.props.roles.host === true;
        const isGuest = !isNullOrUndefined(this.props.roles) && this.props.roles.guest === true;
        const isBlocked = !isNullOrUndefined(this.props.roles) && this.props.roles.blocked === true;
        let notificationsLength = this.state.notifications.length;

        // show dummy notification until user uploads profile image
        if (notNullOrUndefined(this.props.user) && isGuest && this.props.user.image === null) {
            notificationsLength++;
        }

        return (
            <Wrapper background={this.props.background}>

                {(this.state.redirect) &&
                <Redirect push to={`/`}/>
                }

                {(isBlocked) &&
                <Redirect push to={`/suspended`}/>
                }

                <ChatWindow open={this.state.showChat}
                            name={this.state.chatWithName}
                            onRead={this.handleRead.bind(this)}
                            messages={this.state.messages}
                            reference={this.state.reference}
                            onSend={this.handleSend.bind(this)}
                            onClose={this.handleCloseChat.bind(this)}/>


                <MuiThemeProvider theme={theme}>


                    <LogoCSS>
                        {!this.props.noLogo
                            ?
                            <Link
                                to={this.props.home ? this.props.home : "/"}><Logo
                                src={logo} alt="culinarioo"/></Link>
                            : <div/>
                        }
                    </LogoCSS>

                    <ContentCSS>
                        {this.props.children}
                    </ContentCSS>

                    {!(this.props.user) &&

                    <MenuCSS>

                        <IconButton
                            aria-haspopup="true"
                            onClick={this.handleOpenLanguageDialog}
                            color="primary"
                        >
                            <LanguageIcon
                                className={this.props.logoWhite ? classes.whiteIcon : classes.regularIcon}
                            />
                        </IconButton>

                        <IconButton
                            aria-haspopup="true"
                            onClick={this.handleClickNavigation}
                            color="inherit"
                        >
                            {!this.props.logoWhite
                                ? <MenuIcon src={menu} alt="*"/>
                                : <MenuIcon src={menu_white} alt="*"/>
                            }
                        </IconButton>

                        <Menu
                            id="fade-menu1"
                            anchorEl={this.state.anchorNavigationEl}
                            open={Boolean(this.state.anchorNavigationEl)}
                            onClose={this.handleClose}
                        >
                            <a href={"/register"}><MenuItem>{i18n('header/guest')}</MenuItem></a>
                            <a href={"/host"}><MenuItem>{i18n('header/host')}</MenuItem></a>
                            <a href={"/privacy"}><MenuItem>{i18n('header/privacy')}</MenuItem></a>
                            <MenuItem onClick={event => this.setState({
                                open: true,
                                anchorNavigationEl: null,
                                anchorNotificationEl: null,
                                anchorProfileEl: null
                            })}>{i18n('header/feedback')}</MenuItem>
                            <MenuItem onClick={(e) => {
                                if (isNullOrUndefined(this.props.onLogin)) {

                                    window.location = "/?login=true";

                                } else {

                                    this.handleClose();
                                    this.props.onLogin();
                                }
                                
                            }}>{i18n('header/login')}</MenuItem>>
                        </Menu>
                    </MenuCSS>
                    }


                    {(this.props.user) &&

                    <MenuCSS>

                        <IconButton
                            aria-haspopup="true"
                            onClick={this.handleClickNotification}
                            color="inherit"
                        >

                            {(notificationsLength + this.state.allUnreadMessages.length) > 0 &&

                            <Badge badgeContent={notificationsLength + this.state.allUnreadMessages.length}
                                   color="primary">
                                <MenuIcon src={bell} alt="*"/>
                            </Badge>
                            }

                            {(notificationsLength + this.state.allUnreadMessages.length) === 0 &&
                            <MenuIcon src={bell} alt="*"/>
                            }

                        </IconButton>
                        <Menu
                            id="fade-menu2"
                            anchorEl={this.state.anchorNotificationEl}
                            open={Boolean(this.state.anchorNotificationEl)}
                            onClose={this.handleClose}
                        >
                            <MenuList subheader={<ListSubheader>{i18n("header/notifications")}</ListSubheader>}>
                                {notificationsLength === 0 &&

                                <MenuItem>
                                    <ListItemText primary={i18n('notifications/none')}
                                                  secondary={i18n('notifications/none/description')}/>
                                </MenuItem>
                                }

                                {isGuest && this.props.user.image === null && (
                                    <Link to={"/profile"}>
                                        <MenuItem key={uuidv4()}>
                                            <ListItemIcon>
                                                <Avatar alt={'Upload Picture'} src={redShield}/>
                                            </ListItemIcon>

                                            <ListItemText   primary={'Profile picture reminder'}
                                                            secondary={'Please upload your profile picture!'}
                                            />
                                    </MenuItem>
                                    </Link>
                                )}

                                {this.state.notifications.map(notification => {

                                    const asGuest = notification.uid === notification.booking.uid;
                                    const name = asGuest ? notification.event.host.firstname : notification.booking.guest.firstname;
                                    const image = asGuest ? notification.event.host.image : notification.booking.guest.image;

                                    return (
                                        <MenuItem key={uuidv4()}
                                                  onClick={e => window.location = `/score/${notification.id}`}>

                                            <ListItemIcon>
                                                <Avatar alt={name} src={image}/>
                                            </ListItemIcon>

                                            <ListItemText primary={i18n('notifications/score', {name: name})}
                                                          secondary={i18n('notifications/score/description', {name: name})}/>
                                        </MenuItem>
                                    )
                                })};
                            </MenuList>
                            <MenuList subheader={<ListSubheader>{i18n("header/messages")}</ListSubheader>}>
                                {this.state.allUnreadMessages.length === 0 &&

                                <MenuItem>
                                    <ListItemText primary={i18n('messages/none')}
                                                  secondary={i18n('messages/none/description')}/>
                                </MenuItem>
                                }

                                {this.state.allUnreadMessages.map(message => {

                                    const asGuest = this.props.user.uid === message.guestUid;
                                    const uid = asGuest ? message.hostUid : message.guestUid;
                                    const name = asGuest ? message.host.name : message.guest.name;
                                    const image = asGuest ? message.host.image : message.guest.image;
                                    const text = message.text;
                                    const reference = {eventId: message.eventId, firstname: name, image: image};

                                    return (
                                        <MenuItem key={uuidv4()}
                                                  onClick={e => this.handleChat({uid: uid, ...reference})}>

                                            <ListItemIcon>
                                                <Avatar alt={name} src={image}/>
                                            </ListItemIcon>

                                            <ListItemText primary={i18n("messages/title", {name: name, ago: moment(message.created.toDate()).fromNow(false)})}
                                                          secondary={text}/>
                                        </MenuItem>
                                    )
                                })};
                            </MenuList>


                        </Menu>

                        <IconButton
                            aria-haspopup="true"
                            onClick={this.handleClickProfile}
                            color="inherit"
                        >
                            <ImageCSS src={this.props.user.image ? this.props.user.image : placeholder}/>


                        </IconButton>

                        <Menu
                            id="fade-menu3"
                            anchorEl={this.state.anchorProfileEl}
                            open={Boolean(this.state.anchorProfileEl)}
                            onClose={this.handleClose}
                        >

                            <Link to={"/profile"}>
                                <MenuItem>
                                    <ListItemIcon>
                                        <MenuIcon src={asset_settings} alt="*"/>
                                    </ListItemIcon>
                                    <ListItemText inset primary={i18n('header/profile')}
                                                  secondary={i18n('header/profile/description')}/>
                                </MenuItem>
                            </Link>

                            {!isGuest &&
                            <Link to={"/my"}>
                                <MenuItem>
                                    <ListItemIcon>
                                        <MenuIcon src={asset_fooddome} alt="*"/>
                                    </ListItemIcon>
                                    <ListItemText inset primary={i18n('header/becomeGuest')}
                                                  secondary={i18n('header/becomeGuest/description')}/>
                                </MenuItem>
                            </Link>
                            }

                            {!isHost &&
                            <Link to={"/host/my"}>
                                <MenuItem>
                                    <ListItemIcon>
                                        <MenuIcon src={asset_fooddome} alt="*"/>
                                    </ListItemIcon>
                                    <ListItemText inset primary={i18n('header/becomeHost')}
                                                  secondary={i18n('header/becomeHost/description')}/>
                                </MenuItem>
                            </Link>
                            }

                            {isGuest && window.location.pathname !== "/my" &&
                            <Link to={"/my"}>
                                <MenuItem>
                                    <ListItemIcon>
                                        <MenuIcon src={asset_fooddome} alt="*"/>
                                    </ListItemIcon>
                                    <ListItemText inset primary={i18n('header/beGuest')}
                                                  secondary={i18n('header/beGuest/description')}/>
                                </MenuItem>
                            </Link>
                            }

                            {isHost && window.location.pathname !== "/host/my" &&
                            <Link to={"/host/my"}>
                                <MenuItem>
                                    <ListItemIcon>
                                        <MenuIcon src={asset_fooddome} alt="*"/>
                                    </ListItemIcon>
                                    <ListItemText inset primary={i18n('header/beHost')}
                                                  secondary={i18n('header/beHost/description')}/>
                                </MenuItem>
                            </Link>
                            }

                            <MenuItem onClick={this.handleLogout.bind(this)}>
                                <ListItemIcon>
                                    <MenuIcon src={asset_logout} alt="*"/>
                                </ListItemIcon>
                                <ListItemText inset primary={i18n('header/logout')}
                                              secondary={i18n('header/logout/description')}/>
                            </MenuItem>
                        </Menu>


                    </MenuCSS>
                    }


                </MuiThemeProvider>

                <StyledDialog
                    open={this.state.open}
                    title={i18n("feedback/title")}
                    text={this.state.feedbackSent ? i18n("feedback/thanks") : i18n("feedback/text")}
                    working={this.state.working}
                    name={"feedback"}
                    actions={
                        (this.state.feedbackSent) ? (
                            <DialogActions>
                                <Button onClick={event => this.handleOk("ok")} color="primary">
                                    {i18n("feedback/ok")}
                                </Button>
                            </DialogActions>
                        ) : (
                            <DialogActions>
                                <Button onClick={event => this.handleCancel("cancel")} color="primary">
                                    {i18n("edit/dialog/cancel")}
                                </Button>
                                <Button onClick={event => this.handleSave("save")} color="primary"
                                        disabled={this.state.feedback === null || this.state.feedback.length < 1 || (firebase.auth().currentUser ? false : (this.state.email === null || !isEMailValid(this.state.email)))}>
                                        {i18n("feedback/send")}
                                </Button>
                            </DialogActions>
                        )
                    }
                    control={
                        (!this.state.feedbackSent) ? (
                            <div>
                                {firebase.auth().currentUser ? (
                                    <P23TextField
                                        autoFocus={true}
                                        name="email"
                                        width={"100%"}
                                        label={i18n("feedback/email")}
                                        value={firebase.auth().currentUser.email}
                                        onChange={this.handleChange.bind(this)}
                                        disabled={true}
                                        multiline={false}
                                        inputProps={{
                                            disableUnderline: false,
                                        }}
                                    />
                                ) : (
                                    <P23TextField
                                        autoFocus={true}
                                        name="email"
                                        width={"100%"}
                                        label={i18n("feedback/email")}
                                        value={this.state.email}
                                        onChange={this.handleChange.bind(this)}
                                        error={this.state.error}
                                        multiline={false}
                                        inputProps={{
                                            disableUnderline: false,
                                        }}
                                    />
                                )}

                                <P23TextField
                                    autoFocus={false}
                                    name="feedback"
                                    width={"100%"}
                                    label={i18n("feedback/label")}
                                    value={this.state.feedback}
                                    onChange={this.handleChange.bind(this)}
                                    error={this.state.error}
                                    multiline={true}
                                    inputProps={{
                                        disableUnderline: true,
                                    }}
                                    rows={10}
                                />
                            </div>
                        ) : (<div/>)


                    }
                />

                <Dialog
                    // fullWidth={true}
                    maxWidth={'sm'}
                    open={dialogLanguageOpen}
                    onClose={this.handleCloseLanguageDialog}
                    aria-labelledby="language-dialog-title"
                >
                    <DialogContent>
                        <DialogContentText>
                            Standort und Sprachauswahl
                        </DialogContentText>
                        <form className={classes.form} noValidate>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="location">Standort</InputLabel>
                                <Select
                                    className={classes.select}
                                    value={location}
                                    onChange={this.handleLocationChange}
                                    inputProps={{
                                        name: 'location',
                                        id: 'location',
                                    }}
                                >
                                    <MenuItem value={'DE'}>Deutschland</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="location">Meine Sprache</InputLabel>
                                <Select
                                    value={language}
                                    onChange={this.handleLanguageChange}
                                    inputProps={{
                                        name: 'language',
                                        id: 'language',
                                    }}
                                >
                                    <MenuItem value={'de_DE'}>Deutsch</MenuItem>
                                    <MenuItem value={'en_GB'}>English</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rememberLanguageSetting}
                                        onChange={this.handleCheckboxLanguageChange}
                                    />
                                }
                                label="Einstellung merken"
                            />
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleChangeLanguage}
                            style={{background: '#FF6344', color: '#FFFFFF'}}
                            variant={"contained"}
                        >
                            {i18n("register/next")}
                        </Button>
                        <Button
                            onClick={this.handleCloseLanguageDialog}
                            className={classes.buttonCancel}
                            variant={"contained"}
                        >
                            {i18n("register/cancelPromoCode")}
                        </Button>
                    </DialogActions>
                </Dialog>

            </Wrapper>

        );
    }
}

export default withStyles(styles, {withTheme: true})(withWidth()(Header));
