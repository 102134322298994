import React, {Component} from 'react';


import styled from 'styled-components';
import asset_cook from '../assets/cook.png';
import Cook from "./Cook";
import Balloon from "./Balloon";

const WrapperCSS = styled.div`
margin-top: 64px;
margin-bottom: 64px;
width: ${props => props.width  ? props.width : "100%"}
display: grid;
margin-left: auto;
margin-right: auto;
grid-template-columns: 25% 25% 25% 25%;
grid-template-rows: 1fr;
grid-row-gap: 15px;
grid-template-areas: 
"illustration content content content";
@media screen and (max-width: 800px) {
grid-template-columns: 100%;
grid-template-rows: 2fr;
grid-template-areas: 
"illustration"
"content";
}
`;

const IllustrationCSS = styled.div`
grid-area: illustration;
width: 100%;
height: 100%;
justify-self: center;
align-self: start;
`;

const ContentCSS = styled.div`
grid-area: content;
width: 100%;
height: 100%;
justify-self: center;
justify-content: center;
align-self: center;
@media screen and (max-width: 800px) {
align-self: start;
justify-self: start;
}
display: flex;
flex-direction: column;
`;

export const AssistantActions = styled.div`
width: 100%;
height: 100%;
justify-content:flex-end;
display: flex;
@media screen and (max-width: 800px) {
flex-direction: column;
}
`;


class Assistent extends Component {

    constructor(props) {
        super(props);
    }


    render() {

        return (

            <WrapperCSS width={this.props.width}>
                <IllustrationCSS>
                    <Cook/>
                </IllustrationCSS>

                <ContentCSS>
                    <Balloon text={this.props.text} width={"100%"}/>
                    {this.props.actions}
                </ContentCSS>

            </WrapperCSS>
        )

    }
}

export default Assistent;
