import firebaseApp from "./firebase";
const storage = firebaseApp.storage();

const assetExtensionOrder = [
    'svg',
    'png',
    'jpg',
    'gif',
];

export const assets = {};

export async function getAssetURL(filePath) {
    return new Promise(resolve => {
        try {
            storage.ref().child(`/assets/${filePath}`).getDownloadURL()
                .then((downloadURL) => {
                    resolve(downloadURL);
                })
                .catch(e => {
                    resolve('');
                })
        } catch (e) {
            resolve('');
        }
    });
}

export async function getAssetURLWithoutExtension(filePath) {
    return new Promise(async resolve => {
        let finalURL = '';
        for (let i = 0; i < assetExtensionOrder.length; i++) {
            await new Promise(async (resolve, reject) => {
                if (finalURL !== '') {
                    resolve();
                    return;
                }
                finalURL = await getAssetURL(`${filePath}.${assetExtensionOrder[i]}`);
                resolve();
            })
        }

        resolve(finalURL);
    });
}

export async function fetchAssets(fullPathAssets=[], noExtensionAssets=[]) {

    return new Promise(async (resolve, reject) => {
        const assetURLs = {};
        for (let i = 0; i < fullPathAssets.length; i++) {
            await new Promise(async (resolve, reject) => {
                assetURLs[fullPathAssets[i]] = await getAssetURL(fullPathAssets[i]);
                resolve();
            })
        }
        for (let i = 0; i < noExtensionAssets.length; i++) {
            await new Promise(async (resolve, reject) => {
                assetURLs[noExtensionAssets[i]] = await getAssetURLWithoutExtension(noExtensionAssets[i]);
                resolve();
            })
        }
        resolve(assetURLs);
    });
}
