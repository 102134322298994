import React, {Component} from 'react';
import dot from "./../assets/dot.svg";

import styled from 'styled-components';

import Slider from "react-slick";
import {uuidv4} from "../common/utils";


const Wrapper = styled.div`
    background-color: rgba(239, 178, 85, 0.03);
    background-image: ${props => props.image ? `url('${props.image}')` : "none"};
       background-size: cover;
    background-repeat: no-repeat;
    padding-left: 0%;
    padding-right: 0%;
    position: relative;
    // min-height: 500px;
    @media screen and (max-width: 600px) {
        min-height: 340px;
    }
`;

const InnerWrapper = styled.div`
   min-height: 500px;
   @media screen and (max-width: 668px) {
        min-height: 340px;
    }
`;

const InnerWrapper2 = styled.div`
   min-height: 500px;
   @media screen and (max-width: 1030px) {
        min-height: inherit;
    }
`;

const ImagesCSS = styled.div`
position: absolute;
left: 0px;
top:0px;
width: 100%;
min-height: 500px;
display: flex;
    @media screen and (max-width: 600px) {
display: none;
}
`;

const Image2CSS = styled.div`
background-image: ${props => props.src ? `url('${props.src}')` : "none"};
       background-size: cover;
    background-repeat: no-repeat;
    width: 50%;
min-height: 500px;
background-position: center;


`;

const Image = styled.div`
   background-image:  url(${props => props.src});
   background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.50);
    border-radius: 8px;
    width: 80%;
    background-position: center;
    height: calc(1vw*0.4*38.2);
    max-height: 270px;
    margin-left: auto;
    margin-right: auto;
    top:60px;
    position:relative;
        @media screen and (max-width: 600px) {
    top:0px;
        height: 270px;
            max-height: 270px;
            width: 100%;
}
`;

const Slide = styled.div`
    margin-left: 1vw;
    margin-right: 1vw;
    max-height: 500px;
    min-height: 470px;
    position:relative;
    @media screen and (max-width: 600px) {
        max-height: 320px;
        min-height: 300px;
}
`;

const Slide2 = styled.div`
    margin-left: 1vw;
    margin-right: 1vw;
    max-height: 500px;
    min-height: 470px;
    position:relative;
    @media screen and (max-width: 1030px) {
        min-height: 277px;
    }
    @media screen and (max-width: 600px) {
        max-height: 320px;
        min-height: 300px;
}
`;

const SmallScreen = styled.div`
    display: none;
    @media screen and (max-width: 600px) {
display: block;
}
`;

const BigScreen = styled.div`
    display: block;
    @media screen and (max-width: 600px) {
display: none;
}
`;


class Cover extends Component {

    constructor(props) {
        super(props);


    }

    render() {
        const settings = {
            customPaging: function (i) {
                return (
                    <a>
                        <img src={dot} alt="*"/>
                    </a>
                );
            },
            className: "testimonialSlider",
            centerMode: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            speed: 500,
            dots: true,
            initialSlide: this.props.highlight,
            focusOnSelect: true,

        };

        let settingsSmall = {
            customPaging: function (i) {
                return (
                    <a>
                        <img src={dot} alt="*"/>
                    </a>
                );
            },
            className: "testimonialSlider",
            centerMode: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            dots: true,
            initialSlide: this.props.highlight,
            focusOnSelect: true,

        };

        return (
            <Wrapper image={this.props.images.length === 1 ? this.props.images[0] : null}>

                {this.props.images.length === 2 &&
                <InnerWrapper>
                    <ImagesCSS>
                        <Image2CSS src={this.props.images[0]}/>
                        <Image2CSS src={this.props.images[1]}/>
                    </ImagesCSS>
                    <SmallScreen>
                        <Slider {...settingsSmall}>

                            {this.props.images.map(image => {
                                    return (<div key={uuidv4()}>
                                        <Slide>
                                            <Image src={image} alt=""/>
                                        </Slide>
                                    </div>)

                                }
                            )}
                        </Slider>
                    </SmallScreen>
                </InnerWrapper>
                }

                {this.props.images.length > 2 &&
                <InnerWrapper2>
                    <BigScreen>
                        <Slider {...settings}>

                            {this.props.images.map(image => {
                                    return (<div key={uuidv4()}>
                                        <Slide2 className="Slide2">
                                            <Image src={image} className="SliderImage" alt=""/>
                                        </Slide2>
                                    </div>)

                                }
                            )}
                        </Slider>
                    </BigScreen>

                    <SmallScreen>
                        <Slider {...settingsSmall}>

                            {this.props.images.map(image => {
                                    return (<div key={uuidv4()}>
                                        <Slide>
                                            <Image src={image} alt=""/>
                                        </Slide>
                                    </div>)

                                }
                            )}
                        </Slider>
                    </SmallScreen>

                </InnerWrapper2>
                }

            </Wrapper>

        );


    }
}

export default Cover;
