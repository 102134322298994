import React, {Component} from 'react';
import {initialize} from "../common/configuration";


import styled from 'styled-components';
import {i18n, i18nText} from "../common/I18n";
import Header from "../components/Header";
import Footer from "../components/Footer";
import qs from 'qs';
import {Redirect} from 'react-router';
import quote_top from '../assets/quote_top.svg';
import quote_bottom from '../assets/quote_bottom.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {isNullOrUndefined} from "util";
import Visibility from '@material-ui/icons/Visibility';


import * as firebase from "firebase";

import firebaseApp from '../common/firebase';
import LoginForm from "../components/LoginForm";
import Timer from "../components/Timer";
import StyledButton from "../components/StyledButton";
import {CenterCSS} from "../common/Layout";
import StyledTextField from "../components/StyledTextField";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import IconButton from "@material-ui/core/IconButton/IconButton";
import VisibilityOff from "@material-ui/core/SvgIcon/SvgIcon";
import withDebug from "../common/withDebug";

const firestore = firebaseApp.firestore();

const Wizard = styled.div`

margin-top: 54px;
margin-bottom: 54px;
@media screen and (max-width: 1200px) {
margin-left: 0%;
margin-right: 0%;
}
`;

const QuestionCSS = styled.div`
width: 70%;
text-align: center;
display: grid;
grid-template-columns: 10% 80% 10%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 900px) {
width: 100%;
grid-template-columns: 20% 60% 20%;
}
`;

const ContentCSS = styled.div`
margin-top: 64px;
width: 70%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 900px) {
width: 90%;
}
`;

const TextCSS = styled.div`
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
opacity: 0.8;
text-transform: uppercase;
justify-self: center;
align-self: center;
padding-left: 24px;
padding-right: 24px;
`;

const LeftQuoteCSS = styled.img`
justify-self: end;
align-self: start;
margin-top: -32px;
`;

const RightQuoteCSS = styled.img`
justify-self: start;
align-self: end;
margin-bottom: -32px;
`;

const ErrorCSS = styled.div`
opacity: 0.8;
font-family: TruenoLight;
font-size: 18px;
color: #FF6344;
letter-spacing: 0;
line-height: 28px;
margin-top: 16px;
text-align: center;
`;


const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
    },
    typography: {
        useNextVariants: true,
    },
});


function Question(props) {
    return (
        <QuestionCSS>
            <LeftQuoteCSS src={quote_top}/>
            <TextCSS>{props.children}</TextCSS>
            <RightQuoteCSS src={quote_bottom}/>
        </QuestionCSS>
    )
}


class Auth extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mode: qs.parse(props.location.search.slice(1)).mode,
            oobCode: qs.parse(props.location.search.slice(1)).oobCode,
            apiKey: qs.parse(props.location.search.slice(1)).apiKey,
            continueUrl: qs.parse(props.location.search.slice(1)).continueUrl,
            lang: qs.parse(props.location.search.slice(1)).lang,
            error: null,
            working: false,
            validCode: false,
            verifiedCode: false,
            passwordChanged: false,
            passwordError: null,
            password: null,
            showPassword:false,
            user: null,
            restoredEmail: null,
            initialized:false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            mode: qs.parse(nextProps.location.search.slice(1)).mode,
            oobCode: qs.parse(nextProps.location.search.slice(1)).oobCode,
            apiKey: qs.parse(nextProps.location.search.slice(1)).apiKey,
            continueUrl: qs.parse(nextProps.location.search.slice(1)).continueUrl,
            lang: qs.parse(nextProps.location.search.slice(1)).lang,
        };
    }

    componentDidMount() {

        initialize().then(() => this.setState({initialized: true}));

        firebaseApp.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({user: user})

            }
        });

        // Try to apply the email verification code.

        this.setState({working: true}, () => {

            if (this.state.mode === "verifyEmail") {
                firebaseApp.auth()
                    .applyActionCode(this.state.oobCode)
                    .then(() => {
                        this.setState({working: false, validCode: true, verifiedCode: true});
                    }, error => {
                        this.setState({working: false, error: error.message, validCode: false, verifiedCode: true});
                    });
            }

            if (this.state.mode === "resetPassword") {
                firebaseApp.auth()
                    .verifyPasswordResetCode(this.state.oobCode)
                    .then(() => {
                        this.setState({working: false, validCode: true, verifiedCode: true});
                    }, error => {
                        this.setState({working: false, error: error.message, validCode: false, verifiedCode: true});
                    });
            }

            if (this.state.mode === "recoverEmail") {
                firebaseApp.auth()
                    .checkActionCode(this.state.oobCode)
                    .then((info) => {

                        const restoredEmail = info['data']['email'];
                        firebaseApp.auth()
                            .applyActionCode(this.state.oobCode)
                            .then(() => {
                                this.setState({ restoredEmail: restoredEmail, working: false, validCode: true, verifiedCode: true });
                            });
                    }, error => {
                        this.setState({working: false, error: error.message, validCode: false, verifiedCode: true});
                    });
            }
        });
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    handleClick(name) {
        switch(name) {
            case "button_next":
                window.location.href = this.state.continueUrl;
                break;
            case "button_profile":
                window.location = "/profile";
                break;
            case "button_password":

                this.setState({working: true}, () => {

                        firebaseApp.auth()
                            .confirmPasswordReset(this.state.oobCode, this.state.password)
                            .then(() => {
                                // Password reset has been confirmed and new password updated.
                                this.setState({working: false, passwordChanged: true, passwordError: null});
                            }, error => {
                                // Error occurred during confirmation. The code might have expired or the
                                // password is too weak.
                                this.setState({working: false, passwordChanged: false, passwordError: error.code });
                            });
                });



                break;
            default:
                break;
        }
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    handleClickShowPassword = () => {
        this.setState(state => ({showPassword: !state.showPassword}));
    };

    render() {

        if (!this.state.initialized) {
            return (<Timer/>);
        }

        const isVerifyEmail = this.state.mode === "verifyEmail";
        const isResetPassword = this.state.mode === "resetPassword";
        const isRecoverEmail = this.state.mode === "recoverEmail";

        return (
            <div style={{userSelect: "none"}}>

                <Header></Header>

                <Wizard>
                    <Question>{this.state.validCode ? i18n(`auth/processing/${this.state.mode}`) : i18n(`auth/processing/${this.state.mode}/error`)}</Question>

                    {this.state.working &&
                    <ContentCSS>

                        <CenterCSS width={"40%"} marginTop={"40px"}>

                            <MuiThemeProvider theme={theme}>
                                <CircularProgress size={50}/>
                            </MuiThemeProvider>
                        </CenterCSS>
                    </ContentCSS>

                    }

                    {isRecoverEmail && !this.state.working &&
                    <ContentCSS>

                        {this.state.verifiedCode &&
                        <CenterCSS width={"40%"} marginTop={"40px"}>

                            <StyledButton
                                label={i18n("auth/processing/profile")}
                                onClick={this.handleClick.bind(this)}
                                name="button_profile"
                                working={this.state.working}
                                disabled={false}/>

                        </CenterCSS>
                        }


                    </ContentCSS>
                    }

                    {isVerifyEmail && !this.state.working &&
                    <ContentCSS>


                        {this.state.verifiedCode && this.state.validCode &&
                        <CenterCSS width={"40%"} marginTop={"40px"}>

                            <MuiThemeProvider theme={theme}>
                                <CircularProgress size={50}/>
                            </MuiThemeProvider>

                            <div style={{color: "#ffffff"}}>
                                {window.location.href = this.state.continueUrl}
                            </div>

                        </CenterCSS>
                        }
                        {this.state.verifiedCode && !this.state.validCode && !this.state.working &&
                        <CenterCSS width={"40%"} marginTop={"40px"}>

                            <StyledButton
                                label={i18n("auth/processing/next")}
                                onClick={this.handleClick.bind(this)}
                                name="button_next"
                                working={this.state.working}
                                disabled={false}/>

                        </CenterCSS>
                        }


                    </ContentCSS>
                    }

                    {isResetPassword && !this.state.working &&
                    <ContentCSS>


                        {this.state.verifiedCode && this.state.validCode && !this.state.passwordChanged &&
                        <div>
                            <CenterCSS width={"60%"}>
                                <StyledTextField
                                    label={i18n("auth/resetPassword/password")}
                                    name="password"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    value={this.state.password}
                                    onChange={this.handleChange.bind(this)}
                                    error={isNullOrUndefined(this.state.passwordError) ? null : i18n("error/" + this.state.passwordError)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                            >
                                                {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    required={false}
                                    autoFocus={true}/>
                            </CenterCSS>

                            <CenterCSS width={"40%"} marginTop={"40px"}>

                                <StyledButton label={i18n("auth/resetPassword/reset")}
                                              onClick={this.handleClick.bind(this)}
                                              name="button_password"
                                              working={this.state.working}
                                              disabled={this.state.password === null || this.state.password.trim().length === 0}/>
                            </CenterCSS>
                        </div>
                        }
                        {this.state.passwordChanged &&
                        <CenterCSS width={"40%"} marginTop={"40px"}>

                            <StyledButton
                                label={i18n("auth/resetPassword/login")}
                                onClick={this.handleClick.bind(this)}
                                name="button_next"
                                working={this.state.working}
                                disabled={false}/>

                        </CenterCSS>
                        }
                        {this.state.error &&
                        <CenterCSS width={"40%"} marginTop={"40px"}>

                            <StyledButton
                                label={i18n("auth/resetPassword/again")}
                                onClick={this.handleClick.bind(this)}
                                name="button_next"
                                working={this.state.working}
                                disabled={false}/>

                        </CenterCSS>
                        }


                    </ContentCSS>
                    }
                </Wizard>
                <Footer/>
            </div>
        );
    }
}

export default withDebug()(Auth);
