import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';
import MUIDataTable from "mui-datatables";
import firebaseApp from "../common/firebase";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button/Button";
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import RefreshIcon from "@material-ui/icons/Refresh";


const firestore = firebaseApp.firestore();


const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
});

class Muted extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            working: false,
            values: [],
            showDialog: false,
            editMode: false,
            value: null,
            label: "",
            score: "0",
            data: [],
            columns: [
                {

                    name: "Action",
                    field: "action",
                    options: {
                        filter: false,
                        sort: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <Tooltip title={"Edit entry"}>
                                    <IconButton
                                        onClick={(e) => this.setState({value: value}, () => this.openDialog(true))}>
                                        <EditIcon/>
                                    </IconButton>
                                </Tooltip>

                            );
                        },
                    },

                },
                {

                    name: "Word",
                    field: "word",
                    options: {
                        filter: false,
                        sort: true
                    }
                },
                {

                    name: "Score",
                    field: "score",
                    options: {
                        filter: false,
                        sort: true
                    }
                },
            ],


            options: {
                filter: false,
                selectableRows: 'multiple',
                responsive: 'scrollFullHeight',
                customToolbar: () =>
                    <Tooltip title={"Reload Data"}>
                        <IconButton
                            onClick={this.reload}>
                            <RefreshIcon/>
                        </IconButton>
                    </Tooltip>,
                customToolbarSelect: (selectedRows) =>
                    <StyledCustomToolbarSelect
                        selectedRows={selectedRows}
                        onDelete={this.handleDelete}/>

            }
        };
    }


    componentDidMount() {
        this.reload();
    }

    reload = () => {
        this.setState({loading: true}, async () => {
            try {

                let fsValues = await firestore.collection("muted").get();

                let values = [];

                for (const fsValue of fsValues.docs) {
                    values.push({...fsValue.data(), ref: fsValue.ref});
                }

                const data = values.map(value => {

                    const line = [];

                    for (const column of this.state.columns) {
                        let field = value[column.field];

                        if (column.field === "action") {
                            field = value;
                        }
                        line.push(field);
                    }

                    return line;
                });

                this.setState({values: values, data: data, loading: false});
            } catch (error) {
                console.error(error);
                this.setState({loading: false});
            }

        })

    };

    handleDelete = (selectedRows) => {

        this.setState({working: true}, async () => {
            try {

                const batch = firestore.batch();

                for (const selectedRow of selectedRows.data) {

                    batch.delete(this.state.values[selectedRow.dataIndex].ref);
                }

                await batch.commit();

                this.setState({working: false}, () => {
                    this.reload();
                });

            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });

    };

    handleChange = event => {

        let value = event.target.value;
        this.setState({[event.target.name]: value});
    };

    openDialog = (mode) => {

        if (mode) {
            this.setState({
                showDialog: true,
                label: this.state.value.word,
                score: this.state.value.score,
                editMode: true
            })
        } else {
            this.setState({showDialog: true, label: "", score: "0", editMode: false, value: null})
        }
    };

    closeDialog = () => {
        this.setState({showDialog: false, label: "", score: "0", editMode: false, value: null})
    };

    handleSave = () => {
        this.setState({working: true}, async () => {

            const score = parseFloat(this.state.score);

            if (this.state.value) {
                await this.state.value.ref.set({
                    score: score,
                    word: this.state.label,
                });

            } else {

                await firestore.collection("muted").doc(this.state.label).set({
                    score: score,
                    word: this.state.label,
                });
            }

            this.setState({
                working: false,
                showDialog: false,
                label: "",
                score: "0",
                editMode: false,
                value: null
            }, () => {
                this.reload();
            })
        })
    };

    render() {
        const {classes} = this.props;


        const labelExistsAlready = this.state.values.filter(value => value.word === this.state.label).length > 0;
        const isValidScore = !isNaN(this.state.score) && parseFloat(this.state.score) >= 0;
        const isValidLabel = this.state.label.trim().length > 0;

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page}) => (
                        <React.Fragment>

                            {this.state.loading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress className={classes.progress} size={50}/>
                                    </Grid>
                                ) :
                                (
                                    <React.Fragment>

                                        <MUIDataTable
                                            columns={this.state.columns}
                                            data={this.state.data}
                                            title={page.title}
                                            options={this.state.options}
                                        />

                                        <Button variant="fab" className={classes.fab} color={"secondary"}
                                                onClick={(e) => this.openDialog(false)}>
                                            <AddIcon/>
                                        </Button>

                                    </React.Fragment>

                                )}


                            <Dialog
                                open={this.state.showDialog}
                                aria-labelledby="form-dialog-title"
                            >

                                {this.state.editMode ? (
                                    <React.Fragment>
                                        <DialogTitle
                                            id="form-dialog-title">Edit {this.state.label} of {page.title}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Edit the muted values. You can't change the Label
                                            </DialogContentText>

                                            <TextField
                                                value={this.state.score}
                                                margin="dense"
                                                id="score"
                                                name="score"
                                                label="Score"
                                                error={!isValidScore}
                                                helperText={!isValidScore ? "Valid score is >= 0 " : ""}
                                                type="number"
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />


                                        </DialogContent>

                                        <DialogActions>
                                            <Button onClick={this.closeDialog} color="primary"
                                                    disabled={this.state.working}>
                                                Close
                                            </Button>
                                            <Button onClick={this.handleSave} color="primary" autoFocus
                                                    disabled={this.state.working || !isValidScore}>
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <DialogTitle id="form-dialog-title">Add a new value
                                            to {page.title}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                add a new value.
                                            </DialogContentText>
                                            <TextField
                                                value={this.state.label}
                                                autoFocus
                                                margin="dense"
                                                id="label"
                                                name="label"
                                                label="Label"
                                                type="text"
                                                onChange={this.handleChange}
                                                fullWidth
                                                error={labelExistsAlready}
                                                helperText={"Label exists already"}
                                                disabled={this.state.working}
                                            />

                                            <TextField
                                                value={this.state.score}
                                                margin="dense"
                                                id="score"
                                                name="score"
                                                label="Score"
                                                error={!isValidScore}
                                                helperText={!isValidScore ? "Valid score is >= 0 " : ""}
                                                type="number"
                                                onChange={this.handleChange}
                                                fullWidth
                                                disabled={this.state.working}
                                            />


                                        </DialogContent>

                                        <DialogActions>
                                            <Button onClick={this.closeDialog} color="primary"
                                                    disabled={this.state.working}>
                                                Close
                                            </Button>
                                            <Button onClick={this.handleSave} color="primary" autoFocus
                                                    disabled={this.state.working || !isValidScore || !isValidLabel || labelExistsAlready}>
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </React.Fragment>
                                )}

                            </Dialog>


                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

Muted.propTypes = {
    classes: PropTypes.object.isRequired,
};

const defaultToolbarSelectStyles = {
    iconButton: {
        marginRight: "24px",
        top: "50%",
        display: "inline-block",
        position: "relative",
        transform: "translateY(-50%)",
    },
};

class CustomToolbarSelect extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <div className={"custom-toolbar-select"}>
                <Tooltip title={"Delete"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onDelete(this.props.selectedRows)}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

}

const StyledCustomToolbarSelect = withStyles(defaultToolbarSelectStyles, {name: "CustomToolbarSelect"})(CustomToolbarSelect);

export default withStyles(styles, {withTheme: true})(Muted)