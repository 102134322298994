import React, {Component} from 'react';
import {initialize} from "../common/configuration";
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import P23Button from '../components/P23Button';
import P23TextField from '../components/P23TextField';
import P23SelectField from '../components/P23SelectField';
import P23RadioButtons from '../components/P23RadioButtons';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'firebase/firestore';
import CookieConsent from "react-cookie-consent";


import '../stylesheets/animations.css';


import ReactTransitions from 'react-transitions';

import logo_large from "../assets/logo_large.svg";
import hero1 from "../assets/hero2.jpg";
import money from "../assets/money.svg";
import goodfood from "../assets/goodfood.svg";
import exclusive from "../assets/exclusive.svg";
import explore from "../assets/explore.svg";
import passion from "../assets/passion.svg";
import event from "../assets/event.svg";
import people from "../assets/people.svg";
import easy from "../assets/easy.svg";
import asset_globe from "../assets/globe.svg";
import styled from 'styled-components';
import {i18n, i18nLocale, i18nText, i18nConfiguration, i18nChange} from '../common/I18n';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import firebase from '../common/firebase';
import {ReactGA, ReactPixel} from "../common/tracking"
import Footer from "../components/Footer";
import StyledFloatingButton from "../components/StyledFloatingButton";
import Timer from "../components/Timer";
import withDebug from "../common/withDebug";


const firebaseDB = firebase.firestore();

const OverlayContainer = styled.div`
display: grid;
  grid-template-columns: 10% 40% 40% 10%;
  grid-template-rows: 1fr;
  @media screen and (max-width: 800px) {
    grid-template-columns: 5% 90% 5%;
      grid-template-rows: 360px 1fr;
}
  
    width: 100%;
    height: auto;
    position: relative;
    z-index:99;
`;

const BackgroundContainer = styled.div`
    width: 100%;
    height: 100%;
    /* Rectangle: */
       background-image: linear-gradient(0deg, #FFFFFF 4%, rgba(255,255,255,0.83) 76%, rgba(255,255,255,0.33) 99%);
    position: absolute;
    top:0px;
    left:0px;
   background-size: cover;
    background-repeat: no-repeat;
`;

const VideoContainer = styled.video`
      position: absolute;
  top: 50%; 
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-height: 100%; 
  min-width: 100%;
  width: auto; 
  height: auto;
  z-index: -1000; 

`;


const FormContainer = styled.div`
  grid-column-start: 3;
  grid-column-end:4;
  grid-row-start: 1;
grid-row-end: 2;
  @media screen and (max-width: 800px) {
    grid-column-start: 2;
  grid-column-end:3;
  grid-row-start: 2;
grid-row-end: 3;
}
`;

const SliderContainer = styled.div`

  grid-column-start: 2;
grid-column-end: 3;
grid-row-start: 1;
grid-row-end: 2;
  @media screen and (max-width: 800px) {
    grid-column-start: 2;
  grid-column-end:3;
  grid-row-start: 1;
grid-row-end: 2;
    padding-top: 12%;

}
    padding-top: 24%;
`;

const HeaderContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow-x: hidden;

    @media screen and (min-width: 800px) {
        overflow-y: hidden;



}
`;

const Title = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 44px;
@media screen and (max-width: 800px) {
font-size: 32px;
}
color: #333333;
letter-spacing: -0.54px;
text-align: center;
margin-top: 70px;
`;

const ButtonsCSS = styled.div`
@media screen and (max-width: 800px) {
font-size: 32px;
margin-top: 70px;
}
color: #333333;
letter-spacing: -0.54px;
text-align: center;
margin-top: 70px;
`;

const Claim = styled.div`
display: block;
margin-top:20px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 16px;
color: #818181;
letter-spacing: 0;
text-align: center;
line-height: 26px;
    width:50%;
      @media screen and (max-width: 800px) {
          width:80%;

}
    margin-left: auto;
    margin-right: auto;
`;

const SmallerTitle = styled.div`
margin-top:70px;
opacity: 0.8;
font-family: TruenoBold;
font-size: 44px;
@media screen and (max-width: 800px) {
font-size: 32px;

}
color: #333333;
letter-spacing: -0.6px;
text-align: center;
line-height: 48px;
margin-bottom: 28px;

`;

const Row = styled.div`
  margin-top: 42px;

display: grid;
  grid-template-columns: 10% 13% 25% 4% 13% 25% 10%;
  
    @media screen and (max-width: 800px) {
  grid-template-columns: 5% 30% 60% 5%;
      grid-template-rows: 50% 50%;
      grid-column-gap: 5px;
      grid-row-gap: 42px;
}

`;

const Image1 = styled.img`
grid-column-start: 2;
grid-column-end: 3;
   @media screen and (max-width: 800px) {
grid-column-start: 2;
grid-column-end: 3;
grid-row-start: 1;
grid-row-end: 2;
}
width: 90%;
box-shadow: 0 0 24px 0 rgba(0,0,0,0.04);
border-radius: 100%;
z-index: 10;
`;

const Text1 = styled.div`
grid-column-start: 3;
grid-column-end: 4;
   @media screen and (max-width: 800px) {
grid-column-start: 3;
grid-column-end: 4;
grid-row-start: 1;
grid-row-end: 2;
}

`;

const Image2 = styled.img`
grid-column-start: 5;
grid-column-end: 6;
   @media screen and (max-width: 800px) {
grid-column-start: 2;
grid-column-end: 3;
grid-row-start: 2;
grid-row-end: 3;
}
width: 90%;
box-shadow: 0 0 24px 0 rgba(0,0,0,0.04);
border-radius: 100%;
z-index: 10;

`;

const Text2 = styled.div`
grid-column-start: 6;
grid-column-end: 7;
   @media screen and (max-width: 800px) {
grid-column-start: 3;
grid-column-end: 4;
grid-row-start: 2;
grid-row-end: 3;
}
`;

const SmallTitle = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
`;

const Paragraph = styled.div`
margin-top: 12px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 14px;
color: #818181;
letter-spacing: 0;
line-height: 26px;
`;

const AboutUs = styled.div`
  display: grid;
  grid-template-columns: 15% 25% 4% 41% 15%;
     @media screen and (max-width: 800px) {
       grid-template-columns: 5% 90% 5%;
       grid-template-rows: 30% 70%;

}
`;

const AboutUsLeft = styled.div`
  grid-column-start: 2;
grid-column-end: 3;
@media screen and (max-width: 800px) {
  grid-column-start: 2;
grid-column-end: 3;
  grid-row-start: 2;
grid-row-end: 3;

}
`;

const AboutUsLine1 = styled.div`
margin-top: 16px;
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #04ADA9;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
text-align: right;
@media screen and (max-width: 800px) {
text-align: center;

}
`;

const AboutUsDetails = styled.div`
  grid-column-start: 4;
grid-column-end: 5;
@media screen and (max-width: 800px) {
  grid-column-start: 2;
grid-column-end: 3;
  grid-row-start: 1;
grid-row-end: 2;

}

`;

const AboutUsParagraph = styled.div`
margin-top: 16px;
opacity: 0.8;
font-family: TruenoLight;
font-size: 16px;
color: #818181;
letter-spacing: 0;
line-height: 26px;
`;

const AboutUsParagraphRight = styled(AboutUsParagraph)`
text-align: right;
@media screen and (max-width: 800px) {
text-align: center;
}
`;

const LargeLogo = styled.img`
margin-top: 50px;
@media screen and (max-width: 800px) {
margin-top: 32px;
}
width: 40%;
margin-bottom: 32px;
margin-left: auto;
margin-right: auto;
    display: block;

`;

const SliderParagraph = styled.div`
opacity: 0.8;
margin-top: 16px;
font-family: TruenoLight;
font-size: 18px;
color: #818181;
letter-spacing: 0;
text-align: center;
line-height: 26px;
margin-left: auto;
margin-right: auto;
display: block;
`;

const FormParagraph = styled.div`
opacity: 0.8;
margin-top: 20%;
margin-bottom: 20%;
font-family: TruenoLight;
font-size: 18px;
color: #818181;
letter-spacing: 0;
text-align: center;
line-height: 26px;
width: 80%;
margin-left: auto;
margin-right: auto;
display: block;
`;

const HashtagGreen = styled.div`
padding-top: 70px;
font-family: TruenoUltraLightItalic;
font-size: 50px;
@media screen and (max-width: 800px) {
font-size: 35px;
padding-top: 20px;

}
color: #04ADA9;
text-align: center;
margin-left: auto;
margin-right: auto;
display: block;
`;


const HashtagBlue = styled(HashtagGreen)`
color: #4B9AC1;
`;

const HashtagYellow = styled(HashtagGreen)`
color: #F5A623;
`;

const Form = styled.div`
display: block;
background-color: rgba(70,65,67,0.05);
border-radius: 8px;
width: 70%;
@media screen and (max-width: 800px) {
width: 100%;
}
margin-top: 27px;
margin-left: auto;
margin-right: auto;
padding-top: 10px;
padding-bottom: 10px;
`;

const FormTitle = styled.div`
font-family: TruenoLight;
text-transform: uppercase;
font-size: 18px;
width: 70%;
@media screen and (max-width: 800px) {
width: 100%;
}
text-align: center;
color:var(--green);
display: block;
margin-top: 40px;
margin-left: auto;
margin-right: auto;
`;

const LinkLanguageCSS = styled.span`
font-family: TruenoRegular;
text-transform: uppercase;
cursor: pointer;
font-size: 14px;
color: var(--green);
user-select: none;
&:hover {
font-family: TruenoBold;
}
`;

const theme = createMuiTheme({

    palette: {
        primary: {
            main: "#FF6344",
            light: "#FF6344"
        },
    },

    typography: {
        useNextVariants: true,
    },

    overrides: {
        typography: {
            fontFamily:
                'TruenoLight'
        },

        MuiCircularProgress: {
            root: {
                display: 'block',
                marginTop: '24px',
                marginBottom: '16px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        },

        MuiButton: {
            root: {
                textTransform: 'uppercase',
                fontSize: '18px',
                letterSpacing: 2,

            }
        },
        MuiInput: {
            root: {
                textTransform: 'uppercase',
                fontSize: '14px',
                color: '#818181',
                height: 20,
                paddingTop: 10,
                paddingBottom: 10,
                letterSpacing: 0,
            },
        },
        MuiListItem: {
            root: {
                fontFamily: "TruenoLight",
                fontSize: 14,
                letterSpacing: 1.5,
                color: '#5C5C5C',
                textTransform: 'uppercase'

            }
        }
    }
});


ReactGA.pageview('/landingPage/start');
ReactPixel.pageView();


class Landing extends Component {


    constructor(props) {
        super(props);
        this.state = {
            ticked: 0,
            name: '',
            email: '',
            country: 'DE',
            role: 'guest',
            failed: false,
            requesting: false,
            success: false,
            ip: "unknown",
            anchorLanguagesEl: null,
            initialized:false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.validate = this.validate.bind(this);
        this.tick = this.tick.bind(this);
        this.handleSend = this.handleSend.bind(this);
        this.handleAgain = this.handleAgain.bind(this);
        this.isEMailValid = this.isEMailValid.bind(this);
    }

    componentDidMount() {

        initialize().then(() => this.setState({initialized: true}));

        let timer = setInterval(this.tick, 6000);
        this.setState({timer: timer});


    }

    componentWillUnmount() {
        this.clearInterval(this.state.timer);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value}, () => {
            this.validate();
        });
    };

    handleSelectChange = name => event => {
        this.setState({[name]: event.target.value}, () => {
            this.validate();
        });
    };


    validate() {
        //
    };

    handleClickLanguage = event => {
        this.setState({anchorLanguagesEl: event.currentTarget});
    };

    handleChangeLanguage = language => {
        i18nChange(language);
        this.setState({anchorLanguagesEl: null});
        this.setState({state: this.state});
    };

    handleClose = event => {
        this.setState({anchorLanguagesEl: null});
    };

    shallBeDisabled() {
        return this.state.name === '' || !this.isEMailValid();
    };

    handleSend() {


        this.setState({requesting: true}, () => {
            firebaseDB.collection("subscribers").doc(this.state.email.toLowerCase()).set({
                name: this.state.name,
                email: this.state.email,
                country: this.state.country,
                role: this.state.role,
                ip: this.state.ip,
                date: new Date()
            })
                .then(docRef => {
                    this.setState({
                        requesting: false,
                        success: true
                    });
                })
                .catch(error => {
                    this.setState({
                        requesting: false,
                        failed: true
                    });
                });
        });
    };

    handleAgain() {
        this.setState({
            requesting: false,
            success: false,
            failed: false
        });
    };

    isEMailValid() {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(this.state.email);
    }


    tick() {

        this.setState({
            ticked: this.state.ticked + 1
        });
    }


    render() {

        if (!this.state.initialized) {
            return (<Timer/>);
        }

        const comps = [
            <div key="1">
                <LargeLogo src={logo_large} alt=""/>
                <SliderParagraph>
                    {i18n("landing/slider/1/p")}
                </SliderParagraph>
            </div>,
            <div key="2">
                <HashtagGreen>
                    {i18n("landing/slider/2/hashTag")}
                </HashtagGreen>
                <SliderParagraph>
                    {i18n("landing/slider/2/p")}
                </SliderParagraph>
                <SliderParagraph>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </SliderParagraph>
            </div>,
            <div key="3">
                <HashtagBlue>
                    {i18n("landing/slider/3/hashTag")}
                </HashtagBlue>
                <SliderParagraph>
                    {i18n("landing/slider/3/p")}

                </SliderParagraph>
            </div>,
            <div key="4">
                <HashtagYellow>
                    {i18n("landing/slider/4/hashTag")}
                </HashtagYellow>
                <SliderParagraph>
                    {i18n("landing/slider/4/p")}

                </SliderParagraph>
            </div>,
            <div key="5">
                <HashtagGreen>
                    {i18n("landing/slider/5/hashTag")}
                </HashtagGreen>
                <SliderParagraph>
                    {i18n("landing/slider/5/p")}

                </SliderParagraph>
            </div>,
            <div key="6">
                <HashtagBlue>
                    {i18n("landing/slider/6/hashTag")}
                </HashtagBlue>
                <SliderParagraph>
                    {i18n("landing/slider/6/p")}

                </SliderParagraph>
            </div>,
            <div key="7">
                <HashtagYellow>
                    {i18n("landing/slider/7/hashTag")}
                </HashtagYellow>
                <SliderParagraph>
                    {i18n("landing/slider/7/p")}
                </SliderParagraph>
            </div>,
            <div key="1">
                <LargeLogo src={logo_large} alt=""/>
                <SliderParagraph>
                    {i18n("landing/slider/1/p")}
                </SliderParagraph>
            </div>,
            <div key="8">
                <HashtagGreen>
                    {i18n("landing/slider/8/hashTag")}
                </HashtagGreen>
                <SliderParagraph>
                    {i18n("landing/slider/8/p")}

                </SliderParagraph>
            </div>,
            <div key="9">
                <HashtagBlue>
                    {i18n("landing/slider/9/hashTag")}
                </HashtagBlue>
                <SliderParagraph>
                    {i18n("landing/slider/9/p")}

                </SliderParagraph>
                <SliderParagraph>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </SliderParagraph>
            </div>,
            <div key="10">
                <HashtagYellow>
                    {i18n("landing/slider/10/hashTag")}
                </HashtagYellow>
                <SliderParagraph>
                    {i18n("landing/slider/10/p")}

                </SliderParagraph>
            </div>,
            <div key="11">
                <HashtagGreen>
                    {i18n("landing/slider/11/hashTag")}
                </HashtagGreen>
                <SliderParagraph>
                    {i18n("landing/slider/11/p")}

                </SliderParagraph>
            </div>,
            <div key="12">
                <HashtagBlue>
                    {i18n("landing/slider/12/hashTag")}
                </HashtagBlue>
                <SliderParagraph>
                    {i18n("landing/slider/12/p")}

                </SliderParagraph>
            </div>,
            <div key="13">
                <HashtagYellow>
                    {i18n("landing/slider/13/hashTag")}
                </HashtagYellow>
                <SliderParagraph>
                    {i18n("landing/slider/13/p")}

                </SliderParagraph>
            </div>,
            <div key="14">
                <HashtagGreen>
                    {i18n("landing/slider/14/hashTag")}
                </HashtagGreen>
                <SliderParagraph>
                    {i18n("landing/slider/14/p")}

                </SliderParagraph>
            </div>
        ];


        const index = this.state.ticked % comps.length;
        const comp = React.cloneElement(comps[index], {key: index});

        const radioChoices = [
            {value: "guest", label: {de_DE: "Gast", en_GB: "guest"}},
            {value: "host", label: {de_DE: "Gastgeber", en_GB: "host"}},
            {value: "both", label: {de_DE: "Beides", en_GB: "both"}}
        ];

        const countryChoices = [
            {value: "AD", label: {de_DE: "Andorra", en_GB: "Andorra"}},
            {value: "AE", label: {de_DE: "Vereinigte Arabische Emirate", en_GB: "United Arab Emirates"}},
            {value: "AF", label: {de_DE: "Afghanistan", en_GB: "Afghanistan"}},
            {value: "AG", label: {de_DE: "Antigua und Barbuda", en_GB: "Antigua and Barbuda"}},
            {value: "AI", label: {de_DE: "Anguilla", en_GB: "Anguilla"}},
            {value: "AL", label: {de_DE: "Albanien", en_GB: "Albania"}},
            {value: "AM", label: {de_DE: "Armenien", en_GB: "Armenia"}},
            {value: "AN", label: {de_DE: "Niederländische Antillen", en_GB: "Netherlands Antilles"}},
            {value: "AO", label: {de_DE: "Angola", en_GB: "Angola"}},
            {value: "AQ", label: {de_DE: "Antarktis", en_GB: "Antarctica"}},
            {value: "AR", label: {de_DE: "Argentinien", en_GB: "Argentina"}},
            {value: "AS", label: {de_DE: "Amerikanisch-Samoa", en_GB: "American Samoa"}},
            {value: "AT", label: {de_DE: "Österreich", en_GB: "Austria"}},
            {value: "AU", label: {de_DE: "Australien", en_GB: "Australia"}},
            {value: "AW", label: {de_DE: "Aruba", en_GB: "Aruba"}},
            {value: "AX", label: {de_DE: "Åland", en_GB: "Aland Islands"}},
            {value: "AZ", label: {de_DE: "Aserbaidschan", en_GB: "Azerbaijan"}},
            {value: "BA", label: {de_DE: "Bosnien und Herzegowina", en_GB: "Bosnia and Herzegovina"}},
            {value: "BB", label: {de_DE: "Barbados", en_GB: "Barbados"}},
            {value: "BD", label: {de_DE: "Bangladesch", en_GB: "Bangladesh"}},
            {value: "BE", label: {de_DE: "Belgien", en_GB: "Belgium"}},
            {value: "BF", label: {de_DE: "Burkina Faso", en_GB: "Burkina Faso"}},
            {value: "BG", label: {de_DE: "Bulgarien", en_GB: "Bulgaria"}},
            {value: "BH", label: {de_DE: "Bahrain", en_GB: "Bahrain"}},
            {value: "BI", label: {de_DE: "Burundi", en_GB: "Burundi"}},
            {value: "BJ", label: {de_DE: "Benin", en_GB: "Benin"}},
            {value: "BM", label: {de_DE: "Bermuda", en_GB: "Bermuda"}},
            {value: "BN", label: {de_DE: "Brunei Darussalam", en_GB: "Brunei"}},
            {value: "BO", label: {de_DE: "Bolivien", en_GB: "Bolivia"}},
            {value: "BR", label: {de_DE: "Brasilien", en_GB: "Brazil"}},
            {value: "BS", label: {de_DE: "Bahamas", en_GB: "Bahamas"}},
            {value: "BT", label: {de_DE: "Bhutan", en_GB: "Bhutan"}},
            {value: "BV", label: {de_DE: "Bouvetinsel", en_GB: "Bouvet Island"}},
            {value: "BW", label: {de_DE: "Botswana", en_GB: "Botswana"}},
            {value: "BY", label: {de_DE: "Belarus (Weißrussland)", en_GB: "Belarus"}},
            {value: "BZ", label: {de_DE: "Belize", en_GB: "Belize"}},
            {value: "CA", label: {de_DE: "Kanada", en_GB: "Canada"}},
            {value: "CC", label: {de_DE: "Kokosinseln (Keelinginseln)", en_GB: "Cocos (Keeling) Islands"}},
            {value: "CD", label: {de_DE: "Kongo", en_GB: "Congo (Kinshasa)"}},
            {value: "CF", label: {de_DE: "Zentralafrikanische Republik", en_GB: "Central African Republic"}},
            {value: "CG", label: {de_DE: "Republik Kongo", en_GB: "Congo (Brazzaville)"}},
            {value: "CH", label: {de_DE: "Schweiz", en_GB: "Switzerland"}},
            {value: "CI", label: {de_DE: "Elfenbeinküste", en_GB: "Ivory Coast"}},
            {value: "CK", label: {de_DE: "Cookinseln", en_GB: "Cook Islands"}},
            {value: "CL", label: {de_DE: "Chile", en_GB: "Chile"}},
            {value: "CM", label: {de_DE: "Kamerun", en_GB: "Cameroon"}},
            {value: "CN", label: {de_DE: "China", en_GB: "China"}},
            {value: "CO", label: {de_DE: "Kolumbien", en_GB: "Colombia"}},
            {value: "CR", label: {de_DE: "Costa Rica", en_GB: "Costa Rica"}},
            {value: "CU", label: {de_DE: "Kuba", en_GB: "Cuba"}},
            {value: "CV", label: {de_DE: "Kap Verde", en_GB: "Cape Verde"}},
            {value: "CX", label: {de_DE: "Weihnachtsinsel", en_GB: "Christmas Island"}},
            {value: "CY", label: {de_DE: "Zypern", en_GB: "Cyprus"}},
            {value: "CZ", label: {de_DE: "Tschechische Republik", en_GB: "Czech Republic"}},
            {value: "DE", label: {de_DE: "Deutschland", en_GB: "Germany"}},
            {value: "DJ", label: {de_DE: "Dschibuti", en_GB: "Djibouti"}},
            {value: "DK", label: {de_DE: "Dänemark", en_GB: "Denmark"}},
            {value: "DM", label: {de_DE: "Dominica", en_GB: "Dominica"}},
            {value: "DO", label: {de_DE: "Dominikanische Republik", en_GB: "Dominican Republic"}},
            {value: "DZ", label: {de_DE: "Algerien", en_GB: "Algeria"}},
            {value: "EC", label: {de_DE: "Ecuador", en_GB: "Ecuador"}},
            {value: "EE", label: {de_DE: "Estland (Reval)", en_GB: "Estonia"}},
            {value: "EG", label: {de_DE: "Ägypten", en_GB: "Egypt"}},
            {value: "EH", label: {de_DE: "Westsahara", en_GB: "Western Sahara"}},
            {value: "ER", label: {de_DE: "Eritrea", en_GB: "Eritrea"}},
            {value: "ES", label: {de_DE: "Spanien", en_GB: "Spain"}},
            {value: "ET", label: {de_DE: "Äthiopien", en_GB: "Ethiopia"}},
            {value: "FI", label: {de_DE: "Finnland", en_GB: "Finland"}},
            {value: "FJ", label: {de_DE: "Fidschi", en_GB: "Fiji"}},
            {value: "FK", label: {de_DE: "Falklandinseln (Malwinen)", en_GB: "Falkland Islands"}},
            {value: "FM", label: {de_DE: "Mikronesien", en_GB: "Micronesia"}},
            {value: "FO", label: {de_DE: "Färöer", en_GB: "Faroe Islands"}},
            {value: "FR", label: {de_DE: "Frankreich", en_GB: "France"}},
            {value: "GA", label: {de_DE: "Gabun", en_GB: "Gabon"}},
            {value: "GB", label: {de_DE: "Großbritannien und Nordirland", en_GB: "United Kingdom"}},
            {value: "GD", label: {de_DE: "Grenada", en_GB: "Grenada"}},
            {value: "GE", label: {de_DE: "Georgien", en_GB: "Georgia"}},
            {value: "GF", label: {de_DE: "Französisch-Guayana", en_GB: "French Guiana"}},
            {value: "GG", label: {de_DE: "Guernsey (Kanalinsel)", en_GB: "Guernsey"}},
            {value: "GH", label: {de_DE: "Ghana", en_GB: "Ghana"}},
            {value: "GI", label: {de_DE: "Gibraltar", en_GB: "Gibraltar"}},
            {value: "GL", label: {de_DE: "Grönland", en_GB: "Greenland"}},
            {value: "GM", label: {de_DE: "Gambia", en_GB: "Gambia"}},
            {value: "GN", label: {de_DE: "Guinea", en_GB: "Guinea"}},
            {value: "GP", label: {de_DE: "Guadeloupe", en_GB: "Guadeloupe"}},
            {value: "GQ", label: {de_DE: "Äquatorialguinea", en_GB: "Equatorial Guinea"}},
            {value: "GR", label: {de_DE: "Griechenland", en_GB: "Greece"}},
            {
                value: "GS",
                label: {
                    de_DE: "Südgeorgien und die Südl. Sandwichinseln",
                    en_GB: "South Georgia and the South Sandwich Islands"
                }
            },
            {value: "GT", label: {de_DE: "Guatemala", en_GB: "Guatemala"}},
            {value: "GU", label: {de_DE: "Guam", en_GB: "Guam"}},
            {value: "GW", label: {de_DE: "Guinea-Bissau", en_GB: "Guinea-Bissau"}},
            {value: "GY", label: {de_DE: "Guyana", en_GB: "Guyana"}},
            {value: "HK", label: {de_DE: "China", en_GB: "Hong Kong S.A.R."}},
            {value: "HM", label: {de_DE: "Heard- und McDonald-Inseln", en_GB: "Heard Island and McDonald Islands"}},
            {value: "HN", label: {de_DE: "Honduras", en_GB: "Honduras"}},
            {value: "HR", label: {de_DE: "Kroatien", en_GB: "Croatia"}},
            {value: "HT", label: {de_DE: "Haiti", en_GB: "Haiti"}},
            {value: "HU", label: {de_DE: "Ungarn", en_GB: "Hungary"}},
            {value: "ID", label: {de_DE: "Indonesien", en_GB: "Indonesia"}},
            {value: "IE", label: {de_DE: "Irland", en_GB: "Ireland"}},
            {value: "IL", label: {de_DE: "Israel", en_GB: "Israel"}},
            {value: "IM", label: {de_DE: "Insel Man", en_GB: "Isle of Man"}},
            {value: "IN", label: {de_DE: "Indien", en_GB: "India"}},
            {
                value: "IO",
                label: {de_DE: "Britisches Territorium im Indischen Ozean", en_GB: "British Indian Ocean Territory"}
            },
            {value: "IQ", label: {de_DE: "Irak", en_GB: "Iraq"}},
            {value: "IR", label: {de_DE: "Iran", en_GB: "Iran"}},
            {value: "IS", label: {de_DE: "Island", en_GB: "Iceland"}},
            {value: "IT", label: {de_DE: "Italien", en_GB: "Italy"}},
            {value: "JE", label: {de_DE: "Jersey (Kanalinsel)", en_GB: "Jersey"}},
            {value: "JM", label: {de_DE: "Jamaika", en_GB: "Jamaica"}},
            {value: "JO", label: {de_DE: "Jordanien", en_GB: "Jordan"}},
            {value: "JP", label: {de_DE: "Japan", en_GB: "Japan"}},
            {value: "KE", label: {de_DE: "Kenia", en_GB: "Kenya"}},
            {value: "KG", label: {de_DE: "Kirgisistan", en_GB: "Kyrgyzstan"}},
            {value: "KH", label: {de_DE: "Kambodscha", en_GB: "Cambodia"}},
            {value: "KI", label: {de_DE: "Kiribati", en_GB: "Kiribati"}},
            {value: "KM", label: {de_DE: "Komoren", en_GB: "Comoros"}},
            {value: "KN", label: {de_DE: "St. Kitts und Nevis", en_GB: "Saint Kitts and Nevis"}},
            {value: "KP", label: {de_DE: "Nordkorea", en_GB: "North Korea"}},
            {value: "KR", label: {de_DE: "Südkorea", en_GB: "South Korea"}},
            {value: "KW", label: {de_DE: "Kuwait", en_GB: "Kuwait"}},
            {value: "KY", label: {de_DE: "Kaimaninseln", en_GB: "Cayman Islands"}},
            {value: "KZ", label: {de_DE: "Kasachstan", en_GB: "Kazakhstan"}},
            {value: "LA", label: {de_DE: "Laos", en_GB: "Laos"}},
            {value: "LB", label: {de_DE: "Libanon", en_GB: "Lebanon"}},
            {value: "LC", label: {de_DE: "St. Lucia", en_GB: "Saint Lucia"}},
            {value: "LI", label: {de_DE: "Liechtenstein", en_GB: "Liechtenstein"}},
            {value: "LK", label: {de_DE: "Sri Lanka", en_GB: "Sri Lanka"}},
            {value: "LR", label: {de_DE: "Liberia", en_GB: "Liberia"}},
            {value: "LS", label: {de_DE: "Lesotho", en_GB: "Lesotho"}},
            {value: "LT", label: {de_DE: "Litauen", en_GB: "Lithuania"}},
            {value: "LU", label: {de_DE: "Luxemburg", en_GB: "Luxembourg"}},
            {value: "LV", label: {de_DE: "Lettland", en_GB: "Latvia"}},
            {value: "LY", label: {de_DE: "Libyen", en_GB: "Libya"}},
            {value: "MA", label: {de_DE: "Marokko", en_GB: "Morocco"}},
            {value: "MC", label: {de_DE: "Monaco", en_GB: "Monaco"}},
            {value: "MD", label: {de_DE: "Moldawien", en_GB: "Moldova"}},
            {value: "MG", label: {de_DE: "Madagaskar", en_GB: "Madagascar"}},
            {value: "MH", label: {de_DE: "Marshallinseln", en_GB: "Marshall Islands"}},
            {value: "MK", label: {de_DE: "Mazedonien", en_GB: "Macedonia"}},
            {value: "ML", label: {de_DE: "Mali", en_GB: "Mali"}},
            {value: "MM", label: {de_DE: "Myanmar (Burma)", en_GB: "Myanmar"}},
            {value: "MN", label: {de_DE: "Mongolei", en_GB: "Mongolia"}},
            {value: "MO", label: {de_DE: "China", en_GB: "Macao S.A.R."}},
            {value: "MP", label: {de_DE: "Nördliche Marianen", en_GB: "Northern Mariana Islands"}},
            {value: "MQ", label: {de_DE: "Martinique", en_GB: "Martinique"}},
            {value: "MR", label: {de_DE: "Mauretanien", en_GB: "Mauritania"}},
            {value: "MS", label: {de_DE: "Montserrat", en_GB: "Montserrat"}},
            {value: "MT", label: {de_DE: "Malta", en_GB: "Malta"}},
            {value: "MU", label: {de_DE: "Mauritius", en_GB: "Mauritius"}},
            {value: "MV", label: {de_DE: "Malediven", en_GB: "Maldives"}},
            {value: "MW", label: {de_DE: "Malawi", en_GB: "Malawi"}},
            {value: "MX", label: {de_DE: "Mexiko", en_GB: "Mexico"}},
            {value: "MY", label: {de_DE: "Malaysia", en_GB: "Malaysia"}},
            {value: "MZ", label: {de_DE: "Mosambik", en_GB: "Mozambique"}},
            {value: "NA", label: {de_DE: "Namibia", en_GB: "Namibia"}},
            {value: "NC", label: {de_DE: "Neukaledonien", en_GB: "New Caledonia"}},
            {value: "NE", label: {de_DE: "Niger", en_GB: "Niger"}},
            {value: "NF", label: {de_DE: "Norfolkinsel", en_GB: "Norfolk Island"}},
            {value: "NG", label: {de_DE: "Nigeria", en_GB: "Nigeria"}},
            {value: "NI", label: {de_DE: "Nicaragua", en_GB: "Nicaragua"}},
            {value: "NL", label: {de_DE: "Niederlande", en_GB: "Netherlands"}},
            {value: "NO", label: {de_DE: "Norwegen", en_GB: "Norway"}},
            {value: "NP", label: {de_DE: "Nepal", en_GB: "Nepal"}},
            {value: "NR", label: {de_DE: "Nauru", en_GB: "Nauru"}},
            {value: "NU", label: {de_DE: "Niue", en_GB: "Niue"}},
            {value: "NZ", label: {de_DE: "Neuseeland", en_GB: "New Zealand"}},
            {value: "OM", label: {de_DE: "Oman", en_GB: "Oman"}},
            {value: "PA", label: {de_DE: "Panama", en_GB: "Panama"}},
            {value: "PE", label: {de_DE: "Peru", en_GB: "Peru"}},
            {value: "PF", label: {de_DE: "Französisch-Polynesien", en_GB: "French Polynesia"}},
            {value: "PG", label: {de_DE: "Papua-Neuguinea", en_GB: "Papua New Guinea"}},
            {value: "PH", label: {de_DE: "Philippinen", en_GB: "Philippines"}},
            {value: "PK", label: {de_DE: "Pakistan", en_GB: "Pakistan"}},
            {value: "PL", label: {de_DE: "Polen", en_GB: "Poland"}},
            {value: "PM", label: {de_DE: "St. Pierre und Miquelon", en_GB: "Saint Pierre and Miquelon"}},
            {value: "PN", label: {de_DE: "Pitcairninseln", en_GB: "Pitcairn"}},
            {value: "PR", label: {de_DE: "Puerto Rico", en_GB: "Puerto Rico"}},
            {value: "PS", label: {de_DE: "Palästina", en_GB: "Palestine"}},
            {value: "PT", label: {de_DE: "Portugal", en_GB: "Portugal"}},
            {value: "PW", label: {de_DE: "Palau", en_GB: "Palau"}},
            {value: "PY", label: {de_DE: "Paraguay", en_GB: "Paraguay"}},
            {value: "QA", label: {de_DE: "Katar", en_GB: "Qatar"}},
            {value: "RE", label: {de_DE: "Réunion", en_GB: "Reunion"}},
            {value: "RO", label: {de_DE: "Rumänien", en_GB: "Romania"}},
            {value: "RU", label: {de_DE: "Russische Föderation", en_GB: "Russia"}},
            {value: "RW", label: {de_DE: "Ruanda", en_GB: "Rwanda"}},
            {value: "SA", label: {de_DE: "Saudi-Arabien", en_GB: "Saudi Arabia"}},
            {value: "SB", label: {de_DE: "Salomonen", en_GB: "Solomon Islands"}},
            {value: "SC", label: {de_DE: "Seychellen", en_GB: "Seychelles"}},
            {value: "SD", label: {de_DE: "Sudan", en_GB: "Sudan"}},
            {value: "SE", label: {de_DE: "Schweden", en_GB: "Sweden"}},
            {value: "SG", label: {de_DE: "Singapur", en_GB: "Singapore"}},
            {value: "SH", label: {de_DE: "St. Helena", en_GB: "Saint Helena"}},
            {value: "SI", label: {de_DE: "Slowenien", en_GB: "Slovenia"}},
            {value: "SJ", label: {de_DE: "Svalbard und Jan Mayen", en_GB: "Svalbard and Jan Mayen"}},
            {value: "SK", label: {de_DE: "Slowakei", en_GB: "Slovakia"}},
            {value: "SL", label: {de_DE: "Sierra Leone", en_GB: "Sierra Leone"}},
            {value: "SM", label: {de_DE: "San Marino", en_GB: "San Marino"}},
            {value: "SN", label: {de_DE: "Senegal", en_GB: "Senegal"}},
            {value: "SO", label: {de_DE: "Somalia", en_GB: "Somalia"}},
            {value: "SR", label: {de_DE: "Suriname", en_GB: "Suriname"}},
            {value: "ST", label: {de_DE: "São Tomé und Príncipe", en_GB: "Sao Tome and Principe"}},
            {value: "SV", label: {de_DE: "El Salvador", en_GB: "El Salvador"}},
            {value: "SY", label: {de_DE: "Syrien", en_GB: "Syria"}},
            {value: "SZ", label: {de_DE: "Swasiland", en_GB: "Swaziland"}},
            {value: "TC", label: {de_DE: "Turks- und Caicosinseln", en_GB: "Turks and Caicos Islands"}},
            {value: "TD", label: {de_DE: "Tschad", en_GB: "Chad"}},
            {
                value: "TF",
                label: {de_DE: "Französische Süd- und Antarktisgebiete", en_GB: "French Southern Territories"}
            },
            {value: "TG", label: {de_DE: "Togo", en_GB: "Togo"}},
            {value: "TH", label: {de_DE: "Thailand", en_GB: "Thailand"}},
            {value: "TJ", label: {de_DE: "Tadschikistan", en_GB: "Tajikistan"}},
            {value: "TK", label: {de_DE: "Tokelau", en_GB: "Tokelau"}},
            {value: "TL", label: {de_DE: "Timor-Leste", en_GB: "East Timor"}},
            {value: "TM", label: {de_DE: "Turkmenistan", en_GB: "Turkmenistan"}},
            {value: "TN", label: {de_DE: "Tunesien", en_GB: "Tunisia"}},
            {value: "TO", label: {de_DE: "Tonga", en_GB: "Tonga"}},
            {value: "TR", label: {de_DE: "Türkei", en_GB: "Turkey"}},
            {value: "TT", label: {de_DE: "Trinidad und Tobago", en_GB: "Trinidad and Tobago"}},
            {value: "TV", label: {de_DE: "Tuvalu", en_GB: "Tuvalu"}},
            {value: "TW", label: {de_DE: "Taiwan", en_GB: "Taiwan"}},
            {value: "TZ", label: {de_DE: "Tansania", en_GB: "Tanzania"}},
            {value: "UA", label: {de_DE: "Ukraine", en_GB: "Ukraine"}},
            {value: "UG", label: {de_DE: "Uganda", en_GB: "Uganda"}},
            {value: "UM", label: {de_DE: "Amerikanisch-Ozeanien", en_GB: "United States Minor Outlying Islands"}},
            {value: "US", label: {de_DE: "Vereinigte Staaten von Amerika", en_GB: "United States"}},
            {value: "UY", label: {de_DE: "Uruguay", en_GB: "Uruguay"}},
            {value: "UZ", label: {de_DE: "Usbekistan", en_GB: "Uzbekistan"}},
            {value: "VA", label: {de_DE: "Vatikanstadt", en_GB: "Vatican"}},
            {value: "VC", label: {de_DE: "St. Vincent und die Grenadinen", en_GB: "Saint Vincent and the Grenadines"}},
            {value: "VE", label: {de_DE: "Venezuela", en_GB: "Venezuela"}},
            {value: "VG", label: {de_DE: "Britische Jungferninseln", en_GB: "British Virgin Islands"}},
            {value: "VI", label: {de_DE: "Amerikanische Jungferninseln", en_GB: "U.S. Virgin Islands"}},
            {value: "VN", label: {de_DE: "Vietnam", en_GB: "Vietnam"}},
            {value: "VU", label: {de_DE: "Vanuatu", en_GB: "Vanuatu"}},
            {value: "WF", label: {de_DE: "Wallis und Futuna", en_GB: "Wallis and Futuna"}},
            {value: "WS", label: {de_DE: "Samoa", en_GB: "Samoa"}},
            {value: "YE", label: {de_DE: "Jemen", en_GB: "Yemen"}},
            {value: "YT", label: {de_DE: "Mayotte", en_GB: "Mayotte"}},
            {value: "ZA", label: {de_DE: "Südafrika", en_GB: "South Africa"}},
            {value: "ZM", label: {de_DE: "Sambia", en_GB: "Zambia"}},
            {value: "ZW", label: {de_DE: "Simbabwe", en_GB: "Zimbabwe"}},
            {value: "RS", label: {de_DE: "Serbien", en_GB: "Serbia"}},
            {value: "ME", label: {de_DE: "Montenegro", en_GB: "Montenegro"}},
            {value: "BL", label: {de_DE: "Saint-Barthélemy", en_GB: "Saint Barthelemy !Saint Barthélemy"}},
            {value: "BQ", label: {de_DE: "Sint Eustatius and Saba", en_GB: "Bonaire"}},
            {value: "CW", label: {de_DE: "Curaçao", en_GB: "Curacao !Curaçao"}},
            {value: "MF", label: {de_DE: "Saint-Martin (franz. Teil)", en_GB: "Saint Martin (French part)"}},
            {value: "SX", label: {de_DE: "Sint Maarten (niederl. Teil)", en_GB: "Sint Maarten (Dutch part)"}},
            {value: "SS", label: {de_DE: "Sudsudan!Südsudan", en_GB: "South Sudan"}}
        ];

        const failed = this.state.failed;
        const requesting = this.state.requesting;
        const success = this.state.success;


        return (
            <div>
                <MuiThemeProvider theme={theme}>

                    <HeaderContainer>

                        <BackgroundContainer image={hero1}/>


                        <VideoContainer
                            loop autoPlay playsInline muted={true}>
                            <source src="/video_high.mp4" type="video/mp4"/>
                        </VideoContainer>

                        <OverlayContainer>

                            <SliderContainer>
                                <ReactTransitions
                                    transition="scale-down-center-scale-up-center"
                                    width='100%'
                                    height='100%'>
                                    {comp}
                                </ReactTransitions>
                            </SliderContainer>
                            <FormContainer>

                                <FormTitle>
                                    {i18n("landing/form/title")}
                                </FormTitle>


                                {failed &&
                                <Form>

                                    <FormParagraph>
                                        {i18n("landing/form/error")}

                                    </FormParagraph>

                                    <P23Button label={i18n("landing/form/retry")}
                                               onClick={this.handleAgain}/>

                                    {ReactGA.pageview('/landingPage/error')}

                                </Form>
                                }


                                {success &&
                                <Form>

                                    <FormParagraph>
                                        {i18n("landing/form/success")}

                                    </FormParagraph>

                                    <P23Button label={i18n("landing/form/retry")}
                                               onClick={this.handleAgain}/>

                                    {ReactGA.pageview('/landingPage/success')}

                                </Form>
                                }

                                {(!failed && !success) &&
                                <Form>

                                    <P23TextField disabled={this.state.requesting} label={i18n("landing/form/name")}
                                                  name="name"
                                                  required={true} value={this.state.name}
                                                  onChange={this.handleChange}/>
                                    <P23TextField disabled={this.state.requesting} label={i18n("landing/form/email")}
                                                  name="email"
                                                  value={this.state.email} onChange={this.handleChange}
                                                  error={this.state.email.length > 0 && !this.isEMailValid()}
                                                  required={true}/>
                                    <P23RadioButtons disabled={this.state.requesting} label={i18n("landing/form/type")}
                                                     name="role" value={this.state.role} onChange={this.handleChange}
                                                     choices={radioChoices.map(choice => ({
                                                         value: choice.value,
                                                         label: i18nText(choice.label)
                                                     }))}/>
                                    <P23SelectField disabled={this.state.requesting} name="country"
                                                    label={i18n("landing/form/country")}
                                                    value={this.state.country} onChange={this.handleSelectChange}
                                                    choices={countryChoices.map(choice => ({
                                                        value: choice.value,
                                                        label: i18nText(choice.label)
                                                    })).sort(function (obj1, obj2) {
                                                        return obj1.label.localeCompare(obj2.label);
                                                    })}/>

                                    {requesting ? (
                                        <CircularProgress size={50}/>
                                    ) : (
                                        <P23Button label={i18n("landing/form/button")}
                                                   disabled={this.state.requesting || this.shallBeDisabled()}
                                                   onClick={this.handleSend}/>
                                    )}
                                </Form>
                                }


                            </FormContainer>
                        </OverlayContainer>
                    </HeaderContainer>
                    <Title>{i18n("landing/title")}</Title>
                    <Claim>{i18n("landing/claim")}</Claim>
                    <SmallerTitle>{i18n("landing/guest")}</SmallerTitle>
                    <Row>
                        <Image1 src={people} alt=""/>
                        <Text1>
                            <SmallTitle>{i18n("landing/guest/1/title")}</SmallTitle>
                            <Paragraph>{i18n("landing/guest/1/p")}</Paragraph>
                        </Text1>
                        <Image2 src={event} alt=""/>
                        <Text2>
                            <SmallTitle>{i18n("landing/guest/2/title")}</SmallTitle>
                            <Paragraph>{i18n("landing/guest/2/p")}</Paragraph>
                        </Text2>
                    </Row>
                    <Row>
                        <Image1 src={explore} alt=""/>
                        <Text1>
                            <SmallTitle>{i18n("landing/guest/3/title")}</SmallTitle>
                            <Paragraph>{i18n("landing/guest/3/p")}</Paragraph>
                        </Text1>
                        <Image2 src={goodfood} alt=""/>
                        <Text2>
                            <SmallTitle>{i18n("landing/guest/4/title")}</SmallTitle>
                            <Paragraph>{i18n("landing/guest/4/p")}</Paragraph>
                        </Text2>
                    </Row>

                    <SmallerTitle>{i18n("landing/host")}</SmallerTitle>
                    <Row>
                        <Image1 src={passion} alt=""/>
                        <Text1>
                            <SmallTitle>{i18n("landing/host/1/title")}</SmallTitle>
                            <Paragraph>{i18n("landing/host/1/p")}</Paragraph>
                        </Text1>
                        <Image2 src={easy} alt=""/>
                        <Text2>
                            <SmallTitle>{i18n("landing/host/2/title")}</SmallTitle>
                            <Paragraph>{i18n("landing/host/2/p")}</Paragraph>
                        </Text2>
                    </Row>
                    <Row>
                        <Image1 src={exclusive} alt=""/>
                        <Text1>
                            <SmallTitle>{i18n("landing/host/3/title")}</SmallTitle>
                            <Paragraph>{i18n("landing/host/3/p")}</Paragraph>
                        </Text1>
                        <Image2 src={money} alt=""/>
                        <Text2>
                            <SmallTitle>{i18n("landing/host/4/title")}</SmallTitle>
                            <Paragraph>{i18n("landing/host/4/p")}</Paragraph>
                        </Text2>
                    </Row>


                    <ButtonsCSS>
                        <Button variant="outlined" href={"/host"} color={"primary"}>
                            {i18n("landing/becomeHost")}
                        </Button>
                    </ButtonsCSS>

                    <SmallerTitle>{i18n("landing/about")}</SmallerTitle>


                    <AboutUs>
                        <AboutUsLeft>
                            <AboutUsLine1>{i18n("landing/imprint")}</AboutUsLine1>
                            <AboutUsParagraphRight>
                                {i18n("landing/imprint/p")}
                            </AboutUsParagraphRight>
                            <AboutUsLine1>{i18n("landing/press")}</AboutUsLine1>
                            <AboutUsParagraphRight>
                                {i18n("landing/press/p")}
                            </AboutUsParagraphRight>
                            <AboutUsLine1>{i18n("landing/privacy")}</AboutUsLine1>
                            <AboutUsParagraphRight>
                                {i18n("landing/privacy/p")}
                            </AboutUsParagraphRight>
                        </AboutUsLeft>

                        <AboutUsDetails>
                            <AboutUsParagraph>
                                <p>{i18n("landing/about/1/p")}</p>
                                <p>{i18n("landing/about/2/p")}</p>
                                <p>{i18n("landing/about/3/p")}</p>

                            </AboutUsParagraph>
                        </AboutUsDetails>
                    </AboutUs>

                    <Footer/>

                    <center style={{marginBottom: 250}}>
                        <IconButton
                            aria-haspopup="true"
                            color="inherit"
                            onClick={this.handleClickLanguage}
                        >
                            <img src={asset_globe} alt=""/>
                        </IconButton>
                        <Menu
                            id="fade-languages"
                            anchorEl={this.state.anchorLanguagesEl}
                            open={Boolean(this.state.anchorLanguagesEl)}
                            onClose={this.handleClose}
                        >
                            <MenuItem
                                onClick={this.handleChangeLanguage.bind(null, "de_DE")}>{i18nConfiguration('language', "de_DE")}</MenuItem>
                            <MenuItem
                                onClick={this.handleChangeLanguage.bind(null, "en_GB")}>{i18nConfiguration('language', "en_GB")}</MenuItem>

                        </Menu>

                        <LinkLanguageCSS onClick={this.handleClickLanguage}>
                            {i18nConfiguration("language", i18nLocale())}
                        </LinkLanguageCSS>
                    </center>

                </MuiThemeProvider>

                <CookieConsent
                    buttonText={i18n("cookie/ok")}
                    cookieName="culinariooCookieConsent"
                    location={"top"}
                    style={{
                        background: "#F5F5F5",
                        color: "var(--green)",
                        fontFamily: "TruenoLight",
                        alignItems: "start",
                        opacity: "0.8",
                    }}
                    buttonStyle={{
                        background: "var(--red)",
                        color: "white",
                        fontFamily: "TruenoRegular",
                        borderRadius: 8,
                        fontSize: 18,
                        textTransform: "uppercase"

                    }}
                >
                    {i18n("cookie/text")}
                </CookieConsent>

                <StyledFloatingButton text={i18n("landing/fab/becomeHost")} href={"/host"}/>

            </div>
        );
    }
}

export default withDebug()(Landing);
