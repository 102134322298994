import React, {Component} from 'react';
import styled from 'styled-components';

import {Redirect} from 'react-router';

import placeholder from '../assets/placeholder.svg';
import asset_people from '../assets/icon_people.svg';
import asset_cook from '../assets/icon_cook.svg';
import asset_plate from '../assets/icon_plate.svg';
import asset_globe from '../assets/icon_globe.svg';
import asset_leaf from '../assets/icon_leaf.svg';
import asset_place from '../assets/icon_place.svg';
import asset_medical from '../assets/icon_medical.svg';
import asset_house from '../assets/icon_house.svg';
import asset_clock from '../assets/icon_clock.svg';
import asset_map from "../assets/icon_map.svg";
import asset_select from '../assets/select_dark.svg';


import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Rating from '../components/Rating';
import Moment from 'react-moment';
import 'moment/locale/de';
import moment from 'moment';


import {i18nText, i18n, i18nLocale, i18nConfiguration} from "../common/I18n";
import ScrollSelect from "./ScrollSelect";
import {calcFee, uuidv4, nf} from "../common/utils";
import {isNullOrUndefined} from "util";
import StyledInvertedWhiteButton from "./StyledGreyButton";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import FavoriteIcon from "@material-ui/icons/FavoriteBorder";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import UnfavoriteIcon from '@material-ui/icons/Favorite';
import WarningIcon from '@material-ui/icons/Warning';
import StyledButton from "./StyledButton";
import Tooltip from '@material-ui/core/Tooltip';
import {fees} from "../common/configuration";


const WrapperCSS = styled.div`
    margin-left: 69%;
    height: 120%;
    width:25%;
    margin-top: ${(props) => props.marginTop ? props.marginTop : "420px"};
    position: absolute;
    top:0;
@media screen and (max-width: 1100px) {
    display:block;
    position:relative;
    margin-top: 0px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
        width:100%;
}
`;

const StickyBoxCSS = styled.div`
position: sticky;
display: block;
top:0px;
z-index: 99;
@media screen and (max-width: 1100px) {
    position:relative;
    margin-top: 0px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    top:0px;
}
`;

const BoxCSS = styled.div`
background: #FFFFFF;
box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
border-radius: 8px;
position: relative;
@media screen and (max-width: 1100px) {
width:100%;
box-shadow: none;
border-radius: 0px;
}
width:90%;
display: block;
margin-top: 0;
margin-left: auto;
margin-right: auto;
`;


const PriceBox = styled.div`
width:50%;
display: grid;
margin-left: auto;
margin-right: auto;
padding-top: 12px;
grid-template-columns: repeat(3, 1fr);
`;

const Amount = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 44px;
color: #04ADA9;
letter-spacing: -0.6px;
text-align: center;
line-height: 48px;
`;

const Currency = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 28px;
color: #04ADA9;
letter-spacing: -0.2px;
text-align: center;
line-height: 32px;
justify-self: start;
align-self: start;
padding-left: 4px;
`;

const PerGuest = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #E2E2E2;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
grid-column-start: span 3;
text-transform: uppercase;
padding-top: 8px;
`;


class Price extends Component {
    constructor(props) {
        super(props);


    }

    render() {



        return (

            <PriceBox>

                <div/>
                <Amount>{nf.format(this.props.price.amount + calcFee(this.props.price.amount, fees["basic"]))}</Amount>
                <Currency>{i18nConfiguration('currency', this.props.price.currency)}</Currency>
                <PerGuest>{i18n("overview/guest")}</PerGuest>

            </PriceBox>

        );
    }

}

const ButtonBox = styled.div`
width:100%;
display: grid;
margin-left: auto;
margin-right: auto;
height: 35px;
margin-top: 8px;
grid-template-columns: 15% 30% 40% 15%;
position: relative;
`;

const Line = styled.hr`
border: 0;
height: 0;
border-top: 1px solid rgba(0, 0, 0, 0.1);
border-bottom: 1px solid rgba(255, 255, 255, 0.3);
width: 100%;
align-self: center;
`;

const ButtonCSS = styled.div`
background: #FF6344;
border-radius: 0 8px 8px 0;
font-family: TruenoRegular;
font-size: 14px;
color: #FFFFFF;
letter-spacing: 1.5px;
text-align: center;
line-height: 35px;
height: 100%;
text-transform: uppercase;
user-select: none;
&:hover {
box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
cursor: pointer;
font-family: TruenoBold;
}
&:active {
box-shadow: none;
cursor: pointer;
font-family: TruenoRegular;
}
`;

const ButtonDisabledCSS = styled.div`
background: #EBEBEB;
border-radius: 0 8px 8px 0;
font-family: TruenoRegular;
font-size: 14px;
color: #FFFFFF;
letter-spacing: 1.5px;
text-align: center;
line-height: 35px;
height: 100%;
text-transform: uppercase;
user-select: none;
`;

const DateBox = styled.div`
/* Rectangle: */
background: #EBEBEB;
border-radius: 8px 0 0 8px;
font-family: TruenoLight;
font-size: 14px;
color: rgba(129,129,129,0.8);
letter-spacing: 1.5px;
text-align: center;
line-height: 35px;
height: 100%;
user-select: none;
&:hover {
box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
cursor: pointer;
font-family: TruenoRegular;
}
&:active {
box-shadow: none;
cursor: pointer;
}
`;

const Select = styled.img`
padding-left:8px;
padding-bottom:3px;

`;

const MenuItemStyle = {
    fontFamily: 'TruenoLight',
    fontSize: '14px',
    color: 'rgba(129,129,129,0.8)',
    letterSpacing: 1.5,
    lineHeight: '35px'
};

class BookButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
        };


    }

    handleClick = event => {
        this.setState({anchorEl: event.currentTarget});
    };


    handleMenuItemClick = (event, index) => {
        this.setState({anchorEl: null}, () => {
            this.props.onChange(index);
        });
    };

    handleClose = event => {
        this.setState({anchorEl: null});
    };

    render() {
        const {anchorEl} = this.state;

        return (

            <ButtonBox>

                <Line/>
                <DateBox>

                    <div
                        aria-owns={anchorEl ? 'simple-menu' : null}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                    >
                        <Moment format="DD. MMM">{this.props.dates[this.props.selected].date}</Moment>
                        <Select src={asset_select}/>
                    </div>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}

                    >


                        {this.props.dates.map((date, index) =>

                            <MenuItem
                                key={index}
                                value={index}
                                selected={index === this.props.selected}
                                style={MenuItemStyle}
                                onClick={event => this.handleMenuItemClick(event, index)}>
                                {i18n("overview/menuItem", {
                                    day: moment(date.date).format("DD. MMM"),
                                    start: moment(date.start).format("HH:mm"),
                                    end: moment(date.end).format("HH:mm")
                                })}
                            </MenuItem>
                        )}

                    </Menu>
                </DateBox>
                {this.props.bookable &&
                <ButtonCSS onClick={this.props.onBook}>{i18n("overview/book")}</ButtonCSS>
                }
                {!this.props.bookable &&
                <ButtonDisabledCSS>{i18n("overview/book")}</ButtonDisabledCSS>
                }
                <Line/>


            </ButtonBox>

        );
    }

}

const BoxEntriesGrid = styled.div`
margin-top: 16px;
width: 100%;
display: grid;
grid-template-columns: 45% 55%;
@media screen and (max-width: 600px) {
grid-template-columns: 30% 70%;
}

`;

const BoxEntryProfileGrid = styled.div`
margin-top: 16px;
width: 100%;
display: grid;
grid-template-columns: 35% 65%;

`;


const BoxEntriesWrapper = styled.div`
margin-top: 24px;
width: 100%;
padding-bottom: 24px;
`;

const BoxEntryIcon = styled.img`
align-self: start;
  justify-self: end;
  margin-right: ${
    props => {
        if (props.extraPadding) {
            return (16 + props.extraPadding) + "px";
        } else {
            return "16px";
        }
    }};
`;

const BoxEntryProfileIcon = styled.img`
align-self: center;
  justify-self: end;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: ${
    props => {
        if (props.extraPadding) {
            return (16 + props.extraPadding) + "px";
        } else {
            return "16px";
        }
    }};
`;

const BoxEntryProfile = styled.img`
border-radius: 100px;
width: 40px;
align-self: start;
  justify-self: end;
  margin-right: ${
    props => {
        if (props.extraPadding) {
            return (16 + props.extraPadding) + "px";
        } else {
            return "16px";
        }
    }};
`;

const BoxEntryText = styled.div`
font-family: TruenoLight;
font-size: 14px;
color: #818181;
letter-spacing: 0;
line-height: 14px;
padding-top: 2px;
`;

const BoxEntryTextLine = styled.p`
    -webkit-margin-after: 0.5em;
`;

const BoxEntrySelectLine = styled.div`
`;

const Button2CSS = styled.div`
margin-top: 32px;
width:50%;
display: block;
margin-left: auto;
margin-right: auto;
padding-bottom: 32px;
`;

class BoxEntries extends Component {
    constructor(props) {
        super(props);


    }

    render() {
        return (
            <BoxEntriesWrapper>

                {this.props.entries.map(entry => {
                    if ((entry.values.length !== 0 && !isNullOrUndefined(entry.values[0]))) {

                        return (


                            <BoxEntriesGrid key={uuidv4()}>

                                <Tooltip title={entry.tooltip} enterTouchDelay={0}>
                                    <BoxEntryIcon src={entry.icon} extraPadding={entry.extraPadding}/>
                                </Tooltip>

                                <BoxEntryText>

                                    {entry.values.map(value => {
                                        return (
                                            <BoxEntryTextLine key={uuidv4()}>{value}</BoxEntryTextLine>
                                        )
                                    })}
                                </BoxEntryText>


                            </BoxEntriesGrid>)
                    }
                })}
            </BoxEntriesWrapper>

        )
    }

}

const theme = createMuiTheme({

    typography: {
        useNextVariants: true,
    },


    overrides: {

        MuiIconButton: {
            root: {
                marginLeft: 8

            }
        },

        MuiMenuItem: {
            root: {
                marginTop: 16,
                marginBottom: 16,
                fontFamily: "TruenoLight",
                fontSize: 14,
                letterSpacing: 1.5,
                color: '#FF6344',
                textTransform: 'uppercase'
            }
        },

    }
});

const Button1CSS = styled.div`
margin-top: 32px;
width:50%;
display: block;
margin-left: auto;
margin-right: auto;
`;

class Overview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorMoreEl: null,
            test: true,
        }

    }

    handleMore = (name, event) => {
        this.setState({anchorMoreEl: event.currentTarget});
    };

    handleClose = event => {

        this.setState({anchorMoreEl: null});
    };

    handleReport = event => {

        this.setState({anchorMoreEl: null}, () => {
            this.props.onReport();
        });
    };


    handleFavorite = event => {

        this.setState({anchorMoreEl: null}, () => {
            this.props.onFavorite(this.props.event.id);
        });
    };

    handleUnFavorite = event => {

        this.setState({anchorMoreEl: null}, () => {
            this.props.onUnFavorite(this.props.favorite.id);
        });
    };

    render() {

        const entries = [
            {
                icon: asset_place,
                values: [i18nText(this.props.event.address.city)],
                extraPadding: 3,
                tooltip: i18n("overview/address/tooltip"),

            },
            {
                icon: asset_map,
                values: [i18nText(this.props.addressSpecials)],
                extraPadding: 3,
                name: "addressSpecials",
                tooltip: i18n("overview/addressSpecials/tooltip"),
            },
            {
                icon: asset_clock,
                values: [i18n("overview/date", {
                    date: moment(this.props.event.start.toDate()).format("dddd")
                }),
                    i18n("overview/date", {
                        date: moment(this.props.event.start.toDate()).format("DD. MMMM YYYY")
                    }),
                    i18n("overview/time", {
                        start: moment(this.props.event.start.toDate()).format("HH:mm"),
                        end: moment(this.props.event.end.toDate()).format("HH:mm")
                    }),
                ],
                tooltip: i18n("overview/times/tooltip"),

            },
            {
                icon: asset_people,
                values: [
                    ((this.props.event.seats.max - this.props.event.currentSeats) > 0) ? i18n("overview/seats/free", {
                        current: this.props.event.seats.max - this.props.event.currentSeats,
                    }) : i18n("overview/seats/booked")],
                tooltip: i18n("overview/people/tooltip"),

            },
            {
                icon: asset_plate,
                values: this.props.event.cuisines.map(entry => i18nConfiguration('cuisine', entry)),
                tooltip: i18n("overview/cuisines/tooltip"),
            },
            {
                icon: asset_cook,
                values: this.props.event.types.map(entry => i18nConfiguration('type', entry)),
                extraPadding: 2,
                tooltip: i18n("overview/types/tooltip"),
            },
            {
                icon: asset_globe,
                values: this.props.event.languages.map(entry => i18nConfiguration('language', entry)),
                tooltip: i18n("overview/languages/tooltip"),
            },
            {
                icon: asset_leaf,
                values: this.props.event.specials.map(entry => i18nConfiguration('special', entry)),
                tooltip: i18n("overview/specials/tooltip"),
            },
            {
                icon: asset_medical,
                values: this.props.event.allergens.map(entry => i18nConfiguration('allergen', entry)),
                tooltip: i18n("overview/allergens/tooltip"),
                extraPadding: 2
            },
            {
                icon: asset_house,
                values: this.props.event.courtesies.map(entry => i18nConfiguration('courtesy', entry)),
                tooltip: i18n("overview/courtesies/tooltip"),

            },
        ];

        const remainingSeats = this.props.event ? (this.props.event.seats.max - this.props.event.currentSeats) : 1;
        const peopleList = [...Array(remainingSeats).keys()].map(i => i === 0 ? i18n("people/me") : `+${i}`);

        return (

            <WrapperCSS>

                <StickyBoxCSS>

                    <BoxCSS>
                        <Rating
                            style={{
                                width: '33%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                paddingTop: '12px'

                            }}
                            score={this.props.event.host.score}/>
                        <Price price={this.props.event.price}/>

                        <BookButton dates={this.props.events.map(event => {
                            return {date: event.date.toDate(), start: event.start.toDate(), end: event.end.toDate()}
                        })} selected={this.props.selected} bookable={this.props.events[this.props.selected].status === "running"}
                                    onChange={this.props.onSelectionChange}
                                    onBook={this.props.onBook}/>

                        {this.props.isGuest &&

                        <BoxEntryProfileGrid key={uuidv4()}>

                            <BoxEntryProfileIcon src={this.props.user.image ? this.props.user.image : placeholder}
                                                 title={this.props.user.firstname}/>

                            <BoxEntryText>

                                <BoxEntrySelectLine key={uuidv4()}>
                                    <ScrollSelect
                                        height={"50px"}
                                        values={peopleList}
                                        scrollAfter={1}
                                        minVisible={2}
                                        boxSize={50}
                                        disabled={false}
                                        selected={this.props.people}
                                        onChange={this.props.onPeopleChange}
                                        name={"people"}
                                    />
                                </BoxEntrySelectLine>
                            </BoxEntryText>


                        </BoxEntryProfileGrid>
                        }
                        <BoxEntries entries={entries}
                                    scrollAfter={2}
                                    minVisible={3}
                                    boxSize={35}
                        />

                        {this.props.isAuth &&
                        <Button1CSS>
                            <StyledButton label={i18n("bookedOverview/chat")}
                                          onClick={this.props.onChat}
                                          name="chat"
                                          minWidth={"0"}/>
                        </Button1CSS>
                        }

                        {this.props.isAuth &&
                        <MuiThemeProvider theme={theme}>


                            <Button2CSS>
                                <StyledInvertedWhiteButton label={i18n("bookedOverview/more")}
                                                           name="more"
                                                           onClick={this.handleMore}

                                />

                                <Menu
                                    id="more-menu"
                                    anchorEl={this.state.anchorMoreEl}
                                    open={Boolean(this.state.anchorMoreEl)}
                                    onClose={this.handleClose}
                                >

                                    {!this.props.favorite &&
                                    <MenuItem onClick={this.handleFavorite.bind(this)}>
                                        <ListItemIcon>
                                            <FavoriteIcon/>
                                        </ListItemIcon>
                                        <ListItemText inset primary={i18n('bookedOverview/favorite')}
                                                      secondary={i18n('bookedOverview/favorite/description')}/>
                                    </MenuItem>
                                    }

                                    {this.props.favorite &&
                                    <MenuItem onClick={this.handleUnFavorite.bind(this)}>
                                        <ListItemIcon>
                                            <UnfavoriteIcon/>
                                        </ListItemIcon>
                                        <ListItemText inset primary={i18n('bookedOverview/unfavorite')}
                                                      secondary={i18n('bookedOverview/unfavorite/description')}/>
                                    </MenuItem>
                                    }

                                    <MenuItem onClick={this.handleReport.bind(this)}>
                                        <ListItemIcon>
                                            <WarningIcon/>
                                        </ListItemIcon>
                                        <ListItemText inset primary={i18n('bookedOverview/report')}
                                                      secondary={i18n('bookedOverview/report/description')}/>
                                    </MenuItem>

                                </Menu>

                            </Button2CSS>
                        </MuiThemeProvider>
                        }


                    </BoxCSS>
                </StickyBoxCSS>


            </WrapperCSS>

        );
    }
}


export default Overview;
