import React, {Component} from 'react';
import {initialize} from "../common/configuration";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Progress from "../components/Progress";
import {CenterCSS, Grid, BoxCSS} from "../common/Layout";
import {i18n, i18nConfiguration, i18nText, i18nLocale, i18nChange} from "../common/I18n";
import styled from 'styled-components';

import quote_top from '../assets/quote_top.svg';
import quote_bottom from '../assets/quote_bottom.svg';
import StyledButton from "../components/StyledButton";
import Timer from "../components/Timer";
import Moment from 'react-moment';
import 'moment/locale/de';
import moment from "moment/moment";
import {Redirect} from 'react-router';
import firebaseApp, {trackUserPresence} from "../common/firebase";

import * as firebase from "firebase";

import {isNullOrUndefined} from "util";
import {ReactGA} from "../common/tracking";
import ErrorSnackbar from "../components/ErrorSnackbar";
import StyledProgress from "../components/StyledProgress";
import StyledFlatButton from "../components/StyledFlatButton";
import {List} from "react-content-loader";
import Rating from "react-rating";

import asset_emptystar from '../assets/star.svg';
import asset_star from '../assets/filled_start.svg';
import Avatar from "@material-ui/core/Avatar/Avatar";
import StyledTextArea from "../components/StyledTextArea";
import withDebug from "../common/withDebug";

firebaseApp.auth().useDeviceLanguage();
const firestore = firebaseApp.firestore();


const Wizard = styled.div`

margin-top: 54px;
margin-bottom: 54px;
@media screen and (max-width: 1200px) {
margin-left: 0%;
margin-right: 0%;
}
`;

const QuestionCSS = styled.div`
width: 70%;
text-align: center;
display: grid;
grid-template-columns: 10% 80% 10%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 900px) {
width: 100%;
grid-template-columns: 20% 60% 20%;
}
`;

const ContentCSS = styled.div`
margin-top: 64px;
width: 70%;
margin-left: auto;
margin-right: auto;
@media screen and (max-width: 900px) {
width: 90%;
}
`;

const TextCSS = styled.div`
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
opacity: 0.8;
text-transform: uppercase;
justify-self: center;
align-self: center;
padding-left: 24px;
padding-right: 24px;
`;

const LeftQuoteCSS = styled.img`
justify-self: end;
align-self: start;
margin-top: -32px;
`;

const RightQuoteCSS = styled.img`
justify-self: start;
align-self: end;
margin-bottom: -32px;
`;

const ProfileCSS = styled.img`
width: 132px;
height: 132px;
object-fit: cover;
border-radius: 100%;
`;


function Question(props) {
    return (
        <QuestionCSS>
            <LeftQuoteCSS src={quote_top}/>
            <TextCSS>{props.children}</TextCSS>
            <RightQuoteCSS src={quote_bottom}/>
        </QuestionCSS>
    )
}

class Suspended extends Component {


    constructor(props) {
        super(props);

        this.state = {
            user: null,
            roles: null,
            initialized: false
        };
    }

    componentDidMount() {

        initialize().then(() => this.setState({initialized: true}));


        firebaseApp.auth().onAuthStateChanged(async (user) => {
            if (user) {
                try {

                    const profile = await firestore.collection('profiles').doc(user.uid).get();
                    const roles = await firestore.collection('roles').doc(user.uid).get();

                    if (!profile.exists || !roles.exists) {
                        this.setState({systemError: "noProfile"});
                    } else {
                        this.setState({user: profile.data(), roles: roles.data()}, () => {
                            i18nChange(this.state.user.language);
                        })
                    }
                } catch (error) {
                    this.setState({systemError: error.code});
                }
            }
        });


    };



    render() {

        if (!this.state.initialized) {
            return (<Timer/>);
        }

        const isAuth = this.state.user !== null;

        return (


            <div>

                {!isAuth &&
                <Timer time={3}>
                    <Redirect push to={`/`}/>
                </Timer>
                }

                {isAuth  &&
                <div>

                    <Header user={this.state.user} roles={this.state.roles}>
                    </Header>

                    <Wizard>
                        <Question>{i18n("suspended", {firstname: this.state.user.firstname})}</Question>
                        <ContentCSS>
                        </ContentCSS>
                    </Wizard>


                    <ErrorSnackbar message={this.state.systemError ? this.state.systemError.code : null}
                                   open={!isNullOrUndefined(this.state.systemError)} onClose={(event) => {
                        console.log(this.state.systemError);
                        this.setState({systemError: null})
                    }}/>

                    <Footer/>
                </div>
                }
            </div>
        );
    }
}

export default withDebug()(Suspended);
