import React, { Component } from 'react';
import styled from 'styled-components';
import share from './../assets/share.svg';

import { i18nText, i18n, i18nConfiguration } from "../common/I18n";
import asset_chat from "../assets/chat_lined.svg"
import asset_report from "../assets/report.svg"

import Badge from "@material-ui/core/Badge/Badge";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import ReportDialog from "./ReportDialog";


const Wrapper = styled.div`
    padding-left: 10.00%;
    padding-right: 33%;
    @media screen and (max-width: 1100px) {
    padding-right: 10%;

}
    user-select: none;
`;

const TitleLine = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 24px;
`;

const Title = styled.div`
    width: fit-content;
    padding-top: 24px;
    opacity: 0.8;
    font-family: TruenoBold;
    font-size: 22px;
    color: #333333;
    letter-spacing: -0.16px;
    line-height: 28px;
    text-transform: uppercase;
`;

const Guests = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 24px;

`;

const ProfileImageCSS = styled.img`
width: 112px;
height: 112px;
object-fit: cover;
border-radius: 100%;
cursor: pointer;
@media screen and (max-width: 1100px) {
width: 96px;
height: 96px;
`;

const NameCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #333333;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
text-transform: uppercase;
`;

const ToolsCSS = styled.div`
opacity: 0.8;
color: #333333;
text-align: center;
display: flex;
justify-content: space-around;
`;

const ToolCSS = styled.img`
opacity: 0.6;
color: #333333;
text-align: center;
cursor: pointer;
&:hover {
opacity: 1;
}
&:active {
opacity: 0.8;
}`;

const ProfileCSS = styled.div`
width: 112px;
height: 160px;
margin-right: 16px;
margin-bottom: 16px;
@media screen and (max-width: 1100px) {
width: 96px;
height: 120px;
margin-right: 16px;
}
position: relative;
`;

const SeatsCSS = styled.div`
opacity: 0.7;
background: #04ADA9;
color: #ffffff;
right:-8px;
position: absolute;
top:80px;
font-family: TruenoLight;
font-size: 18px;
padding: 4px;
width: 32px;
height: 32px;
line-height: 32px;
border-radius: 100%;
text-align: center;
@media screen and (max-width: 1100px) {
top:60px;
}
`;

const theme = createMuiTheme({

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#FF6344',
            dark: '#FF6344',
            // contrastText: will be calculated to contast with palette.primary.main
            contrastText: '#ffffff'
        },

    },
    typography: {
        useNextVariants: true,
    },

    overrides: {

        MuiBadge: {
            badge: {
                fontFamily: "TruenoLight",
                color: '#5C5C5C',
                width: "32px",
                height: "32px",
                lineHeight: "32px",
                fontSize: "18px",
                padding: "4px",
                opacity: 0.7,

            }
        },

    }


});

class Guest extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <MuiThemeProvider theme={theme}>

                <ProfileCSS>

                    {(this.props.badge > 0) ?
                        (
                            <Badge badgeContent={this.props.badge} color="primary">
                                <ProfileImageCSS src={this.props.image} onClick={this.props.onChat} />
                            </Badge>
                        ) :
                        (
                            <ProfileImageCSS src={this.props.image} onClick={this.props.onChat} />
                        )
                    }

                    {(this.props.seats > 0) &&
                        <SeatsCSS>{"+" + this.props.seats}</SeatsCSS>
                    }

                    {(this.props.vip) ? (<NameCSS>{this.props.name} (VIP)</NameCSS>) : (<NameCSS>{this.props.name}</NameCSS>)} 
                    
                    <ToolsCSS>
                        <ToolCSS src={asset_chat} onClick={this.props.onChat} />
                        <ToolCSS src={asset_report} onClick={this.props.onReport} />
                    </ToolsCSS>
                </ProfileCSS>
            </MuiThemeProvider>

        )
    }

}

class GuestList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openReportDialog: false,
            subject: null,
        }
    }

    handleOpenReportDialog = (subject) => {
        this.setState({ openReportDialog: true, subject: subject });
    };

    handleCloseReportDialog = (event) => {
        this.setState({ openReportDialog: false, subject: null });
    };


    render() {


        const keys = Object.keys(this.props.allMessages).filter((key) => this.props.bookings.filter(booking => booking.uid === key).length === 0);

        return (
            <Wrapper>


                <TitleLine>
                    <Title>
                        {(this.props.seats === 0) ? i18n("host/guestList/noGuests") : i18n("host/guestList/guests", { seats: this.props.seats })}
                    </Title>
                </TitleLine>

                {(this.props.bookings.length > 0) &&
                    <Guests>
                        {
                            this.props.bookings.map((booking) => {
                                return <Guest
                                    key={booking.uid}
                                    onChat={(e) => this.props.onChat({ uid: booking.uid, ...booking.guest })}
                                    onReport={(e) => this.handleOpenReportDialog(booking)}
                                    image={booking.guest.image}
                                    name={booking.guest.firstname}
                                    vip={booking.guest.vip === true}
                                    seats={booking.seats}
                                    badge={this.props.count[booking.uid] ? this.props.count[booking.uid] : 0}
                                />
                            })
                        }
                    </Guests>
                }

                {(keys.length > 0) &&
                    <TitleLine>
                        <Title>
                            {i18n("host/guestList/chats")}
                        </Title>
                    </TitleLine>
                }

                {(keys.length > 0) &&
                    <Guests>
                        {
                            keys.map((key) => {
                                return <Guest onChat={(e) => this.props.onChat({
                                    uid: key,
                                    image: this.props.allMessages[key][0].guest.image,
                                    firstname: this.props.allMessages[key][0].guest.name
                                })}
                                    key={key}
                                    onReport={(e) => this.handleOpenReportDialog({ uid: key, ...this.props.allMessages[key][0].guest })}
                                    image={this.props.allMessages[key][0].guest.image}
                                    name={this.props.allMessages[key][0].guest.name}
                                    seats={0}
                                    badge={this.props.count[key] ? this.props.count[key] : 0}
                                />
                            })
                        }
                    </Guests>
                }

                <ReportDialog
                    open={this.state.openReportDialog}
                    onClose={this.handleCloseReportDialog.bind(this)}
                    subject={this.state.subject}
                    kind={"guest"}
                />


            </Wrapper>


        );
    }
}

export default GuestList;
