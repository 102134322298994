/* eslint-disable jsx-a11y/mouse-events-have-key-events */

import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing.unit,
    },
});

class MouseOverPopover extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;

        return (
                <Popover

                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}

                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.paper,
                    }}
                    open={this.props.open}
                    anchorEl={this.props.anchorEl}

                    onClose={this.onClose}
                    disableRestoreFocus
                >
                    <div>
                        {this.props.children}
                    </div>
                </Popover>
        );
    }
}

MouseOverPopover.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MouseOverPopover);