import React, {Component} from 'react';
import 'moment/locale/de';

class Timer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            seconds: 0,
            time: this.props.time ? this.props.time * 1000 : 10 * 1000,
            step: 0
        };

        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.resetTimer = this.resetTimer.bind(this);
        this.onSecondsChanged = this.onSecondsChanged.bind(this);

    }

    componentDidMount() {
        this.startTimer();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    onSecondsChanged(seconds) {
        seconds = parseInt(seconds);

        if (seconds && typeof seconds === 'number') {
            if (seconds <= 359999) {
                this.setState(() => ({seconds: seconds, time: seconds * 1000}));
            }
        } else {
            this.setState(() => ({seconds: 0, time: 0}));
        }
    }

    startTimer() {

        if (this.state.status !== 'started') {
            this.interval = setInterval(() => {
                if (this.state.time !== 0) {
                    this.setState(prevState => ({time: prevState.time - 200, step: prevState.step + 1}));
                } else {
                    this.setState(() => ({seconds: 0, status: null, time: 0}));
                    clearInterval(this.interval);
                }
            }, 200);

            this.setState(() => ({status: 'started'}));
        }
    }

    stopTimer() {
        if (this.state.status && this.state.status === 'started') {

            clearInterval(this.interval);

            this.setState((prevState) => {
                return ({
                    status: 'stopped',
                    seconds: Math.floor(prevState.time / 1000)
                });
            });
        }
    }

    resetTimer() {
        clearInterval(this.interval);
        this.setState(() => ({seconds: 0, status: null, time: 0}));
    }

    render() {

        const frames = [
            "◐", "◓", "◑", "◒"
        ];


        return (

            <div>
                {(this.state.time > 0) ?
                    (
                        <div style={{
                            display: "block", margin: "auto", position: "absolute",
                            top: "50%",
                            left: "50%",
                            color: this.props.color ? this.props.color : "var(--green)"
                        }}>{frames[this.state.step % frames.length]}</div>
                    ) : (
                        <div>
                            {this.props.children}
                        </div>
                    )
                }
            </div>

        )

    }
}

export default Timer;
