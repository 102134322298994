import React, {Component} from 'react';
import styled from 'styled-components';


import {i18n, i18nChange} from "../common/I18n";
import {Flex, Grid} from "../common/Layout";

import culinarioo from './../assets/culinarioo.svg';

import firebaseApp from "../common/firebase";
import Carousel from "./Carousel";
import {uuidv4} from "../common/utils";
import * as assetsService from "../common/assets";

const firestore = firebaseApp.firestore();





const Wrapper = styled.div`
margin-left: ${props => props.margin ? props.margin : "10%"};
margin-top: 56px;
margin-right: ${props => props.margin ? props.margin : "10%"};
`;

const Text = styled.div`
opacity: 0.8;
font-family: TruenoLight;
font-size: 24px;
color: #818181;
letter-spacing: 0;
width: 100%;
position: absolute;
bottom:20px;
text-align: center;
height: 20px;
justify-self: center;
align-self: center;
text-align: center;
`;

const Image = styled.div`
background-image: url(${props => props.src});
background-size: cover;
background-repeat: no-repeat;
border-radius: 8px 8px 0 0;
width: 100%;
position: absolute;
left: 0px;
top: 0px;
height: 120px;
`;

const Block = styled.div`
justify-self: center;
align-self: center;
width:100%;
height: 180px;
border-radius: 8px;
border-color: #EEEEEE;
border-style: solid;
border-width: 1px;
position: relative;
&:hover {
box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
cursor: pointer;
}
&:active {
box-shadow: none;
cursor: pointer;
}
&:hover ${Text} {
font-family: TruenoRegular;
}
`;

const Title = styled.div`
opacity: 0.8;
font-family: TruenoBold;
font-size: 34px;
color: #333333;
letter-spacing: -0.49px;
line-height: 42px;
text-transform: uppercase;
@media screen and (max-width: 400px) {
font-size: 24px;
}

`;

const Culinarioo = styled.img`
float: left;
padding-left: 16px;
height: 30px;
margin-top: 6px;
`;

const Link = styled.a`
text-decoration: none;
`;


class Categories extends Component {

    constructor(props) {
        super(props);

        this.state = {
            casualFood: false,
            takeAway: false,
            charityFood: false,
            now: false,
            eventFood: false,
            assetURLs: {},
            categories: []
        }
    }

    componentDidMount() {
        this.fetchAssets()
            .then(async () => {
                await this.setState({
                    categories: [
                        {
                            src: this.state.assetURLs['category/casualFood/index.svg'],
                            text: i18n('categories/casualFood'),
                            href: 'casualFood',
                        },
                        {
                            src: this.state.assetURLs['category/takeAway/index.svg'],
                            text: i18n('categories/takeAway'),
                            href: 'takeAway',
                        },
                        {
                            src: this.state.assetURLs['category/charityFood/index.svg'],
                            text: i18n('categories/charityFood'),
                            href: 'charityFood',
                        },
                        {
                            src: this.state.assetURLs['category/now/index.svg'],
                            text: i18n('categories/now'),
                            href: 'now',
                        },
                        {
                            src: this.state.assetURLs['category/eventFood/index.svg'],
                            text: i18n('categories/eventFood'),
                            href: 'eventFood',
                        }
                    ]
                });
                this.load();
            })
    }

    fetchAssets = async () => {
        const fullPathAssets = [
            'category/casualFood/index.svg',
            'category/takeAway/index.svg',
            'category/charityFood/index.svg',
            'category/now/index.svg',
            'category/eventFood/index.svg',
        ];
        const assetURLs = await assetsService.fetchAssets(fullPathAssets);
        await this.setState({
            assetURLs: assetURLs,
        });
    };

    load = async () => {

        let status = {};


        for (const category of this.state.categories) {
            const fsCounter = await firestore.collection("counter").doc(category.href).get();

            if (fsCounter.exists) {
                status[category.href] = fsCounter.data().count > 0;
            } else {
                status[category.href] = false;
            }
        }

        this.setState({casualFood: status.casualFood, takeAway: status.takeAway, charityFood: status.charityFood, now: status.now, eventFood: status.eventFood})

    };

    render() {

        let display = false;
        for (const category of this.state.categories) {
            display = display || this.state[category.href];
        }


        return (
            <Wrapper margin={this.props.margin} style={{display: display ? "block" : "none"}}>
                <Flex>
                    <Title>{i18n('categories/title')}</Title><Culinarioo src={culinarioo}/>
                </Flex>

                <Carousel autoplay={true} infinite={false} slidesToShow={4} slidesToShowL={3} slidesToShowM={2}
                          slidesToShowS={1}>
                    {this.state.categories.filter(category => category.href !== this.props.except && this.state[category.href]).map(category => {
                        return (
                            <Link key={uuidv4()} href={`/category/${category.href}`}>
                                <Block>
                                    <Image src={category.src}/>
                                    <Text>
                                        {category.text}
                                    </Text>
                                </Block>
                            </Link>
                        )
                    })}


                </Carousel>
            </Wrapper>

        )
            ;
    }
}

export default Categories;
