import React, {Component} from 'react';
import P23TextField from './P23TextField';
import StyledButton from './StyledButton';
import StyledFlatButton from './StyledFlatButton';
import {i18n} from "../common/I18n";

import styled from 'styled-components';
import asset_close from '../assets/close.svg';

import * as firebase from "firebase";
import StyledHintButton from "./StyledHintButton";

const Form = styled.div`
position: relative;
margin-left: auto;
margin-right: auto;
background-color: rgba(255,255,255,0.9);
border-radius: 20px;
width: 100%;
max-width: 300px;
padding-top: 10px;
padding-bottom: 10px;
margin-bottom: 24px;
`;

const MessageCSS = styled.div`
opacity: 0.8;
width: 80%;
display: block;
margin-left: auto;
margin-right: auto;
font-family: TruenoLight;
font-size: 18px;
color: #04ADA9;
letter-spacing: 1.5px;
line-height: 28px;
margin-top: 16px;
text-align: center;
`;

const HintCSS = styled.div`
opacity: 0.8;
width: 80%;
display: block;
padding-top: 24px;
margin-left: auto;
margin-right: auto;
font-family: TruenoLight;
font-size: 14px;
color: #a9a9a9;
letter-spacing: 1.5px;
line-height: 28px;
text-align: center;
text-transform: uppercase;
`;

const CloseCSS = styled.div`
opacity: 0.8;
display: flex;
justify-content: center;
align-items:center;
min-width: 42px;
min-height: 42px;
background-color: var(--green);
user-select: none;
border-radius: 100%;
position: absolute;
right:-15px;
top:-15px;
&:hover {
cursor: pointer;
opacity: 1;
}
&:active {
opacity: 0.8;
cursor: pointer;
}
`;


class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showResendPassword: false,
            error: null,
            passwordResent: false,
            working: false,
            email:"",
        }
    }

    toggleResendPassword() {
        this.setState({showResendPassword: (!this.state.showResendPassword), email: "", error: null});
    };

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    };

    handleClick(name) {

        this.setState({working: true}, () => {

            var actionCodeSettings = {
                url: `https://${window.location.hostname}`,
            };

            firebase.auth().sendPasswordResetEmail(this.state.email, actionCodeSettings).then(() => {
                this.setState({error: null, passwordResent: true, working: false});
            }).catch((error) => {
                this.setState({error: i18n("error/" + error.code), working: false, passwordResent: true})
            });

        });
    }

    render() {
        return (

            <div style={{width: "100%", position:"relative"}}>

                {!this.state.showResendPassword ?
                    (
                        <Form>

                            {this.props.onClose &&
                            <CloseCSS onClick={this.props.onClose}>
                                <img src={asset_close} width={16} height={16}/>
                            </CloseCSS>
                            }

                            <P23TextField name="username"
                                          label={i18n("loginForm/username")}
                                          value={this.props.username}
                                          onChange={this.props.onChange}
                                          error={this.props.error}/>
                            <P23TextField name="password"
                                          label={i18n("loginForm/password")}
                                          value={this.props.password}
                                          type={"password"}
                                          onChange={this.props.onChange}
                                          error={this.props.error}/>

                            <div style={{
                                width: "80%",
                                marginBottom: "16px",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto"
                            }}>
                                <StyledHintButton name={"forgotPassword"}
                                                  onClick={this.toggleResendPassword.bind(this)}
                                                  label={i18n("loginForm/forgotPassword")}/>
                            </div>

                            <div style={{
                                width: "80%",
                                marginTop: "16px",
                                marginBottom: "16px",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto"
                            }}>
                                <StyledButton
                                    onClick={this.props.onLogin}
                                    label={i18n("loginForm/login")}
                                    working={this.props.working}
                                    name={"login"}
                                    disabled={
                                        this.props.password === null || this.props.password.trim().length === 0 ||
                                        this.props.username === null || this.props.username.trim().length === 0
                                    }
                                />
                            </div>

                            {this.props.enableRegister &&
                            <div style={{
                                width: "80%",
                                marginBottom: "16px",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto"
                            }}>

                                <HintCSS>{i18n("loginForm/noAccountYet")}</HintCSS>
                                <StyledHintButton name={"register"}
                                                  onClick={this.props.onRegister}
                                                  label={i18n("loginForm/register")}
                                                  align={"center"}/>
                            </div>


                            }


                        </Form>

                    ) : (
                        <Form>

                            {this.props.onClose &&
                            <CloseCSS onClick={this.props.onClose}>
                                <img src={asset_close} width={16} height={16}/>
                            </CloseCSS>
                            }

                            <P23TextField
                                name="email"
                                label={i18n("loginForm/email")}
                                value={this.state.email}
                                onChange={this.handleChange.bind(this)}
                                error={this.state.error}/>

                            {(this.state.error === null && this.state.passwordResent) &&
                            <MessageCSS>
                                {i18n("loginForm/resend/description")}
                            </MessageCSS>
                            }

                            <div style={{
                                width: "80%",
                                marginTop: "40px",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto"
                            }}>
                                <StyledButton
                                    onClick={this.handleClick.bind(this)}
                                    label={(!this.state.passwordResent) ? i18n("loginForm/resend") : i18n("loginForm/resendAgain")}
                                    working={this.state.working}
                                    name={"resend"}
                                    disabled={
                                        this.state.email === null || this.state.email.trim().length === 0
                                    }
                                />
                            </div>

                            <div style={{
                                width: "80%",
                                marginBottom: "16px",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto"
                            }}>
                                <StyledFlatButton name={"cancel"}
                                                  onClick={this.toggleResendPassword.bind(this)}
                                                  label={i18n("loginForm/cancel")}/>
                            </div>
                        </Form>
                    )}
            </div>
        )
    }
}

export default LoginForm;
