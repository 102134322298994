import React, {Component} from 'react';

import {i18nText, i18n, i18nLocale} from "../common/I18n";
import Slider from "react-slick";
import Moment from 'react-moment';


import dot from "./../assets/dot.svg";
import quote_grey from "./../assets/quote_grey.svg";

import styled from 'styled-components';
import 'moment/locale/de';
import Carousel from "./Carousel";


const Wrapper = styled.div`
    padding-left: 10.00%;
    padding-right: 33%;
    margin-bottom: 16px;
    @media screen and (max-width: 1100px) {
    padding-right: 10%;

}
    user-select: none;
`;

const Title = styled.div`
    opacity: 0.8;
    font-family: TruenoRegular;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    text-transform: uppercase;
    margin-top: 24px;
`;

const Quote = styled.div`
    width: 90%;
    padding-top: 48px;
    padding-left: 25px;
    padding-right: 25px;
    opacity: 0.8;
    font-family: TruenoLight;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    text-align: center;
    line-height: 31px;
    min-height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
`;

const Profile = styled.img`
    border-radius: 100%;
    width: 68px;
    height: 68px;
    margin-top: 16px;
    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
`;

const Icon = styled.img`
display: block;
    margin-top-top: 24px;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.8;

`;

const Name = styled.div`
    opacity: 0.8;
    font-family: TruenoRegular;
    font-size: 14px;
    color: #333333;
    letter-spacing: 1.5px;
    text-align: center;
    line-height: 22px;
    text-transform: uppercase;
    padding-top: 16px;
        display: block;
    margin-left: auto;
    margin-right: auto;

`;

const Time = styled.div`
    opacity: 0.8;
    font-family: TruenoLight;
    font-size: 16px;
    color: #E2E2E2;
    letter-spacing: 0;
    text-align: center;
        display: block;
    margin-left: auto;
    margin-right: auto;
`;


class Testimonial extends Component {

    constructor(props) {
        super(props);


    }

    render() {

        return (
            <Wrapper>


                <Title>{i18n('testimonial/title')}</Title>

                <Carousel slidesToShow={1} slidesToShowL={1} slidesToShowM={1}
                          slidesToShowS={1}
                          width={"100%"} autoplay={true} infinite={true}>

                    {this.props.testimonials.map(testimonial => {
                        return (
                            <div style={{marginTop:"16px",marginBottom:"16px"}}>

                                <Icon src={quote_grey} alt=""/>
                                <Quote>{testimonial.text}</Quote>
                                <Profile src={testimonial.who.image} alt=""/>
                                <Name>{testimonial.who.firstname}</Name>
                                <Time><Moment fromNow
                                              locale={i18nLocale()}>{testimonial.created.toDate()}</Moment></Time>

                            </div>)
                    })}


                </Carousel>


            </Wrapper>

        );
    }
}

export default Testimonial;
