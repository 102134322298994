import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';
import MUIDataTable from "mui-datatables";
import firebaseApp from "../common/firebase";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";
import {isNullOrUndefined} from "util";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import PauseIcon from "@material-ui/icons/Pause";
import StarIcon from "@material-ui/icons/Star";
import EmptyStarIcon from "@material-ui/icons/StarBorder";
import PlayButton from "@material-ui/icons/PlayArrow";
import DeleteButton from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button/Button";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import RefreshIcon from "@material-ui/icons/Refresh";

const firestore = firebaseApp.firestore();


const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
});

class Admins extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            values: [],
            displayName: "",
            email: "",
            password: "",
            super: false,
            showDialog: false,


        };
    }


    UNSAFE_componentWillMount() {
        this.reload();

    }

    reload = () => {
        try {
            this.setState({loading: true}, async () => {

                const token = await firebaseApp.auth().currentUser.getIdToken();

                const response = await fetch(`${process.env.REACT_APP_ADMIN_API_URI}/admins`, {
                    method: 'get',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                });

                const values = await response.json();

                this.setState({values: values, loading: false});

            })
        } catch (error) {
            console.error(error);
            this.setState({loading: false});
        }
    };

    handlePromote = (selectedRows) => {


        this.setState({working: true}, async () => {
            try {


                for (const selectedRow of selectedRows.data) {

                }


                this.setState({working: false}, () => {
                    this.reload();
                });
            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });

    };

    handleDelete = (selectedRows) => {


        this.setState({working: true}, async () => {
            try {


                for (const selectedRow of selectedRows.data) {

                }


                this.setState({working: false}, () => {
                    this.reload();
                });
            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });

    };

    handleDemote = (selectedRows) => {
        this.setState({working: true}, async () => {
            try {

                for (const selectedRow of selectedRows.data) {

                }

                this.setState({working: false}, () => {
                    this.reload();
                });
            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });
    };

    handleBlock = (selectedRows) => {
        this.setState({working: true}, async () => {
            try {

                for (const selectedRow of selectedRows.data) {

                }


                this.setState({working: false}, () => {
                    this.reload();
                });
            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });
    };

    handleUnblock = (selectedRows) => {
        this.setState({working: true}, async () => {
            try {

                for (const selectedRow of selectedRows.data) {

                }


                this.setState({working: false}, () => {
                    this.reload();
                });
            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });
    };

    openDialog = (mode) => {


        this.setState({
            showDialog: true,
            displayName: "",
            email: "",
            password: "",
            super: false,
            value: null
        })
    };

    closeDialog = () => {
        this.setState({
            showDialog: false,
            displayName: "",
            email: "",
            password: "",
            super: false,
            value: null
        })
    };

    handleChange = event => {

        let value = event.target.value;
        this.setState({[event.target.name]: value});
    };

    handleChangedSwitch = name => event => {
        this.setState({[name]: event.target.checked});
    };

    handleSave = () => {
        this.setState({working: true}, async () => {

            ///

            this.setState({
                working: false,
                showDialog: false,
                displayName: "",
                email: "",
                password: "",
                super: false,
                value: null
            }, () => {
                this.reload();
            })
        })
    };


    render() {
        const {classes} = this.props;

        const columns = [
            {

                name: "Disabled",
                field: "disabled",
                options: {
                    filter: true,
                    sort: true,
                    display: "false",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {(value === true) ? (
                                    <Tooltip title={"Admin is disabled"}>
                                        <PauseIcon/>
                                    </Tooltip>) : (
                                    <Tooltip title={"Admin is active"}>
                                        <PlayButton/>
                                    </Tooltip>)}
                            </React.Fragment>

                        );
                    },

                }
            },
            {

                name: "Created",
                field: "created",
                options: {
                    filter: false,
                    sort: false,
                    display: "false"
                }
            },
            {

                name: "E-Mail",
                field: "email",
                options: {
                    filter: false,
                    sort: true,

                }
            },
            {

                name: "Display Name",
                field: "displayName",
                options: {
                    filter: false,
                    sort: true,
                    display: "false"
                }
            },
            {

                name: "Super Admin",
                field: "super",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {(value === true) ? (
                                    <Tooltip title={"Admin is super Admin"}>
                                        <StarIcon/>
                                    </Tooltip>) : (
                                    <Tooltip title={"User is no super Admin"}>
                                        <EmptyStarIcon/>
                                    </Tooltip>)}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Uid",
                field: "uid",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                }
            },
            {

                name: "Last Login",
                field: "lastSignInTime",
                options: {
                    filter: false,
                    sort: false,
                    display: "false"
                }
            },
        ];


        const options = {
            filter: true,
            filterType: 'dropdown',
            selectableRows: 'multiple',
            responsive: 'scrollFullHeight',
            customToolbar: () =>
                <Tooltip title={"Reload Data"}>
                    <IconButton className={classes.iconButton}
                                onClick={this.reload}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>,
            customToolbarSelect: (selectedRows) =>
                <StyledCustomToolbarSelect
                    selectedRows={selectedRows}
                    onBlock={this.handleBlock}
                    onDelete={this.handleDelete}
                    onUnblock={this.handleUnblock}
                    onPromote={this.handlePromote}
                    onDemote={this.handleDemote}/>
        };

        const data = this.state.values.map(value => {

            const line = [];

            for (const column of columns) {

                let field = value[column.field];


                if (column.field === "disabled") {
                    field = value.record.disabled === true;
                }

                if (column.field === "super") {
                    field = value.data.super === true;
                }

                if (column.field === "uid") {
                    field = value.uid;
                }

                if (column.field === "email") {
                    field = value.record.email;
                }

                if (column.field === "lastSignInTime") {
                    field = value.record.lastSignInTime;
                }

                if (column.field === "created") {
                    field = value.record.creationTime;
                }

                if (column.field === "displayName") {
                    field = value.record.displayName;
                }


                if (isNullOrUndefined(field)) {
                    field = "-";
                }

                line.push(field);
            }

            return line;
        });

        const isValidDisplayName = !isNullOrUndefined(this.state.displayName) && this.state.displayName.trim().length > 0;
        const isValidEMail = !isNullOrUndefined(this.state.email) && this.state.email.trim().length > 0;
        const isValidPassword = !isNullOrUndefined(this.state.password) && this.state.password.trim().length > 0;

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page}) => (
                        <React.Fragment>

                            {this.state.loading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress className={classes.progress} size={50}/>
                                    </Grid>
                                ) :
                                (
                                    <React.Fragment>
                                        <MUIDataTable
                                            columns={columns}
                                            data={data}
                                            title={page.title}
                                            options={options}
                                        />
                                        <Button variant="fab" className={classes.fab} color={"secondary"}
                                                onClick={(e) => this.openDialog(false)}>
                                            <AddIcon/>
                                        </Button>
                                    </React.Fragment>


                                )}

                            <Dialog
                                open={this.state.showDialog}
                                aria-labelledby="form-dialog-title"
                            >
                                <React.Fragment>
                                    <DialogTitle id="form-dialog-title">Add user to {page.title}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            add a new user.
                                        </DialogContentText>
                                        <TextField
                                            value={this.state.displayName}
                                            margin="dense"
                                            id="displayName"
                                            name="displayName"
                                            label="Display Name"
                                            type="text"
                                            onChange={this.handleChange}
                                            fullWidth
                                            disabled={this.state.working}
                                        />

                                        <TextField
                                            value={this.state.email}
                                            margin="dense"
                                            id="email"
                                            name="email"
                                            label="EMail"
                                            type="text"
                                            onChange={this.handleChange}
                                            fullWidth
                                            disabled={this.state.working}
                                        />

                                        <TextField
                                            value={this.state.password}
                                            margin="dense"
                                            id="password"
                                            name="password"
                                            label="Password"
                                            type="password"
                                            onChange={this.handleChange}
                                            fullWidth
                                            disabled={this.state.working}
                                        />

                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.super}
                                                    onChange={this.handleChangedSwitch('super')}
                                                    value="super"
                                                />
                                            }
                                            label="is super admin"
                                        />


                                    </DialogContent>

                                    <DialogActions>
                                        <Button onClick={this.closeDialog} color="primary"
                                                disabled={this.state.working}>
                                            Close
                                        </Button>
                                        <Button onClick={this.handleSave} color="primary" autoFocus
                                                disabled={this.state.working || !isValidDisplayName || !isValidEMail || !isValidPassword}>
                                            Save
                                        </Button>
                                    </DialogActions>
                                </React.Fragment>
                            </Dialog>
                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

Admins.propTypes = {
    classes: PropTypes.object.isRequired,
};

const defaultToolbarSelectStyles = {
    iconButton: {
        marginRight: "24px",
        top: "50%",
        display: "inline-block",
        position: "relative",
        transform: "translateY(-50%)",
    },
};

class CustomToolbarSelect extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <div className={"custom-toolbar-select"}>

                <Tooltip title={"Promote to super admin"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onPromote(this.props.selectedRows)}>
                        <StarIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Demote from super admin"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onDemote(this.props.selectedRows)}>
                        <EmptyStarIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Disable user"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onBlock(this.props.selectedRows)}>
                        <PauseIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Enable user"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onUnblock(this.props.selectedRows)}>
                        <PlayButton/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"delete user"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onDelete(this.props.selectedRows)}>
                        <DeleteButton/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

}

const StyledCustomToolbarSelect = withStyles(defaultToolbarSelectStyles, {name: "CustomToolbarSelect"})(CustomToolbarSelect);


export default withStyles(styles, {withTheme: true})(Admins)