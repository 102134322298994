import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {AdminContextConsumer} from './Admin';
import MUIDataTable from "mui-datatables";
import firebaseApp from "../common/firebase";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";
import moment from "moment";
import {isNullOrUndefined} from "util";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button/Button";
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import CheckedIcon from "@material-ui/icons/CheckBox";
import UncheckedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import RefreshIcon from "@material-ui/icons/Refresh";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import MomentUtils from "@date-io/moment";

const firestore = firebaseApp.firestore();


const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
});

class Vouchers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            working: false,
            values: [],
            showDialog: false,
            editMode: false,
            amount: null,
            minAmount: null,
            validFrom: null,
            validTo: null,
            maxRedeems: null,
            constraint: "none",
            types: [],
            type: "percentage",
            code: "",
        };
    }


    componentDidMount() {
        this.reload();
    }

    reload = () => {
        this.setState({loading: true}, async () => {
            try {

                let fsValues = await firestore.collection("vouchers").get();

                const fsTypes = await firestore.collection("masterdata").doc("types").get();
                const types = fsTypes.data().values;

                let values = [];

                for (const fsValue of fsValues.docs) {

                    const fsRedeems = await fsValue.ref.collection("redeem").get();

                    let counter = 0;
                    for (const fsRedeem of fsRedeems.docs) {
                        counter++;
                    }

                    const {created, updated, validFrom, validTo, ...other} = fsValue.data();
                    values.push({
                        created: created.toDate(),
                        updated: updated.toDate(),
                        validFrom: validFrom.toDate(),
                        validTo: validTo.toDate(),
                        currentRedeems: counter, ...other,
                        ref: fsValue.ref
                    });
                }

                this.setState({values: values, loading: false, types: types});
            } catch (error) {
                console.error(error);
                this.setState({loading: false});
            }

        })

    };

    handleDelete = (selectedRows) => {

        this.setState({working: true}, async () => {
            try {

                const batch = firestore.batch();

                for (const selectedRow of selectedRows.data) {

                    batch.delete(this.state.values[selectedRow.dataIndex].ref);
                }

                await batch.commit();

                this.setState({working: false}, () => {
                    this.reload();
                });

            } catch (error) {
                console.error(error);
                this.setState({working: false});
            }

        });

    };

    handleChange = event => {

        let value = event.target.value;
        this.setState({[event.target.name]: value});
    };

    handleValidFromChange = date => {

        this.setState({validFrom: date.toDate()});
    };

    handleValidToChange = date => {

        this.setState({validTo: date.toDate()});
    };

    openDialog = (mode) => {

        if (mode) {
            this.setState({
                showDialog: true,
                editMode: true,
                type: this.state.value.type,
                amount: this.state.value.amount,
                minAmount: this.state.value.minAmount,
                code: this.state.value.code,
                validFrom: this.state.value.validFrom,
                validTo: this.state.value.validTo,
                maxRedeems: this.state.value.maxRedeems,
                constraint: !isNullOrUndefined(this.state.value.constraint) ? this.state.value.constraint : "none",
                working: false,
            })
        } else {
            this.setState({
                showDialog: true,
                editMode: false,
                type: "percentage",
                code: "",
                amount: null,
                minAmount: null,
                validFrom: null,
                validTo: null,
                maxRedeems: null,
                constraint: "none",
                working: false,

            })
        }
    };

    closeDialog = () => {
        this.setState({
            showDialog: false,
            editMode: false,
            type: "percentage",
            code: "",
            amount: "",
            minAmount: "",
            validFrom: "",
            validTo: "",
            maxRedeems: "",
            constraint: "none",
            working: false,

        })
    };

    handleSave = () => {
        this.setState({working: true}, async () => {

            const amount = parseFloat(this.state.amount);
            const minAmount = parseFloat(this.state.minAmount);
            const maxRedeems = parseInt(this.state.maxRedeems);


            if (this.state.value) {
                await this.state.value.ref.set({
                    type: this.state.type,
                    amount: amount,
                    minAmount: minAmount,
                    code: this.state.code,
                    validFrom: this.state.validFrom,
                    validTo: this.state.validTo,
                    maxRedeems: maxRedeems,
                    constraint: !isNullOrUndefined(this.state.constraint) && this.state.constraint !== "none" ? this.state.constraint : null,
                    updated: new Date(),
                }, {merge: true});

            } else {

                await firestore.collection("vouchers").doc(this.state.code).set({
                    type: this.state.type,
                    amount: amount,
                    minAmount: minAmount,
                    code: this.state.code,
                    validFrom: this.state.validFrom,
                    validTo: this.state.validTo,
                    maxRedeems: maxRedeems,
                    constraint: !isNullOrUndefined(this.state.constraint) && this.state.constraint !== "none" ? this.state.constraint : null,
                    created: new Date(),
                    updated: new Date(),
                });
            }

            this.setState({
                showDialog: false,
                type: "percentage",
                code: "",
                amount: null,
                minAmount: null,
                editMode: false,
                validFrom: null,
                validTo: null,
                maxRedeems: null,
                constraint: "none",
                working: false,

            }, () => {
                this.reload();
            })
        })
    };

    render() {
        const {classes} = this.props;

        const columns = [
            {

                name: "Action",
                field: "action",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Tooltip title={"Edit entry"}>
                                <IconButton onClick={(e) => this.setState({value: value}, () => this.openDialog(true))}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>

                        );
                    },
                },

            },
            {

                name: "Active",
                field: "active",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {(value === true) ? (<CheckedIcon/>) : (<UncheckedIcon/>)}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Valid From",
                field: "validFrom",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {moment(value).format("YYYY-MM-DD")}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Valid To",
                field: "validTo",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {moment(value).format("YYYY-MM-DD")}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Type",
                field: "type",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {

                name: "Code",
                field: "code",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {

                name: "Amount",
                field: "amount",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {

                name: "Minimal Amount",
                field: "minAmount",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {

                name: "Current Redeems",
                field: "currentRedeems",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {

                name: "Max Redeems",
                field: "maxRedeems",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {

                name: "Constraint",
                field: "constraint",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {

                name: "Created",
                field: "created",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {moment(value).format("YYYY-MM-DD, HH:mm:ss")}
                            </React.Fragment>

                        );
                    },
                }
            },
            {

                name: "Updated",
                field: "updated",
                options: {
                    filter: false,
                    sort: true,
                    display: "false",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {moment(value).format("YYYY-MM-DD, HH:mm:ss")}
                            </React.Fragment>

                        );
                    },
                }
            },
        ];


        const options = {
            filter: true,
            selectableRows: 'multiple',
            responsive: 'scrollFullHeight',
            customToolbar: () =>
                <Tooltip title={"Reload Data"}>
                    <IconButton className={classes.iconButton}
                                onClick={this.reload}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>,
            customToolbarSelect: (selectedRows) =>
                <StyledCustomToolbarSelect
                    selectedRows={selectedRows}
                    onDelete={this.handleDelete}/>

        };

        const data = this.state.values.map(value => {

            const line = [];

            for (const column of columns) {
                let field = value[column.field];

                if (column.field === "action") {
                    field = value;
                }

                if (column.field === "active") {

                    const validFrom = moment(value["validFrom"]);
                    const validTo = moment(value["validTo"]);
                    const now = moment();
                    const currentRedeems = value["currentRedeems"];
                    const maxRedeems = value["maxRedeems"];

                    field = now.isAfter(validFrom) && now.isBefore(validTo) && (currentRedeems < maxRedeems);
                }

                if (isNullOrUndefined(field)) {
                    field = "-"
                }
                line.push(field);
            }

            return line;
        });

        const isValidCode = this.state.code.trim().length > 0;

        const isValidPercentage = !isNaN(this.state.amount) && parseFloat(this.state.amount) >= 0 && parseFloat(this.state.amount) <= 1;
        const isValidAmount = !isNaN(this.state.amount) && parseFloat(this.state.amount) >= 0;
        const isValidMinAmount = !isNaN(this.state.minAmount) && parseFloat(this.state.minAmount) >= 0;
        const isValidMaxRedeems = !isNaN(this.state.maxRedeems) && parseFloat(this.state.maxRedeems) >= 1;
        const isPercentage = this.state.type === "percentage";

        const isValidDate = !isNullOrUndefined(this.state.validFrom) && !isNullOrUndefined(this.state.validTo) && moment(this.state.validFrom).isBefore(moment(this.state.validTo));

        const codeAlreadyExists = this.state.values.filter(value => value.code === this.state.code).length > 0;

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({page}) => (
                        <React.Fragment>

                            {this.state.loading ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <CircularProgress className={classes.progress} size={50}/>
                                    </Grid>
                                ) :
                                (
                                    <React.Fragment>

                                        <MUIDataTable
                                            columns={columns}
                                            data={data}
                                            title={page.title}
                                            options={options}
                                        />

                                        <Button variant="fab" className={classes.fab} color={"secondary"}
                                                onClick={(e) => this.openDialog(false)}>
                                            <AddIcon/>
                                        </Button>

                                    </React.Fragment>

                                )}


                            <MuiPickersUtilsProvider utils={MomentUtils}>

                                <Dialog
                                    open={this.state.showDialog}
                                    aria-labelledby="form-dialog-title"
                                >

                                    {this.state.editMode ? (
                                        <React.Fragment>

                                            <DialogTitle
                                                id="form-dialog-title">Edit {this.state.title} of {page.title}</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    You can't edit the code
                                                </DialogContentText>


                                                <FormControl className={classes.formControl}>
                                                    <InputLabel htmlFor="type">Type</InputLabel>
                                                    <Select
                                                        value={this.state.type}
                                                        onChange={this.handleChange}
                                                        inputProps={{
                                                            name: 'type',
                                                            id: 'type',
                                                        }}
                                                    >
                                                        <MenuItem value={"percentage"}>Percentage</MenuItem>
                                                        <MenuItem value={"amount"}>Amount</MenuItem>
                                                    </Select>
                                                </FormControl>

                                                <TextField
                                                    value={this.state.amount}
                                                    margin="dense"
                                                    id="amount"
                                                    name="amount"
                                                    label="amount"
                                                    type="number"
                                                    error={isPercentage ? !isValidPercentage : !isValidAmount}
                                                    helperText={isPercentage ? (!isValidPercentage ? "Valid percentages are between 0 and 1, e.g. 0.01 for 1% " : "") : (!isValidAmount ? "Valid amount are between 0 and 1, e.g. 0.01 for 1% " : "")}
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                    disabled={this.state.working}
                                                />

                                                <TextField
                                                    value={this.state.minAmount}
                                                    margin="dense"
                                                    id="minAmount"
                                                    name="minAmount"
                                                    label="Minimal Amount"
                                                    type="number"
                                                    error={!isValidMinAmount}
                                                    helperText={!isValidAmount ? "Valid min amounts are between 0 and 1, e.g. 0.01 for 1% " : ""}
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                    disabled={this.state.working}
                                                />

                                                <DatePicker
                                                    label="Valid From"
                                                    value={this.state.validFrom}
                                                    onChange={this.handleValidFromChange}
                                                    animateYearScrolling
                                                    error={!isValidDate}
                                                    helperText={!isValidDate ? "Select a valid date" : ""}
                                                    fullWidth
                                                    format="YYYY-MM-DD"
                                                    disabled={this.state.working}

                                                />

                                                <DatePicker
                                                    label="Valid To"
                                                    value={this.state.validTo}
                                                    onChange={this.handleValidToChange}
                                                    animateYearScrolling
                                                    error={!isValidDate}
                                                    helperText={!isValidDate ? "Select a valid date" : ""}
                                                    fullWidth
                                                    format="YYYY-MM-DD"
                                                    disabled={this.state.working}
                                                />

                                                <TextField
                                                    value={this.state.maxRedeems}
                                                    margin="dense"
                                                    id="maxRedeems"
                                                    name="maxRedeems"
                                                    label="Max Redeems"
                                                    type="number"
                                                    error={!isValidMaxRedeems}
                                                    helperText={!isValidMaxRedeems ? "Valid max redeems amounts are > 1 " : ""}
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                    disabled={this.state.working}
                                                />

                                                <FormControl className={classes.formControl}>
                                                    <InputLabel>Constraint</InputLabel>
                                                    <Select
                                                        value={this.state.constraint}
                                                        onChange={this.handleChange}
                                                        inputProps={{
                                                            name: 'constraint',
                                                            id: 'constraint',
                                                            fullWidth: true
                                                        }}
                                                        displayEmpty={true}
                                                    >
                                                        <MenuItem value="none">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {
                                                            this.state.types.map(type => (
                                                                <MenuItem value={type}>{type}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>


                                            </DialogContent>

                                            <DialogActions>
                                                <Button onClick={this.closeDialog} color="primary"
                                                        disabled={this.state.working}>
                                                    Close
                                                </Button>
                                                <Button onClick={this.handleSave} color="primary" autoFocus
                                                        disabled={this.state.working || !isValidMaxRedeems || !isValidDate || !isValidMinAmount || (isPercentage ? !isValidPercentage : !isValidAmount)}>
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <DialogTitle id="form-dialog-title">Add a new code
                                                to {page.title}</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    add a new code.
                                                </DialogContentText>
                                                <TextField
                                                    value={this.state.code}
                                                    margin="dense"
                                                    id="code"
                                                    name="code"
                                                    label="Code"
                                                    type="text"
                                                    error={codeAlreadyExists}
                                                    helperText={codeAlreadyExists ? "code exists already" : ""}
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                    disabled={this.state.working}
                                                />

                                                <FormControl className={classes.formControl}>
                                                    <InputLabel htmlFor="type">Type</InputLabel>
                                                    <Select
                                                        value={this.state.type}
                                                        onChange={this.handleChange}
                                                        inputProps={{
                                                            name: 'type',
                                                            id: 'type',
                                                        }}
                                                    >
                                                        <MenuItem value={"percentage"}>Percentage</MenuItem>
                                                        <MenuItem value={"amount"}>Amount</MenuItem>
                                                    </Select>
                                                </FormControl>

                                                <TextField
                                                    value={this.state.amount}
                                                    margin="dense"
                                                    id="amount"
                                                    name="amount"
                                                    label="Amount"
                                                    type="number"
                                                    error={isPercentage ? !isValidPercentage : !isValidAmount}
                                                    helperText={isPercentage ? (!isValidPercentage ? "Valid percentages are between 0 and 1, e.g. 0.01 for 1% " : "") : (!isValidAmount ? "Valid amounts are > 0" : "")}
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                    disabled={this.state.working}
                                                />

                                                <TextField
                                                    value={this.state.minAmount}
                                                    margin="dense"
                                                    id="minAmount"
                                                    name="minAmount"
                                                    label="Minimal Amount"
                                                    error={!isValidMinAmount}
                                                    helperText={!isValidAmount ? "Valid min amounts are > 0 " : ""}
                                                    type="number"
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                    disabled={this.state.working}
                                                />

                                                <DatePicker
                                                    label="Valid From"
                                                    value={this.state.validFrom}
                                                    onChange={this.handleValidFromChange}
                                                    animateYearScrolling
                                                    error={!isValidDate}
                                                    helperText={!isValidDate ? "Select a valid date" : ""}
                                                    fullWidth
                                                    format="YYYY-MM-DD"
                                                    disabled={this.state.working}

                                                />

                                                <DatePicker
                                                    label="Valid To"
                                                    value={this.state.validTo}
                                                    onChange={this.handleValidToChange}
                                                    animateYearScrolling
                                                    error={!isValidDate}
                                                    helperText={!isValidDate ? "Select a valid date" : ""}
                                                    fullWidth
                                                    format="YYYY-MM-DD"
                                                    disabled={this.state.working}
                                                />

                                                <TextField
                                                    value={this.state.maxRedeems}
                                                    margin="dense"
                                                    id="maxRedeems"
                                                    name="maxRedeems"
                                                    label="Max Redeems"
                                                    type="number"
                                                    error={!isValidMaxRedeems}
                                                    helperText={!isValidMaxRedeems ? "Valid max redeems amounts are > 1 " : ""}
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                    disabled={this.state.working}
                                                />

                                                <FormControl className={classes.formControl}>
                                                    <InputLabel>Constraint</InputLabel>
                                                    <Select
                                                        value={this.state.constraint}
                                                        onChange={this.handleChange}
                                                        inputProps={{
                                                            name: 'constraint',
                                                            id: 'constraint',
                                                            fullWidth: true
                                                        }}
                                                        displayEmpty={true}
                                                    >
                                                        <MenuItem value="none">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {
                                                            this.state.types.map(type => (
                                                                <MenuItem value={type}>{type}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>

                                            </DialogContent>

                                            <DialogActions>
                                                <Button onClick={this.closeDialog} color="primary"
                                                        disabled={this.state.working}>
                                                    Close
                                                </Button>
                                                <Button onClick={this.handleSave} color="primary" autoFocus
                                                        disabled={this.state.working || !isValidMaxRedeems || !isValidDate || !isValidCode || codeAlreadyExists || !isValidMinAmount || isPercentage ? !isValidPercentage : !isValidAmount}>
                                                    Save
                                                </Button>
                                            </DialogActions>

                                        </React.Fragment>
                                    )}

                                </Dialog>

                            </MuiPickersUtilsProvider>

                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        );
    }
}

Vouchers.propTypes = {
    classes: PropTypes.object.isRequired,
};

const defaultToolbarSelectStyles = {
    iconButton: {
        marginRight: "24px",
        top: "50%",
        display: "inline-block",
        position: "relative",
        transform: "translateY(-50%)",
    },
};

class CustomToolbarSelect extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <div className={"custom-toolbar-select"}>
                <Tooltip title={"Delete"}>
                    <IconButton className={classes.iconButton}
                                onClick={e => this.props.onDelete(this.props.selectedRows)}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

}

const StyledCustomToolbarSelect = withStyles(defaultToolbarSelectStyles, {name: "CustomToolbarSelect"})(CustomToolbarSelect);

export default withStyles(styles, {withTheme: true})(Vouchers)