import React, {Fragment, PureComponent} from 'react';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';

import {i18nText, i18n, i18nConfiguration} from "../common/I18n";


import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, TimePicker } from "material-ui-pickers";

import styled from 'styled-components';

const materialTheme = createMuiTheme({


    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#04ADA9',
            dark: '#04ADA9',
            // contrastText: will be calculated to contast with palette.primary.main
        },
    },

    typography: {
        fontFamily:
            'TruenoLight',
        useNextVariants: true,

    },

    overrides: {

        MuiFormLabel: {
            root: {
                textTransform: 'uppercase',
                fontSize: '14px',
                height: 20,
                color: '#04ADA9',

            },
            '&$focused': {
                color: '#04ADA9',
            }
        },
        MuiInput: {
            root: {
                textTransform: 'uppercase',
                fontSize: '14px',
                height: 20,
                paddingTop: 8,
                paddingBottom: 8,
                letterSpacing: 0,
                fontFamily: 'TruenoLight'
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#04ADA9',
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                // backgroundColor: lightBlue.A200,
                // color: 'white',
            },
        },
        MuiPickersDay: {
            day: {
                color: '#04ADA9',
            },
            selected: {
                backgroundColor: '#04ADA9',
            },
            current: {
                color: '#04ADA9',
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: '#04ADA9',
            },
        },
        MuiPickersClockPointer: {
            pointer: {
                backgroundColor: '#04ADA9',
            },
            thumb: {
                backgroundColor: '#04ADA9',
                border: `14px solid #04ADA9`,

            },
            noPoint: {
                backgroundColor: '#04ADA9',

            }
        },
        MuiPickersClock: {
            pin: {
                backgroundColor: '#04ADA9',
            },

        }


    },
});

export default class StyledDatePicker extends React.Component {

    render() {
        return (
            <Fragment>


                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <MuiThemeProvider theme={materialTheme}>
                        <div className="picker" style={{userSelect: "none", marginBottom:"16px"}}>
                            <TimePicker
                                clearable
                                ampm={false}
                                label={this.props.label}
                                value={this.props.date}
                                onChange={date => this.props.onChange(this.props.index, this.props.position, date)}
                                keyboard
                                mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                                placeholder="19:00"
                                invalidDateMessage={i18n("general/error/invalidTime")}
                                disableOpenOnEnter
                            />
                        </div>
                    </MuiThemeProvider>
                </MuiPickersUtilsProvider>


            </Fragment>
        )
            ;
    }
}