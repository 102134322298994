import {countries, masterdata} from "../common/configuration";
import {i18n, i18nConfiguration, i18nText} from "../common/I18n";
import React, {Component} from 'react';
import styled from 'styled-components';
import StyledCheckBox from "../components/StyledCheckBox";
import StyledTextField from "../components/StyledTextField";
import StyledTimePicker from "../components/StyledTimePicker";
import StyledFlatButton from "../components/StyledFlatButton";
import ScrollSelect from "../components/ScrollSelect";
import {PostalCodeMaskCustom} from "../common/utils";


const CheckboxLabelCSS = styled.div`
    opacity: 0.8;
    font-Family: TruenoRegular;
    font-size: 15.00px;
    color: #818181;
    letter-spacing: 0;
    line-height: 24.00px;
    align-items: start;
    user-select:none;
`;



export class CheckBoxSelectControl extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        return (
            <div>
                {this.props.allValues.map(value => (
                    <StyledCheckBox
                        checked={this.props.values.includes(value)}
                        onChange={this.props.onChange}
                        value={value}
                        marginBottom={'8px'}
                        label={
                            <CheckboxLabelCSS>{i18nConfiguration(this.props.i18nKey, value)}</CheckboxLabelCSS>}
                    />

                ))}
            </div>
        );
    }
}



const StreetCSS = styled.div`
margin-bottom: 16px;
`;

const PlaceCSS = styled.div`
margin-bottom: 16px;
`;

const CountryCSS = styled.div`
grid-area: country;
font-family: TruenoRegular;
font-size: 20px;
color: #04ADA9;
letter-spacing: 1.5px;
line-height: 22px;
text-transform: uppercase;
text-align: right;
user-select:none;
`;


const PlaceLineCSS = styled.div`
display: flex;
`;

const PlaceCodeCSS = styled.div`
width:20%;
padding-right: 10%;
`;

const PlaceCityCSS = styled.div`
width:70%;
`;

export class AddressControl extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        return (
            <div>
                <StreetCSS>
                    <StyledTextField
                        label={i18n("host/draft/street")}
                        name="street"
                        value={this.props.address.street}
                        onChange={this.props.onChange}
                        required={false}/>
                </StreetCSS>

                <PlaceCSS>
                    <PlaceLineCSS>
                        <PlaceCodeCSS>
                            <StyledTextField
                                label={i18n("host/draft/postalCode")}
                                name="postalCode"
                                value={this.props.address.postalCode}
                                mask={PostalCodeMaskCustom}
                                onChange={this.props.onChange}
                                required={false}/>


                        </PlaceCodeCSS>
                        <PlaceCityCSS>
                            <StyledTextField
                                label={i18n("host/draft/city")}
                                name="city"
                                value={this.props.address.city}
                                onChange={this.props.onChange}
                                required={false}/>


                        </PlaceCityCSS>
                    </PlaceLineCSS>
                </PlaceCSS>


                <CountryCSS>
                    {i18nText(countries[this.props.address.country])}
                </CountryCSS>

            </div>
        );
    }
}

const TimeLineCSS = styled.div`
margin-top: 16px;
margin-bottom: 16px;
display: flex;
justify-content:space-around;
@media screen and (max-width: 1000px) {
flex-direction: column;
align-items: center;
justify-content:center;
margin-bottom: 32px;
}
`;

const AddAnotherCSS = styled.div`
opacity: 0.8;
font-family: TruenoRegular;
font-size: 9px;
color: #FF6344;
letter-spacing: 0.96px;
text-align: center;
line-height: 22px;
text-transform: uppercase;
`;

export class TimesControl extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        return (
            <div>
                {Array.from(Array(this.props.times.length).keys()).map((value, index) => {

                    return (
                        <TimeLineCSS>

                            <StyledTimePicker label={i18n("host/register/starts")}
                                              index={index}
                                              position={0}
                                              date={this.props.times[index][0]}
                                              onChange={this.props.onChange}/>
                            <StyledTimePicker label={i18n("host/register/ends")}
                                              index={index}
                                              position={1}
                                              date={this.props.times[index][1]}
                                              onChange={this.props.onChange}/>
                        </TimeLineCSS>

                    )
                })}


                <TimeLineCSS>

                    <AddAnotherCSS>
                        <StyledFlatButton label={i18n("host/register/otherSlot")}
                                          onClick={this.props.onOtherSlot}/>
                    </AddAnotherCSS>
                </TimeLineCSS>

                {(this.props.times.length > 1) &&
                <TimeLineCSS>

                    <AddAnotherCSS>
                        <StyledFlatButton label={i18n("host/register/removeSlot")}
                                          onClick={this.props.onRemoveSlot}/>
                    </AddAnotherCSS>
                </TimeLineCSS>
                }

            </div>
        );
    }
}


const LabelCSS = styled.div`
margin-top: 40px;
margin-bottom: 40px;
opacity: 0.8;
font-family: TruenoRegular;
font-size: 14px;
color: #04ADA9;
letter-spacing: 1.5px;
text-align: center;
line-height: 22px;
text-transform: uppercase;
user-select: none;
`;


export class PeopleControl extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        return (
            <div>
                <LabelCSS>{i18n("host/draft/seats/min")}</LabelCSS>
                <ScrollSelect
                    values={[...Array(6).keys()].map(i => i+1)}
                    scrollAfter={3}
                    minVisible={3}
                    height={"40px"}
                    boxSize={40}
                    selected={this.props.people.min - 1}
                    onChange={this.props.onChange}
                    name={"min"}
                />
                <LabelCSS>{i18n("host/draft/seats/max")}</LabelCSS>
                <ScrollSelect
                    values={[...Array(20).keys()].map(i => i+1)}
                    scrollAfter={3}
                    minVisible={3}
                    height={"40px"}
                    boxSize={40}
                    selected={this.props.people.max - 1}
                    onChange={this.props.onChange}
                    name={"max"}
                />

            </div>
        );
    }
}








